import React from "react";

const Head = () => (
  <thead>
    <tr>
      <th></th>
      <th>Date</th>
      <th>Amount</th>
      <th>Type</th>
      <th>Description</th>
      <th>Status</th>
      <th></th>
      <th></th>
      <th></th>
    </tr>
  </thead>
);

export default Head;
