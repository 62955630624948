import React, { Component } from "react";
import Select from "react-select";
import _ from "underscore";
import { getRequest } from "../../../../../../../utils/httpRequest";
import AllPaymentMethods from "./AllPaymentMethods";
import SelectedPaymentMethodGroup from "./SelectedPaymentMethodGroup";
import BankAccount from "../BankAccount";
import OnlineWallet from "../OnlineWallet";

class SelectPaymentMethod extends Component {
  constructor(props) {
    super(props);
    this.state = {
      giftCards: [],
      onlineTransfers: [],
      cashDeposits: [],
      creditCards: [],
      bankTransfers: [],
      digitalCurrencies: [],
      goodsAndServices: [],
      allPaymentMethods: [],
      selectedPaymentMethod: null,
      selectedPaymentMethodGroup: "",
      showPaymentMethods: false,
      selectedOnlineWallet: null,
      paymentMethodGroups: [],
      currencies: [],
      countries: [],
      paymentMethodGroup: "",
      isLoadingPaymentMethods: false,
      isLoadingCountries: false,
      isLoadingCurrencies: false,
      isLoadingBankAccounts: false,
      selectedBankAccount: "",
    };

    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    this.divRef = React.createRef();
  }

  componentDidMount() {
    this.getPaymentMethodsList();
    this.getPaymentMethodGroups();
    setTimeout(() => {
      this.getCurrencies();
    }, 500);

    setTimeout(() => {
      this.getCountries();
    }, 1000);

    document.addEventListener("mousedown", this.handleOutsideClick);
    this.setState({
      selectedCurrency: this.props.getData("selectedCurrency"),
      // grab select currency and update the flag
      flagUrl: `
      https://flagcdn.com/48x36/${this.props
        .getData("selectedCurrency")
        .value.slice(0, -1)
        .toLowerCase()}.png
      `,
      selectedCountry: this.props.getData("selectedCountry"),
      selectedPaymentMethod: this.props.getData("selectedPaymentMethod"),
      selectedPaymentMethodGroup: this.props.getData(
        "selectedPaymentMethodGroup"
      ),
    });
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleOutsideClick);
  }

  handleOutsideClick(event) {
    if (this.divRef.current && !this.divRef.current.contains(event.target)) {
      this.setState({
        showPaymentMethods: false,
      });
    }
  }

  getCurrencies = () => {
    this.setState({ isLoadingCurrencies: true });
    getRequest("/p2p_trades/currencies").then((response) => {
      const currencies = response.map((currency) => ({
        label: `${currency.name} (${currency.code})`,
        value: currency.code,
        rateToUsd: currency.rateToUsd,
        overrideRate: null,
        sellRate: parseFloat(currency?.sellRate),
        buyRate: parseFloat(currency.buyRate),
      }));

      this.setState({
        currencies,
        selectedCurrency: this.state.selectedCurrency
          ? currencies.find(
              (item) => item.value === this.state.selectedCurrency.value
            )
          : null,
        flagUrl: `
        https://flagcdn.com/48x36/${this.props
          .getData("selectedCurrency")
          .value.slice(0, -1)
          .toLowerCase()}.png
        `,
        isLoadingCurrencies: false,
      });

      this.props.updateParent("selectedCurrency", this.state.selectedCurrency);
    });
  };

  getPaymentMethodsList = () => {
    getRequest("/p2p_trades/payment_methods").then((response) => {
      this.setState({
        allPaymentMethods: response.all_payment_methods,
      });
    });
  };

  getCountries = () => {
    this.setState({ isLoadingCountries: true });
    getRequest("/p2p_trades/countries").then((response) => {
      const countries = response.map((country) => ({
        label: country.name,
        value: country.code,
      }));

      this.setState({
        countries,
        selectedCountry: this.state.selectedCountry || countries[0],
        isLoadingCountries: false,
      });

      this.props.updateParent(
        "selectedCountry",
        this.state.selectedCountry || countries[0]
      );
    });
  };

  getPaymentMethodGroups = () => {
    this.setState({ isLoadingPaymentMethods: true });
    getRequest("/p2p_trades/payment_method_groups").then((response) => {
      this.setState({
        giftCards: this.getPaymentMethodGroup(response, "gift-cards"),
        onlineTransfers: this.getPaymentMethodGroup(
          response,
          "online-transfers"
        ),
        cashDeposits: this.getPaymentMethodGroup(response, "cash-deposits"),
        creditCards: this.getPaymentMethodGroup(response, "debitcredit-cards"),
        bankTransfers: this.getPaymentMethodGroup(response, "bank-transfers"),
        digitalCurrencies: this.getPaymentMethodGroup(
          response,
          "digital-currencies"
        ),
        goodsAndServices: this.getPaymentMethodGroup(
          response,
          "goods-and-services"
        ),
        paymentMethodGroups: response,
        isLoadingPaymentMethods: false,
      });
    });
  };

  getPaymentMethodGroup = (response, slug) => {
    const pmg = _.find(response, function (paymentMethodGroup) {
      return paymentMethodGroup.slug === slug;
    });

    return pmg.paymentMethods;
  };

  findPaymentMethodGroup = (slug) => {
    const { paymentMethodGroups } = this.state;

    return _.find(paymentMethodGroups, function (paymentMethodGroup) {
      return paymentMethodGroup.slug === slug;
    });
  };

  findPaymentMethod = (id) => {
    const { allPaymentMethods } = this.state;

    return _.find(allPaymentMethods, function (pm) {
      return pm.id === id;
    });
  };

  findPaymentMethodBySlug = (slug) => {
    const { allPaymentMethods } = this.state;

    return _.find(allPaymentMethods, function (pm) {
      return pm.slug === slug;
    });
  };

  toggleShowPaymentMethods = (e) => {
    e.preventDefault();

    this.setState({ showPaymentMethods: !this.state.showPaymentMethods });
  };

  onSelectPaymentMethodGroup = (e, pmg) => {
    e.preventDefault();

    this.setState({
      selectedPaymentMethodGroup: pmg,
    });

    this.props.updateParent("selectedPaymentMethodGroup", pmg);
  };

  handleCurrencyChange = (selectedCurrency) => {
    this.setState({ selectedCurrency });
    this.props.updateParent("selectedCurrency", selectedCurrency);

    const currencyCode = selectedCurrency.value;
    const countryCode = currencyCode.slice(0, -1).toLowerCase();
    const newFlagUrl = `https://flagcdn.com/48x36/${countryCode}.png`;
    this.setState({ flagUrl: newFlagUrl });
  };

  getPaymentMethods = () => {
    const {
      giftCards,
      onlineTransfers,
      cashDeposits,
      creditCards,
      bankTransfers,
      digitalCurrencies,
      goodsAndServices,
      allPaymentMethods,
      selectedPaymentMethodGroup,
    } = this.state;

    switch (selectedPaymentMethodGroup) {
      case "gift-cards":
        return giftCards;
      case "online-transfers":
        return onlineTransfers;
      case "cash-deposits":
        return cashDeposits;
      case "debitcredit-cards":
        return creditCards;
      case "bank-transfers":
        return bankTransfers;
      case "digital-currencies":
        return digitalCurrencies;
      case "goods-and-services":
        return goodsAndServices;
      default:
        return allPaymentMethods;
    }
  };

  resetSelectedPmg = (e) => {
    e.preventDefault();
    this.setState({ selectedPaymentMethodGroup: "" });
  };

  selectPaymentMethod = (e, pm, pmg) => {
    e.preventDefault();

    this.setState({
      selectedPaymentMethod: pm,
      showPaymentMethods: false,
      paymentMethodGroup: pmg,
    });

    const selectedPaymentMethod = this.findPaymentMethodBySlug(pm.value);

    this.props.updateParent("selectedPaymentMethod", pm);
    this.props.updateParent(
      "selectedPaymentMethodId",
      selectedPaymentMethod.id
    );
    this.props.updateParent("selectedPaymentMethodGroup", pmg);
  };

  handlePaymentMethodChange = (selectedPaymentMethod) => {
    const paymentMethod = this.findPaymentMethodBySlug(
      selectedPaymentMethod.value
    );
    const getPaymentMethodGroup = paymentMethod?.paymentMethodGroup;
    this.setState({
      selectedPaymentMethod: selectedPaymentMethod,
      selectedPaymentMethodGroup: getPaymentMethodGroup?.slug,
    });
    this.props.updateParent("selectedPaymentMethod", selectedPaymentMethod);
    this.props.updateParent("selectedPaymentMethodId", paymentMethod?.id);
    this.props.updateParent(
      "selectedPaymentMethodGroup",
      getPaymentMethodGroup?.slug
    );
  };

  handleCountryChange = (selectedCountry) => {
    this.setState({ selectedCountry });

    this.props.updateParent("selectedCountry", selectedCountry);
  };

  render() {
    const {
      allPaymentMethods,
      giftCards,
      onlineTransfers,
      cashDeposits,
      creditCards,
      bankTransfers,
      digitalCurrencies,
      goodsAndServices,
      showPaymentMethods,
      selectedPaymentMethodGroup,
      paymentMethodGroups,
      selectedPaymentMethod,
      countries,
      currencies,
      bankAccounts,
      selectedCurrency,
      selectedCountry,
      selectedBankAccount,
      selectedOnlineWallet,
      paymentMethodGroup,
      isLoadingPaymentMethods,
      isLoadingCountries,
      isLoadingCurrencies,
      flagUrl,
    } = this.state;

    const tradeType = this.props.tradeType;
    const formatPaymentMethods = allPaymentMethods.map((pm) => ({
      label: pm.name,
      value: pm.slug,
    }));

    return (
      <div className="select-payment-method createoffer-select-payment-method col-12">

          {selectedPaymentMethod && (
            <div className="tab-container">
              <div className="currency-group">
                {/* Asset Container USD */}
                <div className="content">
                  <h3 className="createoffer-paymentmethod-labels">Currency {
                      tradeType === "buy" ? "I Want to Buy: " : "I Want to Sell:"
                    } </h3>
                  <div className="createoffer-currency">
                    <div className="createoffer-flag">
                    <img src="https://flagcdn.com/48x36/us.png" />
                    </div>
                    <p className="select createoffer-selected-currency">US Dollars</p>
                  </div>
                </div>
                {/* Fiat container Other currency */}
                <div className="content">
                  <h3 className="createoffer-paymentmethod-labels">
                    {
                      tradeType === "buy" ? "Currency I Will Pay With: " : "Currency I Will Receive: "
                    }
                  </h3>
                  <div className="createoffer-currency">
                    <div className="createoffer-flag">
                    <img src={flagUrl} />
                    </div>
                    <Select
                    className="_currency-select currencies-select-container"
                    options={currencies}
                    value={selectedCurrency}
                    onChange={this.handleCurrencyChange}
                  />
                  </div>
                </div>
              </div>
            </div>
          )}

        <div className={`createoffer-payment-method-wrapper ${tradeType === "sell" ? "createoffer-payment-method-wrapper-sell" : ""}`}>
          <h3 className="createoffer-paymentmethod-labels">Select Payment Method:</h3>
          <div className="createoffer-payment-method">
            <div className="createoffer-paymentmethod-inputgroup mt-3 px-0">
              <Select
                className="createoffer-paymentmethod-dropdown"
                options={formatPaymentMethods}
                value={selectedPaymentMethod}
                onChange={this.handlePaymentMethodChange}
              />
              {/* <button
                className={`createoffer-all-payment-btn ${tradeType === "sell" ? "createoffer-sell-btn" : ""}`}
                type="button"
                onClick={this.toggleShowPaymentMethods}
              >
                Show All
              </button> */}
            </div>

            {selectedPaymentMethodGroup && showPaymentMethods ? (
              <div ref={this.divRef}>
                <SelectedPaymentMethodGroup
                  paymentMethods={this.getPaymentMethods()}
                  resetSelectedPmg={this.resetSelectedPmg}
                  selectPaymentMethod={this.selectPaymentMethod}
                  name={
                    selectedPaymentMethodGroup === "all-payment-methods"
                      ? "All Payment Methods"
                      : this.findPaymentMethodGroup(selectedPaymentMethodGroup)
                        ?.name
                  }
                />
              </div>
            ) : (
              // not sure where this was used....
              <div ref={this.divRef}>
                {showPaymentMethods && (
                  <AllPaymentMethods
                    allPaymentMethods={allPaymentMethods}
                    giftCards={giftCards}
                    onlineTransfers={onlineTransfers}
                    cashDeposits={cashDeposits}
                    creditCards={creditCards}
                    bankTransfers={bankTransfers}
                    digitalCurrencies={digitalCurrencies}
                    goodsAndServices={goodsAndServices}
                    onSelectPaymentMethodGroup={this.onSelectPaymentMethodGroup}
                  />
                )}
              </div>
            )}
          </div>
          {selectedPaymentMethod &&
            selectedPaymentMethodGroup === "bank-transfers" && (
              <>
                <BankAccount
                  countries={countries}
                  currencies={currencies}
                  selectedCurrency={selectedCurrency}
                  selectedCountry={selectedCountry}
                  selectedBankAccount={selectedBankAccount}
                  selectedPaymentMethod={selectedPaymentMethod}
                  handleCountryChange={this.handleCountryChange}
                  updateParent={this.props.updateParent}
                  getData={this.props.getData}
                />
              </>
            )}
          {selectedPaymentMethod &&
            selectedPaymentMethodGroup === "online-transfers" && selectedPaymentMethod.value !== "airline-tickets" && (
              <>
                {selectedPaymentMethod?.value === "paypal" && (
                  <div>
                    <br />
                    <div className="col-md-12">
                      <strong>Requirements before making payment</strong>
                      <p>
                        How would you like to verify your buyer’s identity?
                        Select the ID proofs you want to request from them. You
                        can view these documents before the buyer makes the
                        payment. However, your PayPal email address will be
                        shared with them only after you approve these documents.
                      </p>
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="beforePaymentIdDocument"
                          name="example1"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="beforePaymentIdDocument"
                        >
                          ID Document
                        </label>
                        <br />
                        <span>
                          A picture of the buyer’s valid government-issued ID
                          document.
                        </span>
                        <br />
                      </div>
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="beforePaymentselfie"
                          name="example1"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="beforePaymentselfie"
                        >
                          Selfie with ID
                        </label>
                        <br />
                        <span>
                          A picture of the buyer holding their ID next to their
                          face, with their face clearly visible in the document.
                        </span>
                        <br />
                      </div>
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="beforePaymentProofFunds"
                          name="example1"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="beforePaymentProofFunds"
                        >
                          Proof of funds
                        </label>
                        <br />
                        <span>
                          A screenshot of their PayPal account showing the
                          buyer’s available balance.
                        </span>
                      </div>
                    </div>
                    <br />
                    <div className="col-md-12">
                      <strong>Requirements after making payment</strong>
                      <p>
                        How would you like to receive confirmation of payment?
                        Select Proof of payment below to request a receipt of
                        payment from the buyer once the payment is made.
                      </p>
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="beforePaymentProofPayment"
                          name="example1"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="beforePaymentProofPayment"
                        >
                          Proof of payment
                        </label>
                        <br />
                        <span>
                          A proof that would confirm that the payment was made.
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                <OnlineWallet
                  paymentMethodGroup={selectedPaymentMethodGroup}
                  selectedOnlineWallet={selectedOnlineWallet}
                  updateParent={this.props.updateParent}
                  getData={this.props.getData}
                />
              </>
            )}
        </div>
      </div>
    );
  }
}

export default SelectPaymentMethod;
