import React, { Component } from "react";
import moment from "moment";
import _ from "underscore";
import { filtersMapping, typesMapping } from "./helpers";
import "./index.scss";

class Filters extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: moment().startOf("year"),
      endDate: moment(),
      date: "",
      kind: "today",
      status: "",
    };
  }

  componentDidMount() {
    this.setDatePicker(false);
  }

  handleSearchChange = (e) => {
    this.setState({ search: e.target.value });

    this.props.onSelectFilter("search", e.target.value);
  };

  selectStatus = (e, value) => {
    e.preventDefault();

    this.setState({ status: value });
    this.props.onSelectFilter("status", value);
  };

  selectType = (e, value) => {
    e.preventDefault();

    this.setState({ transferType: value });
    this.props.onSelectFilter("transferType", value);
  };

  selectCurrency = (e, value) => {
    e.preventDefault();

    this.setState({ currency: value });
    this.props.onSelectFilter("currency", value);
  };

  setDatePicker = () => {
    const that = this;

    var start = moment().startOf("year");
    var end = moment();

    function cb(start, end) {
      const startDate = moment(start);
      const endDate = moment(end);

      that.setState({
        startDate,
        endDate,
      });

      that.props.onSelectFilter("startDate", startDate);
      that.props.onSelectFilter("endDate", endDate);
    }

    $("#date-range").daterangepicker(
      {
        startDate: start,
        endDate: end,
        ranges: {
          Today: [moment(), moment()],

          "Last 7 Days": [moment().subtract(6, "days"), moment()],
          "This Month": [moment().startOf("month"), moment().endOf("month")],
          "Last Month": [
            moment().subtract(1, "months").startOf("month"),
            moment().subtract(1, "months").endOf("month"),
          ],
          "Last 6 Months": [
            moment().subtract(6, "months").startOf("month"),
            moment().endOf("month"),
          ],
          "Last Year": [
            moment().subtract(1, "years").startOf("month"),
            moment().endOf("month"),
          ],
        },
      },
      cb
    );

    cb(start, end);
  };

  setDateFilters = () => {
    this.props.onSelectFilter("startDate", this.state.startDate, true);
    this.props.onSelectFilter("endDate", this.state.endDate, true);
  };

  render() {
    const { currencies, filters } = this.props;
    const { date } = this.state;

    const hasData =
      filters?.currency ||
      filters?.transferType ||
      filters?.search ||
      filters.status ||
      !(
        filters.startDate === moment().startOf("year") ||
        filters.endDate === moment()
      );

    return (
      <div>
        <form className="form-inline justify-content-start my-2 my-lg-0 ml-md-3 pt-4">
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
                <i className="fa fa-search"></i>
              </span>
            </div>
            <input
              className="form-control mr-sm-2 rounded-sm font-0_9 pl-2 pr-2 search-input"
              type="search"
              placeholder="Search Transactions"
              aria-label="Search"
              value={filters.search}
              onChange={this.handleSearchChange}
            />
          </div>

          <div className="btn-group status">
            <button
              className="btn btn-secondary dropdown-toggle"
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {filters?.status ? filtersMapping[filters.status] : "Status"}
            </button>
            <div className="dropdown-menu">
              <a
                className="dropdown-item"
                href="#"
                onClick={(e) => this.selectStatus(e, "pending")}
              >
                Pending
              </a>
              <a
                className="dropdown-item"
                href="#"
                onClick={(e) => this.selectStatus(e, "completed")}
              >
                Completed
              </a>
              <a
                className="dropdown-item"
                href="#"
                onClick={(e) => this.selectStatus(e, "unconfirmed")}
              >
                Unconfirmed
              </a>
              <a
                className="dropdown-item"
                href="#"
                onClick={(e) => this.selectStatus(e, "needs_confirmation")}
              >
                Needs confirmation
              </a>
            </div>
          </div>
          <div className="btn-group transfer_type">
            <button
              className="btn btn-secondary dropdown-toggle"
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {filters?.transferType
                ? typesMapping[filters.transferType]
                : "Type"}
            </button>
            <div className="dropdown-menu">
              <a
                className="dropdown-item"
                href="#"
                onClick={(e) => this.selectType(e, "sent transfer")}
              >
                Sent Transfer
              </a>
              <a
                className="dropdown-item"
                href="#"
                onClick={(e) => this.selectType(e, "migration transfer")}
              >
                Migration Transfer
              </a>
              <a
                className="dropdown-item"
                href="#"
                onClick={(e) => this.selectType(e, "funding deposit")}
              >
                Funding Deposit
              </a>
              <a
                className="dropdown-item"
                href="#"
                onClick={(e) => this.selectType(e, "payout withdrawal")}
              >
                Payout Withdrawal
              </a>
              <a
                className="dropdown-item"
                href="#"
                onClick={(e) => this.selectType(e, "convert deposit")}
              >
                Convert Deposit
              </a>
              <a
                className="dropdown-item"
                href="#"
                onClick={(e) => this.selectType(e, "received transfer")}
              >
                Received Transfer
              </a>
              <a
                className="dropdown-item"
                href="#"
                onClick={(e) => this.selectType(e, "transaction")}
              >
                Transaction
              </a>
            </div>
          </div>
          <div className="btn-group currency-list">
            <button
              className="btn btn-secondary dropdown-toggle"
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {filters?.currency ? filters?.currency : "Currency"}
            </button>
            <div className="dropdown-menu currencies">
              {currencies.map((currency, i) => (
                <a
                  key={i}
                  className="dropdown-item"
                  href="#"
                  onClick={(e) => this.selectCurrency(e, currency.value)}
                >
                  {currency.value}
                </a>
              ))}
            </div>
          </div>
          <input
            id="date-range"
            placeholder="Date range"
            className="form-control rounded-sm font-0_9 pl-2 pr-2"
            style={{ minWidth: 200 }}
            onClick={this.setDateFilters}
          />
          <button
            className="btn btn-tiny btn-purple ml-sm-0 ml-md-4 pt-2 pb-2"
            type="submit"
            onClick={this.props.filterTransfers}
          >
            Apply{" "}
            <svg
              viewBox="0 0 24 24"
              fill="none"
              height={16}
              width={16}
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <path
                  d="M21 6H19M21 12H16M21 18H16M7 20V13.5612C7 13.3532 7 13.2492 6.97958 13.1497C6.96147 13.0615 6.93151 12.9761 6.89052 12.8958C6.84431 12.8054 6.77934 12.7242 6.64939 12.5617L3.35061 8.43826C3.22066 8.27583 3.15569 8.19461 3.10948 8.10417C3.06849 8.02393 3.03853 7.93852 3.02042 7.85026C3 7.75078 3 7.64677 3 7.43875V5.6C3 5.03995 3 4.75992 3.10899 4.54601C3.20487 4.35785 3.35785 4.20487 3.54601 4.10899C3.75992 4 4.03995 4 4.6 4H13.4C13.9601 4 14.2401 4 14.454 4.10899C14.6422 4.20487 14.7951 4.35785 14.891 4.54601C15 4.75992 15 5.03995 15 5.6V7.43875C15 7.64677 15 7.75078 14.9796 7.85026C14.9615 7.93852 14.9315 8.02393 14.8905 8.10417C14.8443 8.19461 14.7793 8.27583 14.6494 8.43826L11.3506 12.5617C11.2207 12.7242 11.1557 12.8054 11.1095 12.8958C11.0685 12.9761 11.0385 13.0615 11.0204 13.1497C11 13.2492 11 13.3532 11 13.5612V17L7 20Z"
                  stroke="#fff"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>{" "}
              </g>
            </svg>
          </button>
          {hasData && (
            <a
              className="btn btn-outline-danger clear-btn btn-tiny ml-2"
              type="submit"
              onClick={this.props.clearFilters}
            >
              Clear filters{" "}
              <svg
                viewBox="0 0 24 24"
                height={16}
                width={16}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <path
                    d="M15 15L21 21M21 15L15 21M10 21V14.6627C10 14.4182 10 14.2959 9.97237 14.1808C9.94787 14.0787 9.90747 13.9812 9.85264 13.8917C9.7908 13.7908 9.70432 13.7043 9.53137 13.5314L3.46863 7.46863C3.29568 7.29568 3.2092 7.2092 3.14736 7.10828C3.09253 7.01881 3.05213 6.92127 3.02763 6.81923C3 6.70414 3 6.58185 3 6.33726V4.6C3 4.03995 3 3.75992 3.10899 3.54601C3.20487 3.35785 3.35785 3.20487 3.54601 3.10899C3.75992 3 4.03995 3 4.6 3H19.4C19.9601 3 20.2401 3 20.454 3.10899C20.6422 3.20487 20.7951 3.35785 20.891 3.54601C21 3.75992 21 4.03995 21 4.6V6.33726C21 6.58185 21 6.70414 20.9724 6.81923C20.9479 6.92127 20.9075 7.01881 20.8526 7.10828C20.7908 7.2092 20.7043 7.29568 20.5314 7.46863L17 11"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>{" "}
                </g>
              </svg>
            </a>
          )}
        </form>
      </div>
    );
  }
}

export default Filters;
