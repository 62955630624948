import React, { Component } from "react";
import PropTypes from "prop-types";
import { SDK as BancorSDK } from "@bancor/sdk";
import Web3 from "web3";
import BigNumber from "bignumber.js";
import { asciiToHex, fromWei, toHex, toWei, unitMap } from "web3-utils";
import MetaMaskOnboarding from "@metamask/onboarding";
import toastr from "toastr";
import "toastr/toastr.scss";
import ConnectMetamask from "./ConnectMetamask";
import ErrorModal from "./ErrorModal";
import bancorNetworkAbi from "../../model/bancorNetworkAbi.json";
import contractRegistryAbi from "../../model/contractRegistryAbi.json";
import tokenAbi from "../../model/tokenAbi.json";
import "./index.scss";

toastr.options = {
  closeButton: true,
  debug: false,
  newestOnTop: false,
  progressBar: false,
  positionClass: "toast-top-right",
  preventDuplicates: true,
  onclick: null,
  showDuration: "300",
  hideDuration: "1000",
  timeOut: 0,
  extendedTimeOut: 0,
  showEasing: "swing",
  hideEasing: "linear",
  showMethod: "fadeIn",
  hideMethod: "fadeOut",
  tapToDismiss: false
};

class Trade extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMetaMaskInstalled: false,
      isMetaMaskConnected: false,
      metamaskAccount: "",
      sourceToken: {
        symbol: this.props.sourceTokenSymbol,
        contract: this.props.sourceTokenAddress,
        precision: 18
      },
      destinationToken: {
        symbol: this.props.destinationTokenSymbol,
        contract: this.props.destinationTokenAddress,
        precision: 6
      },
      tradeFromAllTokens: this.props.tradeFromAllTokens,

      mode: "connect",
      sourceAmount: "",
      destinationAmount: null,
      rate: null,
      openConfirmTradeModal: false,
      showConfirmationModal: false,
      isMainnet: false,
      errorMessage: "",
      showErrorModal: false,
      tradeInProgress: false,
      sourceTokenBalance: 0,
      destinationTokenBalance: 0,
      tradeMessage: ""
    };

    if (window.ethereum) {
      console.log(`Injected ethereum detected.`);
      this.web3 = new Web3(window.ethereum);
    } else if (window.web3) {
      console.log(`Injected web3 detected.`);
      this.web3 = new Web3(window.web3.currentProvider);
    }

    this.initializeBancor();

    if (window.ethereum) {
      ethereum.on("chainChanged", chainId => {
        this.setChain(chainId);
      });

      ethereum.on("accountsChanged", accounts => {
        this.setAccount(accounts[0]);
      });
    }
  }

  componentDidMount() {
    if (this.isMetaMaskInstalled()) {
      this.setState({ isMetaMaskInstalled: true });
      this.getAccount();
    }
  }

  setChain = chainId => {
    this.setState({ isMainnet: chainId === "0x1" });

    this.getAccount();
  };

  setAccount = account => {
    this.setState({ metamaskAccount: account }, () => this.setTokenBalance());
  };

  getAccount = async () => {
    const chainId = this.web3.currentProvider.chainId;

    const accounts = await ethereum.request({ method: "eth_accounts" });
    const account = accounts[0];

    if (account) {
      this.setState(
        {
          metamaskAccount: account,
          isMetaMaskConnected: true,
          isMainnet: chainId === "0x1"
        },
        () => this.setTokenBalance()
      );
    }
  };

  initializeBancor = async () => {
    const settings = {
      // optional, mandatory when interacting with the ethereum mainnet
      ethereumNodeEndpoint: this.props.ethereumNodeEndpoint
      // optional, mandatory when interacting with the EOS mainnet
    };

    const sdk = await BancorSDK.create(settings);

    this.setState({ sdk }, () => this.setRate());
  };

  setRate = async () => {
    const amount = await this.getAmount(1);

    const rate = `1 ${this.state.sourceToken.symbol} = ${amount.toFixed(6)} ${this.state.destinationToken.symbol
      }`;

    this.setState({ rate });
  };

  isMetaMaskInstalled = () => {
    //Have to check the ethereum binding on the window object to see if it's installed
    const { ethereum } = window;
    return Boolean(ethereum && ethereum.isMetaMask);
  };

  onClickInstallMetamask = e => {
    e.preventDefault();
    const onboarding = new MetaMaskOnboarding({});

    onboarding.startOnboarding();
  };

  onClickConnectMetamask = async e => {
    e.preventDefault();

    try {
      // Will open the MetaMask UI
      // You should disable this button while the request is pending!
      const { ethereum } = window;
      await ethereum.request({ method: "eth_requestAccounts" });
      this.getAccount();
    } catch (error) {
      console.error(error);
    }
  };

  setToken = (key, token) => {
    this.setState({ [key]: token }, () => {
      this.setConvertedAmount();
      this.setTokenBalance();
    });
  };

  setConvertedAmount = async () => {
    const sourceAmount = Number(this.state.sourceAmount);
    const amount = await this.getAmount(sourceAmount);

    this.setState({ destinationAmount: amount });
    this.setRate();
  };

  getPathAndRate = async () => {
    const sourceToken = {
      blockchainType: "ethereum",
      blockchainId: this.state.sourceToken.contract
    };

    const targetToken = {
      blockchainType: "ethereum",
      blockchainId: this.state.destinationToken.contract
    };

    const path = await this.state.sdk.pricing.getPathAndRate(
      sourceToken,
      targetToken,
      "1.0"
    );

    return path;
  };

  getAmount = async sourceAmount => {
    const pathAndRate = await this.getPathAndRate();
    const rate = Number(pathAndRate.rate);
    const amount = rate * sourceAmount;

    return amount;
  };

  handleSourceAmountChange = amount => {
    if (isNaN(amount)) {
      this.setState({ sourceAmount: "" });
      return;
    }

    this.setState({ sourceAmount: amount }, () => this.setConvertedAmount());
  };

  exchangeToken = () => {
    const { sourceToken, destinationToken } = this.state;

    const previousSourceToken = sourceToken;
    const previousDestinationToken = destinationToken;

    this.setState(
      {
        sourceToken: previousDestinationToken,
        destinationToken: previousSourceToken
      },
      () => {
        this.setConvertedAmount();
        this.setTokenBalance();
      }
    );
  };

  setTokenBalance = async () => {
    const { sourceToken, destinationToken } = this.state;

    const sourceTokenBalance = await this.getTokenBalance(sourceToken);

    const destinationTokenBalance = await this.getTokenBalance(
      destinationToken
    );

    this.setState({ sourceTokenBalance, destinationTokenBalance });
  };

  getTokenBalance = async token => {
    const { metamaskAccount } = this.state;
    const that = this;
    let balance = 0;

    if (token.symbol === "ETH") {
      if (this.web3) {
        balance = await this.web3.eth.getBalance(metamaskAccount);
        return fromWei(balance);
      } else {
        balance = 1.0
      }
    } else {
      try {
        const tokenContract = new that.web3.eth.Contract(
          tokenAbi,
          token.contract
        );

        const tokenBalance = await tokenContract.methods
          .balanceOf(metamaskAccount)
          .call();

        balance = new BigNumber(tokenBalance)
          .div(new BigNumber(10).pow(token.precision))
          .toFixed(token.precision, BigNumber.ROUND_DOWN)
          .toString();
      } catch (e) {
        balance = "0";
      }
    }

    return isNaN(balance) || Number(balance) === 0 ? "0" : balance;
  };


  handleOpenConfirmModal = e => {
    e.preventDefault();
    this.handleCloseConfirmTradeModal(e);
    this.setState({ showConfirmationModal: true });
  };

  handleCloseConfirmModal = e => {
    e.preventDefault();
    this.setState({ showConfirmationModal: false });
  };

  handleCloseErrorModal = e => {
    e.preventDefault();
    this.setState({ showErrorModal: false, errorMessage: "" });
  };

  handleOpenConfirmTradeModal = e => {
    e.preventDefault();
    this.setState({ openConfirmTradeModal: true });
  };

  handleCloseConfirmTradeModal = e => {
    e.preventDefault();
    this.setState({ openConfirmTradeModal: false });
  };

  // units for the source token based on precision
  getUnitsByPrecision = (precision) => {
    const unitMap = this.web3.utils.unitMap;
    const value = Math.pow(10, precision).toString();
    return Object.keys(unitMap).find(key => unitMap[key] === value);
  };

  trade = async e => {
    e.preventDefault();
    const {
      sourceAmount,
      sourceToken,
      destinationAmount,
      destinationToken
    } = this.state;

    const tradeMessage = `Sell ${sourceAmount} ${sourceToken.symbol
      } and receive ${destinationAmount.toFixed(6)} ${destinationToken.symbol}`;

    this.setState({ tradeMessage, errorMessage: "", tradeInProgress: true });

    const accounts = await ethereum.request({ method: "eth_accounts" });
    this.web3.eth.defaultAccount = accounts[0];
    // this.web3.eth.personal.unlockAccount(this.web3.eth.defaultAccount);

    const contractRegistryAddress = this.props.contractRegistryAddress;
    const ContractRegistry = new this.web3.eth.Contract(
      contractRegistryAbi,
      contractRegistryAddress
    );

    const bancorNetworkName = this.web3.utils.fromAscii("BancorNetwork");
    const bancorNetworkAddress = await ContractRegistry.methods
      .addressOf(bancorNetworkName)
      .call();

    const BancorNetworkContract = new this.web3.eth.Contract(
      bancorNetworkAbi,
      bancorNetworkAddress
    );

    // Convert sourceAmount to Wei based on the token's precision
    const sourceAmountInWei = this.web3.utils.toWei(
      this.state.sourceAmount,
      this.getUnitsByPrecision(this.state.sourceToken.precision)
    ).toString();

    // console.log('sourceAmountInWei', sourceAmountInWei);
    // console.log('precision', this.state.sourceToken.precision);
    // console.log('sourceAmount', this.state.sourceAmount);
    // console.log('units', this.getUnitsByPrecision(this.state.sourceToken.precision));


    const pathAndRate = await this.getPathAndRate();

    // console.log('pathAndRate', pathAndRate);
    const paths = pathAndRate.path.map(val => val.blockchainId);
    //console.log('paths', paths);
    // const rateForGasEstimate = Number(pathAndRate.rate);

    const minReturn = await BancorNetworkContract.methods
      .rateByPath(
        paths,
        sourceAmountInWei
      ).call();

    // console.log('minReturn', minReturn);
    // From bancor
    // when exchanging ether for another token, use the convert function
    // when exchanging one token for another token, use the claimAndConvert function
    // however convert has been deprecated and defaults to convertByPath
    // see bancor contract https://etherscan.io/address/0x2f9ec37d6ccfff1cab21733bdadede11c823ccb0#code
    let web3EthContract;

    web3EthContract = await BancorNetworkContract.methods.convert(
      paths,
      sourceAmountInWei,
      minReturn
    );

    // TODO try with affiliate fees
    // web3EthContract =  await BancorNetworkContract.methods.convertByPath(
    //     paths,
    //     sourceAmountInWei,
    //     minReturn,
    //     "0x0000000000000000000000000000000000000000",
    //     "0x0000000000000000000000000000000000000000",
    //     "0"
    //   );
    // console.log('web3EthContract', web3EthContract);

    this.handleOpenConfirmModal(e);
    const that = this;

    // send a value only when swapping from ETH
    const value = sourceToken.symbol === "ETH" ? sourceAmount : "0";

    // esiimate gas limit
    // equal to the converted rate - minReturn
    let gasLimit = "800000";

    // DEBUG - estimate gas limit
    // web3EthContract.estimateGas({
    //   from: accounts[0],
    //   gas: "500000",
    //   value: sourceAmountInWei
    // }).then(function(gasAmount){
    //   gasLimit = gasAmount;
    //   console.log('gasLimit', gasLimit)
    // }).catch(function(error){
    //   console.log('gasLimit', gasLimit)
    //   // console.log(error);
    // });

    let sendObject = {};
    // only send the value when source token is ETH
    if (sourceToken.symbol === "ETH") {
      sendObject = {
        from: accounts[0],
        value: this.web3.utils.toWei(value, "ether"),
        gas: gasLimit
      };
    } else {
      sendObject = {
        from: accounts[0],
        gas: gasLimit,
        // TODO
        // handleRevert: true (available until web3 1.2.9)
      };
    }
    // console.log('sendObject', sendObject);

    web3EthContract
      .send(sendObject)
      .on("transactionHash", function (hash) {
        that.handleCloseConfirmModal(e);
        toastr.info(`In Progress... ${that.state.tradeMessage}`, "Swap");
        that.setState({ sourceAmount: "", destinationAmount: 0 });
      })
      .on("confirmation", function (confirmationNumber, receipt) {
        // console.log(confirmationNumber, receipt);
      })
      .on("receipt", function (receipt) {
        toastr.clear();
        toastr.success(`${that.state.tradeMessage}`, "Swap");
        that.setState({ tradeInProgress: false });
      })
      .on("error", function (error, receipt) {
        toastr.clear();
        that.handleCloseConfirmModal(e);

        that.setState({
          errorMessage: error.message,
          showErrorModal: true,
          tradeInProgress: false
        });
      });
  };

  renderTradeView = () => {
    const {
      isMetaMaskInstalled,
      isMetaMaskConnected,
      sourceToken,
      destinationToken,
      mode,
      sourceAmount,
      destinationAmount,
      rate,
      metamaskAccount,
      sourceTokenBalance,
      isMainnet,
      destinationTokenBalance,
      tradeInProgress,
      openConfirmTradeModal,
      showConfirmationModal
    } = this.state;

    switch (mode) {
      case "connect":
        return (
          <ConnectMetamask
            isMetaMaskInstalled={isMetaMaskInstalled}
            isMetaMaskConnected={isMetaMaskConnected}
            onClickInstallMetamask={this.onClickInstallMetamask}
            onClickConnectMetamask={this.onClickConnectMetamask}
            sourceToken={sourceToken}
            destinationToken={destinationToken}
            setToken={this.setToken}
            rate={rate}
            tradeFromAllTokens={this.state.tradeFromAllTokens}
            isMainnet={isMainnet}
            metamaskAccount={metamaskAccount}
            tradeInProgress={tradeInProgress}
            sourceAmount={sourceAmount}
            destinationAmount={destinationAmount}
            sourceTokenBalance={sourceTokenBalance}
            destinationTokenBalance={destinationTokenBalance}
            loginRequired={this.props.loginRequired}
            handleSourceAmountChange={this.handleSourceAmountChange}
            exchangeToken={this.exchangeToken}
            trade={this.trade}
            openConfirmTradeModal={openConfirmTradeModal}
            showConfirmationModal={showConfirmationModal}
            handleOpenConfirmTradeModal={this.handleOpenConfirmTradeModal}
            handleCloseConfirmTradeModal={this.handleCloseConfirmTradeModal}
            handleOpenConfirmModal={this.handleOpenConfirmModal}
            handleCloseConfirmModal={this.handleCloseConfirmModal}
            getTokenBalance={this.getTokenBalance}
          />
        );
        break;
      default:
        return <div />;
    }
  };

  render() {
    const {
      isMainnet,
      isMetaMaskConnected,
      errorMessage,
      showErrorModal
    } = this.state;

    return (
      <div className="trade-container">
        {isMetaMaskConnected && !isMainnet && (
          <div
            className="alert alert-warning"
            role="alert"
            style={{ textAlign: "center" }}
          >
            You are not on the mainnet. To proceed, go to metamask and switch to Ethereum Mainnet
          </div>
        )}
        {this.renderTradeView()}

        <ErrorModal
          errorMessage={errorMessage}
          showErrorModal={showErrorModal}
          handleCloseErrorModal={this.handleCloseErrorModal}
        />
      </div>
    );
  }
}

Trade.defaultProps = {
  loginRequired: false
};
export default Trade;
