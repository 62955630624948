import React, { useState } from "react";
import Cards from "react-credit-cards-2";
import "./index.scss";
import "react-credit-cards-2/dist/es/styles-compiled.css";

const DebitCard = ({ currentUser, cardId, canActivateCard }) => {
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState("");

  const closeModal = (e) => {
    e.stopPropagation();
    setMessage("");
    setShowModal(false);
  };

  const handleLinkClick = (e) => {
    e.preventDefault();
    const action = e.target.innerText;
    setMessage(`'${action}' feature is coming soon!`);
  };

  return (
    <div className="debit-card-container kai-os-hide">
      <div className="debit-card-wrapper">
        <Cards
          number="0000000000000000"
          expiry="12/27"
          cvc="000"
          name={currentUser}
        />
      </div>

      {showModal && (
        <div className="cmodal fade show" tabIndex="-1" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content pl-2 pr-2">
              <div className="modal-header">
                <h5 className="modal-title">Feature Coming Soon</h5>
                <button type="button" className="close" onClick={closeModal}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div
                  className="mb-2"
                  style={{
                    background: "#f79237",
                    color: "white",
                    fontSize: "1rem",
                  }}
                >
                  <span>This feature is currently in development.</span>
                </div>
                <ul>
                  <li>
                    <a href="/cards/requests/new">Apply for Card</a>
                  </li>
                  <li>
                    <a href="#" onClick={handleLinkClick}>
                      Activate Card
                    </a>
                  </li>
                  <li>
                    <a href="#" onClick={handleLinkClick}>
                      View Card Information
                    </a>
                  </li>
                </ul>
                <div>{message}</div>
              </div>
              <div className="modal-footer border-0 pl-0 pr-0">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={closeModal}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={closeModal}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DebitCard;
