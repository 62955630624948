import React, { Component, useState, useEffect } from "react";
import _ from "underscore";
import { getRequest, postRequest, patchRequest } from "../../utils/httpRequest";
import {
  validateWalletAddress,
  validateWalletAddressTag
} from "../../model/walletAddress";
import { formErrors } from "../../utils/formErrors";
import AddFundsModal from "./AddFundsModal";
import BCLoader from "../P2PTrades/Loaders/bcloader";

const transferTypes = [
  {
    label: "Recipient's Wallet",
    value: "recipient"
  },
  {
    label: "My Wallet",
    value: "my-wallet"
  }
];

class CryptoTransferPayMe extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuth: props.isAuth || false,
      selectedSourceWallet: props.selectedSourceWallet,
      selectedDestinationWallet: "",
      destinationWallets: "",
      selectedCurrency: props.selectedSourceToken,
      selectedCurrencyBalance: props.selectedSourceCurrencyBalance,
      alternateCurrency: props.alternateCurrency,
      alternateCurrencyBalance: parseFloat(
        props.alternateCurrencyBalance
      ).toFixed(2),
      alternateCurrencyRate: parseFloat(props.alternateCurrencyRate).toFixed(2),
      balances: props.balances,
      selectedTransferType: "recipient",
      recipient: props.recipient || "",
      storeName: props.storeName || "",
      storeReturnLink: props.storeReturnLink || "",
      amount: parseFloat(props.amount).toFixed(2) || parseFloat(0.0).toFixed(2),
      recipientError: "",
      error: "",
      amountError: "",
      mode: "form",
      invalidTransfer: false,
      submitting: false,
      validationErrors: {},
      fees: 0.0,
      blockchainAddress: "",
      blockchainAddressTag: "",
      walletAddresses: props.walletAddresses || [],
      submitTransfer: false,
      selectedWalletAddress: "",
      note: props.note || "",
      heading: props.heading || "PAY",
      senderRef: props.senderRef || "",
      senderData: props.senderData || "",
      bid: props.bid || "",
      isPayButton: props.isPayButton || false,
      isWpPlugin: props.isWpPlugin || false,
      orderId: props.orderId || "",
      partnerId: props.partnerId || "",
      currency: props.currency || "USD",
      currenciesList: [],
      usdAmount: "",
      balanceInCurrency: "",
    };
  }

  componentDidMount() {
    this.getCurrencies();
}

  addFundsPage = (linkText) => {
    return <a href="/add_funds" target="_blank" className="paymepage-error-addfundstext">
    {linkText}➚
  </a>
  }
  
  addFundsButton = (linkText) => {
    return <div>
                <div>                
                  <a
                    href="/add_funds"
                    target="_blank"
                    role="button"
                    className="paymepage-paidbtn"
                  >{linkText}
                  </a>  
                </div>
              </div>
  }

  backToMerchantLink = (storeName, storeReturnLink) => {
    return <a href={storeReturnLink} className="storeReturnLink">
      <i class="fas fa-undo-alt"></i>
      &nbsp;
      <span>Return back to <strong>{storeName}</strong></span>
    </a>
  }

  showModal = () => {
    $("#addFundsModal").modal("show");
  };
  
  closeModal = () => {
    $("#addFundsModal").modal("hide");
  };
  
  AddFundsModalLink = (modalLinkText) => {
    return <div className="row mx-auto mt-4">
              <div className="col-md-12">
                <center>
                <a
                  href="#"
                  role="button"
                  className="btn btn-primary ml-2 mr-2"
                  data-toggle="modal"
                  onClick={this.showModal}
                >{modalLinkText}
                </a>
                </center>
              </div>
            </div>
  }

  getChainWalletAddress = chain => {
    getRequest(`/crypto_transfers/chain_wallet_addresses?chain=${chain}`).then(
      addresses => {
        const walletAddresses = addresses.map(address => {
          return {
            label: address.wallet_label,
            address: address.address,
            addressTag: address.tag,
            value: address.id
          };
        });

        this.setState({ walletAddresses });
      }
    );
  };

  getUsdEquivalent = () => {
    const { currenciesList } = this.state;
    let usdEquivalentRate;

    // Find the currency object from currenciesList that matches the currency code
    const selectedCurrency = currenciesList.find((curr) => curr.value === this.state.currency);
    if (selectedCurrency) {
      // Check the offer type and determine the rate
      if (selectedCurrency.sellRate) {
        usdEquivalentRate = selectedCurrency.sellRate;
      } else if (selectedCurrency.buyRate) {
        usdEquivalentRate = selectedCurrency.buyRate;
      } else if (selectedCurrency.value === 'USD') {
        usdEquivalentRate = '1';
      } else {
        // Fallback to rateToUsd if no sell/buy rate is found
        usdEquivalentRate = selectedCurrency.rateToUsd;
      }
    }

    const resultUsdAmount = Number(this.state.amount) / Number(usdEquivalentRate);
    const resultBalanceInCurrency = Number(this.state.selectedCurrencyBalance) * Number(usdEquivalentRate);

    this.setState({
      usdAmount: resultUsdAmount.toFixed(2),
      balanceInCurrency: resultBalanceInCurrency.toFixed(2) 
     });
     if (resultUsdAmount  >  resultBalanceInCurrency && this.state.isAuth) {
      this.showModal();
    }
  }

  handleChange = (event, key) => {
    this.setState({ [key]: event.target.value });

    if (key === "selectedTransferType") {
      this.setState({ invalidTransfer: false });
    }
  };

  handleRecipientChange = e => {
    const { validationErrors } = this.state;
    validationErrors.recipientError = "";

    this.setState({ recipient: e.target.value, recipientError: "" }, () =>
      this.lookupUser()
    );
  };

  lookupUser = () => {
    // console.log("recipient", this.state.recipient);
    const { validationErrors } = this.state;

    if (this.state.recipient) {
      const data = {
        crypto_transfer: {
          recipient: this.state.recipient
        }
      };

      const crsfToken = $('meta[name="csrf-token"]').attr("content");

      postRequest("/crypto_transfers/lookup_recipient", data, {
        "X-CSRF-Token": crsfToken
      })
        .then(response => {
          validationErrors.recipientError = "";
          this.setState({
            validationErrors,
            invalidTransfer: false
          });
        })
        .catch(error => {
          validationErrors.recipientError = error.data.error;

          this.setState({
            validationErrors,
            invalidTransfer: true
          });
        });
    }
  };

  handleAmountChange = e => {
    this.setState({ amount: e.target.value },
      () => {
      this.validateAmount();
      this.getUsdEquivalent()});
  };

  handleBlockchainAddressChange = e => {
    this.setState(
      {
        blockchainAddress: e.target.value
      },
      () => this.validateBlockchainAddress()
    );
    this.validateBlockchainAddressTag();
  };

  handleBlockchainAddressTagChange = e => {
    this.setState({ blockchainAddressTag: e.target.value }, () =>
      this.validateBlockchainAddressTag()
    );
    this.validateBlockchainAddress();
  };

  handleSelectedWalletAddress = e => {
    this.setState({ selectedWalletAddress: e.target.value }, () =>
      this.setBlockchainAddress(e.target.value)
    );
    this.validateBlockchainAddress();
  };

  handleNoteChange = e => {
    this.setState({ note: e.target.value });
  };

  handleOrderIdChange = e => {
    this.setState({ orderId: e.target.value });
  };

  handleSenderRefChange = e => {
    this.setState({ senderRef: e.target.value });
  };

  setBlockchainAddress = val => {
    const { walletAddresses, validationErrors } = this.state;

    const walletAddress = _.find(walletAddresses, function(address) {
      return address.value === val;
    });

    if (undefined === walletAddress) {
      this.setState({ blockchainAddress: "" });
      this.setState({ blockchainAddressTag: "" });
      this.setState({ validationErrors: {} });
    } else {
      this.setState({ blockchainAddress: walletAddress.address });
      this.setState({ blockchainAddressTag: walletAddress.addressTag });
    }
  };

  validateBlockchainAddress = () => {
    const {
      selectedCurrency,
      blockchainAddress,
      blockchainAddressTag,
      validationErrors
    } = this.state;
    const currency = selectedCurrency.toUpperCase();

    const chain = ["BTC", "AVAX", "XLM"].includes(currency) ? currency : "ETH";

    const validAddress = validateWalletAddress(chain, blockchainAddress);

    if (validAddress) {
      validationErrors.blockchainAddressError = "";
    } else {
      validationErrors.blockchainAddressError = "Address is invalid";
    }

    this.setState({ validationErrors });
  };

  validateBlockchainAddressTag = () => {
    const {
      selectedCurrency,
      blockchainAddress,
      blockchainAddressTag,
      validationErrors
    } = this.state;
    const currency = selectedCurrency.toUpperCase();

    const chain = ["BTC", "AVAX", "XLM"].includes(currency) ? currency : "ETH";

    const validAddressTag = validateWalletAddressTag(
      chain,
      blockchainAddressTag
    );

    if (validAddressTag) {
      validationErrors.blockchainAddressTagError = "";
    } else {
      validationErrors.blockchainAddressTagError =
        "Address tag/memo is missing or not required";
    }

    this.setState({ validationErrors });
  };

  validateAmount = () => {
    const { validationErrors } = this.state;
    this.setState({
      error: ""
    });

    if (Number(this.state.amount) < 0.01) {
      validationErrors.amountError =
        "Amount to transfer needs to be greater than or equal to 0.01. Please enter a higher amount.";

      this.setState({
        validationErrors,
        invalidTransfer: true
      });
    } else if (
      Number(this.state.amount) > Number(this.state.selectedCurrencyBalance) && (this.state.isAuth)
    ) {
      validationErrors.amountError = <>
        Amount to pay or transfer is greater than your available balance, please {this.addFundsPage('fund your account')}
        <br/>
        {this.addFundsButton('Add Funds')}
        </>;

      this.setState({
        validationErrors,
        invalidTransfer: true
      });
    } else {
      validationErrors.amountError = "";
      this.setState({
        validationErrors,
        invalidTransfer: false
      });
    }
  };

  validateFormFields = () => {
    const {
      amount,
      recipient,
      selectedTransferType,
      blockchainAddress
    } = this.state;
    const { type } = this.props;

    let validationErrors = {};

    if (Number(amount) <= 0) {
      validationErrors.amountError = "amount should be greater than zero";
    }

    if (
      type === "wallet" &&
      selectedTransferType === "recipient" &&
      !recipient
    ) {
      validationErrors.recipientError = "recipient is required";
    }

    if (type === "blockchain" && !blockchainAddress) {
      validationErrors.blockchainAddressError =
        "blockchain address is required";
    }

    return validationErrors;
  };

  refreshPage = e => {
    e.preventDefault();
    const url = window.location.href;
    window.location.assign(url);
  };

  setApiErrors = errors => {
    const formattedErrors = formErrors(errors);

    const { validationErrors } = this.state;

    const amountError = formattedErrors["gross_amount"];
    const recipientError = formattedErrors["destination_wallet_id"];

    validationErrors.amountError = amountError;
    validationErrors.recipientError = recipientError;

    return validationErrors;
  };

  submitTransferPreview = e => {
    const {
      selectedSourceWallet,
      selectedDestinationWallet,
      destinationWallets,
      recipient,
      usdAmount,
      amount,
      fees,
      selectedCurrency,
      selectedTransferType,
      blockchainAddress,
      blockchainAddressTag,
      note,
      senderRef,
      senderData,
      bid,
      selectedCurrencyBalance,
      orderId,
      partnerId,
    } = this.state;

    // console.log(this.state);
    e.preventDefault();

    const validationErrors = this.validateFormFields();

    if (!_.isEmpty(validationErrors)) {
      this.setState({ validationErrors, invalidTransfer: true });

      return;
    }

    this.setState({ submitting: true, error: "", validationErrors: {} });

    const data = {
      crypto_transfer: {
        destination_type: "wallet",
        total_type: "total_to_withdraw",
        source_wallet_id: selectedSourceWallet,
        recipient: selectedTransferType === "recipient" ? recipient : "",
        destination_wallet_id: selectedDestinationWallet,
        gross_amount: usdAmount,
        gross_amount_currency: "USDT",
        fees: 0.0,
        fees_currency: "USDT",
        net_amount: usdAmount,
        net_amount_currency: "USDT",
        source_currency: "USDT",
        destination_currency: "USDT",
        destination_type: this.props.type,
        blockchain_address: blockchainAddress,
        blockchain_address_tag: blockchainAddressTag,
        note: note,
        sender_ref: senderRef,
        sender_data: senderData,
        bid: bid,
        selected_currency_balance: selectedCurrencyBalance,
        order_id: orderId,
        partner_id: partnerId,
        type: "CryptoTransfers::Payme",
      }
    };

    const crsfToken = $('meta[name="csrf-token"]').attr("content");

    postRequest("/crypto_transfers/send_crypto", data, {
      "X-CSRF-Token": crsfToken
    })
      .then(response => {
        this.setState({
          fees: response.fees,
          submitTransfer: true,
          transferId: response.transfer_id
        });
      })
      .catch(error => {
        console.log(error)
      })
  };

  getCurrencies = () => {
    getRequest("/api/v1/p2p_trades/currencies")
      .then((response) => {
        const currencies = response.map((currency) => ({
          label: `${currency.name} (${currency.code})`,
          value: currency.code,
          rateToUsd: parseFloat(currency?.rateToUsd) || 0, // Ensure it's a float or default to 0
          overrideRate: null,
          sellRate: parseFloat(currency?.sellRate) || 0,   // Default to 0 if null/undefined
          buyRate: parseFloat(currency?.buyRate) || 0,     // Default to 0 if null/undefined
        }));
        this.setState({
          currenciesList: currencies,
        });
        this.getUsdEquivalent();
      })
      .catch((error) => {
        console.error("Failed to fetch currencies:", error);
        // Handle error state if needed
      });
  };
  
  submitTransfer = e => {
    const { transferId } = this.state;

    e.preventDefault();

    getRequest(`/crypto_transfers/${transferId}/resend_confirmation`).then(
      () => {
        window.location = `/crypto_transfers/${transferId}`;
        // window.location = `/wallets/digital_dollars?confirm=${transferId}`;
      }
    );
  };

  cancelTransfer = e => {
    const { transferId } = this.state;

    e.preventDefault();

    const data = {
      crypto_transfer: {
        id: transferId
      }
    };

    const crsfToken = $('meta[name="csrf-token"]').attr("content");

    patchRequest(`/crypto_transfers/${transferId}/cancel`, data, {
      "X-CSRF-Token": crsfToken
    })
      .then(() => {
        // window.location = `/crypto_transfers/`;
        this.refreshPage(e);
        // window.location = `/crypto_transfers/new_blockchain_to_usd_transfer?token=${selectedCurrency}`;
      })
      .catch(error => {
        this.setState({ submitting: false });
        console.log(error);
      });
  };

  redirectToLogin = e => {
    e.preventDefault();
    let currentPath = `${location.protocol}//${window.location.host}${window.location.pathname}${window.location.search}`;
    // console.log('currentPath', currentPath)
    window.location = `/users/sign_in?redirect_to=${currentPath}`;
  };

  render() {
    const { sourceWallets, currencies, type } = this.props;

    const {
      isAuth,
      isStore,
      amount,
      selectedSourceWallet,
      selectedDestinationWallet,
      destinationWallets,
      selectedCurrency,
      selectedCurrencyBalance,
      alternateCurrency,
      alternateCurrencyBalance,
      alternateCurrencyRate,
      selectedTransferType,
      recipient,
      recipientError,
      storeName,
      storeReturnLink,
      mode,
      error,
      amountError,
      invalidTransfer,
      submitting,
      validationErrors,
      fees,
      submitTransfer,
      transferId,
      blockchainAddress,
      blockchainAddressTag,
      walletAddresses,
      balances,
      selectedWalletAddress,
      note,
      heading,
      senderRef,
      bid,
      orderId,
      isPayButton,
      currency,
      currenciesList,
      usdAmount,
      balanceInCurrency,
      isWpPlugin
    } = this.state;

    // console.log('currencies', currencies);

    return (
      <div className="paymepage-detailsection-cont">
      {!this.state.usdAmount ?  (
      <div>
        <BCLoader />
      </div>) : (
      <div className="paymepage-details-cont">
        <form 
          className="paymepage-form"
          id="new_crypto_transfer"
        >
          <div>  
            {isAuth && (
              <div className="paymepage-account-balance">
                <span className="text-muted">
                  Your Account Balance: {" "}
                  <strong>
                    {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                        maximumFractionDigits: 2,
                      }).format(parseFloat(selectedCurrencyBalance))}
                    {currency != "USD" && (
                      <span>
                        {" "}
                        
                        / {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: currency,
                        maximumFractionDigits: 2,
                      }).format(parseFloat(this.state.balanceInCurrency))}
                      </span>
                    )}
                  </strong>
                </span>
              </div>
            )}
          </div>

          <div>
              {/* Enter total transfer amount desired (USD) {selectedCurrency}. */}
              {error && (
              <p className="createoffer-error-alert">
                {error}
              </p>
            )}
            {validationErrors?.amountError && (
              <p className="createoffer-error-alert">
                {validationErrors.amountError}
              </p>
            )}
            {validationErrors?.recipientError && (
              <p className="createoffer-error-alert">
                {validationErrors.recipientError}
               </p>
              )}
            </div>

          <div className="paymepage-form">
            {/* Order Total */}
            {!isAuth && ( 
            <div className="paymepage-details-section required ">
              <p className="paymepage-small-label-section">
                <p>{isWpPlugin ? "Order Total:" : "Amount:"}
                  <span style={{color: "#f69137"}}>*</span>
                </p>
              </p>
              <div className="paymepage-input-cont">
                <div className="paymepage-input-section">
                  {isWpPlugin || isPayButton ? (
                    <input
                    step="any"
                    autoFocus="autofocus"
                    placeholder="0.000"
                    className={`paymepage-paymentcurrency ${
                      validationErrors?.amountError ? "is-invalid" : ""
                    } ${
                      isPayButton || isWpPlugin ? "" : ""
                    }`}
                    required="required"
                    type="text"
                    value={new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: currency,
                      maximumFractionDigits: 2,
                    }).format(parseFloat(amount))}
                    onChange={this.handleAmountChange}
                    id="amount"
                    disabled={
                      submitting == true
                        ? true
                        : false || isPayButton == true || isWpPlugin == true
                        ? true
                        : false
                    }
                  />) :
                  (
                    <div className="paymepage-totalamount-editable">
                      <input
                        step="any"
                        autoFocus="autofocus"
                        placeholder="0.000"
                        className={`paymepage-paymentcurrency ${
                          validationErrors?.amountError ? "is-invalid" : ""
                        } ${
                          isPayButton || isWpPlugin ? "" : ""
                        }`}
                        required="required"
                        type="number"
                        value={amount}
                        onChange={this.handleAmountChange}
                        id="amount"
                        disabled={submitting || isPayButton || isWpPlugin}
                      />
                      <span>{currency}</span>
                    </div>         
                  )}
                </div>
                <p className="paymepage-input-section">
                  In USD:
                  {" "}
                  {this.state.usdAmount ? 
                  new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                    maximumFractionDigits: 2,
                  }).format(parseFloat(this.state.usdAmount)) : 
                  (<i class="fas fa-sync-alt fa-spin"></i>)}
                </p>
              </div>
            </div>)}

            <div className="selectWallet">
              {type === "wallet" && (
                <div>
                  {destinationWallets.length > 0 && (
                    <div className="form-group row required mt-2 ">
                      <label className="form-label">
                        Select the crypto wallet
                      </label>
                      <select
                        className="form-control form-control-lg expand-select"
                        required="required"
                        value={selectedTransferType}
                        disabled={submitting == true ? true : false}
                        onChange={e =>
                          this.handleChange(e, "selectedTransferType")
                        }
                      >
                        <option value="">-- Select the Wallet ---</option>
                        {transferTypes.map((transferType, i) => (
                          <option key={i} value={transferType.value}>
                            {transferType.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  {((destinationWallets.length === 0 && recipient === "") ||
                    (selectedTransferType === "recipient" &&
                      recipient === "")) && (
                    <div className="paymepage-isAuth-order recipient">
                      <label className="paymepage-small-label-section">To:
                        <sup style={{color: "red"}}>*</sup>
                      </label>
                      <div className="paymepage-input-section">
                        <input
                          step="any"
                          autoFocus="autofocus"
                          className={`form-control ${
                            validationErrors?.recipientError ? "is-invalid" : ""
                          }`}
                          placeholder="Enter your recipient's username or email"
                          type="text"
                          value={recipient}
                          disabled={
                            submitting == true
                              ? true
                              : false || isPayButton == true || isWpPlugin == true
                              ? true
                              : false
                          }
                          onChange={_.debounce(this.handleRecipientChange, 1000, {
                            leading: true
                          })}
                        />
                        <small
                          id="amountHelpBlock"
                          className="form-text text-muted"
                        ></small>
                      </div>
                    </div>
                  )}
                  {selectedTransferType === "my-wallet" && (
                    <div className="form-group row required destination_wallet">
                      <label className="form-label">Destination wallet</label>
                      <select
                        className="form-control form-control-lg expand-select"
                        value={selectedDestinationWallet}
                        onChange={e =>
                          this.handleChange(e, "selectedDestinationWallet")
                        }
                      >
                        {destinationWallets.map((wallet, i) => (
                          <option key={i} value={wallet[1]}>
                            {wallet[0]}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                </div>
              )}
            </div>
            {/* { display for authenticated users } */}
            {isAuth && (
              <div className="isAuth">
                <div
                  className="paymepage-isAuth-order"
                  style={{ display: isPayButton ? "none" : "flex" }}
                >
                  <p className="paymepage-small-label-section">Order ID:</p>
                  <div className="paymepage-input-cont">
                    <div className="paymepage-input-section">
                      <input
                        className={
                          submitting == true || isWpPlugin == true
                            ? "paymepage-paymentcurrency"
                            : "paymepage-orderid-editable"
                        }
                        placeholder={submitting == true ? orderId : ""}
                        type="text"
                        onChange={this.handleOrderIdChange}
                        id="orderId"
                        value={orderId}
                        disabled={
                          submitting == true
                            ? true
                            : false || isPayButton == true || isWpPlugin == true
                            ? true
                            : false
                        }
                      />
                    </div>
                    <small
                      id="orderIdHelpBlock"
                      className="paymepage-orderid-notice"
                      style={{
                        display: isPayButton || isWpPlugin ? "none" : "block"
                      }}
                    >
                      <p>{" "}</p>
                    </small>
                  </div>
                </div>

                <div className="paymepage-order-description">
                  <p className="paymepage-small-label-section">
                    {isWpPlugin ? "Order Description:" : "Description:"}
                  </p>
                  <div className="paymepage-input-section">
                  {isPayButton || isWpPlugin ? 
                  (<small>
                    {note}
                  </small>) :

                  (<textarea
                    className={
                      submitting == true ||
                      isPayButton == true ||
                      isWpPlugin == true
                        ? "paymepage-order-textarea"
                        : "paymepage-order-textarea-editable"
                    }
                    style={{ display: isWpPlugin ? "none" : "flex" }}
                    placeholder={submitting == true ? note : ""}
                    type="text"
                    onChange={this.handleNoteChange}
                    id="note"
                    value={note}
                    disabled={
                      submitting == true
                        ? true
                        : false || isPayButton == true || isWpPlugin == true
                        ? true
                        : false
                    }
                  />)}
                  
                  
                  <div
                    className="pr-2 pl-2 border-0"
                    style={{
                      display: isWpPlugin ? "paymepage-order-textarea " : "none",
                      whiteSpace: "pre-wrap"
                    }}
                  >
                    <small
                    id="noteHelpBlock"
                    className="form-text text-muted"
                    style={{
                      display: isPayButton || isWpPlugin ? "none" : "block"
                    }}
                    >
                    Enter your own description or message to your recipient
                    (optional)
                    </small>
                    </div>
                  </div>
                </div>

                <div>
                <div
                  className="paymepage-comments-section"
                  style={{ display: isPayButton ? "none" : "flex" }}
                >
                  <label className="paymepage-small-label-section">
                    {isWpPlugin == true ? "Comments:" : "Reference:"}
                  </label>
                  <input
                    className={
                      submitting == true || isWpPlugin == true
                        ? "paymepage-order-comments"
                        : "paymepage-order-comments-editable"
                    }
                    placeholder={submitting == true ? senderRef : ""}
                    type="text"
                    onChange={this.handleSenderRefChange}
                    id="senderRef"
                    value={senderRef}
                    disabled={
                      submitting == true
                        ? true
                        : false || isPayButton == true || isWpPlugin == true
                        ? true
                        : false
                    }
                  />
                </div>
                <small
                    id="refHelpBlock"
                    className="form-text text-muted"
                    style={{
                      display: isPayButton || isWpPlugin ? "none" : "block"
                    }}
                  >
                    {" "}
                  </small>
                </div>

                <div className="preview-wrapper mt-1 pt-1">
                  <hr className="paymepage-hr" />
                  <div className="pl-0 preview-list mt-1 pt-1">
                    
                    <div className="paymepage-details-section">
                      <p className="paymepage-small-label-section">
                         <i className="fa fa-info-circle"></i>
                         {" "}
                          <span>Total Amount:
                            <span style={{color: "#f69137"}}>*</span>
                          </span>
                        <a
                          data-toggle="tooltip"
                          className="btn btn-link"
                          data-original-title="Total Amount to Send"
                        >
                        </a>
                      </p>
                      <div className="paymepage-input-cont">
                        {isPayButton || isWpPlugin ? (
                          <input
                          className="paymepage-paymentcurrency "
                          placeholder="0.00"
                          data-target="cryptoTransfer.outputNetAmount"
                          required="required"
                          readOnly="readonly"
                          type="text"
                          value={new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: currency,
                            maximumFractionDigits: 2,
                          }).format(parseFloat(amount))}
                        />) 
                        : (
                      <div className="paymepage-input-section">
                        <div className="paymepage-totalamount-editable">
                        <input
                          step="any"
                          autoFocus="autofocus"
                          placeholder="0.000"
                          className={`paymepage-paymentcurrency ${
                            validationErrors?.amountError ? "is-invalid" : ""
                          } ${
                            isPayButton || isWpPlugin ? "" : ""
                          }`}
                          required="required"
                          type="number"
                          value={amount}
                          onChange={this.handleAmountChange}
                          id="amount"
                          disabled={submitting || isPayButton || isWpPlugin}
                        />
                        <span>{currency}</span>
                        </div>
                        
                        </div>
                        )}
                        {currency !== "USD" && (<p className="paymepage-input-section">
                          IN USD: {" "}
                          {this.state.usdAmount ? 
                          new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                            maximumFractionDigits: 2,
                          }).format(parseFloat(this.state.usdAmount)) : 
                          (<i class="fas fa-sync-alt fa-spin"></i>)}
                        </p>)}
                      </div> 
                      <small
                        id="netAmountHelpBlock"
                        className="form-text text-muted"
                      ></small>
                    </div>
                  </div>
                </div>
                <div className="paymepage-notices">
                  {submitting ? (
                    <div className="paymepage-notices-text">
                      <i className="fa fa-exclamation-circle"></i>{" "}
                      <strong>Valid for the next 10 min</strong>. If your
                      transaction expires, simply start again. Click Submit to
                      continue.
                    </div>
                  ) : (
                    <div className="paymepage-notices-text">
                      <small>
                      <i className="fa fa-exclamation-circle"></i> 
                      {" "}
                      Click Preview
                      to preview your transaction.
                      </small>
                    </div>
                  )}
                </div>
                <hr className="paymepage-hr" />
                <div className="form-actions">
                  {submitTransfer && transferId ? (
                    <div className="paymepage-buttons-section">
                      <div className="col-md-6 float-md-left pl-0">
                        <button
                          name="cancel"
                          type="submit"
                          className="paymepage-cancelbtn"
                          onClick={this.cancelTransfer}
                          disabled={invalidTransfer}
                        >
                          Cancel
                        </button>
                      </div>
                      <div className="col-md-6 float-md-right pr-0">
                        <button
                          name="button"
                          type="submit"
                          className="paymepage-paidbtn"
                          onClick={this.submitTransfer}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="paymepage-buttons-section">
                      <div>
                        <button
                          name="start_again"
                          type="submit"
                          className="paymepage-cancelbtn"
                          onClick={this.refreshPage}
                        >
                          Start Again
                        </button>
                      </div>
                      <div>
                        <button
                          name="button"
                          type="submit"
                          className="paymepage-paidbtn"
                          onClick={this.submitTransferPreview}
                          disabled={invalidTransfer || submitting}
                        >
                          {submitting ? (
                            <>
                              <i className="fa fa-circle-o-notch fa-spin"></i>
                              <span style={{ paddingLeft: "5px" }}>
                                Loading...
                              </span>
                            </>
                          ) : (
                            <span>Preview</span>
                          )}
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}

            {isAuth === false && (
              <div className="paymepage-login-section text-center">
                <br/><br/>
                <hr className="paymepage-hr" />
                <br/><br/>
                <div className="col-md-12 paymepage-account-balance">
                  <strong>
                    Or{" "}
                    <a href="/users/sign_up/" target="blank" className="paymepage-error-addfundstext">
                      create and fund an account
                    </a>{" "}
                    with BananaCrystal
                  </strong>
                </div>

                <div className="row mr-auto">
                  <div className="col-md-12">
                    <center className="pt-2">
                      <button
                        name="button"
                        type="submit"
                        className="paymepage-paidbtn"
                        onClick={this.redirectToLogin}
                      >
                        <i class="fas fa-sign-in-alt"></i>
                        {" "}&nbsp;
                        <span> LOGIN </span>
                      </button>
                    </center>
                  </div>
                </div>
              </div>
            )}
          </div>
        </form>
        <br />
        { storeReturnLink !== "" && (
          <div className="mx-auto mt-2 mb-4" style={{ maxWidth: 380 }}>
            <div className="text-center">
              {this.backToMerchantLink(storeName, storeReturnLink)}
            </div>
          </div>
        )}
        <AddFundsModal
          closeModal={this.closeModal}
        />
      </div> )}    
      </div> 
    );
  }
}

export default CryptoTransferPayMe;
