import React from "react";
const bcloader = require("../../../../assets/images/bcloader.gif");

const BCLoader = () => {
  return (
    <div id="bcloader-cont">
      <div className="bcloader-section">
        <p className="bc-loader">
          <img src={bcloader} alt="loader"/>
        </p>
      </div>
    </div>
  )
}

export default BCLoader;
