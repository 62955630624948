import { forEach } from "underscore";

export const urlRegex = new RegExp(
  "^(https?:\\/\\/)?" + // protocol
  "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
  "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
  "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
  "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
    "(\\#[-a-z\\d_]*)?$",
  "i"
); // fragment locator

const bananacrystal_names = ["BananaCrystal", "Banana Crystal"];

export const required = value => {
  if (!value) {
    return "Required";
  }
};

export const url = value => {
  if (!value) {
    return "Required";
  } else if (!value.match(urlRegex)) {
    return `${value} is an Invalid URL`;
  }
};

export const multipleUrls = value => {
  if (!value) {
    return "Required";
  } else {
    let urls = value.split(" ")
    let invalidUrls=0;
    urls.forEach(url => {
      if (url && !url.match(urlRegex)) {
        invalidUrls++;
      }
    });
    if (invalidUrls > 0) {
      return  'contains and Invalid URL';
    }
  } 
}

export const validateName = value => {
  if (!value) {
    return "Required";
  } else if (bananacrystal_names.includes(value)) {
    return 'Your name cannot contain the word BananaCrystal, we recommend that you use your store or platform name';
  }
}