import React from "react";

const tokenMap = {
  USDT: "USD"
};

const Header = ({
  step = "selectTrade",
  tradeType = "Buy",
  token = "USDT"
}) => (
  <div className="header createoffer-step-progress-bar">
    <div className="createoffer-progress-bar-steps-cont">
    <span className={`createoffer-progress-bar-steps ${step === "selectTrade" || step === "pricing" || step === "settings" ? "createoffer-activestep" : "createoffer-inactivestep"}`}>
      {step === "pricing" || step === "settings" ? (<i class="fa-solid fa-circle-check"></i>) : (<i className="material-icons">adjust</i> )}
        <span>Set Type & Payment Method</span>
      </span>
      <hr className={`createoffer-progress-bar-line ${step === "pricing" || step === "settings" ? "createoffer-activeline" : "createoffer-inactiveline"}`}/>
      <span className={`createoffer-progress-bar-steps ${step === "pricing" || step === "settings" ? "createoffer-activestep" : "createoffer-inactivestep"}`}>
      {step === "settings" ? (<i class="fa-solid fa-circle-check"></i>) : (<i className="material-icons">adjust</i> )}
        <span>Pricing</span>
      </span>
      <hr className={`createoffer-progress-bar-line ${step === "settings" ? "createoffer-activeline" : "createoffer-inactiveline"}`}/>
      <span className={`createoffer-progress-bar-steps ${step === "settings" ? "createoffer-activestep" : "createoffer-inactivestep"}`}>
        <i className="material-icons">adjust</i> Other Settings
      </span>
    </div>
  </div>
);

export default Header;
