import React from "react";
import DatePicker from "react-datepicker";
import premiums from "./premiums.json";

const DefaultForm = ({
  selectedGender,
  dateOfBirth,
  selectedPremium,
  mode,
  submitting,
  nonTerminalIllness,
  validationErrors,
  submit,
  handleChange,
  onChangeDateOfBirth
}) => (
  <form noValidate>
    <div className="form-row">
      <div className="form-group col-md-6 gender required">
        <label className="form-label">Your Gender</label>
        <select
          className="form-control"
          value={selectedGender}
          onChange={e => handleChange(e, "selectedGender")}
        >
          <option value="male">Male</option>
          <option value="female">Female</option>
        </select>
      </div>
      <div className="form-group col-md-6 date-of-birth required">
        <label className="form-label">Your Date of Birth</label>
        <DatePicker
          className={`form-control ${
            validationErrors?.dateOfBirthError ? "is-invalid" : ""
          }`}
          required="required"
          selected={dateOfBirth}
          onChange={e => onChangeDateOfBirth(e)}
          dateFormat="MMMM d, yyyy"
          placeholderText="Enter your date of birth"
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          isClearable={true}
          todayButton="Vandaag"
          placeholderText="Click to select a date"
          minDate={new Date().setFullYear(new Date().getFullYear() - 62)}
          maxDate={new Date().setFullYear(new Date().getFullYear() - 1)}
        />
        {validationErrors?.dateOfBirthError && (
          <div className="error text-danger">
            {validationErrors.dateOfBirthError}
          </div>
        )}
      </div>
    </div>
    <div className="form-group">
      <label className="form-label">Your Planned Premium - Maximum Annual Contribution to the Plan</label>
      <div className="input-group mb-2">
        <div className="input-group-prepend">
          <div className="input-group-text">$</div>
        </div>

        <select
          className="form-control"
          value={selectedPremium}
          onChange={e => handleChange(e, "selectedPremium")}
        >
          {premiums.map((premium, i) => (
            <option key={i} value={premium.value}>
              {premium.label} per year
            </option>
          ))}
        </select>
      </div>
    </div>
    <div className="form-check">
      <input
        type="checkbox"
        className="form-check-input"
        checked={nonTerminalIllness}
        onChange={e => handleChange(e, "nonTerminalIllness")}
      />
      <label className="form-check-label text-muted">
        I have not been diagnosed with a terminal illness
      </label>
    </div>
    {!nonTerminalIllness && (
      <div className="alert alert-warning" role="alert">
        If you have been diagnosed with a terminal illness, you don't qualify
        for this program at this time.
      </div>
    )}
    <div className="action">
      <button
        type="submit"
        className="btn btn-primary"
        disabled={!nonTerminalIllness || submitting}
        onClick={e => submit(e)}
      >
        {submitting && (
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
        )}
        Preview
      </button>
    </div>
  </form>
);

export default DefaultForm;
