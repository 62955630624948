import React, { useState, useEffect } from "react";
import { getRequest, putRequest } from "../../../../utils/httpRequest";
import ReactLoading from "react-loading";
import { validateTotalAmount, getFiatAmount } from "../helpers";
import Filter from 'bad-words';
import "./index.scss";
import Price from "./components/Price";
import Amount from "./components/Amount";
import Limit from "./components/Limit";
import { Offer } from "../Types";
import BCLoader from "../../Loaders/bcloader";

interface Props {
  offerId: string;
  availableBalance: string;
  tradeFees: number;
  canEditAmount: boolean;
}

const Edit: React.FC<Props> = ({
  offerId,
  availableBalance,
  tradeFees,
  canEditAmount,
}) => {
  // @ts-ignore
  const [offer, setOffer] = useState<Offer>({});
  const filter = new Filter();
  const [editCustomOfferLabel, setEditCustomOfferLabel] = useState(true);
  const [isFixedPrice, setIsFixedPrice] = useState(true);
  const [rangeMin, setRangeMin] = useState("10");
  const [rangeMax, setRangeMax] = useState("");
  const [amount, setAmount] = useState("0");
  const [margin, setMargin] = useState("5");
  const [tradeType, setTradeType] = useState(null);
  const [offerTerms, setOfferTerms] = useState(null);
  const [validationMessage, setValidationMessage] = useState("");
  const [hasError, setHasError] = useState(false);
  const [hasExternalRate, setHasExternalRate] = useState(false);
  const [marketrate, setMarketrate] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [submitting, setSubmtting] = useState(false);
  const [maxFieldValidationMessage, setMaxFieldValidationMessage] = useState("")
  const [minFieldValidationMessage, setMinFieldValidationMessage] = useState("")
  const [actualRate, setActualRate] = useState(0);
  const [fiatCurrency, setFiatCurrency] = useState({});
  const [offerTradeRate, setOfferTradeRate] = useState("");
  const [profanityErrorMessage, setProfanityErrorMessage] = useState("");


  const handleMarketChange = (e) => {
    setIsFixedPrice(e.target.value === "fixed_price");
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const getOfferById = () => {
    setIsLoading(true);
    getRequest(`/p2p_trades/offers/${offerId}`).then((response) => {
      setOffer(response);
      setTradeType(response.offerTypeField);
      setIsFixedPrice(response.isFixedPrice);
      setRangeMin(response.rangeMin);
      setRangeMax(response.rangeMax);
      setEditCustomOfferLabel(response.customOfferLabel);
      setMargin(response.margin);
      setOfferTerms(response.offerTerms);
      setAmount(response.amount);
      setFiatCurrency(response.fiatCurrency);
      setOfferTradeRate(Number(response.tradeRate)?.toFixed(2));

      let marketrate = response.fiatCurrency?.rateToUsd;
      const externalRateExists =
        response.fiatCurrency?.externalRates?.length > 0;

      setHasExternalRate(externalRateExists);

      if (externalRateExists && response.offerTypeField === "sell") {
        marketrate = response.fiatCurrency?.externalRates?.[0]?.sellRate;
      }
      if (externalRateExists && response.offerTypeField === "buy") {
        marketrate = response.fiatCurrency?.externalRates?.[0]?.buyRate;
      }

      if (response.fiatCurrency?.sellRate && response.offerTypeField === "sell") {
        setActualRate(response.fiatCurrency?.sellRate);
      } else if (response.fiatCurrency?.buyRate && response.offerTypeField === "buy") {
        setActualRate(response.fiatCurrency?.buyRate);
      } else {
        setActualRate(response.fiatCurrency?.rateToUsd);
      }

      setMarketrate(marketrate);
      setIsLoading(false);
    });
  };

  const updatelimitsValidation = (field) => {
    setMaxFieldValidationMessage("");
    setMinFieldValidationMessage("");
    
    const currentFiatAmountStr = getFiatAmount(marketrate, amount);
    const currentFiatAmount = Number(currentFiatAmountStr);
    const rangeMaxNum = Number(rangeMax);
    const rangeMinNum = Number(rangeMin);

    if (rangeMaxNum > currentFiatAmount) {
      setHasError(true);
      setMaxFieldValidationMessage("Maximum amount cannot exceed your total amount");
      setSubmtting(false);
      return;
    }

    if ((rangeMinNum > rangeMaxNum) && (field === "maxfield")) {
      setHasError(true);
      setMaxFieldValidationMessage("Maximum amount cannot be lesser than the minimum amount");
      setSubmtting(false);
      return;
    }

    if ((rangeMinNum > rangeMaxNum) && (field === "minfield")) {
      setHasError(true);
      setMinFieldValidationMessage("Minimum amount cannot be greater than the maximum amount");
      setSubmtting(false);
      return;
    }

    setHasError(false);
    setValidationMessage("");
    setMaxFieldValidationMessage("");
    setMinFieldValidationMessage("");
  }

  const handleProfanityCheck = () => {
    setProfanityErrorMessage("");
    if (filter.isProfane(offerTerms)) {
      setHasError(true);
      setProfanityErrorMessage(`The Offer Terms field contains inappropriate language. Please revise the terms.`);
      setSubmtting(false);
      return;
    }

    setHasError(false);
    setProfanityErrorMessage("");
  }

  useEffect(() => {
    handleProfanityCheck();
  }, [offerTerms]);

  useEffect(() => {
    updatelimitsValidation("maxfield");
  }, [rangeMax]);
  
  useEffect(() => {
    updatelimitsValidation("minfield");
  }, [rangeMin]);
  

  const updateOffer = () => {
    setSubmtting(true);
    setValidationMessage("");

    if (hasError){
      setSubmtting(false);
      setValidationMessage("Please fix the errors");
      scrollToTop();
      return;
    } else if (parseFloat(rangeMin) < 1 || !rangeMax) {
      setHasError(true);
      setValidationMessage(`Please provide valid range minimum limit`);
      setSubmtting(false);
      scrollToTop();
      return;
    } else if (parseFloat(rangeMax) < 1 || !rangeMax) {
      setHasError(true);
      setValidationMessage(`Please provide valid range maximum limit.`);
      setSubmtting(false);
      scrollToTop();
      return;
    } else if (parseFloat(rangeMin) > parseFloat(rangeMax)) {
      setHasError(true);
      setValidationMessage(`Minimum limit should be less than Maximum limit.`);
      setSubmtting(false);
      scrollToTop();
      return;
    } else if (filter.isProfane(offerTerms)) {
      setHasError(true);
      setValidationMessage(`The Offer Terms field contains inappropriate language. Please revise the terms.`);
      setSubmtting(false);
      scrollToTop();
      return;
    }

    setHasError(false);
    setValidationMessage("");

    putRequest(`/p2p_trades/offers/${offerId}`, {
      offer_id: offerId,
      offer_type_field: tradeType,
      margin: margin,
      amount: parseFloat(amount),
      range_min: parseFloat(rangeMin),
      range_max: parseFloat(rangeMax),
      fixed_price: isFixedPrice
        ? offer.fixedPrice
        : offer.fiatCurrency.rateToUsd,
      is_fixed_price: isFixedPrice,
      offer_terms: offerTerms,
    })
      .then((response) => {
        setSubmtting(false);
        const offerType = offer.offerTypeField === "sell" ? "buy" : "sell";
        window.location.href = `/p2p_trades/offers/my_offers?offer_type=${offerType}`;
      })
      .catch((error) => {
        console.log(error);
        let message;
        if (error.data?.errors?.length > 0) {
          message = error.data?.errors[0]?.error;
        } else if (error.data?.errors?.data?.errors?.length > 0) {
          message = error.data?.errors?.data?.errors[0]?.error;
        } else if (error?.data?.error) {
          message = error?.data?.error;
        }
        if (message) {
          setValidationMessage(message);
          setHasError(true);
        }
        setSubmtting(false);
      });
  };

  const updateAmount = (_fieldName, value) => {
    setAmount(value);
  };

  useEffect(() => {
    if (offerId) {
      getOfferById();
    }
  }, [offerId]);

  return (
    <div className="p2p-trades createoffer-p2p-trades">
      <div>
        <div className="create-offer-title">
          <p className="create-trade-page-title">
            <i className="fa fa-edit"></i>
            Edit Offer {editCustomOfferLabel}
          </p>
        </div>
        <div className="row" data-controller="clipboard">
          {isLoading ? (
            <div>
              <BCLoader />
            </div>
          ) : (
            <>
              <div
                className={`editoffer-info-cont ${tradeType === "sell" ? "editoffer-info-cont-sell" : ""}`}
              >
                {hasError && (
                  <div className="createoffer-error-alert">
                      {validationMessage}
                  </div>
                )}
                <div className="edit-offers">
                  <Price
                    offer={offer}
                    isFixedPrice={isFixedPrice}
                    handleMarketChange={handleMarketChange}
                    tradeType={tradeType}
                  />
                  <Amount
                    amount={amount}
                    tradeType={tradeType}
                    tradeFees={tradeFees}
                    availableBalance={availableBalance}
                    updateAmount={updateAmount}
                    setAmount={setAmount}
                    canEditAmount={canEditAmount}
                  />
                  <Limit
                    offer={offer}
                    amount={amount}
                    isFixedPrice={isFixedPrice}
                    rangeMin={rangeMin}
                    rangeMax={rangeMax}
                    tradeType={tradeType}
                    margin={margin}
                    hasError={hasError}
                    offerTerms={offerTerms}
                    availableBalance={parseFloat(availableBalance)}
                    hasExternalRate={hasExternalRate}
                    validationMessage={validationMessage}
                    marketrate={marketrate}
                    getFiatAmount={getFiatAmount}
                    setRangeMax={setRangeMax}
                    setRangeMin={setRangeMin}
                    setMargin={setMargin}
                    setOfferTerms={setOfferTerms}
                    canEditAmount={canEditAmount}
                    updateOffer={updateOffer}
                    actualRate={actualRate}
                    offerTradeRate={offerTradeRate}
                    setOfferTradeRate={setOfferTradeRate}
                    maxFieldValidationMessage={maxFieldValidationMessage}
                    minFieldValidationMessage={minFieldValidationMessage}
                    updatelimitsValidation={updatelimitsValidation}
                    profanityErrorMessage={profanityErrorMessage}
                    handleProfanityCheck={handleProfanityCheck}

                  />
                </div>
              </div>
              <div className="editoffer-buttons-parentsection">
                <div className="actions editoffer-buttons-section">
                  <a
                    href="/p2p_trades/offers/my_offers"
                    className="createoffer-prev-cancel-button"
                  >
                    Cancel
                  </a>
                  <button
                    type="button"
                    className="createoffer-next-submit-button"
                    disabled={submitting}
                    onClick={(e) => updateOffer()}
                  >
                    {submitting ? "Submitting" : "Update"}
                  </button>
                  <br />
                  {submitting && (
                    <div>
                      <BCLoader />
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Edit;
