import React from "react";

const SelectCurrency = ({
  type,
  selectedCurrency,
  currencies,
  handleChange,
  handleSelectedCurrencyChange
}) => (
  <div className="form-group row required mt-0 ">
    {type === "wallet" ? (
      <select
        className="form-control form-control-lg expand-select font-0_9"
        required="required"
        value={selectedCurrency}
        onChange={e => handleChange(e, "selectedCurrency")}
      >
        {currencies.map((currency, i) => (
          <option key={i} value={currency[1]}>
            {currency[0]}
          </option>
        ))}
      </select>
    ) : (
      <select
        className="form-control form-control-lg expand-select font-0_9 font-weight-bold"
        required="required"
        value={selectedCurrency}
        onChange={e => handleSelectedCurrencyChange(e)}
      >
        {currencies.map((currency, i) => (
          <option key={i} value={currency[1]}>
            {currency[0]}
          </option>
        ))}
      </select>
    )}

    <small id="chainHelpBlock" className="form-text text-muted">
      Select from available crypto assets
    </small>
  </div>
);

export default SelectCurrency;
