import React, { useState } from "react";
import { calculateFiatTradeFee, calculateTradeFee } from "../helpers";
import { CirlceQuestionmark } from "../../../TradeIcons";

const TradeInput = ({
  offer,
  hasError,
  validationMessage,
  offerTypeDisplay,
  highlightUSD,
  highlightFIAT,
  cryptoAmount,
  onCryptoChange,
  tradeFee,
  availableBalance,
  fiatAmount,
  onFiatChange,
  sellerRate,
  selectedValue,
  userBankAccounts,
  handleSelectChange,
  submitting,
  onSubmit,
}) => {

  const [showBalance, setShowBalance] = useState(true); // state to toggle balance visibility

  const toggleBalanceVisibility = () => {
    setShowBalance(!showBalance); // toggle balance visibility on icon click
  };
  return (
    <div className="offer-numbers">
      <div>
        <div className="create-trade-section">
          <div className="create-trade-card">
            {hasError && (
              <div className="createtrade-error">
                {validationMessage}
              </div>
            )}

            <div className="createtrade-currbalance">
              <i className="fas fa-info-circle"></i> My Current Balance:{" "}
              <span className="createtrade-currbalance-amount">
                {offer.cryptoCurrency?.code === "USDT" ? "$" : offer.cryptoCurrency?.code}{" "}
                {showBalance ? (
                  <>
                    {new Intl.NumberFormat("en-US", {
                      style: "decimal",
                      maximumFractionDigits: 2,
                    }).format(parseFloat(availableBalance) || 0)}{" "}
                  </>
                ) : (
                  "****"
                )}
              </span>
              <i
                className={`fas ${showBalance ? "fa-eye-slash" : "fa-eye"}`} // Toggle between eye and eye-slash
                style={{ marginLeft: "10px", cursor: "pointer" }}
                onClick={toggleBalanceVisibility}
              ></i>
            </div>
            <h4 className="tradeinput-heading">
              How much do you want to {offerTypeDisplay}?
            </h4>

            <div className="create-trade-cont">
              <div title="Must not exceed Broker's Target Amount">
                <label
                  className="create-trade-labels"
                  htmlFor="inputField3"
                >
                  I want to {offerTypeDisplay}
                </label>
                <div className="input-group mb-1">
                  <input
                    type="text"
                    className={`createtrade-input ${
                      highlightUSD ? "border-danger" : ""
                    }`}
                    id="inlineFormInputGroup2"
                    value={cryptoAmount}
                    placeholder="0.00"
                    onChange={(e) => onCryptoChange(e.target.value)}
                  />

                  <div className="currency-code-tag">
                    <p
                      className={`${
                        highlightUSD ? "border-danger" : ""
                      }`}
                    >
                      {offer.cryptoCurrency?.code == "USDT"
                        ? "USD"
                        : offer.cryptoCurrency?.code}
                    </p>
                  </div>
                </div>
              </div>
              <div title="Must not exceed Broker's Min and Max Amount for this trade">
                <label
                  className="create-trade-labels"
                  htmlFor="inputField2"
                >
                  {offerTypeDisplay === "sell"
                    ? "I will Receive"
                    : "I will Pay"}
                </label>
                <div className="input-group createtrade-inputsection">
                  <input
                    type="text"
                    className={`createtrade-input ${
                      highlightFIAT ? "border-danger" : ""
                    }`}
                    id="inlineFormInputGroup1"
                    placeholder="0.00"
                    value={fiatAmount}
                    onChange={(e) => onFiatChange(e.target.value)}
                  />
                  <div className="currency-code-tag">
                    <p>
                      {offer.fiatCurrency?.code}
                    </p>
                  </div>
                </div>
                <br />
              </div>
            </div>
            <div className="create-trade-cont">
              {offer?.offerTypeField === "buy" ? (
                offer?.paymentMethod?.slug === "bank-transfer" ? (
                  <div>
                    <div>
                      <div className="input-group createtrade-inputsection">
                        <div className="label">
                          <div>
                            <label
                              htmlFor="inputField3"
                              className="create-trade-labels"
                            >
                              Select Bank Account To Receive Payment
                            </label>
                          </div>
                          <div>
                            <a
                              href="/p2p_trades/payment_methods/bank_accounts"
                              className="createtrade-addbankaccount"
                            >
                              <i className="fas fa-plus-circle"></i>
                              {" "}
                              Add a New Bank Account
                            </a>
                          </div>
                        </div>

                        <div className="input-group createtrade-inputsection">
                          <select
                            className="createtrade-dropdown"
                            value={selectedValue}
                            onChange={handleSelectChange}
                          >
                            <option value="" disabled>
                              Select Bank Account
                            </option>
                            {userBankAccounts.map((account) => (
                              <option key={account.value} value={account.label}>
                                {account.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div>
                      <div>
                        <label
                          htmlFor="inputField3"
                          className="create-trade-labels"
                        >
                          Enter Your {offer.paymentMethod?.name} details
                       
                          <p className="createtrade-paymentmethod-description">Provide information necessary to enable you receive payment via {offer.paymentMethod?.name}.</p>
                        </label>
                        <textarea
                          className="createtrade-input"
                          value={selectedValue}
                          onChange={handleSelectChange}
                        >
                        </textarea>
                      </div>
                    </div>
                  </div>
                )
              ) : (
                <></>
              )}
            </div>

            <div 
              className={`extra-transaction-summary-container ${offerTypeDisplay === 'sell' ? 'createtrade-sell' : 'createtrade-buy'}`}
            >
              <div className="extra-transaction-summary-row">
                <div className="extra-transaction-summary">
                  <div className="extra-transaction-summary-details">
                    <p>Subtotal:</p>
                    <div className="extra-transaction-summary-details-price">
                      ${" "}
                      {new Intl.NumberFormat("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(
                        parseFloat(cryptoAmount || "0.00")
                      )}{" "}
                      <br />
                      <small>
                      {new Intl.NumberFormat("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(
                        parseFloat(fiatAmount || "0.00")
                      )}{" "}
                        {offer.fiatCurrency?.code}{" "}
                      </small>
                    </div>
                  </div>
                  {offerTypeDisplay === "sell" && tradeFee && (
                    <div className="extra-transaction-summary-details" title="Blockchain Gas Fee + Transaction Processing Fee">
                      <p>
                        <span className="createtrade-fee-helpbubble">
                          <CirlceQuestionmark />
                        </span>
                        <span>Fee:</span>
                      </p>
                      <div className="extra-transaction-summary-details-price">
                          ${" "}
                          {new Intl.NumberFormat("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }).format(
                            calculateTradeFee(cryptoAmount, tradeFee)
                          )}{" "}
               
                        <br />
                        <small>
                          {new Intl.NumberFormat("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }).format(
                           calculateFiatTradeFee(cryptoAmount, tradeFee, sellerRate)
                          )}{" "}
                          {offer.fiatCurrency?.code}{" "}
                        </small>
                      </div>
                    </div>
                  )}

                  <div className="extra-transaction-summary-details">
                    <p className="iwill-sellbuy">
                      <span>I Will</span>
                      <span>
                        {offerTypeDisplay === "sell"
                        ? "Receive:"
                        : "Pay:"}
                      </span>
                    </p>
                    <div className="extra-transaction-summary-details-price">
                      {new Intl.NumberFormat("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(
                        parseFloat(fiatAmount || "0.00")
                      )}{" "}
                      {offer.fiatCurrency?.code}
                    </div>
                  </div>
                </div>
              </div>
                    <button
                      className={`createtrade-button ${offerTypeDisplay === 'sell' ? 'createtrade-sellbtn' : 'createtrade-buybutton'}`}
                      onClick={(e) => onSubmit(e)}
                      disabled={submitting}
                    >
                      {!offer || submitting
                        ? "PLEASE WAIT"
                        : `${offerTypeDisplay.toUpperCase()} NOW`}
                    </button>
            </div>
            <div className="createtrade-profile-details">
              <div className="text-center">
                Secure escrow + Automated trade with <br />
                <div className="createtrade-offer-title font-weight-bold font-0_9">
                  {offer.customOfferLabel}
                </div>
                <div title={`${offer.profile?.name}`}>
                <a href={`/profile/${offer.profile?.userName}`} className="font-0_9 font-weight-bold createtrade-profile-name" target="_blank" rel="noopener noreferrer">
                          <i className="fa fa-user-circle"></i>{" "}
                          {offer.profile?.name.length > 20 ? `${offer.profile?.name.slice(0, 20)}...` : `${offer.profile?.name.slice(0, 20)}`}{" "}
                          </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TradeInput;
