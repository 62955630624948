import React from "react";
import { Offer } from "../../Types";

interface Props {
  offer: Offer;
  isFixedPrice: boolean;
  tradeType: string;
  handleMarketChange: (e: any) => void;
}

const Price: React.FC<Props> = ({
  offer,
  isFixedPrice,
  handleMarketChange,
  tradeType,
}) => {
  return (
    <div className="pricing edit-offer-pricing">
      <div className="rate_type">
        <div className="mb-3">
          <h3 className={`buy-section_title ${tradeType === "sell" ? "sell-section_title " : ""}`}>
            <p className="createoffers-indexnumber"> 1 </p>
            {" "}
            Choose your Preferred Rate Type
          </h3>
        </div>
        <div className="editoffer-ratetype-cont">
          <div className="custom-control custom-radio ">
            <div className="editoffer-ratetype-input editoffer-ratetype-input-rightborder">
              <input
                className="custom-control-input"
                type="radio"
                name="price_type"
                value={"fixed_price"}
                id="fixed_price"
                checked={isFixedPrice}
                onChange={(e) => handleMarketChange(e)}
              />
              <label
                className="custom-control-label createoffer-sublabels"
                htmlFor="fixed_price"
              >
                {" "}
                Fixed Price {" "}
                <i className="fa fa-lock"></i>
              </label>
              <div className="mt-2 card-text text-muted font-1">
                <i className="fa fa-question-circle-0"></i>
                Your {" "}
                {offer.cryptoCurrency?.code === "USDT"
                  ? "USD"
                  : offer.cryptoCurrency?.code}{" "}
                to {offer.fiatCurrency?.code} price <strong>stays the same</strong> and will not flunctuate
                as the market price changes.
              </div>
            </div>
          </div>
          <div className="custom-control custom-radio">
            <div className="editoffer-ratetype-input">
              <input
                className="custom-control-input"
                type="radio"
                name="price_type"
                value={"market_price"}
                id="market_price"
                checked={!isFixedPrice}
                onChange={handleMarketChange}
              />
              <label
                className="custom-control-label createoffer-sublabels"
                htmlFor="market_price"
              >
                {" "}
                Market Price  {" "}
                <i className="fa fa-bar-chart"></i>
              </label>
              <div className="mt-2 card-text text-muted font-1">
                <i className="fa fa-question-circle-0"></i>
                Your {" "}
                {offer.cryptoCurrency?.code === "USDT"
                  ? "USD"
                  : offer.cryptoCurrency?.code}{" "}
                to {offer.fiatCurrency?.code} price <strong>will flunctuate</strong> as the
                market price changes.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Price;
