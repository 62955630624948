import { FieldProps } from "formik";
import React from "react";

const FileField: React.FC<FieldProps> = ({ field, form }) => (
  <>
    <input
      id="file"
      type="file"
      name="file"
      accept="image/jpeg, image/png, application/pdf"
      className={`form-control ${form.errors[field.name] ? 'is-invalid' : ''}`}
      onChange={(event) => {
        form.setFieldValue(field.name, event.currentTarget.files[0]);
      }}
    />
    {field.value && (
      <div>
        <p>{field.value.name}</p>
      </div>
    )}
  </>
);
export default FileField;
