import React, {useState,useEffect} from "react";
import { getRequest } from "../../../utils/httpRequest";
import SendPaymentCard from "./SendPaymentCard";
import P2pMini from "./P2pMini";
import AppTab from "./AppTab";
import AccountSummaryPie from "./AccountSummaryPie";
import CurrencyTrends from "./CurrencyTrends";
import "./index.scss";

const AppTaskComponent = () => {
    const [appData, setAppData] = useState(null);

    useEffect(() => {
        const fetchAppData = async () => {
            try {
            const data = await getRequest("/api/v1/dashboard/summary");
            setAppData(data); 
            } catch (error) {
            console.error("Error fetching wallet data:", error);
            }
        };
    
        fetchAppData();
        }, []); 

    return (
        <div className="app-task-row ">
            <div className="app-task-two-third ">
                <div className="app-task-row-one">
                    <SendPaymentCard />
                    <P2pMini p2pData={appData?.offers}/>
                </div>
                <div className="app-task-row-two">
                    <CurrencyTrends />
                    <AccountSummaryPie accountSummaryData={appData?.user_last_payment_transaction_history_types}/>
                </div>
            </div>
            <div className="app-task-one-third ">
                <AppTab featuredTradersData={appData?.featured_traders} userTradeHistory={appData?.user_p2p_trade_history}/>
            </div>
        </div>
    );
    
}

export default AppTaskComponent;