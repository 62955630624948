
 // https://stackoverflow.com/a/9393768/1886853
 $(function() {

  // Javascript to enable link to tab
  var hash = location.hash.replace(/^#/, '');  // ^ means starting, meaning only match the first hash
  if (hash) {
      $('.nav-tabs a[href="#' + hash + '"]').tab('show');
  } 

  // Change hash for page-reload
  $('.nav-tabs a').on('shown.bs.tab', function (e) {
      window.location.hash = e.target.hash;
      // window.scrollTo(0, 0);
  })

});
