import React from "react";
import NoOffers from "../../../../../../assets/images/noofferfound.gif";

const NoHits = () => {
  return (
    <div className="no-offer-found">
      <img className="w-50" src={NoOffers} alt="No Offers Found" />
      <p>No offers found. Create an offer or try again later!</p>
    </div>
  );
};

export default NoHits;
