import React from "react";

interface Props {
  modalContent: string;
  closeModal: () => void;
}

const Modal: React.FC<Props> = ({ modalContent, closeModal }) => (
  <div className="cmodal">
    <div className="modal-dialog modal-dialog-centered d-flex justify-content-center">
      <div className="modal-content w-75">
        <div className="modal-header">
          <h4 className="font-weight-bold">
            <i className="fa fa-info-circle" aria-hidden="true"></i> Info
          </h4>
          <span className="close" onClick={closeModal}>
            &times;
          </span>
        </div>
        <div className="modal-body text-start">
          <p>{modalContent}</p>
        </div>
      </div>
    </div>
  </div>
);
export default Modal;
