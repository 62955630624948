import React, { useState, useEffect, useRef } from "react";
import noEye from "../../../../../assets/images/HideBalance.svg";
import eye from "../../../../../assets/images/eye-regular 1.svg";
import caret from "../../../../../assets/images/Vector.svg";
import plus from "../../../../../assets/images/AddButton.svg";
import loading from "../../../../../assets/images/spin.gif";
import SelectCurrency from "../../../SelectCurrency";

const Card = (props) => {
  const {
    cardBg,
    footerBg,
    footerLinks,
    walletData,
    textColor,
    inactive,
    refreshWalletData,
    className,
  } = props;
  const [showAmount, setShowAmount] = useState(true);
  const [curDDown, showCurDDown] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const selectRef = useRef(null);
  const [isRotating, setIsRotating] = useState(false);

  const handleRefresh = async () => {
    setIsRotating(true);
    setLoadingData(true);
    await refreshWalletData();
    setIsRotating(false);
    setLoadingData(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        // Clicked outside the SelectCurrency component
        showCurDDown(false); // Hide the select component
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Check if walletData is undefined or null
    if (walletData === undefined || walletData === null) {
      setLoadingData(true);
    } else {
      setLoadingData(false);
    }
  }, [walletData]);

  return (
    <div className={`wallet-card ${cardBg} ${inactive} ${className}`}>
      <div className="eye-div">
        <img
          src={showAmount ? noEye : eye}
          className="image"
          onClick={() => setShowAmount(!showAmount)}
          title={showAmount ? "Hide" : "View"}
          style={{ cursor: "pointer" }}
          alt="Eye Icon"
        />
      </div>
      {loadingData ? (
        <div className="account-bal">
          <img src={loading} alt="Loading" width={70} />
        </div>
      ) : (
        <div className="account-bal">
          <div className="currency">
            {showAmount && (
              <div className={textColor}>{walletData?.balance} </div>
            )}
            {!showAmount && <div>*******</div>}
            <i
              className={`fa fa-refresh p-2 ${isRotating ? "rotate" : ""}`}
              title="refresh"
              onClick={handleRefresh}
              style={{ fontSize: "16px", cursor: "pointer" }}
            ></i>
          </div>
          <div className="alt-currency">
            {!curDDown && showAmount && (
              <>
                <div className="alt-balance">{walletData?.alt_balance}</div>
                <img
                  src={caret}
                  style={{ height: "16px", width: "13px", cursor: "pointer" }}
                  onClick={() => showCurDDown(!curDDown)}
                  alt="Caret Icon"
                  title="Change Alternate Currency"
                />
              </>
            )}
            {curDDown && (
              <div ref={selectRef}>
                <SelectCurrency
                  preferredCurrency={walletData?.alt_currency}
                  saveOnSelect={true}
                  refreshOnSave={true}
                />
              </div>
            )}
          </div>
        </div>
      )}

      <div className="big-text">{walletData?.wallet}</div>
      <div className={`footer ${footerBg}`}>
        {footerLinks &&
          Object.entries(footerLinks).map(([text, href]) => (
            <div key={text} className="footer-icons">
              <img className="svg" src={plus} alt="Plus Icon" />
              <a href={href} className="footer-icons-link">
                {text}
              </a>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Card;
