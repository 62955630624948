const groups = [
  {
    title: "Trade Notifications",
    resource: "P2pTrade",
    icon: "fa fa-exchange",
    id: "trades",
  },
  {
    title: "Transaction Notifications",
    resource: "CryptoTransfer",
    icon: "fa fa-bank",
    id: "crypto",
  },
  {
    title: "Referral Notifications",
    resource: "Referral",
    icon: "fa fa-user-circle",
    id: "referral",
  },
  {
    title: "Comission Notifications",
    resource: "Commission",
    icon: "fa fa-bolt",
    id: "commission",
  },
  {
    title: "Verification Notifications",
    resource: "Verification",
    icon: "fa fa-lock",
    id: "verification",
  },
  {
    title: "Card Notifications",
    resource: "Card::Request",
    icon: "fa fa-credit-card",
    id: "cards",
  },
  {
    title: "Broker Notifications",
    resource: "Broker",
    icon: "fa fa-globe",
    id: "broker",
  },
];

export default groups;