import { Controller } from "stimulus";
import Rails from "@rails/ujs";
import MetaMaskOnboarding from "@metamask/onboarding";

export default class extends Controller {
  static targets = ["tradeType", "bananacrystalTrade", "metamaskTrade"];

  connect() {
    this.bananacrystalTradeTarget.style.display = "none";
    this.metamaskTradeTarget.style.display = "none";
  }

  onSelectTradeType() {
    if (this.tradeTypeTarget.value === "banana_crystal") {
      this.bananacrystalTradeTarget.style.display = "block";
      this.metamaskTradeTarget.style.display = "none";
    } else if (this.tradeTypeTarget.value === "meta_mask") {
      this.bananacrystalTradeTarget.style.display = "none";
      this.metamaskTradeTarget.style.display = "block";
    } else {
      this.bananacrystalTradeTarget.style.display = "none";
      this.metamaskTradeTarget.style.display = "none";
    }
  }

  getAccounts = async () => {
    const accounts = await ethereum.request({ method: "eth_accounts" });

    if (accounts[0]) {
      window.location = `/trade/new?metamask_account=${accounts[0]}`;
    }
  };

  onClickConnect = async () => {
    this.getAccounts();
  };
}
