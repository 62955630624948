import React from "react";
interface Props {
  data: any;
  onClickEdit: (e: any, step: string) => void;
}

const MailingDetails: React.FC<Props> = ({ data, onClickEdit }) => (
  <div>
    <h6>Shipping Details</h6>
    <div className="form-group row">
      <label className="col-12 col-md-3 form-label">Address</label>
      <div className="col-12 col-md-9">
        <span>{data.address}</span>
      </div>
    </div>
    <div className="form-group row">
      <label className="col-12 col-md-3 form-label">City</label>
      <div className="col-12 col-md-9">
        <span>{data.city}</span>
      </div>
    </div>
    <div className="form-group row">
      <label className="col-12 col-md-3 form-label">State</label>
      <div className="col-12 col-md-9">
        <span>{data.state}</span>
      </div>
    </div>
    <div className="form-group row">
      <label className="col-12 col-md-3 form-label">Country</label>
      <div className="col-12 col-md-9">
        <span>{data.country}</span>
      </div>
    </div>
    <div className="form-group row">
      <label className="col-12 col-md-3 form-label">Postal Code</label>
      <div className="col-12 col-md-9">
        <span>{data.postalCode}</span>
      </div>
    </div>
    <div className="ml-auto">
      <a
        className="btn btn-purple"
        href="#"
        onClick={(e) => onClickEdit(e, "mailing")}
      >
        Edit <i className="fa fa-pen"></i>
      </a>
    </div>
  </div>
);

export default MailingDetails;
