import { Controller } from "stimulus";
import Rails from "@rails/ujs";
import axios from "axios";
import { SDK as BancorSDK } from "@bancor/sdk";
import Web3 from "web3";
import bancorNetworkAbi from "../model/bancorNetworkAbi.json";
import contractRegistryAbi from "../model/contractRegistryAbi.json";

export default class extends Controller {
  static targets = [
    "sourceToken",
    "destinationToken",
    "sourceTokenLabel",
    "destinationTokenLabel",
    "sentAmount",
    "receivedAmount",
    "amountHelpBlock"
  ];

  initialize = async () => {
    this.web3 = null;

    if (window.ethereum) {
      console.log(`Injected ethereum detected.`);
      this.web3 = new Web3(window.ethereum);
    } else if (window.web3) {
      console.log(`Injected web3 detected.`);
      this.web3 = new Web3(window.web3.currentProvider);
    }

    const settings = {
      // optional, mandatory when interacting with the ethereum mainnet
      ethereumNodeEndpoint:
        "wss://mainnet.infura.io/ws/v3/b5af199048ef4b8f8bd8797051d2378f"
      // optional, mandatory when interacting with the EOS mainnet
    };

    this.bancorSDK = await BancorSDK.create(settings);

    console.log("the sdk", this.bancorSDK);
  };

  getPathAndRate = async () => {
    const sourceToken = {
      blockchainType: "ethereum",
      blockchainId: this.sourceTokenTarget.value
    };

    const targetToken = {
      blockchainType: "ethereum",
      blockchainId: this.destinationTokenTarget.value
    };

    const path = await this.bancorSDK.pricing.getPathAndRate(
      sourceToken,
      targetToken,
      1.0
    );

    return path;
  };

  trade = async () => {
    const accounts = await ethereum.request({ method: "eth_accounts" });
    this.web3.eth.defaultAccount = accounts[0];
    // this.web3.eth.personal.unlockAccount(this.web3.eth.defaultAccount);
    console.log(this.web3);
    const contractRegistryAddress =
      "0x52Ae12ABe5D8BD778BD5397F99cA900624CfADD4";
    // mainnet
    const ContractRegistry = new this.web3.eth.Contract(
      contractRegistryAbi,
      contractRegistryAddress
    );

    const bancorNetworkName = this.web3.utils.fromAscii("BancorNetwork");
    const bancorNetworkAddress = await ContractRegistry.methods
      .addressOf(bancorNetworkName)
      .call();

    const BancorNetworkContract = new this.web3.eth.Contract(
      bancorNetworkAbi,
      bancorNetworkAddress
    );

    const pathAndRate = await this.getPathAndRate();

    console.log("aasas", pathAndRate.path);
    const paths = pathAndRate.path.map(val => val.blockchainId);

    const web3EthContract = await BancorNetworkContract.methods.convert(
      paths,
      this.sentAmountTarget.value,
      this.web3.utils.toWei(pathAndRate.rate)
    );

    web3EthContract
      .send({ from: accounts[0] })
      .on("transactionHash", function(hash) {
        console.log(hash);
      })
      .on("confirmation", function(confirmationNumber, receipt) {
        console.log(confirmationNumber, receipt);
      })
      .on("receipt", function(receipt) {
        console.log(receipt);
      })
      .on("error", function(error, receipt) {
        console.log(error, receipt);
      });
  };

  getAmount = async () => {
    const pathAndRate = await this.getPathAndRate();
    const rate = Number(pathAndRate.rate);
    const amount = rate * Number(this.sentAmountTarget.value);

    this.amountHelpBlockTarget.innerHTML = `1 ${this.tokenLabel(
      this.sourceTokenTarget
    )} = ${rate} ${this.tokenLabel(this.destinationTokenTarget)}`;
    this.receivedAmountTarget.value = amount;
    console.log(amount);
  };

  onChangeSelectToken() {
    const sourceTokenLabel = this.tokenLabel(this.sourceTokenTarget);

    const destinationTokenLabel = this.tokenLabel(this.destinationTokenTarget);

    this.sourceTokenLabelTarget.innerHTML = sourceTokenLabel;
    this.destinationTokenLabelTarget.innerHTML = destinationTokenLabel;

    this.getAmount();
  }

  tokenLabel(target) {
    return target.options[target.selectedIndex].text;
  }
}
