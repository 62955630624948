import React from "react";
import Countdown from "react-countdown";
import moment from "moment/moment";
import TradeChat from "../../TradeChat";
import BankAccountDetails from "./BankAccountDetails";
import SellerBankPaymentMethodDetails from "./SellerBankPaymentMethodDetails";
import SellerOtherPaymentMethodDetails from "./SellerOtherPaymentMethodDetails";
import TermsInfo from "./TermsInfo";
import TradeInfo from "./TradeInfo";
import DisputeInfo from "./DisputeInfo";
import AssetsSafetyCheckModal from "./AssetsSafetyCheckModal";
import TradeActions from "./TradeActions";

const PaymentPaid = ({
  tradeData,
  isSystemCancelled,
  showChat,
  isRotating,
  handleRefresh,
  isBuyer,
  isSeller,
  acceptSuccess,
  paidSuccess,
  isPaidClicked,
  paidTrade,
  readyToAccept,
  setReadyToAccept,
  validationMessageAccept,
  handleReleaseButtonClick,
  isAcceptClicked,
  tradeBankAccount,
  referenceMessage,
  sellerPaymentDetail,
  offer,
  sellerRate,
  chatActionCableUrl,
  hideChat,
  disputeData,
  isDisputed,
  currentUserId,
  isDisputedAllowed,
  showDisputeModal,
  isDisputeClicked,
  setValidationMessageDispute,
  showModal,
  cancelTrade,
  handleAssetsSafetyCheckConfirm,
  onClose,
  validationMessageDispute,
  checkChatDisabled,
  isCancelClicked,
  setShowChat,

}) => {

  return (
    <div>
      <div>
          <div className="tradedetails-actions-steps-cont">
            {/* Progress Bar */}
            <div className="tradedetails-actions-progressbar">
              <div className="tradedetails-actions-circle"></div>
              <div className="tradedetails-actions-line"></div>
              <div className="tradedetails-actions-circle"></div>
              <div className="tradedetails-actions-line"></div>
              <div className="tradedetails-actions-circle"></div>
            </div>

            <div className="tradedetails-action-stepsection">
              {/* Instructions from offer user */}
              <div className="tradedetails-actions-step">
                <TradeInfo
                  tradeId={tradeData?.id}
                  tradeCreatedAt={tradeData?.created_at}
                  sellerRate={sellerRate}
                  offerFiatCurrencyCode={offer?.fiatCurrency?.code}
                  customOfferLabel={offer?.customOfferLabel}
                />

                {/* Dispute Info */}
                <DisputeInfo disputeData={disputeData} isDisputed={isDisputed} />
    
                <TermsInfo
                  instructionsFrom={
                    isBuyer ? tradeData.seller_username : tradeData.buyer_username
                  }
                  instructionsFromName={
                    isBuyer
                      ? tradeData.seller_profile_name
                      : tradeData.buyer_username
                  }
                  customOfferLabel={offer?.customOfferLabel}
                  offerTags={offer?.tags}
                  offerTerms={offer?.offerTerms}
                />
              </div>
  
              <div className="tradedetails-actions-step ">
                {/* Transaction Instructions - Bank account info section */}
                {/* Sell Offer and payment method -> Bank Transfer */}
                {offer?.paymentMethod?.name === "Bank Transfer" &&
                  offer?.offerTypeField === "sell" && (
                    <BankAccountDetails
                      isBuyer={isBuyer}
                      instructionsFrom={
                        isBuyer
                          ? tradeData.seller_username
                          : tradeData.buyer_username
                      }
                      sellerUsername={tradeData?.seller_username}
                      tradeBankAccount={tradeBankAccount}
                      transferTotal={`${tradeData?.fiat} ${tradeData?.fiat_currency_code}`}
                      referenceMessage={referenceMessage}
                      buyerName={tradeData?.buyer_name}
                    />
                  )}
                {/* Sell Offer and payment methood -> !Bank Transfer */}
                {offer?.paymentMethod?.name !== "Bank Transfer" &&
                  offer?.offerTypeField === "sell" && (
                    <SellerOtherPaymentMethodDetails
                      isSeller={isSeller}
                      paymentMethod={tradeData?.payment_method_name}
                      sellerPaymentDetail={sellerPaymentDetail}
                      instructionsFrom={tradeData?.seller_username}
                      transferTotal={`${tradeData?.fiat} ${tradeData?.fiat_currency_code}`}
                      referenceMessage={referenceMessage}
                      sellerUsername={tradeData?.seller_username}
                      buyerUsername={tradeData?.buyer_username}
                      buyerName={tradeData?.buyer_name}
                    />
                  )}
    
                {/* Buy Offer and payment methood -> !Bank Transfer */}
                {offer?.paymentMethod?.name !== "Bank Transfer" &&
                  offer?.offerTypeField === "buy" && (
                    <SellerOtherPaymentMethodDetails
                      isSeller={isSeller}
                      paymentMethod={tradeData?.payment_method_name}
                      sellerPaymentDetail={sellerPaymentDetail}
                      instructionsFrom={tradeData?.seller_username}
                      transferTotal={`${tradeData?.fiat} ${tradeData?.fiat_currency_code}`}
                      referenceMessage={referenceMessage}
                      sellerUsername={tradeData?.seller_username}
                      buyerUsername={tradeData?.buyer_username}
                      buyerName={tradeData?.buyer_name}
                    />
                  )}
    
                {/* Buy Offer and payment methood -> Bank Transfer */}
                {offer?.paymentMethod?.name === "Bank Transfer" &&
                  offer?.offerTypeField === "buy" && (
                    <SellerBankPaymentMethodDetails
                      isSeller={isSeller}
                      paymentMethod={tradeData?.payment_method_name}
                      sellerPaymentDetail={sellerPaymentDetail}
                      buyerUsername={tradeData?.buyer_username}
                      buyerName={tradeData?.buyer_name}
                      sellerUsername={tradeData?.seller_username}
                      instructionsFrom={tradeData?.seller_username}
                      transferTotal={`${tradeData?.fiat} ${tradeData?.fiat_currency_code}`}
                      referenceMessage={referenceMessage}
                    />
                )}
              </div>
              <div className="tradedetails-actions-step ">
                <TradeActions 
                  isBuyer={isBuyer}
                  tradeData={tradeData}
                  offer={offer}
                  isDisputed={isDisputed}
                  isSeller={isSeller}
                  acceptSuccess={acceptSuccess}
                  isPaidClicked={isPaidClicked}
                  paidTrade={paidTrade}
                  readyToAccept={readyToAccept}
                  setReadyToAccept={setReadyToAccept}
                  validationMessageAccept={validationMessageAccept}
                  handleReleaseButtonClick={handleReleaseButtonClick}
                  handleAssetsSafetyCheckConfirm={handleAssetsSafetyCheckConfirm}
                  isAcceptClicked={isAcceptClicked}
                  isSystemCancelled={isSystemCancelled}
                  isCancelClicked={isCancelClicked}
                  validationMessageDispute={validationMessageDispute}
                  setValidationMessageDispute={setValidationMessageDispute}
                  isDisputeClicked={isDisputeClicked}
                  isDisputedAllowed={isDisputedAllowed}
                  showModal={showModal}
                  showDisputeModal={showDisputeModal}
                  isRotating={isRotating}
                  handleRefresh={handleRefresh}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default PaymentPaid;
