import React from "react";
import { Transaction } from "../Types";

interface Props {
  transactions: Transaction[];
}

const Body: React.FC<Props> = ({ transactions }) => {
  return (
    <tbody>
      {transactions.map((transaction, i) => (
        <tr key={i}>
          <td>{transaction.executed_at}</td>
          <td>{transaction.trn_ref_number}</td>
          <td>{transaction.description}</td>
          <td>{transaction.cr_amount > 0 ? transaction.cr_amount : "-"}</td>
          <td>{transaction.dr_amount > 0 ? transaction.dr_amount : "-"}</td>
          <td>{transaction.end_bal}</td>
        </tr>
      ))}
      {transactions.length === 0 && (
        <tr>
          <td colSpan={8} className="text-center">
            Currently you have no card activities.
          </td>
        </tr>
      )}
    </tbody>
  );
};

export default Body;
