import React, { Component } from "react";
import _ from "underscore";
import { getRequest, postRequest } from "../../utils/httpRequest";
import ReactTooltip from "react-tooltip";
// import { ReactSVG } from "react-svg";
// import Countdown from "react-countdown";
// import { getSearchParameters } from "../../utils/urls";
import { formErrors } from "../../utils/formErrors";
import CryptoForm from "./CryptoForm";

class CryptoTransferConvertToBC extends Component {
  constructor(props) {
    super(props);

    this.state = {
      totalTypes: props.totalTypes,
      selectedTotalType: props.totalType,
      selectedSourceWallet: props.selectedSourceWallet,
      selectedCurrency: props.sourceCurrency || "BTC",
      selectedCurrencyBalance: props.sourceCurrencyBalance,
      selectedCurrencyDescription: props.sourceCurrencyDescription,
      selectedCurrencyImgSrc: props.sourceCurrencyImgSrc,
      selectedTransferType: "recipient",
      destinationCurrency: props.destinationCurrency,
      destinationWallets: "",
      selectedDestinationWallet: props.selectedDestinationWallet,
      destinationCurrencyImgSrc: props.destinationCurrencyImgSrc,
      recipient: "",
      blockchainAddress: this.props.blockchainAddress,
      walletAddresses: props.walletAddresses || [],
      selectedWalletAddress: "",
      sourceAmount: parseFloat(0.0).toFixed(2),
      destinationAmount: "TBD",
      fees: 0.0,
      exchangeRate: props.exchangeRate,
      note: "", // user message or note
      recipientError: "",
      error: "",
      sourceAmountError: "",
      mode: "form",
      invalidTransfer: false,
      submitting: false,
      previewing: false,
      validationErrors: {},
      submitTransfer: false
    };
  }

  validateSourceAmount = () => {
    const { validationErrors } = this.state;
    this.setState({ error: "" });

    if (Number(this.state.sourceAmount) <= 0) {
      validationErrors.sourceAmountError =
        "Amount to convert cannot be zero, please enter a higher amount.";

      this.setState({
        validationErrors,
        invalidTransfer: true
      });
    } else if (
      Number(this.state.sourceAmount) >
      Number(this.state.selectedCurrencyBalance)
    ) {
      validationErrors.sourceAmountError =
        "Amount to convert is greater than your available balance, please enter a lower amount.";

      this.setState({
        validationErrors,
        invalidTransfer: true
      });
    } else {
      validationErrors.sourceAmountError = "";
      this.setState({
        validationErrors,
        invalidTransfer: false
      });
    }
  };

  handleSourceAmountChange = e => {
    this.setState({ sourceAmount: e.target.value }, () =>
      this.validateSourceAmount()
    );

    if (this.state.submitting) {
      this.setState({
        destinationAmount:
          Math.round((sourceAmount * exchangeRate + Number.EPSILON) * 100) / 100
      });
    } else {
      this.setState({ destinationAmount: "TBD" });
    }
  };

  validateFormFields = () => {
    const { sourceAmount } = this.state;

    let validationErrors = {};

    if (Number(sourceAmount) <= 0) {
      validationErrors.sourceAmountError = "amount should be greater than zero";
    }

    return validationErrors;
  };

  createQuote = e => {
    e.preventDefault();

    const { sourceAmount, selectedCurrency } = this.state;

    const validationErrors = this.validateFormFields();

    if (!_.isEmpty(validationErrors)) {
      alert("called");
      this.setState({ validationErrors, invalidTransfer: true });

      return;
    }

    this.setState({
      submitting: true,
      previewing: true,
      error: "",
      validationErrors: {}
    });

    const data = {
      amount: sourceAmount,
      token: selectedCurrency
    };

    postRequest(`/crypto_api/quote`, data)
      .then(response => {
        this.setState({
          submitTransfer: true,
          submitting: false,
          destinationAmount: response
        });
      })
      .catch(error => {
        this.setState({ submitting: false });
      });
  };

  refreshPage = e => {
    e.preventDefault();

    const url = window.location.href;
    window.location.assign(url);
  };

  createSwap = e => {
    e.preventDefault();

    this.setState({
      submitting: true
    });

    const { sourceAmount, selectedCurrency, destinationAmount } = this.state;

    const data = {
      amount: sourceAmount,
      token: selectedCurrency,
      destination_amount: destinationAmount
    };

    postRequest(`/crypto_api/swap`, data)
      .then(response => {
        window.location = `/crypto_transfers/${response}`;
      })
      .catch(error => {
        this.setState({ submitting: false });
      });
  };

  render() {
    const { sourceWallets, exchangeRate, type } = this.props;

    const {
      sourceAmount,
      selectedCurrency,
      selectedCurrencyBalance,
      selectedCurrencyDescription,
      selectedCurrencyImgSrc,
      destinationAmount,
      mode,
      error,
      sourceAmountError,
      invalidTransfer,
      submitting,
      previewing,
      validationErrors,
      submitTransfer,
      transferId
    } = this.state;

    return (
      <div className="content row">
        <div className="card-text text-center">
          <div className="row">
            <div className="col-md-12">
              <CryptoForm
                selectedCurrencyImgSrc={selectedCurrencyImgSrc}
                selectedCurrency={selectedCurrency}
                validationErrors={validationErrors}
                exchangeRate={exchangeRate}
                selectedCurrencyBalance={selectedCurrencyBalance}
                submitTransfer={submitTransfer}
                destinationAmount={destinationAmount}
                transferId={transferId}
                sourceAmount={sourceAmount}
                error={error}
                invalidTransfer={invalidTransfer}
                submitting={submitting}
                previewing={previewing}
                handleSourceAmountChange={this.handleSourceAmountChange}
                createQuote={this.createQuote}
                createSwap={this.createSwap}
                refreshPage={this.refreshPage}
              />
              <ReactTooltip data-type="dark" data-background-color="#00669c" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CryptoTransferConvertToBC;
