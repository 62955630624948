import React from "react";

const Preview = ({
  fees,
  amount,
  netAmount,
  selectedCurrency,
  firstTimeWithdrawalFee,
  isFirstTimeWithdrawal
}) => (
  <div className="preview-wrapper">
    <h4>Review Your Transfer Details</h4>
    <hr />
    <div className="mx-1">
      <div className="row">
        <div className="col-md-5">
          <div className="form-group row d-block ">
            <label className="form-label">
              Amount
              <a
                data-toggle="tooltip"
                className="btn btn-link"
                data-original-title="Subtotal amount (Crypto)."
              >
                <i className="fa fa-info-circle"></i>
              </a>
            </label>
            <small
              id="netAmountHelpBlock"
              className="form-text text-muted"
            ></small>
          </div>{" "}
        </div>
        <div className="col-md-7">
          <div className="form-group row ">
            <div className="input-group mb-2 mr-sm-2">
              <div className="input-group-prepend">
                <div className="input-group-text selected-currency border-0">
                  {selectedCurrency}
                </div>
              </div>
              <input
                className="form-control border-0 pr-0"
                placeholder="0.00"
                data-target="cryptoTransfer.outputNetAmount"
                required="required"
                readOnly="readonly"
                type="number"
                value={amount}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-5">
          <div className="form-group row d-block ">
            <label className="form-label">
              Fee
              <a
                data-toggle="tooltip"
                className="btn btn-link"
                data-original-title="Transaction and processing fees (Crypto)"
              >
                <i className="fa fa-info-circle"></i>
              </a>
            </label>
            <small id="feesHelpBlock" className="form-text text-muted"></small>
          </div>{" "}
        </div>
        <div className="col-md-7">
          <div className="form-group row ">
            <div className="input-group mb-2 mr-sm-2">
              <div className="input-group-prepend">
                <div className="input-group-text selected-currency border-0">
                  {selectedCurrency}
                </div>
              </div>
              <input
                className="form-control border-0"
                placeholder="TBD"
                data-target="cryptoTransfer.outputFeesAmount"
                required="required"
                readOnly="readonly"
                type="number"
                value={parseFloat(fees).toFixed(7)}
              />
            </div>
          </div>{" "}
        </div>
      </div>
      {isFirstTimeWithdrawal && (
        <div className="row">
          <div className="col-md-5">
            <div className="form-group row d-block ">
              <label className="form-label pr-0">
                One Time Setup Fee
                <a
                  data-toggle="tooltip"
                  className="btn btn-link pl-1 pr-0"
                  data-original-title="Transaction and processing fees (Crypto)"
                >
                  <i className="fa fa-info-circle"></i>
                </a>
              </label>
              <small
                id="feesHelpBlock"
                className="form-text text-muted"
              ></small>
            </div>{" "}
          </div>
          <div className="col-md-7">
            <div className="form-group row ">
              <div className="input-group mb-2 mr-sm-2">
                <div className="input-group-prepend">
                  <div className="input-group-text selected-currency border-0">
                    {selectedCurrency}
                  </div>
                </div>
                <input
                  className="form-control border-0"
                  placeholder="TBD"
                  data-target="cryptoTransfer.outputFeesAmount"
                  required="required"
                  readOnly="readonly"
                  type="number"
                  value={parseFloat(firstTimeWithdrawalFee).toFixed(7)}
                />
              </div>
            </div>{" "}
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-md-5">
          <div className="form-group row d-block ">
            <label className="form-label">
              Total
              <a
                data-toggle="tooltip"
                className="btn btn-link"
                data-original-title="Transaction and processing fees (Crypto)"
              >
                <i className="fa fa-info-circle"></i>
              </a>
            </label>
            <small id="feesHelpBlock" className="form-text text-muted"></small>
          </div>{" "}
        </div>
        <div className="col-md-7">
          <div className="form-group row ">
            <div className="input-group mb-2 mr-sm-2">
              <div className="input-group-prepend">
                <div className="input-group-text selected-currency border-0">
                  {selectedCurrency}
                </div>
              </div>
              <input
                className="form-control border-0"
                placeholder="TBD"
                data-target="cryptoTransfer.outputFeesAmount"
                required="required"
                readOnly="readonly"
                type="number"
                value={parseFloat(netAmount).toFixed(7)}
              />
            </div>
          </div>{" "}
        </div>
      </div>
    </div>
  </div>
);

export default Preview;
