import React, { useState, useEffect, useRef } from "react";
import { getRequest } from "../../../../utils/httpRequest";
import "./index.scss";
import { IconUserPurple } from "../../../Dashboard/dashboardIcons";

const P2pMini = (props) => {
  const { alt_currency } = props;
  const [offers, setOffers] = useState([]);

  const [search, setSearch] = useState("");
  const [showHam, setShowHam] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(async () => {
    if (!alt_currency) return;
    await getRequest(
      `/landing_page/top_offers?currency=${alt_currency}&offer_type=buy`
    ).then((response) => {
      setOffers((prev) => [...prev, ...response.splice(0, 3)]);
    });
    await getRequest(
      `/landing_page/top_offers?currency=${alt_currency}&offer_type=sell`
    ).then((response) => {
      setOffers((prev) => [...prev, ...response.splice(0, 3)]);
    });
  }, [alt_currency]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowHam(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="p2p-table-container app-card2">
      <div className="table-title">
        <i className="fa fa-dot"></i>
        <div ref={dropdownRef}>
          <div onClick={() => setShowHam(!showHam)}>
            <i className="fa fa-ellipsis-h icon" aria-hidden="true"></i>
          </div>
          {showHam && (
            <ul className="offer-menu-link">
              <li>
                <i className="mr-1 fa fa-plus" />
                <a href="/p2p_trades/offers/new?offer_type=buy">
                  Create Buy Offer
                </a>
              </li>
              <li>
                <i className="mr-1 fa fa-plus" />
                <a href="/p2p_trades/offers/new?offer_type=sell">
                  Create Sell Offer
                </a>
              </li>
              <li>
                <i className="mr-1 fa fa-long-arrow-right" />
                <a href="/p2p_trades/offers/search?">See More</a>
              </li>
              <li>
                <i className="mr-1 fa fa-long-arrow-right" />
                <a href="/p2p_trades/offers/my_offers">See My Offer</a>
              </li>
            </ul>
          )}
        </div>
      </div>
      <div className="search">
        <input
          type="text"
          onChange={(e) => setSearch(e.target.value)}
          value={search}
          placeholder="Search for offers with advertiser, price, payment method, or offer type..."
          style={{ fontSize: "0.8rem" }}
        />
      </div>
      <table className="table table-bordered rounded-lg">
        <thead className="">
          <tr className="">
            <th className="">Advertiser</th>
            <th className="">Price ({alt_currency || "..."})</th>
            <th className="">Payment Method</th>
            <th className="">Action</th>
          </tr>
        </thead>
        <tbody className="">
          {offers
            .filter((offer) =>
              Object.values(offer).some(
                (val) =>
                  typeof val === "string" &&
                  val.toLowerCase().includes(search.toLowerCase())
              )
            )
            .map((offer, index) => (
              <tr key={index} className="p2p-table-body-row">
                <td
                  className="p2p-table-datacell d-flex align-items-center"
                  data-label="Advertiser"
                >
                  <IconUserPurple className="mr-1" />
                  <span className="offer-label">{offer.customOfferLabel}</span>
                </td>
                <td
                  className="p2p-table-datacell datacell-mobile"
                  data-label="Price"
                >
                  <span className="hidden-label">Price:</span>
                  <span>{Number(offer.tradeRate).toFixed(2) || "N/A"}</span>
                </td>
                <td
                  className="p2p-table-datacell datacell-mobile"
                  data-label="Limit/Available"
                >
                  <span className="hidden-label">Payment Method:</span>
                  {offer.paymentOfferLabel}
                </td>
                <td className="p2p-table-datacell" data-label="Action">
                  <a
                    href={`/p2p_trades/offers/trade/${offer.id}/create`}
                    className="btn btn-sm"
                    id={`${
                      offer.flowType === "sell" ? "buy-color" : "sell-color"
                    }`}
                  >
                    {`${offer.flowType === "sell" ? "Buy" : "Sell"}`}
                    <i className="fa fa-long-arrow-right ml-1"></i>
                  </a>
                </td>
              </tr>
            ))}
          {offers.length === 0 && (
            <tr>
              <td colSpan="4">
                <div className="text-center">
                  No offers found {alt_currency ? `for ${alt_currency}` : ""}
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default P2pMini;
