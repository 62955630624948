import React from "react";

const SelectedPaymentMethodGroup = ({
  name,
  paymentMethods,
  resetSelectedPmg,
  selectPaymentMethod
}) => (
  <div className="payment-methods" style={{"height": "300px", "overflowY": "auto", "zIndex": "2"}}>
    <p>
      <a href="#" onClick={e => resetSelectedPmg(e)}>
        <i className="fa fa-arrow-left" />
      </a>
      {name} (Options: {paymentMethods.length})
    </p>
    {paymentMethods.map((pm, i) => (
      <button
        key={i}
        className="btn btn-light"
        onClick={e =>
          selectPaymentMethod(
            e,
            {
              label: pm.name,
              value: pm.slug
            },
            pm.payment_method_group_slug
          )
        }
      >
        <span className="name">{pm.name}</span>
      </button>
    ))}
  </div>
);

export default SelectedPaymentMethodGroup;
