import React from "react";

const Head = () => (
  <thead className="table-header">
    <tr>
      <th>Date</th>
      <th>Amount</th>
      <th>Status</th>
      <th>Loaded At</th>
      <th className="text-center">Action</th>
    </tr>
  </thead>
);

export default Head;
