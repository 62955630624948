import React, { Component, useState, useEffect } from "react";
import _ from "underscore";
import { getRequest, postRequest, patchRequest } from "../../utils/httpRequest";
import {
  validateWalletAddress,
  validateWalletAddressTag,
} from "../../model/walletAddress";
import AutoComplete from "../AutoComplete";
import WalletAddressesModal from "./WalletAddressesModal";

import paypalLogo from "../../../assets/images/payment_networks/paypal.png";
import cashappLogo from "../../../assets/images/payment_networks/cashapp.png";
import coinbaseLogo from "../../../assets/images/payment_networks/coinbase.png";
import binanceLogo from "../../../assets/images/payment_networks/binance.png";
import kucoinLogo from "../../../assets/images/payment_networks/kucoin.png";
import robinhoodLogo from "../../../assets/images/payment_networks/robinhood.png";
import { formErrors } from "../../utils/formErrors";

const transferTypes = [
  {
    label: "Recipient's Wallet",
    value: "recipient",
  },
  {
    label: "My Wallet",
    value: "my-wallet",
  },
];

class CryptoTransfer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedSourceWallet: props.selectedSourceWallet,
      selectedDestinationWallet: "",
      destinationWallets: "",
      selectedCurrency: props.selectedSourceToken,
      selectedCurrencyBalance:
        props.selectedSourceCurrencyBalance || parseFloat(0.0).toFixed(2),
      balances: props.balances,
      selectedTransferType: "recipient",
      recipient: props.recipient || "",
      recipients: props.recipients || [],
      amount: parseFloat(0.0).toFixed(2),
      sourceAmount: parseFloat(0.0).toFixed(2),
      netAmount: parseFloat(0.0).toFixed(2),
      recipientError: "",
      error: "",
      amountError: "",
      mode: "form",
      invalidTransfer: false,
      submitting: false,
      validationErrors: {},
      fees: parseFloat(0.0).toFixed(2),
      firstTimeWithdrawalFee: parseFloat(0.0).toFixed(2),
      blockchainAddress: "",
      blockchainAddressTag: "",
      walletAddresses: props.walletAddresses || [],
      submitTransfer: false,
      selectedWalletAddress: "",
      lookedUpRecipient: {},
      withdraw: props.withdraw || false,
    };
  }

  componentDidMount() {}

  showModal = () => {
    $("#walletAddressesModal").modal("show");
  };

  closeModal = () => {
    $("#walletAddressesModal").modal("hide");
  };

  getChainWalletAddress = (chain) => {
    getRequest(`/crypto_transfers/chain_wallet_addresses?chain=${chain}`).then(
      (addresses) => {
        const walletAddresses = addresses.map((address) => {
          return {
            label: address.wallet_label,
            address: address.address,
            addressTag: address.tag,
            value: address.id,
          };
        });

        this.setState({ walletAddresses });
      }
    );
  };

  handleChange = (event, key) => {
    this.setState({ [key]: event.target.value });

    if (key === "selectedTransferType") {
      this.setState({ invalidTransfer: false });
    }
  };

  lookupUser = () => {
    const { validationErrors } = this.state;

    if (this.state.recipient) {
      const data = {
        crypto_transfer: {
          recipient: this.state.recipient,
        },
      };

      const crsfToken = $('meta[name="csrf-token"]').attr("content");

      postRequest("/crypto_transfers/lookup_recipient", data, {
        "X-CSRF-Token": crsfToken,
      })
        .then((response) => {
          validationErrors.recipientError = "";
          this.setState({
            validationErrors,
            invalidTransfer: false,
            lookedUpRecipient: response,
          });
        })
        .catch((error) => {
          validationErrors.recipientError = error.data.error;

          this.setState({
            validationErrors,
            invalidTransfer: true,
          });
        });
    }
  };

  handleAmountChange = (e) => {
    this.setState({ amount: e.target.value }, () => this.validateAmount());
  };

  handleBlockchainAddressChange = (e) => {
    this.setState(
      {
        blockchainAddress: e.target.value,
      },
      () => this.validateBlockchainAddress()
    );
    this.validateBlockchainAddressTag();
  };

  handleBlockchainAddressTagChange = (e) => {
    this.setState({ blockchainAddressTag: e.target.value }, () =>
      this.validateBlockchainAddressTag()
    );
    this.validateBlockchainAddress();
  };

  handleSelectedWalletAddress = (e) => {
    this.setState({ selectedWalletAddress: e.target.value }, () =>
      this.setBlockchainAddress(e.target.value)
    );
    this.validateBlockchainAddress();
  };

  handleSelectedCurrencyChange = (e) => {
    this.setState({ selectedCurrency: e.target.value }, () =>
      this.getChainWalletAddress(e.target.value)
    );
    // console.log('balances', this.state.balances);
    // console.log('selectedBalance', this.state.balances[e.target.value]);
    this.setState({
      selectedCurrencyBalance: this.state.balances[e.target.value],
    });
  };

  setBlockchainAddress = (val) => {
    const { walletAddresses, validationErrors } = this.state;

    const walletAddress = _.find(walletAddresses, function (address) {
      return address.value === val;
    });

    if (undefined === walletAddress) {
      this.setState({ blockchainAddress: "" });
      this.setState({ blockchainAddressTag: "" });
      this.setState({ validationErrors: {} });
    } else {
      this.setState({ blockchainAddress: walletAddress.address });
      this.setState({ blockchainAddressTag: walletAddress.addressTag });
    }
  };

  validateBlockchainAddress = () => {
    const {
      selectedCurrency,
      blockchainAddress,
      blockchainAddressTag,
      validationErrors,
    } = this.state;
    const currency = selectedCurrency.toUpperCase();

    const chain = ["BTC", "AVAX", "AVAXC", "POL", "XLM", "SUSDC"].includes(
      currency
    )
      ? currency
      : "ETH";

    const validAddress = validateWalletAddress(chain, blockchainAddress);

    if (validAddress) {
      validationErrors.blockchainAddressError = "";
    } else {
      validationErrors.blockchainAddressError = "Address is invalid";
    }

    this.setState({ validationErrors });
  };

  validateBlockchainAddressTag = () => {
    const {
      selectedCurrency,
      blockchainAddress,
      blockchainAddressTag,
      validationErrors,
    } = this.state;
    const currency = selectedCurrency.toUpperCase();

    const chain = ["BTC", "AVAX", "XLM"].includes(currency) ? currency : "ETH";

    const validAddressTag = validateWalletAddressTag(
      chain,
      blockchainAddressTag
    );

    if (validAddressTag) {
      validationErrors.blockchainAddressTagError = "";
    } else {
      validationErrors.blockchainAddressTagError =
        "Address tag/memo is missing or not required";
    }

    this.setState({ validationErrors });
  };

  validateAmount = () => {
    const { validationErrors } = this.state;
    this.setState({
      error: "",
      selectedCurrencyBalance: this.state.balances[this.state.selectedCurrency],
    });

    if (Number(this.state.amount) <= 0) {
      validationErrors.amountError =
        "Amount to transfer cannot be zero. Please enter a higher amount.";

      this.setState({
        validationErrors,
        invalidTransfer: true,
      });
    } else if (
      Number(this.state.amount) > Number(this.state.selectedCurrencyBalance)
    ) {
      validationErrors.amountError =
        "Amount to transfer is greater than your available balance, please enter a lower amount.";

      this.setState({
        validationErrors,
        invalidTransfer: true,
      });
    } else {
      validationErrors.amountError = "";
      this.setState({
        validationErrors,
        invalidTransfer: false,
      });
    }
  };

  handleRecipientChange = (value, userExists = false) => {
    const { validationErrors } = this.state;

    validationErrors.recipientError = "";
    this.setState({ invalidTransfer: false });

    this.setState({ recipient: value, recipientError: "" }, () => {
      if (!userExists) {
        this.lookupUser();
      }
    });
  };

  validateFormFields = () => {
    const { amount, recipient, selectedTransferType, blockchainAddress } =
      this.state;
    const { type } = this.props;

    let validationErrors = {};

    if (Number(amount) <= 0) {
      validationErrors.amountError = "amount should be greater than zero";
    }

    if (
      type === "wallet" &&
      selectedTransferType === "recipient" &&
      !recipient
    ) {
      validationErrors.recipientError = "recipient is required";
    }

    if (type === "blockchain" && !blockchainAddress) {
      validationErrors.blockchainAddressError =
        "blockchain address is required";
    }

    return validationErrors;
  };

  refreshPage = (e) => {
    e.preventDefault();
    const url = window.location.href;
    window.location.assign(url);
  };

  setApiErrors = (errors) => {
    const formattedErrors = formErrors(errors);

    const { validationErrors } = this.state;

    const amountError = formattedErrors["gross_amount"];
    const recipientError = formattedErrors["destination_wallet_id"];

    validationErrors.amountError = amountError;
    validationErrors.recipientError = recipientError;

    return validationErrors;
  };

  submitTransferPreview = (e) => {
    const {
      selectedSourceWallet,
      selectedDestinationWallet,
      destinationWallets,
      recipient,
      amount,
      selectedCurrency,
      selectedTransferType,
      blockchainAddress,
      blockchainAddressTag,
      selectedCurrencyBalance,
    } = this.state;

    e.preventDefault();

    const validationErrors = this.validateFormFields();

    if (!_.isEmpty(validationErrors)) {
      this.setState({ validationErrors, invalidTransfer: true });

      return;
    }

    this.setState({ submitting: true, error: "", validationErrors: {} });

    const data = {
      crypto_transfer: {
        total_type: "total_to_withdraw",
        source_wallet_id: selectedSourceWallet,
        recipient: selectedTransferType === "recipient" ? recipient : "",
        destination_wallet_id: selectedDestinationWallet,
        gross_amount: amount,
        source_currency: selectedCurrency,
        net_amount: amount,
        net_amount_currency: selectedCurrency,
        destination_type: this.props.type,
        blockchain_address: blockchainAddress,
        blockchain_address_tag: blockchainAddressTag,
        selected_currency_balance: selectedCurrencyBalance,
        type: "CryptoTransfers::BcCryptoWithdrawal",
      },
    };

    const crsfToken = $('meta[name="csrf-token"]').attr("content");

    postRequest("/crypto_transfers/send_crypto", data, {
      "X-CSRF-Token": crsfToken,
    })
      .then((response) => {
        this.setState({
          fees: response.fees,
          firstTimeWithdrawalFee: response.first_time_withdrawal_fee,
          submitTransfer: true,
          transferId: response.transfer_id,
          sourceAmount: response.source_amount,
          netAmount: response.destination_amount,
        });
      })
      .catch((error) => {
        console.log(error);

        this.setState({ error: error?.data?.error });

        const errors = this.setApiErrors(error?.data?.errors);
        this.setState({ validationErrors: errors, submitting: false });
      });
  };

  submitTransfer = (e) => {
    const { transferId } = this.state;

    e.preventDefault();

    getRequest(`/crypto_transfers/${transferId}/resend_confirmation`).then(
      () => {
        window.location = `/crypto_transfers/${transferId}`;
      }
    );
  };

  cancelTransfer = (e) => {
    const { transferId } = this.state;

    e.preventDefault();

    const data = {
      crypto_transfer: {
        id: transferId,
      },
    };

    const crsfToken = $('meta[name="csrf-token"]').attr("content");

    patchRequest(`/crypto_transfers/${transferId}/cancel`, data, {
      "X-CSRF-Token": crsfToken,
    })
      .then(() => {
        // window.location = `/crypto_transfers/`;
        this.refreshPage(e);
        // window.location = `/crypto_transfers/new_blockchain_to_usd_transfer?token=${selectedCurrency}`;
      })
      .catch((error) => {
        this.setState({ submitting: false });
        console.log(error);
      });
  };

  render() {
    const {
      sourceWallets,
      currencies,
      type,
      recipients,
      isFirstTimeWithdrawal,
    } = this.props;

    const {
      amount,
      selectedSourceWallet,
      selectedDestinationWallet,
      destinationWallets,
      selectedCurrency,
      selectedCurrencyBalance,
      selectedTransferType,
      recipient,
      recipientError,
      mode,
      error,
      amountError,
      invalidTransfer,
      submitting,
      validationErrors,
      sourceAmount,
      fees,
      netAmount,
      submitTransfer,
      transferId,
      blockchainAddress,
      blockchainAddressTag,
      walletAddresses,
      balances,
      selectedWalletAddress,
      lookedUpRecipient,
      withdraw,
      firstTimeWithdrawalFee,
    } = this.state;

    // console.log('currencies', currencies);

    return (
      <div className="container-fluid">
        <form
          className="form-horizontal transfer-form pl-2 pr-4"
          id="new_crypto_transfer"
        >
          <div className="amount-wrapper">
            <div className="form-group row required mt-0 ">
              {type === "wallet" ? (
                <select
                  className="form-control form-control-lg expand-select font-0_9"
                  required="required"
                  value={selectedCurrency}
                  onChange={(e) => this.handleChange(e, "selectedCurrency")}
                >
                  {currencies.map((currency, i) => (
                    <option key={i} value={currency[1]}>
                      {currency[0]}
                    </option>
                  ))}
                </select>
              ) : (
                <select
                  className="form-control form-control-lg expand-select font-0_9 font-weight-bold"
                  required="required"
                  value={selectedCurrency}
                  onChange={this.handleSelectedCurrencyChange}
                >
                  {currencies.map((currency, i) => (
                    <option key={i} value={currency[1]}>
                      {currency[0]}
                    </option>
                  ))}
                </select>
              )}

              <small id="chainHelpBlock" className="form-text text-muted">
                Select from available crypto assets
              </small>
            </div>

            <div className="form-group row required ">
              <label className="form-label">Amount</label>
              <div className="input-group mb-2 mr-sm-2">
                <div className="input-group-prepend">
                  <div
                    className="input-group-text selected-currency font-2_25"
                    style={{ color: "#4c3f84", focus: { color: "#414da7" } }}
                  >
                    {selectedCurrency}
                  </div>
                </div>
                <input
                  step="any"
                  autoFocus="autofocus"
                  style={{ maxWidth: "400px" }}
                  placeholder="0.000"
                  className={`form-control font-2_25 ${
                    validationErrors?.amountError ? "is-invalid" : ""
                  }`}
                  required="required"
                  type="number"
                  value={amount}
                  onChange={this.handleAmountChange}
                  id="amount"
                  disabled={submitting == true ? true : false}
                />
              </div>
              {error && (
                <span className="error text-danger">
                  <br />
                  {error}
                </span>
              )}
              {validationErrors?.amountError && (
                <span className="error text-danger">
                  <br />
                  {validationErrors.amountError}
                </span>
              )}
              <small id="amountHelpBlock" className="form-text text-muted">
                Enter total transfer amount desired ({selectedCurrency}).
                {["USDT", "USDC", "USDS", "ZUSD"].includes(
                  selectedCurrency
                ) && <span> Minimum 15.00</span>}
              </small>
            </div>

            <div className="source-wallet-wrapper">
              <select
                className="border-0 mx-0 px-0"
                style={{
                  color: "transparent",
                  WebkitAppearance: "none",
                  MozAppearance: "none",
                }}
                value={selectedSourceWallet}
                onChange={(e) => this.handleChange(e, "selectedSourceWallet")}
              >
                {sourceWallets.map((wallet, i) => (
                  <option value={wallet[1]} key={i}>
                    {/* {wallet[0]} */}
                  </option>
                ))}
              </select>
            </div>

            <div className="recipient-wrapper">
              {type === "blockchain" && (
                <>
                  <div className="form-group row required recipient">
                    <div className="row ml-2">
                      <label className="form-label">To:</label>
                    </div>
                    <div className="input-group mb-2">
                      <input
                        step="any"
                        autoFocus="autofocus"
                        style={{ maxWidth: "400px" }}
                        className={`form-control font-0_9 ${
                          validationErrors?.blockchainAddressError
                            ? "is-invalid"
                            : ""
                        }`}
                        required="required"
                        placeholder="Payment network or blockchain wallet address"
                        type="text"
                        value={blockchainAddress}
                        onChange={this.handleBlockchainAddressChange}
                      />
                      <div className="input-group-append border border-dark">
                        <span className="input-group-text border-0 mx-0 px-0">
                          <a
                            href="#"
                            role="button"
                            className="btn btn-link pull-left border-0"
                            data-toggle="modal"
                            onClick={this.showModal}
                          >
                            <i className="fa fa-book "></i>{" "}
                          </a>
                        </span>
                      </div>
                    </div>
                    {validationErrors?.blockchainAddressError && (
                      <div className="error text-danger">
                        <br />
                        {validationErrors.blockchainAddressError}
                      </div>
                    )}
                    <div
                      id="amountHelpBlock"
                      className="form-text text-muted mb-4 font-0_8"
                    >
                      Go to your payment network or blockchain wallet and copy
                      the {selectedCurrency} address. You can use addresses from
                      PayPal, CashApp, Coinbase, Binance, KuCoin, Robinhood, or
                      any other wallet that supports {selectedCurrency}.
                    </div>
                    <br />
                    {selectedCurrency == "XLM" && (
                      <>
                        <input
                          step="any"
                          autoFocus={false}
                          className={`form-control ${
                            validationErrors?.blockchainAddressTagError
                              ? "is-invalid"
                              : ""
                          }`}
                          required={false}
                          placeholder="Enter the blockchain address tag or memo"
                          type="text"
                          value={blockchainAddressTag}
                          onChange={this.handleBlockchainAddressTagChange}
                        />
                        <div
                          id="addressTagtHelpBlock"
                          className="form-text text-muted mb-4 font-0_8"
                        >
                          Enter the blockchain address tag or memo for
                          blockchains that require it, like Stellar (XLM).
                        </div>
                      </>
                    )}
                    {validationErrors?.blockchainAddressTagError && (
                      <div className="error text-danger">
                        <br />
                        {validationErrors.blockchainAddressTagError}
                      </div>
                    )}

                    <div className="payment-networks">
                      <img src={paypalLogo} width="60" className=" pl-2 pr-2" />
                      <img
                        src={cashappLogo}
                        width="110"
                        className="pl-2 pr-2"
                      />
                      <img
                        src={coinbaseLogo}
                        width="100"
                        className="pl-2 pr-2"
                      />
                      <img src={binanceLogo} width="70" className="pl-2 pr-2" />
                      <img src={kucoinLogo} width="70" className="pl-2 pr-2" />
                      <img
                        src={robinhoodLogo}
                        width="100"
                        className="pl-2 pr-2"
                      />
                      <span style={{ width: 40 }} className="pl-2 pr-2">
                        <strong>
                          <i className="fa fa-qrcode fa-2x "></i>
                        </strong>
                      </span>
                    </div>

                    <div className="alert alert-warning font-0_9 pt-2">
                      <p>
                        <i className="fa fa-exclamation-circle"></i> To avoid
                        loss of funds, ensure the wallet address entered is
                        correct for {selectedCurrency}
                        {selectedCurrency == "USDT" && (
                          <span> on Polygon </span>
                        )}
                      </p>
                    </div>
                    <br />
                  </div>
                </>
              )}
              {type === "wallet" && (
                <div>
                  {destinationWallets.length > 0 && (
                    <div className="form-group row required mt-2 ">
                      <label className="form-label">
                        Select the crypto wallet
                      </label>
                      <select
                        className="form-control form-control-lg expand-select"
                        required="required"
                        value={selectedTransferType}
                        disabled={submitting == true ? true : false}
                        onChange={(e) =>
                          this.handleChange(e, "selectedTransferType")
                        }
                      >
                        <option value="">-- Select the Wallet ---</option>
                        {transferTypes.map((transferType, i) => (
                          <option key={i} value={transferType.value}>
                            {transferType.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  {(destinationWallets.length === 0 ||
                    selectedTransferType === "recipient") && (
                    <div className="form-group row required recipient">
                      <label className="form-label">Recipient</label>
                      <AutoComplete
                        updateRecipient={this.handleRecipientChange}
                        placeholder="Enter your recipient's username or email"
                        className={`form-control ${
                          validationErrors?.recipientError ? "is-invalid" : ""
                        }`}
                        disabled={submitting == true ? true : false}
                        suggestions={recipients}
                        lookedUpRecipient={lookedUpRecipient}
                        userInput={recipient}
                      />
                      {validationErrors?.recipientError && (
                        <span className="error text-danger">
                          <br />
                          {validationErrors.recipientError}
                        </span>
                      )}
                      <small
                        id="amountHelpBlock"
                        className="form-text text-muted"
                      >
                        Enter username or email of the recipient
                      </small>
                    </div>
                  )}
                  {selectedTransferType === "my-wallet" && (
                    <div className="form-group row required destination_wallet">
                      <label className="form-label">Destination wallet</label>
                      <select
                        className="form-control form-control-lg expand-select"
                        value={selectedDestinationWallet}
                        onChange={(e) =>
                          this.handleChange(e, "selectedDestinationWallet")
                        }
                      >
                        {destinationWallets.map((wallet, i) => (
                          <option key={i} value={wallet[1]}>
                            {wallet[0]}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="preview-wrapper">
              <h4>Review Your Transfer Details</h4>
              <hr />
              <div className="mx-1">
                <div className="row">
                  <div className="col-md-5">
                    <div className="form-group row d-block ">
                      <label className="form-label">
                        Amount
                        <a
                          data-toggle="tooltip"
                          className="btn btn-link"
                          data-original-title="Subtotal amount (Crypto)."
                        >
                          <i className="fa fa-info-circle"></i>
                        </a>
                      </label>
                      <small
                        id="netAmountHelpBlock"
                        className="form-text text-muted"
                      ></small>
                    </div>{" "}
                  </div>
                  <div className="col-md-7">
                    <div className="form-group row ">
                      <div className="input-group mb-2 mr-sm-2">
                        <div className="input-group-prepend">
                          <div className="input-group-text selected-currency border-0">
                            {selectedCurrency}
                          </div>
                        </div>
                        <input
                          className="form-control border-0 pr-0"
                          placeholder="0.00"
                          data-target="cryptoTransfer.outputNetAmount"
                          required="required"
                          readOnly="readonly"
                          type="number"
                          value={amount}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-5">
                    <div className="form-group row d-block ">
                      <label className="form-label">
                        Fee
                        <a
                          data-toggle="tooltip"
                          className="btn btn-link"
                          data-original-title="Transaction and processing fees (Crypto)"
                        >
                          <i className="fa fa-info-circle"></i>
                        </a>
                      </label>
                      <small
                        id="feesHelpBlock"
                        className="form-text text-muted"
                      ></small>
                    </div>{" "}
                  </div>
                  <div className="col-md-7">
                    <div className="form-group row ">
                      <div className="input-group mb-2 mr-sm-2">
                        <div className="input-group-prepend">
                          <div className="input-group-text selected-currency border-0">
                            {selectedCurrency}
                          </div>
                        </div>
                        <input
                          className="form-control border-0"
                          placeholder="TBD"
                          data-target="cryptoTransfer.outputFeesAmount"
                          required="required"
                          readOnly="readonly"
                          type="number"
                          value={
                            selectedCurrency === "USDT"
                              ? parseFloat(firstTimeWithdrawalFee).toFixed(2)
                              : parseFloat(firstTimeWithdrawalFee).toFixed(7)
                          }
                        />
                      </div>
                    </div>{" "}
                  </div>
                </div>
                {isFirstTimeWithdrawal && (
                  <div className="row">
                    <div className="col-md-5">
                      <div className="form-group row d-block ">
                        <label className="form-label pr-0">
                          One Time Setup Fee
                          <a
                            data-toggle="tooltip"
                            className="btn btn-link pl-1 pr-0"
                            data-original-title="Transaction and processing fees (Crypto)"
                          >
                            <i className="fa fa-info-circle"></i>
                          </a>
                        </label>
                        <small
                          id="feesHelpBlock"
                          className="form-text text-muted"
                        ></small>
                      </div>{" "}
                    </div>
                    <div className="col-md-7">
                      <div className="form-group row ">
                        <div className="input-group mb-2 mr-sm-2">
                          <div className="input-group-prepend">
                            <div className="input-group-text selected-currency border-0">
                              {selectedCurrency}
                            </div>
                          </div>
                          <input
                            className="form-control border-0"
                            placeholder="TBD"
                            data-target="cryptoTransfer.outputFeesAmount"
                            required="required"
                            readOnly="readonly"
                            type="number"
                            value={
                              selectedCurrency === "USDT"
                                ? parseFloat(fees).toFixed(2)
                                : parseFloat(fees).toFixed(7)
                            }
                          />
                        </div>
                      </div>{" "}
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="col-md-5">
                    <div className="form-group row d-block ">
                      <label className="form-label">
                        Total
                        <a
                          data-toggle="tooltip"
                          className="btn btn-link"
                          data-original-title="Transaction and processing fees (Crypto)"
                        >
                          <i className="fa fa-info-circle"></i>
                        </a>
                      </label>
                      <small
                        id="feesHelpBlock"
                        className="form-text text-muted"
                      ></small>
                    </div>{" "}
                  </div>
                  <div className="col-md-7">
                    <div className="form-group row ">
                      <div className="input-group mb-2 mr-sm-2">
                        <div className="input-group-prepend">
                          <div className="input-group-text selected-currency border-0">
                            {selectedCurrency}
                          </div>
                        </div>
                        <input
                          className="form-control border-0"
                          placeholder="TBD"
                          data-target="cryptoTransfer.outputFeesAmount"
                          required="required"
                          readOnly="readonly"
                          type="number"
                          value={
                            selectedCurrency === "USDT"
                              ? parseFloat(netAmount).toFixed(2)
                              : parseFloat(netAmount).toFixed(7)
                          }
                        />
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className="text-muted font-0_9 text-right">
              {submitting ? (
                <div className="pt-4 mt-2">
                  <i className="fa fa-exclamation-circle"></i> The quoted
                  transfer fees are <strong>valid for the next 10 min</strong>.
                  If your quote expires, simply start again. Click Submit to
                  continue.
                </div>
              ) : (
                <div>
                  <i className="fa fa-exclamation-circle"></i> Click Preview
                  Transfer to preview your transfer including the applicable
                  network fees.
                </div>
              )}
            </div>
            <hr />
            <div className="form-actions">
              {submitTransfer && transferId ? (
                <div className="mr-auto">
                  <div className="col-md-6 float-md-right pr-0">
                    <button
                      name="button"
                      type="submit"
                      className="btn btn-primary pull-right ml-4 mb-4"
                      onClick={this.submitTransfer}
                    >
                      Submit
                    </button>
                  </div>
                  <div className="col-md-6 float-md-left pl-0">
                    <button
                      name="cancel"
                      type="submit"
                      className="btn pull-left ml-4 mb-4"
                      onClick={this.cancelTransfer}
                      disabled={invalidTransfer}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              ) : (
                <div className="mr-auto">
                  <div className="col-md-6 float-md-right pr-0">
                    <button
                      name="button"
                      type="submit"
                      className="btn btn-primary pull-right mb-2 col-xs-4"
                      onClick={this.submitTransferPreview}
                      disabled={invalidTransfer || submitting}
                    >
                      {submitting ? (
                        <>
                          <i className="fa fa-circle-o-notch fa-spin"></i>
                          <span style={{ paddingLeft: "5px" }}>
                            Please Wait...Loading
                          </span>
                        </>
                      ) : (
                        <span>Preview Transfer</span>
                      )}
                    </button>
                  </div>
                  <div className="col-md-6 float-md-left pl-0">
                    <button
                      name="start_again"
                      type="submit"
                      className="btn pull-left mb-2"
                      onClick={this.refreshPage}
                    >
                      Start Again
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </form>
        <WalletAddressesModal
          walletAddresses={walletAddresses}
          availableWalletAddresses={walletAddresses}
          selectedCurrency={selectedCurrency}
          closeModal={this.closeModal}
          handleSelectedWalletAddress={this.handleSelectedWalletAddress}
        />
      </div>
    );
  }
}

export default CryptoTransfer;
