import React, { Component } from "react";
import _ from "underscore";
import tokens from "./tokens.json";
import "./index.scss";

import coinbaseLogo from "../../../assets/images/payment_networks/coinbase.png";
import binanceLogo from "../../../assets/images/payment_networks/binance.png";
import kucoinLogo from "../../../assets/images/payment_networks/kucoin.png";
import robinhoodLogo from "../../../assets/images/payment_networks/robinhood.png";
import opaig from "../../../assets/images/crypto/opaig.png";
import { IconCopy } from "../Dashboard/dashboardIcons";

const maticImage =
  "https://media.bananacrystal.com/wp-content/uploads/2023/07/20151846/matic.png";
class SelectToken extends Component {
  constructor(props) {
    super(props);

    this.state = {
      preferredCurrency: props.preferredCurrency,
      selectedToken: props.selectedToken || "POL",
      source: props.source,
      chain: props.chain,
      tokenAddress: props.tokenAddress,
      tokenImage: props.tokenImage,
      isCopied: false,
    };
  }

  copyToClipboard = () => {
    const addressInput = document.getElementById("tokenAddressInput");
    addressInput.select();
    navigator.clipboard.writeText(this.state.tokenAddress).then(
      () => {
        this.setState({ isCopied: true });
        setTimeout(() => {
          this.setState({ isCopied: false });
        }, 2000);
      },
      (err) => {
        console.error("Could not copy text: ", err);
      }
    );
  };

  render() {
    const { selectedToken, tokenAddress, tokenImage, isCopied, chain } =
      this.state;

    const token = tokens.find((token) => token.value === selectedToken);

    return (
      <div className="select-token-wrapper mb-5 d-flex flex-column flex-md-row">
        <div className="card-body">
          <div className="card-network">
            <p className="row-title font-1_0">Deposit Network</p>
            <ul>
              <li className="active">POL</li>
              <li>
                <img className="mr-2" src={maticImage} width={24} height={24} />
                POLYGON NETWORK
              </li>
            </ul>
            <p className="font-0_8 text-warning">
              <i className="fa fa-clock"></i> Average Arrival Time:{" "}
              <strong>3 Minutes</strong>
            </p>
          </div>
          <div className="card-coin">
            <div className="form-row">
              <div className="w-100">
                <label className="font-1_0">
                  <p className="row-title">Coin:</p>
                </label>
                <div className="input-group mb-2 coin-input-wrapper">
                  <div className="input-group-prepend">
                    <div className="input-group-text p-1">
                      <div className="d-flex align-items-center coin-image-input">
                        <img
                          src={token.value === "OPAIG" ? opaig : token.image}
                          alt={token.name}
                          height={24}
                          width={24}
                        />
                        <span className="ml-1 font-weight-bold">
                          {token.value}
                        </span>
                      </div>
                    </div>
                  </div>
                  <input
                    type="text"
                    className="form-control cursor-pointer"
                    id="inlineFormInputGroup"
                    placeholder="Select Coin"
                    value={token.label}
                    disabled={true}
                  />
                  <div className="drop-icon-wrapper">
                    <i className="fa fa-chevron-down"></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="w-100">
                <label className="row-title">
                  <p className="">Your {token.value} Address:</p>
                </label>
                <div className="input-group mb-2 address-input-wrapper">
                  <input
                    type="text"
                    className="form-control"
                    id="tokenAddressInput"
                    placeholder="Select Coin"
                    value={tokenAddress}
                  />
                  <div className="drop-icon-wrapper">
                    <div
                      className="address-copy-btn"
                      onClick={this.copyToClipboard}
                    >
                      {isCopied ? (
                        <>
                          Copied <i className="fa fa-check"></i>
                        </>
                      ) : (
                        <>
                          Copy <IconCopy />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-row mt-4">
              <div className="w-100">
                <div className="alert-gray-light">
                  <p className="font-0_9 font-weight-bold">
                    <i className="fa fa-info-circle"></i> Warning
                  </p>
                  <p className="font-0_8">
                    Only send {token.label} to this address. Using this address
                    for another blockchain or other coins or tokens will result
                    in the irreversible loss of your funds. Please always check
                    with your sending party that the blockchain is Polygon
                    network and the cryptocurrency is {token.label}.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-body d-flex align-items-center mt-md-5">
          <div className="light-bg">
            {this.state.tokenImage && (
              <div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: tokenImage,
                  }}
                />
                <p className="text-center font-weight-bold">
                  Scan the {token.value} address above
                </p>
              </div>
            )}
            <div className="d-flex justify-content-center align-items-center flex-wrap mt-5 supported-exchanges-wrapper">
              <img src={coinbaseLogo} width="120" alt="Coinbase" />
              <img src={binanceLogo} width="80" alt="Binance" />
              <img
                src={kucoinLogo}
                width="70"
                style={{
                  marginTop: "-15px",
                  paddingLeft: "6px",
                  paddingRight: "10px",
                }}
                alt="KuCoin"
              />
              <img src={robinhoodLogo} width="120" alt="Robinhood" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default SelectToken;
