import React from "react";

const DeleteModal = ({ handleShouldDelete, closeDeleteModal}) => {

  return (
    <div
      className="modal-delistoffers-alert-cont"
      tabIndex="-1"
      aria-hidden="true"
      aria-labelledby="delistOffers"
    >
      <div>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          onClick={closeDeleteModal}
        >
          <span aria-hidden="true">&times;</span>
        </button>

        <h5 className="delistoffer-modal-title">
        <i className="fas fa-exclamation-circle"></i>
          <span>Are you sure?</span>
        </h5>
        <div className="delistoffers-modal-notice" data-controller="clipboard">
          This action cannot be undone
        </div>
        <div className="delistoffer-modal-buttons-section">
          <button
            type="button"
            className="delistoffer-modal-tradeaction-proceedbtn"
            onClick={handleShouldDelete}
          >
            Proceed
          </button>
        </div>
      </div>
    </div>
  )
}

export default DeleteModal;
