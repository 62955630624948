import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["amount"]

  connect() {
  }

  // appends the fiat amount to the iframe url before loading
  getFundingAmount() {
    let url = document.getElementById("add_funds_next").href;

    document.getElementById("add_funds_next").href = `${url}&fiat_amount=${this.amountTarget.value}`

  }
  // disables the amount input field
  disableInputField() {
    document.getElementById("amount").disabled = true;
    document.getElementById("add_funds_currency").className = 'input-group-text selected-currency font-2_25 border-0 text-muted'
    document.getElementById("amount").className = 'form-control font-2_25 border-0 text-muted'
    document.getElementById("add_funds_next").style.display = 'none'

  }
}
