import React, { Component } from "react";
import Modal from "react-modal";
import ReactLoading from "react-loading";

const customStyles = {
  content: {
    width: "30%",
    margin: "0 auto",
    top: "10%",
    borderRadius: "12px",
    height: "35%"
  }
};

const ConfirmationModal = ({
  showConfirmationModal,
  handleCloseConfirmModal
}) => (
  <Modal
    isOpen={showConfirmationModal}
    style={customStyles}
    ariaHideApp={false}
  >
    <div className="token-modal confirmation-modal">
      <div className="header">
        <a href="#" onClick={e => handleCloseConfirmModal(e)}>
          <p>
            <i className="fa fa-times"></i>
          </p>
        </a>
      </div>
      <div>
        <div className="loading">
          <ReactLoading
            type="spin"
            color="#0f59d1"
            height={30}
            width={30}
            className="loader"
          />
        </div>
        <div className="message">
          <h4>Waiting For Confirmation</h4>
          <p>Processing conversion...</p>
        </div>
      </div>
    </div>
  </Modal>
);

export default ConfirmationModal;
