import React, { useEffect } from "react";
import AmountInput from "../../components/Pricing/AmountInput";

interface Props {
  amount: string;
  tradeType: string;
  availableBalance: string;
  canEditAmount: boolean;
  tradeFees: number;
  updateAmount: (_fieldName: string, value: string) => void;
  setAmount: (amount: string) => void;
}

const Amount: React.FC<Props> = ({
  amount,
  tradeType,
  availableBalance,
  tradeFees,
  canEditAmount,
  updateAmount,
  setAmount,
}) => {

  useEffect(() => {
    const inputElement = document.querySelector('#editoffer-amountinput #pricing-totalamount-input') as HTMLInputElement;
    
    if (inputElement) {
      inputElement.setAttribute('readonly', 'true');
      inputElement.setAttribute('title', 'Amount Remaining for This Offer');
    }
  }, []); 
  
  return (
    <div className="rate editoffer-rate">
      <div id="editoffer-amountinput">
        <AmountInput
          amount={amount}
          canEditAmount={canEditAmount}
          tradeType={tradeType}
          accountBalance={availableBalance}
          feePercent={tradeFees}
          updateParent={updateAmount}
          setAmount={setAmount}
        />
      </div>
    </div>
  );
};

export default Amount;
