import React, { Component, useState, useEffect } from "react";
import _ from "underscore";
import { getRequest, postRequest, patchRequest } from "../../utils/httpRequest";
import {
  validateWalletAddress,
  validateWalletAddressTag
} from "../../model/walletAddress";
import { formErrors } from "../../utils/formErrors";
import AddFundsModal from "./AddFundsModal";

const transferTypes = [
  {
    label: "Recipient's Wallet",
    value: "recipient"
  },
  {
    label: "My Wallet",
    value: "my-wallet"
  }
];

class CryptoTransferPaySubscriptions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuth: props.isAuth || false,
      selectedSourceWallet: props.selectedSourceWallet,
      selectedDestinationWallet: "",
      destinationWallets: "",
      selectedCurrency: props.selectedSourceToken,
      selectedCurrencyBalance: props.selectedSourceCurrencyBalance,
      alternateCurrency: props.alternateCurrency,
      alternateCurrencyBalance: parseFloat(
        props.alternateCurrencyBalance
      ).toFixed(2),
      alternateCurrencyRate: parseFloat(props.alternateCurrencyRate).toFixed(2),
      balances: props.balances,
      selectedTransferType: "recipient",
      recipient: props.recipient || "",
      storeName: props.storeName || "",
      storeReturnLink: props.storeReturnLink || "",
      amount: parseFloat(props.amount).toFixed(2) || parseFloat(0.0).toFixed(2),
      recipientError: "",
      error: "",
      amountError: "",
      mode: "form",
      invalidTransfer: false,
      submitting: false,
      validationErrors: {},
      fees: 0.0,
      blockchainAddress: "",
      blockchainAddressTag: "",
      walletAddresses: props.walletAddresses || [],
      submitTransfer: false,
      selectedWalletAddress: "",
      note: props.note || "",
      heading: props.heading || "PAY",
      senderRef: props.senderRef || "",
      senderData: props.senderData || "",
      bid: props.bid || "",
      isPayButton: props.isPayButton || false,
      isWpPlugin: props.isWpPlugin || true,
      subscriberUserId: props.subscriberUserId || "",
      subscriberUsername: props.subscriberUsername || "",
      partnerId: props.partnerId || ""
    };
  }

  componentDidMount() {
    if (Number(this.state.amount) > Number(this.state.selectedCurrencyBalance) && this.state.isAuth) {
      this.showModal();
    }
  }

  addFundsPage = (linkText) => {
    return <a href="/add_funds" target="_blank">
    {linkText}
  </a>
  }
  
  addFundsButton = (linkText) => {
    return <div className="row mx-auto mt-4">
                <div className="col-md-12">
                  <center>
                  <a
                    href="/add_funds"
                    target="_blank"
                    role="button"
                    className="btn btn-primary ml-2 mr-2"
                  >{linkText}
                  </a>
                  </center>
                </div>
              </div>
  }

  backToMerchantLink = (storeName, storeReturnLink) => {
    return <a href={storeReturnLink} className="">
    Return back to <strong>{storeName}</strong>
  </a>
  }
  

  showModal = () => {
    $("#addFundsModal").modal("show");
  };
  
  closeModal = () => {
    $("#addFundsModal").modal("hide");
  };
  
  AddFundsModalLink = (modalLinkText) => {
    return <div className="row mx-auto mt-4">
              <div className="col-md-12">
                <center>
                <a
                  href="#"
                  role="button"
                  className="btn btn-primary ml-2 mr-2"
                  data-toggle="modal"
                  onClick={this.showModal}
                >{modalLinkText}
                </a>
                </center>
              </div>
            </div>
  }


  getChainWalletAddress = chain => {
    getRequest(`/crypto_transfers/chain_wallet_addresses?chain=${chain}`).then(
      addresses => {
        const walletAddresses = addresses.map(address => {
          return {
            label: address.wallet_label,
            address: address.address,
            addressTag: address.tag,
            value: address.id
          };
        });

        this.setState({ walletAddresses });
      }
    );
  };

  handleChange = (event, key) => {
    this.setState({ [key]: event.target.value });

    if (key === "selectedTransferType") {
      this.setState({ invalidTransfer: false });
    }
  };

  handleRecipientChange = e => {
    const { validationErrors } = this.state;
    validationErrors.recipientError = "";

    this.setState({ recipient: e.target.value, recipientError: "" }, () =>
      this.lookupUser()
    );
  };

  lookupUser = () => {
    console.log("recipient", this.state.recipient);
    const { validationErrors } = this.state;

    if (this.state.recipient) {
      const data = {
        crypto_transfer: {
          recipient: this.state.recipient
        }
      };

      const crsfToken = $('meta[name="csrf-token"]').attr("content");

      postRequest("/crypto_transfers/lookup_recipient", data, {
        "X-CSRF-Token": crsfToken
      })
        .then(response => {
          validationErrors.recipientError = "";
          this.setState({
            validationErrors,
            invalidTransfer: false
          });
        })
        .catch(error => {
          validationErrors.recipientError = error.data.error;

          this.setState({
            validationErrors,
            invalidTransfer: true
          });
        });
    }
  };

  handleAmountChange = e => {
    this.setState({ amount: e.target.value }, () => this.validateAmount());
  };

  handleBlockchainAddressChange = e => {
    this.setState(
      {
        blockchainAddress: e.target.value
      },
      () => this.validateBlockchainAddress()
    );
    this.validateBlockchainAddressTag();
  };

  handleBlockchainAddressTagChange = e => {
    this.setState({ blockchainAddressTag: e.target.value }, () =>
      this.validateBlockchainAddressTag()
    );
    this.validateBlockchainAddress();
  };

  handleSelectedWalletAddress = e => {
    this.setState({ selectedWalletAddress: e.target.value }, () =>
      this.setBlockchainAddress(e.target.value)
    );
    this.validateBlockchainAddress();
  };

  handleSelectedCurrencyChange = e => {
    this.setState({ selectedCurrency: e.target.value }, () =>
      this.getChainWalletAddress(e.target.value)
    );
    // console.log('balances', this.state.balances);
    // console.log('selectedBalance', this.state.balances[e.target.value]);
    this.setState({
      selectedCurrencyBalance: this.state.balances[e.target.value]
    });
  };

  handleNoteChange = e => {
    this.setState({ note: e.target.value });
  };

  handleSenderRefChange = e => {
    this.setState({ senderRef: e.target.value });
  };

  setBlockchainAddress = val => {
    const { walletAddresses, validationErrors } = this.state;

    const walletAddress = _.find(walletAddresses, function(address) {
      return address.value === val;
    });

    if (undefined === walletAddress) {
      this.setState({ blockchainAddress: "" });
      this.setState({ blockchainAddressTag: "" });
      this.setState({ validationErrors: {} });
    } else {
      this.setState({ blockchainAddress: walletAddress.address });
      this.setState({ blockchainAddressTag: walletAddress.addressTag });
    }
  };

  validateBlockchainAddress = () => {
    const {
      selectedCurrency,
      blockchainAddress,
      blockchainAddressTag,
      validationErrors
    } = this.state;
    const currency = selectedCurrency.toUpperCase();

    const chain = ["BTC", "AVAX", "XLM"].includes(currency) ? currency : "ETH";

    const validAddress = validateWalletAddress(chain, blockchainAddress);

    if (validAddress) {
      validationErrors.blockchainAddressError = "";
    } else {
      validationErrors.blockchainAddressError = "Address is invalid";
    }

    this.setState({ validationErrors });
  };

  validateBlockchainAddressTag = () => {
    const {
      selectedCurrency,
      blockchainAddress,
      blockchainAddressTag,
      validationErrors
    } = this.state;
    const currency = selectedCurrency.toUpperCase();

    const chain = ["BTC", "AVAX", "XLM"].includes(currency) ? currency : "ETH";

    const validAddressTag = validateWalletAddressTag(
      chain,
      blockchainAddressTag
    );

    if (validAddressTag) {
      validationErrors.blockchainAddressTagError = "";
    } else {
      validationErrors.blockchainAddressTagError =
        "Address tag/memo is missing or not required";
    }

    this.setState({ validationErrors });
  };

  validateAmount = () => {
    const { validationErrors } = this.state;
    this.setState({
      error: "",
      selectedCurrencyBalance: this.state.balances[this.state.selectedCurrency]
    });

    if (Number(this.state.amount) < 0.01) {
      validationErrors.amountError =
        "Amount to transfer needs to be greater than or equal to 0.01. Please enter a higher amount.";

      this.setState({
        validationErrors,
        invalidTransfer: true
      });
    } else if (
        Number(this.state.amount) > Number(this.state.selectedCurrencyBalance) && (this.state.isAuth)
      ) {
      validationErrors.amountError = <>
        Amount to pay or transfer is greater than your available balance, please {this.addFundsPage('fund your account')}
        <br/>
        {this.addFundsButton('Add Funds')}
        </>;

      this.setState({
        validationErrors,
        invalidTransfer: true
      });
    } else {
      validationErrors.amountError = "";
      this.setState({
        validationErrors,
        invalidTransfer: false
      });
    }
  };

  validateFormFields = () => {
    const {
      amount,
      recipient,
      selectedTransferType,
      blockchainAddress
    } = this.state;
    const { type } = this.props;

    let validationErrors = {};

    if (Number(amount) <= 0) {
      validationErrors.amountError = "amount should be greater than zero";
    }

    if (
      type === "wallet" &&
      selectedTransferType === "recipient" &&
      !recipient
    ) {
      validationErrors.recipientError = "recipient is required";
    }

    if (type === "blockchain" && !blockchainAddress) {
      validationErrors.blockchainAddressError =
        "blockchain address is required";
    }

    return validationErrors;
  };

  refreshPage = e => {
    e.preventDefault();
    const url = window.location.href;
    window.location.assign(url);
  };

  setApiErrors = errors => {
    const formattedErrors = formErrors(errors);

    const { validationErrors } = this.state;

    const amountError = formattedErrors["gross_amount"];
    const recipientError = formattedErrors["destination_wallet_id"];

    validationErrors.amountError = amountError;
    validationErrors.recipientError = recipientError;

    return validationErrors;
  };

  submitTransferPreview = e => {
    const {
      subscriptionUserId,
      subscriptionUsername,
      subscriptionPlanId,
      partnerId
    } = this.props;

    const {
      selectedSourceWallet,
      selectedDestinationWallet,
      destinationWallets,
      recipient,
      amount,
      fees,
      selectedCurrency,
      selectedTransferType,
      blockchainAddress,
      blockchainAddressTag,
      note,
      senderRef,
      senderData,
      bid,
      selectedCurrencyBalance,
      sub
    } = this.state;

    // console.log(this.state);
    e.preventDefault();

    const validationErrors = this.validateFormFields();

    if (!_.isEmpty(validationErrors)) {
      this.setState({ validationErrors, invalidTransfer: true });

      return;
    }

    this.setState({ submitting: true, error: "", validationErrors: {} });

    const data = {
      crypto_transfer: {
        destination_type: "wallet",
        total_type: "total_to_withdraw",
        source_wallet_id: selectedSourceWallet,
        recipient: selectedTransferType === "recipient" ? recipient : "",
        destination_wallet_id: selectedDestinationWallet,
        gross_amount: amount,
        gross_amount_currency: "USDT",
        fees: 0.0,
        fees_currency: "USDT",
        net_amount: amount,
        net_amount_currency: "USDT",
        source_currency: "USDT",
        destination_currency: "USDT",
        destination_type: this.props.type,
        blockchain_address: blockchainAddress,
        blockchain_address_tag: blockchainAddressTag,
        note: note,
        sender_ref: senderRef,
        sender_data: senderData,
        bid: bid,
        selected_currency_balance: selectedCurrencyBalance,
        // Integrations
        subscription_plan_id: this.props.subscriptionPlanId,
        subscriber_user_id: this.props.subscriberUserId,
        subscriber_username: this.props.subscriberUsername,
        partner_id: this.props.partnerId,
        type: "CryptoTransfers::Payme"
      }
    };

    const crsfToken = $('meta[name="csrf-token"]').attr("content");

    postRequest("/crypto_transfers/send_crypto", data, {
      "X-CSRF-Token": crsfToken
    })
      .then(response => {
        this.setState({
          fees: response.fees,
          submitTransfer: true,
          transferId: response.transfer_id
        });
      })
      .catch(error => {
        console.log(error);

        const errorMessage =
          error?.data?.error === "Insufficient funds"
            ? "Insufficient funds, please enter a lower amount to cover the transaction and/or network fees."
            : error?.data?.error;

        const errors = this.setApiErrors(error.data.errors);

        this.setState({
          validationErrors: errors,
          submitting: false,
          error: errorMessage
        });
      });
  };

  submitTransfer = e => {
    const { transferId } = this.state;

    e.preventDefault();

    getRequest(`/crypto_transfers/${transferId}/resend_confirmation`).then(
      () => {
        window.location = `/crypto_transfers/${transferId}`;
        // window.location = `/wallets/digital_dollars?confirm=${transferId}`;
      }
    );
  };

  cancelTransfer = e => {
    const { transferId } = this.state;

    e.preventDefault();

    const data = {
      crypto_transfer: {
        id: transferId
      }
    };

    const crsfToken = $('meta[name="csrf-token"]').attr("content");

    patchRequest(`/crypto_transfers/${transferId}/cancel`, data, {
      "X-CSRF-Token": crsfToken
    })
      .then(() => {
        // window.location = `/crypto_transfers/`;
        this.refreshPage(e);
        // window.location = `/crypto_transfers/new_blockchain_to_usd_transfer?token=${selectedCurrency}`;
      })
      .catch(error => {
        this.setState({ submitting: false });
        console.log(error);
      });
  };

  redirectToLogin = e => {
    e.preventDefault();
    let currentPath = `${location.protocol}//${window.location.host}${window.location.pathname}${window.location.search}`;
    // console.log('currentPath', currentPath)
    window.location = `/users/sign_in?redirect_to=${currentPath}`;
  };

  render() {
    const { sourceWallets, currencies, type, subscriptionPlanId } = this.props;

    const {
      isAuth,
      amount,
      selectedSourceWallet,
      selectedDestinationWallet,
      destinationWallets,
      selectedCurrency,
      selectedCurrencyBalance,
      alternateCurrency,
      alternateCurrencyBalance,
      alternateCurrencyRate,
      selectedTransferType,
      recipient,
      storeName,
      storeReturnLink,
      recipientError,
      mode,
      error,
      amountError,
      invalidTransfer,
      submitting,
      validationErrors,
      fees,
      submitTransfer,
      transferId,
      blockchainAddress,
      blockchainAddressTag,
      walletAddresses,
      balances,
      selectedWalletAddress,
      note,
      heading,
      senderRef,
      bid,
      isPayButton,
      isWpPlugin
    } = this.state;

    // console.log('currencies', currencies);

    return (
      <div className="col-md-12">
        <form
          className="form-horizontal transfer-form"
          id="new_crypto_transfer"
        >
          <div className="col-12">
            <div className="form-group row required ">
              <label className="form-label">
                {isWpPlugin ? "Order Total" : "Amount"}
              </label>
              <div className="input-group mb-2 mr-sm-2">
                <div className="input-group-prepend">
                  <div
                    className={`input-group-text selected-currency font-2_25 ${
                      isPayButton || isWpPlugin ? "border-0 lightgray-bg" : ""
                    }`}
                    style={{ color: "#4173a7", focus: { color: "#414da7" } }}
                  >
                    USD
                  </div>
                </div>
                <input
                  step="any"
                  autoFocus="autofocus"
                  style={{ maxWidth: "340px" }}
                  placeholder="0.000"
                  className={`form-control font-2_25 ${
                    validationErrors?.amountError ? "is-invalid" : ""
                  } ${
                    isPayButton || isWpPlugin ? "border-0 lightgray-bg" : ""
                  }`}
                  required="required"
                  type="number"
                  value={amount}
                  onChange={this.handleAmountChange}
                  id="amount"
                  disabled={
                    submitting == true
                      ? true
                      : false || isPayButton == true || isWpPlugin == true
                      ? true
                      : false
                  }
                />
              </div>
              {isAuth && (
                <div className="font-0_9 text-muted mt-1">
                  <span className="text-muted">
                    Max: $ {selectedCurrencyBalance}
                    {alternateCurrency != "USD" && (
                      <span>
                        {" "}
                        / {alternateCurrency} {alternateCurrencyBalance}
                      </span>
                    )}
                  </span>
                </div>
              )}
              {error && (
                <span className="error text-danger">
                  <br />
                  {error}
                </span>
              )}
              {validationErrors?.amountError && (
                <span className="error text-danger">
                  <br />
                  {validationErrors.amountError}
                </span>
              )}
              <small id="amountHelpBlock" className="form-text text-muted">
                {/* Enter total transfer amount desired (USD) {selectedCurrency}. */}
              </small>
            </div>

            <div className="selectWallet">
              {type === "wallet" && (
                <div>
                  {destinationWallets.length > 0 && (
                    <div className="form-group row required mt-2 ">
                      <label className="form-label">
                        Select the crypto wallet
                      </label>
                      <select
                        className="form-control form-control-lg expand-select"
                        required="required"
                        value={selectedTransferType}
                        disabled={submitting == true ? true : false}
                        onChange={e =>
                          this.handleChange(e, "selectedTransferType")
                        }
                      >
                        <option value="">-- Select the Wallet ---</option>
                        {transferTypes.map((transferType, i) => (
                          <option key={i} value={transferType.value}>
                            {transferType.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  {((destinationWallets.length === 0 && recipient === "") ||
                    (selectedTransferType === "recipient" &&
                      recipient === "")) && (
                    <div className="form-group row required recipient">
                      <label className="form-label">To:</label>
                      <input
                        step="any"
                        autoFocus="autofocus"
                        className={`form-control ${
                          validationErrors?.recipientError ? "is-invalid" : ""
                        }`}
                        placeholder="Enter your recipient's username or email"
                        type="text"
                        value={recipient}
                        disabled={
                          submitting == true
                            ? true
                            : false || isPayButton == true || isWpPlugin == true
                            ? true
                            : false
                        }
                        onChange={_.debounce(this.handleRecipientChange, 1000, {
                          leading: true
                        })}
                      />
                      {validationErrors?.recipientError && (
                        <span className="error text-danger">
                          <br />
                          {validationErrors.recipientError}
                        </span>
                      )}
                      <small
                        id="amountHelpBlock"
                        className="form-text text-muted"
                      ></small>
                    </div>
                  )}
                  {selectedTransferType === "my-wallet" && (
                    <div className="form-group row required destination_wallet">
                      <label className="form-label">Destination wallet</label>
                      <select
                        className="form-control form-control-lg expand-select"
                        value={selectedDestinationWallet}
                        onChange={e =>
                          this.handleChange(e, "selectedDestinationWallet")
                        }
                      >
                        {destinationWallets.map((wallet, i) => (
                          <option key={i} value={wallet[1]}>
                            {wallet[0]}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                </div>
              )}
            </div>
            {/* { display for authenticated users } */}
            {isAuth && (
              <div className="isAuth">
                {isWpPlugin && (
                  <div
                    className="form-group row mt-2"
                    style={{ display: isPayButton ? "none" : "block" }}
                  >
                    <label className="form-label">Subscription ID</label>
                    <div className="form-control pr-4 border-0 lightgray-bg">
                      {subscriptionPlanId}
                    </div>
                    <small id="refHelpBlock" className="form-text text-muted">
                      Subscription Plan ID
                    </small>
                  </div>
                )}
                <div className="form-group row mt-4 pt-4">
                  <label className="form-label">
                    {isWpPlugin ? "Plan Description" : "Description"}
                  </label>
                  <textarea
                    className={
                      submitting == true ||
                      isPayButton == true ||
                      isWpPlugin == true
                        ? "form-control pr-4 border-0 lightgray-bg"
                        : "form-control pr-4"
                    }
                    style={{ display: isWpPlugin ? "none" : "block" }}
                    placeholder={submitting == true ? note : ""}
                    type="text"
                    onChange={this.handleNoteChange}
                    id="note"
                    value={note}
                    disabled={
                      submitting == true
                        ? true
                        : false || isPayButton == true || isWpPlugin == true
                        ? true
                        : false
                    }
                  />
                  <small
                    id="noteHelpBlock"
                    className="form-text text-muted"
                    style={{
                      display: isPayButton || isWpPlugin ? "none" : "block"
                    }}
                  >
                    Enter your own description or message to your recipient
                    (optional)
                  </small>
                  <div
                    className="form-control pr-4 border-0 lightgray-bg"
                    style={{
                      display: isWpPlugin ? "block" : "none",
                      whiteSpace: "pre-wrap"
                    }}
                  >
                    {note}
                  </div>
                </div>
                <div
                  className="form-group row mt-2"
                  style={{ display: isPayButton ? "none" : "block" }}
                >
                  <label className="form-label">Reference</label>
                  <input
                    className={
                      submitting == true || isWpPlugin == true
                        ? "form-control pr-4 border-0 lightgray-bg"
                        : "form-control pr-4"
                    }
                    placeholder={submitting == true ? senderRef : ""}
                    type="text"
                    onChange={this.handleSenderRefChange}
                    id="senderRef"
                    value={senderRef}
                    disabled={
                      submitting == true
                        ? true
                        : false || isPayButton == true || isWpPlugin == true
                        ? true
                        : false
                    }
                  />
                  <small
                    id="refHelpBlock"
                    className="form-text text-muted"
                    style={{
                      display: isPayButton || isWpPlugin ? "none" : "block"
                    }}
                  >
                    Use this field to add a Reference or Order ID/Number
                    (optional)
                  </small>
                </div>

                <div className="preview-wrapper mt-1 pt-1">
                  <hr />
                  <div className="pl-3 preview-list mt-1 pt-1">
                    <div className="row">
                      <div className="col-5">
                        <div className="form-group row d-block ">
                          <label className="form-label">
                            Total Amount
                            <a
                              data-toggle="tooltip"
                              className="btn btn-link"
                              data-original-title="Total Amount to Send"
                            >
                              <i className="fa fa-info-circle"></i>
                            </a>
                          </label>
                          <small
                            id="netAmountHelpBlock"
                            className="form-text text-muted"
                          ></small>
                        </div>{" "}
                      </div>
                      <div className="col-7">
                        <div className="row ">
                          <div className="input-group mb-2 mr-sm-2">
                            <div className="input-group-prepend">
                              <div className="input-group-text selected-currency border-0 font-1_5 font-weight-bold">
                                $
                              </div>
                            </div>
                            <input
                              className="form-control border-0 font-1_5 font-weight-bold"
                              placeholder="0.00"
                              data-target="cryptoTransfer.outputNetAmount"
                              required="required"
                              readOnly="readonly"
                              type="number"
                              value={amount}
                            />
                          </div>
                        </div>
                        {alternateCurrency != "USD" && amount != 0 && (
                          <div className="row mb-2">
                            <div className="input-group-prepend">
                              <div className="input-group-text selected-currency border-0 font-0_9 pt-0">
                                {alternateCurrency}
                              </div>
                            </div>
                            <span className="font-0_9 text-muted">
                              {parseFloat(
                                alternateCurrencyRate * amount
                              ).toFixed(2)}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-muted font-0_8">
                  {submitting ? (
                    <div className="pt-4 mt-2">
                      <i className="fa fa-exclamation-circle"></i>{" "}
                      <strong>Valid for the next 10 min</strong>. If your
                      transaction expires, simply start again. Click Submit to
                      continue.
                    </div>
                  ) : (
                    <div>
                      <i className="fa fa-exclamation-circle"></i> Click Preview
                      to preview your transaction.
                    </div>
                  )}
                </div>
                <hr />
                <div className="form-actions">
                  {submitTransfer && transferId ? (
                    <div className="mr-auto">
                      <div className="col-md-6 float-md-right pr-0">
                        <button
                          name="button"
                          type="submit"
                          className="btn btn-primary pull-right mb-3"
                          onClick={this.submitTransfer}
                        >
                          Submit
                        </button>
                      </div>
                      <div className="col-md-6 float-md-left pl-0">
                        <button
                          name="cancel"
                          type="submit"
                          className="btn pull-left mb-3"
                          onClick={this.cancelTransfer}
                          disabled={invalidTransfer}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="mr-auto pb-4">
                      <div className="col-md-6 float-md-right pr-0">
                        <button
                          name="button"
                          type="submit"
                          className="btn btn-primary pull-right mb-2 col-xs-4"
                          onClick={this.submitTransferPreview}
                          disabled={invalidTransfer || submitting}
                        >
                          {submitting ? (
                            <>
                              <i className="fa fa-circle-o-notch fa-spin"></i>
                              <span style={{ paddingLeft: "5px" }}>
                                Please Wait...Loading
                              </span>
                            </>
                          ) : (
                            <span>Preview</span>
                          )}
                        </button>
                      </div>
                      <div className="col-md-6 float-md-left pl-0">
                        <button
                          name="start_again"
                          type="submit"
                          className="btn pull-left mb-2"
                          onClick={this.refreshPage}
                        >
                          Start Again
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}

            {isAuth === false && (
              <div className="form-actions text-center">
                <div className="row mr-auto">
                  <div className="col-md-12">
                    <button
                      name="button"
                      type="submit"
                      className="btn btn-primary mt-3 mb-3 pl-4 pr-4"
                      onClick={this.redirectToLogin}
                    >
                      Login
                    </button>
                  </div>
                </div>

                <div className="col-md-12">
                  <strong>
                    Or{" "}
                    <a href="/users/sign_up/" target="blank">
                      create and fund an account
                    </a>{" "}
                    with BananaCrystal
                  </strong>
                </div>
              </div>
            )}
          </div>
        </form>
        <br />

        { storeReturnLink !== "" && (
          <div className="mx-auto pt-4 mt-4" style={{ maxWidth: 380 }}>
            <div className="text-center">
              {this.backToMerchantLink(storeName, storeReturnLink)}
            </div>
          </div>
        )}

        <div className="mx-auto pt-4 mt-4" style={{ maxWidth: 380 }}>
          <div className="powered-by text-muted text-center">
            <a href="https://www.bananacrystal.com" target="_blank">
              <div className="font-0_75">powered by</div>
              <div>
                <img
                  src="https://media.bananacrystal.com/wp-content/uploads/2020/12/29182034/bananacrystal-logo-200x50-1.png"
                  height="25"
                />
              </div>
            </a>
          </div>
        </div>
        <AddFundsModal
          closeModal={this.closeModal}
        />
      </div>
    );
  }
}

export default CryptoTransferPaySubscriptions;
