import React, { useState } from "react";
import "../../../styles/trademodals.scss";

const NoticeMessageModal = ({ onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [showCheckboxWarning, setShowCheckboxWarning] = useState(false); // State to track checkbox warning

  const handleCheckboxChange = (event) => {
    setIsCheckboxChecked(event.target.checked);
    setShowCheckboxWarning(false); // Remove the warning if the checkbox is checked
  };

  const handleConfirmClick = () => {
    if (!isCheckboxChecked) {
      setShowCheckboxWarning(true); // Show the warning if checkbox is not checked
      return;
    }
    onClose("noticeMessageModal");
  };

  return (
    <div
      className="modal-status-cont"
      tabIndex={-1}
      aria-hidden="true"
      id="noticeMessageModal"
      aria-labelledby="disputeConfirmModalLabel"
    >
      <div className="modal-status-alert-cont">
        <div>
          <button
            type="button"
            className="close"
            onClick={() => {
              onClose("noticeMessageModal");
            }}
            aria-label="Close"
            disabled={isLoading}
          >
            <span aria-hidden="true">{" "}</span>
          </button>
          <br />
        </div>
        <div>
          <div className="modal-title" id="disputeConfirmModalLabel">
            <p style={{ textAlign: "center" }}>
              <i className="fas fa-exclamation-triangle" aria-hidden="true"></i>
              {" "}
              Notice!!!
            </p>
          </div>
          <div className="trademodal-notice">
            <span>
              To avoid becoming a victim of scammers, <b>NEVER</b> transfer funds before actually receiving the payment! <br /> <br />
              Ensure the payment is successfully credited to your preferred account <b>before selecting "I have received payment"</b>.<br /> <br />
              Don't believe anyone who claims to be a customer support and convinces you to complete a transaction before you receive the payment - they're scammers.<br /> <br />
              <b>Once the seller confirms the order and transfers the assets to the buyer, the transaction is considered completed and cannot be disputed.</b><br /> <br />
              BananaCrystal does not take any responsibility for transactions made outside of the platform.
            </span>
          </div>
          <br />
          <div className="trademodal-form-check">
            <input
              className={`trademodal-form-check-input ${
                showCheckboxWarning ? "checkbox-warning" : ""
              }`}
              type="checkbox"
              id="noticeMessageCheckbox"
              onChange={handleCheckboxChange}
            />
            <label
              className="trademodal-form-check-label"
              htmlFor="noticeMessageCheckbox"
            >
              <strong> I have read and agree to the above content. </strong>
            <br/>
            </label>
          </div>
          {showCheckboxWarning && (
              <p className="checkbox-warning-text">Please check this box to proceed.</p>
            )}
          {/* buttons */}
          <div className="modal-buttons-section">
            <div>
              <button
                type="button"
                className="modal-tradeaction-paidbtn"
                onClick={handleConfirmClick}
              >
                CONFIRM
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoticeMessageModal;
