import React, { useState } from "react";
import "./index.scss";
import identicon from "../../../../../assets/images/identicon.png";

const Featured = (props) => {
  const { featuredTradersData, userTradeHistory } = props;
  const [activeTab, setActiveTab] = useState("featured-traders");

  const openTab = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <div className="featured app-card0">
      <div className="tab">
        <button
          className={
            activeTab === "featured-traders" ? "tablinks active" : "tablinks"
          }
          onClick={() => openTab("featured-traders")}
        >
          Featured Traders
        </button>
        <button
          className={
            activeTab === "trade-history" ? "tablinks active" : "tablinks"
          }
          onClick={() => openTab("trade-history")}
        >
          My Trade History
        </button>
      </div>
      <div
        id="featured-traders"
        className={
          activeTab === "featured-traders" ? "tabcontent" : "tabcontent hidden"
        }
      >
        <div className="traders">
          {featuredTradersData?.map((trader, index) => (
            <div className="trader" key={index}>
              <div className="profile">
                <img src={identicon} alt="trader" />
                <div className="profile-name">
                  <a href={trader.trader_profile_link}>
                    <h4 className="profile-username">{trader.trader_label}</h4>
                  </a>
                  <h5 className="profile-payments">
                    {trader.trader_payment_method}
                  </h5>
                  <div className="profile-location d-flex">
                    <i className="fa fa-globe profile-location-icon" aria-hidden="true"></i>
                    <h6>{trader.trader_location}</h6>
                  </div>
                </div>
              </div>
              
              <div className="details">
                <span>Vol:</span>
                <i className="fa fa-arrow-circle-o-up" aria-hidden="true"></i>
                <span>{trader.trader_trade_vol}</span>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* trade history tab */}
      <div
        id="trade-history"
        className={
          activeTab === "trade-history" ? "tabcontent" : "tabcontent hidden"
        }
      >
        <div className="data">
          <table id="trade-history-table" className="display" width="100%">
            <thead>
              <tr>
                <th>Trade Details</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {userTradeHistory?.map((history, index) => (
                <tr key={index} className="trade-row">
                  <td>
                    <a
                      href={history.view_trade_link}
                      className="trade-history-link"
                    >
                      <span className="trade-lable">
                        {history.trade_details.label}
                      </span>
                      <br />
                      <span className="trade-date">{history.date}</span>
                    </a>
                  </td>

                  <td>
                    <a
                      href={history.view_trade_link}
                      className="trade-history-link"
                    >
                      {history.status}
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Featured;
