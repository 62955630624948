import { FieldProps } from "formik";
import React from "react";
import Select, { Option, ReactSelectProps } from "react-select";

const SelectField: React.FC<ReactSelectProps & FieldProps> = ({
  options,
  field,
  placeholder,
  form,
  onChange,
  error
}) => (
  <Select
    options={options}
    name={field.name}
    placeholder={placeholder}
    value={
      options ? options.find((option) => option.value === field.value) : ""
    }
    onChange={
      onChange
        ? onChange
        : (option: Option) => form.setFieldValue(field.name, option.value)
    }
    onBlur={field.onBlur}
    className={`${error ? "is-invalid" : ""}`}
  />
);

export default SelectField;
