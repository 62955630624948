import React from "react";

const Header = ({ offer, offerTypeDisplay, bankAccounts }) => {
  return (
    <div className=" page-header d-flex align-items-center pb-1 pt-3 bg-transparent">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div>
            <h2 className="create-trade-page-title">
              <i className="material-icons">add_circle</i>
              Create Trade
            </h2>
            <h3 className="tracking-tighter">
              {offerTypeDisplay === ""
                ? ""
                : offerTypeDisplay === "buy"
                ? "Buy"
                : "Sell"}{" "}
              {offer.cryptoCurrency?.code == "USDT"
                ? "USD "
                : offer.cryptoCurrency?.code}
              {offer.cryptoCurrency ? "with " : ""}
              {offer.paymentMethod?.name} ({offer.fiatCurrency?.code})
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
