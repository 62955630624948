import React from "react";
import "../../../styles/faq.scss";

const FaqSection = () => {
  return (
    <div id="accordion" className="tradedetail-accordion">
      <h4 className="trade-faq-title" title="Frequently Asked Questions">
        FAQ
        <i className="fas fa-question-circle"></i>
      </h4>
      <div className="trade-faq-card">
        <a className="trade-faq-header" data-toggle="collapse" href="#collapseOne">
          How to Avoid Common P2P Scams
        </a>
        <div
          id="collapseOne"
          className="collapse"
          data-parent="#accordion"
        >
          <div className="card-body">
            <ol>
              <li>
                <b>Always verify payment:</b> Independently confirm that you've
                received funds in your bank account or e-wallet from your
                counterparty before marking any transaction as complete. Never
                trust just a screenshot.
              </li>
              <li>
                <b>Be wary of chargebacks and third-party accounts:</b> Ensure
                the counterparty's name matches their verified name on
                BananaCrystal and avoid accepting payments from third-party
                accounts. If such payment occurs, submit a dispute request
                immediately.
              </li>
              <li>
                <b>Be cautious with external communication:</b> BananaCrystal
                will never send emails or SMS messages asking you to complete a
                P2P transaction.
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div className="trade-faq-card">
        <a
          className="collapsed trade-faq-header"
          data-toggle="collapse"
          href="#collapseTwo"
        >
          What should I do if the other party did not pay, but clicked
          "Payment Paid"?
        </a>
        <div id="collapseTwo" className="collapse" data-parent="#accordion">
          <div className="card-body">
            Try to contact the other party. If you cannot reach the other party
            or negotiate to resolve the issue in 3 hours, please click "Dispute
            Request".
          </div>
        </div>
      </div>
      <div className="trade-faq-card">
        <a
          className="collapsed trade-faq-header"
          data-toggle="collapse"
          href="#collapseThree"
        >
          What should I do if the other party urges me to complete the
          confirmation of the payment process and Release funds?
        </a>
        <div id="collapseThree" className="collapse" data-parent="#accordion">
          <div className="card-body">
            Please do not believe any reason to urge the release of currency. To
            avoid losses, only release the USD funds assets after confirming the
            receipt of the payment. To avoid SMS scams, please do not release
            digital currency after receiving the text message receipt only.
            Always log in to online banking or mobile banking to confirm whether
            the payment is credited before releasing the USD.
          </div>
        </div>
      </div>
      <div className="trade-faq-card">
        <a
          className="collapsed trade-faq-header"
          data-toggle="collapse"
          href="#collapseFour"
        >
          What should I do if the other party does not pay using the verified
          account?
        </a>
        <div id="collapseFour" className="collapse" data-parent="#accordion">
          <div className="card-body">
            You have the right not to release the funds and create "Dispute
            Request" to resolve the issue.
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqSection;
