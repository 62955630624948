import React, { useState, useEffect } from "react";
import { getRequest } from "../../../../utils/httpRequest";
import "./index.scss";
import loading from "../../../../../assets/images/spin.gif";
import { IconArrowUpRight, IconZap } from "../../../Dashboard/dashboardIcons";

//Formik
const SendPayment = () => {
  const [walletData, setWalletData] = useState(null);

  //Fetching account data to  determine user available balance
  useEffect(() => {
    let isMounted = true;

    const fetchWalletData = async () => {
      try {
        const data = await getRequest("/api/v1/dashboard/accounts");
        if (isMounted) {
          setWalletData(data);
        }
      } catch (error) {
        console.error("Error fetching wallet data:", error);
      }
    };

    fetchWalletData();

    return () => {
      isMounted = false;
    };
  }, []);

  const userBalanceData =
    walletData?.wallet_balances?.account_balance.balance || "0";
  const userBalance = parseFloat(
    userBalanceData?.replace("$", "").replace(",", "").trim()
  );

  const [formData, setFormData] = useState({
    to: "",
    amount: "",
    altAmount: "",
    description: "",
    reference: "",
  });

  const [errors, setErrors] = useState({
    to: "",
    amount: "",
    altAmount: "",
    description: "",
    reference: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false); // State to track form submission

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Reset errors for the field being edited
    setErrors({ ...errors, [name]: "" });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Validate form before submission
    const validationErrors = validateForm(formData);
    if (Object.keys(validationErrors).length === 0) {
      // Form is valid, set submitting state to true
      setIsSubmitting(true);
      sessionStorage.setItem("formData", JSON.stringify(formData));
      window.location.href = "/send_usd";
    } else {
      setErrors(validationErrors);
    }
  };

  const validateForm = (data) => {
    const errors = {};
    // Validation rules
    if (!data.to) {
      errors.to = "Recipient is required";
    } else {
      // Check if to field is either a valid email address or a valid username
      if (
        !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(data.to) &&
        !/^[a-zA-Z0-9\s-]+$/.test(data.to)
      ) {
        errors.to = "Please enter a valid username or email address";
      }
    }
    if (!data.amount && !data.altAmount) {
      errors.amount = "Amount is required";
      errors.altAmount = "Alternative amount is required";
    } else {
      // Check if amount fields contain only numbers
      if (!/^\d*\.?\d+$/.test(data.amount)) {
        errors.amount = "Amount should only contain numbers";
      }
      if (parseFloat(data.amount) > userBalance) {
        errors.amount = "Insufficient balance. Please fund your account";
      }
    }
    if (data.description && !/^[a-zA-Z0-9\s.]+$/.test(data.description)) {
      errors.description =
        "Description should only contain letters, numbers, spaces, and periods";
    }
    if (data.reference && !/^[a-zA-Z0-9\s.]+$/.test(data.reference)) {
      errors.reference =
        "Reference should only contain letters, numbers, spaces, and periods";
    }
    return errors;
  };

  const [isRotating, setIsRotating] = useState(false);

  const handleRefresh = () => {
    setIsRotating(true);
    setTimeout(() => {
      setIsRotating(false);
    }, 500);

    setFormData({
      to: "",
      amount: "",
      altAmount: "",
      description: "",
      reference: "",
    });
    setErrors({
      to: "",
      amount: "",
      altAmount: "",
      description: "",
      reference: "",
    });
  };

  return (
    <div className="send-payment app-card1">
      <div className="head d-flex justify-content-between">
        <p className="font-weight-bold">
          <IconZap height="1.5em" width="1.5em" /> Send Payments{" "}
        </p>
        <i
          className={`fa fa-refresh cursor-pointer ${
            isRotating ? "rotate" : ""
          }`}
          id="refreshBtn"
          title="refresh"
          onClick={handleRefresh}
        ></i>
      </div>
      <form onSubmit={handleSubmit} id="send-payment-form">
        {["to", "amount", "description", "reference"].map((fieldName) => (
          <div key={fieldName}>
            {errors[fieldName] && (
              <div className="error">{errors[fieldName]}</div>
            )}
            <label
              htmlFor={fieldName}
              style={{ display: errors[fieldName] ? "none" : "block" }}
            >
              {fieldName === "amount"
                ? `${
                    fieldName.charAt(0).toUpperCase() + fieldName.slice(1)
                  } (Your Available Balance: USD ${userBalance.toLocaleString(
                    "en-US",
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )})`
                : fieldName === "to"
                ? "Recipient"
                : fieldName.charAt(0).toUpperCase() + fieldName.slice(1)}
            </label>
            <input
              type="text"
              name={fieldName}
              id={fieldName}
              placeholder={
                fieldName === "to"
                  ? "Enter recipient username or email"
                  : `Enter ${fieldName}`
              }
              value={formData[fieldName]}
              onChange={handleChange}
              disabled={isSubmitting}
              className={errors[fieldName] ? "error-input" : ""}
            />
          </div>
        ))}
        <button type="submit" disabled={isSubmitting}>
          {isSubmitting ? (
            <div className="loading-gif">
              <img src={loading} alt="Loading" height={"32px"} width={"32px"} />
            </div>
          ) : (
            <>
              <IconArrowUpRight /> Preview & Proceed
            </>
          )}
        </button>
      </form>
    </div>
  );
};

export default SendPayment;
