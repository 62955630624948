import React, { useState, useEffect } from "react";
import NotificationGroup from "./NotificationGroup";
import { getRequest, putRequest } from "../../utils/httpRequest";
import groups from "../../utils/notificationGroups";

import "./index.scss";

const NotificationSetting = (props) => {
  const [Settings, setSettings] = useState([]);
  const [SelectedItems, setSelectedItems] = useState({}); 
  const [Channels, setChannels] = useState([]);

  const getSetting = (key, channel) => {
    if (typeof SelectedItems[`${key}_${channel}`] !== 'undefined') {
      return  SelectedItems[`${key}_${channel}`];
    }

    return false;
  }

  const fetchSettings = () => {
    getRequest('/users/notification_settings')
      .then((res) => {
        let settingTemp = {};
        if (res.length > 0) {
          res.forEach(item => {
              settingTemp[item.slug] = item.active;
          });
        }
        setSelectedItems(settingTemp);        
      })
      .catch((rej) => {
        console.log(rej.response);
      });
  };

  const updateSetting = (key, channel, value = false) => {    
    let tempItems = {};
    Object.assign(tempItems, SelectedItems);
    tempItems[`${key}_${channel}`] = value;
    setSelectedItems(tempItems);
    const data = {
      notification_setting_slug: key,
      notification_channel_platform: channel,
      active: value
    };
    console.log('data', data)

    putRequest('/users/notification_settings', data)
      .then((res) => {})
      .catch((rej) => {
        console.log('upsert setting error', rej)
      });
  }

  useEffect(() => {
    setSettings(props.settings);
    setChannels(props.channels);
    fetchSettings();
  }, []);

  return (
    <div className="accordion" id="notifications_accordion">
      {groups.map((group, i) => 
        <NotificationGroup 
          group={group} 
          Settings={Settings}
          Channels={Channels}
          getSetting={getSetting}
          updateSetting={updateSetting}
          key={i}
        />
      )}
    </div>

  );
};

export default NotificationSetting;
