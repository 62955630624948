import React from "react";

interface Props {
  label: string;
  value: number;
  placeholder?: string;
}
const Preview: React.FC<Props> = ({ label, value, placeholder = "0.00" }) => (
  <div className="">
    <div className="col-5">
      <div className="form-group row d-block ">
        <label className="form-label">
          {label}
          <a
            data-toggle="tooltip"
            className="btn btn-link"
            data-original-title="Subtotal amount"
          >
            <i className="fa fa-info-circle"></i>
          </a>
        </label>
        <small id="netAmountHelpBlock" className="form-text text-muted"></small>
      </div>{" "}
    </div>
    <div className="col-7">
      <div className="row ">
        <div className="input-group mb-2 mr-sm-2">
          <div className="input-group-prepend">
            <div className="input-group-text selected-currency border-0 font_1_1">
              $
            </div>
          </div>
          <input
            className="form-control border-0 font_1_1"
            placeholder={placeholder}
            type="number"
            value={value}
            readOnly={true}
          />
        </div>
      </div>
    </div>
  </div>
);

export default Preview;
