import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';

const AccountActivityChart = ({ accountSummaryData }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [chartOptions, setChartOptions] = useState({});
  const [chartSeries, setChartSeries] = useState([]);
  const [chartLabels, setChartLabels] = useState([]);
  const [transactionStats, setTransactionStats] = useState({});

  useEffect(() => {
    // Extracting transaction types and their counts from the accountSummaryData prop
    const transactionTypes = accountSummaryData?.map(item => item.type);
    const transactionCounts = accountSummaryData?.map(item => item.status === 'completed' ? 1 : 0);

    // Calculate individual statistics for each transaction type
    const stats = {};
    accountSummaryData?.forEach(item => {
      if (stats[item.type]) {
        stats[item.type]++;
      } else {
        stats[item.type] = 1;
      }
    });

    // Define chart options dynamically based on the accountSummaryData prop
    const options = {
      chart: {
        type: 'donut',
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
      },
      labels: transactionTypes,
    };

    setChartOptions(options);
    setChartSeries(transactionCounts);
    setChartLabels(transactionTypes);
    setTransactionStats(stats);
  }, [accountSummaryData]);

  // Calculate total transactions and completed transactions
  const totalTransactions = accountSummaryData?.length || 0;
  const completedTransactions = accountSummaryData?.filter(item => item.status === 'completed').length || 0;

  return (
    <div className="account-activity-container app-card4">
      <div className="head">
        <h3>Your Account Activity</h3>
        <div className="activity-summary-menu">
          <div onClick={() => setShowMenu(!showMenu)}><i className="bx bx-dots-horizontal-rounded icon"></i></div>
          {showMenu && 
            <ul className="activity-menu-link">
              <li><a href="#">Account Settings</a></li>
              <li><a href="#">Save</a></li>
              <li><a href="#">See More</a></li>
            </ul>
          }
        </div>
      </div>
      
      <Chart options={chartOptions} series={chartSeries || []} labels={chartLabels} type="donut" width="100%"/>
      <div className="account-activity-data">
        {Object.entries(transactionStats).map(([type, count]) => (
          <p className={`account-activity-data ${type}`} key={type}>{type}: {count}</p>
        ))}
      </div>
    </div>
  );
};

export default AccountActivityChart;
