import React, { Component } from "react";
import Table from "./Table";

interface Load {
  id: string;
}

interface Props {
  loads: Load[];
  cardId: string;
}

class List extends Component<Props, {}> {
  constructor(props) {
    super(props);
  }

  render() {
    const { loads, cardId } = this.props;

    return (
      <div className="account-activity">
        <Table loads={loads} cardId={cardId} />
      </div>
    );
  }
}

export default List;
