require("./kiso_themes/kernel");
require("./kiso_themes/fastclick");
require("./kiso_themes/slimscroll");
require("./kiso_themes/metis_menu");
require("./kiso_themes/popper");
require("./kiso_themes/bootstrap");
require("./kiso_themes/sidenav");
require("./kiso_themes/header");
require("./kiso_themes/forms");
require("./kiso_themes/bootstrap_switch");
require("./kiso_themes/sheets");
require("./kiso_themes/gmaps");
require("./kiso_themes/jqvectormaps");
require("./kiso_themes/jasny");
require("./kiso_themes/flot");
require("./kiso_themes/chartjs");
require("./kiso_themes/sparklines");
require("./kiso_themes/easypiechart");
require("./kiso_themes/prettify");
require("./kiso_themes/countTo");
require("./kiso_themes/prism");
require("./kiso_themes/clipboard");
require("./kiso_themes/countdown");
require("./kiso_themes/parallax");
require("./kiso_themes/tabs");
require("./kiso_themes/multi-input");

// require('./kiso_themes/demo/data_generator');
// require('./kiso_themes/demo/chartjs');
// require('./kiso_themes/demo/dashboard');
// require('./kiso_themes/demo/easypiechart');
// require('./kiso_themes/demo/flot');
// require('./kiso_themes/demo/gmaps');
// require('./kiso_themes/demo/jqvectormap');
// require('./kiso_themes/demo/sparklines');

require("./kiso_themes/inspect_mode/inspect_mode");

Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false;
//Popper.Defaults.modifiers.computeStyle.enabled = false;
