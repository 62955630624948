import { FieldProps } from "formik";
import React from "react";
import DatePicker from "react-datepicker";
import moment from "moment";

const DateTimeField: React.FC<FieldProps> = ({ field, form }) => (
  <DatePicker
    className={`form-control ${form.errors[field.name] ? 'is-invalid' : ''}`}
    dateFormat="dd-MM-yyyy"
    peekNextMonth
    showMonthDropdown
    showYearDropdown
    dropdownMode="select"
    isClearable={true}
    todayButton="Vandaag"
    placeholderText="Click to select a date"
    placeholder="Click to select a date"
    selected={field.value}
    onChange={(date) => {
      form.setFieldValue(field.name, date);
    }}
  />
);

export default DateTimeField;
