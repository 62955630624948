import React from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import Select from "react-select";
import premiums from "./premiums.json";
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input';

const SignupForm = ({
  selectedGender,
  dateOfBirth,
  firstName,
  lastName,
  email,
  phoneNumber,
  selectedPremium,
  selectedCountry,
  countries,
  nonTerminalIllness,
  validationErrors,
  handleCountryChange,
  handleChange,
  createInsuranceLead,
  back,
  creating,
  getSelectedPremium
}) => (
  <div>
    <form className="preview-form">
      <div className="form-row">
        <div className="form-group col-md-6 gender required">
          <label className="form-label">Gender</label>
          <select
            className="form-control"
            disabled={true}
            value={selectedGender}
            onChange={e => handleChange(e, "selectedGender")}
          >
            <option value="male">Male</option>
            <option value="female">Female</option>
          </select>
        </div>
        <div className="form-group col-md-6 date-of-birth required">
          <label className="form-label">Date of Birth</label>
          <DatePicker
            disabled={true}
            className="form-control"
            required="required"
            selected={dateOfBirth}
            dateFormat="MMMM d, yyyy"
            placeholderText="Enter your date of birth"
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            maxDate={new Date("2003-01-01")}
          />
        </div>
      </div>
      <div className="form-group">
        <label className="form-label">Your Planned Premium</label>
        <div className="input-group mb-2">
          <div className="input-group-prepend">
            <div className="input-group-text">$</div>
          </div>
          <select
            disabled={true}
            className="form-control"
            value={selectedPremium}
            onChange={e => handleChange(e, "selectedPremium")}
          >
            {premiums.map((premium, i) => (
              <option key={i} value={premium.value}>
                {premium.label} per year
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-6 gender required">
          <label className="form-label">First Name</label>
          <input
            className="form-control"
            value={firstName}
            onChange={e => handleChange(e, "firstName")}
          />
          {validationErrors?.firstNameError && (
            <div className="error text-danger">
              {validationErrors.firstNameError}
            </div>
          )}
        </div>
        <div className="form-group col-md-6 date-of-birth required">
          <label className="form-label">Last Name</label>
          <input
            className="form-control"
            value={lastName}
            onChange={e => handleChange(e, "lastName")}
          />
          {validationErrors?.lastNameError && (
            <div className="error text-danger">
              {validationErrors.lastNameError}
            </div>
          )}
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-6 phone required mt-4 mb-4">
          <label className="form-label">Phone Number</label>
          {/* <input
            type="tel"
            className="form-control"
            value={phoneNumber}
            placeholder="123-123-1234"
            onChange={e => handleChange(e, "phoneNumber")}
          /> */}
          <PhoneInput
            placeholder="Enter phone number"
            className="col-md-6"
            defaultCountry="US"
            value={phoneNumber}
            onChange={e => handleChange(e, "phoneNumber")}
            error={phoneNumber ? (isValidPhoneNumber(phoneNumber) ? undefined : 'Invalid phone number') : 'Phone number required'}/>

          {validationErrors?.phoneNumberError && (
            <div className="error text-danger">
              {validationErrors.phoneNumberError}
            </div>
          )}
        </div>
        <div className="form-group col-md-6 email required mt-4">
          <label className="form-label">Email</label>
          <input
            className="form-control"
            value={email}
            type="email"
            onChange={e => handleChange(e, "email")}
          />
          {validationErrors?.emailError && (
            <div className="error text-danger">
              {validationErrors.emailError}
            </div>
          )}
        </div>
      </div>

      <div className="form-group">
        <label className="form-label">Country</label>
        <Select
          className="country-select"
          value={selectedCountry}
          onChange={e => handleCountryChange(e)}
          options={countries}
        />
      </div>

      <div className="form-check">
        <input
          disabled={true}
          type="checkbox"
          className="form-check-input"
          checked={nonTerminalIllness}
          onChange={e => handleChange(e, "nonTerminalIllness")}
        />
        <label className="form-check-label">Non terminal illness</label>
      </div>
      <div className="action">
        <button
          type="submit"
          className="btn btn-primary left"
          onClick={e => createInsuranceLead(e)}
          disabled={creating}
        >
          {creating && (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          Submit
        </button>

        <button
          type="submit"
          className="btn btn-primary right"
          onClick={e => back(e)}
        >
          Back
        </button>
      </div>
    </form>
  </div>
);

export default SignupForm;
