import React from "react";
import Countdown from "react-countdown";
import moment from "moment";

const TradeCountdown = ({
  tradeData,
  isSystemCancelled,
  isDisputed,
  acceptSuccess,
  isDisputedAllowed
}) => {
  return (
    <div className="tradecountdown-parent-cont">
      {tradeData?.external_status === "pending_payment" &&
      !isSystemCancelled ? (
        <div>
          <div className="tradecountdown-text">
            Trade expires in:{" "}
            <Countdown date={new Date(tradeData?.expired_at)} />
          </div>
        </div>
      ) : (
        <></>
      )}

      {tradeData?.external_status === "payment_paid" &&
      !isDisputed &&
      !acceptSuccess ? (
        <div>
          {!isDisputedAllowed() && (
            <div className="tradecountdown-text">
              Waiting for payment confirmation! You can dispute in:{" "}
              <Countdown
                daysInHours={true}
                date={moment
                  .utc(tradeData?.paid_at)
                  .local()
                  .add(3, "hours")
                  .toDate()}
              />
            </div>
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default TradeCountdown;
