import moment from "moment/moment";
import React, { useState } from "react";

const TradeInfo = ({
  tradeId,
  tradeCreatedAt,
  sellerRate,
  offerFiatCurrencyCode,
  customOfferLabel,
}) => {
  const [copied, setCopied] = useState(null);

  const handleCopy = (text, field) => {
    navigator.clipboard.writeText(text);
    setCopied(field);
    setTimeout(() => setCopied(false), 2000);
};

  return (
    <div>
      <p className="tradedetails-tradeinfo-title">
        <span>Trade Info</span>
      </p>
      <div className="tradedetails-tradeinfo-section">
        <div>
          <div className="tradedetails-tradeinfo-specifics">
            <div>
              <div className="tradedetails-tradeinfo-specifics-text"  title="Broker's Price per USD">
                <p className="tradedetails-tradeinfo-specifics-textnames">
                <i className="fas fa-star"></i>
                  {" "}Trade Name
                </p>
                <p className="tradedetails-tradeinfo-specifics-textvalues">{customOfferLabel}</p>
              </div>
            </div>
            <div>
              <div className="tradedetails-tradeinfo-specifics-text"  title="Broker's Price per USD">
                <p className="tradedetails-tradeinfo-specifics-textnames">
                  <i className="fas fa-chart-pie"></i>
                  {" "}Trade Rate
                </p>
                <p className="tradedetails-tradeinfo-specifics-textvalues">{parseFloat(sellerRate).toFixed(2)} {offerFiatCurrencyCode}/USD</p>
              </div>
            </div>
            <div className="tradedetails-tradeinfo-specifics-text" title="Current Trade ID">
              <p className="tradedetails-tradeinfo-specifics-textnames">
                <i className="fas fa-bullseye"></i>
                {" "}Trade ID
              </p>
              <p className="tradedetails-tradeinfo-specifics-textvalues">{tradeId}
                <button onClick={() => handleCopy(tradeId, "tradeId")} className="tradedetails-copybtn">
                  <i className={`fas fa-${copied === "tradeId" ? "check" : "copy"}`} title={`${copied ? "Copied" : "Copy"}`}></i>
                </button>
              </p>
            </div>
            <div className="tradedetails-tradeinfo-specifics-text" title="Date this trade was created">
              <p className="tradedetails-tradeinfo-specifics-textnames">
                <i className="fas fa-stopwatch"></i>
                {" "}Started
              </p>
              <p className="tradedetails-tradeinfo-specifics-textvalues">
                {moment.utc(tradeCreatedAt).local().format("lll")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TradeInfo;
