import * as Yup from "yup";
import countries from "../../../utils/countries.json";
import countryIDNames from "../../../utils/countryIDNames.json"

export const validationSchema = Yup.object({
  address: Yup.string()
    .required("Address is required")
    .min(2, "Address must be at least 2 characters"),
  city: Yup.string()
    .required("City is required")
    .min(2, "City must be at least 2 characters"),
  state: Yup.string().required("State is required"),
  country: Yup.string().required("Country is required"),
  nationalIDNumber: Yup.number()
    .required("This field is required"),
  phone: Yup.string()
    .required("Phone number is required")
    .min(10, "Please enter a valid phone number"),
  zipCode: Yup.string()
    .required("ZIP code is required")
});

export const countryOptions = countries
  .map((country) => {
    return {
      value: country.code2,
      label: country.name,
    };
  });

export const findCountryID = (country) => {
  const selectedCountry = countryIDNames
  .find((element) => element.country.toLowerCase() === country.toLowerCase())
  return selectedCountry
}
