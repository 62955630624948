import React, { useState, useEffect } from 'react';
import { putRequest } from "../../utils/httpRequest";
import SuccessAddAppModal from './SuccessModal';

const DeleteAppModal = ({ onSuccess }) => {
  // Delete the app (trigger the deletion API request)
  const handleDelete = async () => {
    try {
      const response = await putRequest('/partners/api_keys/');
      if (response.status === "success") {
        closeModal();
        showSuccessModal();
      } else {
        console.error("Error creating app:", response);
      }
    } catch (error) {
      console.error("Network error:", error);
    }
  };

  // Set up the event listener for the delete button when the component mounts
  useEffect(() => {
    const deleteButton = document.querySelector('#partnersdev-deletebtn');
    if (deleteButton) {
      deleteButton.addEventListener('click', showDeleteModal);
    }

    // Clean up the event listener when the component unmounts
    return () => {
      if (deleteButton) {
        deleteButton.removeEventListener('click', showDeleteModal);
      }
    };
  }, []);

  const showSuccessModal = () => {
    const successModalCont = document.querySelector('#successmodal-partnersdev-cont') as HTMLElement;
    if (successModalCont) {
      successModalCont.style.display = 'flex';
    }
  };

  const showDeleteModal = () => {
    const deleteModalCont = document.querySelector('#partnersdev-delapp-modal') as HTMLElement;
    if (deleteModalCont) {
      deleteModalCont.style.display = 'flex';
    }
  };

  const closeModal = () => {
    const deleteModalCont = document.querySelector('#partnersdev-delapp-modal') as HTMLElement;
    if (deleteModalCont) {
      deleteModalCont.style.display = 'none';
    }
  };

  return (
    <>
      <div id="successmodal-partnersdev-cont">
        <SuccessAddAppModal
        action='Deleted'
        />
      </div>
    <div id="partnersdev-delapp-modal">
      <div
        className="modal-delistoffers-alert-cont"
        aria-hidden="true"
        aria-labelledby="delistOffers"
      >
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          onClick={closeModal}
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <br/><br/>
        <div>
          <h5 className="delistoffer-modal-title">
          <i className="fas fa-circle-exclamation"></i>
            <span>Are you sure?</span>
          </h5>
          <p className='text-center'>Note: This action cannot be undone.</p>
          <div className="delistoffer-modal-buttons-section">
            <button
              type="button"
              className="delistoffer-modal-tradeaction-proceedbtn"
              onClick={handleDelete}
            >
              PROCEED
            </button>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default DeleteAppModal;
