import React, { useState } from "react";
import AccountModal from "./AccountModal";
import NoAccountFoundLoader from "../../../../../../assets/images/noaccountsloaderbc.gif";
import Account from "./Account";

const Body = ({ bankAccounts, deleteAccount }) => {
  return (
    <tbody className="bankaccountspage-tablebody">
      {bankAccounts.map((account, i) => (
        <Account
          account={account}
          key={account.id}
          deleteAccount={deleteAccount}
          index={i}
        />
      ))}
      {bankAccounts.length === 0 && (
          <p className="bankaccountspage-noaccount-loader">
            <img src={NoAccountFoundLoader} width="500" alt="No Accounts Found" />
          </p>
      )}
    </tbody>
  );
};

export default Body;
