import React from "react";

const CreateOfferModal = ({ statusMessage, successStatus }) => {
  return (
    <div className="createoffer-modal-cont">
      <h5>
        {successStatus ? (
          <div className="createoffer-statuscont">
            <i className="fas fa-check-circle createoffer-modal-successicon"></i>
            {" "}<span className="createoffer-modal-successtext">Success</span>
          </div>
        ) : (
          <div className="createoffer-statuscont">
            <i className="fas fa-times-circle createoffer-modal-failicon"></i>
            {" "}<span className="createoffer-modal-failtext">Oh No!</span>
          </div>
        )
      }
      </h5>
      <p className="createoffermodal-statusmessage">
        {statusMessage}
      </p>
      <div>
      {successStatus && (
        <button
          type="button"
          className="createoffer-modal-button"
          onClick={() => {
            window.location.href = "/p2p_trades/offers/search";
           }}
            >
              Okay
          </button>
      )}
      </div>
    </div>
  );
};

export default CreateOfferModal;
