import React from "react";
import ReviewForm from "../../ReviewTrade/ReviewForm";
import BankAccountDetails from "./BankAccountDetails";
import DisputeInfo from "./DisputeInfo";
import SellerBankPaymentMethodDetails from "./SellerBankPaymentMethodDetails";
import SellerOtherPaymentMethodDetails from "./SellerOtherPaymentMethodDetails";
import TermsInfo from "./TermsInfo";
import TradeInfo from "./TradeInfo";
import TradeChat from "../../TradeChat";

const PaymentCompleted = ({
  tradeData,
  showChat,
  isRotating,
  initialReview,
  setShowChat,
  isBuyer,
  isOfferUser,
  offer,
  tradeBankAccount,
  referenceMessage,
  sellerPaymentDetail,
  sellerRate,
  isSeller,
  checkChatDisabled,
  chatActionCableUrl,
  hideChat,
  disputeData,
  isDisputed,
  currentUserId,
}) => {
  const handleReviewSuccess = () => {
    window.location.reload();
  };

  return (
    <div className="payment-container">
      {["completed", "payment_accepted"].includes(
        tradeData.external_status
      ) && (
        <div>
          <div>
            {/* Trade Status */}
            <div className="tradedetails-actions-steps-cont">
              {/* Progress Bar */}
              <div className="tradedetails-action-stepsection">
                <div className="tradedetails-actions-step">
                  <div className="tradedetails-cancelled-title">
                    <i
                      className="fa fa-check-square-o fa-2x completed-tick-icon"
                      aria-hidden="true"
                    ></i>
                    {isBuyer ? (
                      <div>
                        {tradeData?.crypto_currency_code == "USDT"
                          ? "USD"
                          : tradeData?.crypto_currency_code}{" "}
                        released to your wallet.
                        <div>
                          You received{" "}
                          {parseFloat(tradeData?.crypto_amount).toFixed(2)}{" "}
                          {tradeData?.crypto_currency_code == "USDT"
                            ? "USD"
                            : tradeData?.crypto_currency_code}
                          {" "}
                          (
                            {tradeData?.fiat}{" "}
                            {tradeData?.fiat_currency_code} 
                          ) to your BananaCrystal wallet.
                        </div>
                        <div>
                          Note: It will take a few minutes for the balance to
                          show in the account.
                        </div>
                      </div>
                      ) : (
                      <div>
                        <div>
                          You sold {parseFloat(tradeData?.crypto_amount).toFixed(2)}{" "}
                          {tradeData?.crypto_currency_code == "USDT"
                            ? "USD"
                            : tradeData?.crypto_currency_code}
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {/* Leave Review */}
                {!isOfferUser && (
                <div className="tradedetails-actions-step">
                
                  {initialReview && (

                    <div>
                      <div className="reviewform-title">
                        {" "}
                        {isOfferUser ? "User Review" : "Your Review"}{" "}
                      </div>
                      <div>
                        <p className="reviewform-subtitle">Rating:</p>
                        <span className="tradedetails-tradeinfo-specifics-textnames">
                          {initialReview.vote === "upvote"
                            ? (<span> I liked this trade! &nbsp; <i className="fas fa-thumbs-up"></i></span>)
                            : (<span> I did not like this trade! &nbsp; <i className="fas fa-thumbs-down"></i></span>)}
                        </span>
                        <p className="reviewform-subtitle">Comment:</p>
                        <span className="tradedetails-tradeinfo-specifics-textnames">
                          {initialReview.comment}
                        </span>
                      </div>
                    </div>
                  )}
              
                  <div>
                    <ReviewForm
                      profileId={
                        isBuyer
                          ? tradeData.buyer_profile_id
                          : tradeData.seller_profile_id
                      }
                      reviewedProfileId={
                        isBuyer
                          ? tradeData.seller_external_profile_id
                          : tradeData.buyer_profile_id
                      }
                      tradeExternalId={tradeData.external_id}
                      onSuccess={handleReviewSuccess}
                      initialReview={initialReview}
                    />
                  </div>
                </div>
                )}
                <div className="tradedetails-actions-step">
                  {/* Transaction Instructions - Bank account info section */}
                  {offer?.paymentMethod?.name === "Bank Transfer" &&
                    offer?.offerTypeField === "sell" && (
                      <BankAccountDetails
                        isBuyer={isBuyer}
                        instructionsFrom={
                          isBuyer
                            ? tradeData.seller_username
                            : tradeData.buyer_username
                        }
                        sellerUsername={tradeData?.seller_username}
                        tradeBankAccount={tradeBankAccount}
                        transferTotal={`${tradeData?.fiat} ${tradeData?.fiat_currency_code}`}
                        referenceMessage={referenceMessage}
                        buyerName={tradeData?.buyer_name}
                      />
                    )}
    
                  {/* Buy Offer and payment methood -> !Bank Transfer */}
                  {offer?.paymentMethod?.name !== "Bank Transfer" &&
                    offer?.offerTypeField === "buy" && (
                      <SellerOtherPaymentMethodDetails
                        isSeller={isSeller}
                        paymentMethod={tradeData?.payment_method_name}
                        sellerPaymentDetail={sellerPaymentDetail}
                        instructionsFrom={tradeData?.seller_username}
                        transferTotal={`${tradeData?.fiat} ${tradeData?.fiat_currency_code}`}
                        referenceMessage={referenceMessage}
                        sellerUsername={tradeData?.seller_username}
                        buyerUsername={tradeData?.buyer_username}
                        buyerName={tradeData?.buyer_name}
                      />
                    )}
                  {/* Sell Offer and payment methood -> !Bank Transfer */}
                  {offer?.paymentMethod?.name !== "Bank Transfer" &&
                    offer?.offerTypeField === "sell" && (
                      <SellerOtherPaymentMethodDetails
                        isSeller={isSeller}
                        paymentMethod={tradeData?.payment_method_name}
                        sellerPaymentDetail={sellerPaymentDetail}
                        instructionsFrom={tradeData?.seller_username}
                        transferTotal={`${tradeData?.fiat} ${tradeData?.fiat_currency_code}`}
                        referenceMessage={referenceMessage}
                        sellerUsername={tradeData?.seller_username}
                        buyerUsername={tradeData?.buyer_username}
                        buyerName={tradeData?.buyer_name}
                      />
                    )}
    
                  {/* Buy Offer and payment methood -> Bank Transfer */}
                  {offer?.paymentMethod?.name === "Bank Transfer" &&
                    offer?.offerTypeField === "buy" && (
                      <SellerBankPaymentMethodDetails
                        isSeller={isSeller}
                        paymentMethod={tradeData?.payment_method_name}
                        sellerPaymentDetail={sellerPaymentDetail}
                        instructionsFrom={tradeData?.seller_username}
                        buyerUsername={tradeData?.buyer_username}
                        sellerUsername={tradeData?.seller_username}
                        buyerName={tradeData?.buyer_name}
                        transferTotal={`${tradeData?.fiat} ${tradeData?.fiat_currency_code}`}
                        referenceMessage={referenceMessage}
                      />
                    )}
                </div>
                <div className="tradedetails-actions-step">
                  {/* Instructions from offer user */}
                  <TradeInfo
                    tradeId={tradeData?.id}
                    tradeCreatedAt={tradeData?.created_at}
                    sellerRate={sellerRate}
                    offerFiatCurrencyCode={offer?.fiatCurrency?.code}
                    customOfferLabel={offer?.customOfferLabel}
                  />
    
                  {/* Dispute Info */}
                  <DisputeInfo disputeData={disputeData} isDisputed={isDisputed} />
                  <TermsInfo
                    instructionsFrom={
                      isBuyer ? tradeData.seller_username : tradeData.buyer_username
                    }
                    instructionsFromName={
                      isBuyer
                        ? tradeData.seller_profile_name
                        : tradeData.buyer_username
                    }
                    customOfferLabel={offer?.customOfferLabel}
                    offerTags={offer?.tags}
                    offerTerms={offer?.offerTerms}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentCompleted;
