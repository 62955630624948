import React from "react";

const TradeAlerts = ({
  isDisputed,
  isSeller,
  acceptSuccess,
  isBuyer,
  paidSuccess,
  onClose
}) => {


    return (
      <div className="tradealerts-cont">
        {!isDisputed && (
          <div className="tradedetails-error" id="createtrade-alert">
            <button type="button" className="close" data-dismiss="createtrade-error" onClick={() => {onClose('createtrade-alert')}}>
              &times;
            </button>
            <span className="tradealerts-cont-text">Keep all conversations within the trade chat. Moderators will
            not be able to assist you if something goes wrong outside
            BananaCrystal.
            </span>
          </div>
        )}
        {isDisputed && (
          <div className="tradedetails-error" id="disputerequest-alert">
            <button type="button" className="close" data-dismiss="createtrade-error" onClick={() => {onClose('disputerequest-alert')}}>
              &times;
            </button>
            <span className="tradealerts-cont-text">Dispute request has been submitted for this trade. Keep all
            conversations about this trade within trade chat and dispute
            request. Moderators will not be able to assist you if
            something goes wrong outside BananaCrystal.
            </span>
          </div>
        )}
        {isSeller && acceptSuccess && (
          <div className="tradedetails-success" id="releaseusd-alert">
            <button type="button" className="close" data-dismiss="alert" onClick={() => {onClose('releaseusd-alert')}}>
              &times;
            </button>
            <span className="tradealerts-cont-text">
            You have successfully Released your USD!
            </span>
          </div>
        )}
        {isBuyer && paidSuccess && (
          <div className="tradedetails-success" id="paidsuccess-alert">
            <button type="button" className="close" data-dismiss="alert" onClick={() => {onClose('paidsuccess-alert')}}>
              &times;
            </button>
            <span className="tradealerts-cont-text">
            You have paid!
            </span>
          </div>
        )}
      </div>
    )
}
export default TradeAlerts;
