import React, { Component } from "react";
import SelectTrade from "../components/SelectTrade";
import Pricing from "../components/Pricing";
import OtherSettings from "../components/OtherSettings";

class Buy extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  render() {
    const { step, token, updateParent, getData,handleProfanityDetection } = this.props;

    return (
      <div className="">
        {step === "selectTrade" && (
          <SelectTrade       
            getData={getData}
            token={token} 
            updateParent={updateParent} 
          />
        )}
        {step === "pricing" && (
          <Pricing
            token={token} 
            getData={getData}
            updateParent={updateParent} 
          />
        )}
        {step === "settings" && (
          <OtherSettings 
            getData={getData}
            updateParent={updateParent} 
            handleProfanityDetection={handleProfanityDetection}
          />
        )}
      </div>
    );
  }
}

export default Buy;
