import React from "react";
import Head from "./Head";
import Body from "./Body";
import "./styles.scss";

const Table = ({ loads, cardId }) => (
  <div className="w-100">
    <div className="card shadow-sm rounded-lg table-responsive mt-3 w-100 card-load-wrapper">
      <table className="table table-bordered table-responsive w-100 d-block d-md-table">
        <Head />
        <Body loads={loads} cardId={cardId} />
      </table>
    </div>
  </div>
);

export default Table;
