import React, {useState} from "react";

const SellerOtherPaymentMethodDetails = ({
  isSeller,
  instructionsFrom,
  sellerPaymentDetail,
  paymentMethod,
  transferTotal,
  referenceMessage,
  sellerUsername,
  buyerUsername,
  buyerName,
}) => {
  const [copied, setCopied] = useState(null);

  const handleCopy = (text, field) => {
      navigator.clipboard.writeText(text);
      setCopied(field);
      setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
  };

  return (
    <div>
      <p className="tradedetails-tradeinfo-title">
      <i className="fas fa-info-circle"></i>
      {" "}{isSeller ? " My " : " Seller's "}
        Payment Details
      </p>
      <div>
        <div>
          <div>
            <div>
              {!isSeller ? (
                <p className="tradedetails-instructions-from">
                  Use the details below to make the transfer. Make sure you use
                  the exact words in the <b>reference message</b> when sending the
                  transfer. 
                </p>
              ) : (
                <p className="tradedetails-instructions-from">
                  Confirm your details below for this transaction. Make sure you
                  look for the exact words in the <b>reference message</b> when looking
                  for the transfer from the buyer.
                </p>
              )}
              <div className="bank_details">
                {sellerPaymentDetail && (
                  <div>
                    <div className="trade_bank_account tradedetails-tradeinfo-specifics-text">
                      <label className="tradedetails-tradeinfo-payment-textnames">Payment Method:</label> 
                      <span className="tradedetails-tradeinfo-payment-textvalues">{paymentMethod}
                        <button onClick={() => handleCopy(paymentMethod, "paymentmethod")} className="tradedetails-copybtn">
                          <i className={`fas fa-${copied === "paymentmethod" ? "check" : "copy"}`}  title={`${copied ? "Copied" : "Copy"}`}></i>
                        </button>
                      </span>
                    </div>
                    
                    <div className="trade_bank_account tradedetails-tradeinfo-specifics-text">
                      <label className="tradedetails-tradeinfo-payment-textnames">{paymentMethod} Details:</label>
                      <span className="tradedetails-tradeinfo-payment-textvalues">{sellerPaymentDetail}
                        <button onClick={() => handleCopy(sellerPaymentDetail, "sellerPaymentDetail")} className="tradedetails-copybtn">
                          <i className={`fas fa-${copied === "sellerPaymentDetail" ? "check" : "copy"}`}  title={`${copied ? "Copied" : "Copy"}`}></i>
                        </button>
                      </span>
                    </div>

                    <div className="trade_bank_account tradedetails-tradeinfo-specifics-text">
                      <label className="tradedetails-tradeinfo-payment-textnames">Reference Message:</label>
                      <span className="tradedetails-tradeinfo-payment-textvalues">{referenceMessage || "hands polish"}
                        <button onClick={() => handleCopy(referenceMessage || "hands polish", "referenceMessage")} className="tradedetails-copybtn">
                          <i className={`fas fa-${copied === "referenceMessage" ? "check" : "copy"}`}  title={`${copied ? "Copied" : "Copy"}`}></i>
                        </button>
                      </span>
                    </div>

                    <div className="trade_bank_account tradedetails-tradeinfo-specifics-text">
                      <label className="tradedetails-tradeinfo-payment-textnames">Total Amount:</label> 
                      <span className="tradedetails-tradeinfo-payment-textvalues">{transferTotal}
                        <button onClick={() => handleCopy(transferTotal, "transferTotal")} className="tradedetails-copybtn">
                          <i className={`fas fa-${copied  === "transferTotal" ? "check" : "copy"}`}  title={`${copied ? "Copied" : "Copy"}`}></i>
                        </button>
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div>
                <p>{isSeller ? "Buyer's Details" : "Seller's Details"}</p>
                <div>
                  <div>
                    <div>
                      <div className="trade_bank_account">
                        <div className="trade_bank_account tradedetails-tradeinfo-specifics-text">
                          <label className="tradedetails-tradeinfo-payment-textnames">Name:</label> 
                          <span className="tradedetails-tradeinfo-payment-textvalues">{isSeller ? buyerName : sellerUsername}</span>
                        </div>
                        <div className="trade_bank_account tradedetails-tradeinfo-specifics-text">
                          <label className="tradedetails-tradeinfo-payment-textnames">Total Amount:</label>
                          <span className="tradedetails-tradeinfo-payment-textvalues">{transferTotal}
                            <button onClick={() => handleCopy(transferTotal, "transferTotal")} className="tradedetails-copybtn">
                              <i className={`fas fa-${copied === "transferTotal" ? "check" : "copy"}`} title={`${copied ? "Copied" : "Copy"}`}></i>
                            </button>
                          </span>
                        </div>
                        <div className="trade_bank_account tradedetails-tradeinfo-specifics-text">
                        <label className="tradedetails-tradeinfo-payment-textnames" title="Transaction Reference or Description Message to include and watchout for when making payment">Transaction Reference Message:</label>
                          <span className="tradedetails-tradeinfo-payment-textvalues">{referenceMessage || "hands polish"}
                            <button onClick={() => handleCopy(referenceMessage || "hands polish", "referenceMessage")} className="tradedetails-copybtn">
                              <i className={`fas fa-${copied === "referenceMessage" ? "check" : "copy"}`} title={`${copied ? "Copied" : "Copy"}`}></i>
                            </button>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SellerOtherPaymentMethodDetails;
