import React from "react";

const WalletAddressesModal = ({ walletAddresses, selectedCurrency, handleSelectedWalletAddress, selectedWalletAddress, closeModal }) => (
<div
    className="modal fade"
    id="walletAddressesModal"
    tabIndex="-1"
    aria-labelledby="walletAddressesModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div className="modal-content pl-2 pr-2">
        <div className="modal-header mb-4">
          <h5 className="modal-title" id="walletAddressesModalLabel">
            Your Saved Wallet Addresses for PayPal
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => closeModal()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body" data-controller="clipboard">
          <div className="row">
            <div className="col-md-12">
              <div className="form-group row recipient">
                <label className="form-label">
                  Select PayPal Wallet address for {selectedCurrency}
                </label>
                <select
                  className="form-control expand-select addresses"
                  value={selectedWalletAddress}
                  onChange={handleSelectedWalletAddress}
                >
                  <option value="">
                    Select {" "}
                  </option>
                  {walletAddresses.map((address, i) => (
                    <option key={i} value={address.value}>
                      {address.label}
                    </option>
                  ))}
                </select>
              </div>
              <div style={{ float: "right" }}>
                <a title="Add more wallet addresses for my contacts" href="/contact_wallet_addresses">Address Management</a>
              </div>
              <div
                style={{
                  padding: "10px 0 20px 0",
                  textAlign: "center",
                  clear: "both"
                }}>
              </div>
            </div>
          </div>


          <div className="modal-footer border-0 pl-0 pr-0">
            <div className="col-md-6">
              <button
                type="button"
                className="btn btn-secondary pull-left"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
            <div className="col-md-6">
              <button
                type="button"
                className="btn btn-primary pull-right"
                data-dismiss="modal"
              >
                Select
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default WalletAddressesModal;
