import React from "react";
import AutoComplete from "../AutoComplete";

const WalletTransfer = ({
  selectedTransferType,
  submitting,
  transferTypes,
  destinationWallets,
  validationErrors,
  recipient,
  lookedUpRecipient,
  recipients,
  selectedDestinationWallet,
  handleChange,
  handleRecipientChange
}) => (
  <div>
    <div className="form-group row required recipient">
      <label className="form-label">To:</label>
      <AutoComplete
        updateRecipient={handleRecipientChange}
        placeholder="Enter your recipient's username or email"
        className={`form-control ${
          validationErrors?.recipientError ? "is-invalid" : ""
        }`}
        disabled={submitting == true ? true : false}
        suggestions={recipients}
        lookedUpRecipient={lookedUpRecipient}
        userInput={recipient}
      />
      {validationErrors?.recipientError && (
        <span className="error text-danger">
          <br />
          {validationErrors.recipientError}
        </span>
      )}
      <small id="amountHelpBlock" className="form-text text-muted">
        Enter username or email of the recipient
      </small>
    </div>
  </div>
);

export default WalletTransfer;
