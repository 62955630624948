import React from "react";

const AddFundsModal = ({ closeModal }) => (
  <div
    className="modal fade"
    id="addFundsModal"
    tabIndex="-1"
    aria-labelledby="addFundsModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div className="modal-content">
        <div className="modal-header mb-4">
          <h4 className="modal-title" id="addFundsModalLabel">Fund Your Account</h4>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => closeModal()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body" data-controller="clipboard">
          <div className="row mx-auto mt-4">
            <div className="col-md-12">
            The amount that you want to pay or transfer is greater than your available balance, please <a href="/add_funds" target="_blank"><strong>fund your account</strong></a>
            </div>
          </div>
          <div className="modal-footer ml-auto border-0 pl-2 pr-2 pt-4 pb-4">
            
              <div className="col-md-6 text-left">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal">
                  Close
                </button>
              </div>
              <div className="col-md-6 text-right">
                  <a
                    href="/add_funds"
                    target="_blank"
                    role="button"
                    className="btn btn-primary">Add Funds
                  </a>
              </div>
            
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default AddFundsModal;
