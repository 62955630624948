import React from "react";

const Terms = ({ offerTerms }) => (
  <div>
    <div className="createtrade-terms" title="Broker's Trade Terms">
      <h4>
        <i className="material-icons">assignment</i>
        <span>Terms</span>
      </h4>
      <ul>
        <li>{offerTerms}</li>
      </ul>
    </div>
  </div>
);

export default Terms;
