import React, { useState, useEffect } from "react";
import "../../../styles/trademodals.scss";

const DisputeInfoModal = ({ isClaimant, onClose }) => {
  return (
    <div
      className="modal-status-cont"
      tabIndex={-1}
      aria-hidden="true"
      id="disputeInfoModal"
      aria-labelledby="disputeInfoModalLabel"
    >
      <div className="modal-status-alert-cont">
        <div>
          <button
            type="button"
            className="close"
            onClick={() => {onClose('disputeInfoModal')}}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <br/>
        </div>
        <div>
          <p className="modal-title" id="disputeInfoModalLabel">
            Trade has been Disputed
          </p>
          <div className="trademodal-notice">
            <span>
              {isClaimant &&
                "You have created a dispute for this transaction. The support desk will reach out to you. Please check inbox for details."}
              {!isClaimant &&
                "A dispute has been submitted for this transaction, please check your email for details."}
            </span>
          </div>
          <br/>
          <div className="modal-buttons-section">
            <div>
              <button
                type="button"
                className="modal-tradeaction-paidbtn"
                onClick={() => {onClose('disputeInfoModal')}}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DisputeInfoModal;
