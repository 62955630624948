import React from "react";

const Tags = ({ tags }) => (
  <>
    {tags !== "" && tags?.length > 0 && (
      <div>
        <div className="createtrade-badge-cont" title="Broker's Trade Tags">
          {tags?.map((tag, index) => (
            <p key={index} className="createtrade-badge mr-1">
              <i className="fas fa-tags"></i>{" "}
              {tag.label}
            </p>
          ))}
        </div>
      </div>
    )}
  </>
);

export default Tags;
