import React, { Component } from "react";
import axios from "axios";
import { getRequest, postRequest } from "../../utils/httpRequest";

class Coinify extends Component {
  constructor(props) {
    super(props);

    this.state = {
      offlineToken: "",
      accessToken: ""
    };
  }

  componentDidMount() {
    const { tradeParams } = this.props;
    if (!this.props.coinifyUser) {
      const traderSignupParams = {
        email: tradeParams.email,
        partnerId: tradeParams.partnerId,
        generateOfflineToken: true,
        trustedEmailValidationToken: tradeParams.trustedEmailValidationToken,
        profile: {
          address: {
            state: tradeParams.state,
            country: tradeParams.country
          }
        }
      };

      postRequest(
        `${this.props.coinifyBaseUrl}/signup/trader`,
        traderSignupParams
      )
        .then(traderResponse => {
          this.setState({
            offlineToken: traderResponse.offlineToken,
            traderId: traderResponse.trader.id
          });
          this.setupUser(traderResponse);
        })
        .catch(error => console.log(error));
    } else {
      this.getTrader();

      this.createTradeInformation(this.props.coinifyUser.offline_token);
    }
  }

  getTrader = () => {
    postRequest(`${this.props.coinifyBaseUrl}/auth`, {
      grant_type: "offline_token",
      offline_token: this.props.coinifyUser.offline_token
    })
      .then(authResponse => {
        this.setState({ accessToken: authResponse.access_token });

        getRequest(`${this.props.coinifyBaseUrl}/traders/me`, {
          Authorization: "Bearer " + authResponse.access_token
        })
          .then(response => {
            this.setState({ trader: response });
          })
          .catch(error => console.log(error));
      })
      .catch(error => {
        console.error("Error:", error.response.data);
      });
  };

  setupUser = traderResponse => {
    const createCoinifyUserParams = {
      coinify_user: {
        trader_id: traderResponse.trader.id,
        offline_token: traderResponse.offlineToken
      }
    };

    const token = $('meta[name="csrf-token"]').attr("content");

    postRequest("/coinify", createCoinifyUserParams, {
      "X-CSRF-Token": token
    })
      .then(response => {
        this.setState({ kycToken: response.kyc_token });
        this.createTradeInformation(traderResponse.offlineToken);
      })
      .catch(error => console.log(error));
  };

  createTradeInformation = offlineToken => {
    postRequest(`${this.props.coinifyBaseUrl}/auth`, {
      grant_type: "offline_token",
      offline_token: offlineToken
    })
      .then(authResponse => {
        this.setState({ accessToken: authResponse.access_token });
        this.createPersonalInformation(authResponse.access_token);
        this.createSourceOfFunds(authResponse.access_token);
      })
      .catch(error => {
        console.error("Error:", error.response.data);
      });
  };

  createPersonalInformation = accessToken => {
    const { tradeParams } = this.props;
    const { trader } = this.state;
    if (trader && trader.personalInformationState === "approved") {
      return;
    }

    postRequest(
      `${this.props.coinifyBaseUrl}/traders/me/personal-information`,
      {
        address: {
          street: tradeParams.address,
          city: tradeParams.city,
          postalCode: tradeParams.postalCode
        }
      },
      {
        Authorization: "Bearer " + accessToken
      }
    )
      .then(response => {
        console.log("Success:", response);
      })
      .catch(error => {
        console.error("Error:", error);
      });
  };

  createSourceOfFunds = accessToken => {
    const { trader } = this.state;

    if (trader && trader.sourceOfFundsState === "approved") {
      return;
    }

    const { tradeParams } = this.props;

    postRequest(
      `${this.props.coinifyBaseUrl}/traders/me/source-of-funds`,
      {
        originOfFunds: tradeParams.sourceOfFunds,
        annualTradeVolumeBucketId: 1,
        areFundsFromLegalActivities: true
      },
      {
        Authorization: "Bearer " + accessToken
      }
    )
      .then(res => {
        console.log("Success:", res.data);
      })
      .catch(error => {
        console.error("Error:", error);
      });
  };

  createKYCInformation = () => {
    const { tradeParams } = this.props;
    const { trader } = this.state;
    if (trader && trader.identificationState === "approved") {
      return;
    }

    postRequest(
      `${this.props.coinifyBaseUrl}/partners/${tradeParams.partnerId}/kyc`,
      {
        traderId: this.state.traderId,
        externalId: tradeParams.verificationId,
        state: "approved",
        approveTime: tradeParams.verificationCreatedTime,
        createTime: tradeParams.verificationCreatedTime,
        pep: {
          approved: true,
          approveTime: tradeParams.verificationCreatedTime
        },
        sanction: {
          approved: true,
          approveTime: tradeParams.verificationCreatedTime
        },
        profile: {
          citizenCountry: tradeParams.country,
          name: tradeParams.name,
          dateOfBirth: tradeParams.dateOfBirth,
          address: {
            street: tradeParams.address,
            city: tradeParams.city,
            postalCode: tradeParams.zipCode,
            country: tradeParams.country
          }
        }
      },
      {
        Authorization: "Bearer " + this.state.kycToken
      }
    )
      .then(res => {
        getRequest(
          `/coinify/kyc_documents?review_id=${res.id}`
        ).then(response => this.uploadKycDocument(response, res.id));
      })
      .catch(error => console.log(error.response.data));
  };

  uploadKycDocument = (response, reviewId) => {
    const { coinifyBaseUrl, partnerId } = this.props;
    postRequest(
      `${coinifyBaseUrl}/partners/${partnerId}/kyc/${reviewId}/documents`,
      {},
      {
        Authorization: "Bearer " + token,
        "Content-Type": response.content_type,
        "X-Upload-Filename": response.file_name,
        "X-Upload-Document-Type": response.document_type,
        "X-Upload-External-Id": response.external_id
      }
    )
      .then(res => {
        console.log("Success:", res.data);
      })
      .catch(error => {
        console.error("Error:", error.response.data);
      });
  };

  render() {
    return <div>Please use the same email as your BananaCrystal Email</div>;
  }
}

export default Coinify;
