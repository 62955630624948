import { Controller } from "stimulus";
import Rails from "@rails/ujs";
import { sepaCountries } from "../model/payment_methods";

export default class extends Controller {
  static targets = [
    "paymentMethodOptions",
    "wire",
    "ach",
    "zelle",
    "card",
    "sepa",
    "countrySelect",
    "bankIntermmediaryRequired",
    "isBusinessAccount",
    "isStoreAccount",
    "storeSelect"
  ];


  connect() {
    console.log('payments controller connected')
    if (this.hasPaymentMethodOptionsTarget) {
      this.paymentMethodOptionsTarget.style.display = "none";
    }
    if(this.hasBankIntermmediaryRequiredTarget) {
      this.bankIntermmediaryRequiredTarget.value = "0";
    }
    if(this.hasIsBusinessAccountTarget) {
      this.isBusinessAccountTarget.value = "0";
    }
    if(this.hasIsStoreAccountTarget) {
      this.isStoreAccountTarget.value = "0";
    } else {
      this.isStoreAccountTarget.value = "0";
    }
    if(this.hasStoreSelectTarget) {
      this.storeSelectTarget.value = '';
      this.storeSelectTarget.style.display = "none";
    }
  }

  changeLinks() {
    let wire_url = '/payment_methods/new_wire_bank_account';
    let ach_url = '/payment_methods/new_ach_bank_account';
    let card_url = '/payment_methods/new_card';
    let sepa_url = '/payment_methods/new_sepa_bank_account';
    let zelle_url = '/payment_methods/new_zelle_bank_account';
    let bank_intermmediary_flag = this.countrySelectTarget.value == 'US' ? '0' : '1'
    // add country to params
    this.wireTarget.href = wire_url 
      + '?country=' + this.countrySelectTarget.value 
      + '&bank_intermmediary_required=' + bank_intermmediary_flag
      + '&is_business=' + this.isBusinessAccountTarget.value
      + '&is_store=' + this.isStoreAccountTarget.value
      +  '&store=' + this.storeSelectTarget.value;

    // console.log(this.wireTarget.href)
    document.getElementById("button-wire").href = this.wireTarget.href;

    // handle payouts
    if (this.hasAchTarget) {
      this.achTarget.href = ach_url 
      + '?country=US'
      + '&bank_intermmediary_required=0' 
      + '&is_business=' + this.isBusinessAccountTarget.value
      + '&is_store=' + this.isStoreAccountTarget.value
      +  '&store=' + this.storeSelectTarget.value;
      document.getElementById("button-ach").href = this.achTarget.href;
    }

    if (this.hasZelleTarget) {
      this.zelleTarget.href = zelle_url 
      + '?country=US'
      + '&bank_intermmediary_required=0' 
      + '&is_business=' + this.isBusinessAccountTarget.value
      + '&is_store=' + this.isStoreAccountTarget.value
      +  '&store=' + this.storeSelectTarget.value;
      document.getElementById("button-zelle").href = this.zelleTarget.href;
    }

    // handle cards
    if (this.hasCardTarget) {
      this.cardTarget.href = card_url + '?country=' + this.countrySelectTarget.value +'&store=' + this.storeSelectTarget.value;
      document.getElementById("button-card").href = this.cardTarget.href;
    }

    if (this.hasSepaTarget) {
      this.sepaTarget.href = sepa_url + '?country=' + this.countrySelectTarget.value +'&store=' + this.storeSelectTarget.value;
      document.getElementById("button-sepa").href = this.sepaTarget.href;
    }
  }

  onChangeBankIntermmediaryRequired() {
    if(this.bankIntermmediaryRequiredTarget.value == '0') {
      this.bankIntermmediaryRequiredTarget.value = '1';
    } else {
      this.bankIntermmediaryRequiredTarget.value = '0';
    }
    this.changeLinks();
  }


  onChangeIsBusinessAccount() {
    if(this.isBusinessAccountTarget.value == '0') {
      this.isBusinessAccountTarget.value = '1';
    } else {
      this.isBusinessAccountTarget.value = '0';
    }
    this.changeLinks();
  }

  onChangeIsStoreAccount() {
    if(this.isStoreAccountTarget.value == "0") {
      this.isStoreAccountTarget.value = 1;
      this.storeSelectTarget.style.display = "block";
    } else {
      this.isStoreAccountTarget.value = 0;
      this.storeSelectTarget.value = '';
      this.storeSelectTarget.style.display = "none";
    }
    this.changeLinks();
  }


  onChangeStore() {
    let storeSelectFormControl= document.getElementById("payment_method_store");
    if (storeSelectFormControl) {
      this.changeLinks();
    }
  }

  onChangeCountry() {
    //new
    let countrySelectFormControl= document.getElementById("payment_method_country");
    let bankIntermmediaryCheckbox = document.getElementById("bank_intermmediary_checkbox");
    if (this.countrySelectTarget.value === '') {
      countrySelectFormControl.className ='country-select form-control is-invalid';
    } else {
      countrySelectFormControl.className ='country-select form-control';
    }

    // new_payment_method
    if (this.countrySelectTarget.value === "US") {
      if (this.hasAchTarget) {
          this.achTarget.style.display = "block";
      }
      if (this.hasZelleTarget) {
        this.zelleTarget.style.display = "block";
      }
    } else {
      if (this.hasAchTarget) {
        this.achTarget.style.display = "none";
      }
      if (this.hasZelleTarget) {
        this.zelleTarget.style.display = "none";
      }
    }

    if (sepaCountries().includes(this.countrySelectTarget.value)) {
      if (this.hasSepaTarget)
        this.sepaTarget.style.display = "block";
    } else {
      if (this.hasSepaTarget)
      this.sepaTarget.style.display = "none";
    }

    this.paymentMethodOptionsTarget.style.display = "block";

    this.changeLinks();
  }

}
