import React, { Component, useState, useEffect } from "react";
import { ReactSVG } from "react-svg";
import _ from "underscore";
import { getRequest, postRequest, patchRequest } from "../../utils/httpRequest";
import cashAppLogo from "../../../assets/images/payment_networks/cashapp.png";
import { formErrors } from "../../utils/formErrors";

class CryptoTransferFromCashApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sourceWallet: props.sourceWallet,
      destinationWallet: props.destinationWallet,
      sourceCurrency: props.sourceCurrency,
      sourceCurrencyImgSrc: props.sourceCurrencyImgSrc,
      sourceCurrencyDescription: props.sourceCurrencyDescription,
      sourceCurrencyBalance:
        props.sourceCurrencyBalance || parseFloat(0.0).toFixed(4),
      exchangeRate: props.exchangeRate,
      selectedTransferType: "recipient",
      recipient: props.recipient || "",
      recipients: props.recipients || [],
      amount: props.sourceCurrencyBalance || parseFloat(0.0).toFixed(4),
      destinationAmount: "TBD",
      destinationCurrency: "USDT",
      recipientError: "",
      error: "",
      amountError: "",
      mode: "form",
      invalidTransfer: false,
      submitting: false,
      validationErrors: {},
      fees: "",
      blockchainAddress: props.blockchainAddress,
      blockchainAddressTag: "",
      depositAddress: props.depositAddress,
      depositAddressQrCode: props.depositAddressQrCode,
      walletAddresses: props.walletAddresses || [],
      submitTransfer: false,
      selectedWalletAddress: "",
      lookedUpRecipient: {},
      withdraw: props.withdraw || false,
      exchangeRate: props.exchangeRate,
      stepOne: true,
      stepTwo: false,
      stepThree: false,
    };
  }

  componentDidMount() {}

  showModal = () => {
    $("#walletAddressesModal").modal("show");
  };

  closeModal = () => {
    $("#walletAddressesModal").modal("hide");
  };

  getChainWalletAddress = (chain) => {
    getRequest(`/crypto_transfers/chain_wallet_addresses?chain=${chain}`).then(
      (addresses) => {
        const walletAddresses = addresses.map((address) => {
          return {
            label: address.wallet_label,
            address: address.address,
            addressTag: address.tag,
            value: address.id,
          };
        });

        this.setState({ walletAddresses });
      }
    );
  };

  getWalletBalance = (chain) => {
    getRequest(`/crypto_transfers/chain_wallet_balance?chain=${chain}`)
      .then((response) => {
        this.setState({ sourceCurrencyBalance: response[chain] });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleAmountChange = (e) => {
    this.setState({ amount: e.target.value }, () => this.validateAmount());
  };

  validateAmount = () => {
    const { validationErrors } = this.state;

    this.setState({
      error: "",
      sourceCurrencyBalance: this.state.sourceCurrencyBalance,
    });

    if (Number(this.state.amount) <= 0) {
      validationErrors.amountError =
        "Amount to transfer cannot be zero. Please enter a higher amount.";

      this.setState({
        validationErrors,
        invalidTransfer: true,
      });
    } else if (
      Number(this.state.amount) > Number(this.state.sourceCurrencyBalance)
    ) {
      validationErrors.amountError =
        "Amount to transfer is greater than your available balance, please enter a lower amount.";

      this.setState({
        validationErrors,
        invalidTransfer: true,
      });
    } else {
      validationErrors.amountError = "";
      this.setState({
        validationErrors,
        invalidTransfer: false,
      });
    }
  };

  validateFormFields = () => {
    const { amount, recipient, selectedTransferType, blockchainAddress } =
      this.state;
    const { type } = this.props;

    let validationErrors = {};

    this.getWalletBalance(this.state.sourceCurrency);

    if (Number(amount) <= 0) {
      validationErrors.amountError = "amount should be greater than zero";
    }

    this.validateAmount();
    return validationErrors;
  };

  refreshPage = (e) => {
    e.preventDefault();
    const url = window.location.href;
    window.location.assign(url);
  };

  setApiErrors = (errors) => {
    const formattedErrors = formErrors(errors);

    const { validationErrors } = this.state;

    const amountError = formattedErrors["gross_amount"];
    const recipientError = formattedErrors["destination_wallet_id"];

    validationErrors.amountError = amountError;
    validationErrors.recipientError = recipientError;

    return validationErrors;
  };

  submitTransferPreview = (e) => {
    const {
      sourceWallet,
      destinationWallet,
      destinationWallets,
      exchangeRate,
      recipient,
      amount,
      fees,
      sourceCurrency,
      destinationCurrency,
      selectedTransferType,
      blockchainAddress,
      blockchainAddressTag,
      sourceCurrencyBalance,
    } = this.state;

    e.preventDefault();

    const validationErrors = this.validateFormFields();

    if (!_.isEmpty(validationErrors)) {
      this.setState({ validationErrors, invalidTransfer: true });

      return;
    }

    this.setState({ submitting: true, error: "", validationErrors: {} });

    const data = {
      crypto_transfer: {
        total_type: "total_to_withdraw",
        source_wallet_id: sourceWallet,
        recipient: selectedTransferType === "recipient" ? recipient : "",
        destination_wallet_id: destinationWallet,
        gross_amount: amount,
        gross_amount_currency: sourceCurrency,
        source_currency: sourceCurrency,
        destination_currency: destinationCurrency,
        net_amount: (amount - fees) * exchangeRate, // temp ammount
        net_amount_currency: destinationCurrency,
        destination_type: this.props.type,
        blockchain_address: blockchainAddress,
        blockchain_address_tag: blockchainAddressTag,
        selected_currency_balance: sourceCurrencyBalance,
      },
    };

    const crsfToken = $('meta[name="csrf-token"]').attr("content");

    postRequest("/crypto_transfers", data, {
      "X-CSRF-Token": crsfToken,
    })
      .then((response) => {
        if (response.external_status === "error") {
          if (response.failure_reason == "Insufficient funds") {
            this.setState({
              error:
                "Insufficient funds, please enter a lower amount to cover the transaction and/or network fees.",
              submitting: false,
            });
          } else {
            this.setState({
              error: response.failure_reason,
              submitting: false,
            });
          }
        } else {
          this.setState({
            fees: Number.parseFloat(response.fees),
            exchangeRate: response.exchange_rate,
            fees: response.total_external_fees,
            submitTransfer: true,
            transferId: response.id,
            expiresAt: response.expiresAt,
            destinationAmount: response.destination_amount,
            sourceAmount: response.source_amount,
          });
        }
      })
      .catch((error) => {
        console.log(error);

        const errors = this.setApiErrors(error.data.errors);

        this.setState({ validationErrors: errors, submitting: false });
      });
  };

  submitTransfer = (e) => {
    const { transferId, sourceWallet, destinationWallet } = this.state;

    e.preventDefault();

    getRequest(`/crypto_transfers/${transferId}/resend_confirmation`).then(
      () => {
        window.location = `/crypto_transfers/${transferId}`;
      }
    );
  };

  cancelTransfer = (e) => {
    const { transferId } = this.state;

    e.preventDefault();

    const data = {
      crypto_transfer: {
        id: transferId,
      },
    };

    const crsfToken = $('meta[name="csrf-token"]').attr("content");

    patchRequest(`/crypto_transfers/${transferId}/cancel`, data, {
      "X-CSRF-Token": crsfToken,
    })
      .then(() => {
        // window.location = `/crypto_transfers/`;
        this.refreshPage(e);
        // window.location = `/crypto_transfers/new_blockchain_to_usd_transfer?token=${sourceCurrency}`;
      })
      .catch((error) => {
        this.setState({ submitting: false });
        console.log(error);
      });
  };

  render() {
    const { sourceWallets, currencies, type, recipients } = this.props;

    const {
      amount,
      sourceWallet,
      destinationWallet,
      destinationWallets,
      sourceCurrency,
      sourceCurrencyBalance,
      sourceCurrencyDescription,
      sourceCurrencyImgSrc,
      destinationCurrency,
      destinationAmount,
      selectedTransferType,
      recipient,
      recipientError,
      mode,
      error,
      amountError,
      invalidTransfer,
      submitting,
      validationErrors,
      fees,
      exchangeRate,
      submitTransfer,
      transferId,
      blockchainAddress,
      blockchainAddressTag,
      depositAddressQrCode,
      depositAddress,
      walletAddresses,
      balances,
      selectedWalletAddress,
      lookedUpRecipient,
      stepOne,
      stepTwo,
      stepThree,
    } = this.state;

    return (
      <div className="container-fluid">
        <div className="payment-networks mx-auto text-center">
          <img src={cashAppLogo} width="180" className=" pl-2 pr-2" />
        </div>
        <hr />

        {stepOne && (
          <div className="step-one-wrapper">
            <div className="address mx-auto text-center mt-2 mb-2">
              <div className="font-0_9 font-weight-bold text-center mb-4">
                STEP 1. Send BTC from Cash App to the address below
              </div>
              <div className="text-center mt-4 mx-auto">
                <ReactSVG
                  src={sourceCurrencyImgSrc}
                  className="svg30 pt-2 mt-4"
                  wrapper="div"
                  beforeInjection={(svg) => {
                    svg.setAttribute("style", "width: 30px");
                  }}
                />

                <div className="text-muted">
                  {depositAddressQrCode && (
                    <span className="font-0_75">Scan QR Code OR</span>
                  )}
                  <div className="font-0_75">Copy Address Below</div>
                </div>
                <h4 className="m-0 mt-3 p-0 text-muted leading-normal font-1_4">
                  {" "}
                  {sourceCurrencyDescription} {sourceCurrency}{" "}
                </h4>
                <img
                  src={depositAddressQrCode}
                  width="200"
                  className=" pl-2 pr-2"
                />
              </div>
              <div className="mb-1">
                <code className="text-default">{depositAddress}</code>{" "}
                <button
                  className="btn btn-link"
                  onClick={() => navigator.clipboard.writeText(depositAddress)}
                >
                  <i className="fa fa-copy fa-2x"></i>
                </button>
              </div>
            </div>
            <hr />
            <div className="m-auto">
              <div className="col-md-6 float-md-right">
                <button
                  name="button"
                  type="submit"
                  className="btn btn-primary pull-right ml-4 mb-4"
                  onClick={() =>
                    this.setState({
                      stepOne: false,
                      stepTwo: true,
                      stepThree: false,
                    })
                  }
                >
                  Next
                </button>
              </div>
              <div className="col-md-6 float-md-left">
                <button
                  name="cancelone"
                  type="submit"
                  className="btn pull-left ml-4 mb-4"
                  onClick={() => {
                    window.location.href = "/add_funds_crypto/select_token";
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
        {stepTwo && (
          <div className="step-two-wrapper">
            <div className="font-0_9 font-weight-bold text-center">
              STEP 2.{" "}
              {sourceCurrencyBalance == 0
                ? "Wait"
                : "Use your balance or wait "}{" "}
              to receive the BTC you sent from Cash App
            </div>
            <div className="text-center mx-auto">
              <div className="col-md-12">
                <ReactSVG
                  src={sourceCurrencyImgSrc}
                  className="svg30 pt-2"
                  wrapper="div"
                  beforeInjection={(svg) => {
                    svg.setAttribute("style", "width: 30px");
                  }}
                />
                <div className="text-muted pt-2">
                  <span className="font-0_75">
                    It can take from 5 minutes up to 24 hours
                  </span>
                  <div className="font-0_75">to receive BTC from Cash App</div>
                </div>
                <h4 className="m-0 mt-3 mb-4 p-0 text-muted leading-normal font-1_4">
                  {" "}
                  {sourceCurrencyDescription} {sourceCurrency}{" "}
                </h4>
                <h2
                  className="m-0 counter text-default p-0 font-weight-normal"
                  style={{ fontSize: "40px" }}
                >
                  {sourceCurrencyBalance == 0
                    ? parseFloat(0.0).toFixed(4)
                    : sourceCurrencyBalance}
                </h2>
                {destinationCurrency === "USDT" ? "$ " : destinationCurrency}{" "}
                {parseFloat(sourceCurrencyBalance * exchangeRate).toFixed(2)}
              </div>
            </div>
            <div className="mx-auto pt-4 pb-4 text-center">
              <div className="col-md-12">
                <button
                  name="button"
                  type="submit"
                  className="btn btn-link mb-2"
                  onClick={() =>
                    this.getWalletBalance(this.state.sourceCurrency)
                  }
                >
                  <i className="fa fa-refresh" /> Refresh Balance
                </button>
              </div>
            </div>
            <hr />
            <div className="m-auto pt-2">
              <div className="col-md-6 float-md-right">
                <button
                  name="button"
                  type="submit"
                  className="btn btn-primary pull-right ml-4 mb-4"
                  onClick={() =>
                    this.setState({
                      stepOne: false,
                      stepTwo: false,
                      stepThree: true,
                    })
                  }
                  disabled={sourceCurrencyBalance == 0}
                >
                  Next
                </button>
              </div>
              <div className="col-md-6 float-md-left">
                <button
                  name="button"
                  type="submit"
                  className="btn pull-left ml-4 mb-4"
                  onClick={() =>
                    this.setState({
                      stepOne: true,
                      stepTwo: false,
                      stepThree: false,
                    })
                  }
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        )}
        {stepThree && sourceCurrencyBalance > 0 && (
          <div className="step-three-wrapper">
            <div className="font-0_9 font-weight-bold pb-4">
              <div>STEP 3. Enter the amount of BTC, Preview, then Submit</div>
            </div>
            <form
              className="form-horizontal transfer-form pl-2 pr-2"
              id="new_crypto_transfer"
            >
              <div className="amount-wrapper">
                <div className="form-group row required ">
                  <div className="input-group mb-2 mr-sm-2">
                    <div className="input-group-prepend">
                      <div
                        className="input-group-text selected-currency font-2_25"
                        style={{
                          color: "#4c3f84",
                          focus: { color: "#414da7" },
                        }}
                      >
                        {sourceCurrency}
                      </div>
                    </div>
                    <input
                      step="any"
                      autoFocus="autofocus"
                      style={{ maxWidth: "400px" }}
                      placeholder="0.000"
                      className={`form-control font-2_25 ${
                        validationErrors?.amountError ? "is-invalid" : ""
                      }`}
                      required="required"
                      type="number"
                      value={amount}
                      onChange={this.handleAmountChange}
                      id="amount"
                      disabled={submitting == true ? true : false}
                    />
                  </div>
                  {error && (
                    <span className="error text-danger">
                      <br />
                      {error}
                    </span>
                  )}
                  {validationErrors?.amountError && (
                    <span className="error text-danger">
                      <br />
                      {validationErrors.amountError}
                    </span>
                  )}
                </div>
                <div className="form-group row font-0_8 text-muted pl-1">
                  <span className="col-md-6 text-muted font-weight-bold">
                    Max: {sourceCurrencyBalance} ({sourceCurrency})
                  </span>
                  <span className="col-md-6 text-muted">
                    1 {sourceCurrency} = ${" "}
                    {Number.parseFloat(exchangeRate).toFixed(2)}
                  </span>
                </div>
              </div>

              <div className="preview-wrapper pt-4">
                <h4>Review Details</h4>
                <hr />
                <div className="mx-auto">
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group row d-block ">
                        <label className="form-label">
                          Amount
                          <a
                            data-toggle="tooltip"
                            className="btn btn-link"
                            data-original-title="Subtotal amount (Crypto)."
                            data-tip=""
                          >
                            <i className="fa fa-info-circle"></i>
                          </a>
                        </label>
                        <small
                          id="netAmountHelpBlock"
                          className="form-text text-muted"
                        ></small>
                      </div>{" "}
                    </div>
                    <div className="col-6 pr-0">
                      <div className="form-group row ">
                        <div className="input-group mb-2 mr-sm-2">
                          <div className="input-group-prepend">
                            <div className="input-group-text selected-currency border-0 pr-0">
                              {sourceCurrency}
                            </div>
                          </div>
                          <input
                            className="form-control border-0"
                            placeholder="0.00"
                            data-target="cryptoTransfer.outputNetAmount"
                            required="required"
                            readOnly="readonly"
                            type="number"
                            value={amount}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group row d-block ">
                        <label className="form-label">
                          Network Fee
                          <a
                            data-toggle="tooltip"
                            className="btn btn-link"
                            data-original-title="Transaction and processing fees (Crypto)"
                          >
                            <i className="fa fa-info-circle"></i>
                          </a>
                        </label>
                        <small
                          id="feesHelpBlock"
                          className="form-text text-muted"
                        ></small>
                      </div>{" "}
                    </div>
                    <div className="col-6">
                      <div className="form-group row ">
                        <div className="input-group mb-2 mr-sm-2">
                          <div className="input-group-prepend">
                            <div className="input-group-text selected-currency border-0 pr-0">
                              {destinationCurrency === "USDT"
                                ? "$ "
                                : destinationCurrency}
                            </div>
                          </div>
                          <input
                            className="form-control border-0"
                            placeholder="TBD"
                            data-target="cryptoTransfer.outputFeesAmount"
                            required="required"
                            readOnly="readonly"
                            type="number"
                            value={parseFloat(fees * exchangeRate).toFixed(2)}
                          />
                        </div>
                      </div>{" "}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6">
                      <div className="form-group row d-block ">
                        <label className="form-label">
                          Total
                          <a
                            data-toggle="tooltip"
                            className="btn btn-link"
                            data-original-title="Total"
                          >
                            <i className="fa fa-info-circle"></i>
                          </a>
                        </label>
                        <small
                          id="netAmountHelpBlock"
                          className="form-text text-muted"
                        ></small>
                      </div>{" "}
                    </div>
                    <div className="col-6">
                      <div className="form-group row ">
                        <div className="input-group mb-2 mr-sm-2">
                          <div className="input-group-prepend">
                            <div className="input-group-text selected-currency border-0 pr-0">
                              {destinationCurrency === "USDT"
                                ? "$ "
                                : destinationCurrency}
                            </div>
                          </div>
                          <input
                            className="form-control border-0"
                            placeholder="TBD"
                            data-target="cryptoTransfer.outputNetAmount"
                            required="required"
                            readOnly="readonly"
                            type="number"
                            value={parseFloat(destinationAmount).toFixed(2)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="text-muted font-0_9 text-left">
                {submitting ? (
                  <div className="pt-4 mt-2">
                    <i className="fa fa-exclamation-circle"></i> The quoted fees
                    are <strong>valid for the next 10 min</strong>. If your
                    quote expires, simply start again. Click Submit to continue.
                  </div>
                ) : (
                  <div>
                    <i className="fa fa-exclamation-circle"></i> Click Preview
                    to view your total including fees.
                  </div>
                )}
              </div>
              <hr />
              <div className="form-actions">
                {submitTransfer && transferId ? (
                  <div className="m-auto">
                    <div className="col-md-6 float-md-right">
                      <button
                        name="button"
                        type="submit"
                        className="btn btn-primary pull-right ml-4 mb-4"
                        onClick={this.submitTransfer}
                      >
                        Submit
                      </button>
                    </div>
                    <div className="col-md-6 float-md-left">
                      <button
                        name="cancel"
                        type="submit"
                        className="btn pull-left ml-4 mb-4"
                        onClick={this.cancelTransfer}
                        disabled={invalidTransfer}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="mx-auto">
                    <div className="float-md-right">
                      <button
                        name="button"
                        type="submit"
                        className="btn btn-primary pull-right ml-4 mb-4"
                        onClick={this.submitTransferPreview}
                        disabled={invalidTransfer || submitting}
                      >
                        {submitting ? (
                          <>
                            <i className="fa fa-circle-o-notch fa-spin"></i>
                            <span style={{ paddingLeft: "5px" }}>
                              Please Wait...Loading
                            </span>
                          </>
                        ) : (
                          <span>Preview</span>
                        )}
                      </button>
                    </div>
                    <div className="col-md-6 float-md-left">
                      <button
                        name="start_again"
                        type="submit"
                        className="btn pull-left ml-4 mb-4"
                        onClick={this.refreshPage}
                      >
                        Start Again
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </form>
          </div>
        )}
      </div>
    );
  }
}

export default CryptoTransferFromCashApp;
