import { Controller } from "stimulus";
import { validateWalletAddress } from "../model/walletAddress";

export default class extends Controller {
  static targets = ["address", "chain"];

  validateAddress() {
    const selectedChain = this.chainTarget.value?.toUpperCase();
    const address = this.addressTarget.value;

    const chain = ["BTC", "AVAX", "XLM"].includes(selectedChain)
      ? selectedChain
      : "ETH";

    const validAddress = validateWalletAddress(chain, address);

    if (validAddress) {
      this.addressTarget.setCustomValidity("");
    } else {
      this.addressTarget.setCustomValidity("Address is invalid");
    }
  }

  onChangeCoin() {
    $.ajax({
      url: "/contact_wallet_addresses/new",
      type: "GET",
      dataType: "script",
      data: {
        coin: $(".coin option:selected").val()
      }
    });
  }
}
