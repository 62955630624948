import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'accountType',
    'billingCountry',
    'bankAddressCountry'
  ];

  connect() {
    //console.log('connect')
    let bankAccountSelected = this.getSelectedBankAccountType();
    console.log('1', bankAccountSelected)
    if (bankAccountSelected == 0 || bankAccountSelected == 2) {
      this.displayUSAndNonUsIbanNotSupportedItems();
    } else if (bankAccountSelected == 1) {
      this.displayNonUsIbanItems();
    } else {
      this.hideAllItems();
    }
  }

  handleChange() {
    //console.log('handleChange')
    let bankAccountSelected = this.getSelectedBankAccountType();
    //console.log('bankAccountSelected ', bankAccountSelected);
    // us and non-us iban not supported
    if (bankAccountSelected == 0 || bankAccountSelected == 2) {
      //console.log('displayUSAndNonUSIbanNotSupportedItems');
      this.displayUSAndNonUsIbanNotSupportedItems();
      //non-us + iban
    } else if (bankAccountSelected == 1) {
      this.displayNonUsIbanItems();
      //console.log('displayNonUsIbanItems')
    } else {
      this.hideAllItems();
    }
  }

  displayUSAndNonUsIbanNotSupportedItems() {
    let { billingCountrySelected, bankAddressCountrySelected } = this.getSelectedCountries();

    this.addAccountNumberField();
    this.addAccountRoutingField();
    this.removeIbanField();

    if (billingCountrySelected == 'Nigeria' || bankAddressCountrySelected == 'Nigeria') {
      this.addBvnField();
    } else {
      this.removeBvnField();
    }
  }

  displayNonUsIbanItems() {
    let { billingCountrySelected, bankAddressCountrySelected } = this.getSelectedCountries();
    //console.log(this.getSelectedCountries());

    this.addIbanField();
    this.removeAccountNumberField();
    this.removeAccountRoutingField();

    // display country specific info
    if (billingCountrySelected == 'Nigeria' || bankAddressCountrySelected == 'Nigeria') {
      this.addBvnField();
    } else {
      this.removeBvnField();
    }
  }

  hideAllItems() {
    this.removeAccountNumberField();
    this.removeAccountRoutingField();
    this.removeIbanField();
    this.removeBvnField();
  }

  addAccountNumberField() {
    let { accountNumber, accountNumberInput } = this.getAccountNumberFields();
    accountNumberInput.readOnly = false;
    accountNumberInput.required = true;
    accountNumber[0].classList.add('required')
    accountNumberInput.classList.remove('is-invalid');

    accountNumberInput.classList.remove('d-none');
    accountNumberInput.classList.add('d-block');

    accountNumber[0].classList.remove('d-none');
    accountNumber[0].classList.add('d-block');
  }

  removeAccountNumberField() {
    let { accountNumber, accountNumberInput } = this.getAccountNumberFields();

    accountNumberInput.removeAttribute("required");
    accountNumberInput.readOnly = true;
    accountNumber[0].classList.remove('required')
    accountNumberInput.classList.remove('is-invalid');

    accountNumberInput.classList.remove('d-block');
    accountNumberInput.classList.add('d-none');

    accountNumber[0].classList.remove('d-block');
    accountNumber[0].classList.add('d-none');
  }

  addAccountRoutingField() {
    let { routingNumber, routingNumberInput } = this.getAccountRoutingFields();
    routingNumberInput.readOnly = false;
    routingNumberInput.required = true;
    routingNumber[0].classList.add('required')

    routingNumber[0].classList.remove('d-none');
    routingNumber[0].classList.add('d-block');
  }

  removeAccountRoutingField() {
    let { routingNumber, routingNumberInput } = this.getAccountRoutingFields();

    routingNumberInput.removeAttribute("required");
    routingNumberInput.readOnly = true;
    routingNumber[0].classList.remove('required')
    routingNumberInput.classList.remove('is-invalid');

    routingNumber[0].classList.remove('d-block')
    routingNumber[0].classList.add('d-none')
  }

  addIbanField() {
    let { iban, ibanInput } = this.getIbanFields();
    ibanInput.readOnly = false;
    ibanInput.required = true;

    ibanInput.classList.remove('d-none');
    ibanInput.classList.add('d-block');

    iban[0].classList.remove('d-none');
    iban[0].classList.add('d-block');
    iban[0].classList.add('required');
  }

  removeIbanField() {
    let { iban, ibanInput } = this.getIbanFields();

    if(iban && ibanInput) {
      ibanInput.removeAttribute("required");
      ibanInput.readOnly = true;

      iban[0].classList.remove('required');
      ibanInput.classList.remove('is-invalid');

      ibanInput.classList.remove('d-block');
      ibanInput.classList.add('d-none');

      iban[0].classList.remove('d-block');
      iban[0].classList.add('d-none');
    }
  }

  addBvnField() {
    let { bvn, bvnInput } = this.getBvnFields();

    bvnInput.classList.remove('d-none');
    bvnInput.classList.add('d-block');

    bvn[0].classList.remove('d-none');
    bvn[0].classList.add('d-block');
  }

  removeBvnField() {
    let { bvn, bvnInput } = this.getBvnFields();

    if (bvnInput) {
      bvnInput.classList.add('d-none');
      bvnInput.classList.remove('d-block');
    }

    if (bvn[0]) {
      bvn[0].classList.add('d-none');
      bvn[0].classList.remove('d-block');
    }
  }

  getSelectedBankAccountType() {
    let bankAccountSelected;
    let bankAccountSelect = document.getElementsByClassName('form-control form-control-lg expand-select bank_account_type');
    if (bankAccountSelect.length > 0) {
      bankAccountSelected = bankAccountSelect[0].selectedOptions[0].value;
    }
    return bankAccountSelected;
  }

  getSelectedCountries() {
    let bankAddressCountrySelected, billingCountrySelected = "US";

    let billingCountrySelect = document.getElementsByClassName('form-control form-control-lg expand-select billing_country');
    if (billingCountrySelect.length > 0) {
      billingCountrySelected = billingCountrySelect[0].selectedOptions[0].label
    }

    let bankAddressCountrySelect = document.getElementsByClassName('form-control form-control-lg expand-select bank_address_country');
    if (bankAddressCountrySelect.length > 0) {
      bankAddressCountrySelected = bankAddressCountrySelect[0].selectedOptions[0].label
    }
    return { billingCountrySelected, bankAddressCountrySelected };
  }


  getAccountNumberFields() {
    let accountNumber = document.getElementsByClassName('bank_account_number_group');
    let accountNumberInput = document.getElementById('payment_method_bank_account_number');

    return { accountNumber, accountNumberInput };
  }

  getAccountRoutingFields() {
    let routingNumber = document.getElementsByClassName('routing_number_group');
    let routingNumberInput = document.getElementById('payment_method_bank_account_routing_number');

    return { routingNumber, routingNumberInput };
  }

  getIbanFields() {
    let iban = document.getElementsByClassName('bank_account_iban_group');
    let ibanInput = document.getElementById('payment_method_bank_account_iban');

    return { iban, ibanInput };
  }

  getBvnFields() {
    let bvn = document.getElementsByClassName('bank_account_bvn_group');
    let bvnInput = document.getElementById('payment_method_bank_account_bvn');

    return { bvn, bvnInput };
  }
}
