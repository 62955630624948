import React, { useState } from "react";
import { postRequest } from "../../utils/httpRequest";
import Form from "./RequestForm";

const Brokers = () => {
  const [data, setData] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    business_name: "",
    phone_number: "",
    gender: "Male",
    email: "",
    country: "",
    proof_of_address: null,
    bank_statement: null,
    min_deposit: 0,
    max_deposit: 0,
  });

  const [errors, setErrors] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    business_name: "",
    phone_number: "",
    email: "",
    depositWarning: "",
  });

  const handleInputChange = (e) => {
    const { name, value, type, files } = e.target;

    setData((prevState) => ({
      ...prevState,
      [name]: type === "file" ? files[0] : value,
    }));

    if (name === "min_deposit" || name === "max_deposit") {
      validateDeposits(name, value);
    } else if (name === "phone_number") {
      validatePhoneNumber(value);
    } else if (["first_name", "middle_name", "last_name"].includes(name)) {
      validateName(name, value);
    } else if (name === "business_name") {
      validateBusinessName(value);
    } else if (name === "email") {
      validateEmail(value);
    } else if (name === "countryCode") {
      updatePhoneNumber(value);
    }
  };

  const validateDeposits = (name, value) => {
    const { min_deposit, max_deposit } = data;
    const min =
      name === "min_deposit" ? parseFloat(value) : parseFloat(min_deposit);
    const max =
      name === "max_deposit" ? parseFloat(value) : parseFloat(max_deposit);

    if ((!isNaN(min) && !isNaN(max)) && max < min) {
      setErrors((prevState) => ({
        ...prevState,
        depositWarning: "Maximum deposit cannot be less than minimum deposit.",
      }));
    } else {
      setErrors((prevState) => ({
        ...prevState,
        depositWarning: "",
      }));
    }
  };

  const validatePhoneNumber = (value) => {
    if (/[^0-9\s-\+]/.test(value)) {
      setErrors((prevState) => ({
        ...prevState,
        phone_number:
          "Phone number can only contain numbers, spaces, and hyphens.",
      }));
    } else {
      setErrors((prevState) => ({
        ...prevState,
        phone_number: "",
      }));
    }
  };

  const validateName = (name, value) => {
    if (/[^a-zA-Z\s]/.test(value)) {
      setErrors((prevState) => ({
        ...prevState,
        [name]: "Name can only contain letters and spaces.",
      }));
    } else {
      setErrors((prevState) => ({
        ...prevState,
        [name]: "",
      }));
    }
  };

  const validateBusinessName = (value) => {
    if (/[.<>]/.test(value)) {
      setErrors((prevState) => ({
        ...prevState,
        business_name: "Business name cannot contain periods, '<' or '>' special characters.",
      }));
    } else {
      setErrors((prevState) => ({
        ...prevState,
        business_name: "",
      }));
    }
  };

  const validateEmail = (value) => {
    // Regular expression to match a valid email pattern
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
    // Check if the email contains '<' or '>' characters
    if (/[<>]/.test(value)) {
      setErrors((prevState) => ({
        ...prevState,
        email: "Email address cannot contain '<' or '>' characters.",
      }));
    } else if (!emailPattern.test(value)) {
      setErrors((prevState) => ({
        ...prevState,
        email: "Please enter a valid email address.",
      }));
    } else {
      setErrors((prevState) => ({
        ...prevState,
        email: "",
      }));
    }
  };  

  const updatePhoneNumber = (countryCode) => {
    const selectedCountry = countryCodes.find(
      (code) => code.name === countryCode
    );
    if (selectedCountry) {
      const phoneCode = selectedCountry.code;
      setData((prevState) => ({
        ...prevState,
        phone_number: prevState.phone_number.startsWith(phoneCode)
          ? prevState.phone_number
          : `${phoneCode} `,
      }));
    }
  };

  const closePopup = (e) => {
    e.preventDefault();
    let errorParent = document.getElementById('form-error-cont');
    errorParent.style.display = "none";
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    let feedbackloader = document.getElementById("form-status-cont");
    let successbtn = document.getElementById("form-success-button");
    let formstatusText = document.getElementById("formstatus-popup-title-text");
    let errorParent = document.getElementById('form-error-cont');
    let errorCont = document.getElementById('form-error-message');
    feedbackloader.style.display = "flex";

    const formData = new FormData();
    // Validate deposits one last time before submission
    validateDeposits();

    // Check if there are any validation errors
    if (
      !errors.depositWarning &&
      !errors.phone_number &&
      !errors.first_name &&
      !errors.middle_name &&
      !errors.last_name &&
      !errors.business_name &&
      !errors.email &&
      data.max_deposit > 0 &&
      data.min_deposit > 0
    ) {
      try {
        // Create FormData object to match { broker: { first_name: first_name, ... } }
  
        const brokerParams = {
          broker: {
            first_name: data.first_name.toUpperCase(),
            middle_name: data.middle_name.toUpperCase(),
            last_name: data.last_name.toUpperCase(),
            business_name: data.business_name.toUpperCase(),
            phone_number: data.phone_number,
            gender: data.gender,
            email: data.email,
            country: data.country,
            min_deposit: Number(data.min_deposit),
            max_deposit: Number(data.max_deposit),
          },
        };

        Object.keys(brokerParams.broker).forEach((key) => {
          formData.append(`broker[${key}]`, brokerParams.broker[key]);
        });

        if (data.proof_of_address) {
          formData.append("broker[proof_of_address]", data.proof_of_address);
        }

        if (data.bank_statement) {
          formData.append("broker[bank_statement]", data.bank_statement);
        }

        await postRequest("/api/v1/brokers", formData, {
          "Content-Type": "multipart/form-data",
        })
        .then((resp) => {
          if (resp.status === 'pending') {
            // Modify the text of the feedbackloader from SUBMITTING to SUCCESSFUL
            formstatusText.textContent = " ";
            formstatusText.textContent = "Submission Successful";
            successbtn.style.display= "flex";
          }
        })
        .catch((err) => {
          console.log(err);
          // Hide the feedbackloader
          feedbackloader.style.display = "none";
          
          // Display the container for error notification
          errorParent.style.display = 'flex';
          // Clear previous errors
          errorCont.innerHTML = '';
        
          if (err.data) {
            const errors = err.data;
        
            for (const [field, messages] of Object.entries(errors)) {
              // Format the field name: replace underscores with spaces and capitalize the first letter
              const formattedField = field.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase());
              const messagesArray = [messages];
              messagesArray.forEach((message) => {
                let errorItem = document.createElement('p');
                errorItem.classList.add('error-popup-text')
                // Display the formatted field and the message
                errorItem.innerHTML = ` <span class="material-symbols-outlined broker-form-errormessages">error</span> ${formattedField} ${message}.`;
                errorCont.appendChild(errorItem);
              });
            }
          } else {
            // If the error isn't in the expected format, display a general message
            errorCont.innerHTML = 'An unexpected error occurred. Please try again.';
          }
        
          console.log(err);
        });        
      } catch (error) {
        console.error("Form submission failed:", error.message);
      }
    } else {
      // Hide the feedbackloader
      feedbackloader.style.display = "none";
      
      // Display the container for error notification
      errorParent.style.display = 'flex';
      // Clear previous errors
      errorCont.innerHTML = '';
      let fieldsMissing = document.createElement('div');
      fieldsMissing.classList.add('error-popup-hint')
      // Display the formatted field and the message
      fieldsMissing.innerHTML = ` <p class="error-popup-text"><span class="material-symbols-outlined broker-form-errormessages">error</span> Some fields were not properly filled.</p> <p><strong>Hint:</strong> Check phone number, minimum deposit and maximum deposit fields.</p>`;
      errorCont.appendChild(fieldsMissing);
      console.error("Form submission prevented due to validation errors.");
    }
  };

  return (
    <Form
      data={data}
      errors={errors}
      handleInputChange={handleInputChange}
      handleSubmit={handleSubmit}
      closePopup={closePopup}
      updatePhoneNumber={updatePhoneNumber}
    />
  );
};

export default Brokers;
