export const disputeMessages = (offer, disputeData, tradeData, bankAccount) => {
  let message;
  try {
    message = `
    <p>Hi <strong>${disputeData?.respondent_name}</strong> and <strong>${disputeData?.claimant_name}</strong>,</p>
    <p>A dispute has been initiated regarding the “Sell USD Trade” with reference No “${
      tradeData.id
    }.”</p>
    <p>Please see details of the transaction below:</p>
    <p>
      Deposit Amount (Local Currency): <strong>${
        tradeData.fiat_currency_code
      } ${tradeData.fiat}</strong><br />
      Deposit Amount (USD): <strong>${tradeData.crypto_amount}</strong><br />
      Depositor Name: <strong>${tradeData.buyer_name}</strong>
    </p>
    <p>Please, note that you will not be credited until this dispute has been resolved.</p>
    <p><strong>Possible Resolutions</strong></p>
    <ul>
      <li>Please share your proof of payment, it should be an easily verifiable document. Proof of payment can be a screenshot from your banking app, your bank statement (you can cover private/sensitive details), email alert, etc.</li>
      <li>If you did not make full payment, please pay the full amount.</li>
      <li>If you agree that the funds were not sent, please reply to this message with an instruction for us to cancel this transaction.</li>
    </ul>
    <p>If you do not respond to the dispute chat within 3 hours, the transaction will be resolved in favor of the disputer.</p>
    <p>Regards,<br />BananaCrystal Team</p>
  `;
  } catch (error) {
    console.log(error);
    message = "";
  }

  return message;
};

export const disputeResolvedMessages = (offer, disputeData, tradeData, bankAccount) => {
  let message;
  try {
    message = `
    <p>Hi <strong>${disputeData?.respondent_name}</strong> and <strong>${disputeData?.claimant_name}</strong>,</p>
    <p>The dispute regarding the “Sell USD Trade” with reference No “${
      tradeData.id
    }” has been resolved.</p>
    <p>Please see the final details of the transaction below:</p>
    <p>
      Deposit Amount (Local Currency): <strong>${
        tradeData.fiat_currency_code
      } ${tradeData.fiat}</strong><br />
      Deposit Amount (USD): <strong>${tradeData.crypto_amount}</strong><br />
      Depositor Name: <strong>${tradeData.buyer_name}</strong>
    </p>
    <p>The resolution has been applied based on the information provided by both parties. The transaction status will be updated accordingly.</p>
    <p>If you have further questions, please feel free to reach out to our support team.</p>
    <p>Thank you for using BananaCrystal, and we appreciate your cooperation.</p>
    <p>Regards,<br />BananaCrystal Team</p>
  `;
  } catch (error) {
    console.log(error);
    message = "";
  }

  return message;
};
