import React from "react";

const DelistOffersModal = ({ viewType, closeModal}) => {

  const handleReload = () => {
    window.location.reload();
  }

  return (
    <div
      className="modal-delistoffers-alert-cont"
      tabIndex="-1"
      aria-hidden="true"
      aria-labelledby="delistOffers"
    >
      <div>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          onClick={closeModal}
        >
          <span aria-hidden="true">&times;</span>
        </button>

        <h5 className="delistoffer-modal-title">
          <i className="fas fa-check-circle"></i>
          <span>Offer Successfully Delisted!</span>
        </h5>
        {viewType === "default" && (
          <div className="delistoffers-modal-notice" data-controller="clipboard">
            This offer can no longer be enlisted or accessed
          </div>
        )}
        {viewType === "listUserOffers" && (
          <div className="delistoffers-modal-notice" data-controller="clipboard">
            This offer can no longer be enlisted or accessed
          </div>
        )}
        <div className="delistoffer-modal-buttons-section">
          <button
            type="button"
            className="delistoffer-modal-tradeaction-proceedbtn"
            onClick={handleReload}
          >
            Okay
          </button>
        </div>
      </div>
    </div>
  )
}

export default DelistOffersModal;
