import React, { useState, useEffect } from "react";
import { getRequest } from "../../../utils/httpRequest";
import WalletCard from "./WalletCard";
import DebitCard from "./DebitCard";
import "./index.scss";

const MainWalletCard = ({
  walletId,
  current_user,
  debitCardId,
  canActivateCard,
}) => {
  const [walletData, setWalletData] = useState(null);

  const fetchWalletData = async () => {
    try {
      const data = await getRequest("/api/v1/dashboard/accounts");
      setWalletData(data);
    } catch (error) {
      console.error("Error fetching wallet data:", error);
    }
  };

  useEffect(() => {
    fetchWalletData();
  }, []);

  return (
    <div className="card-wallets">
      <WalletCard
        walletData={walletData?.wallet_balances?.account_balance}
        cardBg="bg-white"
        footerBg="bg-white"
        footerLinks={{
          "Add Funds": "/add_funds",
          "Send Payments": "/send_usd#SendPaymentsModal",
          "View All Assests": walletId,
        }}
        refreshWalletData={fetchWalletData}
        className=""
      />
      <WalletCard
        walletData={walletData?.wallet_balances?.p2p_escrow_balance}
        cardBg="bg-orange"
        footerBg="bg-white"
        footerLinks={{
          "Create Offer": "/p2p_trades/offers/new?offer_type=buy",
          "Trade USD": "/p2p_trades/offers/search?offer_type=buy",
          "View All Trades": "/p2p_trades/trades",
        }}
        refreshWalletData={fetchWalletData}
        className=""
      />
      <DebitCard
        currentUser={current_user}
        cardId={debitCardId}
        canActivateCard={canActivateCard}
      />
      <WalletCard
        walletData={walletData?.wallet_balances?.crypto_asset_balance}
        cardBg="bg-purple"
        footerBg="bg-orange"
        footerLinks={{
          "Send Token": "/crypto_transfers/send_crypto/new",
          "Receive Token": `${walletId}/wallet_addresses`,
          "See More": walletId,
        }}
        refreshWalletData={fetchWalletData}
        className="kai-os-hide"
      />
    </div>
  );
};

export default MainWalletCard;
