//= require vendor/clipboardjs/clipboard

(function() {

  function initClipboard() {
    $(document).ready(function() {
      $('[data-toggle="tooltip"]').tooltip();
      
      $(".copy-btn").on("click", function (event) {
        event.stopPropagation();
        var $btn = $(this);
        $btn.attr("data-original-title", "Copied!").tooltip("show");
    
        setTimeout(function () {
          $btn.attr("data-original-title", "Copy to clipboard").tooltip("hide");
        }, 1000);
      });
    });
  }

  KisoThemes.hookOnPageLoad( function() {
    KisoThemes.jsLibIsActive('clipboard') && initClipboard.call(this)
  })

  

})()
