import React from "react";
import { ReactSVG } from "react-svg";
import Preview from "./Preview";

const CryptoForm = ({
  selectedCurrencyImgSrc,
  selectedCurrency,
  validationErrors,
  exchangeRate,
  selectedCurrencyBalance,
  submitTransfer,
  transferId,
  sourceAmount,
  destinationAmount,
  error,
  invalidTransfer,
  submitting,
  previewing,
  handleSourceAmountChange,
  createQuote,
  createSwap,
  refreshPage
}) => (
  <form className="transfer-form pl-2 pr-2" id="new_crypto_transfer">
    <div className="form-inputs">
      <h4>
        <i className="ti-arrow-right "></i> Convert {selectedCurrency} to USDT
      </h4>
      <hr />

      <div className="row required">
        <div className="col-md-12">
          <label className="form-label">
            Amount
            <a
              data-toggle="tooltip"
              className="btn btn-link"
              data-original-title="Amount that will be converted from your Bitcoin wallet, inclusive of fees"
              data-tip="Amount that will be converted from your Bitcoin wallet, inclusive of fees"
            >
              <i className="fa fa-info-circle"></i>
            </a>
          </label>
          <div className="input-group ml-2 pr-3">
            <div className="input-group-prepend">
              <span
                className="form-control font-2_25 input-group-text"
                style={{
                  color: "#4c3f84",
                  focus: { color: "#414da7" }
                }}
              >
                {selectedCurrency}
              </span>
            </div>

            <input
              step="any"
              autoFocus="autofocus"
              style={{
                maxWidth: "350px",
                focus: { color: "#a1a4bf" }
              }}
              placeholder="0.00"
              className={`form-control ml-0 font-2_25 ${
                validationErrors?.sourceAmountError ? "is-invalid" : ""
              }`}
              required="required"
              type="number"
              value={sourceAmount}
              onChange={e => handleSourceAmountChange(e)}
              id="source_amount"
              disabled={previewing || submitting}
            />
          </div>
          <div className="">
            {error && (
              <span className="error text-danger">
                <p>
                  <i className="fa fa-exclamation-circle"></i> {error}
                </p>
              </span>
            )}
            {validationErrors?.sourceAmountError && (
              <span className="error text-danger">
                <p>
                  <i className="fa fa-exclamation-circle"></i>{" "}
                  {validationErrors.sourceAmountError}
                </p>
              </span>
            )}
          </div>
          <div className="font-0_8 text-muted mt-2">
            <span className="text-muted">Max: </span>
            {selectedCurrencyBalance} {selectedCurrency}
          </div>
          {exchangeRate ? (
            <div className="font-0_85 text-muted mt-4">
              1 {selectedCurrency} = ${" "}
              {Number.parseFloat(exchangeRate).toFixed(2)}
            </div>
          ) : (
            <div>Loading...</div>
          )}
        </div>
      </div>
    </div>

    <div className="form-preview pt-3">
      {previewing && (
        <Preview
          sourceCurrency={selectedCurrency}
          fees={0.0}
          amount={sourceAmount}
          destinationCurrency="USDT"
          destinationAmount={destinationAmount}
        />
      )}
      <hr></hr>

      <div className="form-actions">
        {submitTransfer ? (
          <div className="m-auto">
            <div className="col-md-6 float-md-right">
              <button
                name="button"
                type="submit"
                className="btn btn-primary pull-right ml-4 mb-4"
                onClick={e => createSwap(e)}
              >
                {submitting ? (
                  <>
                    <i className="fa fa-circle-o-notch fa-spin"></i>
                    <span style={{ paddingLeft: "5px" }}>Submitting</span>
                  </>
                ) : (
                  <span>Convert</span>
                )}
              </button>
            </div>
            <div className="col-md-6 float-md-left">
              <button
                name="cancel"
                type="submit"
                className="btn pull-left ml-4 mb-4"
                onClick={e => refreshPage(e)}
                disabled={invalidTransfer}
              >
                Cancel
              </button>
            </div>
          </div>
        ) : (
          <div className="m-auto">
            <div className="float-md-right">
              <button
                name="button"
                type="submit"
                className="btn btn-primary pull-right mb-4"
                onClick={e => createQuote(e)}
                disabled={invalidTransfer || submitting}
              >
                {submitting ? (
                  <>
                    <i className="fa fa-circle-o-notch fa-spin"></i>
                    <span style={{ paddingLeft: "5px" }}>
                      Please Wait...Loading
                    </span>
                  </>
                ) : (
                  <span>Preview Convert</span>
                )}
              </button>
            </div>
            <div className="col-md-6 float-md-left">
              <button
                name="start_again"
                type="submit"
                className="btn pull-left mb-4"
                onClick={e => refreshPage(e)}
              >
                Start Again
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  </form>
);

export default CryptoForm;
