import React, { Component } from "react";
import "./index.scss";
import NotificationPopup from "./NotificationPopup";

class Notification extends Component {
  constructor(props) {
    super(props);

    this.state = {
      notificationList: props.notificationList,
      notificationCount: props.notificationCount
    };
  }

  render() {
    return (
      <div className="float-right b-1">
        <NotificationPopup
          notificationList={this.state.notificationList}
          notificationCount={this.state.notificationCount}
        />
      </div>
    );
  }
}

export default Notification;
