import React, { useEffect } from "react";

const Body = ({ transfers, removeTransfer, storeId }) => {
  useEffect(() => {
    $('[data-toggle="tooltip"]').tooltip();
  });

  const getAmount = transfer => {
    if (transfer.is_convert) {
      return (
        <>
          {transfer.gross_amount} {transfer.source_currency}{" "}
          <i className="fa fa-arrow-right" /> {transfer.net_amount}{" "}
          {transfer.destination_currency == "USDT"
            ? "USD"
            : transfer.destination_currency}
        </>
      );
    } else if (transfer.is_store_receiving_fees) {
      return (
        <>
          {transfer.store_receiving_fee_amount}{" "}
          {transfer.destination_currency == "USDT"
            ? "USD"
            : transfer.destination_currency}
        </>
      );
    }

    return `${transfer.net_amount} ${
      transfer.destination_currency === "USDT"
        ? "USD"
        : transfer.destination_currency
    }`;
  };

  const getDescription = transfer => {
    const {
      source_currency,
      destination_currency,
      source_wallet_id,
      destination_wallet_id,
      default_crypto_wallet_id,
      is_destination_user_wallet,
      is_destination_store_wallet,
      destination_wallet_user_name,
      destination_wallet_user_email,
      destination_wallet_store_name,
      destination_wallet_store_email,
      destination_wallet_store_address,
      blockchain_tx,
      blockchain_address,
      user_email,
      user_name
    } = transfer;

    if (source_currency !== destination_currency) return <></>;

    if (is_destination_store_wallet) {
      return (
        <>
          {destination_wallet_store_name}
          <br /> {destination_wallet_store_address}
          <br /> {destination_wallet_store_email}
        </>
      );
    } else if (
      !source_wallet_id ||
      destination_wallet_id === default_crypto_wallet_id
    ) {
      return source_wallet_id ? (
        <>
          {user_name} <br />
          {user_email}
        </>
      ) : (
        <>
          External Wallet
          <br />({source_currency}){blockchain_tx}
        </>
      );
    } else if (is_destination_user_wallet) {
      return (
        <>
          {destination_wallet_user_name}
          <br /> {destination_wallet_user_email}
        </>
      );
    } else {
      return (
        <>
          External Wallet
          <br /> ({source_currency} {blockchain_address})
        </>
      );
    }
  };

  const getDescriptionMeta = transfer => {
    if (transfer.completed_at) {
      return (
        <div className="font-0_85">Completed: {transfer.completed_at}</div>
      );
    }

    return <div className="font-0_85">Created: {transfer.created_at}</div>;
  };

  const getExpiredMeta = transfer => {
    if (transfer.expires_at && transfer.is_expired) {
      return <div className="font-0_85">Expired: {transfer.expires_at}</div>;
    }

    return <></>;
  };

  const getIncompleteTransferStatus = transfer => {
    const {
      is_complete,
      is_confirmed,
      is_unconfirmed,
      is_failed,
      is_expired
    } = transfer;

    if (
      !is_complete &&
      (!is_confirmed || is_unconfirmed) &&
      !is_failed &&
      !is_expired
    ) {
      return (
        <a
          href="#"
          data-toggle="tooltip"
          title="This transaction is pending as it needs your email confirmation.
              Please click on the confirmation link sent to your email"
        >
          <span>
            <i className="fa fa-info-circle"></i>
          </span>
        </a>
      );
    }

    return <></>;
  };

  const getStatus = transfer => {
    if (transfer.is_expired) {
      return (
        <a href={`/crypto_transfers/${transfer.id}`} className="unlink">
          Expired
        </a>
      );
    } else {
      return (
        <>
          {transfer.external_status} {getIncompleteTransferStatus(transfer)}
        </>
      );
    }

    return <></>;
  };

  const getResendConfirmationLink = transfer => {
    const {
      is_complete,
      is_confirmed,
      is_unconfirmed,
      is_failed,
      is_expired,
      id
    } = transfer;

    if (
      !is_complete &&
      (!is_confirmed || is_unconfirmed) &&
      !is_failed &&
      !is_expired
    ) {
      return (
        <a
          className={`btn btn-link ${transfer.active_class_by_status}`}
          title="Resend Confirmation"
          data-confirm="A confirmation link will be sent to your email"
          data-remote="true"
          href={`/crypto_transfers/${id}/resend_confirmation`}
        >
          <i className="fa fa-envelope "></i>
        </a>
      );
    }

    return <></>;
  };

  const getRemoveTransactionLink = transfer => {
    const { is_kept, is_complete, is_pending, id } = transfer;

    if (is_kept && !is_complete && !is_pending) {
      return (
        <a
          className="unlink"
          title="Remove transaction"
          rel="nofollow"
          onClick={e => removeTransfer(e, transfer.id)}
        >
          <i className="fa fa-trash "></i>
        </a>
      );
    }

    return <></>;
  };

  return (
    <tbody>
      {transfers.map((transfer, i) => (
        <tr key={transfer.id}>
          <td className="actions expand">
            <a
              className="btn btn-link"
              data-toggle="collapse"
              href={`#collapseDescription${i}`}
              role="button"
              aria-expanded="false"
              aria-controls={`collapseDescription${i}`}
            >
              <i className="fa fa-collapsible" aria-hidden="true"></i>
            </a>
          </td>
          <td>{transfer.date_created}</td>
          <td>{getAmount(transfer)}</td>
          <td>{transfer.transfer_type}</td>
          <td className="expandable-description">
            {getDescription(transfer)}
            <div className="collapse" id={`collapseDescription${i}`}>
              {getDescriptionMeta(transfer)}
              {getExpiredMeta(transfer)}
            </div>
          </td>
          <td className={transfer.active_class_by_status}>
            {getStatus(transfer)}
          </td>

          <td>
            {transfer.is_received && (
              <a
                className="btn-link btn-tiny"
                target="_blank"
                href={`/stores/${storeId}/send_usd?amount=${transfer.destination_amount}&email=${transfer.source_wallet_user_email}&ref=${transfer.id}`}
              >
                Refund
              </a>
            )}
          </td>
          <td>
            <a
              className="btn-link btn-tiny"
              href={`/crypto_transfers/${transfer.id}?store=${storeId}`}
            >
              View
            </a>
          </td>
          <td className="actions trash">
            <span className={transfer.active_class_by_status}>
              {getResendConfirmationLink(transfer)}
            </span>
            <span>{getRemoveTransactionLink(transfer)}</span>
          </td>
        </tr>
      ))}
      {transfers.length === 0 && (
        <tr>
          <td colSpan={9} className="text-center">
            Currently you have no activity or clear your filters.
          </td>
        </tr>
      )}
    </tbody>
  );
};

export default Body;
