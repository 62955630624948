import React, { Component } from "react";
import SumsubWebSdk from "@sumsub/websdk-react";
import { postRequest } from "../../utils/httpRequest";
import { formErrors } from "../../utils/formErrors";
import { formattedApiErrors } from "./helper";
import "./style.scss";

class Verifications extends Component {
  constructor(props) {
    super(props);

    this.state = {
      apiErrors: {},
      submitting: false,
      accessToken: props.accessToken,
    };
  }

  submit = (values) => {
    this.setState({ submitting: true });

    const { accessToken } = values;

    const data = {
      verification: {
        access_token: AcessToken,
      },
    };

    const crsfToken = $('meta[name="csrf-token"]').attr("content");

    postRequest(`/verifications.json`, data, {
      "X-CSRF-Token": crsfToken,
    })
      .then((response) => {
        window.location = "/verifications";
      })
      .catch((error) => {
        const errors = formErrors(error.data.errors);

        this.setState({
          apiErrors: formattedApiErrors(errors),
        });

        this.setState({ submitting: false });
      });
  };

  accessTokenExpirationHandler() {
    const crsfToken = $('meta[name="csrf-token"]').attr("content");

    postRequest(`/verifications/access_token.json`, data, {
      "X-CSRF-Token": crsfToken,
    })
      .then((response) => {
        this.setState({ accessToken: response.access_token });
      })
      .catch((error) => {
        const errors = formErrors(error.data.errors);

        this.setState({
          apiErrors: formattedApiErrors(errors),
        });

        window.location = "/verifications";
      });
  }

  render() {
    const { step, apiErrors, submitting, accessToken } = this.state;

    return (
      <div>
        <SumsubWebSdk
          config={{theme: 'light'}}
          accessToken={accessToken}
          expirationHandler={this.accessTokenExpirationHandler}
        />
      </div>
    );
  }
}

export default Verifications;