import React, { Component } from "react";
import Modal from "react-modal";

const customStyles = {
  content: {
    width: "30%",
    margin: "0 auto",
    top: "10%",
    borderRadius: "12px",
    height: "65%"
  }
};

class ConfirmTradeModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tokens: [],
      searchTerm: "",
      currentTokens: [],
      showMoreTokensButton: true
    };
  }

  onConfirm = e => {
    e.preventDefault();
    const { sourceAmount, sourceTokenBalance } = this.props;

    if (Number(sourceAmount) > Number(sourceTokenBalance)) {
      this.props.handleCloseConfirmModal(e);
      this.props.setInsufficientBalance(true);
    } else {
      this.props.trade(e);
    }
  };

  render() {
    const {
      sourceAmount,
      destinationAmount,
      sourceToken,
      destinationToken,
      rate,
      openConfirmModal,
      handleCloseConfirmModal,
      trade
    } = this.props;

    return (
      <Modal isOpen={openConfirmModal} style={customStyles} ariaHideApp={false}>
        <div className="token-modal confirm-trade-modal">
          <div className="header">
            <a href="#" onClick={e => handleCloseConfirmModal(e)}>
              <p>
                <i className="fa fa-times"></i>
              </p>
            </a>
          </div>
          <div>
            <div className="exchange-icon">
              <i className="fa fa-exchange"></i>
            </div>
            <h5>Confirm Token Swap</h5>
            <div className="trade-details">
              <div className="details">
                <p className="title">Sell</p>
                <p className="value">{`${sourceAmount} ${sourceToken.symbol}`}</p>
              </div>
              <div className="details">
                <p className="title">Receive</p>
                <p className="value">{`${destinationAmount?.toFixed(6)} ${
                  destinationToken.symbol
                }`}</p>
              </div>
            </div>
            <p className="info title">
              Output is estimated. If the price changes by more than 1.0%, your
              transaction will revert.
            </p>
            <div className="trade-details rate-details">
              <div className="details">
                <p className="title">Rate</p>
                <p className="value">{rate}</p>
              </div>
            </div>
            <button
              type="submit"
              className="btn btn-primary btn-lg submit"
              onClick={this.onConfirm}
            >
              Confirm
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}

export default ConfirmTradeModal;
