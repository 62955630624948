import React from "react";
import BankAccountDetails from "./BankAccountDetails";
import DisputeInfo from "./DisputeInfo";
import SellerBankPaymentMethodDetails from "./SellerBankPaymentMethodDetails";
import SellerOtherPaymentMethodDetails from "./SellerOtherPaymentMethodDetails";
import TermsInfo from "./TermsInfo";
import TradeChat from "../../TradeChat";
import TradeInfo from "./TradeInfo";

const PaymentCancelled = ({
  tradeData,
  isSystemCancelled,
  isDisputed,
  showChat,
  isRotating,
  handleRefresh,
  setShowChat,
  isBuyer,
  offer,
  isOfferUser,
  isReopenClicked,
  reopenTrade,
  tradeBankAccount,
  sellerRate,
  isSeller,
  referenceMessage,
  checkChatDisabled,
  sellerPaymentDetail,
  chatActionCableUrl,
  hideChat,
  disputeData,
  currentUserId,
}) => {
  return (
    <div>
      <div>
          <div className="tradedetails-actions-steps-cont">
            {/* Progress Bar */}
            <div className="tradedetails-actions-progressbar">
              <div className="tradedetails-actions-circle"></div>
              <div className="tradedetails-actions-line"></div>
              <div className="tradedetails-actions-circle"></div>
              <div className="tradedetails-actions-line"></div>
              <div className="tradedetails-actions-circle"></div>
            </div>
            <div className="tradedetails-action-stepsection">
              <div className="tradedetails-actions-step">
                <div className="tradedetails-cancelled-title">
                  <p>
                    You can always start a new trade with{" "}
                      <a   href={`/profile/${isBuyer ? tradeData.seller_username : tradeData.buyer_username}`} className="cancelled-name-page-link">
                        {isBuyer
                          ? tradeData.seller_username
                          : tradeData.buyer_username}
                      </a>{" "}
                      or find a new one from our list of{" "}
                      <a href="/p2p_trades/offers/search?offer_type=buy" className="cancelled-name-page-link">
                        {offer?.paymentMethod?.name}{" "}
                      </a>
                      offers.
                      <br />
                      <i className="fas fa-exclamation-triangle cancelled-exclamation-triangle"></i>
                      {" "}Did you {isBuyer ? "make" : "receive"} payment before this trade {isSystemCancelled ? "expired" : "got cancelled"}?
                      Please contact support. 
                      <br/>
                      <button
                      type="button"
                      className="tradedetails-reopen-trade-btn"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open("https://support.bananacrystal.com/hc/en-us/requests/new", "_blank");
                      }}
                      disabled={isReopenClicked}
                    >
                      {isReopenClicked ? "Please wait" : "CONTACT SUPPORT"}
                    </button>
                  </p>
                </div>
              </div>
              {/* Instructions from offer user */}
              <div className="tradedetails-actions-step">
                <TradeInfo
                  tradeId={tradeData?.id}
                  tradeCreatedAt={tradeData?.created_at}
                  sellerRate={sellerRate}
                  offerFiatCurrencyCode={offer?.fiatCurrency?.code}
                  customOfferLabel={offer?.customOfferLabel}
                />
    
                {/* Dispute Info */}
                <DisputeInfo disputeData={disputeData} isDisputed={isDisputed} />
                <TermsInfo
                  instructionsFrom={
                    isBuyer ? tradeData.seller_username : tradeData.buyer_username
                  }
                  instructionsFromName={
                    isBuyer
                      ? tradeData.seller_profile_name
                      : tradeData.buyer_username
                  }
                  customOfferLabel={offer?.customOfferLabel}
                  offerTags={offer?.tags}
                  offerTerms={offer?.offerTerms}
                />
              </div>
  
              {/* Transaction Instructions - Bank account info section */}
              <div className="tradedetails-actions-step">
                {offer?.paymentMethod?.name === "Bank Transfer" &&
                  offer?.offerTypeField === "sell" && (
                    <BankAccountDetails
                      isBuyer={isBuyer}
                      instructionsFrom={
                        isBuyer
                          ? tradeData.seller_username
                          : tradeData.buyer_username
                      }
                      sellerUsername={tradeData?.seller_username}
                      tradeBankAccount={tradeBankAccount}
                      transferTotal={`${tradeData?.fiat} ${tradeData?.fiat_currency_code}`}
                      referenceMessage={referenceMessage}
                      buyerName={tradeData?.buyer_name}
                    />
                  )}
    
                {/* Buy Offer and payment methood -> !Bank Transfer */}
                {offer?.paymentMethod?.name !== "Bank Transfer" &&
                  offer?.offerTypeField === "buy" && (
                    <SellerOtherPaymentMethodDetails
                      isSeller={isSeller}
                      paymentMethod={tradeData?.payment_method_name}
                      sellerPaymentDetail={sellerPaymentDetail}
                      instructionsFrom={tradeData?.seller_username}
                      transferTotal={`${tradeData?.fiat} ${tradeData?.fiat_currency_code}`}
                      referenceMessage={referenceMessage}
                      sellerUsername={tradeData?.seller_username}
                      buyerUsername={tradeData?.buyer_username}
                      buyerName={tradeData?.buyer_name}
                    />
                  )}

                {/* Sell Offer and payment methood -> !Bank Transfer */}
                {offer?.paymentMethod?.name !== "Bank Transfer" &&
                  offer?.offerTypeField === "sell" && (
                    <SellerOtherPaymentMethodDetails
                      isSeller={isSeller}
                      paymentMethod={tradeData?.payment_method_name}
                      sellerPaymentDetail={sellerPaymentDetail}
                      instructionsFrom={tradeData?.seller_username}
                      transferTotal={`${tradeData?.fiat} ${tradeData?.fiat_currency_code}`}
                      referenceMessage={referenceMessage}
                      sellerUsername={tradeData?.seller_username}
                      buyerUsername={tradeData?.buyer_username}
                      buyerName={tradeData?.buyer_name}
                    />
                  )}
    
                {/* Buy Offer and payment methood -> Bank Transfer */}
                {offer?.paymentMethod?.name === "Bank Transfer" &&
                  offer?.offerTypeField === "buy" && (
                    <SellerBankPaymentMethodDetails
                      isSeller={isSeller}
                      paymentMethod={tradeData?.payment_method_name}
                      sellerPaymentDetail={sellerPaymentDetail}
                      instructionsFrom={tradeData?.seller_username}
                      buyerUsername={tradeData?.buyer_username}
                      transferTotal={`${tradeData?.fiat} ${tradeData?.fiat_currency_code}`}
                      referenceMessage={referenceMessage}
                      buyerName={tradeData?.buyer_name}
                      sellerUsername={tradeData?.seller_username}
                    />
                  )}
              </div>
            </div>
          </div>
       </div>
    </div>
  );
};

export default PaymentCancelled;
