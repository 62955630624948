import React from "react";

const TermsInfo = ({
  instructionsFrom,
  instructionsFromName,
  customOfferLabel,
  offerTags,
  offerTerms,
}) => {
  return (
    <div>
      <div>
        <div>
          <h4 className="tradedetails-tradeinfo-title">
            <i className="fa fa-file-contract"></i>
            Terms
          </h4>
          <p className="tradedetails-tradeinfo-terms-textvalues">{offerTerms}</p>
        </div>
        <div className="tradedetails-badge-cont ">
          {offerTags != "" && offerTags?.length > 0 ? (
            offerTags?.map((tag) => (
              <div
                key={tag.label}
                className={`tradedetails-badge`}
              >
                <i className="fas fa-tags"></i>{" "}
                <span>{tag.label}</span>
              </div>
            ))
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default TermsInfo;
