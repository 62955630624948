import React, { Component } from "react";
import _ from "underscore";
import { getRequest } from "../../utils/httpRequest";
import TokenModal from "./TokenModal";
import ConfirmTradeModal from "./ConfirmTradeModal";
import tokens from "./tokens.json";
import ConfirmationModal from "./ConfirmationModal";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

class ConnectMetamask extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null,
      openModal: false,
      loadingTokens: true,
      allTokens: [],
      sourceToken: props.sourceToken,
      destinationToken: props.destinationToken,
      tokenChange: "sourceToken",
      insufficientBalance: false
    };
  }

  componentDidMount() {
    getRequest(
      "https://raw.githubusercontent.com/Velua/eth-tokens-registry/master/tokens.json"
    ).then(response => {
      const allTokens = _.uniq(tokens.concat(response), "symbol");

      this.setState({
        allTokens,
        loadingTokens: false
      });
    });
  }

  handleShowSourceTokenModal = e => {
    e.preventDefault();
    this.setState({ openModal: true, tokenChange: "sourceToken" });
  };

  handleShowDestinationTokenModal = e => {
    e.preventDefault();
    this.setState({ openModal: true, tokenChange: "destinationToken" });
  };

  handleCloseModal = e => {
    e.preventDefault();
    this.setState({ openModal: false });
  };

  onSelectToken = (e, token) => {
    const { tokenChange } = this.state;

    const { sourceToken, destinationToken } = this.props;
    const previousSourceToken = sourceToken;
    const previousDestinationToken = destinationToken;
    e.preventDefault();

    if (
      (tokenChange === "sourceToken" &&
        destinationToken.symbol === token.symbol) ||
      (tokenChange === "destinationToken" &&
        sourceToken.symbol === token.symbol)
    ) {
      this.props.exchangeToken();
    } else {
      this.props.setToken(this.state.tokenChange, token);
    }
  };

  renderButton = () => {
    const {
      isMetaMaskInstalled,
      isMetaMaskConnected,
      loginRequired,
      destinationAmount,
      tradeInProgress
    } = this.props;

    if (loginRequired) {
      return (
        <a href="/users/sign_in" className="btn btn-primary btn-lg submit">
          Login or Register to Continue
        </a>
      );
    }

    if (!isMetaMaskInstalled) {
      if (isMobile) {
        return (
          <div>
            <a href="https://metamask.io/" className="btn btn-primary btn-lg submit">
            Install MetaMask
            </a>
            <i className="fa fa-question-circle"></i> <strong>Using Metamask on Your Mobile Phone</strong>
            <br/>
            Swap Crypto using the <a href="https://metamask.io/">MetaMask Mobile App</a>.
            <ol>
              <li>Install the MetaMask Mobile App at <a href="https://metamask.io/">MetaMask.io</a>.</li>
              <li>Login to the Metamask Mobile app by scanning your QR code or using your recovery phrase, see how to <a href="https://metamask.zendesk.com/hc/en-us/articles/360032378452-How-to-Sync-Mobile-with-MetaMask-Extension">here</a>.</li>
              <li>Go to Menu &gt; Browser (within the Mobile app)</li>
              <li>Browse to BananaCrystal &gt;<a href="/advanced_trade">Trade/Swap Crypto</a> and click on Connect Wallet.</li>
            </ol>
          </div>
        );
      } else {
        return (
          <button
            type="submit"
            className="btn btn-primary btn-lg submit"
            onClick={e => this.props.onClickInstallMetamask(e)}
          >
            Install MetaMask
          </button>
        );
      }

    } else if (!isMetaMaskConnected) {
      return (
        <button
          type="submit"
          className="btn btn-primary btn-lg submit"
          onClick={e => this.props.onClickConnectMetamask(e)}
        >
          Connect Wallet
        </button>
      );
    } else {
      return (
        <button
          type="submit"
          className="btn btn-primary btn-lg submit"
          onClick={e => this.props.handleOpenConfirmTradeModal(e)}
          disabled={
            !destinationAmount ||
            tradeInProgress ||
            this.state.insufficientBalance
          }
        >
          Swap
        </button>
      );
    }
  };

  handleSourceAmountChange = e => {
    const { sourceTokenBalance } = this.props;

    const amount = e.target.value;
    this.props.handleSourceAmountChange(amount);

    if (Number(amount) > Number(sourceTokenBalance)) {
      this.setInsufficientBalance(true);
    } else {
      this.setInsufficientBalance(false);
    }
  };

  setInsufficientBalance = value => {
    this.setState({ insufficientBalance: value });
  };

  formatBalance = amount =>
    amount.length > 8 ? Number(amount).toFixed(6) : amount;

  render() {
    const {
      selectedOption,
      openModal,
      allTokens,
      tokenChange,
      loadingTokens,
      openConfirmModal,
      insufficientBalance
    } = this.state;

    const {
      sourceAmount,
      destinationAmount,
      sourceToken,
      destinationToken,
      rate,
      isMetaMaskConnected,
      trade,
      metamaskAccount,
      isMainnet,
      sourceTokenBalance,
      destinationTokenBalance,
      openConfirmTradeModal,
      showConfirmationModal,
      tradeFromAllTokens
    } = this.props;

    const header = isMetaMaskConnected
      ? "Enter your Desired Amount"
      : "Connect Your Wallet";

    return (
      <div className="trade-card">
        <h3>{header}</h3>
        <div className="card">
          <div className="card-header">
            <header className="text-center">
              <div className="profile">
                <img src="https://media.bananacrystal.com/wp-content/uploads/2020/12/30060859/cropped-bananacrystal-logo-400x100-1-1.png" />
              </div>
            </header>
          </div>
          <div className="card-body">
            <form className="trade-form">
              <div>
                <label>From</label>
                <span className="balance">
                  Balance: {this.formatBalance(sourceTokenBalance)}
                </span>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter an Amount"
                    value={sourceAmount || ""}
                    onChange={_.debounce(this.handleSourceAmountChange, 500, {
                      leading: true
                    })}
                  />
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      {tradeFromAllTokens ? (
                        <button onClick={this.handleShowSourceTokenModal}>
                          <span>{sourceToken.symbol}</span>
                          <i className="fa fa-sort-down"></i>
                        </button>
                      ) : (
                        <div className="source-token-text">
                          {sourceToken.symbol}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {insufficientBalance && (
                <div className="alert-error-light">
                  <span>
                    <div>Your current balance for this token is insufficient, connect your wallet or select another amount or token</div>
                  </span>
                </div>
              )}
              <div className="divider">
                <svg
                  data-v-27cd901d=""
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="exchange-alt"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    data-v-27cd901d=""
                    fill="currentColor"
                    d="M0 168v-16c0-13.255 10.745-24 24-24h360V80c0-21.367 25.899-32.042 40.971-16.971l80 80c9.372 9.373 9.372 24.569 0 33.941l-80 80C409.956 271.982 384 261.456 384 240v-48H24c-13.255 0-24-10.745-24-24zm488 152H128v-48c0-21.314-25.862-32.08-40.971-16.971l-80 80c-9.372 9.373-9.372 24.569 0 33.941l80 80C102.057 463.997 128 453.437 128 432v-48h360c13.255 0 24-10.745 24-24v-16c0-13.255-10.745-24-24-24z"
                  ></path>
                </svg>
              </div>
              <div>
                <label>To (Estimated)</label>
                <span className="balance">
                  Balance: {this.formatBalance(destinationTokenBalance)}
                </span>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter an Amount"
                    value={sourceAmount ? destinationAmount : ""}
                    disabled={true}
                  />
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <button onClick={this.handleShowDestinationTokenModal}>
                        <span>{destinationToken.symbol}</span>
                        <i className="fa fa-sort-down"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pricing">
                <div className="price">
                  <p className="title">Rate</p>
                  <p className="value">{rate}</p>
                </div>
              </div>
              {this.renderButton()}
            </form>
          </div>
        </div>
        {!loadingTokens && (
          <TokenModal
            openModal={openModal}
            handleCloseModal={this.handleCloseModal}
            allTokens={allTokens}
            onSelectToken={this.onSelectToken}
            getTokenBalance={this.props.getTokenBalance}
            metamaskAccount={metamaskAccount}
            isMainnet={isMainnet}
          />
        )}
        <ConfirmTradeModal
          openConfirmModal={openConfirmTradeModal}
          handleCloseConfirmModal={this.props.handleCloseConfirmTradeModal}
          sourceToken={sourceToken}
          destinationToken={destinationToken}
          setToken={this.setToken}
          rate={rate}
          sourceAmount={sourceAmount}
          destinationAmount={destinationAmount}
          trade={trade}
          sourceTokenBalance={sourceTokenBalance}
          setInsufficientBalance={this.setInsufficientBalance}
        />
        <ConfirmationModal
          showConfirmationModal={showConfirmationModal}
          handleCloseConfirmModal={this.props.handleCloseConfirmModal}
        />
      </div>
    );
  }
}

export default ConnectMetamask;
