import React, { useState, useRef } from "react";
import "../../../styles/trademodals.scss";

const AssetsSafetyCheckModal = ({ tradeData, onConfirm, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [checkboxes, setCheckboxes] = useState({
    check1: false,
    check2: false,
    check3: false,
  });

  const errorRef = useRef(null);

  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target;
    setCheckboxes({ ...checkboxes, [id]: checked });
  };

  const handleConfirm = (e) => {
    e.preventDefault();
    if (Object.values(checkboxes).every((checked) => checked)) {
      if (errorRef.current) {
        errorRef.current.style.display = "none";
      }
      setIsLoading(true);
      onConfirm();
    } else {
      if (errorRef.current) {
        errorRef.current.style.display = "block";
      }
    }
  };

  return (
    <div
      className="modal-status-cont"
      tabIndex={-1}
      aria-hidden="true"
      id="releaseConfirmModal"
      aria-labelledby="cancelConfirmModalLabel"
    >
      <div className="modal-status-alert-cont">
        <div>
          <button
            type="button"
            className="close"
            onClick={() => {onClose('releaseConfirmModal')}}
            aria-label="Close"
            disabled={isLoading}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <br/>
        </div>
        <div>
          <p className="modal-title" id="assetsSafetyCheckModalLabel">
            Assets Safety Check
          </p>
          <div className="trademodal-notice">
            <span>
              {" "}
              To avoid loss of assets due to bank chargeback, do not accept
              payments from an unassociated third-party account.
            </span>
          </div>
          {/* Checklist Section */}
          <div className="trademodal-form-section">
            <small id="cancelErrorMessage" ref={errorRef} style={{ display: "none", color: "red" }}>
              Please check all the required boxes.
            </small>
            {/* Checklist 1 */}
            <div className="trademodal-form-check">
              <input
                type="checkbox"
                className="trademodal-form-check-input"
                id="check1"
                checked={checkboxes.check1}
                onChange={handleCheckboxChange}
              />
              <label className="trademodal-form-check-label" htmlFor="check1">
                I have received {tradeData?.fiat}{" "}
                {tradeData?.fiat_currency_code} from the buyer
              </label>
            </div>
            {/* Checklist 2 */}
            <div className="trademodal-form-check">
              <input
                type="checkbox"
                className="trademodal-form-check-input"
                id="check2"
                checked={checkboxes.check2}
                onChange={handleCheckboxChange}
              />
              <label className="trademodal-form-check-label" htmlFor="check2">
                I have checked that the payment is from{" "}
                {tradeData.buyer_username}
              </label>
            </div>
            {/* Checklist 3 */}
            <div className="trademodal-form-check">
              <input
                type="checkbox"
                className="trademodal-form-check-input"
                id="check3"
                checked={checkboxes.check3}
                onChange={handleCheckboxChange}
              />
              <label className="trademodal-form-check-label" htmlFor="check3">
                I agree to release {parseFloat(tradeData?.crypto_amount).toFixed(2)} USD to the buyer
              </label>
            </div>
          </div>
        </div>

        {/* buttons */}
        <div className="modal-buttons-section">
          <div>
            <button
              type="button"
              className="modal-tradeaction-canceltradebtn"
              onClick={() => {onClose('releaseConfirmModal')}}
              disabled={isLoading}
            >
              Cancel
            </button>
          </div>
          <div>
            <button
              type="button"
              disabled={!Object.values(checkboxes).every(Boolean)}
              className="modal-tradeaction-paidbtn"
              onClick={handleConfirm}
            >
              {isLoading ? "Please wait..." : "Confirm"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssetsSafetyCheckModal;
