import React, { useRef, useEffect } from "react";
import "./addfundstransak.scss";

const WithdrawalTransak = () => {
  return (
    <>
      <div className="success-page">
        <div className="success-page__container">
          <h1 className="success-page__title">
            <svg
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 50 50"
              xmlSpace="preserve"
              className="success-icon _withdraw"
            >
              <circle style={{ fill: "#ff9100" }} cx="25" cy="25" r="25" />
              <polyline
                style={{
                  fill: "none",
                  stroke: "#FFFFFF",
                  strokeWidth: "2",
                  strokeLinecap: "round",
                  strokeLinejoin: "round",
                  strokeMiterlimit: "10",
                }}
                points="38,15 22,33 12,25"
              />
            </svg>
            <br />
            Your Digital Dollars (USDT) Withdrawal Request Successfully Submitted!
          </h1>
          <div className="success-page__details">
            <div className="btn-container">
              <button
                className="success-page__cta"
                onClick={() => (window.location.href = "/user/dashboard")}
              >
                Return to Dashboard
              </button>
            </div>
            <p className="success-page__message">
              Your transaction has been succesfully submitted and is currently
              processing. If <strong>successful</strong>, please wait a few minutes for processing to complete and for your
              balance to update. 
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default WithdrawalTransak;
