import React, { Component } from "react";
import Select from "react-select";
import {
  getRequest,
  postRequest
} from "../../../../../../../utils/httpRequest";
import FormModal from "./FormModal";

class OnlineWallet extends Component {
  constructor(props) {
    super(props);

    this.state = {
      walletName: "online-wallets",
      url: "",
      caption: "",
      wallets: [],
      isSubmitting: false,
      isModalOpen: false,
    };
  }

  componentDidMount() {
    this.getOnlineWallets();
    this.setState({ selectedOnlineWallet: this.props.getData('selectedOnlineWallet') });
  }

  updateWallets = newWallet => {
    this.setState(prevState => ({
      wallets: [...prevState.wallets, newWallet],
      selectedOnlineWallet: newWallet,
      isSubmitting: false
    }));
  };

  getOnlineWallets = () => {
    this.setState({ isLoading: true });
    getRequest("/p2p_trades/online_wallets").then(response => {
      const wallets = response.map(wallet => ({
        label: `${wallet.caption}, ${wallet.url}`,
        details: `Name: ${wallet.caption}, url: ${wallet.url}`,
        value: wallet.id
      }));

      this.setState({
        wallets,
        isLoading: false
      });
    });
  };

  handleInputChange = (e, key) => {
    this.setState({ [key]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();

    this.setState({ isSubmitting: true });

    const { selectedPaymentMethod } = this.props;

    const { walletName, url, caption } = this.state;

    const params = {
      url,
      caption,
      wallet_name: walletName
    };

    postRequest("/p2p_trades/online_wallets", params)
      .then(response => {
        this.updateWallets({
          label: `${caption}, ${url}`,
          details: `Name: ${caption}, url: ${url}`,
          value: response.id
        });

        this.props.updateParent("selectedOnlineWallet", {
          label: `${caption}, ${url}`,
          details: `Name: ${caption}, url: ${url}`,
          value: response.id
        });
        this.toggleOpenModal(e);
      })
      .catch(error => {
        this.setState({ isSubmitting: false });
      });
  };

  toggleOpenModal = e => {
    e.preventDefault();

    this.setState({ isModalOpen: !this.state.isModalOpen });
  };

  handleWalletChange = selectedOnlineWallet => {
    this.setState({ selectedOnlineWallet });
    this.props.updateParent("selectedOnlineWallet", selectedOnlineWallet);
  };

  render() {
    const { onSubmitWallet, paymentMethodGroup } = this.props;

    const {
      wallets,
      walletName,
      url,
      caption,
      selectedOnlineWallet,
      isModalOpen,
      isSubmitting
    } = this.state;

    return (
      <>
        {["online-transfers"].includes(paymentMethodGroup) && (
          <div className="online_wallets pt-4">
            <div className="col-md-12 px-0">
              <label><b>Select Your Online Wallet <span style={{ color: "red" }}>*</span></b></label>
              <div className="select_online_wallet pb-2">
                <Select
                  className="currencies-select-container"
                  options={wallets}
                  value={selectedOnlineWallet}
                  onChange={this.handleWalletChange}
                />
              </div>
              <div className="add_online_wallet">
                <br/>
                <label><b> <i class="fa fa-info-circle"></i>{" "}
                Can't find your preferred Online Wallet?</b></label>
                <button
                  type="button"
                  className="createoffer-default-buttons "
                  onClick={this.toggleOpenModal}
                >
                  <i className="fa fa-plus" aria-hidden="true"></i>{" "}
                  Add Online Wallet
                </button>
              </div>
            <div>
          </div>
        </div>
            {isModalOpen && (
              <FormModal
                walletName={walletName}
                url={url}
                caption={caption}
                isSubmitting={isSubmitting}
                handleCloseModal={this.toggleOpenModal}
                handleInputChange={this.handleInputChange}
                onSubmit={this.onSubmit}
              />
            )}
        </div>
        )}
      </>
    );
  }
}

export default OnlineWallet;
