import base58check from "base58check";
import walletAddressValidator from "@swyftx/api-crypto-address-validator/dist/wallet-address-validator.min.js";

export const formattedApiErrors = errors => {
  const apiErrors = {};

  const coinError = errors["coin"];
  const addressError = errors["address"];
  const blockChainError = errors["chain"];
  const walletLabelError = errors["wallet_label"];
  const addressVerifiedError = errors["address_verified"];

  apiErrors.coinError = coinError;
  apiErrors.addressError = addressError;
  apiErrors.blockChainError = blockChainError;
  apiErrors.walletLabelError = walletLabelError;
  apiErrors.addressVerifiedError = addressVerifiedError;

  return apiErrors;
};

export const validateAddress = (selectedBlockchain, address) => {
  const blockchain = ["BTC", "AVAX", "XLM"].includes(selectedBlockchain)
    ? selectedBlockchain
    : "ETH";

  const isValidAddress = validateWalletAddress(blockchain, address);

  return isValidAddress;
};

const validateWalletAddress = (blockchain, address) => {
  let isValidAddress = true;

  if (blockchain && address) {
    isValidAddress =
      blockchain === "SOL"
        ? validateSolanaAddress(address)
        : walletAddressValidator.validate(address, blockchain, "both");
  }

  return isValidAddress;
};

const validateSolanaAddress = address => {
  try {
    base58check.encode(address);
    return true;
  } catch (e) {
    return false;
  }
};
