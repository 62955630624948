import React from "react";
import CardRequest from "./CardRequest";
import "../../../P2PTrades/Offers/index.scss";

const List = ({ card_requests }) => {
  return (
    <div className="container-fluid px-2 px-md-5">
      <div className="create-offer-title" style={{ marginBottom: "20px" }}>
        <h2 className="_page-title font-1_8"></h2>
      </div>
      <div className="card shadow-sm rounded-lg">
        <div className="card-header">
          <h5 className="font-weight-bold">Your Card Request Information <i className="fa fa-address-card-o"></i></h5>
        </div>
        <div className="card-body">
          {card_requests.length > 0 ? (
            <div className="d-flex flex-row flex-wrap">
              {card_requests.map((cardRequest, i) => (
                <CardRequest key={i} cardRequest={cardRequest} />
              ))}
            </div>
          ) : (
            <div>
              You have not requested for a card.{" "}
              <a href="/cards/requests/new">Click on the link to begin.</a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default List;
