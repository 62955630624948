import React, {useState} from "react";

const BankAccountDetails = ({
  isBuyer,
  instructionsFrom,
  sellerUsername,
  tradeBankAccount,
  transferTotal,
  referenceMessage,
  buyerName,
}) => {
  const [copied, setCopied] = useState(null);

  const handleCopy = (text, field) => {
    navigator.clipboard.writeText(text);
    setCopied(field);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <>
      <div>
        <p className="tradedetails-tradeinfo-title">
        <i className="fas fa-info-circle"></i>
        {" "}{isBuyer ? " Seller's " : " My "}
          Transaction Instructions
        </p>
        <div>
          <div>
            <div>
              <div>
                {isBuyer ? (
                  <p className="tradedetails-instructions-from">
                    Use the details below to make the transfer. Make sure you use
                    the exact words in the <b>reference message</b> when sending the
                    transfer. 
                  </p>
                ) : (
                  <p className="tradedetails-instructions-from">
                    Confirm your details below for this transaction. Make sure you
                    look for the exact words in the <b>reference message</b> when looking
                    for the transfer from the buyer.
                  </p>
                )}
                <div className="bank_details">
                  {tradeBankAccount?.bankName && tradeBankAccount?.bankName !== "Other" && (
                      <div className="trade_bank_account tradedetails-tradeinfo-specifics-text">
                        <label className="tradedetails-tradeinfo-payment-textnames">Bank Name:</label>
                        <span className="tradedetails-tradeinfo-payment-textvalues">{tradeBankAccount?.bankName}
                          <button onClick={() => handleCopy(tradeBankAccount?.bankName, "tradeBankAccount?.bankName")} className="tradedetails-copybtn">
                            <i className={`fas fa-${copied === "tradeBankAccount?.bankName" ? "check" : "copy"}`}   title={`${copied ? "Copied" : "Copy"}`}></i>
                          </button>
                        </span>
                      </div>
                    )}
                    {tradeBankAccount?.customBankDetails && (
                      <div className="trade_bank_account tradedetails-tradeinfo-specifics-text">
                        <label className="tradedetails-tradeinfo-payment-textnames">Bank Name:</label>
                        <span className="tradedetails-tradeinfo-payment-textvalues">{tradeBankAccount?.customBankDetails}
                          <button onClick={() => handleCopy(tradeBankAccount?.customBankDetails, "tradeBankAccount?.customBankDetails")} className="tradedetails-copybtn">
                            <i className={`fas fa-${copied === "tradeBankAccount?.customBankDetails" ? "check" : "copy"}`}   title={`${copied ? "Copied" : "Copy"}`}></i>
                          </button>
                        </span>
                      </div>
                    )}
                  {tradeBankAccount?.accountHolderName && (
                      <div className="trade_bank_account tradedetails-tradeinfo-specifics-text">
                        <label className="tradedetails-tradeinfo-payment-textnames">Account Title:</label>
                        <span className="tradedetails-tradeinfo-payment-textvalues">{tradeBankAccount?.accountHolderName}
                          <button onClick={() => handleCopy(tradeBankAccount?.accountHolderName, "tradeBankAccount?.accountHolderName")} className="tradedetails-copybtn">
                            <i className={`fas fa-${copied === "tradeBankAccount?.accountHolderName" ? "check" : "copy"}`}   title={`${copied ? "Copied" : "Copy"}`}></i>
                          </button>
                        </span>
                      </div>
                  )}
                  {!tradeBankAccount?.ibanNumber && tradeBankAccount?.accountNumber && (
                      <div className="trade_bank_account tradedetails-tradeinfo-specifics-text">
                        <label className="tradedetails-tradeinfo-payment-textnames">Account Number:</label>
                        <span className="tradedetails-tradeinfo-payment-textvalues">{tradeBankAccount?.accountNumber}
                          <button onClick={() => handleCopy(tradeBankAccount?.accountNumber, "tradeBankAccount?.accountNumber")} className="tradedetails-copybtn">
                            <i className={`fas fa-${copied === "tradeBankAccount?.accountNumber" ? "check" : "copy"}`}   title={`${copied ? "Copied" : "Copy"}`}></i>
                          </button>
                        </span>
                      </div>
                  )}
                      {tradeBankAccount?.routingNumber && (
                        <div className="trade_bank_account tradedetails-tradeinfo-specifics-text">
                          <label className="tradedetails-tradeinfo-payment-textnames">Routing Number/Transit No/Branch Code:</label>
                          <span className="tradedetails-tradeinfo-payment-textvalues">{tradeBankAccount?.routingNumber}
                            <button onClick={() => handleCopy(tradeBankAccount?.routingNumber, "tradeBankAccount?.routingNumber")} className="tradedetails-copybtn">
                              <i className={`fas fa-${copied === "tradeBankAccount?.routingNumber" ? "check" : "copy"}`}   title={`${copied ? "Copied" : "Copy"}`}></i>
                            </button>
                          </span>
                        </div>
                      )}
                      {tradeBankAccount?.interacEmail && (
                        <div className="trade_bank_account tradedetails-tradeinfo-specifics-text">
                          <label className="tradedetails-tradeinfo-payment-textnames">Interac e-transfer Email:</label>
                          <span className="tradedetails-tradeinfo-payment-textvalues">{tradeBankAccount?.interacEmail}
                            <button onClick={() => handleCopy(tradeBankAccount?.interacEmail, "tradeBankAccount?.interacEmail")} className="tradedetails-copybtn">
                              <i className={`fas fa-${copied === "tradeBankAccount?.interacEmail" ? "check" : "copy"}`}   title={`${copied ? "Copied" : "Copy"}`}></i>
                            </button>
                          </span>
                        </div>
                      )}
                      {tradeBankAccount?.swiftCode && (
                        <div className="trade_bank_account tradedetails-tradeinfo-specifics-text">
                          <label className="tradedetails-tradeinfo-payment-textnames">Swift Code:</label>
                          <span className="tradedetails-tradeinfo-payment-textvalues">{tradeBankAccount?.swiftCode}
                            <button onClick={() => handleCopy(tradeBankAccount?.swiftCode, "tradeBankAccount?.swiftCode")} className="tradedetails-copybtn">
                              <i className={`fas fa-${copied === "tradeBankAccount?.swiftCode" ? "check" : "copy"}`}   title={`${copied ? "Copied" : "Copy"}`}></i>
                            </button>
                          </span>
                        </div>
                      )}
                      {tradeBankAccount?.ibanNumber && (
                        <div className="trade_bank_account tradedetails-tradeinfo-specifics-text">
                          <label className="tradedetails-tradeinfo-payment-textnames">IBAN Number:</label>
                          <span className="tradedetails-tradeinfo-payment-textvalues">{tradeBankAccount?.ibanNumber}
                            <button onClick={() => handleCopy(tradeBankAccount?.ibanNumber, "tradeBankAccount?.ibanNumber")} className="tradedetails-copybtn">
                              <i className={`fas fa-${copied === "tradeBankAccount?.ibanNumber" ? "check" : "copy"}`} title={`${copied ? "Copied" : "Copy"}`}></i>
                            </button>
                          </span>
                        </div>
                      )}
                      {tradeBankAccount?.clabe && (
                        <div className="trade_bank_account tradedetails-tradeinfo-specifics-text">
                         <label className="tradedetails-tradeinfo-payment-textnames">Clabe:</label>
                         <span className="tradedetails-tradeinfo-payment-textvalues">{tradeBankAccount?.clabe}
                            <button onClick={() => handleCopy(tradeBankAccount?.clabe, "tradeBankAccount?.clabe")} className="tradedetails-copybtn">
                              <i className={`fas fa-${copied === "tradeBankAccount?.clabe" ? "check" : "copy"}`} title={`${copied ? "Copied" : "Copy"}`}></i>
                            </button>
                         </span>
                        </div>
                      )}
                      {tradeBankAccount?.country?.name && (
                        <div className="trade_bank_account tradedetails-tradeinfo-specifics-text">
                          <label className="tradedetails-tradeinfo-payment-textnames">Country:</label>
                          <span className="tradedetails-tradeinfo-payment-textvalues">{tradeBankAccount?.country?.name}
                            <button onClick={() => handleCopy(tradeBankAccount?.country?.name, "tradeBankAccount?.country?.name")} className="tradedetails-copybtn">
                              <i className={`fas fa-${copied === "tradeBankAccount?.country?.name" ? "check" : "copy"}`} title={`${copied ? "Copied" : "Copy"}`}></i>
                            </button>
                          </span>
                        </div>
                      )}
                      {tradeBankAccount?.fiatCurrency?.code && (
                        <div className="trade_bank_account tradedetails-tradeinfo-specifics-text">
                          <label className="tradedetails-tradeinfo-payment-textnames">Currency:</label>
                          <span className="tradedetails-tradeinfo-payment-textvalues">{tradeBankAccount?.fiatCurrency?.code}
                            <button onClick={() => handleCopy(tradeBankAccount?.fiatCurrency?.code, "tradeBankAccount?.fiatCurrency?.code")} className="tradedetails-copybtn">
                              <i className={`fas fa-${copied === "tradeBankAccount?.fiatCurrency?.code" ? "check" : "copy"}`} title={`${copied ? "Copied" : "Copy"}`}></i>
                            </button>
                          </span>
                        </div>
                      )}
                      {
                        tradeBankAccount?.error && (
                          <div className="trade_bank_account tradedetails-tradeinfo-specifics-text">
                            <label className="tradedetails-tradeinfo-payment-textnames">Bank Account Details:</label>
                            <p className="tradedetails-tradeinfo-payment-textvalues">{tradeBankAccount?.error}</p>
                          </div>
                        )
                      }
                      <div className="trade_bank_account tradedetails-tradeinfo-specifics-text">
                        <label className="tradedetails-tradeinfo-payment-textnames" title="Transaction Reference or Description Message to include and watchout for when making payment">Transaction Reference Message:</label>
                        <span className="tradedetails-tradeinfo-payment-textvalues">{referenceMessage || "hands polish"}
                            <button onClick={() => handleCopy(referenceMessage || "hands polish", "referenceMessage")} className="tradedetails-copybtn">
                              <i className={`fas fa-${copied === "referenceMessage" ? "check" : "copy"}`} title={`${copied ? "Copied" : "Copy"}`}></i>
                            </button>
                        </span>
                      </div>
                      <div className="trade_bank_account tradedetails-tradeinfo-specifics-text">
                        <label className="tradedetails-tradeinfo-payment-textnames">Total Amount:</label>
                        <span className="tradedetails-tradeinfo-payment-textvalues">{transferTotal}
                          <button onClick={() => handleCopy(transferTotal, "transferTotal")} className="tradedetails-copybtn">
                            <i className={`fas fa-${copied === "transferTotal" ? "check" : "copy"}`} title={`${copied ? "Copied" : "Copy"}`}></i>
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>            
                </div>
              </div>
            </div>
          </div>
      <div>
        <p className="tradedetails-tradeinfo-title">
          <i className="fas fa-info-circle"></i>
          {isBuyer ? "Seller's Details" : "Buyer's Details"}
        </p>
        <div>
          <div>
            <div>
              <div className="trade_bank_account tradedetails-tradeinfo-specifics-text">
                <label className="tradedetails-tradeinfo-payment-textnames">Name:</label>
                <span className="tradedetails-tradeinfo-payment-textvalues">{isBuyer ? sellerUsername : buyerName}</span>
              </div>
              <div className="trade_bank_account tradedetails-tradeinfo-specifics-text">
                <label className="tradedetails-tradeinfo-payment-textnames">Total Amount:</label>
                <span className="tradedetails-tradeinfo-payment-textvalues">{transferTotal}
                  <button onClick={() => handleCopy(transferTotal, "transferTotal")} className="tradedetails-copybtn">
                    <i className={`fas fa-${copied === "transferTotal" ? "check" : "copy"}`} title={`${copied ? "Copied" : "Copy"}`}></i>
                  </button>
                </span>
              </div>
              <div className="trade_bank_account tradedetails-tradeinfo-specifics-text">
                <label className="tradedetails-tradeinfo-payment-textnames" title="Transaction Reference or Description Message to include and watchout for when making payment">Transaction Reference Message:</label>
                <span className="tradedetails-tradeinfo-payment-textvalues">{referenceMessage || "hands polish"}
                  <button onClick={() => handleCopy(referenceMessage || "hands polish", "referenceMessage")} className="tradedetails-copybtn">
                    <i className={`fas fa-${copied === "referenceMessage" ? "check" : "copy"}`} title={`${copied ? "Copied" : "Copy"}`}></i>
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BankAccountDetails;
