import { formErrors } from "../../../../../utils/formErrors";

interface FormErrors {
  cardNumberError: string;
}
export const formattedErrors = (errors) => {
  const validationErrors = {} as FormErrors;

  const formatErrors = formErrors(errors);

  validationErrors.cardNumberError = formatErrors["card_number"];

  return validationErrors;
};
