import React from "react";
import Head from "./Head";
import Body from "./Body";

const Table = ({ transfers, storeId, removeTransfer, loading }) => (
  <div className="container-fluid">
    <div className="row">
      <div className="col-md-12">
        <div className="card card-sheet sheet-condensed table-responsive mt-3">
          <table className="table table-striped table-hover table-responsive w-100 d-block d-md-table">
            <Head />
            {loading ? (
              <div className="d-flex justify-content-center align-items-center">
                Loading...
              </div>
            ) : (
              <Body
                transfers={transfers}
                storeId={storeId}
                removeTransfer={removeTransfer}
              />
            )}
          </table>
        </div>
      </div>
    </div>
  </div>
);

export default Table;
