import React from "react";

const Head = () => (
  <thead style={{ backgroundColor: '#4c3f84', color: '#fff' }}>
    <tr>
      <th>Date</th>
      <th>Transaction Ref</th>
      <th>Description</th>
      <th>Credit($)</th>
      <th>Debit($)</th>
      <th>Balance($)</th>
    </tr>
  </thead>
);

export default Head;
