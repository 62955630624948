import React, { Component } from "react";
import Modal from "react-modal";
import ReactLoading from "react-loading";

const customStyles = {
  content: {
    width: "30%",
    margin: "0 auto",
    top: "10%",
    borderRadius: "12px",
    height: "35%"
  }
};

const ErrorModal = ({
  errorMessage,
  showErrorModal,
  handleCloseErrorModal
}) => (
  <Modal isOpen={showErrorModal} style={customStyles} ariaHideApp={false}>
    <div className="token-modal confirmation-modal">
      <div className="header">
        <a href="#" onClick={e => handleCloseErrorModal(e)}>
          <p>
            <i className="fa fa-times"></i>
          </p>
        </a>
      </div>
      <div className="content">
        <svg
          data-v-56fa0e99=""
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="exclamation-triangle"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 576 512"
          className="text-danger svg-inline--fa fa-exclamation-triangle fa-w-18 fa-3x"
        >
          <path
            data-v-56fa0e99=""
            fill="currentColor"
            d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"
            className=""
          ></path>
        </svg>
        <div className="error-message">
          <h3>Transaction Failed</h3>
          <p>Error {errorMessage}</p>
        </div>
      </div>
    </div>
  </Modal>
);

export default ErrorModal;
