(function () {
  KisoThemes.hookOnPageLoad(function () {
    const inputs = document.querySelectorAll(".code-input");
    const fullCodeInput = document.getElementById("full-code-input");

    function updateFullCode() {
      let fullCode = "";
      inputs.forEach((input) => {
        fullCode += input.value;
      });
      fullCodeInput.value = fullCode;
    }

    inputs.forEach((input, index) => {
      input.addEventListener("input", function (e) {
        updateFullCode();
        if (this.value.length === this.maxLength) {
          if (index < inputs.length - 1) {
            inputs[index + 1].focus();
          }
        }
      });

      input.addEventListener("keydown", function (e) {
        if (e.key === "Backspace" && this.value.length === 0 && index > 0) {
          inputs[index - 1].focus();
        }
      });

      // Handle pasting of the entire code
      input.addEventListener("paste", function (e) {
        e.preventDefault();
        const pastedData = e.clipboardData.getData("text");
        const codeLength = inputs.length;

        for (let i = 0; i < codeLength; i++) {
          if (i < pastedData.length) {
            inputs[i].value = pastedData[i];
          }
        }

        updateFullCode();

        if (pastedData.length >= codeLength) {
          inputs[codeLength - 1].focus();
        } else {
          inputs[pastedData.length].focus();
        }
      });
    });
  });
})();
