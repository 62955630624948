import React, { Component } from "react";
import toastr from "toastr";
import moment from "moment/moment";
import Table from "./Table";
import Filters from "./Filters";
import { getRequest } from "../../../../utils/httpRequest";
import { Transaction } from "./Types";

interface Props {
  transactions: Transaction[];
  cardId: string;
}

interface State {
  filters: {
    startDate: any;
    endDate: any;
  };
  transactions: Transaction[];
  loading: boolean;
}

class List extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      filters: {
        startDate: moment().subtract(2, "months").startOf("month"),
        endDate: moment(),
      },
      transactions: [],
      loading: true,
    };
  }

  componentDidMount() {
    this.getTransactions();
  }

  onSelectFilter = (key, value) => {
    const { filters } = this.state;

    const updatedFilters = { ...filters, [key]: value };

    this.setState({ filters: updatedFilters });
  };
  getTransactions = (e = null) => {
    e?.preventDefault();

    this.setState({ loading: true });

    const {
      filters: { startDate, endDate },
    } = this.state;

    const url = `/api/v1/cards/${
      this.props.cardId
    }/transactions?start_date=${startDate.format(
      "YYYY-MM-DD"
    )}&end_date=${endDate.format("YYYY-MM-DD")}`;

    getRequest(url)
      .then((response) => {
        this.setState({
          transactions: response,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({ loading: false, transactions: [] });
        toastr.error("Failed to fetch transactions. Please try again.");
      });
  };
  render() {
    const { cardId } = this.props;
    const { transactions, filters, loading } = this.state;

    return (
      <div className="account-activity">
        <Filters
          filters={filters}
          getTransactions={this.getTransactions}
          onSelectFilter={this.onSelectFilter}
        />
        <Table transactions={transactions} loading={loading} />
      </div>
    );
  }
}

export default List;
