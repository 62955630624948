import React from "react";

const SuccessUpdatedModal = ({ action, store_id, integration,  }) => {

  const handleReload = () => {
    window.location.href = `/stores/${store_id}/integrations`;
  };  

  return (
    <div
      className="modal-delistoffers-alert-cont"
      aria-hidden="true"
      aria-labelledby="delistOffers"
    >
      <div>
        <h5 className="delistoffer-modal-title">
          <i className="fas fa-check-circle"></i>
          <span>Successfully {action}!</span>
        </h5>

        <div className="delistoffer-modal-buttons-section">
          <button
            type="button"
            className="delistoffer-modal-tradeaction-proceedbtn"
            onClick={handleReload}
          >
            Okay
          </button>
        </div>
      </div>
    </div>
  )
}

export default SuccessUpdatedModal;
