import React, { Component } from "react";
import _ from "underscore";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { getRequest, postRequest } from "../../utils/httpRequest";
import { formErrors } from "../../utils/formErrors";
import DefaultForm from "./DefaultForm";
import SignupForm from "./SignupForm";
import TableBody from "./TableBody";
import premiums from "./premiums.json";
import ReactTooltip from 'react-tooltip';
import { isPossiblePhoneNumber } from 'react-phone-number-input'

import "./index.scss";

class InsuranceCalculator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dateOfBirth: props.params?.age ? this.setAge(props.params.age) : null,
      selectedGender: props.params?.gender || "male",
      selectedPremium: props.params?.premium || 2000,
      nonTerminalIllness: true,
      validationErrors: {},
      email: "",
      phoneNumber: "",
      firstName: "",
      lastName: "",
      selectedCountry: { label: "United States", value: "US" },
      countries: props.params.countries,
      mode: "default",
      creating: false,
      policies: []
    };
  }

  componentDidMount() {}

  setAge = age => {
    const date = moment()
      .subtract(age, "years")
      .format("YYYY-MM-DD");

    return new Date(date);
  };

  onChangeDateOfBirth = dateOfBirth => {
    // console.log(dateOfBirth)
    this.setState({ dateOfBirth }, () => this.validateDateOfBirth());
  };

  validateDateOfBirth = () => {
    const { dateOfBirth, validationErrors } = this.state;

    if (dateOfBirth) {
      validationErrors.dateOfBirthError = "";
      this.setState({ validationErrors });
    } else {
      validationErrors.dateOfBirthError = "Please fill in this field.";
      this.setState({ validationErrors });
    }
  };

  handleChange = (e, key) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({ [key]: value });
  };

  handleCountryChange = selectedCountry => {
    this.setState({ selectedCountry });
  };

  validatePhoneNumber = phoneNumber => {
    // const regex = /[0-9]{3}-[0-9]{3}-[0-9]{4}/g;
    // return phoneNumber.match(regex);
    return isPossiblePhoneNumber(phoneNumber);
  };

  validateFormFields = () => {
    const {
      dateOfBirth,
      email,
      mode,
      firstName,
      lastName,
      phoneNumber
    } = this.state;

    let validationErrors = {};

    if (mode === "default" && !dateOfBirth) {
      validationErrors.dateOfBirthError = "Please fill in your date of birth.";
    }

    if (mode === "sign-up") {
      if (!email) {
        validationErrors.emailError = "Please fill in your email address.";
      }

      if (!phoneNumber) {
        validationErrors.phoneNumberError = "Please fill your phone.";
      } else if (!this.validatePhoneNumber(phoneNumber)) {
        validationErrors.phoneNumberError =
         "Invalid Phone Number";
      }

      if (!firstName) {
        validationErrors.firstNameError =
          "Please fill in your first or given name.";
      }

      if (!lastName) {
        validationErrors.lastNameError =
          "Please fill in your last name or surname.";
      }
    }

    return validationErrors;
  };

  back = e => {
    e.preventDefault();

    this.setState({ mode: "default" });
  };

  preview = e => {
    e.preventDefault();

    this.setState({ mode: "sign-up" });
  };

  submit = e => {
    e.preventDefault();

    const { dateOfBirth, selectedPremium, selectedGender } = this.state;

    const validationErrors = this.validateFormFields();

    if (!_.isEmpty(validationErrors)) {
      this.setState({ validationErrors });

      return;
    }

    this.setState({ submitting: true, error: "", validationErrors: {} });

    getRequest(
      `/insurance_policies?date_of_birth=${dateOfBirth}&gender=${selectedGender}&premium=${selectedPremium}`
    )
      .then(response => {
        this.setState({
          policies: response,
          mode: "preview",
          submitting: false
        });
      })
      .catch(error => {
        this.setState({ submitting: false });
        console.log(error);
      });

      let card_container = document.querySelector('.insurance-calculator-card')

      // scroll down
      window.scrollTo({
        top: card_container.offsetHeight*1.5,
        left: 100,
        behavior: 'smooth'
      });
  };

  createInsuranceLead = e => {
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      selectedGender,
      selectedPremium,
      dateOfBirth,
      selectedCountry
    } = this.state;
    e.preventDefault();

    const validationErrors = this.validateFormFields();

    if (!_.isEmpty(validationErrors)) {
      this.setState({ validationErrors });

      return;
    }

    this.setState({ creating: true, error: "", validationErrors: {} });

    const crsfToken = $('meta[name="csrf-token"]').attr("content");

    const data = {
      insurance_lead: {
        first_name: firstName,
        last_name: lastName,
        email,
        phone_number: phoneNumber,
        premium: selectedPremium,
        gender: selectedGender,
        date_of_birth: dateOfBirth,
        country: selectedCountry.label
      }
    };

    postRequest("/insurance_leads", data, {
      "X-CSRF-Token": crsfToken
    })
      .then(response => {
        this.setState({ creating: false, mode: "lead-created" });
      })
      .catch(error => {
        const errors = formErrors(error.data.errors);

        const formattedErrors = this.formattedErrors(errors);

        this.setState({
          validationErrors: formattedErrors,
          creating: false
        });
      });
  };

  formattedErrors = errors => {
    const { validationErrors } = this.state;

    const firstNameError = errors["first_name"];
    const lastNameError = errors["last_name"];
    const emailError = errors["email"];
    const phoneNumberError = errors["phone_number"];
    const dateOfBirthError = errors["date_of_birth"];

    validationErrors.firstNameError = firstNameError;
    validationErrors.lastNameError = lastNameError;
    validationErrors.emailError = emailError;
    validationErrors.phoneNumberError = phoneNumberError;
    validationErrors.dateOfBirthError = dateOfBirthError;

    return validationErrors;
  };

  getSelectedPremium = () => {
    const { selectedPremium } = this.state;

    return _.find(premiums, function(premium) {
      return premium.value === Number(selectedPremium);
    });
  };

  scrollToAction = e => {
    e.preventDefault();

    // $("html, body").animate(
    //   {
    //     scrollTop: $("#call-to-action").offset().top - 300
    //   },
    //   1000
    // );
    this.setState({ mode: "sign-up" });
    window.scrollTo(0, 0);
  };

  render() {
    const {
      dateOfBirth,
      selectedGender,
      selectedPremium,
      nonTerminalIllness,
      validationErrors,
      mode,
      email,
      phoneNumber,
      firstName,
      lastName,
      selectedCountry,
      countries,
      policies,
      submitting,
      creating
    } = this.state;

    const defaultMode = mode === "default" || mode === "preview";

    return (
      <div className="container-fluid insurance-calculator">
        <div className="insurance-calculator-card col-12 col-md-8 mr-auto ml-auto">
          {/* <h3>Calculator</h3> */}
          <div className="card">
            <div className="card-header">
              <header className="text-center">
                <div className="profile">
                  <img src="https://media.bananacrystal.com/wp-content/uploads/2020/12/30060859/cropped-bananacrystal-logo-400x100-1-1.png" />
                </div>
              </header>
            </div>
            <div className="card-body">
              {defaultMode && (
                <DefaultForm
                  selectedGender={selectedGender}
                  dateOfBirth={dateOfBirth}
                  selectedPremium={selectedPremium}
                  mode={mode}
                  submitting={submitting}
                  nonTerminalIllness={nonTerminalIllness}
                  validationErrors={validationErrors}
                  submit={this.submit}
                  preview={this.preview}
                  handleChange={this.handleChange}
                  onChangeDateOfBirth={this.onChangeDateOfBirth}
                />
              )}
              {mode === "sign-up" && (
                <SignupForm
                  selectedGender={selectedGender}
                  dateOfBirth={dateOfBirth}
                  firstName={firstName}
                  selectedPremium={selectedPremium}
                  lastName={lastName}
                  email={email}
                  phoneNumber={phoneNumber}
                  selectedCountry={selectedCountry}
                  countries={countries}
                  handleCountryChange={this.handleCountryChange}
                  nonTerminalIllness={nonTerminalIllness}
                  validationErrors={validationErrors}
                  creating={creating}
                  getSelectedPremium={this.getSelectedPremium}
                  handleChange={this.handleChange}
                  createInsuranceLead={this.createInsuranceLead}
                  back={this.back}
                />
              )}
              {mode === "lead-created" && (
                <div className="alert alert-success" role="alert">
                  Thank you! Your information has been received. You will
                  receive an email shortly with the next steps.
                </div>
              )}
            </div>
          </div>
        </div>
        {mode === "preview" && policies.length > 0 && (
          <div className="action call-to-action" id="call-to-action">
            <button
              type="submit"
              className="btn btn-primary right"
              onClick={this.preview}>
              Contact Us to Proceed
            </button>
          </div>
        )}
        {mode === "preview" && policies.length == 0 && (
            <div className="alert alert-warning">
            <br></br>
            <div className="mb-4">
            Please select an age between 1 - 62 years as you must be between 1 - 62 years of age to participate in this program.
              <br></br>
            </div>
          </div>
        )}
        { policies.length == 0 && (
          <div className="summaries">
            <hr></hr>
            <div className="summary">
              <h3>Your Total Contribution: <span className="text-muted">Click Preview</span></h3>
            </div>
            <div className="summary tax-free">
              <h3>
                Your Total Tax Free Distribution:{" "}
                <span className="text-muted">Click Preview</span>
              </h3>
            </div>
          </div>
        )}
        {policies.length > 0 && (
            <div className="summaries">
              <hr></hr>
              <div className="summary">
                <h3>Your Total Contribution: {policies[0].contribution_total}</h3>
              </div>
              <div className="summary tax-free">
                <h3>
                  Your Total Tax Free Distribution:{" "}
                  <span className="text-success">{policies[0].tax_free_distribution_total}</span>
                </h3>
              </div>
            </div>
          )}

        <hr></hr>
        <br></br>
        <h4>
          Contribution and Distribution Details { policies.length > 0 &&  
          ( <span>for Ages <span className="text-warning">{policies[0].age_range}</span> <i>(Example</i>) </span>)}
        </h4>
        <div className="results card card-sheet sheet-condensed table-responsive">
          <table className="table table-striped table-hover table-responsive w-100 d-block d-md-table">
            <thead>
              <tr>
                <th scope="col" data-tip="Represents Age of Plan">Policy Year</th>
                <th scope="col" data-tip="Age of the Insured">Age</th>
                <th scope="col" data-tip="Maximum Annual Contribution to the Plan">Planned Premium (per Year)</th>
                <th scope="col" data-tip="Tax-Free Distribution Taken as Income">Annual Income</th>
                <th scope="col" data-tip="Account Value used as Multiplier to Calculate Interest Credit">Accumulated Value</th>
                <th scope="col" data-tip="Liquidity of Account for Lending/Investing Needs">Surrender Value</th>
                <th scope="col" data-tip="Tax Free Death Benefit paid to Beneficiaries">Net Death Benefit</th>
              </tr>
            </thead>
            <TableBody policies={policies} />
          </table>
          <ReactTooltip data-type="dark" data-background-color="#00669c" />
          {policies.length == 0 && (
            <div className="summaries">
              <br></br>
              <div className="summary mb-4">
                Enter your information above and click Preview to continue.
                <br></br>
              </div>
            </div>
          )}
        </div>

        {mode === "preview" && policies.length > 0 && (
        <div className="action call-to-action" id="call-to-action">
          <button
            type="submit"
            className="btn btn-primary right"
            onClick={this.scrollToAction}>
            Contact Us to Proceed
          </button>
        </div>
        )}

        <div className="notes">
          <div>
            <h4>Legend</h4>
            <ul className="list-group">
              <li className="list-group-item">
                Policy Year - Represents Age of Plan
              </li>
              <li className="list-group-item">Age - Age of the Insured</li>
              <li className="list-group-item">
                Planned Premium - Maximum Annual Contribution to the Plan (per year)
              </li>
              <li className="list-group-item">
                Annual Income - Tax-Free Distribution Taken as Income
              </li>
              <li className="list-group-item">
                Accumulated Value - Account Value used as Multiplier to
                Calculate Interest Credit
              </li>
              <li className="list-group-item">
                Surrender Value - Liquidity of Account for Lending/Investing
                Needs
              </li>
              <li className="list-group-item">
                Net Death Benefit - Tax Free Death Benefit paid to Beneficiaries
              </li>
            </ul>
          </div>
          <div className="recurring">
            <h6>Recurring Notes</h6>
            <ul className="list-group">
              <li className="list-group-item">
                Year 11 - Your Contributions stop for duration of plan, but cash value continues to increase and Death Benefit stays in force at a minimum level.
              </li>
              <li className="list-group-item">
                At Age 65 - You are receiving Annual Income, thus reducing your Death Benefit. Note: You must have completed your plan premium payments.
              </li>
              <li className="list-group-item">
                At Age 90 - Your Income Continues to Age 120
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default InsuranceCalculator;
