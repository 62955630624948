import React, { useState, useEffect } from 'react';
import { patchRequest } from "../../utils/httpRequest";
import SuccessAddAppModal from './SuccessModal';

const PartnerDevEditForm = ({ appId, appName, appRedirectUrl, appWebhook }) => {
  const [name, setName] = useState('');
  const [redirectUri, setRedirectUri] = useState('');
  const [webhookUrl, setWebhookUrl] = useState('');
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  
  // Helper function to check if a URL is valid
  const isValidUrl = (url) => /^https:\/\/(?!.*\.exe$).+\..+/i.test(url);
  
  // Validate form inputs and update the save button state
  const validateForm = () => {
    const nameValid = name && !name.includes('.');
    const redirectUriValid = isValidUrl(redirectUri);
    const webhookUrlValid = webhookUrl.length > 0 ? isValidUrl(webhookUrl) : true;

    setIsSaveDisabled(!(nameValid && redirectUriValid && webhookUrlValid));
  };
  
  // Disable pasting in fields
  const handlePaste = (e) => e.preventDefault();
  
  // Update input values and validate form
  const handleNameChange = (e) => {
    const value = e.target.value.replace(/\./g, ''); // Remove dots
    setName(value);
    validateForm();
  };
  
  const handleRedirectUriChange = (e) => {
    // Remove spaces, `<`, and `>` characters from the input
    const value = e.target.value.replace(/[ <>\s]/g, '');
    setRedirectUri(value);
    validateForm();
  };
  
  const handleWebhookUrlChange = (e) => {
    // Remove spaces, `<`, and `>` characters from the input
    const value = e.target.value.replace(/[ <>\s]/g, '');
    setWebhookUrl(value);
    validateForm();
  };

  useEffect(() => {
    validateForm();
  }, [name, redirectUri, webhookUrl])

  useEffect(() => {
    setName(appName);
    setRedirectUri(appRedirectUrl);
    setWebhookUrl(appWebhook);
  }, []);

  const showSuccessModal = () => {
    const successModalCont = document.querySelector('#successmodal-partnersdev-cont') as HTMLElement;
    if (successModalCont) {
      successModalCont.style.display = 'flex';
    }
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const payload = {
      name,
      redirect_uri: redirectUri,
      webhook_url: webhookUrl,
    };
  
    try {
      const response = await patchRequest(`/partners/api_keys/`, payload);
      if (response.status === "success") {
        showSuccessModal();
      } else {
        console.error("Error creating app:", response);
      }
    } catch (error) {
      console.error("Network error:", error);
    }
  };  

  return (
    <div className="partners-dev-addapp-cont" id="partners-dev-addapp-cont">
     
     <div id="successmodal-partnersdev-cont">
      <SuccessAddAppModal
        action='Updated'
      />
     </div>
      <form className="form-horizontal" data-controller="form">
        <div className="form-group row">
          <label htmlFor="api_name" className="form-label">
            Name <span style={{ color: 'red' }}>*</span>
          </label>
          <input
            type="text"
            className="form-control"
            id="api_name"
            value={name}
            required
            onChange={handleNameChange}
            onPaste={handlePaste}
          />
          <div className="error-messages">{/* Display name error here if needed */}</div>
        </div>
        <br />

        <div className="form-group row">
          <label htmlFor="api_redirect_uri" className="form-label">
            Redirect URL <span style={{ color: 'red' }}>*</span>
          </label>
          <input
            type="text"
            className="form-control"
            id="api_redirect_uri"
            value={redirectUri}
            required
            onChange={handleRedirectUriChange}
            onPaste={handlePaste}
          />
          <div className="error-messages">{/* Display redirect URL error here if needed */}</div>
          <small>Please ensure to start your URL with <b>"https://"</b></small>
        </div>
        <br />

        <div className="form-group row">
          <label htmlFor="api_webhook_url" className="form-label">
            Webhook URL for Notifications
          </label>
          <input
            type="text"
            className="form-control"
            id="api_webhook_url"
            value={webhookUrl}
            onChange={handleWebhookUrlChange}
            onPaste={handlePaste}
          />
          <div className="error-messages">{/* Display webhook URL error here if needed */}</div>
          <small>Please ensure to start your URL with <b>"https://"</b></small>
        </div>
        <br />

        <div className="row">
          <div className="partnership-dev-btnsection">
            <button
              type="button"
              className="btn partnership-dev-btn ml-auto"
              id="partners-saveapp"
              disabled={isSaveDisabled}
              onClick={handleSubmit}
            >
              SAVE
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default PartnerDevEditForm;
