import React from "react";

const AllPaymentMethods = ({
  allPaymentMethods,
  giftCards,
  onlineTransfers,
  cashDeposits,
  creditCards,
  bankTransfers,
  digitalCurrencies,
  goodsAndServices,
  onSelectPaymentMethodGroup
}) => {
  return (
    <div className="payment-methods">
      <button
        className="btn btn-light"
        onClick={e => onSelectPaymentMethodGroup(e, "all-payment-methods")}
      >
        <span className="name">All payment methods</span>
        <span className="count">
          {allPaymentMethods.length}
          <i className="fa fa-arrow-right" aria-hidden="true"></i>
        </span>
      </button>
      <button
        className="btn btn-light"
        onClick={e => onSelectPaymentMethodGroup(e, "bank-transfers")}
      >
        <span className="name">Bank transfers</span>
        <span className="count">
          {bankTransfers.length}
          <i className="fa fa-arrow-right" aria-hidden="true"></i>
        </span>
      </button>
      <button
        className="btn btn-light"
        onClick={e => onSelectPaymentMethodGroup(e, "online-transfers")}
      >
        <span className="name">Online wallets</span>
        <span className="count">
          {onlineTransfers.length}
          <i className="fa fa-arrow-right" aria-hidden="true"></i>
        </span>
      </button>
      <button
        className="btn btn-light"
        onClick={e => onSelectPaymentMethodGroup(e, "debitcredit-cards")}
      >
        <span className="name">Debit/credit cards</span>
        <span className="count">
          {creditCards.length}
          <i className="fa fa-arrow-right" aria-hidden="true"></i>
        </span>
      </button>
      <button
        className="btn btn-light"
        onClick={e => onSelectPaymentMethodGroup(e, "gift-cards")}
      >
        <span className="name">Gift cards</span>
        <span className="count">
          {giftCards.length}
          <i className="fa fa-arrow-right" aria-hidden="true"></i>
        </span>
      </button>
      <button
        className="btn btn-light"
        onClick={e => onSelectPaymentMethodGroup(e, "digital-currencies")}
      >
        <span className="name">Digital currencies</span>
        <span className="count">
          {digitalCurrencies.length}
          <i className="fa fa-arrow-right" aria-hidden="true"></i>
        </span>
      </button>
      <button
        className="btn btn-light"
        onClick={e => onSelectPaymentMethodGroup(e, "cash-deposits")}
      >
        <span className="name">Cash payments</span>
        <span className="count">
          {cashDeposits.length}
          <i className="fa fa-arrow-right" aria-hidden="true"></i>
        </span>
      </button>
      <button
        className="btn btn-light"
        onClick={e => onSelectPaymentMethodGroup(e, "goods-and-services")}
      >
        <span className="name">Goods and services</span>
        <span className="count">
          {goodsAndServices.length}
          <i className="fa fa-arrow-right" aria-hidden="true"></i>
        </span>
      </button>
    </div>
  );
};

export default AllPaymentMethods;
