import React from "react";
import _ from "lodash";
import PersonalDetails from "./components/PersonalDetails";
import IdentificationDetails from "./components/IdentificationDetails";
import MailingDetails from "./components/MailingDetails";
import {
  personalDetails,
  identificationDetails,
  mailingDetails,
} from "./helpers";
import { CardType } from "../../Types";

interface Props {
  data: any;
  cardType: string;
  cardTypes: CardType[];
  submitting: boolean;
  handleSetStep: (step: string) => void;
}

const Preview: React.FC<Props> = ({
  cardType,
  cardTypes,
  data,
  handleSetStep,
  submitting,
}) => {
  const onClickEdit = (e, step) => {
    e.preventDefault();
    handleSetStep(step);
  };

  const cardTypeInfo = cardTypes.find((card) => card.type === cardType);

  return (
    <div className="card-body">
      <div id="preview">
        <div>
          <h4>Preview</h4>
          <p>
            Please confirm all your details have been entered correctly before
            submitting!
          </p>
        </div>
        <br />
        <div className="form-horizontal">
          <h6>Card Details</h6>
          <div className="form-group row">
            <label className="col-12 col-md-3 form-label">Card Type</label>
            <div className="col-12 col-md-9">
              <span>{_.startCase(_.toLower(cardType))}</span>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-12 col-md-3 form-label">
              Application Fee
            </label>
            {cardTypeInfo.is_coupon_claimable ? (
              <div className="col-12 col-md-9">
                <span style={{ textDecoration: "line-through" }}>
                  ${cardTypeInfo.application_fee}
                </span>{" "}
                <span>${cardTypeInfo.discounted_fee}</span>
                <span>(Coupon: {cardTypeInfo.coupon_code})</span>
              </div>
            ) : (
              <div className="col-12 col-md-9">
                <span>${cardTypeInfo.application_fee}</span>
              </div>
            )}
          </div>
          <hr />
          <PersonalDetails
            onClickEdit={onClickEdit}
            data={personalDetails(data)}
          />
          <hr />
          <IdentificationDetails
            onClickEdit={onClickEdit}
            data={identificationDetails(data)}
          />
          <hr />
          <MailingDetails
            onClickEdit={onClickEdit}
            data={mailingDetails(data)}
          />
          <hr />
        </div>
        <div className="d-flex flex-row justify-content-between">
          <div className="ml-auto">
            <button
              type="submit"
              className="btn btn-purple  float-right"
              disabled={submitting}
            >
              {submitting && (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              Submit <i className="fa fa-arrow-right"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Preview;
