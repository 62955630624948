import React, { Component } from "react";
import Modal from "react-modal";
import { Image, useImage } from 'react-img-placeholder'

import _ from "underscore";

const customStyles = {
  content: {
    width: "30%",
    margin: "0 auto",
    top: "10%",
    borderRadius: "12px",
    height: "62%"
  }
};

class TokenModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tokens: [],
      searchTerm: "",
      currentTokens: [],
      showMoreTokensButton: true
    };
  }

  componentDidMount() {
    const tokens = this.props.allTokens.slice(0, 7);
    const tokensWithAmount = tokens.map(token => {
      this.props.getTokenBalance(token).then(balance => {
        token.balance = balance;
      });

      return token;
    });

    this.setState({
      tokens: tokensWithAmount,
      currentTokens: tokensWithAmount
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.metamaskAccount !== prevProps.metamaskAccount ||
      this.props.isMainnet !== prevProps.isMainnet
    ) {
      const tokensWithAmount = this.state.currentTokens.map(token => {
        this.props.getTokenBalance(token).then(balance => {
          token.balance = balance;
        });

        return token;
      });

      this.setState({
        tokens: tokensWithAmount,
        currentTokens: tokensWithAmount
      });
    }
  }

  showMoreTokens = e => {
    e.preventDefault();

    const { tokens } = this.state;
    const filteredTokens = this.props.allTokens.slice(0, tokens.length + 7);

    const tokensWithAmount = filteredTokens.map(token => {
      if (!token.balance) {
        this.props.getTokenBalance(token).then(balance => {
          token.balance = balance;
        });
      }

      return token;
    });

    this.setState({
      tokens: tokensWithAmount,
      currentTokens: tokensWithAmount
    });
  };

  handleSearchChange = e => {
    this.setState({ searchTerm: e.target.value, showMoreTokensButton: false });

    setTimeout(() => {
      if (this.state.searchTerm.length < 1)
        return this.setState({
          tokens: this.state.currentTokens,
          showMoreTokensButton: true
        });

      const re = new RegExp(_.escape(this.state.searchTerm), "i");
      const isMatch = token => re.test(token.symbol.toLowerCase());

      const filteredTokens = _.filter(this.state.tokens, isMatch);

      this.setState({
        tokens: filteredTokens
      });
    }, 300);
  };

  onSelectToken = (e, token) => {
    e.preventDefault();

    this.props.onSelectToken(e, {
      symbol: token.symbol,
      contract: token.contract,
      precision: token.precision
    });
    this.props.handleCloseModal(e);
  };

  render() {
    const { openModal, handleCloseModal } = this.props;

    const { tokens, showMoreTokensButton } = this.state;

    return (
      <Modal isOpen={openModal} style={customStyles} ariaHideApp={false}>
        <div className="token-modal">
          <div className="header">
            <p>Select a Token</p>
            <a href="#" onClick={e => handleCloseModal(e)}>
              <p>
                <i className="fa fa-times"></i>
              </p>
            </a>
          </div>
          <div>
            <form className="trade-form">
              <input
                className="form-control mr-sm-2"
                type="search"
                placeholder="Search"
                aria-label="Search"
                value={this.state.searchTerm}
                onChange={_.debounce(this.handleSearchChange, 500, {
                  leading: true
                })}
              />
            </form>
            <div className="tokens">
              <p className="title">Tokens</p>
              {tokens.map((token, i) => (
                <a href="#" key={i} onClick={e => this.onSelectToken(e, token)}>
                  <div className="token">
                    {/* <img src={token.image} /> */}
                    <Image
                      src={token.image}
                      alt={token.symbol}
                      width={32}
                      height={32}
                      style={{display: 'inline'}}
                      placeholderColor="white" // <- this field is optional
                    />

                    <span>{token.symbol}</span>
                    <span className="balance">
                      {isNaN(token.balance) ||
                      token.balance === "0.000000" ||
                      token.balance === "0"
                        ? ""
                        : Number(token.balance)?.toFixed(6)}
                    </span>
                  </div>
                </a>
              ))}
              {showMoreTokensButton && (
                <div className="more">
                  <button
                    type="button"
                    className="btn block-rounded btn-outline-gray btn-sm btn-block"
                    onClick={this.showMoreTokens}
                  >
                    <span className="font-size-14 font-w500">More</span>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default TokenModal;
