import { Controller } from "stimulus";
import { encryptText } from "../model/crypto";
import { validateCard, validCardExpiryDate } from "../model/card_validation";

export default class extends Controller {
  static targets = [
    "inputtoencrypt",
    "encryptedoutput",
    "cardlastFourOutput",
    "number",
    "cvv",
    "cardExpMonth",
    "cardExpYear"
  ];

  connect() {
    let number = ''
    if (this.hasNumberTarget) {
      number = this.numberTarget.value
    }

    let cvv = this.cvvTarget.value || ''

    this.encryptCardDetails(number, cvv)

  }

  encryptCardDetails(number, cvv) {
    let data_json = '';

    data_json = this.getDataJson(number, cvv);

    if (data_json) {
      const message = this.inputtoencryptTarget.value = JSON.stringify(data_json)
      // console.log('message to encrypt', message)
      if (number) {
        this.cardlastFourOutputTarget.value = number.substr(-4);
      }
      this.encrypt(message);
      
    }
  }

  getDataJson(number, cvv) {
    let data_json = null;
    let isCardValid = false;
 
    // when updating a card or adding payments
    if (this.validCvv(cvv) && number == '') {
      // when updating a card (cvv onlly)
      data_json = { cvv: cvv }
    }
    // when adding a card
    else if (this.validCvv(cvv) && this.invalidNumber(number)) {

      this.addCardErrorMessage();
      // number 13 to 19 digits, cvv 3 to 4 digits
    } else if (this.validNumber(number) && this.validCvv(cvv)) {
      // validate card numbers
      isCardValid = validateCard(number)
      // console.log('isCardValid', isCardValid)
      if (isCardValid) {
        data_json = { number: number, cvv: cvv }
      } else {
        data_json = null
        this.addCardErrorMessage();
      }
    }
    return data_json;
  }

  validCvv(cvv) {
    let validCvv = (cvv && (cvv.length > 2 && cvv.length < 5)) == true;
    return validCvv;
  }

  validNumber(number) {
    let validNumber = (number && (number.length > 12 && number.length < 20)) == true;
    return validNumber;
  }

  invalidNumber(number) {
    let invalidNumber = (number == '' || number && (number.length < 12 || number.length > 20)) == true;
    return invalidNumber;
  }

  addCardErrorMessage() {
    const cardGroup = document.getElementsByClassName('row card_number')[0]
    if (cardGroup && cardGroup.children.length == 2) {
      let errorSpan = document.createElement('span');
      errorSpan.innerHTML = 'Invalid Card Number'
      errorSpan.className = "error text-danger card_number"
      cardGroup.appendChild(errorSpan);
    }
  }

  // updates existing card message
  updateCardErrorMessage() {
    const cardGroup = document.getElementsByClassName('row card_number')[0]
    if (cardGroup && cardGroup.children.length == 3) {
      const errorMessage = cardGroup.children[2]
      if (errorMessage) {
        errorMessage.innerText = "Invalid Card Number"
      }
    }
  }

  validateCardExpiration() {
    // this.cardExpMonthTarget.setCustomValidity("");

    let cardExpMonth;
    let cardExpYear;
    let isValidCardExpiryDate;

    if (this.cardExpMonthTarget) {
      cardExpMonth = Number(this.cardExpMonthTarget.value);
    }

    if (this.cardExpYearTarget) {
      cardExpYear = Number(this.cardExpYearTarget.value);
    }

    isValidCardExpiryDate = validCardExpiryDate(cardExpMonth, cardExpYear);

    // console.log('isValidCardExpiryDate', isValidCardExpiryDate);

    if (isValidCardExpiryDate) {
      this.updateCardExpiryErrorMessage('')

    } else {
      if(cardExpMonth == 0 || cardExpYear == 0) {
        this.addCardExpiryErrorMessage('Please select the expiration year and month');
        this.updateCardExpiryErrorMessage('Please select the expiration year and month');
      } else {
        this.addCardExpiryErrorMessage('Invalid Expiration Date');
        this.updateCardExpiryErrorMessage('Invalid Expiration Date');
      }
    }
  }

  addCardExpiryErrorMessage(msg) {
    const cardGroup = document.getElementsByClassName('row card_exp_year')[0]
    if (cardGroup && cardGroup.children.length == 2) {
      let errorSpan = document.createElement('span');
      errorSpan.innerHTML = msg;
      errorSpan.className = "error text-danger card_expiration"
      cardGroup.appendChild(errorSpan);
    }
  }

  // updates existing card expiration message
  updateCardExpiryErrorMessage(msg) {
    const cardGroup = document.getElementsByClassName('row card_exp_year')[0]
    if (cardGroup && cardGroup.children.length == 3) {
      const errorMessage = cardGroup.children[2]
      if (errorMessage) {
        errorMessage.innerText = msg;
      }
    }
  }




  async encrypt(message) {
    let encryptedMessage;
    // Get message and key
    const key = { keyId: 'key1', publicKey: process.env.CIRCLE_PUBLIC_KEY }

    const encrypted = await encryptText(message, key).catch((err) => { console.error(err); });
    if(encrypted) {
      // console.log('encrypted', encrypted)

      encryptedMessage = btoa(encrypted)
      if (encryptedMessage) {
        this.encryptedoutputTarget.value = encryptedMessage;
      }
    } else {
      console.log('error encrypting')
    }
  }
}
