import React, { Component } from "react";
import toastr from "toastr";
import CurrencyFlag from "react-currency-flags";
import Preview from "./Preview";
import { validateAmount, setApiErrors } from "./helpers";
import { getRequest, postRequest } from "../../../../utils/httpRequest";
import "./styles.scss";

interface Props {
  cardType: string;
  availableBalance: number;
  cardId: string;
}

interface State {
  amount: number;
  fee: number;
  total: number;
  submitting: boolean;
  submitRequest: boolean;
  cardType: string;
  validationErrors: {
    amountError: string;
  };
}

class New extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      amount: 0.0,
      fee: 0.0,
      total: 0.0,
      cardType: props.cardType,
      submitting: false,
      submitRequest: false,
      validationErrors: {
        amountError: "",
      },
    };
  }

  handleAmountChange = (e) => {
    const { availableBalance } = this.props;
    const { validationErrors } = this.state;
    const amount = parseFloat(e.target.value) || 0;

    this.setState({ amount }, () => {
      validationErrors.amountError = validateAmount(amount, availableBalance);

      this.setState({
        validationErrors,
        submitRequest: false,
        fee: 0,
        total: 0,
      });
    });
  };

  cancel = (e) => {
    e.preventDefault();
    const url = window.location.href;
    window.location.assign(url);
  };

  preview = (e) => {
    e.preventDefault();

    const { amount, cardType } = this.state;

    if (amount < 10) {
      this.setState({
        validationErrors: {
          amountError: "Please enter at least $10",
        },
      });
      return;
    }

    this.setState({ submitting: true });

    getRequest(
      `/api/v1/cards/fee?request_type=card_load&card_type=${cardType}&amount=${amount}`
    ).then((response) => {
      this.setState({
        fee: response.total_fee,
        total: response.total_fee + amount,
        submitRequest: true,
        submitting: false,
      });
    });
  };

  onSubmit = (e) => {
    e.preventDefault();

    const { cardId, availableBalance } = this.props;

    const { amount, fee, validationErrors } = this.state;

    if (amount + fee < availableBalance) {
      this.setState({
        validationErrors: {
          amountError:
            "You have insufficient balance to perform this operation. Please top up your account.",
        },
      });
      return;
    }

    this.setState({ submitting: true });

    postRequest(`/api/v1/cards/${cardId}/loads.json`, { amount })
      .then((response) => {
        window.location.href = `/cards/${cardId}/card_loads/${response.id}`;
      })
      .catch((error) => {
        console.log(error);
        const errors = setApiErrors(error?.data?.errors, validationErrors);

        this.setState({ validationErrors: errors, submitting: false });

        toastr.error("Something went wrong");
      });
  };

  render() {
    const { amount, fee, total, validationErrors, submitting, submitRequest } =
      this.state;

    return (
      <form
        className="form-horizontal transfer-form"
        id="new_crypto_transfer"
        style={{ maxWidth: '800px' }}
      >
        <div className="w-100">
          <div className="col-12">
            <div className="form-group row required card-amount-form">
              <label className="form-label">Amount</label>
              <div className={`input-group mb-2 mr-sm-2 ${validationErrors.amountError ? "is-invalid" : ""}`}>
                <div className="input-group-prepend">
                  <div
                    className="input-group-text font-weight-bold"
                  >
                    USD <CurrencyFlag className="ml-1" currency="USD" />
                  </div>
                </div>
                <input
                  autoFocus={true}
                  className={`form-control`}
                  placeholder="0.000"
                  type="text"
                  value={amount}
                  id="amount"
                  disabled={submitting}
                  onChange={this.handleAmountChange}
                />
              </div>
              {validationErrors?.amountError && (
                <small className="error text-danger">
                  {validationErrors.amountError}
                </small>
              )}
            </div>
          </div>
        </div>

        <div>
          <hr />
          <div className="preview-list w-100 d-flex flex-row justify-content-around bg-gray rounded-lg p-3">
            <div className="d-flex flex-column justify-content-center align-items-center" style={{ borderRight: "1px solid #ccc", width: '100%' }}>
              <span className="font-weight-bold">
                Amount
                <i className="fa fa-info-circle ml-1" data-toggle="tooltip" title="Amount to load"></i>
              </span>
              <span className="font-0_95">{amount}</span>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center" style={{ borderRight: "1px solid #ccc", width: '100%' }}>
              <span className="font-weight-bold">
                Fees
                <i className="fa fa-info-circle ml-1" data-toggle="tooltip" title="Processing fees"></i>
              </span>
              <span className="font-0_95">{fee}</span>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center" style={{ width: '100%' }}>
              <span className="font-weight-bold">
                Total
                <i className="fa fa-info-circle ml-1" data-toggle="tooltip" title="Total amount including fees"></i>
              </span>
              <span className="font-0_95">{total}</span>
            </div>
          </div>

          <hr />
        </div>
        <div className="form-actions">
          {submitRequest ? (
            <div className="mr-auto">
              <div className="col-md-6 float-md-right pr-0">
                <button
                  name="button"
                  type="submit"
                  className="btn btn-purple pull-right mb-3"
                  onClick={this.onSubmit}
                  disabled={submitting}
                >
                  {submitting ? (
                    <>
                      <i className="fa fa-circle-o-notch fa-spin"></i>
                      <span style={{ paddingLeft: "5px" }}>
                        Submitting...
                      </span>
                    </>
                  ) : (
                    <span>Submit</span>
                  )}
                </button>
              </div>
              <div className="col-md-6 float-md-left pl-0">
                <button
                  name="cancel"
                  type="submit"
                  className="btn btn-outline-purple pull-left mb-3"
                  onClick={this.cancel}
                >
                  Cancel <i className="fa fa-times ml-1"></i>
                </button>
              </div>
            </div>
          ) : (
            <div className="mr-auto">
              <div className="col-md-6 float-md-right pr-0">
                <button
                  name="button"
                  type="submit"
                  className="btn btn-purple pull-right mb-2 col-xs-4"
                  onClick={this.preview}
                  disabled={submitting}
                >
                  {submitting ? (
                    <>
                      <i className="fa fa-circle-o-notch fa-spin mr-1"></i>
                      <span style={{ paddingLeft: "5px" }}>Loading...</span>
                    </>
                  ) : (
                    <span>Preview <i className="fa fa-eye ml-1"></i></span>
                  )}
                </button>
              </div>
              <div className="col-md-6 float-md-left pl-0">
                <button
                  name="start_again"
                  type="submit"
                  className="btn btn-outline-purple pull-left mb-2"
                  onClick={this.cancel}
                >
                  Start Again <i className="fa fa-undo ml-1"></i>
                </button>
              </div>
            </div>
          )}
        </div>
      </form>
    );
  }
}

export default New;
