import countries from "../../../../../utils/countries.json";
import countriesPhoneCodes from "../../../../../utils/countryPhoneCodes.json";
export const titleOptions = [
  {
    value: "Mr.",
    label: "Mr.",
  },

  {
    value: "Mrs.",
    label: "Mrs.",
  },
  {
    value: "Ms.",
    label: "Ms.",
  },
];

export const genderOptions = [
  {
    value: "male",
    label: "Male",
  },
  {
    value: "female",
    label: "Female",
  },
];

export const maritalOptions = [
  {
    value: "single",
    label: "Single",
  },
  {
    value: "married",
    label: "Married",
  },
];

export const idTypeOptions = [
  {
    value: "passport",
    label: "International Passport",
  },
];

export const cardNetworkOptions = [
  {
    value: "visa",
    label: "Visa",
  },
];

export const countryOptions = countries
  .map((country) => {
    return {
      value: country.code2,
      label: country.name,
    };
  });

export const dialingCodeOptions = countriesPhoneCodes
  .map((country) => {
    return {
      value: `+${country.code}`,
      label: `+${country.code} (${country.country})`
    }
  });

let indexOfStepUpper = 0;
export const canNavigate = (currentStep, step) => {
  let allSteps = ["personal", "identification", "mailing", "preview"]
  let currentStepIndex = allSteps.indexOf(currentStep)
  if(currentStepIndex >= indexOfStepUpper){
    indexOfStepUpper = currentStepIndex
  }
  let targetStepIndex = allSteps.indexOf(step)
  return targetStepIndex <= indexOfStepUpper
}

export const isCompleted = (currentStep, step) => {
  let allSteps = ["personal", "identification", "mailing", "preview"]
  let currentStepIndex = allSteps.indexOf(currentStep)
  let targetStepIndex = allSteps.indexOf(step)
  return targetStepIndex < currentStepIndex
}