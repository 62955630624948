import React, { useState, useEffect } from "react";
import { patchRequest } from "../../utils/httpRequest";

import "./index.scss";
import {
  IconArrowUpRight,
  IconBellPurple,
  IconNotificationNonePurple,
  IconNotificationPurple,
  IconNotificationPurpleFilled,
} from "../Dashboard/dashboardIcons";

const NotificationPopup = (props) => {
  let notificationList = props.notificationList.slice(0, 5);
  let notificationCount = props.notificationCount;

  const [setNotificationList] = useState([notificationList]);
  const [setNotificationCount] = useState(notificationCount);

  useEffect(() => {}, []);

  const markAsRead = (id, url) => {
    patchRequest(`/users/notifications/read`, {
      notification_ids: id,
    })
      .then((response) => {
        window.location.href = url;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="notificationList wrapper">
      <div className="">
        <a
          className="dropdown-toggle notification-dp"
          href="javascript:void(0)"
          id="notificationMenuLink"
          data-toggle="dropdown"
        >
          {/* <i className="material-icons font-1_0" style={{color:"#5256A3"}} id="note-ico">notifications</i> */}
          {notificationCount > 0 ? (
            <IconNotificationPurpleFilled />
          ) : (
            <IconNotificationPurple />
          )}

          <span className="badge across-badge rounded-pill badge-notification bg-danger text-white">
            {notificationCount}
          </span>
        </a>
        <ul
          className="dropdown-menu dropdown-menu-right shadow-sm rounded-lg pb-0"
          aria-labelledby="notificationMenuLink"
          style={{ minWidth: "300px", border: "2px solid #e7e7e7" }}
        >
          {notificationList.map((notification) => {
            if (!notification.params?.url) return null;
            return (
              <li key={notification.id} className="cursor-pointer">
                <a
                  className="dropdown-item px-2 d-flex flex-row align-items-center justify-content-between"
                  onClick={() =>
                    markAsRead(notification.id, notification.params?.url)
                  }
                >
                  <div className="d-flex flex-row align-items-center mr-2">
                    <IconBellPurple className="mr-2" />{" "}
                    <div>
                      {notification.params?.title}
                      <br />{" "}
                      <small className="text-wrap flex-wrap">
                        {notification.params?.message}
                      </small>
                    </div>{" "}
                  </div>
                  <IconArrowUpRight className="float-right mr-2" />
                </a>
              </li>
            );
          })}

          <br></br>
          {notificationList.length == 0 && (
            <li className="px-3 pb-2">
              <div className="d-flex justify-content-center align-items-center py-3">
                <IconNotificationNonePurple />
              </div>
              Currently you do not have any unread notifications
            </li>
          )}
          <li>
            <a
              className="dropdown-item text-center border border-top view-all-btn"
              href="/users/notifications"
            >
              View all <IconArrowUpRight className="ml-2" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default NotificationPopup;
