import React, { useState } from "react";
import {
  Formik,
  Form,
  Field,
  FormikHelpers,
  FieldArray,
  ErrorMessage
} from "formik";
import { required } from "./validations";
import { postRequest, putRequest } from "../../utils/httpRequest";
import { formErrors } from "../../utils/formErrors";

const WooCommerceForm = ({ store_id, integration }) => {
  const [creating, setCreating] = useState(false);
  const [errors, setErrors] = useState({});

  const formattedErrors = errors => {
    const { validationErrors } = this.state;

    const appNameError = errors["app_name"];
    const scopeError = errors["scope"];
    const userIdError = errors["user_id"];
    const returnUrlError = errors["return_url"];
    const callbackUrlError = errors["callback_url"];

    validationErrors.appNameError = appNameError;
    validationErrors.scopeError = scopeError;
    validationErrors.userIdError = userIdError;
    validationErrors.returnUrlError = returnUrlError;
    validationErrors.callbackUrlError = callbackUrlError;

    return validationErrors;
  };

  const submit = values => {
    console.log(integration);
    const crsfToken = $('meta[name="csrf-token"]').attr("content");

    const data = {
      store_integration: values
    };
    setCreating(true);

    if (integration) {
      putRequest(`/stores/${store_id}/integrations/${integration.id}`, data, {
        "X-CSRF-Token": crsfToken
      })
        .then(response => {
          window.location = `/stores/${store_id}/integrations`;
        })
        .catch(error => {
          const errors = formErrors(error.data.errors);

          setErrors(error);
          setCreating(false);
        });
    } else {
      postRequest(`/stores/${store_id}/integrations`, data, {
        "X-CSRF-Token": crsfToken
      })
        .then(response => {
          window.location = `/stores/${store_id}/integrations`;
        })
        .catch(error => {
          const errors = formErrors(error.data.errors);

          setErrors(error);
          setCreating(false);
        });
    }
  };

  return (
    <Formik
      initialValues={{
        app_name: integration?.app_name || "",
        scope: integration?.scope || "",
        user_id: integration?.user_id || "",
        return_url: integration?.return_url || "",
        callback_url: integration?.callback_url || "",
        integration_type: "WooCommerce API"
      }}
      onSubmit={values => submit(values)}
    >
      {({ values, touched, handleChange }) => (
        <Form className="form">
          <div className="form-group row required">
            <label htmlFor="app_name" className="form-label">
              App Name
            </label>
            <Field
              id="app_name"
              name="app_name"
              className="form-control"
              validate={required}
              onChange={handleChange}
            />
            <ErrorMessage name="app_name">
              {msg => <div className="error">{msg}</div>}
            </ErrorMessage>
            {errors?.appNameError && (
              <div className="error text-danger">
                {validationErrors.appNameError}
              </div>
            )}
          </div>
          <div className="form-group row required">
            <label htmlFor="scope" className="form-label">
              Scope
            </label>
            <Field
              id="scope"
              name="scope"
              className="form-control"
              validate={required}
              onChange={handleChange}
            />
            <ErrorMessage name="scope">
              {msg => <div className="error">{msg}</div>}
            </ErrorMessage>
            {errors?.scopeError && (
              <div className="error text-danger">
                {validationErrors.scopeError}
              </div>
            )}
          </div>
          <div className="form-group row required">
            <label htmlFor="grant_type" className="form-label">
              User ID
            </label>
            <Field
              id="user_id"
              name="user_id"
              className="form-control"
              validate={required}
              onChange={handleChange}
            />
            <ErrorMessage name="user_id">
              {msg => <div className="error">{msg}</div>}
            </ErrorMessage>
          </div>
          {errors?.userIdError && (
            <div className="error text-danger">
              {validationErrors.userIdError}
            </div>
          )}
          <div className="form-group row">
            <label htmlFor="return_url" className="form-label">
              Return Url
            </label>
            <Field
              id="return_url"
              name="return_url"
              className="form-control"
              validate={required}
              onChange={handleChange}
            />
            <ErrorMessage name="return_url">
              {msg => <div className="error">{msg}</div>}
            </ErrorMessage>
            {errors?.returnUrlError && (
              <div className="error text-danger">
                {validationErrors.returnUrlError}
              </div>
            )}
          </div>
          <div className="form-group row">
            <label htmlFor="callback_url" className="form-label">
              Callback Url
            </label>
            <Field
              id="callback_url"
              name="callback_url"
              className="form-control"
              validate={required}
              onChange={handleChange}
            />
            <ErrorMessage name="callback_url">
              {msg => <div className="error">{msg}</div>}
            </ErrorMessage>
            {errors?.callbackUrlError && (
              <div className="error text-danger">
                {validationErrors.callbackUrlError}
              </div>
            )}
          </div>
          <div className="action">
            <button
              type="submit"
              className="btn btn-primary left"
              disabled={creating}
            >
              {creating && (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              Submit
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default WooCommerceForm;
