import React, { Component } from "react";
import _ from "underscore";
import { getRequest } from "../../utils/httpRequest";
import SelectCurrency from "../SelectCurrency";
import tokens from "../SelectToken/tokens.json";
import ReceiveModal from "./ReceiveModal";
import "./index.scss";

import paypalLogo from '../../../assets/images/payment_networks/paypal.png';
import cashappLogo from '../../../assets/images/payment_networks/cashapp.png';
import coinbaseLogo from '../../../assets/images/payment_networks/coinbase.png';
import binanceLogo from '../../../assets/images/payment_networks/binance.png';
import kucoinLogo from '../../../assets/images/payment_networks/kucoin.png';
import robinhoodLogo from '../../../assets/images/payment_networks/robinhood.png';

class AddFundsCrypto extends Component {
  constructor(props) {
    super(props);

    this.state = {
      balance: "",
      currencyToUSDT: "",
      tokenToCurrency: "",
      source: this.props.source || 'blockchain'
    };
  }

  componentDidMount() {
    this.exchangeCurrency(this.props.selectedToken);
  }

  exchangeCurrency = token => {
    getRequest(`/api/v1/currency_exchanges/convert?token=${token}`).then(
      response => {
        const { balance, currency_to_usdt, token_to_currency } = response;

        this.setState({
          balance,
          currencyToUSDT: currency_to_usdt,
          tokenToCurrency: token_to_currency
        });
      }
    );
  };

  showModal = () => {
    $("#tokenAddressModal").modal("show");
  };

  closeModal = () => {
    $("#tokenAddressModal").modal("hide");
  };

  render() {
    const {
      preferredCurrency,
      selectedToken,
      tokenAddress,
      tokenImage,
      chain
    } = this.props;

    const token = _.find(tokens, function(token) {
      return token.value === selectedToken;
    });

    const { balance, currencyToUSDT, tokenToCurrency } = this.state;

    return (
      <div
        className="add-funds-options pt-2 pb-0"
        style={{ maxWidth: "460px" }}
      >
        <div className="card card-mini card-mini-solid-icon">
          <div className="card-body">
            <div className="media">
              <div className="media-body text-center pt-2">
                <div className="row">
                  <div className="col-md-6 offset-6 pull-right mb-4">
                    <SelectCurrency
                      preferredCurrency={preferredCurrency}
                      refreshOnSave={true}
                      saveOnSelect={true}
                    />
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-md-12">
                    <h4 className="m-0 mt-3 mb-4 p-0 text-muted leading-normal font-1_3">
                      <img src={token?.image} width="30" height="30" /> {token?.label}
                    </h4>
                    <h2
                      className="m-0 counter text-default font-weight-normal"
                      style={{ fontSize: "3.25em" }}
                    >
                      {balance}
                    </h2>
                    <h5
                      id="currency-to-usdt"
                      className="m-0 counter text-center font-weight-bold font-1_3 pt-2"
                    >
                      {currencyToUSDT}
                    </h5>
                    {/* <h5 className="m-0 text-center font-weight-normal font-0_8 text-muted pt-2">
                      1 {selectedToken} = {tokenToCurrency}
                    </h5> */}
                  </div>
                </div>
                <div className="row mt-4 mb-4">
                  <div className="col-md-12">
                    <a
                      className="btn-primary text-center mt-4 ml-3 pt-3 pb-3 pl-2 pr-2"
                      style={{ width: "300px", maxWidth: "350px" }}
                      href={`/crypto_transfers/new_blockchain_to_usd_transfer?token=${selectedToken}`}
                    >
                      <i className="fa fa-exchange "></i> Convert {" "}
                      {selectedToken} to USD to Add Funds
                    </a>{" "}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mt-4">
                    <div
                      className="btn-group mt-4"
                      role="group"
                      aria-label="Send or Receive"
                    >
                      {/* <a
                        className="btn btn-link mr-0 pr-4 pl-4 ml-4 mt-4 mb-4"
                        title={`Buy ${token.name}`}
                        type="button"
                        href={`/trade?chain=${selectedToken}&token_name=${selectedToken ? selectedToken.toLowerCase() : ''}`}
                      >
                        <i className="fa fa-plus-circle "></i>
                        <span className="font-1_1 font-weight-normal">
                          {" "}
                          Buy {selectedToken}
                        </span>
                      </a>{" "} */}
                      <a
                        href="#"
                        role="button"
                        className="btn btn-link ml-0 mt-4 mb-4"
                        data-toggle="modal"
                        onClick={this.showModal}
                      >
                        <i className="fa fa-qrcode fa-2x "></i> Receive{" "}
                        {selectedToken}
                      </a>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-10 font-0_75 text-muted">
                    <p className="pl-3 pr-3 pt-0 mt-0">
                      {token.name} regularly fluctuates in value, so your{" "}
                      {token.name} may be worth more or less than at the time
                      you deposit or the time you receive it. To avoid
                      fluctuations, convert it to USD.
                    </p>
                  </div>
                </div>
                <div className="col-md-1"></div>
              </div>
            </div>
          </div>
        </div>
        <ReceiveModal
          token={token}
          tokenAddress={tokenAddress}
          closeModal={this.closeModal}
          tokenImage={tokenImage}
        />
      </div>
    );
  }
}

export default AddFundsCrypto;
