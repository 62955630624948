import React from "react";
import { ReactSVG } from "react-svg";
import SelectCurrency from "./SelectCurrency";
import BlockchainTransfer from "./BlockchainTransfer";
import WalletTransfer from "./WalletTransfer";
import Preview from "./Preview";
import Actions from "./Actions";

const CryptoForm = ({
  stateParams,
  propsParams,
  handleBlockchainAddressChange,
  handleAmountChange,
  showModal,
  handleChange,
  cancelTransfer,
  refreshPage,
  submitTransferPreview,
  submitTransfer,
  handleRecipientChange,
  handleSelectedCurrencyChange
}) => (
  <form
    className="form-horizontal transfer-form pl-2 pr-4"
    id="new_crypto_transfer"
  >
    <div className="amount-wrapper">
      <SelectCurrency
        type={propsParams.type}
        selectedCurrency={stateParams.selectedCurrency}
        currencies={propsParams.currencies}
        handleChange={handleChange}
        handleSelectedCurrencyChange={handleSelectedCurrencyChange}
      />
      <div className="form-group row required ">
        <label className="form-label">Amount</label>
        <div className="input-group mb-2 mr-sm-2">
          <div className="input-group-prepend">
            <div
              className="input-group-text selected-currency font-2_25"
              style={{ color: "#4173a7", focus: { color: "#414da7" } }}
            >
              {stateParams.selectedCurrency}
            </div>
          </div>
          <input
            step="any"
            autoFocus="autofocus"
            style={{ maxWidth: "400px" }}
            placeholder="0.000"
            className={`form-control font-2_25 ${
              stateParams.validationErrors?.amountError ? "is-invalid" : ""
            }`}
            required="required"
            type="number"
            value={stateParams.amount}
            onChange={e => handleAmountChange(e)}
            id="amount"
            disabled={stateParams.submitting == true ? true : false}
          />
        </div>
        {stateParams.error && (
          <span className="error text-danger">
            <br />
            {stateParams.error}
          </span>
        )}
        {stateParams.validationErrors?.amountError && (
          <span className="error text-danger">
            <br />
            {stateParams.validationErrors.amountError}
          </span>
        )}
        <small id="amountHelpBlock" className="form-text text-muted">
          Enter total transfer amount desired ({stateParams.selectedCurrency}).
        </small>
      </div>

      <div className="source-wallet-wrapper">
        <select
          className="border-0 mx-0 px-0"
          style={{
            color: "transparent",
            WebkitAppearance: "none",
            MozAppearance: "none"
          }}
          value={stateParams.electedSourceWallet}
          onChange={e => handleChange(e, "selectedSourceWallet")}
        >
          {propsParams.sourceWallets.map((wallet, i) => (
            <option value={wallet[1]} key={i}></option>
          ))}
        </select>
      </div>

      <div className="recipient-wrapper">
        {propsParams.type === "blockchain" && (
          <BlockchainTransfer
            blockchainAddress={stateParams.blockchainAddress}
            validationErrors={stateParams.validationErrors}
            selectedCurrency={stateParams.selectedCurrency}
            handleBlockchainAddressChange={handleBlockchainAddressChange}
            showModal={showModal}
          />
        )}
        {propsParams.type === "wallet" && (
          <WalletTransfer
            selectedTransferType={stateParams.selectedTransferType}
            submitting={stateParams.submitting}
            transferTypes={stateParams.transferTypes}
            destinationWallets={stateParams.destinationWallets}
            validationErrors={stateParams.validationErrors}
            recipient={stateParams.recipient}
            recipients={stateParams.recipients}
            lookedUpRecipient={stateParams.lookedUpRecipient}
            selectedDestinationWallet={stateParams.selectedDestinationWallet}
            handleChange={handleChange}
            handleRecipientChange={handleRecipientChange}
          />
        )}
      </div>
      <Preview
        amount={stateParams.amount}
        fees={stateParams.fees}
        netAmount={stateParams.netAmount}
        selectedCurrency={stateParams.selectedCurrency}
        firstTimeWithdrawalFee={stateParams.firstTimeWithdrawalFee}
        isFirstTimeWithdrawal={propsParams.isFirstTimeWithdrawal}
      />
      <div className="text-muted font-0_9 text-right">
        {stateParams.submitting ? (
          <div className="pt-4 mt-2">
            <i className="fa fa-exclamation-circle"></i> The quoted transfer
            fees are <strong>valid for the next 10 min</strong>. If your quote
            expires, simply start again. Click Submit to continue.
          </div>
        ) : (
          <div>
            <i className="fa fa-exclamation-circle"></i> Click Preview Transfer
            to preview your transfer including the applicable network fees.
          </div>
        )}
      </div>
      <hr />
      <Actions
        canSubmitTransfer={stateParams.canSubmitTransfer}
        submitting={stateParams.submitting}
        destinationAmount={stateParams.destinationAmount}
        transferId={stateParams.transferId}
        invalidTransfer={stateParams.invalidTransfer}
        cancelTransfer={cancelTransfer}
        refreshPage={refreshPage}
        submitTransfer={submitTransfer}
        submitTransferPreview={submitTransferPreview}
      />
    </div>
  </form>
);

export default CryptoForm;
