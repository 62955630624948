import React, { useState, useEffect } from "react";
import { getRequest, patchRequest } from "../../utils/httpRequest";
import "./index.scss";
import moment from "moment";
import ReactLoading from "react-loading";
import Pagination from "./Pagination";
import {
  IconBellPurple,
  IconNotificationListPurple,
  IconNotificationNonePurple,
} from "../Dashboard/dashboardIcons";

const NotificationItems = (props) => {
  const [notificationRead, setNotificationRead] = useState([]);
  const [notificationList, setNotificationList] = useState();

  const markAsRead = () => {
    patchRequest(`/users/notifications/read`, {
      notification_ids: notificationRead,
    })
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const markItemRead = (id, url) => {
    patchRequest(`/users/notifications/read`, {
      notification_ids: id,
    })
      .then((response) => {
        window.location.href = url;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const markAllAsRead = () => {
    props.setIsLoading(true);
    patchRequest(`/users/notifications/mark_all_as_read`)
      .then(() => window.location.reload())
      .catch((error) => {
        console.log(error);
      });
  };

  const checkNotification = (check, notificationId) => {
    if (check) {
      setNotificationRead([...notificationRead, notificationId]);
    } else {
      const temp = notificationRead.filter((item) => item !== notificationId);
      setNotificationRead(temp);
    }
  };

  const checkAll = (check) => {
    if (check) {
      setNotificationRead(notificationList.map((item) => item.id));
    } else {
      setNotificationRead([]);
    }
  };

  const transformTitle = (title) => {
    return title?.replace("_", " ");
  };

  useEffect(() => {
    setNotificationList(props.notificationList);
  }, [props.notificationList]);

  return (
    <div className="box shadow-sm rounded-lg mt-3 bg-white mb-3">
      <div className="box-title border-bottom p-3 d-flex align-items-center justify-content-between flex-wrap">
        <div className="d-flex align-items-center">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              id="selectAll"
              className="custom-control-input "
              onChange={(e) => {
                checkAll(e.target.checked);
              }}
            />
            <label
              className="custom-control-label font-0_85 text-muted"
              for="selectAll"
            ></label>
          </div>
          <div className="d-flex align-items-center ml-2">
            <IconNotificationListPurple className="mr-1" />
            <h4 className="m-0 font-weight-bold">Notifications</h4>
          </div>
        </div>

        <div className="mt-3 mt-md-0 ml-4 ml-md-0">
          <button
            style={{ marginLeft: "15px" }}
            className="float-right btn btn-purple btn-sm"
            onClick={(e) => markAllAsRead()}
          >
            Mark all as read
          </button>
          <button
            className="float-right btn btn-purple btn-sm"
            onClick={(e) => markAsRead()}
          >
            Mark as read
          </button>
        </div>
      </div>
      <div className="box-body p-0">
        {notificationList?.length > 0 ? (
          notificationList.map((notification, index) => {
            if (!notification.params.url) return null;

            return (
              <div
                key={notification.id}
                className={`p-3 d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center  no-wrap ${
                  !notification.read_at ? "bg-light" : ""
                } border-bottom osahan-post-header ${
                  notificationRead.includes(notification.id) && "bg-purple"
                }`}
              >
                <div className="d-flex align-items-center font-weight-bold">
                  <div className="d-flex align-items-center">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        id={notification.id}
                        className="custom-control-input "
                        checked={notificationRead.includes(notification.id)}
                        onChange={(e) => {
                          checkNotification(e.target.checked, notification.id);
                        }}
                      />
                      <label
                        className="custom-control-label font-0_85 text-muted"
                        for={notification.id}
                      ></label>
                    </div>
                  </div>
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      markItemRead(notification.id, notification.params.url);
                    }}
                    href={notification.params.url}
                  >
                    <div className="px-2">
                      <div className="d-flex align-items-center">
                        <IconBellPurple
                          className="mr-1 mr-md-2 flex-shrink-0"
                          height="2rem"
                          width="2rem"
                        />
                        <div>
                          <div>{transformTitle(notification.params.title)}</div>
                          <div className="small">
                            {transformTitle(notification.params.message)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="text-muted font-0_9 ml-5 ml-md-0">
                  {moment.utc(notification?.created_at).local().format("lll")}
                </div>
              </div>
            );
          })
        ) : (
          <div className="d-flex flex-column justify-content-center align-items-center py-5">
            <IconNotificationNonePurple />
            <p> You don't have any notifications yet.</p>
          </div>
        )}
      </div>
    </div>
  );
};

const NotificationList = (props) => {
  const [notificationList, setNotificationList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);

  const getNotifications = () => {
    setIsLoading(true);
    getRequest(`/users/notifications/list?page=${currentPage}`).then(
      (response) => {
        updateNotifications(response);
      }
    );
  };

  const updateNotifications = (response) => {
    setNotificationList(response.notifications);
    setTotalPages(response.total_pages);
    setIsLoading(false);
  };

  const loadMoreData = (e, selectedPage) => {
    e.preventDefault();
    const page = selectedPage || currentPage + 1;
    setCurrentPage(page);
  };

  useEffect(() => {
    getNotifications();
  }, [currentPage]);

  return (
    <>
      <div className="w-100">
        {isLoading && (
          <div className="loading">
            <ReactLoading
              type="spin"
              color="#4c3f84"
              height={30}
              width={30}
              className="loader mx-auto"
            />
          </div>
        )}

        <NotificationItems
          notificationList={notificationList}
          updateNotifications={updateNotifications}
          setIsLoading={setIsLoading}
        />
        {totalPages > 1 && (
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={loadMoreData}
          />
        )}
      </div>
    </>
  );
};

export default NotificationList;
