import React, { useState } from "react";
import Bclogoicon from "../../../../../../assets/images/bclogoicon.gif";

const AccountModal = ({ account, handleShowAccountModal }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = (text, field) => {
    navigator.clipboard.writeText(text);
    setCopied(field);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div
      className="modal "
      tabIndex="-1"
      role="dialog"
      style={{ display: "block" }}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <button
            type="button"
            className="bankaccountspage-modal-close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={e => handleShowAccountModal(e, false)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <div className="modal-header">
            <h5 className="bankaccountspage-modal-title" id="showAccountModal">
              <img src={Bclogoicon} width="20" />
              {" "} Bank Account Details
            </h5>
          </div>
          <div className="modal-body" style={{ height: "auto" }}>
            <div className="bankaccountspage-modal-detailsbody">
              <div className="bankaccountspage-modal-details">
                <label className="bankaccountspage-modal-labels">Account Title</label>
                <div className="bankaccountspage-modal-values">
                  <span>{account.accountHolderName}</span>
                </div>
              </div>
              {account.bankName && account.bankName !== "Other" && (
              <div className="bankaccountspage-modal-details">
                <label className="bankaccountspage-modal-labels">Bank Name</label>
                <div className="bankaccountspage-modal-values">
                  <span>{account.bankName}</span>
                </div>
              </div>)}
              {account.customBankDetails && (
                <div className="bankaccountspage-modal-details">
                  <label className="bankaccountspage-modal-labels">Bank Name</label>
                  <div className="bankaccountspage-modal-values">
                    <span>{account.customBankDetails}</span>
                  </div>
                </div>
              )}

              {!account.ibanNumber && (
                  <div className="bankaccountspage-modal-details">
                  <label className="bankaccountspage-modal-labels">Account Number</label>
                  <div className="bankaccountspage-modal-values">
                    <span>{account.accountNumber}</span>
                    <button onClick={() => handleCopy(account.accountNumber, "accountNumber")} className="bankaccountspage-copybtn">
                      <i className={`fas fa-${copied === "accountNumber" ? "check" : "copy"}`} title={`${copied ? "Copied" : "Copy"}`}></i>
                    </button>
                  </div>
                </div>
                )
              }
              {account.routingNumber != '' && account.routingNumber && <div className="bankaccountspage-modal-details">
                <label className="bankaccountspage-modal-labels">Routing Number/Transit No/Branch Code</label>
                <div className="bankaccountspage-modal-values">
                  <span>{account.routingNumber}</span>
                  <button onClick={() => handleCopy(account.routingNumber, "routingNumber")} className="bankaccountspage-copybtn">
                    <i className={`fas fa-${copied === "routingNumber" ? "check" : "copy"}`} title={`${copied ? "Copied" : "Copy"}`}></i>
                  </button>
                </div>
              </div>}
              {account.interacEmail != '' && account.interacEmail && <div className="bankaccountspage-modal-details">
                <label className="bankaccountspage-modal-labels">Interac e-transfer Email</label>
                <div className="bankaccountspage-modal-values">
                  <span>{account.interacEmail}</span>
                  <button onClick={() => handleCopy(account.interacEmail, "interacEmail")} className="bankaccountspage-copybtn">
                    <i className={`fas fa-${copied === "interacEmail" ? "check" : "copy"}`} title={`${copied ? "Copied" : "Copy"}`}></i>
                  </button>
                </div>
              </div>}
              {account.ibanNumber != '' && account.ibanNumber && <div className="bankaccountspage-modal-details">
                <label className="bankaccountspage-modal-labels">IBAN</label>
                <div className="bankaccountspage-modal-values">
                  <span>{account.ibanNumber}</span>
                  <button onClick={() => handleCopy(account.ibanNumber, "ibanNumber")} className="bankaccountspage-copybtn">
                    <i className={`fas fa-${copied === "ibanNumber" ? "check" : "copy"}`} title={`${copied ? "Copied" : "Copy"}`}></i>
                  </button>
                </div>
              </div>}
              {account.swiftCode != '' && account.swiftCode && <div className="bankaccountspage-modal-details">
                <label className="bankaccountspage-modal-labels">Swift Code</label>
                <div className="bankaccountspage-modal-values">
                  <span>{account.swiftCode}</span>
                  <button onClick={() => handleCopy(account.swiftCode, "swiftCode")} className="bankaccountspage-copybtn">
                    <i className={`fas fa-${copied === "swiftCode" ? "check" : "copy"}`} title={`${copied ? "Copied" : "Copy"}`}></i>
                  </button>
                </div>
              </div>}
              {account.fiatCurrency && <div className="bankaccountspage-modal-details">
                <label className="bankaccountspage-modal-labels">Currency</label>
                <div className="bankaccountspage-modal-values">
                  <span>{account.fiatCurrency.name} ({account.fiatCurrency.code})</span>
                </div>
              </div>}
              {account.address != '' && account.address && <div className="bankaccountspage-modal-details">
                <label className="bankaccountspage-modal-labels">Address</label>
                <div className="bankaccountspage-modal-values">
                  <span>{account.address}</span>
                </div>
              </div>}
              {account.state != '' && <div className="bankaccountspage-modal-details">
                <label className="bankaccountspage-modal-labels">State</label>
                <div className="bankaccountspage-modal-values">
                  <span>{account.state}</span>
                </div>
              </div>}
              {account.city != '' && <div className="bankaccountspage-modal-details">
                <label className="bankaccountspage-modal-labels">City</label>
                <div className="bankaccountspage-modal-values">
                  <span>{account.city}</span>
                </div>
              </div>}
              {account.country && account.country.name && account.country.name != '' && <div className="bankaccountspage-modal-details">
                <label className="bankaccountspage-modal-labels">Country</label>
                <div className="bankaccountspage-modal-values">
                  <span>{account.country.name}</span>
                </div>
              </div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountModal;
