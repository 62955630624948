import React, { useState } from "react";
import {
  ArrowTopRight,
  HideIcon,
  ShowIconEye,
  PlusIcon,
  TradeIcon,
} from "../dashboardIcons";
import CurrencySelect from "../CurrencySelect";
import CryptoSelect from "../CryptoSelect";
import { cryptoBalance } from "../helpers";

const BalanceCard = ({
  title,
  id,
  alt_currency,
  showBalance,
  setShowBalance,
  balance,
  balances,
  alt_balance,
  links,
}) => {
  const [preferredCrypto, setPreferredCrypto] = useState(() => {
    return localStorage.getItem("preferred_crypto") || "OPAIG";
  });

  const updatePreferredCrypto = (crypto) => {
    setPreferredCrypto(crypto);
    localStorage.setItem("preferred_crypto", crypto);
  };

  return (
    <div className={`dashboard-card ${id}`} id={id} key={id}>
      <div className="dashboard-card-header">
        <span
          className="dashboard-card-title"
          title="This is your available balance"
        >
          {title}
        </span>
        <div className="dashboard-card-percentage">
          {alt_currency &&
            (id === "dashboard_card_crypto" ? (
              <CryptoSelect
                preferredCurrency={preferredCrypto}
                updatePreferredCrypto={updatePreferredCrypto}
              />
            ) : (
              <CurrencySelect preferredCurrency={alt_currency} />
            ))}
          {showBalance ? (
            <HideIcon title="Hide balance" onClick={setShowBalance} />
          ) : (
            <ShowIconEye title="Show balance" onClick={setShowBalance} />
          )}
        </div>
      </div>
      <div className="dashboard-card-amount">
        <h4 className="font-weight-bold font-0_85">
          {balance || balances ? (
            showBalance ? (
              id === "dashboard_card_crypto" ? (
                cryptoBalance(
                  "crypto_balance",
                  balances,
                  preferredCrypto,
                  alt_currency
                )
              ) : (
                balance
              )
            ) : (
              "*****"
            )
          ) : (
            <div className="loading-skeleton"></div>
          )}
        </h4>
        <h4 className="alternate-currency">
          {alt_balance || balances ? (
            showBalance ? (
              id === "dashboard_card_crypto" ? (
                <div>
                  <span>
                    {cryptoBalance(
                    "usd_balance",
                    balances,
                    preferredCrypto,
                    alt_currency
                  )}
                  </span> / 
                  {" "}<span className="font-0_8">
                    {cryptoBalance(
                    "alt_balance",
                    balances,
                    preferredCrypto,
                    alt_currency
                  )}
                  </span>
                
                </div>
              ) : (
                alt_balance
              )
            ) : (
              "******"
            )
          ) : (
            <div className="loading-skeleton loading-skeleton-long"></div>
          )}
        </h4>
      </div>
      <div className="dashboard-card-bottom">
        {links.map((link, i) => {
          return (
            <a className="dashboard-badge" href={link.link}>
              {link.icon}
              <span className="text-center">{link.title}</span>
            </a>
          );
        })}
      </div>
      <div className="dashboard-card-bg"></div>
    </div>
  );
};

export default BalanceCard;
