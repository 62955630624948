import React from "react";

const Actions = ({
  canSubmitTransfer,
  transferId,
  invalidTransfer,
  cancelTransfer,
  destinationAmount,
  submitting,
  submitTransfer,
  submitTransferPreview,
  refreshPage,
}) => (
  <div className="form-actions">
    {canSubmitTransfer && transferId ? (
      <div className="mr-auto">
        <div className="col-md-6 float-md-right pr-0">
          <button
            name="button"
            type="submit"
            className="btn btn-purple pull-right ml-4 mb-4"
            onClick={(e) => submitTransfer(e)}
            disabled={invalidTransfer}
          >
            Submit <i className="fa fa-arrow-right"></i>
          </button>
        </div>
        <div className="col-md-6 float-md-left pl-0">
          <button
            name="cancel"
            type="submit"
            className="btn pull-left ml-4 mb-4"
            onClick={(e) => cancelTransfer(e)}
          >
            Cancel
          </button>
        </div>
      </div>
    ) : (
      <div className="mr-auto">
        <div className="col-md-6 float-md-right pr-0">
          <button
            name="button"
            type="submit"
            className="btn btn-purple pull-right mb-2 col-xs-4"
            onClick={(e) => submitTransferPreview(e)}
            disabled={invalidTransfer || submitting}
          >
            {submitting ? (
              <>
                <i className="fa fa-circle-o-notch fa-spin"></i>
                <span style={{ paddingLeft: "5px" }}>
                  Please Wait...Loading
                </span>
              </>
            ) : (
              <span>
                Preview Transfer <i className="fa fa-arrow-right"></i>
              </span>
            )}
          </button>
        </div>
        <div className="col-md-6 float-md-left pl-0">
          <button
            name="start_again"
            type="submit"
            className="btn pull-left mb-2"
            onClick={(e) => refreshPage(e)}
          >
            Start Again
          </button>
        </div>
      </div>
    )}
  </div>
);

export default Actions;
