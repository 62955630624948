import * as openpgp from 'openpgp';
// import * as openpgp from 'openpgp/lightweight';
// using v5 https://github.com/openpgpjs/openpgpjs/wiki/V5-Changelog/cae6dca10b81d7850f8854735e816aca5bc01162
// provider uses v4, but v4 does not load, using 5.0.0.-1


export async function encryptText(text, key) {
  let encrypted;
  const message = openpgp.Message.fromText(text);
  let decodedPublicKey = atob(key.publicKey);

  const publicKey = await openpgp.readKey({ armoredKey: decodedPublicKey })
  if (publicKey) {
    // configure to show version and comment (as in v4)
    openpgp.config.showVersion = true;
    // this should match the version expected by the third party card processor
    openpgp.config.versionString = "OpenPGP.js v4.10.4";
    openpgp.config.showComment = true;
    openpgp.config.commentString = "https://openpgpjs.org"

    encrypted = await openpgp.encrypt({
      publicKeys: publicKey,
      message: message
    });
  }
    return encrypted;
}
