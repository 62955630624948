const stepOneAttributes = [
  "name",
  "business_name",
  "store_username",
  "hear_about_us",
  "industry"
];

const stepTwoAttributes = [
  "store_support_email",
  "store_payment_email",
  "store_address",
  "store_url",
  "phone"
];

export const formattedApiErrors = errors => {
  const apiErrors = {};

  const nameError = errors["name"];
  const storeAddressError = errors["store_address"];
  const storeUserNameError = errors["store_username"];
  const storeSupportEmailError = errors["store_support_email"];
  const storePaymentEmailError = errors["store_payment_email"];
  const storeUrlError = errors["store_url"];
  const businessNameError = errors["business_name"];
  const hearAboutUsError = errors["hear_about_us"];
  const industryError = errors["industry"];
  const phoneError = errors["phone"];

  apiErrors.nameError = nameError;
  apiErrors.storeAddressError = storeAddressError;
  apiErrors.storeUserNameError = storeUserNameError;
  apiErrors.storeSupportEmailError = storeSupportEmailError;
  apiErrors.storePaymentEmailError = storePaymentEmailError;
  apiErrors.storeUrlError = storeUrlError;
  apiErrors.businessNameError = businessNameError;
  apiErrors.hearAboutUsError = hearAboutUsError;
  apiErrors.industryError = industryError;
  apiErrors.phoneError = phoneError;

  return apiErrors;
};

export const stepFromError = errors => {
  const attributes = errors.map(err => err.attribute);
  console.log(attributes);

  if (attributes.some(attr => stepOneAttributes.includes(attr))) {
    return "one";
  } else if (attributes.some(attr => stepTwoAttributes.includes(attr))) {
    return "two";
  }

  return "three";
};
