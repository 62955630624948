import React, { useState } from "react";
import { feedback } from "./helper";
import AddressModal from "./AddressModal";

const VerificationStatus = ({
  legacy,
  verification_started,
  active_verification,
  verification_accepted,
  verificationPath,
  startVerificationPath,
  isAddressMissing,
  user_kyc_detail,
  ...props
}) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [canStartVerification, setCanStartVerification] = useState(
    user_kyc_detail
  );
  const openModal = (e) => {
    e.preventDefault();

    setIsOpen(true);
  };
  const closeModal = () => setIsOpen(false);

  const isPendingVerification = !!(legacy || verification_started);

  return (
    <>
      <div className="verification-container">
        <div className="verification-step active">
          <div className="verification-circle"></div>
          <div className="step-content">
            <div className="step-title">Start My Account Verification</div>
            <div className="step-description">
              Begin the verification process by providing your basic
              information. This step ensures we have the correct details to
              proceed with your document submission.
            </div>
            {!canStartVerification && (
              <a
                role="button"
                href="#"
                className="btn btn-primary btn-large verify-btn mt-4"
                onClick={(e) => openModal(e)}
              >
              Add Basic Details <i className="fa fa-arrow-right"></i>
              </a>
            )}
            {!active_verification && canStartVerification && (
              <a
                role="button"
                href={startVerificationPath}
                className="btn btn-primary btn-large verify-btn mt-4"
              >
                Verify Account <i className="fa fa-arrow-right"></i>
              </a>
            )}
          </div>
          <div
            className={`vertical-line ${
              isPendingVerification && "verification-active-item"
            }`}
          ></div>
        </div>
        <div className="verification-step">
          <div
            className={`verification-circle ${
              isPendingVerification && "verification-active-item"
            }`}
          ></div>
          <div className="step-content">
            <div className="step-title">Submit Document</div>
            <div className="step-description">
              Upload the required documents for verification. This may include
              government-issued ID, proof of address, or other relevant
              paperwork. Ensure all documents are clear and legible.
            </div>
            {feedback(props)}
            {!verification_accepted &&
              (legacy || verification_started) &&
              canStartVerification && (
                <a
                  role="button"
                  href={verificationPath}
                  className="btn btn-primary btn-large verify-btn mt-1"
                >
                  Go to My Account Verification{" "}
                  <i className="fa fa-arrow-right"></i>
                </a>
              )}
          </div>
          <div
            className={`vertical-line ${
              verification_accepted && "verification-active-item"
            }`}
          ></div>
        </div>
        <div className="verification-step">
          <div
            className={`verification-circle ${
              verification_accepted && "verification-active-item"
            }`}
          ></div>
          <div className="step-content">
            <div className="step-title">Documents Submitted</div>
            <div className="step-description">
              Your documents have been successfully submitted for review. Our
              team will verify the information provided. You'll be notified once
              the verification process is complete.
            </div>
            {verification_accepted && (
              <>
                <div className="alert alert-success verification-alert verification-alert-success mt-4">
                  <i className="fa fa-info-circle"></i> Your verification
                  application has been accepted and you are now verified.
                </div>
                <a
                  role="button"
                  href="/add_funds"
                  className="btn btn-primary btn-large verify-btn mt-1"
                >
                  <i className="fa fa-plus"></i> Add Funds
                </a>
              </>
            )}
          </div>
        </div>
      </div>
      <AddressModal
        isOpen={modalIsOpen}
        closeModal={closeModal}
        verificationId={active_verification?.id}
        setCanStartVerification={setCanStartVerification}
        kycDetail={user_kyc_detail}
      />
    </>
  );
};

export default VerificationStatus;
