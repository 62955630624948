import React, { Component } from "react";
import _, { identity } from "underscore";
import { getRequest, postRequest, patchRequest } from "../../utils/httpRequest";
import ReactTooltip from "react-tooltip";
import { ReactSVG } from "react-svg";
import Countdown from "react-countdown";
import { formErrors } from "../../utils/formErrors";

const transferTypes = [
  {
    label: "Recipient's Wallet",
    value: "recipient"
  },
  {
    label: "My Wallet",
    value: "my-wallet"
  }
];

class CryptoTransferToUsdc extends Component {
  constructor(props) {
    super(props);

    this.state = {
      totalTypes: props.totalTypes,
      selectedTotalType: props.totalType,
      selectedSourceWallet: props.selectedSourceWallet,
      selectedCurrency: props.sourceCurrency,
      selectedCurrencyBalance: props.sourceCurrencyBalance,
      selectedCurrencyDescription: props.sourceCurrencyDescription,
      selectedCurrencyImgSrc: props.sourceCurrencyImgSrc,
      selectedTransferType: "recipient",

      destinationCurrency: props.destinationCurrency,
      destinationWallets: "",
      selectedDestinationWallet: props.selectedDestinationWallet,
      destinationCurrencyImgSrc: props.destinationCurrencyImgSrc,

      // destinationWires: props.destinationWires,
      // selectedDestinationWire: "",
      recipient: props.recipient || "",

      blockchainAddress: this.props.blockchainAddress,
      walletAddresses: props.walletAddresses || [],
      selectedWalletAddress: "",

      sourceAmount: 0.0,
      destinationAmount: "TBD",
      fees: 0.0,
      exchangeRate: props.exchangeRate,
      note: "", // user message or note
      recipientError: "",
      error: "",
      sourceAmountError: "",
      mode: "form",
      invalidTransfer: false,
      submitting: false,
      validationErrors: {},

      submitTransfer: false
    };

    //console.log('props', props);
  }

  componentDidMount() {}

  // getChainWalletAddress = chain => {
  //   getRequest(`/crypto_transfers/chain_wallet_addresses?chain=${chain}`).then(
  //     addresses => {
  //       const walletAddresses = addresses.map(address => {
  //         return {
  //           label: address.wallet_label,
  //           address: address.address,
  //           value: address.id
  //         };
  //       });

  //       this.setState({ walletAddresses });
  //     }
  //   );
  // };

  handleChange = (event, key) => {
    this.setState({ [key]: event.target.value });

    if (key === "selectedTransferType") {
      this.setState({ invalidTransfer: false });
    }
  };

  handleTotalTypeChange = (e, key) => {
    this.setState({ selectedTotalType: e.target.key });
  };

  handleSourceAmountChange = e => {
    this.setState({ sourceAmount: e.target.value }, () =>
      this.validateSourceAmount()
    );

    if (this.state.submitting) {
      this.setState({
        destinationAmount:
          Math.round((sourceAmount * exchangeRate + Number.EPSILON) * 100) / 100
      });
    } else {
      this.setState({ destinationAmount: "TBD" });
    }
  };

  handleNoteChange = e => {
    this.setState({ note: e.target.value });
  };

  validateSourceAmount = () => {
    const { validationErrors } = this.state;
    this.setState({ error: "" });

    if (Number(this.state.sourceAmount) <= 0) {
      validationErrors.sourceAmountError =
        "Amount to convert cannot be zero, please enter a higher amount.";

      this.setState({
        validationErrors,
        invalidTransfer: true
      });
    } else if (
      Number(this.state.sourceAmount) >
      Number(this.state.selectedCurrencyBalance)
    ) {
      validationErrors.sourceAmountError =
        "Amount to convert is greater than your available balance, please enter a lower amount.";

      this.setState({
        validationErrors,
        invalidTransfer: true
      });
    } else {
      validationErrors.sourceAmountError = "";
      this.setState({
        validationErrors,
        invalidTransfer: false
      });
    }
  };

  validateFormFields = () => {
    const {
      sourceAmount,
      destinationAmount,
      recipient,
      selectedTransferType,
      blockchainAddress
    } = this.state;
    const { type } = this.props;

    let validationErrors = {};

    if (
      type === "wallet" &&
      selectedTransferType === "recipient" &&
      !recipient
    ) {
      validationErrors.recipientError = "recipient is required";
    }

    if (type === "blockchain" && !blockchainAddress) {
      validationErrors.blockchainAddressError =
        "blockchain address is required";
    }

    return validationErrors;
  };

  countdownRenderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return (
        <span>
          Your transaction quote has expired.{" "}
          <a
            href={`/crypto_transfers/new_blockchain_to_usd_transfer?token=${sourceCurrency}`}
          >
            Try again
          </a>
        </span>
      );
    } else {
      // Render a countdown
      return (
        <span>
          You have {minutes}:{seconds} to confirm this transaction
        </span>
      );
    }
  };

  refreshPage = e => {
    e.preventDefault();
    const url = window.location.href;
    window.location.assign(url);
  };

  setApiErrors = errors => {
    const formattedErrors = formErrors(errors);

    const { validationErrors } = this.state;

    const amountError = formattedErrors["gross_amount"];
    const recipientError = formattedErrors["destination_wallet_id"];

    validationErrors.amountError = amountError;
    validationErrors.recipientError = recipientError;

    return validationErrors;
  };

  submitTransferPreview = e => {
    const {
      selectedTotalType,
      selectedSourceWallet,
      selectedDestinationWallet,
      destinationWallets,
      destinationCurrency,
      recipient,
      sourceAmount,
      destinationAmount,
      exchangeRate,
      selectedCurrency,
      selectedTransferType,
      blockchainAddress,
      note,
      selectedCurrencyBalance
    } = this.state;

    e.preventDefault();

    const validationErrors = this.validateFormFields();

    if (!_.isEmpty(validationErrors)) {
      this.setState({ validationErrors, invalidTransfer: true });

      return;
    }

    this.setState({ submitting: true, error: "", validationErrors: {} });

    const data = {
      crypto_transfer: {
        total_type: selectedTotalType,
        source_wallet_id: selectedSourceWallet,
        recipient: selectedTransferType === "recipient" ? recipient : "",
        destination_wallet_id: selectedDestinationWallet,
        gross_amount: sourceAmount, // sourceAmount
        net_amount: sourceAmount, // temporary net_amount
        source_currency: selectedCurrency,
        destination_currency: destinationCurrency,
        destination_type: this.props.type,
        blockchain_address: blockchainAddress,
        note: note,
        selected_currency_balance: selectedCurrencyBalance
      }
    };

    const crsfToken = $('meta[name="csrf-token"]').attr("content");

    postRequest("/crypto_transfers", data, {
      "X-CSRF-Token": crsfToken
    })
      .then(response => {
        //console.log(response)

        if (response.external_status === "error" && response.failure_reason) {
          if (response.failure_reason == "Insufficient funds") {
            this.setState({
              error:
                "Insufficient funds, please enter a lower amount to cover the transaction and/or network fees.",
              submitting: false
            });
          } else {
            this.setState({
              error: response.failure_reason,
              submitting: false
            });
          }
        } else {
          this.setState({
            exchangeRate: response.exchange_rate,
            fees: response.fees,
            submitTransfer: true,
            transferId: response.id,
            expiresAt: response.expiresAt,
            net_amount: response.destination_amount,
            gross_amount: response.source_amount,
            sourceAmount: response.source_amount,
            destinationAmount: response.destinationAmount,
            note: note
          });
        }
      })
      .catch(error => {
        console.log(error);

        const errors = this.setApiErrors(error.data.errors);

        this.setState({ validationErrors: errors, submitting: false });
      });
  };

  submitTransfer = e => {
    const { transferId } = this.state;

    e.preventDefault();

    getRequest(`/crypto_transfers/${transferId}/resend_confirmation`).then(
      () => {
        window.location = `/crypto_transfers/${transferId}`;
      }
    );
  };

  cancelTransfer = e => {
    const { transferId } = this.state;

    e.preventDefault();

    const data = {
      crypto_transfer: {
        id: transferId
      }
    };

    const crsfToken = $('meta[name="csrf-token"]').attr("content");

    patchRequest(`/crypto_transfers/${transferId}/cancel`, data, {
      "X-CSRF-Token": crsfToken
    })
      .then(() => {
        // window.location = `/crypto_transfers/`;
        this.refreshPage(e);
        // window.location = `/crypto_transfers/new_blockchain_to_usd_transfer?token=${selectedCurrency}`;
      })
      .catch(error => {
        this.setState({ submitting: false });
        console.log(error);
      });
  };

  render() {
    const { sourceWallets, type } = this.props;

    const {
      totalTypes,
      selectedTotalType,

      sourceAmount,
      selectedSourceWallet,
      selectedDestinationWallet,
      selectedCurrency,
      selectedCurrencyBalance,
      selectedCurrencyDescription,
      selectedCurrencyImgSrc,

      destinationWallets,
      destinationAmount,
      destinationCurrency,
      destinationCurrencyImgSrc,
      note,
      fees,
      exchangeRate,
      expiresAt,

      selectedTransferType,
      recipient,
      recipientError,
      mode,
      error,
      sourceAmountError,
      invalidTransfer,
      submitting,
      validationErrors,
      submitTransfer,
      transferId,
      blockchainAddress,
      walletAddresses,
      selectedWalletAddress
    } = this.state;

    //console.log('totalTypes', totalTypes);
    // console.log('sourceAmount', sourceAmount);
    // console.log('fees', fees);
    // console.log('destinationAmount', destinationAmount);
    // console.log('validationErrors', validationErrors);
    // console.log('error', error);
    // console.log('transferId', transferId);

    return (
      <div className="container-fluid">
        <form
          className="form-horizontal transfer-form"
          id="new_crypto_transfer"
        >
          <div className="form-inputs">
            <h4>
              <i className="ti-arrow-right "></i> Convert{" "}
              <ReactSVG
                src={selectedCurrencyImgSrc}
                className="svg18"
                wrapper="span"
                beforeInjection={svg => {
                  svg.setAttribute("style", "width: 18px");
                }}
              />{" "}
              {selectedCurrency} to USDC{" "}
              <ReactSVG
                src={destinationCurrencyImgSrc}
                className="svg18"
                wrapper="span"
              />{" "}
              / USD
            </h4>
            <hr />

            <div className="form-group row">
              <div className="col-md-6">
                {/* <select
                    className="form-control form-control-lg expand-select"
                    id="total_type"
                    value={selectedTotalType}
                    onChange={e => this.handleTotalTypeChange(e, "selectedTotalType")}
                  >
                    { totalTypes.map((totalType, i) => (
                      <option value={totalType[0]} key={i}>
                        {totalType[1]}
                      </option>
                    ))}
                  </select> */}
                <span className="text-muted font-0_9">
                  Enter the amount that will be withdrawn from your wallet,
                  inclusive of fees.
                </span>
              </div>
            </div>

            <div className="form-group row required">
              <label className="form-label">Amount</label>
              {/* source amount */}
              <div className="col-md-6">
                <div className="input-group mb-1 mr-sm-2">
                  <div className="input-group-prepend">
                    <div className="input-group-text selected-currency">
                      {selectedCurrency}
                    </div>
                  </div>
                  <input
                    step="any"
                    autoFocus="autofocus"
                    style={{ maxWidth: "400px" }}
                    placeholder="0.000"
                    className={`form-control font-1_2 ${
                      validationErrors?.sourceAmountError ? "is-invalid" : ""
                    }`}
                    required="required"
                    type="number"
                    value={sourceAmount}
                    onChange={this.handleSourceAmountChange}
                    id="source_amount"
                    disabled={submitting == true ? true : false}
                  />
                </div>
                <span className="font-0_9 text-muted pl-2">
                  {selectedCurrencyBalance} {selectedCurrencyDescription} (
                  {selectedCurrency}){" "}
                  <span className="text-muted font-0_75">Available</span>
                </span>
                {error && (
                  <span className="error text-danger">
                    <br />
                    {error}
                  </span>
                )}
                {validationErrors?.sourceAmountError && (
                  <span className="error text-danger">
                    <br />
                    {validationErrors.sourceAmountError}
                  </span>
                )}
              </div>
            </div>

            <div className="form-group row">
              <div className="col-md-12">
                <label className="form-label">Note</label>
                <div className="form-group">
                  <textarea
                    className={
                      submitting == true
                        ? "form-control pr-4 border-0"
                        : "form-control pr-4"
                    }
                    placeholder={
                      submitting == true
                        ? note
                        : "Enter your own notes or comment (optional)"
                    }
                    type="text"
                    onChange={this.handleNoteChange}
                    id="note"
                    value={note}
                    disabled={submitting == true ? true : false}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="form-preview pt-3">
            <h4>{submitting ? "Confirm" : "Preview"}</h4>
            <hr />
            <div className="px-md-5 mx-2">
              <div className="form-group row">
                <div className="col-md-5">
                  <div className="form-group row d-block ">
                    <label className="form-label">
                      Convert
                      <a
                        data-toggle="tooltip"
                        className="btn btn-link"
                        data-original-title={`Amount in ${selectedCurrency} to convert to USDC/USD`}
                        data-tip={`Amount in ${selectedCurrency} to convert to USDC/USD`}
                      >
                        <i className="fa fa-info-circle"></i>
                      </a>
                    </label>
                    <small
                      id="netAmountHelpBlock"
                      className="form-text text-muted"
                    ></small>
                  </div>{" "}
                </div>
                <div className="col-md-7">
                  <div className="form-group row">
                    <div className="input-group mb-2 mr-sm-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text selected-currency border-0">
                          {selectedCurrency}
                        </div>
                      </div>
                      <input
                        className="form-control border-0"
                        placeholder="TBD"
                        data-target="cryptoTransfer.outputNetAmount"
                        required="required"
                        readOnly="readonly"
                        type="number"
                        value={
                          submitting ? Number(sourceAmount).toFixed(6) : "TBD"
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-md-5">
                  <div className="form-group row d-block">
                    <label className="form-label">Exchange Rate</label>
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="form-group row">
                    <div className="input-group mb-2 mr-sm-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text selected-currency border-0 font-0_9">
                          1 {selectedCurrency} = ${" "}
                          {Number.parseFloat(exchangeRate).toFixed(6)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-5">
                  <div className="form-group row d-block ">
                    <label className="form-label">
                      Network/Miner Fee
                      <a
                        data-toggle="tooltip"
                        className="btn btn-link"
                        data-original-title="Transaction and processing fees, if none are displayed then are included in the rate"
                        data-tip="Transaction and processing fees, if none are displayed, then they are included in the rate"
                      >
                        <i className="fa fa-info-circle"></i>
                      </a>
                    </label>
                    <small
                      id="feesHelpBlock"
                      className="form-text text-muted"
                    ></small>
                  </div>{" "}
                </div>
                <div className="col-md-7">
                  <div className="form-group row ">
                    <div className="input-group mb-2 mr-sm-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text selected-currency border-0">
                          $
                        </div>
                      </div>
                      <input
                        className="form-control border-0 font-0_9"
                        placeholder="TBD"
                        step="0.01"
                        data-target="cryptoTransfer.outputFeesAmount"
                        required="required"
                        readOnly="readonly"
                        type="number"
                        value={submitting ? Number(fees).toFixed(6) : "TBD"}
                      />
                    </div>
                  </div>{" "}
                </div>
              </div>
              <div className="pt-0">
                <div className="form-group row">
                  <div className="col-md-5">
                    <div className="form-group row d-block ">
                      <label className="form-label">
                        Total
                        <a
                          data-toggle="tooltip"
                          className="btn btn-link"
                          data-original-title="Total amount in USD that will added to your Digital Dollars Wallet."
                          data-tip="Total amount in USD that will added to your Digital Dollars Wallet."
                        >
                          <i className="fa fa-info-circle"></i>
                        </a>
                      </label>
                      <small
                        id="netAmountHelpBlock"
                        className="form-text text-muted"
                      ></small>
                    </div>{" "}
                  </div>
                  <div className="col-md-7">
                    <div className="form-group row ">
                      <div className="input-group mb-2 mr-sm-2">
                        <div className="input-group-prepend">
                          <div className="input-group-text selected-currency border-0 font-weight-bold font-1_2">
                            $
                          </div>
                        </div>
                        <input
                          className="form-control border-0 font-weight-bold font-1_2"
                          placeholder="TBD"
                          step="0.01"
                          data-target="cryptoTransfer.outputNetAmount"
                          required="required"
                          readOnly="readonly"
                          type="number"
                          value={
                            submitting
                              ? Math.round(
                                  ((sourceAmount - fees) * exchangeRate +
                                    Number.EPSILON) *
                                    100
                                ) / 100
                              : "TBD"
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-muted font-0_9 text-right">
              {submitting ? (
                <div className="pt-4 mt-2">
                  <i className="fa fa-exclamation-circle"></i> The quoted
                  transfer exchange rates and fees are{" "}
                  <strong>valid for the next 10 min</strong>. If your quote
                  expires, simply start again. Click Convert to continue.
                </div>
              ) : (
                <div>
                  <i className="fa fa-exclamation-circle"></i> Click Preview
                  Convert to preview your conversion with the latest rates and
                  applicable network fees
                </div>
              )}
            </div>
            <hr />
            <div className="form-actions">
              {(submitTransfer && transferId) ? (
                <div className="m-auto">
                  <div className="col-md-6 float-md-right">
                    <button
                      name="button"
                      type="submit"
                      className="btn btn-primary pull-right ml-4 mb-4"
                      onClick={this.submitTransfer}
                    >
                      Convert
                    </button>
                  </div>
                  <div className="col-md-6 float-md-left">
                    <button
                      name="cancel"
                      type="submit"
                      className="btn pull-left ml-4 mb-4"
                      onClick={this.cancelTransfer}
                      disabled={invalidTransfer}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              ) : (
                <div className="m-auto">
                  <div className="float-md-right">
                    <button
                      name="button"
                      type="submit"
                      className="btn btn-primary pull-right ml-4 mb-4"
                      onClick={this.submitTransferPreview}
                      disabled={invalidTransfer || submitting}
                    >
                      {submitting ? (
                        <>
                          <i className="fa fa-circle-o-notch fa-spin"></i>
                          <span style={{ paddingLeft: "5px" }}>
                            Please Wait...Loading
                          </span>
                        </>
                      ) : (
                        <span>Preview Convert</span>
                      )}
                    </button>
                  </div>
                  <div className="col-md-6 float-md-left">
                    <button
                      name="start_again"
                      type="submit"
                      className="btn pull-left ml-4 mb-4"
                      onClick={this.refreshPage}
                    >
                      Start Again
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </form>
        <ReactTooltip data-type="dark" data-background-color="#00669c" />
      </div>
    );
  }
}

export default CryptoTransferToUsdc;
