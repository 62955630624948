import React, { useEffect } from "react";
import { volumeOptions } from "./helper";
import ReactApexChart from "react-apexcharts";
import { groupByDateAndSum } from "./helper";

const SummaryChart = () => {
  const [volumeData, setVolumeData] = React.useState([]);

  const fetchData = async () => {
    try {
      const response = await fetch("/crypto_transfers.json");
      const data = await response.json();

      const sortedTransfers = data.transfers.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );

      let groupedTxByDay = groupByDateAndSum(sortedTransfers).splice(0, 5);

      const volumeData = groupedTxByDay.map((transfer) => {
        return {
          x: new Date(transfer.created_at).getTime(),
          y: transfer.net_amount,
        };
      });
      setVolumeData(volumeData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (volumeData.length < 1)
    return (
      <div className="pt-3 pb-4">
        <p className="text-gray summary-chart-text">
          You have no transactions yet.
        </p>
        <a
          className="summary-badge summary-badge-link font-weight-bold mr-0 mr-md-2"
          href="/add_funds"
        >
          <i className="fa fa-plus mr-1"></i>
          Add Funds
        </a>
      </div>
    );

  return (
    <div style={{ width: "100%", height: "auto" }}>
      <ReactApexChart
        options={volumeOptions}
        series={[{ name: "Transaction Volume", data: volumeData }]}
        type="area"
        height={180}
        width="100%"
      />
    </div>
  );
};
export default SummaryChart;
