import React, { useState, useEffect } from "react";

const getTokenName = (token) => {
  switch (token) {
    case "USDT":
      return "USD";
    default:
      return token;
  }
};

const TradeType = ({ token, updateParent, getData }) => {
  const [tradeTypeOpt, setTradeTypeOpt] = useState("buy");

  const onTabClick = (type) => {
    setTradeTypeOpt(type);
    updateParent("tradeType", type);
  };

  useEffect(() => {
    setTradeTypeOpt(getData("tradeType"));
  }, [getData]);

  return (
    <div className="createoffer-trade-type">
      <div className="createoffer-tab-header">
        <div
          className={`${
            tradeTypeOpt === "buy" ? "createoffer-buytab-active" : "createoffer-tab-inactive"
          }`}
          id="tradeTypeBuy"
          onClick={() => onTabClick("buy")}
        >
          I Want to Buy
        </div>
        <div
          className={`${
            tradeTypeOpt === "sell" ? "createoffer-selltab-active" : "createoffer-tab-inactive "
          }`}
          id="tradeTypeSell"
          onClick={() => onTabClick("sell")}
        >
          I Want to Sell
        </div>
      </div>
    </div>
  );
};

export default TradeType;
