import React, { useState, useRef } from "react";
import "../../../styles/trademodals.scss";

const CancelConfirmationModal = ({ onConfirm, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [confirmButtonLabel, setConfirmButtonLabel] = useState("Confirm");
  const [tryAgain, setTryAgain] = useState(false);
  const [checkboxes, setCheckboxes] = useState({
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
  });

  const errorRef = useRef(null);

  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target;
    setCheckboxes({ ...checkboxes, [id]: checked });
  };

  const handleConfirmClick = (e) => {
    e.preventDefault();
    if (Object.values(checkboxes).every((checked) => checked)) {
      if (errorRef.current) {
        errorRef.current.style.display = "none";
      }
      setIsLoading(true);
      onConfirm();

      if (!tryAgain) {
        setTimeout(() => {
          setIsLoading(false);
          setConfirmButtonLabel("Re-Confirm");
          setTryAgain(true);
        }, 5000);
      }
      
    } else {
      if (errorRef.current) {
        errorRef.current.style.display = "block";
      }
    }
  };

  return (
    <div
      className="modal-status-cont"
      tabIndex={-1}
      aria-hidden="true"
      id="cancelConfirmModal"
      aria-labelledby="cancelConfirmModalLabel"
    >
      <div className="modal-status-alert-cont">
        <div>
          <button
            type="button"
            className="close"
            onClick={() => {onClose('cancelConfirmModal')}}
            aria-label="Close"
            disabled={isLoading}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <br/>
        </div>
        <div>
          <p className="modal-title" id="cancelConfirmModalLabel">
            Are you sure you want to cancel this trade? 
          </p>
          <div className="trademodal-notice">
            <span>
              {" "}
              This action cannot be undone.
            </span>
          </div>
          {/* terms and conditions  */}
          <div>
            <div className="trademodal-form-section">
              <small id="cancelErrorMessage" ref={errorRef} style={{ display: "none", color: "red" }}>
                Please check all the required boxes.
              </small>
              <div className="trademodal-form-check">
                <input
                  className="trademodal-form-check-input"
                  type="checkbox"
                  id="checkbox1"
                  checked={checkboxes.checkbox1}
                  onChange={handleCheckboxChange}
                />
                <label className="trademodal-form-check-label" htmlFor="checkbox1">
                  I have not made the payment / I have been refunded
                </label>
              </div>
              <div className="trademodal-form-check">
                <input
                  className="trademodal-form-check-input"
                  type="checkbox"
                  id="checkbox2"
                  checked={checkboxes.checkbox2}
                  onChange={handleCheckboxChange}
                />
                <label className="trademodal-form-check-label" htmlFor="checkbox2">
                  I understand that this process is irreversible
                </label>
              </div>
              <div className="trademodal-form-check">
                <input
                  className="trademodal-form-check-input"
                  type="checkbox"
                  id="checkbox3"
                  checked={checkboxes.checkbox3}
                  onChange={handleCheckboxChange}
                />
                <label className="trademodal-form-check-label" htmlFor="checkbox3">
                  I understand that BananaCrystal is not responsible for any loss of funds
                </label>
              </div>
            </div>
          </div>
          {/* buttons */}
          <div className="modal-buttons-section">
            <div>
              <button
                type="button"
                className="modal-tradeaction-canceltradebtn"
                disabled={isLoading}
                onClick={() => {onClose('cancelConfirmModal')}}
              >
                Cancel
              </button>
            </div>
            <div>
              <button
                type="button"
                className="modal-tradeaction-paidbtn"
                data-dismiss="modal"
                disabled={!Object.values(checkboxes).every(Boolean)}
                onClick={handleConfirmClick}
              >
                {isLoading ? "Please wait..." : confirmButtonLabel}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CancelConfirmationModal;
