import React, { useState, useEffect } from "react";
import { validateTotalAmount, handleAmountChange, handleTotalAmountChange } from "../../helpers";
import { calculateTradeFee } from "../../../Trades/CreateTrade/helpers";
import { convertToNumber, formatAmount } from "./helpers";

interface Props {
  amount: string;
  tradeType: string;
  canEditAmount?: boolean;
  accountBalance: string;
  feePercent: number;
  setAmount: (val: string) => void;
  updateParent: (key: string, value: string) => void;
}

const AmountInput: React.FC<Props> = ({
  amount,
  tradeType,
  accountBalance,
  setAmount,
  feePercent,
  updateParent,
  canEditAmount = true,
}) => {
  const [amountError, setAmountError] = useState("");
  const [formattedAmount, setFormattedAmount] = useState(
    new Intl.NumberFormat("en-US", {
     style: "decimal",
     maximumFractionDigits: 2,
    }).format(Number(amount) || 0))
  const [fee, setFee] = useState(calculateTradeFee(amount, feePercent));

  useEffect(() => {
    const numericAmount = convertToNumber(formattedAmount);
    setFee(calculateTradeFee(numericAmount.toString(), feePercent));
    setAmount(numericAmount.toString());
    updateParent("amount", numericAmount.toString());
  
  }, [formattedAmount, feePercent, updateParent]);

  const validateAmount = () => {
    const error = validateTotalAmount(
      convertToNumber(formattedAmount).toString(),
      accountBalance,
      tradeType,
      feePercent
    );
    if (error.length > 0) {
      setFormattedAmount("");
    }
    setAmountError(error);
  };

  return (
    <div>
      <div className="rate mt-5">
        <div className="col-md-12 px-0 mb-3">
          {/* Only visible on create offers */}
          <h3 id="createoffer-pricing-title" className={`buy-section_title ${tradeType === "sell" ? "sell-section_title " : ""}`}>
            <p className="createoffers-indexnumber"> 2 </p>
            {" "}
            Set the Amount you want to {tradeType === "sell" ? "Sell" : "Buy"}
          </h3>
          {/* Only visible on Edit Offers */}
          <h3 id="editoffer-pricing-title" className={`buy-section_title ${tradeType === "sell" ? "sell-section_title " : ""}`}>
            <p className="createoffers-indexnumber"> 2 </p>
            {" "}
            Total Amount Remaining to {tradeType === "sell" ? "Sell" : "Buy"} on this Offer
          </h3>
        </div>
      </div>

      <div className="mt-2 limit-margin">
        <div className="col-md-12 px-0">
          <div className="col-md-12 mt-0 px-0">
            <form className="limit-form createoffer-cont-boxshadow">

              <div id="createoffer-infotext" className="mt-2">
                {tradeType === "sell" && (
                  <p>
                      Total amount + fee must not exceed 
                      <b> Current Balance: ${" "}
                      {new Intl.NumberFormat("en-US", {
                        style: "decimal",
                        maximumFractionDigits: 2,
                      }).format(parseFloat(accountBalance))}
                    </b>
                  </p>
                )}
              </div>
              <div className="row">
                <div
                  className={tradeType === "sell" ? "col-md-6" : "col-md-12"}
                  title={`The amount you want to ${tradeType === "sell" ? "sell" : "buy"} in this offer`}
                >
                  <label className="createoffer-sublabels">
                    Total Amount <span style={{ color: "red" }}>*</span>
                  </label>
                  <div className="input-group mb-1">
                    <input
                      id="pricing-totalamount-input"
                      type="text"
                      className="createoffer-input"
                      placeholder="0.00"
                      disabled={!canEditAmount}
                      value={formattedAmount}
                      autoFocus
                      onChange={(e) => {
                        const value = e.target.value.replace(/[^0-9.]/g, "").replace(/(\..*)./g, '$1');
                        handleTotalAmountChange(e, setAmount, setFormattedAmount,
                         );
                        setAmountError("");
                      }}
                      onKeyUp={() => validateAmount()}
                    />
                    <div className="createoffer-currency-code-tag">
                      <p>USD</p>
                    </div>
                  </div>
                  {!canEditAmount && (
                    <p>
                      You cannot edit your amount because you have one or more
                      active trade(s) for this offer
                    </p>
                  )}
                  {amountError && (
                    <div
                      className="createoffer-error-alert"
                    >
                      {amountError}
                    </div>
                  )}
                </div>
                {tradeType === "sell" && (
                  <div className="col-md-6" title="Blockchain Gas Fee + Transaction Processing Fee">
                    <label className="createoffer-sublabels">
                    <i className="fas fa-question-circle"></i>{" "} 
                    Fee <span style={{ color: "red" }}>*</span>
                    </label>
                    <div className="input-group mb-1">
                      <input
                        readOnly
                        type="text"
                        className="createoffer-input fee"
                        placeholder="0.00"
                        value={
                          fee
                            ? new Intl.NumberFormat("en-US", {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                              }).format(fee)
                            : "0.00"
                        }
                      />
                      <div className="createoffer-currency-code-tag">
                        <p>USD</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AmountInput;
