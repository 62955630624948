import { Controller } from "stimulus";
import axios from "axios";

export default class extends Controller {
  static targets = ["loader", "linkButton", "saving"];
  initialize() {
    this.linkHandler = null;
  }

  connect() {
    this.linkButtonTarget.style.display = "none";
    this.savingTarget.style.display = "none";

    this.getLinkToken().then(token => {
      this.linkHandler = Plaid.create(this.plaidConfigs(token));
      this.loaderTarget.style.display = "none";
      this.linkButtonTarget.style.display = "block";
    });
  }

  getLinkToken() {
    return axios
      .get("/get_link_token.json", {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then(response => response.data.link_token);
  }

  linkAccount() {
    this.linkHandler.open();
  }

  plaidConfigs(linkToken) {
    const that = this;
    return {
      // 1. Pass a new link_token to Link.
      token: linkToken,
      onSuccess: async function(public_token, metadata) {
        that.linkButtonTarget.style.display = "none";
        that.savingTarget.style.display = "block";

        const crsfToken = $('meta[name="csrf-token"]').attr("content");

        // 2a. Send the public_token to your app server.
        // The onSuccess function is called when the user has successfully
        // authenticated and selected an account to use.
        const data = {
          public_token: public_token,
          metadata: metadata
        };

        axios
          .post("/get_access_token", data, {
            headers: {
              "Content-Type": "application/json",
              "X-CSRF-Token": crsfToken
            }
          })
          .then(response => {
            window.location = `/payment_methods/${response.data.payment_method.id}/ach/edit`;
          });
      },
      onExit: async function(err, metadata) {
        // 2b. Gracefully handle the invalid link token error. A link token
        // can become invalidated if it expires, has already been used
        // for a link session, or is associated with too many invalid logins.
        console.log(err);
        if (err != null && err.error_code === "INVALID_LINK_TOKEN") {
          this.linkHandler.destroy();
          this.linkHandler = Plaid.create(this.plaidConfigs(linkToken));
        }
        if (err != null) {
          // Handle any other types of errors.
        }
        // metadata contains information about the institution that the
        // user selected and the most recent API request IDs.
        // Storing this information can be helpful for support.
      }
    };
  }
}
