import { Controller } from "stimulus";
import Rails from "@rails/ujs";
import axios from "axios";

export default class extends Controller {
  static targets = [
    "recipient",
    "destinationWallet",
    "walletTypeSelect",
    "inputGrossAmount",
    "inputWalletDestinationType",
    "inputWalletTransferType",
    "outputGrossAmount",
    "outputFeesAmount",
    "outputNetAmount",
    "recipientInput",
    "sourceWallet"
  ];

  connect() {
    let recipient,
      destinationWallet,
      grossAmount,
      destinationType,
      walletTypeSelect,
      sourceWalletId;
    // inputs
    if (this.hasRecipientTarget) {
      recipient = this.recipientTarget.style;
    }

    if (this.hasWalletTypeSelectTarget) {
      walletTypeSelect = this.walletTypeSelectTarget.value;
    }

    if (recipient && walletTypeSelect) {
      this.selectWalletTransferType(recipient, walletTypeSelect);
    }

    if (this.hasDestinationWalletTarget) {
      destinationWallet = this.destinationWalletTarget.style;
    }

    if (this.hasInputGrossAmountTarget) {
      grossAmount = this.inputGrossAmountTarget.value;
      // console.log('grossAmount', grossAmount)
    }

    if (this.hasInputWalletDestinationTypeTarget) {
      destinationType = this.inputWalletDestinationTypeTarget.value;
    }

    if (this.sourceWalletTarget) {
      sourceWalletId = this.sourceWalletTarget.value;
    }

    this.inputGrossAmountTarget.setCustomValidity("");

    if (
      destinationType &&
      this.validGrossAmount(grossAmount, destinationType)
    ) {
      this.hasAvailableBalance(grossAmount, sourceWalletId).then(
        walletBalance => {
          this.transferFees(grossAmount, destinationType);

          if (walletBalance < grossAmount) {
            this.inputGrossAmountTarget.setCustomValidity(
              "The selected account does not have enough funds to perform this transaction"
            );
          }
        }
      );
    }
  }

  hasAvailableBalance(grossAmount, sourceWalletId) {
    return axios
      .get(
        `/transfers/selected_wallet_balance?source_wallet_id=${sourceWalletId}`,
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
      .then(response => response.data);
  }

  selectWalletTransferType(recipient, walletTypeSelect) {
    if (walletTypeSelect === "self") {
      recipient.display = "none";
      this.destinationWalletTarget.style.display = "block";
    } else if (walletTypeSelect === "external") {
      recipient.display = "block";
      this.destinationWalletTarget.style.display = "none";
    }
  }

  // single transaction amount limits
  validGrossAmount(grossAmount, walletTransferType) {
    let isValidGrossAmount;
    isValidGrossAmount =
      grossAmount && (grossAmount >= 1.00 && grossAmount < 500000.01) == true;

    return isValidGrossAmount;
  }

  transferFees(grossAmount, destinationType) {
    let response;
    Rails.ajax({
      type: "GET",
      url: `/transfers/fees?gross_amount=${grossAmount}&destination_type=${destinationType}`,
      success: (_data, _status, xhr) => {
        response = JSON.parse(xhr.response);
        this.outputGrossAmountTarget.value = response["gross_amount"];
        this.outputFeesAmountTarget.value = response["fees"];
        this.outputNetAmountTarget.value = response["net_amount"];
      }
    });
  }

  lookupUser() {
    const data = {
      transfer: {
        recipient: this.recipientInputTarget.value
      }
    };

    const crsfToken = $('meta[name="csrf-token"]').attr("content");

    if (this.recipientInputTarget.value) {
      return axios
        .post("/transfers/lookup_recipient", data, {
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": crsfToken
          }
        })
        .then(response => {
          this.recipientInputTarget.setCustomValidity("");
        })
        .catch(error => {
          this.recipientInputTarget.setCustomValidity("user is invalid");
        });
    }
  }
}
