import React, { useEffect, useState } from "react";
import NumericInput from "react-numeric-input";
import { Offer } from "../../Types";
import Decimal from "decimal.js";
import { formatMinMaxAmount, handleAmountChange } from "../../../Offers/helpers";

interface Props {
  offer: Offer;
  isFixedPrice: boolean;
  rangeMax: string;
  rangeMin: string;
  tradeType: string;
  hasError: boolean;
  margin: string;
  actualRate: number;
  offerTerms: string;
  maxFieldValidationMessage: string;
  minFieldValidationMessage: string;
  marketrate: number;
  canEditAmount: boolean;
  setOfferTerms: (value: string) => void;
  hasExternalRate: boolean;
  validationMessage: string;
  amount: string;
  offerTradeRate: string;
  setOfferTradeRate: (value: string) => void;
  availableBalance: number;
  setRangeMax: (value: string) => void;
  setRangeMin: (value: string) => void;
  setMargin: (value: string) => void;
  profanityErrorMessage: string;
  handleProfanityCheck: (value: string) => void;
  updateOffer: () => void;
  getFiatAmount: (value: string, value2: string) => string;
  updatelimitsValidation: (value: string) => void;
}

const Limit: React.FC<Props> = ({
  offer,
  rangeMax,
  rangeMin,
  tradeType,
  isFixedPrice,
  canEditAmount,
  margin,
  hasError,
  actualRate,
  offerTerms,
  availableBalance,
  hasExternalRate,
  validationMessage,
  marketrate,
  setRangeMax,
  setRangeMin,
  setMargin,
  setOfferTerms,
  updateOffer,
  getFiatAmount,
  amount,
  offerTradeRate,
  setOfferTradeRate,
  maxFieldValidationMessage,
  minFieldValidationMessage,
  updatelimitsValidation,
  profanityErrorMessage,
  handleProfanityCheck,
}) => {
  const [tradeRateDisplay, setTradeRateDisplay ] = useState(offerTradeRate);
  const currentOfferRate =  hasExternalRate ? offer.fiatCurrency?.externalRates[0]?.overrideRate : offer.fiatCurrency?.rateToUsd;
  const [rangeMinDisplay, setRangeMinDisplay] = useState(
    new Intl.NumberFormat("en-US", {
      style: "decimal",
      maximumFractionDigits: 2,
    }).format(parseFloat(rangeMin) || 0)
  );
  const [rangeMaxDisplay, setRangeMaxDisplay] = useState(
    new Intl.NumberFormat("en-US", {
    style: "decimal",
    maximumFractionDigits: 2,
  }).format(parseFloat(rangeMax) || 0));


  const calculateMinTradeLimit = () => {
    if (!rangeMin) {
      return "0.00";
    }

    const marginTemp = parseFloat(margin).toFixed(2);
    const rangeMinTemp = parseFloat(rangeMin);
    const calculatedMargin = (rangeMinTemp * parseFloat(marginTemp)) / 100;
    const calculatedAmount = calculatedMargin + rangeMinTemp;
    return calculatedAmount.toFixed(2);
  };

  const calculateTraderRate = (newMargin, currActualRate) => {
    const marginVal = newMargin ? new Decimal(Number(newMargin)) : 0;
    const rate = new Decimal(currActualRate);
  
    const tempAmount = rate.mul(marginVal).div(new Decimal(100));
    let result = rate.plus(tempAmount);
    let roundedResult = Number(result).toFixed(2);

    if (Math.abs(Number(roundedResult) - Math.floor(Number(roundedResult))) === 0.99) {
      let steppedResult = Number(roundedResult) + 0.01;
      return steppedResult.toFixed(2);
    }
    return roundedResult;
  };

  const calculateNewMargin = (newValue, currActualRate) => {
    const numericValue = parseFloat(newValue);
  
    if (isNaN(numericValue)) {
      setMargin("0");
      setTradeRateDisplay(newValue);
      return;
    }
  
    const rate = new Decimal(currActualRate);
    const result = new Decimal(numericValue);
    
    // Reverse the margin calculation
    const tempAmount = result.minus(rate);
    const marginVal = tempAmount.div(rate).mul(new Decimal(100));
  
    setMargin(marginVal.toFixed(2));
  };
  

  const calculateOfferrRate = (currMargin, currRate) => {
    let marginVal = Number(currMargin);
    let currRateVal = Number(currRate);
    const margin = marginVal ? new Decimal(marginVal) : 0;
    const rate = new Decimal(currRateVal);

    const tempAmount = rate.mul(margin).div(new Decimal(100));
    let result = rate.plus(tempAmount);

    if (result.toFixed(2).endsWith(".99")) {
      result = result.plus(new Decimal("0.01"));
    }

    return result.toFixed(2);
  };

  useEffect (() => {
    setOfferTradeRate(tradeRateDisplay);
  }, [tradeRateDisplay]);

  return (
    <div className="editoffer-limits-cont">
      <div className="bg-white">
        <div>
          <div>
            <div>
              <div className="editoffer-tradelimit-title">
                <h3 className={`buy-section_title ${tradeType === "sell" ? "sell-section_title " : ""}`}>
                  <p className="createoffers-indexnumber"> 3 </p>
                  {" "}
                  Update Trade Limits for this Offer
                </h3>
              </div>
              <div className="mt-4">
                <form className="limit-form createoffer-cont-boxshadow">
                  <p>
                    <i className="fas fa-info-circle"></i>
                    {" "}
                    You can have multiple transactions or trades for this offer until you have completely {tradeType === "sell" ? "sold " : "bought "} 
                    the total amount you want to {tradeType === "sell" ? "sell" : "buy"}. 
                    Please set the minimum and maximunm amount you would like to {tradeType === "sell" ? "sell" : "buy"} per transaction or trade for this offer.
                    Your maximum limit per transaction/trade should not exceed{" "}
                    <b>
                      - {" "}
                      the {offer.fiatCurrency?.code} equivalent of the total amount above:
                      {" "}
                      {formatMinMaxAmount(getFiatAmount(marketrate.toString(), amount))}
                      {" "}
                      {offer.fiatCurrency?.code}
                    </b>
                  </p>
                  <div className="row">
                    <div className="col-md-6"
                      title={`The lowest amount you would like to ${tradeType === "sell" ? "sell" : "buy"} for each transaction or trade till you have completely ${tradeType === "sell" ? "sold" : "bought"} ${amount} USD - the total amount that you've set for this offer`}
                    >
                      <label className="font-weight-bold pt-2">
                        <i className="fas fa-question-circle"></i>{" "}
                        Minimum
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          className="createoffer-input"
                          placeholder="0.00"
                          autoFocus={true}
                          disabled={!canEditAmount}
                          value={rangeMinDisplay}
                          onChange={(e) => {
                            const value = e.target.value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, '$1');
                            handleAmountChange(e, setRangeMin, setRangeMinDisplay);
                          }}
                          onBlur={() => updatelimitsValidation("minfield")}
                        />
                        <div className="createoffer-currency-code-tag">
                          <p>
                            {offer.fiatCurrency?.code}
                          </p>
                        </div>
                      </div>
                      {!canEditAmount && (
                        <p style={{color: "red"}}>
                          <i className="fas fa-exclamation-triangle"></i>
                          You cannot edit this section 
                          because you have one or more active trade(s) for
                          this offer
                        </p>
                      )}
                        {hasError && minFieldValidationMessage.length > 0 ? (
                          <div className="createoffer-error-alert">
                            {minFieldValidationMessage}
                          </div>
                        ) : (<></>)}
                    </div>
                    <div className="col-md-6"
                        title={`The highest amount you would like to ${tradeType === "sell" ? "sell" : "buy"} for each transaction or trade till you have completely ${tradeType === "sell" ? "sold" : "bought"} ${amount} USD - the total amount that you've set for this offer`}
                    >
                      <label className="font-weight-bold pt-2">
                        <i className="fas fa-question-circle"></i>{" "}
                        Maximum
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          className="createoffer-input"
                          placeholder="0.00"
                          disabled={!canEditAmount}
                          autoFocus={true}
                          value={rangeMaxDisplay}
                          onChange={(e) => {
                            const value = e.target.value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, '$1');
                            handleAmountChange(e, setRangeMax, setRangeMaxDisplay);
                          }}
                          onBlur={() => updatelimitsValidation("maxfield")}
                        />
                        <div className="createoffer-currency-code-tag">
                          <p>
                            {offer.fiatCurrency?.code}
                          </p>
                        </div>
                      </div>
                      {hasError && maxFieldValidationMessage.length > 0 ? (
                          <div className="createoffer-error-alert">
                            {maxFieldValidationMessage}
                          </div>
                        ) : (<></>)}
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div className="editoffer-terms">
              <div className="editoffer-pricing-title">
                <label className={`buy-section_title ${tradeType === "sell" ? "sell-section_title " : ""}`}>
                  <p className="createoffers-indexnumber"> 4 </p>
                  {" "}
                  Update Current Offer Terms
                </label>
              </div>
              <div className="limit-form createoffer-cont-boxshadow">
                <div className="mt-2 mb-2 mr-sm-2">
                  <textarea
                    className="form-control bg-white editoffer-terms-text"
                    id="exampleInputPassword1"
                    placeholder="Write your terms here."
                    onChange={(e) => setOfferTerms(e.target.value)}
                    onBlur={() => handleProfanityCheck}
                    defaultValue={offerTerms}
                  ></textarea>
                </div>
                {hasError && profanityErrorMessage.length > 0 ? (
                  <div className="createoffer-error-alert">
                    {profanityErrorMessage}
                  </div>
                ) : (<></>)}
                <p id="emailHelp" className="form-text text-muted">
                  Anybody who views your offer will see these terms. Keep them
                  simple and clear to make your offer sound attractive.
                </p>
              </div>
            </div>

            <div>
              <div className="editoffer-pricing-title">
                <p className={`buy-section_title ${tradeType === "sell" ? "sell-section_title " : ""}`}>
                  <p className="createoffers-indexnumber"> 5 </p>
                  {" "}
                  Update your Price/USD Rate for this Offer
                </p>
              </div>
              <div className={`createoffer-payment-method-wrapper ${tradeType === "sell" ? "createoffer-payment-method-wrapper-sell" : ""}`}>
                <div>
                  <div className="text-muted font-1">
                    
                  </div>
                  <form>
                    <div className="mt-2 mb-5">
                      <p>
                        <i className="fas fa-info-circle"></i>
                        {" "}
                        <strong>Use the Most Convenient Option </strong>
                      </p>
                    </div>
                    <div className="_input-field-card createoffer_input-field-card"
                      title={`The price you are willing to ${tradeType === "sell" ? "sell" : "buy"} 1 USD for`}
                    >
                      <p className="createoffer-margin"><b>Method 1 (Use the Custom Field)</b></p>
                      <label className="createoffer-sublabels">
                        How much do you want to {tradeType === "sell" ? "sell" : "buy"} One(1) USD in {offer.fiatCurrency?.code}?
                      </label>
                      <div className="input-group mb-1">
                        <input
                          type="text"
                          value={tradeRateDisplay || " "}
                          className="createoffer-input"
                          onChange={(e) => {
                            const newValue = e.target.value;
                            setTradeRateDisplay(newValue);
                            calculateNewMargin(newValue, actualRate);
                          }}
                        />
                        <div className="createoffer-currency-code-tag">
                          <p>
                              {offer.fiatCurrency?.code}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 mt-4"
                        title={`The percentage profit you'll make for each transaction or trade in this offer, based on your preferred PRICE vs the MARKET PRICE for 1 USD`}
                      >
                        <p className="createoffer-margin"><b>Method 2 (Use the Margin Stepper)</b>
                          <p>
                            <i className="fas fa-question-circle"></i>
                            {" "}
                            To learn how the margin works,{" "}
                              <strong>
                                <a href={tradeType === "buy" ? "https://www.youtube.com/watch?v=QOKGxcnJVg4" : "https://www.youtube.com/watch?v=RH6OPldYDmU"} target="_blank">
                                  Click Here!!
                                </a>
                              </strong>
                            </p>
                        </p>
                        <label className="createoffer-sublabels">
                          How many percent(%) above or below the market price do you want to {tradeType === "sell" ? "sell" : "buy"} One(1) USD in {offer.fiatCurrency?.code}?
                        </label>
                        <div className="input-group mt-2 mb-2 mr-sm-2">
                          <NumericInput
                            min={-10}
                            defaultValue={5}
                            value={margin}
                            step={0.01}
                            disabled={!canEditAmount}
                            mobile
                            className="form-control offer-margin-ranger pt-3 pb-2 createoffer-input"
                            onChange={(value) => {
                              setMargin(value);
                              setTradeRateDisplay(calculateTraderRate(value, actualRate));
                            }}
                          />
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <span className="font-weight-bold">%</span>
                            </div>
                          </div>
                        </div>
                        {!canEditAmount && (
                          <p>
                            You cannot edit your margin because you have one or
                            more active trade(s) for this offer
                          </p>
                        )}
                        <div className="pb-2">
                          <p>Use the +/- above to adjust your margin below or above the market price</p>
                          <p>
                            <i className="fas fa-bullseye"></i> 
                            {" "}
                            Current{" "}
                            {offer.cryptoCurrency?.code === "USDT"
                              ? "USD"
                              : offer.cryptoCurrency?.code}{" "}
                            market price:{" "}
                            <strong>
                              {actualRate}
                              {" "}
                              {offer.fiatCurrency?.code}
                            </strong>
                          </p>
                          <span>
                            You will make a profit of <strong>{margin}%</strong>{" "}
                            on every trade.
                          </span>
                          <div>
                            So for every{" "}
                            <strong>
                              {rangeMin ? parseFloat(rangeMin).toFixed(2) : "0.00"}{" "}
                              {offer.fiatCurrency?.code}
                            </strong>{" "}
                            (your minimum trade limit), you will{" "}
                            {tradeType === "sell" ? "receive" : "pay"} a{" "}
                            <strong>
                              {calculateMinTradeLimit()} {offer.fiatCurrency?.code}
                            </strong>{" "}
                            worth of{" "}
                            <strong>
                              {offer.cryptoCurrency?.code === "USDT"
                                ? "USD"
                                : offer.cryptoCurrency?.code}
                            </strong>{" "}
                            in return.
                          </div>

                        </div>
                      </div>
                    </div>
                  </form>
                  
                  <br/>
                  <div className="createoffer-yourrate">
                    Your rate:{" "}
                    <strong>

                      {formatMinMaxAmount(tradeRateDisplay) || formatMinMaxAmount(calculateTraderRate(margin, actualRate))}{" "}{offer?.fiatCurrency.code} per USD
                    </strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Limit;
