import React, { useState } from "react";
import CryptoTransferUsdToUsd from "../CryptoTransferUsdToUsd";
import SelectPaymentMethod from "./SelectPaymentMethod";

const SendPayments = (props) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  if (
    selectedPaymentMethod === "USD" ||
    new URLSearchParams(window.location.search).get("payment_method") === "USD"
  )
    return (
      <div className="card shadow-sm send-payment-card">
        <CryptoTransferUsdToUsd
          setSelectedPaymentMethod={setSelectedPaymentMethod}
          {...props}
        />
      </div>
    );

  return (
    <SelectPaymentMethod
      referralCode={props.referralCode}
      setSelectedPaymentMethod={setSelectedPaymentMethod}
    />
  );
};

export default SendPayments;
