import { Controller } from "stimulus";
import axios from "axios";

export default class extends Controller {
  static targets = ["loader", "linkButton", "accessToken", "description"];

  initialize() {
    this.linkHandler = null;
  }

  connect() {
    this.linkButtonTarget.style.display = "none";

    const accessToken = this.accessTokenTarget.value;

    if (accessToken) {
      this.getLinkToken(accessToken).then(token => {
        this.linkHandler = Plaid.create(this.plaidConfigs(token));
        this.loaderTarget.style.display = "none";
        this.linkButtonTarget.style.display = "block";
      });
    }
  }

  getLinkToken(accessToken) {
    return axios
      .get(`/get_update_link_token.json?access_token=${accessToken}`, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then(response => response.data.link_token);
  }

  updatePaymentMethod() {
    this.loaderTarget.style.display = "block";
    this.linkButtonTarget.style.display = "none";

    const accessToken = this.accessTokenTarget.value;
    const crsfToken = $('meta[name="csrf-token"]').attr("content");
    const data = {
      access_token: accessToken
    };

    return axios
      .post("/update_verification_status", data, {
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": crsfToken
        }
      })
      .then(response => {
        this.loaderTarget.style.display = "none";
        this.linkButtonTarget.style.display = "none";
        this.descriptionTarget.classList.remove("text-danger");
      });
  }

  linkAccount() {
    this.linkHandler.open();
  }

  plaidConfigs(linkToken) {
    const that = this;
    return {
      // 1. Pass a new link_token to Link.
      token: linkToken,
      onSuccess: async function(public_token, metadata) {
        // 2a. Send the public_token to your app server.
        // The onSuccess function is called when the user has successfully
        // authenticated and selected an account to use.
        that.updatePaymentMethod();
      },
      onExit: async function(err, metadata) {
        // 2b. Gracefully handle the invalid link token error. A link token
        // can become invalidated if it expires, has already been used
        // for a link session, or is associated with too many invalid logins.

        if (err != null && err.error_code === "INVALID_LINK_TOKEN") {
          this.linkHandler.destroy();
          this.linkHandler = Plaid.create(this.plaidConfigs(linkToken));
        }

        if (err != null && err.error_code === "item-no-error") {
          that.updatePaymentMethod();
          // Handle any other types of errors.
        }
        // metadata contains information about the institution that the
        // user selected and the most recent API request IDs.
        // Storing this information can be helpful for support.
      }
    };
  }
}
