import React from "react";
import countryList from "./countryOfResidence.json";
import countryCodes from "./countryCodes.json";
import bcloader from "../../../assets/images/bcloader.gif";

const Form = ({
  data,
  errors,
  handleSubmit,
  handleInputChange,
  closePopup,
}) => {

  // Helper function to prepend country code to the phone number
  const prependCountryCode = (code, phoneNumber) => {
    // Remove any existing country code from the phone number
    let cleanedPhoneNumber = phoneNumber.replace(/^\+\d+/, '');
    return `${code}${cleanedPhoneNumber}`;
  };

  // Modified handleChange function
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Handle country code change
    if (name === "countryCode") {
      const selectedCountry = countryCodes.find(
        (countryCode) => countryCode.name === value
      );
      if (selectedCountry) {
        const newPhoneNumber = prependCountryCode(selectedCountry.code, data.phone_number);
        handleInputChange({ target: { name: "phone_number", value: newPhoneNumber } });
      }
    }

    handleInputChange(e);
  };

  return (
    <form id="broker-form">
      <h3 className="broker-title">Become a Broker</h3>
      <small class="broker-disclaimer">
        <span>
          <strong>DISCLAIMER: </strong>Your privacy is important to us. All information provided in this form will be kept strictly confidential and will not be shared with any third parties without your consent.
        </span>
      </small>
      
      {/* Notifications for Loader, Error. Loader changes to Successful from JS side etc) */}
      <div id="form-status-cont">
        <div id="form-status-section">

            <div>
              <h3 id="formstatus-popup-title-text">
                  <div>
                    <img src={bcloader} alt="loader"/>
                  </div>
              </h3>
            </div>

            <div id="form-success-button">
              <a 
              className="broker-success-btn"
              href="/p2p_trades/offers/new">
              <button
              type="button">
                OKAY
              </button>
              </a>
            </div>
        </div>
      </div>

      <div id="form-error-cont">
        <div id="broker-error-section">
            <div class="error-popup-title">
              <button
              type="button"
              onClick={closePopup}
              id="broker-popup-closebtn">
                <span class="material-symbols-outlined">close</span>
              </button>
            </div>
            <div>
            <h3 class="error-popup-title-text">
                Submission failed!
              </h3>
            </div>
          <div id="form-error-message">
  
          </div>
        </div>
      </div>

      {/* FORM */}
      <div className="form-group broker-field">
        <label htmlFor="first_name">
          First Name <span style={{ color: "red" }}>*</span>
          <br/>
          <small className="text-muted">
            Please use the name exactly as it appears on your bank statement to ensure approval.
          </small>
        </label>
        <input
          type="text"
          name="first_name"
          id="first-name-input"
          className="broker-input"
          required
          value={data.first_name}
          onChange={handleInputChange}
        />
        {errors.first_name && (
          <small className="text-danger">{errors.first_name}</small>
        )}
      </div>
  
      <div className="form-group broker-field">
        <label htmlFor="middle_name">
          Middle Name
          <br/>
          <small className="text-muted">
            Please use the name exactly as it appears on your bank statement to ensure approval.
          </small>
        </label>
        <input
          type="text"
          name="middle_name"
          id="middle-name-input"
          className="broker-input"
          value={data.middle_name}
          onChange={handleInputChange}
        />
        {errors.middle_name && (
          <small className="text-danger">{errors.middle_name}</small>
        )}
      </div>
  
      <div className="form-group broker-field">
        <label htmlFor="last_name">
          Last Name <span style={{ color: "red" }}>*</span>
          <br/>
          <small className="text-muted">
            Please use the name exactly as it appears on your bank statement to ensure approval.
          </small>
        </label>
        <input
          type="text"
          name="last_name"
          id="last-name-input"
          className="broker-input"
          required
          value={data.last_name}
          onChange={handleInputChange}
        />
        {errors.last_name && (
          <small className="text-danger">{errors.last_name}</small>
        )}
      </div>
  
      <div className="form-group broker-field">
        <label htmlFor="business_name">Business Name
          <br/>
          <small className="text-muted">
            Please use the name exactly as it appears on your bank statement to ensure approval.
          </small>
        </label>
        <input
          type="text"
          name="business_name"
          id="business-name-input"
          className="broker-input"
          value={data.business_name}
          onChange={handleInputChange}
        />
        {errors.business_name && (
          <small className="text-danger">{errors.business_name}</small>
        )}
      </div>
  
      <div className="form-group broker-field">
        <label htmlFor="email">
          Email <span style={{ color: "red" }}>*</span>
        </label>
        <input
          type="email"
          name="email"
          id="email-input"
          className="broker-input"
          required
          value={data.email}
          onChange={handleInputChange}
        />
        {errors.email && <small className="text-danger">{errors.email}</small>}
      </div>
  
      <div>
        <br/>
        <p><strong>Phone Number</strong> <span style={{ color: "red" }}>*</span></p>
        <small className="text-muted">Must be filled correctly to ensure approval.</small>
        <div className="form-group row broker-field">
          <div className="col-md-4">
            <label htmlFor="countryCode">
              Country Code
            </label>
            <select
              name="countryCode"
              className="form-control broker-dropdown"
              id="country-code-select"
              value={data.countryCode}
              onChange={handleChange}
              required
            >
              {countryCodes.map((countryCode, index) => (
                <option key={index} value={countryCode.name}>
                  {countryCode.name}
                </option>
              ))}
            </select>
          </div>
    
          <div className="col-md-8">
            <label htmlFor="phone_number">
              Number
            </label>
            <input
              type="tel"
              name="phone_number"
              className="form-control broker-input"
              id="phone-number-input"
              required
              value={data.phone_number}
              onChange={handleChange}
            />
            {errors.phone_number && (
              <small className="text-danger">{errors.phone_number}</small>
            )}
          </div>
        </div>
        <br/>
      </div>
  
      <div className="form-group broker-field">
        <label htmlFor="gender">
          Gender <span style={{ color: "red" }}>*</span>
        </label>
        <select
          name="gender"
          className="form-control broker-dropdown"
          required
          value={data.gender}
          onChange={handleInputChange}
        >
          <option value="Male">Male</option>
          <option value="Female">Female</option>
        </select>
      </div>
  
      <div className="form-group broker-field">
        <label htmlFor="country">
          Country of Residence <span style={{ color: "red" }}>*</span>
          <br/>
          <small className="text-muted">Must match the country in your "Proof of Address" below to ensure approval.</small>
        </label>
        <select
          name="country"
          className="form-control broker-dropdown"
          id="country-select"
          value={data.country}
          onChange={handleInputChange}
          required
        >
          {countryList.map((country, index) => (
            <option key={index} value={country.name}>
              {country.name}
            </option>
          ))}
        </select>
      </div>
  
      <div className="form-group broker-field">
        <label htmlFor="min_deposit">
          Minimum Deposit <span style={{ color: "red" }}>*</span>
          <br/>
          <small className="text-muted">What's your MINIMUM USD($) Deposit/Withdrawal Amount you can process daily?</small>
        </label>
        <input
          placeholder="Input a value greater than 0"
          type="number"
          name="min_deposit"
          id="min-deposit-input"
          className="broker-input"
          required
          value={data.min_deposit}
          onChange={handleInputChange}
        />
      </div>
  
      <div className="form-group broker-field">
        <label htmlFor="max_deposit">
          Maximum Deposit <span style={{ color: "red" }}>*</span>
          <br/>
          <small className="text-muted">What's your MAXIMUM USD($) Deposit/Withdrawal Amount you can process daily?</small>
        </label>
        <input
          type="number"
          placeholder="Input a value greater than 0"
          name="max_deposit"
          id="max-deposit-input"
          className="broker-input"
          required
          value={data.max_deposit}
          onChange={handleInputChange}
        />
        {errors.depositWarning && (
          <small className="text-danger">{errors.depositWarning}</small>
        )}
      </div>
  
      <div className="form-group broker-field">
        <label htmlFor="proof_of_address">
          Proof of Address <span style={{ color: "red" }}>*</span>
          <br/>
          <small className="text-muted">Must be any of the following; Utility Bill, Government ID, Drivers License.</small>
        </label>
        <input
          type="file"
          name="proof_of_address"
          id="proof-of-address-input"
          className="broker-input"
          accept=".png, .jpeg, .jpg, .pdf"
          required
          onChange={handleInputChange}
        />
        <small className="text-muted">Acceptable formats: '.pdf', '.jpg', '.png'  |  Maximum file size: 20MB </small>
      </div>
  
      <div className="form-group broker-field">
        <label htmlFor="bank_statement">
          Recent Bank Statement <span style={{ color: "red" }}>*</span>
          <br/>
          <small className="text-muted">Bank statement must be within the last 30 days.</small>
        </label>
        <input
          type="file"
          name="bank_statement"
          id="bank-statement-input"
          className="broker-input"
          accept=".png, .jpeg, .jpg, .pdf"
          required
          onChange={handleInputChange}
        />
        <small className="text-muted">Acceptable formats: '.pdf', '.jpg', '.png'  |  Maximum file size: 20MB </small>
      </div>
  
      <button
        type="submit"
        className="btn submit-btn broker-btn"
        onClick={(e) => handleSubmit(e)}
      >
        <p id="submit-broker-request">SUBMIT REQUEST</p>
      </button>
    </form>   
  )
}

export default Form;
