import React, { Component } from "react";
import Select from "react-select";
import tokens from "./tokens.json";
import "./index.scss";

class SelectToken extends Component {
  constructor(props) {
    super(props);

    let selectedToken =  tokens.filter(token => {
      return token.value ===  this.props.selectedToken
    });

    this.state = {
      // selectedOption: tokens[0]
      selectedOption: selectedToken[0]
    };
  }

  handleChange = selectedOption => {
    this.setState({ selectedOption });
    console.log(`Option selected:`, selectedOption);
  };

  render() {
    const { selectedOption } = this.state;

    return (
      <div className="container wyre-trade">
        <div className="form-actions row">
          <div className="col-md-6 pt-4">
            <Select
              className="select-token"
              value={selectedOption}
              onChange={this.handleChange}
              options={tokens}
              getOptionLabel={option => (
                <div className="select-option">
                  <img src={option.image} />
                  <span>{option.label}</span>
                </div>
              )}
            />
          </div>
          <div className="continue col-md-6 pt-4">
            <a
              className="btn btn-primary col-md-4"
              href={`/trade?chain=${selectedOption.value}&token_name=${selectedOption.image.split('/').pop().split('.').slice(0, -1).join('.')}`}
            >
              Continue
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default SelectToken;
