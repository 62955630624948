import React from "react";
import AutoComplete from "../AutoComplete";

const WalletTransfer = ({
  selectedTransferType,
  submitting,
  transferTypes,
  destinationWallets,
  validationErrors,
  recipient,
  lookedUpRecipient,
  recipients,
  selectedDestinationWallet,
  handleChange,
  handleRecipientChange
}) => (
  <div>
    {destinationWallets.length > 0 && (
      <div className="form-group row required mt-2 ">
        <label className="form-label">Select the crypto wallet</label>
        <select
          className="form-control form-control-lg expand-select"
          required="required"
          value={selectedTransferType}
          disabled={submitting == true ? true : false}
          onChange={e => handleChange(e, "selectedTransferType")}
        >
          <option value="">-- Select the Wallet ---</option>
          {transferTypes.map((transferType, i) => (
            <option key={i} value={transferType.value}>
              {transferType.label}
            </option>
          ))}
        </select>
      </div>
    )}
    {(destinationWallets.length === 0 ||
      selectedTransferType === "recipient") && (
      <div className="form-group row required recipient">
        <label className="form-label">Recipient</label>
        <AutoComplete
          updateRecipient={handleRecipientChange}
          placeholder="Enter your recipient's username or email"
          className={`form-control ${
            validationErrors?.recipientError ? "is-invalid" : ""
          }`}
          disabled={submitting == true ? true : false}
          suggestions={recipients}
          lookedUpRecipient={lookedUpRecipient}
          userInput={recipient}
        />
        {validationErrors?.recipientError && (
          <span className="error text-danger">
            <br />
            {validationErrors.recipientError}
          </span>
        )}
        <small id="amountHelpBlock" className="form-text text-muted">
          Enter username or email of the recipient
        </small>
      </div>
    )}
    {selectedTransferType === "my-wallet" && (
      <div className="form-group row required destination_wallet">
        <label className="form-label">Destination wallet</label>
        <select
          className="form-control form-control-lg expand-select"
          value={selectedDestinationWallet}
          onChange={e => this.handleChange(e, "selectedDestinationWallet")}
        >
          {destinationWallets.map((wallet, i) => (
            <option key={i} value={wallet[1]}>
              {wallet[0]}
            </option>
          ))}
        </select>
      </div>
    )}
  </div>
);

export default WalletTransfer;
