import React from "react";

const SuccessAddAppModal = ({ action }) => {

  const handleReload = () => {
    window.location.href = "/partners/api_keys/";
  };  

  return (
    <div
      className="modal-delistoffers-alert-cont"
      aria-hidden="true"
      aria-labelledby="delistOffers"
    >
      <div>
        <h5 className="delistoffer-modal-title">
          <i className="fas fa-check-circle"></i>
          <span>App Successfully {action}!</span>
        </h5>

        <div className="delistoffer-modal-buttons-section">
          <button
            type="button"
            className="delistoffer-modal-tradeaction-proceedbtn"
            onClick={handleReload}
          >
            Okay
          </button>
        </div>
      </div>
    </div>
  )
}

export default SuccessAddAppModal;
