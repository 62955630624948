import React, { useEffect } from "react";
import { postRequest } from "../../utils/httpRequest";

const SetUserOnlineStatus = ({ userIds }) => {
  useEffect(() => {
    postRequest(`/p2p_trades/set_online_status`, {
      user_ids: userIds
    }).then(() => console.log("done"));
  }, [userIds]);

  return <div />;
};

export default SetUserOnlineStatus;
