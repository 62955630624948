import React, { useState } from "react";
import SelectToken from "./components/SelectToken";
import TradeType from "./components/TradeType";
import SelectPaymentMethod from "./components/SelectPaymentMethod";

const SelectTrade = ({ token, updateParent, getData }) => {
  const [tradeType, setTradeType] = useState(getData("tradeType") || "buy");

  const handleUpdateParent = (key, value) => {
    updateParent(key, value);
    if (key === "tradeType") {
      setTradeType(value);
    }
  };

  return (
    <div className="select-trade createoffer-select-trade">
      <div className="">
        <div className="card select-trade-card">
          <div className="card-body">
            <SelectToken token={token} updateParent={handleUpdateParent} />
            <TradeType getData={getData} updateParent={handleUpdateParent} />
          </div>
        </div>
      </div>
      <div className="">
        <SelectPaymentMethod 
          getData={getData} 
          updateParent={handleUpdateParent} 
          tradeType={tradeType}
        />
      </div>
    </div>
  );
};

export default SelectTrade;
