import React, { useState, useEffect } from "react";
import consumer from "channels/consumer";

const UserOnlineStatus = ({ userId, online, lastSeenAt }) => {
  const [onlineStatus, setOnlineStatus] = useState(online);
  const [userLastSeenAt, setLastSeenAt] = useState(lastSeenAt);

  useEffect(() => {
    const channel = consumer.subscriptions.create(
      { channel: "UserOnlineStatusChannel", user_id: userId },
      {
        received(data) {
          setOnlineStatus(data.online);
          setLastSeenAt(data.last_seen_at);
        }
      }
    );

    return () => {
      channel.unsubscribe();
    };
  }, [userId]);

  return (
    <div>
      {onlineStatus && (
        <p>
          <i
            className="fa fa-circle"
            aria-hidden="true"
            style={{ color: "green" }}
          ></i>{" "}
          Online
        </p>
      )}

      {!onlineStatus && <p>Last Seen: {userLastSeenAt}</p>}
    </div>
  );
};

export default UserOnlineStatus;
