import React, { useState, useRef, useEffect } from "react";
import { createConsumer } from "@rails/actioncable";
import {
  postRequest,
  getRequest,
  patchRequest,
} from "../../../utils/httpRequest";
import moment from "moment";
import ReactLoading from "react-loading";
import UserOnlineStatus from "../../UserOnlineStatus";
import "./index.scss";

const TradeChat = ({
  tradeId,
  currentUserId,
  userName,
  isChatDisabled = false,
  chatActionCableUrl,
  hideChat,
  offerLabel,
  userOnline,
  lastSeenAt,
  sellerUsername,
  buyerUsername,
  isSeller,
  isBuyer,
}) => {
  const [selectedFileName, setSelectedFileName] = useState("");
  const [message, setMessage] = useState("");
  const [file, setFile] = useState(null);
  const [messages, setMessages] = useState([]);
  const [isChatLoaded, setIsChatLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");
  const [hasError, setHasError] = useState(false);
  const consumer = useRef();
  const messageContainerRef = useRef(null);
  const channelRef = useRef();

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setValidationMessage("");
    setHasError(false);
  };

  const getChatHistory = () => {
    getRequest(`/p2p_trades/trades/${tradeId}/chats`).then((response) => {
      setMessages(response);
      setIsChatLoaded(true);

      const unreadMessageIds = [];
      if (response.length > 0) {
        response.forEach((msg) => {
          if (!msg.is_read) {
            unreadMessageIds.push(msg.id);
          }
        });
        if (unreadMessageIds.length > 0) {
          markReadMessages(unreadMessageIds);
        }
      }
    });
  };

  const scrollToChatBoxBottom = () => {
    if (messageContainerRef.current) {
      const container = messageContainerRef.current;

      const scrollHeight = container.scrollHeight;
      const clientHeight = container.clientHeight;
      const bottomPosition = scrollHeight - clientHeight;

      // Set the scroll position
      container.scrollTop = bottomPosition;
    }
  };

  const changeUnreadMessageState = () => {
    const messageStateUpdated = messages.map((msg) => {
      msg.is_read = true;
      return msg;
    });
    setMessages(messageStateUpdated);
  };

  const markReadMessages = (unreadMessageIds) => {
    const data = {
      chat_ids: unreadMessageIds,
    };
    patchRequest(
      `/p2p_trades/trades/${tradeId}/chats/update_read_messages`,
      data
    )
      .then((response) => {
        setTimeout(() => {
          changeUnreadMessageState();
        }, 5000);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const  truncateFilenameWithExtension = (filename) => {
    const maxLength = 8;
    
    // Split the filename into the base name and extension
    const dotIndex = filename.lastIndexOf('.');
    const name = dotIndex !== -1 ? filename.slice(0, dotIndex) : filename; // Name without extension
    const extension = dotIndex !== -1 ? filename.slice(dotIndex) : '';    // Extension with dot
  
    // Truncate the name if it's longer than the max length
    const truncatedName = name.length > maxLength ? name.substring(0, maxLength) + '...' : name;
  
    // Return the truncated name with the extension
    return truncatedName + extension;
  }

  const handleSendMessage = () => {
    if (message.trim() === "" && !file) return;
    setIsLoading(true);
    setValidationMessage("");
    setHasError(false);
    let formData = new FormData();
    formData.append("message", message);
    if (file) {
      formData.append("file", file, file.name);
    }

    postRequest(
      `/p2p_trades/trades/${tradeId}/chats`,
      {
        file: formData.get("file"),
        message: formData.get("message"),
      },
      {
        "Content-Type": "multipart/form-data",
      }
    )
      .then((response) => {
        setIsLoading(false);
        setFile(null);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.data?.errors?.length > 0) {
          const message = error.data?.errors[0]?.detail;
          setValidationMessage(message);
          setHasError(true);
        }
      });

    setMessage("");
  };

  useEffect(() => {
    if (!isChatLoaded) {
      getChatHistory();
    }

    if (!consumer.current) {
      consumer.current = createConsumer(chatActionCableUrl);
      // console.log('consumer created'. consumer)
    }
  });

  useEffect(() => {
    if (!channelRef.current) {
      channelRef.current = consumer.current.subscriptions.create(
        { channel: `P2pChatChannel`, id: tradeId },
        {
          received(data) {
            // console.log('receivedMessage', data)

            // Handle incoming messages
            const newMessage = {
              message: data.message,
              profile_id: data.profile_id,
              created_at: data.created_at,
              attachment_url: data.attachment_url,
            };

            setMessages((prevMessages) => {
              const updatedMessages = [...prevMessages, newMessage]
              setIsChatLoaded(false)
              return updatedMessages
            });
          },
          connected() {
            console.log("connected");
          },
          disconnected() {
            consumer.current = null;
            console.log("disconnected");
          },
        }
      );
    }
  }, [tradeId, consumer.current?.subscriptions, setMessages]);

  useEffect(() => {
    scrollToChatBoxBottom()
  }, [messages])

  const fileInputRef = useRef(null);

  const handleFileInputClick = () => {
    if (!isChatDisabled) {
      fileInputRef.current.click(); // Trigger the hidden file input
    }
  };

  const onEnterSendMessage = (e) => {
    if (e.keyCode === 13) {
      setMessage(e.target.value);
      handleSendMessage();
    }
  };

  const getFileInfo = (url) => {
    // Split the URL by "/" and get the last element using spread operator
    const [...parts] = url.split("/");

    // get the filename
    const fileName = parts.pop();

    // Split the file name by "."
    const [...segments] = fileName.split(".");

    // get the file extension
    const fileExtension = segments.pop();

    // get the name of the file without extension
    const fileNameWithoutExtension = segments.join(".");

    return {
      fileName,
      fileExtension,
      fileNameWithoutExtension,
    };
  };

  const removeFile = () => {
    if (confirm("Are you sure?")) {
      setFile(null);
    }
  };

  return (
    <div className="tradechat-section">
      <div className="tradechat-button-cont tradechat-button" onClick={(e) => hideChat()}>
        <i className="fas fa-eye-slash"></i>{" "} 
        <b> Hide Chat &nbsp; &nbsp; </b>
      </div>
      <div className="tradechat-profilename-section">
        <div className="tradechat-about-profile-details">
          <div className="tradechat-about-profile-name">
            {isBuyer ? (
              <p title={`${sellerUsername}`}>
                <a href={`/profile/${sellerUsername}`} className="tradechat-profilename" target="_blank" rel="noopener noreferrer">
                  <i className="fa fa-user-circle"></i>{" "}
                  {sellerUsername
                  ? `${sellerUsername.slice(0, 15)}${sellerUsername.length > 15 ? '...' : ''}`
                  : '...'}{" "}
                </a>
                <i title="Verified Broker" className="fa fa-check-circle" style={{ color: "#f79236" }}></i>
              </p>
            ) : (
              <p title={`${buyerUsername}`}>
                <a href={`/profile/${buyerUsername}`} className="tradechat-profilename" target="_blank" rel="noopener noreferrer">
                  <i className="fa fa-user-circle"></i>{" "}
                  {buyerUsername
                  ? `${buyerUsername.slice(0, 15)}${buyerUsername.length > 15 ? '...' : ''}`
                  : '...'}{" "}
                </a>
                <i title="Verified Broker" className="fa fa-check-circle" style={{ color: "#f79236" }}></i>
              </p>
            )}
           
            <div className="tradechat-online">
              <UserOnlineStatus
                userId={currentUserId}
                online={userOnline}
                lastSeenAt={lastSeenAt}
              />
            </div>
          </div>
        </div> 
      </div>
      <div> <br/><hr className="tradechat-divider" /></div>

      <div className="messagearea"  ref={messageContainerRef} >
        {messages.length > 0 &&
          messages.map((message, index) => (
            <div key={index} className="chat-tryout">
              {message.profile_id !== currentUserId ? (
                <div className="left-chat-cont">
                  <div className="col-md-6 left-chat-section">
                    {message.message && (
                      <div className={`${!message.profile_id ? "admin-message" : ""}`}>
                        <p
                          className={`${
                            message.profile_id === null ? "" : "chat-text-left admin-chatbody"
                          } ${!message.is_read ? "unread-chat" : ""}`}
                        >
                          <br />
                          {!message.is_read ?
                            (<i className="fas fa-envelope message-state-icons"></i>) :
                            (<i className="fas fa-envelope-open message-state-icons"></i>)
                          }
                          <div
                            dangerouslySetInnerHTML={{
                              __html: message.message,
                            }}
                          />
                        </p>

                        {!message.profile_id && (
                          <p
                            className=""
                          >
                            <strong>BananaCrystal Team</strong>
                          </p>
                        )}
                        <p className="text-right left-chat-timestamp">
                          {moment
                            .utc(message.created_at)
                            .local()
                            .startOf("minute")
                            .fromNow()}
                        </p>
                      </div>
                    )}
                    {message.attachment_url && (
                      <p>
                        {[".png", ".jpg", ".jpeg", ".gif"].find((ext) =>
                          message.attachment_url.includes(ext)
                        ) && (
                          <a
                            href={message.attachment_url}
                            target="_blank"
                            title={`Download ${
                              getFileInfo(message.attachment_url)?.fileName
                            }`}
                          >
                            {!message.is_read ?
                              (<i className="fas fa-envelope message-state-icons"></i>) :
                              (<i className="fas fa-envelope-open message-state-icons"></i>)
                            }
                            <img
                              src={message.attachment_url}
                              className="border rounded p-2"
                              width="200px"
                            />
                          </a>
                        )}

                        {![".png", ".jpg", ".jpeg", ".gif"].find((ext) =>
                          message.attachment_url.includes(ext)
                        ) && (
                          <a
                            href={message.attachment_url}
                            target="_blank"
                            className="border rounded p-1 display-block"
                            title={`Download ${
                              getFileInfo(message.attachment_url)?.fileName
                            }`}
                          > 
                            {!message.is_read ?
                              (<i className="fas fa-envelope message-state-icons"></i>) :
                              (<i className="fas fa-envelope-open message-state-icons"></i>)
                            }
                            Download{" "}
                            {getFileInfo(message.attachment_url)?.fileName}
                          </a>
                        )}
                      </p>
                    )}
                  </div>
                </div>
              ) : (
                <div className="right-chat-cont">
                  <div className="right-chat-section">
                    {message.message && (
                      <>
                        <p className="chat-text-right">
                          {!message.is_read ?
                            (<i className="fas fa-check message-state-icons"></i>) :
                            (<i className="fas fa-check-double message-state-icons"></i>)
                          }
                          <div
                            dangerouslySetInnerHTML={{
                              __html: message.message,
                            }}
                          />
                        </p>
                        <p className="text-right right-chat-timestamp">
                          {moment
                            .utc(message.created_at)
                            .local()
                            .startOf("minute")
                            .fromNow()}
                        </p>
                      </>
                    )}
                    {message.attachment_url && (
                      <p className="text-right">
                        {[".png", ".jpg", ".jpeg", ".gif"].find((ext) =>
                          message.attachment_url.includes(ext)
                        ) && (
                          <a
                            href={message.attachment_url}
                            target="_blank"
                            title={`Download ${
                              getFileInfo(message.attachment_url)?.fileName
                            }`}
                          >
                            {!message.is_read ?
                              (<i className="fas fa-check message-state-icons"></i>) :
                              (<i className="fas fa-check-double message-state-icons"></i>)
                            }
                            <img
                              src={message.attachment_url}
                              className="border rounded p-2"
                              width="200px"
                            />
                          </a>
                        )}

                        {![".png", ".jpg", ".jpeg", ".gif"].find((ext) =>
                          message.attachment_url.includes(ext)
                        ) && (
                          <a
                            href={message.attachment_url}
                            title={`Download ${
                              getFileInfo(message.attachment_url)?.fileName
                            }`}
                            target="_blank"
                            className="border rounded p-1 display-block"
                          >
                            {!message.is_read ?
                              (<i className="fas fa-check message-state-icons"></i>) :
                              (<i className="fas fa-check-double message-state-icons"></i>)
                            }
                            Download{" "}
                            {getFileInfo(message.attachment_url)?.fileName}
                          </a>
                        )}
                      </p>
                    )}

                  </div>
                </div>
              )}
            </div>
          ))}
      </div>
      <div className="tradechat-sendmessage-cont">
        <div className="col-12">
          <form className="tradechat-form">
            <div className="tradechat-sendsection">
              <div className="d-flex align-items-center">
                {/* Attachment Icon on the left */}
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={(e) => handleFileChange(e)}
                />
                <button
                  type="button"
                  title="Attach an image or file"
                  className="tradechat-btns"
                >
                  <i className="material-icons" onClick={handleFileInputClick}>
                    attach_file
                  </i>
                </button>
      
                {/* Textarea */}
                <textarea
                  className="tradechat-textarea"
                  rows="15"
                  placeholder={
                    isChatDisabled
                      ? "You no longer can chat on this trade"
                      : "Write a message"
                  }
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  onKeyDown={(e) => onEnterSendMessage(e)}
                  disabled={isChatDisabled}
                  title="Drag the bottom-right corner to expand and resize"
                ></textarea>
      
                {/* Send Button on the right */}
                <button
                  type="button"
                  title="Send your Message"
                  className="tradechat-btns"
                  onClick={handleSendMessage}
                  disabled={isChatDisabled}
                >
                  {isLoading && (
                    <div className="loading text-center">
                      <ReactLoading
                        type="spin"
                        color="#0f59d1"
                        height={25}
                        width={25}
                        className="loader mx-auto"
                      />
                    </div>
                  )}
                  {!isLoading && (
                    <>
                      <i className="material-icons float-right pl-2">send</i>
                    </>
                  )}
                </button>
              </div>
      
              {/* Error Messages and File Attachment Display */}
              <div className="form-group mt-2">
                {file && (
                  <span className="ml-2 mt-1 text-primary file-attachment-name">
                    <i title={file?.name}>{truncateFilenameWithExtension(file?.name)}</i>
                    <i
                      className="material-icons"
                      onClick={(e) => removeFile()}
                      style={{ verticalAlign: "middle", cursor: "pointer" }}
                      title="Remove attachment"
                    >
                      cancel
                    </i>
                  </span>
                )}
              </div>
      
              <div className="allowedfiles-section">
                <small className="text-muted">
                  Allowed: jpg, png, jpeg
                </small>
              </div>
      
              {hasError && (
                <div className="col-12 mt-3 createoffer-error-alert">
                  {validationMessage}
                </div>
              )}
            </div>
          </form>
        </div>
      </div>

    </div>
  );
};

export default TradeChat;
