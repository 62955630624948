import React, { useState } from "react";
import {
  Formik,
  Form,
  Field,
  FormikHelpers,
  FieldArray,
  ErrorMessage
} from "formik";
import { required } from "./validations";
import { postRequest, putRequest } from "../../utils/httpRequest";
import { formErrors } from "../../utils/formErrors";

const ShopifyForm = ({ store_id, integration }) => {
  const [creating, setCreating] = useState(false);
  const [errors, setErrors] = useState({});

  const formattedErrors = errors => {
    const { validationErrors } = this.state;

    const apiKeyError = errors["api_key"];
    const apiSecretKeyError = errors["api_secret_key"];
    const hostNameError = errors["host_name"];

    validationErrors.apiKeyError = apiKeyError;
    validationErrors.apiSecretKeyError = apiSecretKeyError;
    validationErrors.hostNameError = hostNameError;

    return validationErrors;
  };

  const submit = values => {
    const crsfToken = $('meta[name="csrf-token"]').attr("content");

    const data = {
      store_integration: values
    };
    setCreating(true);

    if (integration) {
      putRequest(`/stores/${store_id}/integrations/${integration.id}`, data, {
        "X-CSRF-Token": crsfToken
      })
        .then(response => {
          window.location = `/stores/${store_id}/integrations`;
        })
        .catch(error => {
          const errors = formErrors(error.data.errors);

          setErrors(error);
          setCreating(false);
        });
    } else {
      postRequest(`/stores/${store_id}/integrations`, data, {
        "X-CSRF-Token": crsfToken
      })
        .then(response => {
          window.location = `/stores/${store_id}/integrations`;
        })
        .catch(error => {
          const errors = formErrors(error.data.errors);

          setErrors(error);
          setCreating(false);
        });
    }
  };

  return (
    <Formik
      initialValues={{
        api_key: integration?.api_key || "",
        api_secret_key: integration?.api_secret_key || "",
        host_name: integration?.host_name || "",
        scope: integration?.scope || "",
        code: integration?.code || "",
        is_embedded: integration?.is_embedded || false,
        is_private: integration?.is_private || false,
        api_version: integration?.api_version || "",
        integration_type: "Shopify"
      }}
      onSubmit={values => submit(values)}
    >
      {({ values, touched, handleChange }) => (
        <Form className="form">
          <div className="form-group row required">
            <label htmlFor="api_key" className="form-label store-new-formlabel">
              Api Key
            </label>
            <Field
              id="api_key"
              name="api_key"
              className="form-control"
              validate={required}
              onChange={handleChange}
            />
            <ErrorMessage name="api_key">
              {msg => <div className="error">{msg}</div>}
            </ErrorMessage>
            {errors?.apiKeyError && (
              <div className="error text-danger">
                {validationErrors.apiKeyError}
              </div>
            )}
          </div>
          <div className="form-group row required">
            <label htmlFor="api_secret_key" className="form-label store-new-formlabel">
              Api Secret Key
            </label>
            <Field
              type="password"
              id="api_secret_key"
              name="api_secret_key"
              className="form-control"
              validate={required}
              onChange={handleChange}
            />
            <ErrorMessage name="api_secret_key">
              {msg => <div className="error">{msg}</div>}
            </ErrorMessage>
            {errors?.apiSecretKeyError && (
              <div className="error text-danger">
                {validationErrors.apiSecretKeyError}
              </div>
            )}
          </div>
          <div className="form-group row required">
            <label htmlFor="host_name" className="form-label store-new-formlabel">
              Host Name
            </label>
            <Field
              id="host_name"
              name="host_name"
              className="form-control"
              validate={required}
              onChange={handleChange}
            />
            <ErrorMessage name="host_name">
              {msg => <div className="error">{msg}</div>}
            </ErrorMessage>
          </div>
          {errors?.hostNameError && (
            <div className="error text-danger">
              {validationErrors.hostNameError}
            </div>
          )}
          <div className="form-group row">
            <label htmlFor="scope" className="form-label store-new-formlabel">
              Scope
            </label>
            <Field
              id="scope"
              name="scope"
              className="form-control"
              onChange={handleChange}
            />
            <ErrorMessage name="scope">
              {msg => <div className="error">{msg}</div>}
            </ErrorMessage>
          </div>
          <div className="form-group row">
            <label htmlFor="api_version" className="form-label store-new-formlabel">
              Api Version
            </label>
            <Field
              id="api_version"
              name="api_version"
              className="form-control"
              onChange={handleChange}
            />
            <ErrorMessage name="api_version">
              {msg => <div className="api_version">{msg}</div>}
            </ErrorMessage>
          </div>
          <div className="form-group row form-check">
            <Field
              type="checkbox"
              id="is_embedded"
              name="is_embedded"
              className="form-check-input"
              onChange={handleChange}
            />
            <label htmlFor="is_embedded" className="form-check-label store-new-formlabel">
              Embedded?
            </label>

            <ErrorMessage name="is_embedded">
              {msg => <div className="error">{msg}</div>}
            </ErrorMessage>
          </div>
          <div className="form-group row form-check">
            <Field
              type="checkbox"
              id="is_private"
              name="is_private"
              className="form-check-input"
              onChange={handleChange}
            />

            <label htmlFor="is_private" className="form-check-label">
              Private?
            </label>
            <ErrorMessage name="is_private">
              {msg => <div className="error">{msg}</div>}
            </ErrorMessage>
          </div>
          <div className="action">
            <button
              type="submit"
              className="btn btn-primary store-paidbtn left"
              disabled={creating}
            >
              {creating && (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              Submit
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ShopifyForm;
