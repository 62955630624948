import React from "react";

export const HideIcon = (props) => (
  <svg
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={2}
    viewBox="0 0 24 24"
    height="1.5em"
    width="1.5em"
    {...props}
  >
    <path d="M17.94 17.94A10.07 10.07 0 0112 20c-7 0-11-8-11-8a18.45 18.45 0 015.06-5.94M9.9 4.24A9.12 9.12 0 0112 4c7 0 11 8 11 8a18.5 18.5 0 01-2.16 3.19m-6.72-1.07a3 3 0 11-4.24-4.24M1 1l22 22" />
  </svg>
);

export const ShowIconEye = (props) => {
  return (
    <svg
      fill="none"
      viewBox="0 0 24 24"
      height="1.5em"
      width="1.5em"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M16 12a4 4 0 11-8 0 4 4 0 018 0zm-2 0a2 2 0 11-4 0 2 2 0 014 0z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12 3c5.591 0 10.29 3.824 11.622 9-1.332 5.176-6.03 9-11.622 9S1.71 17.176.378 12C1.71 6.824 6.408 3 12 3zm0 16c-4.476 0-8.269-2.942-9.543-7C3.731 7.942 7.524 5 12 5c4.476 0 8.269 2.942 9.543 7-1.274 4.058-5.067 7-9.543 7z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export const PlusIcon = () => (
  <svg
    viewBox="0 0 24 24"
    fill="currentColor"
    height="2em"
    width="2em"
    strokeWidth="0.5"
  >
    <path d="M5 3h14a2 2 0 012 2v14a2 2 0 01-2 2H5a2 2 0 01-2-2V5c0-1.1.9-2 2-2zm0 2v14h14V5H5zm8 6h2a1 1 0 010 2h-2v2a1 1 0 01-2 0v-2H9a1 1 0 010-2h2V9a1 1 0 012 0v2z" />
  </svg>
);

export const TradeIcon = () => (
  <svg
    viewBox="0 0 24 24"
    fill="currentColor"
    height="2em"
    width="2em"
    strokeWidth="0.5"
  >
    <path fill="none" d="M0 0h24v24H0z" />
    <path d="M4 5v14h16V5H4zM3 3h18a1 1 0 011 1v16a1 1 0 01-1 1H3a1 1 0 01-1-1V4a1 1 0 011-1zm9 6V6l5 5H8V9h4zm-5 4h9v2h-4v3l-5-5z" />
  </svg>
);

export const ArrowTopRight = (props) => (
  <svg
    fill="currentColor"
    viewBox="0 0 16 16"
    height="2em"
    width="2em"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M15 2a1 1 0 00-1-1H2a1 1 0 00-1 1v12a1 1 0 001 1h12a1 1 0 001-1V2zM0 2a2 2 0 012-2h12a2 2 0 012 2v12a2 2 0 01-2 2H2a2 2 0 01-2-2V2zm5.854 8.803a.5.5 0 11-.708-.707L9.243 6H6.475a.5.5 0 110-1h3.975a.5.5 0 01.5.5v3.975a.5.5 0 11-1 0V6.707l-4.096 4.096z"
    />
  </svg>
);

export const IconRecycle = () => (
  <svg fill="currentColor" viewBox="0 0 16 16" height="2em" width="2em">
    <path d="M9.302 1.256a1.5 1.5 0 00-2.604 0l-1.704 2.98a.5.5 0 00.869.497l1.703-2.981a.5.5 0 01.868 0l2.54 4.444-1.256-.337a.5.5 0 10-.26.966l2.415.647a.5.5 0 00.613-.353l.647-2.415a.5.5 0 10-.966-.259l-.333 1.242-2.532-4.431zM2.973 7.773l-1.255.337a.5.5 0 11-.26-.966l2.416-.647a.5.5 0 01.612.353l.647 2.415a.5.5 0 01-.966.259l-.333-1.242-2.545 4.454a.5.5 0 00.434.748H5a.5.5 0 010 1H1.723A1.5 1.5 0 01.421 12.24l2.552-4.467zm10.89 1.463a.5.5 0 10-.868.496l1.716 3.004a.5.5 0 01-.434.748h-5.57l.647-.646a.5.5 0 10-.708-.707l-1.5 1.5a.498.498 0 000 .707l1.5 1.5a.5.5 0 10.708-.707l-.647-.647h5.57a1.5 1.5 0 001.302-2.244l-1.716-3.004z" />
  </svg>
);

export function IconRefund2Fill(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="currentColor"
      height="2em"
      width="2em"
      {...props}
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10a9.96 9.96 0 01-6.383-2.302l-.244-.209.902-1.902a8 8 0 10-2.27-5.837L4 12h2.5l-2.706 5.716A9.954 9.954 0 012 12C2 6.477 6.477 2 12 2zm1 4v2h2.5v2H10a.5.5 0 00-.09.992L10 11h4a2.5 2.5 0 110 5h-1v2h-2v-2H8.5v-2H14a.5.5 0 00.09-.992L14 13h-4a2.5 2.5 0 110-5h1V6h2z" />
    </svg>
  );
}

export const IconZap = (props) => {
  return (
    <svg
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      viewBox="0 0 24 24"
      height="2em"
      width="2em"
      {...props}
    >
      <path d="M13 2L3 14h9l-1 8 10-12h-9l1-8z" />
    </svg>
  );
};

export const IconArrowUpRight = (props) => {
  return (
    <svg
      fill="currentColor"
      viewBox="0 0 16 16"
      height="1em"
      width="1em"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M14 2.5a.5.5 0 00-.5-.5h-6a.5.5 0 000 1h4.793L2.146 13.146a.5.5 0 00.708.708L13 3.707V8.5a.5.5 0 001 0v-6z"
      />
    </svg>
  );
};

export const IconBxTransferAlt = (props) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="currentColor"
      height="2em"
      width="2em"
      {...props}
    >
      <path d="M19.924 10.383a1 1 0 00-.217-1.09l-5-5-1.414 1.414L16.586 9H4v2h15a1 1 0 00.924-.617zM4.076 13.617a1 1 0 00.217 1.09l5 5 1.414-1.414L7.414 15H20v-2H5a.999.999 0 00-.924.617z" />
    </svg>
  );
};

export const IconArrowDownLeftSquare = (props) => {
  return (
    <svg
      fill="currentColor"
      viewBox="0 0 16 16"
      height="2em"
      width="2em"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M15 2a1 1 0 00-1-1H2a1 1 0 00-1 1v12a1 1 0 001 1h12a1 1 0 001-1V2zM0 2a2 2 0 012-2h12a2 2 0 012 2v12a2 2 0 01-2 2H2a2 2 0 01-2-2V2zm10.096 3.146a.5.5 0 11.707.708L6.707 9.95h2.768a.5.5 0 110 1H5.5a.5.5 0 01-.5-.5V6.475a.5.5 0 111 0v2.768l4.096-4.097z"
      />
    </svg>
  );
};

export const IconUserPurple = (props) => {
  return (
    <svg
      width="2rem"
      height="2rem"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {" "}
        <path
          opacity="0.4"
          d="M22 7.81V16.19C22 19 20.71 20.93 18.44 21.66C17.78 21.89 17.02 22 16.19 22H7.81C6.98 22 6.22 21.89 5.56 21.66C3.29 20.93 2 19 2 16.19V7.81C2 4.17 4.17 2 7.81 2H16.19C19.83 2 22 4.17 22 7.81Z"
          fill="#4C3F84"
        ></path>{" "}
        <path
          d="M18.4406 21.66C17.7806 21.89 17.0206 22 16.1906 22H7.81055C6.98055 22 6.22055 21.89 5.56055 21.66C5.91055 19.02 8.67055 16.97 12.0005 16.97C15.3305 16.97 18.0906 19.02 18.4406 21.66Z"
          fill="#4C3F84"
        ></path>{" "}
        <path
          d="M15.5799 11.58C15.5799 13.56 13.9799 15.17 11.9999 15.17C10.0199 15.17 8.41992 13.56 8.41992 11.58C8.41992 9.60002 10.0199 8 11.9999 8C13.9799 8 15.5799 9.60002 15.5799 11.58Z"
          fill="#4C3F84"
        ></path>{" "}
      </g>
    </svg>
  );
};

export const USDTLogo = (props) => {
  return (
    <svg
      width="32px"
      height="33px"
      viewBox="0 0 32 33"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>usdt</title>
      <g
        id="Page-2"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="usdt"
          transform="translate(0.000000, 0.500000)"
          fillRule="nonzero"
        >
          <circle id="Oval" fill="#2DA07C" cx="16" cy="16" r="16"></circle>
          <path
            d="M17.9,17.4 L17.9,17.4 C17.8,17.4 17.2,17.4 16,17.4 C15,17.4 14.3,17.4 14,17.4 L14,17.4 C10.1,17.2 7.2,16.6 7.2,15.7 C7.2,14.9 10.1,14.2 14,14 L14,16.6 C14.3,16.6 15,16.7 16,16.7 C17.2,16.7 17.8,16.7 17.9,16.6 L17.9,14 C21.8,14.2 24.7,14.9 24.7,15.7 C24.7,16.5 21.8,17.2 17.9,17.4 M17.9,13.8 L17.9,11.4 L23.3,11.4 L23.3,7.8 L8.6,7.8 L8.6,11.4 L14,11.4 L14,13.8 C9.6,14 6.3,14.9 6.3,15.9 C6.3,16.9 9.6,17.8 14,18 L14,25.6 L17.9,25.6 L17.9,18 C22.3,17.8 25.6,16.9 25.6,15.9 C25.6,14.9 22.3,14 17.9,13.8"
            id="Shape"
            fill="#FFFFFF"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export const IconUserAddPurple = (props) => {
  return (
    <svg
      viewBox="0 0 24 24"
      height="2rem"
      width="2rem"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {" "}
        <path
          d="M16.6495 20.2V20.49C15.1695 21.44 13.3995 22 11.4995 22C9.56953 22 7.76953 21.42 6.26953 20.43V20.2C6.26953 17.87 8.59953 15.97 11.4595 15.97C14.3295 15.97 16.6495 17.87 16.6495 20.2Z"
          fill="#4c3f84"
        ></path>{" "}
        <path
          opacity="0.4"
          d="M21 12.5C21 15.85 19.27 18.79 16.65 20.49V20.2C16.65 17.87 14.33 15.97 11.46 15.97C8.6 15.97 6.27 17.87 6.27 20.2V20.43C3.7 18.73 2 15.82 2 12.5C2 7.25 6.25 3 11.5 3C12.81 3 14.06 3.25999 15.2 3.73999C15.07 4.13999 15 4.56 15 5C15 5.75 15.21 6.46 15.58 7.06C15.78 7.4 16.04 7.70997 16.34 7.96997C17.04 8.60997 17.97 9 19 9C19.44 9 19.86 8.92998 20.25 8.78998C20.73 9.92998 21 11.19 21 12.5Z"
          fill="#4c3f84"
        ></path>{" "}
        <path
          d="M21.97 2.33002C21.25 1.51002 20.18 1 19 1C17.88 1 16.86 1.46002 16.13 2.21002C15.71 2.64002 15.39 3.15999 15.2 3.73999C15.07 4.13999 15 4.56 15 5C15 5.75 15.21 6.46 15.58 7.06C15.78 7.4 16.04 7.70997 16.34 7.96997C17.04 8.60997 17.97 9 19 9C19.44 9 19.86 8.92998 20.25 8.78998C21.17 8.49998 21.94 7.87 22.42 7.06C22.63 6.72 22.79 6.32999 22.88 5.92999C22.96 5.62999 23 5.32 23 5C23 3.98 22.61 3.04002 21.97 2.33002ZM20.49 5.72998H19.75V6.51001C19.75 6.92001 19.41 7.26001 19 7.26001C18.59 7.26001 18.25 6.92001 18.25 6.51001V5.72998H17.51C17.1 5.72998 16.76 5.38998 16.76 4.97998C16.76 4.56998 17.1 4.22998 17.51 4.22998H18.25V3.52002C18.25 3.11002 18.59 2.77002 19 2.77002C19.41 2.77002 19.75 3.11002 19.75 3.52002V4.22998H20.49C20.9 4.22998 21.24 4.56998 21.24 4.97998C21.24 5.38998 20.91 5.72998 20.49 5.72998Z"
          fill="#4c3f84"
        ></path>{" "}
        <path
          d="M11.4604 14.7299C13.0123 14.7299 14.2704 13.4718 14.2704 11.9199C14.2704 10.3679 13.0123 9.10986 11.4604 9.10986C9.90847 9.10986 8.65039 10.3679 8.65039 11.9199C8.65039 13.4718 9.90847 14.7299 11.4604 14.7299Z"
          fill="#4c3f84"
        ></path>{" "}
      </g>
    </svg>
  );
};

export const IconCopy = (props) => {
  return (
    <svg
      viewBox="0 0 24 24"
      height="10px"
      width="10px"
      id="meteor-icon-kit__solid-copy"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 7H21C22.6569 7 24 8.34315 24 10V21C24 22.6569 22.6569 24 21 24H10C8.34315 24 7 22.6569 7 21V10C7 8.34315 8.34315 7 10 7ZM17 5H10C7.23858 5 5 7.23858 5 10V17H3C1.34315 17 0 15.6569 0 14V3C0 1.34315 1.34315 0 3 0H14C15.6569 0 17 1.34315 17 3V5Z"
          fill="#fff"
        ></path>
      </g>
    </svg>
  );
};

export const IconSearchPurple = (props) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      height="1.7rem"
      width="1.7rem"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {" "}
        <rect width="24" height="24" fill="white"></rect>{" "}
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.25007 2.38782C8.54878 2.0992 10.1243 2 12 2C13.8757 2 15.4512 2.0992 16.7499 2.38782C18.06 2.67897 19.1488 3.176 19.9864 4.01358C20.824 4.85116 21.321 5.94002 21.6122 7.25007C21.9008 8.54878 22 10.1243 22 12C22 13.8757 21.9008 15.4512 21.6122 16.7499C21.321 18.06 20.824 19.1488 19.9864 19.9864C19.1488 20.824 18.06 21.321 16.7499 21.6122C15.4512 21.9008 13.8757 22 12 22C10.1243 22 8.54878 21.9008 7.25007 21.6122C5.94002 21.321 4.85116 20.824 4.01358 19.9864C3.176 19.1488 2.67897 18.06 2.38782 16.7499C2.0992 15.4512 2 13.8757 2 12C2 10.1243 2.0992 8.54878 2.38782 7.25007C2.67897 5.94002 3.176 4.85116 4.01358 4.01358C4.85116 3.176 5.94002 2.67897 7.25007 2.38782ZM9 11.5C9 10.1193 10.1193 9 11.5 9C12.8807 9 14 10.1193 14 11.5C14 12.8807 12.8807 14 11.5 14C10.1193 14 9 12.8807 9 11.5ZM11.5 7C9.01472 7 7 9.01472 7 11.5C7 13.9853 9.01472 16 11.5 16C12.3805 16 13.202 15.7471 13.8957 15.31L15.2929 16.7071C15.6834 17.0976 16.3166 17.0976 16.7071 16.7071C17.0976 16.3166 17.0976 15.6834 16.7071 15.2929L15.31 13.8957C15.7471 13.202 16 12.3805 16 11.5C16 9.01472 13.9853 7 11.5 7Z"
          fill="#4c3f84"
        ></path>{" "}
      </g>
    </svg>
  );
};

export const IconCheck = (props) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      height="1.7rem"
      width="1.7rem"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {" "}
        <rect width="24" height="24" fill="white"></rect>{" "}
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.25007 2.38782C8.54878 2.0992 10.1243 2 12 2C13.8757 2 15.4512 2.0992 16.7499 2.38782C18.06 2.67897 19.1488 3.176 19.9864 4.01358C20.824 4.85116 21.321 5.94002 21.6122 7.25007C21.9008 8.54878 22 10.1243 22 12C22 13.8757 21.9008 15.4512 21.6122 16.7499C21.321 18.06 20.824 19.1488 19.9864 19.9864C19.1488 20.824 18.06 21.321 16.7499 21.6122C15.4512 21.9008 13.8757 22 12 22C10.1243 22 8.54878 21.9008 7.25007 21.6122C5.94002 21.321 4.85116 20.824 4.01358 19.9864C3.176 19.1488 2.67897 18.06 2.38782 16.7499C2.0992 15.4512 2 13.8757 2 12C2 10.1243 2.0992 8.54878 2.38782 7.25007C2.67897 5.94002 3.176 4.85116 4.01358 4.01358C4.85116 3.176 5.94002 2.67897 7.25007 2.38782ZM15.7071 9.29289C16.0976 9.68342 16.0976 10.3166 15.7071 10.7071L12.0243 14.3899C11.4586 14.9556 10.5414 14.9556 9.97568 14.3899L11 13.3656L9.97568 14.3899L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929C8.68342 10.9024 9.31658 10.9024 9.70711 11.2929L11 12.5858L14.2929 9.29289C14.6834 8.90237 15.3166 8.90237 15.7071 9.29289Z"
          fill="#4c3f84"
        ></path>{" "}
      </g>
    </svg>
  );
};

export const IconBookmarkPurple = (props) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      height="1.7rem"
      width="1.7rem"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {" "}
        <path
          opacity="0.4"
          d="M21 7V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V7C3 4 4.5 2 8 2H16C19.5 2 21 4 21 7Z"
          fill="#4c3f83"
        ></path>{" "}
        <path
          d="M15.5 2V9.85999C15.5 10.3 14.98 10.52 14.66 10.23L12.34 8.09C12.15 7.91 11.85 7.91 11.66 8.09L9.34003 10.23C9.02003 10.53 8.5 10.3 8.5 9.85999V2H15.5Z"
          fill="#4c3f83"
        ></path>{" "}
        <path
          d="M17.5 14.75H13.25C12.84 14.75 12.5 14.41 12.5 14C12.5 13.59 12.84 13.25 13.25 13.25H17.5C17.91 13.25 18.25 13.59 18.25 14C18.25 14.41 17.91 14.75 17.5 14.75Z"
          fill="#4c3f83"
        ></path>{" "}
        <path
          d="M17.5 18.75H9C8.59 18.75 8.25 18.41 8.25 18C8.25 17.59 8.59 17.25 9 17.25H17.5C17.91 17.25 18.25 17.59 18.25 18C18.25 18.41 17.91 18.75 17.5 18.75Z"
          fill="#4c3f83"
        ></path>{" "}
      </g>
    </svg>
  );
};

export const IconNotificationPurple = (props) => {
  return (
    <svg
      viewBox="0 0 24 24"
      height="2rem"
      width="2rem"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        <g clip-path="url(#clip0_15_159)">
          <rect width="24" height="24"></rect>
          <path
            d="M9.5 19C8.89555 19 7.01237 19 5.61714 19C4.87375 19 4.39116 18.2177 4.72361 17.5528L5.57771 15.8446C5.85542 15.2892 6 14.6774 6 14.0564C6 13.2867 6 12.1434 6 11C6 9 7 5 12 5C17 5 18 9 18 11C18 12.1434 18 13.2867 18 14.0564C18 14.6774 18.1446 15.2892 18.4223 15.8446L19.2764 17.5528C19.6088 18.2177 19.1253 19 18.382 19H14.5M9.5 19C9.5 21 10.5 22 12 22C13.5 22 14.5 21 14.5 19M9.5 19C11.0621 19 14.5 19 14.5 19"
            stroke="#4c3f83"
            stroke-linejoin="round"
          ></path>
          <path
            d="M12 5V3"
            stroke="#4c3f83"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
        </g>
        <defs>
          <clipPath id="clip0_15_159">
            <rect width="24" height="24" fill="white"></rect>
          </clipPath>
        </defs>
      </g>
    </svg>
  );
};

export const IconNotificationPurpleFilled = (props) => {
  return (
    <svg
      fill="#4c3f83"
      height="1.8rem"
      width="1.8rem"
      {...props}
      version="1.1"
      id="Icons"
      viewBox="0 0 32 32"
    >
      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {" "}
        <g>
          {" "}
          <path d="M26.8,25H5.2c-0.8,0-1.5-0.4-1.9-1.1c-0.4-0.7-0.3-1.5,0.1-2.2L4.5,20c1.8-2.7,2.7-5.8,2.7-9c0-3.7,2.4-7.1,5.9-8.3 C13.7,1.6,14.8,1,16,1s2.3,0.6,2.9,1.7c3.5,1.2,5.9,4.6,5.9,8.3c0,3.2,0.9,6.3,2.7,9l1.1,1.7c0.4,0.7,0.5,1.5,0.1,2.2 C28.4,24.6,27.6,25,26.8,25z"></path>{" "}
        </g>{" "}
        <path d="M11.1,27c0.5,2.3,2.5,4,4.9,4s4.4-1.7,4.9-4H11.1z"></path>{" "}
      </g>
    </svg>
  );
};

export const IconNotificationNonePurple = (props) => {
  return (
    <svg
      height={60}
      width={60}
      fill="#4c3f84"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        <path d="m21.71 20.296-1.786-1.786c.045-.163.076-.332.076-.51v-7h-2v5.586L7.414 6H13V4H6c-.178 0-.347.031-.51.076l-1.78-1.78L2.296 3.71l18 18 1.414-1.414zM4 8.121V18c0 1.103.897 2 2 2h9.879l-2-2H6v-7.879l-2-2z"></path>
        <circle cx="18" cy="6" r="3"></circle>
      </g>
    </svg>
  );
};

export const IconBellPurple = (props) => {
  return (
    <svg
      viewBox="0 0 1024 1024"
      xmlns="http://www.w3.org/2000/svg"
      fill="#4c3f84"
      stroke="#4c3f84"
      height="1.5rem"
      width="1.5rem"
      {...props}
    >
      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {" "}
        <path
          fill="#9f93d2"
          d="M229.6 678.1c-3.7 11.6-5.6 23.9-5.6 36.4 0-12.5 2-24.8 5.7-36.4h-.1zm76.3-260.2H184v188.2h121.9l12.9 5.2L840 820.7V203.3L318.8 412.7z"
        ></path>{" "}
        <path d="M880 112c-3.8 0-7.7.7-11.6 2.3L292 345.9H128c-8.8 0-16 7.4-16 16.6v299c0 9.2 7.2 16.6 16 16.6h101.7c-3.7 11.6-5.7 23.9-5.7 36.4 0 65.9 53.8 119.5 120 119.5 55.4 0 102.1-37.6 115.9-88.4l408.6 164.2c3.9 1.5 7.8 2.3 11.6 2.3 16.9 0 32-14.2 32-33.2V145.2C912 126.2 897 112 880 112zM344 762.3c-26.5 0-48-21.4-48-47.8 0-11.2 3.9-21.9 11-30.4l84.9 34.1c-2 24.6-22.7 44.1-47.9 44.1zm496 58.4L318.8 611.3l-12.9-5.2H184V417.9h121.9l12.9-5.2L840 203.3v617.4z"></path>{" "}
      </g>
    </svg>
  );
};

export const IconNotificationListPurple = (props) => {
  return (
    <svg
      height="1.5rem"
      width="1.5rem"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {" "}
        <circle
          cx="19"
          cy="5"
          r="3"
          stroke="#4c3f84"
          stroke-width="1.5"
        ></circle>{" "}
        <path
          d="M7 14H16"
          stroke="#4c3f84"
          stroke-width="1.5"
          stroke-linecap="round"
        ></path>{" "}
        <path
          d="M7 17.5H13"
          stroke="#4c3f84"
          stroke-width="1.5"
          stroke-linecap="round"
        ></path>{" "}
        <path
          d="M2 12C2 16.714 2 19.0711 3.46447 20.5355C4.92893 22 7.28595 22 12 22C16.714 22 19.0711 22 20.5355 20.5355C22 19.0711 22 16.714 22 12V10.5M13.5 2H12C7.28595 2 4.92893 2 3.46447 3.46447C2.49073 4.43821 2.16444 5.80655 2.0551 8"
          stroke="#4c3f84"
          stroke-width="1.5"
          stroke-linecap="round"
        ></path>{" "}
      </g>
    </svg>
  );
};

export const IconUserIdentification = (props) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      height="2rem"
      width="2rem"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {" "}
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7 4L6.93417 4C6.04769 3.99995 5.28387 3.99991 4.67221 4.08215C4.0167 4.17028 3.38835 4.36902 2.87868 4.87868C2.36902 5.38835 2.17028 6.0167 2.08215 6.67221C1.99991 7.28387 1.99995 8.04769 2 8.93417L2 9L2 15.0658C1.99995 15.9523 1.99991 16.7161 2.08215 17.3278C2.17028 17.9833 2.36902 18.6117 2.87868 19.1213C3.38835 19.631 4.0167 19.8297 4.67221 19.9179C5.28387 20.0001 6.04769 20.0001 6.93417 20H17.0658C17.9523 20.0001 18.7161 20.0001 19.3278 19.9179C19.9833 19.8297 20.6117 19.631 21.1213 19.1213C21.631 18.6117 21.8297 17.9833 21.9179 17.3278C22.0001 16.7161 22.0001 15.9523 22 15.0659V8.93414C22.0001 8.04767 22.0001 7.28387 21.9179 6.67221C21.8297 6.0167 21.631 5.38835 21.1213 4.87868C20.6117 4.36902 19.9833 4.17028 19.3278 4.08215C18.7161 3.99991 17.9523 3.99995 17.0658 4L7 4ZM8 8C6.89543 8 6 8.89543 6 10C6 11.1046 6.89543 12 8 12C9.10457 12 10 11.1046 10 10C10 8.89543 9.10457 8 8 8ZM6 14C6.00001 13.4477 6.44773 13 7.00001 13H7.00006H7.00022H7.00083H7.00325H7.0128L7.04983 13L7.18868 13L7.66981 13L9.00001 13C10.4369 13 11.7623 13.2321 12.511 14.4879C12.7938 14.9623 12.6386 15.5761 12.1642 15.8589C11.6898 16.1418 11.076 15.9865 10.7932 15.5121C10.6342 15.2455 10.3406 15 9.00001 15L7.66979 15L7.18866 15L7.04981 15L7.01277 15H7.00323H7.0008H7.00019H7.00004H6.99999C6.4477 15 5.99999 14.5523 6 14ZM12 8C11.4477 8 11 8.44772 11 9C11 9.55228 11.4477 10 12 10H17C17.5523 10 18 9.55228 18 9C18 8.44772 17.5523 8 17 8H12ZM12 12C12 11.4477 12.4477 11 13 11H17C17.5523 11 18 11.4477 18 12C18 12.5523 17.5523 13 17 13H13C12.4477 13 12 12.5523 12 12ZM15 14C14.4477 14 14 14.4477 14 15C14 15.5523 14.4477 16 15 16H17C17.5523 16 18 15.5523 18 15C18 14.4477 17.5523 14 17 14H15Z"
          fill="#4c3f84"
        ></path>{" "}
      </g>
    </svg>
  );
};

export const IconCardPurple = (props) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      height="2rem"
      width="2rem"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {" "}
        <path
          d="M14 4H10C6.22876 4 4.34315 4 3.17157 5.17157C2.32803 6.01511 2.09185 7.22882 2.02572 9.25H21.9743C21.9082 7.22882 21.672 6.01511 20.8284 5.17157C19.6569 4 17.7712 4 14 4Z"
          fill="#4c3f84"
        ></path>{" "}
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14 20H10C6.22876 20 4.34315 20 3.17157 18.8284C2 17.6569 2 15.7712 2 12C2 11.5581 2 11.142 2.00189 10.75H21.9981C22 11.142 22 11.5581 22 12C22 15.7712 22 17.6569 20.8284 18.8284C19.6569 20 17.7712 20 14 20ZM16.0447 12.25C16.4776 12.2499 16.8744 12.2499 17.1972 12.2933C17.5527 12.3411 17.9284 12.4535 18.2374 12.7626C18.5465 13.0716 18.6589 13.4473 18.7067 13.8028C18.7501 14.1256 18.7501 14.5224 18.75 14.9553V15.0447C18.7501 15.4776 18.7501 15.8744 18.7067 16.1972C18.6589 16.5527 18.5465 16.9284 18.2374 17.2374C17.9284 17.5465 17.5527 17.6589 17.1972 17.7067C16.8744 17.7501 16.4776 17.7501 16.0447 17.75L16 17.75L15.9553 17.75C15.5224 17.7501 15.1256 17.7501 14.8028 17.7067C14.4473 17.6589 14.0716 17.5465 13.7626 17.2374C13.4535 16.9284 13.3411 16.5527 13.2933 16.1972C13.2499 15.8744 13.2499 15.4776 13.25 15.0447L13.25 15L13.25 14.9553C13.2499 14.5224 13.2499 14.1256 13.2933 13.8028C13.3411 13.4473 13.4535 13.0716 13.7626 12.7626C14.0716 12.4535 14.4473 12.3411 14.8028 12.2933C15.1256 12.2499 15.5224 12.2499 15.9553 12.25H16.0447ZM5.25 13.5C5.25 13.0858 5.58579 12.75 6 12.75H8C8.41421 12.75 8.75 13.0858 8.75 13.5C8.75 13.9142 8.41421 14.25 8 14.25H6C5.58579 14.25 5.25 13.9142 5.25 13.5ZM5.25 16.5C5.25 16.0858 5.58579 15.75 6 15.75H10C10.4142 15.75 10.75 16.0858 10.75 16.5C10.75 16.9142 10.4142 17.25 10 17.25H6C5.58579 17.25 5.25 16.9142 5.25 16.5Z"
          fill="#4c3f84"
        ></path>{" "}
        <path
          d="M14.8233 13.8232L14.8257 13.8219C14.8276 13.8209 14.8309 13.8192 14.836 13.8172C14.8577 13.8082 14.9061 13.7929 15.0027 13.7799C15.2134 13.7516 15.5074 13.75 16 13.75C16.4926 13.75 16.7866 13.7516 16.9973 13.7799C17.0939 13.7929 17.1423 13.8082 17.164 13.8172C17.1691 13.8192 17.1724 13.8209 17.1743 13.8219L17.1768 13.8232L17.1781 13.8257C17.1791 13.8276 17.1808 13.8309 17.1828 13.836C17.1918 13.8577 17.2071 13.9061 17.2201 14.0027C17.2484 14.2134 17.25 14.5074 17.25 15C17.25 15.4926 17.2484 15.7866 17.2201 15.9973C17.2071 16.0939 17.1918 16.1423 17.1828 16.164C17.1808 16.1691 17.1791 16.1724 17.1781 16.1743L17.1768 16.1768L17.1743 16.1781C17.1724 16.1791 17.1691 16.1808 17.164 16.1828C17.1423 16.1918 17.0939 16.2071 16.9973 16.2201C16.7866 16.2484 16.4926 16.25 16 16.25C15.5074 16.25 15.2134 16.2484 15.0027 16.2201C14.9061 16.2071 14.8577 16.1918 14.836 16.1828C14.8309 16.1808 14.8276 16.1791 14.8257 16.1781L14.8232 16.1768L14.8219 16.1743C14.8209 16.1724 14.8192 16.1691 14.8172 16.164C14.8082 16.1423 14.7929 16.0939 14.7799 15.9973C14.7516 15.7866 14.75 15.4926 14.75 15C14.75 14.5074 14.7516 14.2134 14.7799 14.0027C14.7929 13.9061 14.8082 13.8577 14.8172 13.836C14.8192 13.8309 14.8209 13.8276 14.8219 13.8257L14.8233 13.8232Z"
          fill="#4c3f84"
        ></path>{" "}
      </g>
    </svg>
  );
};

export const IconLocationPurple = (props) => {
  return (
    <svg
      fill="#4c3f84"
      version="1.1"
      id="Capa_1"
      height="1.7rem"
      width="1.7rem"
      viewBox="0 0 413.099 413.099"
      stroke="#4c3f84"
    >
      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {" "}
        <g>
          {" "}
          <g>
            {" "}
            <path d="M206.549,0L206.549,0c-82.6,0-149.3,66.7-149.3,149.3c0,28.8,9.2,56.3,22,78.899l97.3,168.399c6.1,11,18.4,16.5,30,16.5 c11.601,0,23.3-5.5,30-16.5l97.3-168.299c12.9-22.601,22-49.601,22-78.901C355.849,66.8,289.149,0,206.549,0z M206.549,193.4 c-30,0-54.5-24.5-54.5-54.5s24.5-54.5,54.5-54.5s54.5,24.5,54.5,54.5C261.049,169,236.549,193.4,206.549,193.4z"></path>{" "}
          </g>{" "}
        </g>{" "}
      </g>
    </svg>
  );
};

export const IconPreviewPurple = (props) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      height="2rem"
      width="2rem"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {" "}
        <g opacity="0.5">
          {" "}
          <path
            d="M14 2.75C15.9068 2.75 17.2615 2.75159 18.2892 2.88976C19.2952 3.02503 19.8749 3.27869 20.2981 3.7019C20.7852 4.18904 20.9973 4.56666 21.1147 5.23984C21.2471 5.9986 21.25 7.08092 21.25 9C21.25 9.41422 21.5858 9.75 22 9.75C22.4142 9.75 22.75 9.41422 22.75 9L22.75 8.90369C22.7501 7.1045 22.7501 5.88571 22.5924 4.98199C22.417 3.97665 22.0432 3.32568 21.3588 2.64124C20.6104 1.89288 19.6615 1.56076 18.489 1.40314C17.3498 1.24997 15.8942 1.24998 14.0564 1.25H14C13.5858 1.25 13.25 1.58579 13.25 2C13.25 2.41421 13.5858 2.75 14 2.75Z"
            fill="#4c3f84"
          ></path>{" "}
          <path
            d="M2.00001 14.25C2.41422 14.25 2.75001 14.5858 2.75001 15C2.75001 16.9191 2.75289 18.0014 2.88529 18.7602C3.00275 19.4333 3.21477 19.811 3.70191 20.2981C4.12512 20.7213 4.70476 20.975 5.71085 21.1102C6.73852 21.2484 8.09318 21.25 10 21.25C10.4142 21.25 10.75 21.5858 10.75 22C10.75 22.4142 10.4142 22.75 10 22.75H9.94359C8.10583 22.75 6.6502 22.75 5.51098 22.5969C4.33856 22.4392 3.38961 22.1071 2.64125 21.3588C1.95681 20.6743 1.58304 20.0233 1.40762 19.018C1.24992 18.1143 1.24995 16.8955 1.25 15.0964L1.25001 15C1.25001 14.5858 1.58579 14.25 2.00001 14.25Z"
            fill="#4c3f84"
          ></path>{" "}
          <path
            d="M22 14.25C22.4142 14.25 22.75 14.5858 22.75 15L22.75 15.0963C22.7501 16.8955 22.7501 18.1143 22.5924 19.018C22.417 20.0233 22.0432 20.6743 21.3588 21.3588C20.6104 22.1071 19.6615 22.4392 18.489 22.5969C17.3498 22.75 15.8942 22.75 14.0564 22.75H14C13.5858 22.75 13.25 22.4142 13.25 22C13.25 21.5858 13.5858 21.25 14 21.25C15.9068 21.25 17.2615 21.2484 18.2892 21.1102C19.2952 20.975 19.8749 20.7213 20.2981 20.2981C20.7852 19.811 20.9973 19.4333 21.1147 18.7602C21.2471 18.0014 21.25 16.9191 21.25 15C21.25 14.5858 21.5858 14.25 22 14.25Z"
            fill="#4c3f84"
          ></path>{" "}
          <path
            d="M9.94359 1.25H10C10.4142 1.25 10.75 1.58579 10.75 2C10.75 2.41421 10.4142 2.75 10 2.75C8.09319 2.75 6.73852 2.75159 5.71085 2.88976C4.70476 3.02503 4.12512 3.27869 3.70191 3.7019C3.21477 4.18904 3.00275 4.56666 2.88529 5.23984C2.75289 5.9986 2.75001 7.08092 2.75001 9C2.75001 9.41422 2.41422 9.75 2.00001 9.75C1.58579 9.75 1.25001 9.41422 1.25001 9L1.25 8.90369C1.24995 7.10453 1.24992 5.8857 1.40762 4.98199C1.58304 3.97665 1.95681 3.32568 2.64125 2.64124C3.38961 1.89288 4.33856 1.56076 5.51098 1.40314C6.65019 1.24997 8.10584 1.24998 9.94359 1.25Z"
            fill="#4c3f84"
          ></path>{" "}
        </g>{" "}
        <path
          d="M12 10.75C11.3096 10.75 10.75 11.3096 10.75 12C10.75 12.6904 11.3096 13.25 12 13.25C12.6904 13.25 13.25 12.6904 13.25 12C13.25 11.3096 12.6904 10.75 12 10.75Z"
          fill="#4c3f84"
        ></path>{" "}
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.89243 14.0598C5.29747 13.3697 5 13.0246 5 12C5 10.9754 5.29748 10.6303 5.89242 9.94021C7.08037 8.56222 9.07268 7 12 7C14.9273 7 16.9196 8.56222 18.1076 9.94021C18.7025 10.6303 19 10.9754 19 12C19 13.0246 18.7025 13.3697 18.1076 14.0598C16.9196 15.4378 14.9273 17 12 17C9.07268 17 7.08038 15.4378 5.89243 14.0598ZM9.25 12C9.25 10.4812 10.4812 9.25 12 9.25C13.5188 9.25 14.75 10.4812 14.75 12C14.75 13.5188 13.5188 14.75 12 14.75C10.4812 14.75 9.25 13.5188 9.25 12Z"
          fill="#4c3f84"
        ></path>{" "}
      </g>
    </svg>
  );
};

export const IconCheckPurple = (props) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      height="2rem"
      width="2rem"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {" "}
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM16.0303 8.96967C16.3232 9.26256 16.3232 9.73744 16.0303 10.0303L11.0303 15.0303C10.7374 15.3232 10.2626 15.3232 9.96967 15.0303L7.96967 13.0303C7.67678 12.7374 7.67678 12.2626 7.96967 11.9697C8.26256 11.6768 8.73744 11.6768 9.03033 11.9697L10.5 13.4393L12.7348 11.2045L14.9697 8.96967C15.2626 8.67678 15.7374 8.67678 16.0303 8.96967Z"
          fill="#4c3f84"
        ></path>{" "}
      </g>
    </svg>
  );
};
