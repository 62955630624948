/* Card Validator 
* https://github.com/braintree/card-validator
*/

import * as valid from 'card-validator'


export async function validateCard(number) {
  let isNumberValid = false;

  const numberValidation = valid.number(number);
  isNumberValid = !numberValidation.isValid

  return isNumberValid;
}

export async function validateCardExpiry(exp_month, exp_year) {
  let isDateValid = false;

  const dateValidation = valid.expirationDate(`${exp_month}/${exp_year}`);
  isDateValid = !dateValidation.isValid;

  return isDateValid;
}

export async function validateCardNetwork(number) {
  let cardType = "";

  const numberValidation = valid.number(number);
  isNumberValid = !numberValidation.isPotentiallyValid;

  if (isNumberValid && numberValidation.card) {
    cardtype = numberValidation.card.type;
  }
  return cardType;
}

export const validCardExpiryDate = (expMonth, expYear) => {
  const date = new Date();
  const currentYear = date.getFullYear();

  // Months are returned as (0-11) with Jan being 0 and Dec 11
  const currentMonth = date.getMonth() + 1;

  if (expYear > currentYear) {
    return true;
  }

  return currentYear === expYear && expMonth > currentMonth;
};
