import React from "react";
import paypalLogo from "../../../assets/images/payment_networks/paypal.png";
import cashappLogo from "../../../assets/images/payment_networks/cashapp.png";
import coinbaseLogo from "../../../assets/images/payment_networks/coinbase.png";
import binanceLogo from "../../../assets/images/payment_networks/binance.png";
import kucoinLogo from "../../../assets/images/payment_networks/kucoin.png";
import robinhoodLogo from "../../../assets/images/payment_networks/robinhood.png";

const BlockchainTransfer = ({
  blockchainAddress,
  validationErrors,
  selectedCurrency,
  handleBlockchainAddressChange,
  showModal
}) => (
  <>
    <div className="form-group row required recipient">
      <div className="row ml-2">
        <label className="form-label">To:</label>
      </div>
      <div className="input-group mb-2">
        <input
          step="any"
          autoFocus="autofocus"
          style={{ maxWidth: "400px" }}
          className={`form-control font-0_9 ${
            validationErrors?.blockchainAddressError ? "is-invalid" : ""
          }`}
          required="required"
          placeholder="Payment network or blockchain wallet address"
          type="text"
          value={blockchainAddress}
          onChange={e => handleBlockchainAddressChange(e)}
        />
        <div className="input-group-append border border-dark">
          <span className="input-group-text border-0 mx-0 px-0">
            <a
              href="#"
              role="button"
              className="btn btn-link pull-left border-0"
              data-toggle="modal"
              onClick={() => showModal()}
            >
              <i className="fa fa-book "></i>{" "}
            </a>
          </span>
        </div>
      </div>
      {validationErrors?.blockchainAddressError && (
        <div className="error text-danger">
          <br />
          {validationErrors.blockchainAddressError}
        </div>
      )}
      <div id="amountHelpBlock" className="form-text text-muted mb-4 font-0_8">
        Go to your payment network or blockchain wallet and copy the{" "}
        {selectedCurrency} address. You can use addresses from PayPal, CashApp,
        Coinbase, Binance, KuCoin, Robinhood, or any other wallet that supports{" "}
        {selectedCurrency}.
      </div>
      <br />
      <div className="payment-networks">
        <img src={paypalLogo} width="60" className=" pl-2 pr-2" />
        <img src={cashappLogo} width="110" className="pl-2 pr-2" />
        <img src={coinbaseLogo} width="100" className="pl-2 pr-2" />
        <img src={binanceLogo} width="70" className="pl-2 pr-2" />
        <img src={kucoinLogo} width="70" className="pl-2 pr-2" />
        <img src={robinhoodLogo} width="100" className="pl-2 pr-2" />
        <span style={{ width: 40 }} className="pl-2 pr-2">
          <strong>
            <i className="fa fa-qrcode fa-2x "></i>
          </strong>
        </span>
      </div>

      <div className="alert alert-warning font-0_9 pt-2">
        <p>
          <i className="fa fa-exclamation-circle"></i> To avoid loss of funds,
          ensure the wallet address entered is correct for {selectedCurrency}
          {selectedCurrency == "OPAIG" && <span> on Polygon </span>}
        </p>
      </div>
      <br />
    </div>
  </>
);

export default BlockchainTransfer;
