import React, { useState } from "react";

const FormModal = ({
  walletName,
  url,
  caption,
  isSubmitting,
  handleInputChange,
  handleCloseModal,
  onSubmit
}) => {
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] =useState("");

  const submitForm = (e) => {
    const expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
    const regex = new RegExp(expression);

    if (!url.match(regex)) {
      e.preventDefault();
      setErrorMessage("Please enter a valid url.")
      setHasError(true);
      return;
    }

    if (caption === "") {
      e.preventDefault();
      setErrorMessage("Please enter a caption.")
      setHasError(true);
      return;
    }
    setErrorMessage("")
    setHasError(false);
    onSubmit(e);
  };

  return (
    <div className="modal d-flex align-items-center justify-content-center" tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content" style={{ minWidth: "350px" }}>
          <button
            type="button"
            className="close-addaccount"
            data-dismiss="modal"
            aria-label="Close"
            onClick={e => handleCloseModal(e)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <div>
            <h5 className="modal-title-addaccount" id="exampleModalLongTitle">
              Add Online Wallet
            </h5>
          </div>
          <div className="modal-body">
          {hasError && (
              <div className="createtrade-error">
                {errorMessage}
              </div>
            )}
            <form>
              <div className="form-group">
                <label htmlFor="">URL</label>
                <input
                  className="form-control"
                  placeholder="Type or paste your online wallet URL"
                  value={url}
                  onChange={e => handleInputChange(e, "url")}
                />
              </div>
              <div className="form-group">
                <label htmlFor="">Caption</label>
                <input
                  className="form-control"
                  placeholder="Give it a name for your reference"
                  value={caption}
                  onChange={e => handleInputChange(e, "caption")}
                />
              </div>
              <div className="createoffermodal-addaccount-btns-cont">
                <button
                  type="button"
                  className="createoffer-modal-addaccount-cancelbutton"
                  data-dismiss="modal"
                  onClick={e => handleCloseModal(e)}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="createoffer-modal-addaccount-button"
                  disabled={isSubmitting}
                  onClick={e => submitForm(e)}
                >
                  {isSubmitting ? "Submitting" : "Add"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormModal;
