import React, { FC } from "react";

interface Props {
  isActiveTab: string;
  onChangeTab: (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    tab: string
  ) => void;
}

const Tab: FC<Props> = ({ isActiveTab, onChangeTab }) => (
  <div className="p2p-tabs-head">
    <ul className="nav nav-tabs top-buy-sell-cont">
      <li className="nav-item" role="presentation">
        <a
          href="#"
          className={`${
            isActiveTab === "buy" ? "active buytoggle-default" : "p2p-inactive"
          }`}
          onClick={(e) => onChangeTab(e, "buy")}
        >
          <span className={`${isActiveTab === "buy" ? "active-purple" : ""}`}>
            Buy USD
          </span>
        </a>
      </li>
      <li className="nav-item" role="presentation">
        <a
          href="#"
          className={`${
            isActiveTab === "sell" ? "active buytoggle-default" : "p2p-inactive"
          }`}
          onClick={(e) => onChangeTab(e, "sell")}
        >
          <span className={` ${isActiveTab === "sell" ? "active-orange" : ""}`}>
            Sell USD
          </span>
        </a>
      </li>
    </ul>

    <ul className="_nav-links offerlist-extra-navs">
      <li className="offerlist-extra-navitems">
        {" "}
        <a href="/p2p_trades/offers/new">
          {" "}
          <i className="fa fa-plus-circle" aria-hidden="true"></i>{" "}
          <span>Create Offers</span>
        </a>
      </li>
      <li className="offerlist-extra-navitems">
        {" "}
        <a href="/p2p_trades/offers/my_offers">
          {" "}
          <i className="fa fa-gg-circle" aria-hidden="true"></i>{" "}
          <span>My Offers</span>
        </a>
      </li>
      <li className="offerlist-extra-navitems">
        {" "}
        <a
          href="https://www.bananacrystal.com/docs/how-to-perform-p2p-trade/"
          target="_blank"
        >
          {" "}
          <i className="fa fa-question-circle" aria-hidden="true"></i>{" "}
          <span>User Guide</span>
        </a>
      </li>
    </ul>
  </div>
);

export default Tab;
