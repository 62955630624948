import React, { useState } from "react";
import AccountModal from "./AccountModal";
import "../index.scss"
import NoAccountFoundLoader from "../../../../../../assets/images/noaccountsloaderbc.gif";

const Account = ({ account, deleteAccount, index }) => {
  const [showAccountModal, setShowAccountModal] = useState(false);
  const [copied, setCopied] = useState(false);

  const handleShowAccountModal = (e, value) => {
    e.preventDefault();

    setShowAccountModal(value);
  };

  const handleCopy = (text, field) => {
    navigator.clipboard.writeText(text);
    setCopied(field);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <tr className="bankaccountspage-tablebody-row">
      <td className="bankaccountspage-indexnumber-cont">
        <span className="bankaccountspage-indexnumber">{index + 1}</span>
        <span className="bankaccountspage-actions-mobileicons-cont">
          <a href="#" title="View more details" onClick={(e) => handleShowAccountModal(e, true)} className="bankaccountspage-actions-mobileeyeicon">
            <small>View</small> {" "}
            <i className="fas fa-eye"></i>
          </a>
        </span>
      </td>
      <td data-label="Name:">{account.accountHolderName}</td>
      <td data-label="Bank Name:">
        <div>{account.bankName}</div>
        {/* <div className="font-0_9">{account.country?.name}</div> */}
      </td>
      <td data-label={account.ibanNumber ? "IBAN" : "Account Number"}>
      <div className="font-0_9">{account.ibanNumber ? (<span>IBAN: {account.ibanNumber}</span>) : account.accountNumber}
        <button onClick={() => handleCopy(account.accountNumber, "accountNumber")} className="bankaccountspage-copybtn">
          <i className={`fas fa-${copied === "accountNumber" ? "check" : "copy"}`} title={`${copied ? "Copied" : "Copy"}`}></i>
        </button>
      </div>
      </td>
      <td data-label="Actions:" className="bankaccountspage-table-actions bankaccountspage-table-mobileactions">
        <span className="bankaccountspage-actions-icons-cont">
          <a href="#" title="View more details" onClick={(e) => handleShowAccountModal(e, true, "")} className="bankaccountspage-actions-icons bankaccountspage-actions-hidemobile">
            <i className="fas fa-eye"></i>
          </a>

          <a
            href="#"
            title="Delete"
            className="bankaccountspage-actions-icons"
            onClick={(e) => {
              deleteAccount(e, account.id);
            }}
          >            
            <i className="fas fa-trash"></i>
          </a>
        </span>
      </td>
      {showAccountModal && (
        <AccountModal
          account={account}
          handleShowAccountModal={handleShowAccountModal}
        />
      )}
    </tr>
  );
};

export default Account;
