import React from "react";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const handlePageChange = (e, page) => {
    onPageChange(e, page);
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    if (totalPages <= 20) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else if (currentPage <= 10) {
      for (let i = 1; i <= 20; i++) {
        pageNumbers.push(i);
      }
    } else if (currentPage > totalPages - 10) {
      for (let i = totalPages - 99; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      for (let i = currentPage - 9; i <= currentPage + 10; i++) {
        pageNumbers.push(i);
      }
    }
    return pageNumbers;
  };

  return (
    <div>
      <nav aria-label="Page navigation">
        <ul className="pagination d-flex justify-content-center flex-wrap">
          {currentPage > 1 && (
            <li className="page-item">
              <button
                className="page-link"
                onClick={(e) => handlePageChange(e, currentPage - 1)}
              >
                Previous
              </button>
            </li>
          )}

          {getPageNumbers().map((page) => (
            <li
              key={page}
              className={`page-item ${
                currentPage === page ? "active btn-purple" : ""
              }`}
            >
              <button
                className={`page-link ${
                  currentPage === page ? "btn-purple" : ""
                }`}
                onClick={(e) => handlePageChange(e, page)}
              >
                {page}
              </button>
            </li>
          ))}

          {currentPage < totalPages && (
            <li className="page-item">
              <button
                className="page-link"
                onClick={(e) => handlePageChange(e, currentPage + 1)}
              >
                Next
              </button>
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
};

export default Pagination;
