import React from "react";

const SelectCurrency = ({
  type,
  selectedCurrency,
  currencies,
  handleChange,
  handleSelectedCurrencyChange,
}) => (
  <>
    {type === "wallet" ? (
      <select
        className="form-control expand-select font-0_9"
        required="required"
        value={selectedCurrency}
        onChange={(e) => handleSelectedCurrencyChange(e)}
      >
        {currencies.map((currency, i) => (
          <option key={i} value={currency[1]}>
            {currency[0]}
          </option>
        ))}
      </select>
    ) : (
      <select
        className="form-control expand-select font-0_9 font-weight-bold"
        required="required"
        value={selectedCurrency}
        onChange={(e) => handleSelectedCurrencyChange(e)}
      >
        {currencies.map((currency, i) => (
          <option key={i} value={currency[1]}>
            {currency[0]}
          </option>
        ))}
      </select>
    )}
  </>
);

export default SelectCurrency;
