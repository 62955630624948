import React, { useState } from "react";
import {
  IconCopy,
  IconUserAddPurple,
  IconUserPurple,
  USDTLogo,
} from "../Dashboard/dashboardIcons";

const ReceivePaymentMethod = (props) => {
  const [isCopied, setIsCopied] = useState({
    email: false,
    username: false,
    link: false,
  });
  const [selectedOption, setSelectedOption] = useState(null);

  const copyToClipboard = (elementID, item, type) => {
    const itemInput = document.getElementById(elementID);
    itemInput.select();
    navigator.clipboard.writeText(item).then(
      () => {
        setIsCopied((prevState) => ({
          ...prevState,
          [type]: true,
        }));
        setTimeout(() => {
          setIsCopied((prevState) => ({
            ...prevState,
            [type]: false,
          }));
        }, 2000);
      },
      (err) => {
        console.error("Could not copy text: ", err);
      }
    );
  };

  return (
    <div className="card shadow-sm send-payment-card">
      <div className="card-header flex-column align-items-start">
        <h5 className="card-title mb-0 font-weight-bold">
          Select how you want to receive USD
        </h5>
        <p className="m-0 my-1 font-0_85">
          To receive USD, simply provide your email associated with your
          BananaCrystal account, or your USDT address to the sender.
        </p>
      </div>

      <div className="transfer-options">
        <div className="transfer-option-wrapper option-account d-flex flex-column">
          <div
            className="d-flex flex-row align-items-center justify-content-between"
            onClick={() => setSelectedOption("account")}
          >
            <div className="d-flex flex-row align-items-center">
              <div className="transfer-option-icon">
                <IconUserPurple height="3rem" width="3rem" />
              </div>
              <div className="pl-2">
                <p className="font-weight-bold m-0 my-1">
                  Sender has a BananaCrystal Account
                </p>
                <p className="m-0 my-1">
                  <small>
                    Receive from a sender who is already registered on the
                    BananaCrystal platform.
                  </small>
                </p>
              </div>
            </div>
            <div className="transfer-option-icon text-purple justify-self-end">
              <i className="fa fa-long-arrow-right"></i>
            </div>
          </div>
          {selectedOption === "account" && (
            <div className="option-drop">
              <p className="font-weight-bold font-0_8">
                Provide your username or email to your sender.
              </p>
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="input-group mb-2 address-input-wrapper">
                    <input
                      type="text"
                      className="form-control"
                      id="emailInput"
                      value={props.currentUserEmail}
                    />
                    <div className="drop-icon-wrapper">
                      <div
                        className="address-copy-btn"
                        onClick={() =>
                          copyToClipboard(
                            "emailInput",
                            props.currentUserEmail,
                            "email"
                          )
                        }
                      >
                        {isCopied.email ? (
                          <>
                            Copied <i className="fa fa-check"></i>
                          </>
                        ) : (
                          <>
                            Copy Email <IconCopy />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="input-group mb-2 address-input-wrapper">
                    <input
                      type="text"
                      className="form-control"
                      id="usernameInput"
                      value={props.currentUserName}
                    />
                    <div className="drop-icon-wrapper">
                      <div
                        className="address-copy-btn"
                        onClick={() =>
                          copyToClipboard(
                            "usernameInput",
                            props.currentUserName,
                            "username"
                          )
                        }
                      >
                        {isCopied.username ? (
                          <>
                            Copied <i className="fa fa-check"></i>
                          </>
                        ) : (
                          <>
                            Copy Username <IconCopy />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="transfer-option-wrapper option-invite d-flex flex-column">
          <div
            className="d-flex flex-row align-items-center justify-content-between"
            onClick={() => setSelectedOption("invite")}
          >
            <div className="d-flex flex-row align-items-center">
              <div className="transfer-option-icon">
                <IconUserAddPurple height="3rem" width="3rem" />
              </div>
              <div className="pl-2">
                <p className="font-weight-bold m-0 my-1">
                  Invite sender to join BananaCrystal
                </p>
                <p className="m-0 my-1">
                  <small>
                    Invite a sender to join the BananaCrystal platform they can
                    send you USD.
                  </small>
                </p>
              </div>
            </div>
            <div className="transfer-option-icon text-purple">
              <i className="fa fa-long-arrow-right"></i>
            </div>
          </div>
          {selectedOption === "invite" && (
            <div className="option-drop">
              <p className="font-weight-bold font-0_8">
                Invite your sender to sign up for a BananaCrystal account
              </p>
              <div className="input-group mb-2 address-input-wrapper">
                <input
                  type="text"
                  className="form-control"
                  id="inviteInput"
                  value={props.referralCode}
                />
                <div className="drop-icon-wrapper">
                  <div
                    className="address-copy-btn"
                    onClick={() =>
                      copyToClipboard("inviteInput", props.referralCode, "link")
                    }
                  >
                    {isCopied.link ? (
                      <>
                        Copied <i className="fa fa-check"></i>
                      </>
                    ) : (
                      <>
                        Copy <IconCopy />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="transfer-option-wrapper option-blockchain d-flex flex-column">
          <div
            className="d-flex flex-row align-items-center justify-content-between"
            onClick={() => setSelectedOption("blockchain")}
          >
            <div className="d-flex flex-row align-items-center">
              <div className="transfer-option-icon">
                <USDTLogo height="2.7rem" width="2.7rem" />
              </div>
              <div className="pl-2">
                <p className="font-weight-bold m-0 my-1">
                  Receive USDT on Polygon Network
                </p>
                <p className="m-0 my-1">
                  <small>
                    Receive USDT via the blockchain to your wallet address on
                    the Polygon Network.
                  </small>
                </p>
              </div>
            </div>
            <div className="transfer-option-icon text-purple">
              <i className="fa fa-long-arrow-right"></i>
            </div>
          </div>
          {selectedOption === "blockchain" && (
            <div className="option-drop">
              <p className="font-weight-bold font-0_9">
                <i className="fa fa-info-circle"></i> Only receive USDT on the
                Polygon Blockchain to this address.
              </p>
              <p className="font-0_8">
                Using this address for another blockchain or other coins or
                tokens may result in the irreversible loss of the funds. Please
                always ensure the sender is using the Polygon blockchain and
                sending POS Tether (USDT).
              </p>
              <a
                class="btn btn-primary btn-purple mb-2 w-100"
                type="submit"
                href="/add_funds_crypto/select_token"
              >
                Receive USDT <i className="fa fa-arrow-right"></i>
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default ReceivePaymentMethod;
