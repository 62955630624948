import React from "react";
import ReactTooltip from 'react-tooltip';

const TableBody = ({ policies }) => (
  <tbody>
    {policies.map((policy, i) => (
      <tr key={i}
        className={
          (policy.policy_year == 11 || policy.age == 65 || policy.age == 90) ? "year-" + policy.policy_year + " age-" + policy.age : ''}
          >
        <td>
          <div>
            <span>{policy.policy_year}</span>
            <span>{(policy.policy_year == 11 || policy.age == 65 || policy.age == 90) && (
              <span>
                <a data-toggle="collapse" href={"#help" + policy.age} role="button" aria-expanded="false" aria-controls={"help" + policy.age}>
                  <sup><i className="fa fa-question-circle"></i></sup>
                </a>
                <span className="collapse multi-collapse show" id={"help" + policy.age}><br></br><small>
                  {
                    (policy.policy_year == 11 && " Your Contributions stop for duration of plan, but cash value continues to increase and Death Benefit stays in force at a minimum level.") ||
                    (policy.age == 65 && " At Age 65 - You begin receiving Annual Income, thus reducing Death Benefit. Note: You must have completed your plan premium payments.") ||
                    (policy.age == 90 && " At Age 90 - Your Income Continues to Age 120")
                  }
                  </small>
                </span>
              </span>
            )}
            </span>
          </div>
        </td>
        <td>{policy.age}</td>
        <td>{policy.planned_premium}</td>
        <td>{policy.annual_income}</td>
        <td>{policy.accumulated_value}</td>
        <td>{policy.surrender_value}</td>
        <td>{policy.net_death_benefit}</td>
      </tr>
    ))}
  </tbody>
);

export default TableBody;
