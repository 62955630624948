import React from "react";
interface Props {
  file: any;
}

const DisplayFile: React.FC<Props> = ({ file }) => (
  <>
    {file ? (
      <>
        {file.type.startsWith("image") ? (
          <span>
            <a
              href={URL.createObjectURL(file)}
              target="_blank"
              rel="noopener noreferrer"
            >
              Preview Image
            </a>
          </span>
        ) : (
          <span>
            <br />
            <a
              href={URL.createObjectURL(file)}
              target="_blank"
              rel="noopener noreferrer"
            >
              Preview PDF
            </a>
          </span>
        )}
      </>
    ) : (
      <span>No file uploaded!</span>
    )}
  </>
);

export default DisplayFile;
