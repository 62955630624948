import React, { Component, useState, useEffect } from "react";
import _ from "underscore";
import { getRequest, postRequest, patchRequest } from "../../utils/httpRequest";
import {
  validateWalletAddress,
  validateWalletAddressTag
} from "../../model/walletAddress";
import AutoComplete from "../AutoComplete";
import { formErrors } from "../../utils/formErrors";

const transferTypes = [
  {
    label: "Recipient's Wallet",
    value: "recipient"
  },
  {
    label: "My Wallet",
    value: "my-wallet"
  }
];

class CryptoTransferUsdToMoneyGram extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedSourceWallet: props.selectedSourceWallet,
      selectedDestinationWallet: props.selectedDestinationWallet,
      selectedCurrency: props.selectedSourceToken,
      selectedCurrencyBalance: props.selectedSourceCurrencyBalance,
      alternateCurrency: props.alternateCurrency,
      alternateCurrencyBalance: parseFloat(
        props.alternateCurrencyBalance
      ).toFixed(2),
      alternateCurrencyRate: parseFloat(props.alternateCurrencyRate).toFixed(2),
      balances: props.balances,
      selectedTransferType: "wallet",
      recipient: props.recipient,
      amount: parseFloat(props.amount).toFixed(2) || parseFloat(0.0).toFixed(2),
      fees: "",
      recipientError: "",
      error: "",
      amountError: "",
      mode: "form",
      invalidTransfer: false,
      submitting: false,
      validationErrors: {},
      submitTransfer: false,
      selectedWalletAddress: "",
      note: props.note || "",
      senderRef: props.senderRef || "moneygram_withdrawal",
      isStore: props.isStore || false,
      lookedUpRecipient: {}
    };
  }

  componentDidMount() {}

  handleChange = (event, key) => {
    this.setState({ [key]: event.target.value });

    if (key === "selectedTransferType") {
      this.setState({ invalidTransfer: false });
    }
  };

  handleAmountChange = e => {
    this.setState({ amount: e.target.value }, () => this.validateAmount());
  };

  handleNoteChange = e => {
    this.setState({ note: e.target.value });
  };

  validateAmount = () => {
    const {
      validationErrors,
      selectedCurrency,
      selectedCurrencyBalance,
      balances,
      selectedTransferType
    } = this.state;

    validationErrors.amountError = "";

    this.setState({
      error: "",
      validationErrors,
      invalidTransfer: false,
      selectedCurrencyBalance: this.state.selectedCurrencyBalance
    });

    if (Number(this.state.amount) < 2) {
      validationErrors.amountError =
        "Amount to transfer must be greater than or equal to $2. Please enter a higher amount.";

      this.setState({
        validationErrors,
        invalidTransfer: true
      });

      return;
    }

    if (
      Number(this.state.amount) > Number(this.state.selectedCurrencyBalance)
    ) {
      validationErrors.amountError =
        "Amount to transfer is greater than your available balance, please enter a lower amount.";

      this.setState({
        validationErrors,
        invalidTransfer: true
      });

      return;
    }
  };

  validateFormFields = () => {
    const { amount } = this.state;
    const { type } = this.props;

    let validationErrors = {};

    if (Number(amount) < 2) {
      validationErrors.amountError = "amount should be greater than $2";
    }

    return validationErrors;
  };

  refreshPage = e => {
    e.preventDefault();
    const url = window.location.href;
    window.location.assign(url);
  };

  setApiErrors = errors => {
    const formattedErrors = formErrors(errors);

    const { validationErrors } = this.state;

    const amountError = formattedErrors["gross_amount"];
    const recipientError = formattedErrors["destination_wallet_id"];

    validationErrors.amountError = amountError;
    validationErrors.recipientError = recipientError;

    return validationErrors;
  };

  submitTransferPreview = (e, fees) => {
    const {
      selectedSourceWallet,
      selectedDestinationWallet,
      recipient,
      amount,
      selectedCurrency,
      selectedTransferType,
      selectedCurrencyBalance,
      note,
      senderRef
    } = this.state;

    const validationErrors = this.validateFormFields();

    if (!_.isEmpty(validationErrors)) {
      this.setState({ validationErrors, invalidTransfer: true });

      return;
    }

    const data = {
      crypto_transfer: {
        total_type: "total_to_withdraw",
        source_wallet_id: selectedSourceWallet,
        recipient: recipient,
        destination_wallet_id: selectedDestinationWallet,
        destination_type: "wallet",
        source_currency: "USDT",
        destination_currency: "SUSDC",
        gross_amount: amount,
        gross_amount_currency: "USDT",
        fees: fees,
        fees_currency: "USDT",
        net_amount: amount - fees,
        net_amount_currency: "SUSDC",
        note: note,
        sender_ref: senderRef,
        selected_currency_balance: selectedCurrencyBalance
      }
    };

    const crsfToken = $('meta[name="csrf-token"]').attr("content");

    postRequest("/crypto_transfers", data, {
      "X-CSRF-Token": crsfToken
    })
      .then(response => {
        if (response.external_status === "error") {
          if (response.failure_reason == "Insufficient funds") {
            this.setState({
              error:
                "Insufficient funds, please enter a lower amount to cover the transaction and/or network fees.",
              submitting: false
            });
          } else {
            this.setState({
              error: response.failure_reason,
              submitting: false
            });
          }
        } else {
          this.setState({
            // for moneygram & add also other fee in here
            fees: response.fees.toFixed(2),
            net_amount: response.net_amount,
            submitTransfer: true,
            transferId: response.id
          });
        }
      })
      .catch(error => {
        console.log(error);

        const errors = this.setApiErrors(error.data.errors);

        this.setState({ validationErrors: errors, submitting: false });
      });
  };

  getFees = e => {
    e.preventDefault();

    this.setState({ submitting: true, error: "", validationErrors: {} });

    const { amount } = this.state;

    if (amount >= 2) {
      getRequest(
        `/crypto_transfers/fees?gross_amount=${amount}&payment_method_type=transfer&destination_type=moneygram`
      ).then(response => {
        this.submitTransferPreview(e, response["fees"]);
      });
    }
  };

  submitTransfer = e => {
    const { transferId } = this.state;

    e.preventDefault();

    getRequest(`/crypto_transfers/${transferId}/resend_confirmation`).then(
      () => {
        window.location = `/crypto_transfers/${transferId}`;
      }
    );
  };

  cancelTransfer = e => {
    const { transferId } = this.state;

    e.preventDefault();

    const data = {
      crypto_transfer: {
        id: transferId
      }
    };

    const crsfToken = $('meta[name="csrf-token"]').attr("content");

    patchRequest(`/crypto_transfers/${transferId}/cancel`, data, {
      "X-CSRF-Token": crsfToken
    })
      .then(() => {
        // window.location = `/crypto_transfers/`;
        this.refreshPage(e);
        // window.location = `/crypto_transfers/new_blockchain_to_usd_transfer?token=${selectedCurrency}`;
      })
      .catch(error => {
        this.setState({ submitting: false });
        console.log(error);
      });
  };

  render() {
    const { currencies, type } = this.props;

    const {
      amount,
      selectedCurrencyBalance,
      alternateCurrency,
      alternateCurrencyBalance,
      alternateCurrencyRate,
      error,
      invalidTransfer,
      submitting,
      validationErrors,
      fees,
      transferId,
      submitTransfer,
      note,
      senderRef
    } = this.state;

    return (
      <div className="col-md-12">
        <form
          className="form-horizontal transfer-form"
          id="new_crypto_transfer"
        >
          <div className="col-12">
            <h4>
              <i className="ti-arrow-right "></i> Withdraw{" "}
              <i className="fa fa-usd" aria-hidden="true"></i>
            </h4>

            <hr />
            <br />
            <div className="form-group row required ">
              <label className="form-label">Amount</label>
              <div className="input-group mb-2 mr-sm-2">
                <div className="input-group-prepend">
                  <div
                    className="input-group-text selected-currency font-2_25"
                    style={{ color: "#4173a7", focus: { color: "#414da7" } }}
                  >
                    USD
                  </div>
                </div>
                <input
                  step="any"
                  autoFocus="autofocus"
                  style={{ maxWidth: "340px" }}
                  placeholder="0.000"
                  className={`form-control font-2_25 ${
                    validationErrors?.amountError ? "is-invalid" : ""
                  }`}
                  required="required"
                  type="number"
                  value={amount}
                  onChange={this.handleAmountChange}
                  id="amount"
                  disabled={submitting == true ? true : false}
                />
              </div>
              <div className="font-0_95 text-muted mt-1">
                <span className="text-muted">
                  Min: $2 | Max: $ {selectedCurrencyBalance}
                  {alternateCurrency != "USD" && (
                    <span>
                      {" "}
                      / {alternateCurrency} {alternateCurrencyBalance}
                    </span>
                  )}
                </span>
              </div>
              {error && (
                <span className="error text-danger">
                  <br />
                  {error}
                </span>
              )}
              {validationErrors?.amountError && (
                <span className="error text-danger">
                  <br />
                  {validationErrors.amountError}
                </span>
              )}
              <small id="amountHelpBlock" className="form-text text-muted">
                {/* Enter total transfer amount desired (USD) {selectedCurrency}. */}
              </small>
            </div>
            <div></div>
            <div className="form-group row mt-2">
              <label className="form-label">Description</label>
              <textarea
                className={
                  submitting == true
                    ? "form-control pr-4 border-0"
                    : "form-control pr-4"
                }
                placeholder={submitting == true ? note : ""}
                type="text"
                onChange={this.handleNoteChange}
                id="note"
                value={note}
                disabled={submitting == true ? true : false}
              />
              <small id="noteHelpBlock" className="form-text text-muted">
                Enter your own description or notes regarding this withdrawal
                (optional)
              </small>
            </div>
            <div className="form-group row mt-2">
              <label className="form-label">Reference</label>
              <input
                className="form-control pr-4 border-0"
                type="text"
                id="senderRef"
                value={senderRef}
                disabled={true}
              />
            </div>

            <div>
              <hr />
              <div className="px-md-5 mx-2 preview-list">
                {/* subtotal */}
                <div className="row">
                  <div className="col-5">
                    <div className="form-group row d-block ">
                      <label className="form-label">
                        Subtotal
                        <a
                          data-toggle="tooltip"
                          className="btn btn-link"
                          data-original-title="Subtotal amount"
                        >
                          <i className="fa fa-info-circle"></i>
                        </a>
                      </label>
                      <small
                        id="netAmountHelpBlock"
                        className="form-text text-muted"
                      ></small>
                    </div>{" "}
                  </div>
                  <div className="col-7">
                    <div className="row ">
                      <div className="input-group mb-2 mr-sm-2">
                        <div className="input-group-prepend">
                          <div className="input-group-text selected-currency border-0 font_1_1">
                            $
                          </div>
                        </div>
                        <input
                          className="form-control border-0 font_1_1"
                          placeholder="0.00"
                          data-target="cryptoTransfer.outputNetAmount"
                          required="required"
                          readOnly="readonly"
                          type="number"
                          value={amount}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* fees */}
                <div className="row">
                  <div className="col-md-5">
                    <div className="form-group row d-block ">
                      <label className="form-label">
                        Fees
                        <a
                          data-toggle="tooltip"
                          className="btn btn-link"
                          data-original-title="Transaction and processing fees"
                        >
                          <i className="fa fa-info-circle"></i>
                        </a>
                      </label>
                      <small
                        id="feesHelpBlock"
                        className="form-text text-muted"
                      ></small>
                    </div>{" "}
                  </div>
                  <div className="col-md-7">
                    <div className="form-group row ">
                      <div className="input-group mb-2 mr-sm-2">
                        <div className="input-group-prepend">
                          <div className="input-group-text selected-currency border-0">
                            $
                          </div>
                        </div>
                        <input
                          className="form-control border-0"
                          placeholder="TBD"
                          required="required"
                          readOnly="readonly"
                          type="number"
                          value={fees}
                        />
                      </div>
                    </div>{" "}
                  </div>
                </div>
                {/* net amount */}
                <div className="row">
                  <div className="col-md-5">
                    <div className="form-group row d-block ">
                      <label className="form-label">
                        Total
                        <a
                          data-toggle="tooltip"
                          className="btn btn-link"
                          data-original-title="Total"
                        >
                          <i className="fa fa-info-circle"></i>
                        </a>
                      </label>
                      <small
                        id="netAmountHelpBlock"
                        className="form-text text-muted"
                      ></small>
                    </div>{" "}
                  </div>
                  <div className="col-md-7">
                    <div className="form-group row ">
                      <div className="input-group mb-2 mr-sm-2">
                        <div className="input-group-prepend">
                          <div className="input-group-text selected-currency border-0">
                            $
                          </div>
                        </div>
                        <input
                          className="form-control border-0"
                          placeholder="0.00"
                          required="required"
                          readOnly="readonly"
                          type="number"
                          value={parseFloat(amount - fees).toFixed(2)}
                        />
                      </div>
                    </div>
                    {alternateCurrency != "USD" && amount != 0 && (
                      <div className="row mb-2">
                        <div className="input-group-prepend">
                          <div className="input-group-text selected-currency border-0 font-0_75 pt-0">
                            {alternateCurrency}
                          </div>
                        </div>
                        <span className="font-0_75 text-muted">
                          {parseFloat(
                            alternateCurrencyRate * (amount - fees)
                          ).toFixed(2)}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="text-muted font-0_9">
              {submitting ? (
                <div className="pt-4 mt-2">
                  <i className="fa fa-exclamation-circle"></i>{" "}
                  <strong>Valid for the next 10 min</strong>. If your
                  transaction expires, simply start again. Click Submit to
                  continue.
                </div>
              ) : (
                <div>
                  <i className="fa fa-exclamation-circle"></i> Click Preview to
                  preview your transaction.
                </div>
              )}
            </div>

            <hr />
          </div>
          <div className="form-actions">
            {(submitTransfer && transferId) ? (
              <div className="mr-auto">
                <div className="col-md-6 float-md-right pr-0">
                  <button
                    name="button"
                    type="submit"
                    className="btn btn-primary pull-right mb-3"
                    onClick={this.submitTransfer}
                  >
                    Submit
                  </button>
                </div>
                <div className="col-md-6 float-md-left pl-0">
                  <button
                    name="cancel"
                    type="submit"
                    className="btn pull-left mb-3"
                    onClick={this.cancelTransfer}
                    disabled={invalidTransfer}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              <div className="mr-auto">
                <div className="col-md-6 float-md-right pr-0">
                  <button
                    name="button"
                    type="submit"
                    className="btn btn-primary pull-right mb-2 col-xs-4"
                    onClick={this.getFees}
                    disabled={invalidTransfer || submitting}
                  >
                    {submitting ? (
                      <>
                        <i className="fa fa-circle-o-notch fa-spin"></i>
                        <span style={{ paddingLeft: "5px" }}>
                          Please Wait...Loading
                        </span>
                      </>
                    ) : (
                      <span>Preview</span>
                    )}
                  </button>
                </div>
                <div className="col-md-6 float-md-left pl-0">
                  <button
                    name="start_again"
                    type="submit"
                    className="btn pull-left mb-2"
                    onClick={this.refreshPage}
                  >
                    Start Again
                  </button>
                </div>
              </div>
            )}
          </div>
        </form>
      </div>
    );
  }
}

export default CryptoTransferUsdToMoneyGram;
