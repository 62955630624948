import React from "react";
import { USDTLogo } from "./dashboardIcons";

const TradeHistory = ({ userTradeHistory }) => {
  const truncatedHistory = userTradeHistory?.slice(0, 6);
  return (
    <div id="trade-history" className="tabcontent pt-2 mt-1">
      <div className="data">
        <table className="table table-bordered" width="100%">
          <thead>
            <tr>
              <th>Trade Details</th>
              <th className="text-right">Status</th>
            </tr>
          </thead>
          <tbody>
            {truncatedHistory?.map((history, index) => (
              <tr
                key={index}
                className="trade-row cursor-pointer"
                onClick={() => {
                  window.location.href = history.view_trade_link;
                }}
              >
                <td>
                  <div className="d-inline-flex align-items-center">
                    <USDTLogo />
                    <div className="trade-row-details pl-3">
                      <a
                        href={history.view_trade_link}
                        className="trade-history-link"
                      >
                        <span className="trade-lable font-weight-bold">
                          {history.trade_details.label}
                        </span>
                        <br />
                        <span className="trade-date">{history.date}</span>
                      </a>
                    </div>
                  </div>
                </td>

                <td className="text-right">
                  <a
                    href={history.view_trade_link}
                    className="trade-history-link"
                  >
                    {history.status === "Expired" && (
                      <span className="badge badge-danger-light rounded-pill px-3 py-1">
                        Expired
                      </span>
                    )}
                    {history.status === "Payment Paid" && (
                      <span className="badge badge-warning-light rounded-pill px-3 py-1">
                        Payment Paid
                      </span>
                    )}
                    {history.status === "Payment Accepted" && (
                      <span className="badge badge-primary-light rounded-pill px-3 py-1">
                        Payment Accepted
                      </span>
                    )}
                    {history.status === "Payment Completed" && (
                      <span className="badge badge-success-light rounded-pill px-3 py-1">
                        Payment Completed
                      </span>
                    )}
                    {history.status === "Completed" && (
                      <span className="badge badge-success-light rounded-pill px-3 py-1">
                        Completed
                      </span>
                    )}
                    {history.status === "Cancelled" && (
                      <span className="badge badge-danger-light rounded-pill px-3 py-1">
                        Cancelled
                      </span>
                    )}
                    {![
                      "Expired",
                      "Payment Paid",
                      "Payment Accepted",
                      "Payment Completed",
                      "Completed",
                      "Cancelled",
                    ].includes(history.status) && <span>{history.status}</span>}
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TradeHistory;
