import React, { Component, useState, useEffect } from "react";
import _, { select } from "underscore";
import { ReactSVG } from "react-svg";
import { getRequest, postRequest, patchRequest } from "../../utils/httpRequest";
import {
  validateWalletAddress,
  validateWalletAddressTag,
} from "../../model/walletAddress";
import AutoComplete from "../AutoComplete";
import WalletAddressesModal from "./WalletAddressesModal";

import PayPalLogo from "../../../assets/images/payment_networks/paypal.png";
import { formErrors } from "../../utils/formErrors";

const transferTypes = [
  {
    label: "Recipient's Wallet",
    value: "recipient",
  },
  {
    label: "My Wallet",
    value: "my-wallet",
  },
];

class CryptoTransferToPayPal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      destinationType: props.destinationType,
      sourceWallet: props.sourceWallet,
      destinationWallet: "",
      sourceCurrency: props.sourceCurrency || "USDT",
      sourceCurrencyBalance: props.sourceCurrencyBalance,

      destinationCurrency: props.selectedSourceToken || "BTC",
      destinationCurrencyDescription: props.destinationCurrencyDescription,
      destinationCurrencyImgSrc: props.destinationCurrencyImgSrc,

      exchangeRate: props.exchangeRate,

      selectedTransferType: "recipient",
      recipient: "",
      amount:
        parseFloat(Math.floor(props.sourceCurrencyBalance * 100) / 100).toFixed(
          2
        ) || parseFloat(0.0).toFixed(2),
      fees: "",
      firstTimeWithdrawalFee: parseFloat(0.0).toFixed(2),
      isFirstTimeWithdrawal: props.isFirstTimeWithdrawal || false,
      mode: "form",
      selectedWalletAddress: "",

      withdraw: props.withdraw || false,
      exchangeRate: props.exchangeRate,

      blockchainAddress: "",
      blockchainAddressTag: "",
      walletAddresses: props.walletAddresses || [],

      submitTransfer: false,
      validationErrors: {},
      recipientError: "",
      error: "",
      amountError: "",
      invalidTransfer: false,
      submitting: false,
    };
  }

  componentDidMount() {}

  showModal = () => {
    $("#walletAddressesModal").modal("show");
  };

  closeModal = () => {
    $("#walletAddressesModal").modal("hide");
  };

  getChainWalletAddress = (chain) => {
    getRequest(`/crypto_transfers/chain_wallet_addresses?chain=${chain}`).then(
      (addresses) => {
        const walletAddresses = addresses.map((address) => {
          return {
            label: address.wallet_label,
            address: address.address,
            addressTag: address.tag,
            value: address.id,
          };
        });

        this.setState({ walletAddresses });
      }
    );
  };

  handleAmountChange = (e) => {
    this.setState({ amount: e.target.value }, () => this.validateAmount());
  };

  handleBlockchainAddressChange = (e) => {
    this.setState({ blockchainAddress: e.target.value }, () =>
      this.validateBlockchainAddress()
    );
    this.validateBlockchainAddressTag();
  };

  handleBlockchainAddressTagChange = (e) => {
    this.setState({ blockchainAddressTag: e.target.value }, () =>
      this.validateBlockchainAddressTag()
    );
    this.validateBlockchainAddress();
  };

  handleSelectedWalletAddress = (e) => {
    this.setState({ selectedWalletAddress: e.target.value }, () =>
      this.setBlockchainAddress(e.target.value)
    );
    this.validateBlockchainAddress();
  };

  setBlockchainAddress = (val) => {
    const { walletAddresses, validationErrors } = this.state;

    const walletAddress = _.find(walletAddresses, function (address) {
      return address.value === val;
    });

    if (undefined === walletAddress) {
      this.setState({ blockchainAddress: "" });
      this.setState({ blockchainAddressTag: "" });
      this.setState({ validationErrors: {} });
    } else {
      this.setState({ blockchainAddress: walletAddress.address });
      this.setState({ blockchainAddressTag: walletAddress.addressTag });
    }
  };

  validateBlockchainAddress = () => {
    const {
      destinationCurrency,
      blockchainAddress,
      blockchainAddressTag,
      validationErrors,
    } = this.state;
    const currency = destinationCurrency.toUpperCase();

    const chain = ["BTC", "AVAX", "AVAXC", "POL", "XLM", "SUSDC"].includes(
      currency
    )
      ? currency
      : "ETH";

    const validAddress = validateWalletAddress(chain, blockchainAddress);
    this.setState({
      invalidTransfer: false,
    });

    if (validAddress) {
      validationErrors.blockchainAddressError = "";
    } else {
      validationErrors.blockchainAddressError = "Address is invalid";
      this.setState({
        invalidTransfer: true,
      });
    }
    this.setState({
      validationErrors,
    });
    // console.log("1-2", this.state.validationErrors);
  };

  validateBlockchainAddressTag = () => {
    const {
      destinationCurrency,
      blockchainAddress,
      blockchainAddressTag,
      validationErrors,
    } = this.state;
    const currency = destinationCurrency.toUpperCase();

    const chain = ["BTC", "AVAX", "XLM"].includes(currency) ? currency : "ETH";

    const validAddressTag = validateWalletAddressTag(
      chain,
      blockchainAddressTag
    );

    if (validAddressTag) {
      validationErrors.blockchainAddressTagError = "";
    } else {
      validationErrors.blockchainAddressTagError =
        "Address tag/memo is missing or not required";
    }

    this.setState({ validationErrors });
  };

  validateAmount = () => {
    const { validationErrors } = this.state;
    this.setState({
      error: "",
      sourceCurrencyBalance: this.state.sourceCurrencyBalance,
      invalidTransfer: false,
    });

    // console.log('1', this.state.validationErrors)

    if (Number(this.state.amount) <= 0) {
      validationErrors.amountError =
        "Amount to transfer cannot be zero. Please enter a higher amount.";

      this.setState({
        validationErrors,
        invalidTransfer: true,
      });
    } else if (
      Number(this.state.amount) > Number(this.state.sourceCurrencyBalance)
    ) {
      validationErrors.amountError =
        "Amount to transfer is greater than your available balance, please enter a lower amount.";

      this.setState({
        validationErrors,
        invalidTransfer: true,
      });
    } else {
      validationErrors.amountError = "";
      this.setState({
        validationErrors,
      });
    }
  };

  validateFormFields = () => {
    // console.log('2', this.state.validationErrors)

    let validationErrors = {};

    if (Number(this.state.amount) <= 0) {
      validationErrors.amountError = "amount should be greater than zero";
    } else if (
      Number(this.state.amount) > Number(this.state.sourceCurrencyBalance)
    ) {
      validationErrors.amountError =
        "amount should be less than or equal the balance available";
    }
    if (
      this.state.destinationType === "blockchain" &&
      !this.state.blockchainAddress
    ) {
      validationErrors.blockchainAddressError =
        "blockchain address is required";
    }

    return validationErrors;
  };

  refreshPage = (e) => {
    e.preventDefault();
    const url = window.location.href;
    window.location.assign(url);
  };

  setApiErrors = (errors) => {
    const formattedErrors = formErrors(errors);

    const { validationErrors } = this.state;

    const amountError = formattedErrors["gross_amount"];
    const recipientError = formattedErrors["destination_wallet_id"];

    validationErrors.amountError = amountError;
    validationErrors.recipientError = recipientError;

    return validationErrors;
  };

  submitTransferPreview = (e) => {
    const {
      destinationType,
      sourceWallet,
      destinationWallet,
      destinationWallets,
      recipient,
      amount,
      fees,
      sourceCurrency,
      destinationCurrency,
      exchangeRate,
      selectedTransferType,
      blockchainAddress,
      blockchainAddressTag,
      sourceCurrencyBalance,
    } = this.state;

    e.preventDefault();

    // console.log('3', this.state.validationErrors)

    const validationErrors = this.validateFormFields();

    if (!_.isEmpty(validationErrors)) {
      this.setState({ validationErrors, invalidTransfer: true });
      return;
    }

    this.setState({ submitting: true, error: "", validationErrors: {} });

    const data = {
      crypto_transfer: {
        destination_type: destinationType,
        total_type: "total_to_withdraw",
        source_wallet_id: sourceWallet,
        recipient: selectedTransferType === "recipient" ? recipient : "",
        destination_wallet_id: destinationWallet,
        gross_amount: amount,
        gross_amount_currency: sourceCurrency,
        source_currency: sourceCurrency,
        destination_currency: destinationCurrency,
        fees: fees,
        fees_currency: sourceCurrency,
        net_amount: (amount - fees) * exchangeRate, // temp ammount
        net_amount_currency: destinationCurrency,
        // exchange_rate: exchangeRate,
        blockchain_address: blockchainAddress,
        blockchain_address_tag: blockchainAddressTag,
        selected_currency_balance: sourceCurrencyBalance,
        data: {
          type: "withdrawal",
        },
      },
    };

    const crsfToken = $('meta[name="csrf-token"]').attr("content");

    postRequest("/crypto_transfers", data, {
      "X-CSRF-Token": crsfToken,
    })
      .then((response) => {
        if (response.external_status === "error") {
          if (response.failure_reason == "Insufficient funds") {
            this.setState({
              error:
                "Insufficient funds, please enter a lower amount to cover the transaction and/or network fees.",
              submitting: false,
            });
          } else {
            this.setState({
              error: response.failure_reason,
              submitting: false,
            });
          }
        } else {
          this.setState({
            exchangeRate: response.exchange_rate,
            fees: response.fees,
            submitTransfer: true,
            firstTimeWithdrawalFee: response.first_time_withdrawal_fee,
            transferId: response.id,
            expiresAt: response.expiresAt,
            destinationAmount: response.destination_amount,
            sourceAmount: response.source_amount,
          });
        }
      })
      .catch((error) => {
        console.log(error);

        const errors = this.setApiErrors(error.data.errors);

        this.setState({ validationErrors: errors, submitting: false });
      });
  };

  submitTransfer = (e) => {
    const { transferId } = this.state;

    e.preventDefault();

    getRequest(`/crypto_transfers/${transferId}/resend_confirmation`).then(
      () => {
        window.location = `/crypto_transfers/${transferId}`;
      }
    );
  };

  cancelTransfer = (e) => {
    const { transferId } = this.state;

    e.preventDefault();

    const data = {
      crypto_transfer: {
        id: transferId,
      },
    };

    const crsfToken = $('meta[name="csrf-token"]').attr("content");

    patchRequest(`/crypto_transfers/${transferId}/cancel`, data, {
      "X-CSRF-Token": crsfToken,
    })
      .then(() => {
        // window.location = `/crypto_transfers/`;
        this.refreshPage(e);
        // window.location = `/crypto_transfers/new_blockchain_to_usd_transfer?token=${destinationCurrency}`;
      })
      .catch((error) => {
        this.setState({ submitting: false });
        console.log(error);
      });
  };

  render() {
    const {
      sourceWallets,
      currencies,
      type,
      recipients,
      isFirstTimeWithdrawal,
    } = this.props;

    const {
      sourceWallet,
      destinationWallet,
      sourceCurrency,
      sourceCurrencyBalance,
      destinationCurrency,
      destinationCurrencyDescription,
      destinationCurrencyImgSrc,
      destinationAmount,
      selectedTransferType,
      mode,
      amount,
      fees,
      firstTimeWithdrawalFee,
      submitTransfer,
      transferId,
      blockchainAddress,
      blockchainAddressTag,
      walletAddresses,
      selectedWalletAddress,
      withdraw,
      exchangeRate,
      error,
      amountError,
      invalidTransfer,
      submitting,
      validationErrors,
    } = this.state;

    return (
      <div className="container-fluid">
        <div className="payment-networks mx-auto text-center">
          <img src={PayPalLogo} width="100" className=" pl-2 pr-2 mt-0 mb-0" />
        </div>

        <div className="step-three-wrapper">
          <form
            className="form-horizontal transfer-form pl-2 pr-4"
            id="new_crypto_transfer"
          >
            <div className="amount-wrapper">
              <div className="form-group row required ">
                <label className="form-label">Amount</label>
                <div className="input-group mb-2 mr-sm-2">
                  <div className="input-group-prepend">
                    <div
                      className="input-group-text selected-currency font-2_25"
                      style={{ color: "#4173a7", focus: { color: "#414da7" } }}
                    >
                      {sourceCurrency == "USDT" ? "USD" : sourceCurrency}
                    </div>
                  </div>
                  <input
                    step="any"
                    autoFocus="autofocus"
                    style={{ maxWidth: "400px" }}
                    placeholder="0.000"
                    className={`form-control font-2_25 ${
                      validationErrors?.amountError ? "is-invalid" : ""
                    }`}
                    required="required"
                    type="number"
                    value={amount}
                    onChange={this.handleAmountChange}
                    id="amount"
                    disabled={submitting == true ? true : false}
                  />
                </div>
                <div className="pull-right">
                  <div className="input-group-text form-control font-0_9 border-0 font-weight-bold">
                    {destinationCurrency}{" "}
                    {parseFloat(amount * exchangeRate).toFixed(6)}{" "}
                    {destinationCurrency}
                  </div>
                </div>

                {error && (
                  <span className="error text-danger">
                    <br />
                    {error}
                  </span>
                )}
                {validationErrors?.amountError && (
                  <span className="error text-danger">
                    <br />
                    {validationErrors.amountError}
                  </span>
                )}
                <div
                  id="amountHelpBlock"
                  className="form-text text-muted font-0_8 pl-3"
                >
                  Max: ${" "}
                  {parseFloat(
                    Math.floor(sourceCurrencyBalance * 100) / 100
                  ).toFixed(2)}{" "}
                  ({parseFloat(sourceCurrencyBalance * exchangeRate).toFixed(6)}{" "}
                  {destinationCurrency})
                  <i>
                    1 BTC = {parseFloat(1 / exchangeRate).toFixed(2)}{" "}
                    {sourceCurrency == "USDT" ? "USD" : sourceCurrency}
                  </i>
                </div>
              </div>
              <div className="recipient-wrapper">
                {type === "blockchain" && (
                  <>
                    <div className="form-group row required recipient">
                      <div className="row ml-2">
                        <label className="form-label">To:</label>
                      </div>
                      <div className="input-group mb-2">
                        <input
                          step="any"
                          autoFocus="autofocus"
                          style={{ maxWidth: "400px" }}
                          className={`form-control font-0_9 ${
                            validationErrors?.blockchainAddressError
                              ? "is-invalid"
                              : ""
                          }`}
                          required="required"
                          placeholder={`PayPal ${destinationCurrency} wallet address`}
                          type="text"
                          value={blockchainAddress}
                          onChange={this.handleBlockchainAddressChange}
                        />
                        <div className="input-group-append border border-dark">
                          <span className="input-group-text border-0 mx-0 px-0">
                            <a
                              href="#"
                              role="button"
                              className="btn btn-link pull-left border-0"
                              data-toggle="modal"
                              onClick={this.showModal}
                            >
                              <i className="fa fa-book "></i>{" "}
                            </a>
                          </span>
                        </div>
                      </div>
                      {validationErrors?.blockchainAddressError && (
                        <div className="error text-danger">
                          <br />
                          {validationErrors.blockchainAddressError}
                        </div>
                      )}
                      <div
                        id="amountHelpBlock"
                        className="form-text text-muted mb-2 font-0_8"
                      >
                        On your PayPal, tap Bitcoin, tap your BTC balance. Then
                        tap the Arrows. Choose receive, then copy the{" "}
                        {destinationCurrency} address & paste it in the field
                        above.
                      </div>
                      <br />
                      {destinationCurrency == "XLM" && (
                        <>
                          <input
                            step="any"
                            autoFocus={false}
                            className={`form-control ${
                              validationErrors?.blockchainAddressTagError
                                ? "is-invalid"
                                : ""
                            }`}
                            required={false}
                            placeholder="Enter the blockchain address tag or memo"
                            type="text"
                            value={blockchainAddressTag}
                            onChange={this.handleBlockchainAddressTagChange}
                          />
                          <div
                            id="addressTagtHelpBlock"
                            className="form-text text-muted mb-4 font-0_8"
                          >
                            Enter the blockchain address tag or memo for
                            blockchains that require it, like Stellar (XLM).
                          </div>
                        </>
                      )}
                      {validationErrors?.blockchainAddressTagError && (
                        <div className="error text-danger">
                          <br />
                          {validationErrors.blockchainAddressTagError}
                        </div>
                      )}

                      <div className="alert alert-warning font-0_9">
                        <p>
                          <i className="fa fa-exclamation-circle"></i> To avoid
                          loss of funds, ensure the wallet address entered is
                          correct for {destinationCurrency}
                        </p>
                      </div>
                      <br />
                    </div>
                  </>
                )}
              </div>
              <div className="preview-wrapper">
                <h4>Review Your Transfer Details</h4>
                <hr />
                <div className="px-md-5 mx-2">
                  <div className="row subtotal">
                    <div className="col-md-5">
                      <div className="form-group row d-block ">
                        <label className="form-label">
                          Amount
                          <a
                            data-toggle="tooltip"
                            className="btn btn-link"
                            data-original-title="Subtotal amount (Crypto)."
                          >
                            <i className="fa fa-info-circle"></i>
                          </a>
                        </label>
                        <small
                          id="netAmountHelpBlock"
                          className="form-text text-muted"
                        ></small>
                      </div>{" "}
                    </div>
                    <div className="col-md-7">
                      <div className="form-group row ">
                        <div className="input-group mb-2 mr-sm-2">
                          <div className="input-group-prepend">
                            <div className="input-group-text selected-currency border-0">
                              {sourceCurrency == "USDT" ? "$" : sourceCurrency}
                            </div>
                          </div>
                          <input
                            className="form-control border-0"
                            placeholder="0.00"
                            data-target="cryptoTransfer.outputNetAmount"
                            required="required"
                            readOnly="readonly"
                            type="number"
                            value={amount}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row fees">
                    <div className="col-md-5">
                      <div className="form-group row d-block ">
                        <label className="form-label">
                          Fee
                          <a
                            data-toggle="tooltip"
                            className="btn btn-link"
                            data-original-title="Transaction and processing fees (Crypto)"
                          >
                            <i className="fa fa-info-circle"></i>
                          </a>
                        </label>
                        <small
                          id="feesHelpBlock"
                          className="form-text text-muted"
                        ></small>
                      </div>{" "}
                    </div>
                    <div className="col-md-7">
                      <div className="form-group row ">
                        <div className="input-group mb-2 mr-sm-2">
                          <div className="input-group-prepend">
                            <div className="input-group-text selected-currency border-0">
                              {sourceCurrency == "USDT" ? "$" : sourceCurrency}
                            </div>
                          </div>
                          <input
                            className="form-control border-0"
                            placeholder="TBD"
                            data-target="cryptoTransfer.outputFeesAmount"
                            required="required"
                            readOnly="readonly"
                            type="number"
                            value={parseFloat(fees).toFixed(7)}
                          />
                        </div>
                      </div>{" "}
                    </div>
                  </div>
                  {isFirstTimeWithdrawal && (
                    <div className="row fees">
                      <div className="col-md-5">
                        <div className="form-group row d-block ">
                          <label className="form-label pr-0">
                            One Time Setup Fee
                            <a
                              data-toggle="tooltip"
                              className="btn btn-link pl-1 pr-0"
                              data-original-title="Transaction and processing fees (Crypto)"
                            >
                              <i className="fa fa-info-circle"></i>
                            </a>
                          </label>
                          <small
                            id="feesHelpBlock"
                            className="form-text text-muted"
                          ></small>
                        </div>{" "}
                      </div>
                      <div className="col-md-7">
                        <div className="form-group row ">
                          <div className="input-group mb-2 mr-sm-2">
                            <div className="input-group-prepend">
                              <div className="input-group-text selected-currency border-0">
                                $
                              </div>
                            </div>
                            <input
                              className="form-control border-0"
                              placeholder="TBD"
                              data-target="cryptoTransfer.outputFeesAmount"
                              required="required"
                              readOnly="readonly"
                              type="number"
                              value={parseFloat(firstTimeWithdrawalFee).toFixed(
                                2
                              )}
                            />
                          </div>
                        </div>{" "}
                      </div>
                    </div>
                  )}
                  <div className="row total">
                    <div className="col-5">
                      <div className="form-group row d-block ">
                        <label className="form-label">
                          Total
                          <a
                            data-toggle="tooltip"
                            className="btn btn-link"
                            data-original-title="Total"
                          >
                            <i className="fa fa-info-circle"></i>
                          </a>
                        </label>
                        <small
                          id="netAmountHelpBlock"
                          className="form-text text-muted"
                        ></small>
                      </div>{" "}
                    </div>
                    <div className="col-7">
                      <div className="form-group row ">
                        <div className="input-group mb-2 mr-sm-2">
                          <div className="input-group-prepend">
                            <div className="input-group-text selected-currency border-0 pr-0">
                              {destinationCurrency}
                            </div>
                          </div>
                          <input
                            className="form-control border-0"
                            placeholder="TBD"
                            data-target="cryptoTransfer.outputNetAmount"
                            required="required"
                            readOnly="readonly"
                            type="number"
                            value={parseFloat(destinationAmount).toFixed(7)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-muted font-0_8 text-right">
                {submitting ? (
                  <div className="pt-4 mt-2">
                    <i className="fa fa-exclamation-circle"></i> The quoted
                    transfer fees are <strong>valid for the next 10 min</strong>
                    . If your quote expires, simply start again. Click Submit to
                    continue and send to PayPal
                  </div>
                ) : (
                  <div>
                    <i className="fa fa-exclamation-circle"></i> Click Preview
                    Transfer to preview your transfer including the applicable
                    network fees.
                  </div>
                )}
              </div>
              <hr />
              <div className="form-actions">
                {submitTransfer && transferId ? (
                  <div className="m-auto">
                    <div className="col-md-6 float-md-right">
                      <button
                        name="button"
                        type="submit"
                        className="btn btn-primary pull-right ml-4 mb-4"
                        onClick={this.submitTransfer}
                      >
                        Submit
                      </button>
                    </div>
                    <div className="col-md-6 float-md-left">
                      <button
                        name="cancel"
                        type="submit"
                        className="btn pull-left ml-4 mb-4"
                        onClick={this.cancelTransfer}
                        disabled={invalidTransfer}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="m-auto">
                    <div className="float-md-right">
                      <button
                        name="button"
                        type="submit"
                        className="btn btn-primary pull-right ml-4 mb-4"
                        onClick={this.submitTransferPreview}
                        disabled={invalidTransfer || submitting}
                      >
                        {submitting ? (
                          <>
                            <i className="fa fa-circle-o-notch fa-spin"></i>
                            <span style={{ paddingLeft: "5px" }}>
                              Please Wait...Loading
                            </span>
                          </>
                        ) : (
                          <span>Preview Transfer</span>
                        )}
                      </button>
                    </div>
                    <div className="col-md-6 float-md-left">
                      <button
                        name="start_again"
                        type="submit"
                        className="btn pull-left ml-4 mb-4"
                        onClick={this.refreshPage}
                      >
                        Start Again
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </form>
          <WalletAddressesModal
            walletAddresses={walletAddresses}
            availableWalletAddresses={walletAddresses}
            destinationCurrency={destinationCurrency}
            closeModal={this.closeModal}
            handleSelectedWalletAddress={this.handleSelectedWalletAddress}
          />
        </div>
      </div>
    );
  }
}

export default CryptoTransferToPayPal;
