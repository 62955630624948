import React from "react";
interface Props {
  onClickEdit: (e: any, step: string) => void;
  data: any;
}

const PersonalDetails: React.FC<Props> = ({ data, onClickEdit }) => (
  <div>
    <h6>Personal Details</h6>
    <div className="form-group row">
      <label className="col-12 col-md-3 form-label">First Name</label>
      <div className="col-12 col-md-9">
        <span>{data.firstName}</span>
      </div>
    </div>
    <div className="form-group row">
      <label className="col-12 col-md-3 form-label">Last Name</label>
      <div className="col-12 col-md-9">
        <span>{data.lastName}</span>
      </div>
    </div>
    <div className="form-group row">
      <label className="col-12 col-md-3 form-label">Name On Card</label>
      <div className="col-12 col-md-9">
        <span>{data.nameOnCard}</span>
      </div>
    </div>
    <div className="form-group row">
      <label className="col-12 col-md-3 form-label">Title</label>
      <div className="col-12 col-md-9">
        <span>{data.salutation}</span>
      </div>
    </div>
    <div className="form-group row">
      <label className="col-12 col-md-3 form-label">Occupation</label>
      <div className="col-12 col-md-9">
        <span>{data.occupation}</span>
      </div>
    </div>
    <div className="form-group row">
      <label className="col-12 col-md-3 form-label">Phone Number</label>
      <div className="col-12 col-md-9">
        <span>{data.phoneNumber}</span>
      </div>
    </div>
    <div className="form-group row">
      <label className="col-12 col-md-3 form-label">Gender</label>
      <div className="col-12 col-md-9">
        <span>{data.gender}</span>
      </div>
    </div>
    <div className="ml-auto">
      <a
        className="btn btn-purple"
        href="#"
        onClick={(e) => onClickEdit(e, "personal")}
      >
        Edit <i className="fa fa-pen"></i>
      </a>
    </div>
  </div>
);

export default PersonalDetails;
