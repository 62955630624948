import React, { useEffect, useState } from 'react';
import { getRequest } from '../../utils/httpRequest';
import PropTypes from 'prop-types';

const ProfileOffers = ({ userId, currentExternalProfileId }) => {
  const [offers, setOffers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedTab, setSelectedTab] = useState('sell');

  useEffect(() => {
    const fetchOffers = async () => {
      try {
        const response = await getRequest(`/p2p_trades/offers/by-user/${userId}`);
        setOffers(response.body.data.items);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchOffers();
  }, [userId]);

  if (loading) return <p>Loading offers...</p>;
  if (error) return <p>Error loading offers: {error.message}</p>;

  const filteredOffers = offers.filter(offer => offer.offerTypeField.toLowerCase() === selectedTab);

  const isCurrentUserOffer = filteredOffers.some(offer => offer.profile.id === currentExternalProfileId);

  return (
    <div>
      <h4 className="text-info">Offers</h4>
      <hr />
      {isCurrentUserOffer ? (
        <a href="/p2p_trades/offers/my_offers" className="btn btn-primary">View My Offers</a>
      ) : (
        <>
          <div className="nav nav-tabs">
            <button onClick={() => setSelectedTab('sell')} className={selectedTab === 'sell' ? 'active-purple' : ''}>Buy</button>
            <button onClick={() => setSelectedTab('buy')} className={selectedTab === 'buy' ? 'active-orange' : ''}>Sell</button>
          </div>
          {filteredOffers.length > 0 ? (
            <table className="table table-responsive w-100 d-block d-md-table">
              <thead>
                <tr>
                  <th>Offer Label</th>
                  <th>Price</th>
                  <th>Limit Available</th>
                  <th>Payment Method</th>
                  <th>Trade</th>
                </tr>
              </thead>
              <tbody>
                {filteredOffers.map((offer) => (
                  <tr key={offer.id}>
                    <td>{offer.customOfferLabel}</td>
                    <td>{offer.tradeRate}</td>
                    <td>{offer.rangeMin}{offer.fiatCurrency.code} - {offer.rangeMax}{offer.fiatCurrency.code}</td>
                    <td>{offer.paymentMethodLabel}</td>
                    <td className='text-white'>
                      <a href={`/p2p_trades/offers/trade/${offer.id}/create`} className={selectedTab === 'buy' ? 'btn sell-btn' : 'btn buy-btn'}>
                        {selectedTab === 'buy' ? 'Sell' : 'Buy'} USD
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>User has no {selectedTab} offers available</p>
          )}
        </>
      )}
    </div>
  );
};

ProfileOffers.propTypes = {
  userId: PropTypes.string.isRequired,
  currentExternalProfileId: PropTypes.string.isRequired,
};

export default ProfileOffers;
