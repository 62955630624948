import React, { useState, useEffect } from 'react';
import { getRequest } from '../../../../../utils/httpRequest';

const CheckBrokerStatus = () => {
  const [brokerStatus, setBrokerStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBrokerStatus = async () => {
      try {
        // Use GET request for fetching broker status
        const response = await getRequest('/api/v1/brokers/check_broker');
        setBrokerStatus(response);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchBrokerStatus();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      {brokerStatus.broker_exists &&  brokerStatus.status !== 'approved' ? (
        <div className="broker-status-cont">
          <div className="status-alert-cont">
            <i className="fa fa-info-circle status-icon"></i>
            <h4 className="status-alert-heading">Your Broker Request Status</h4>
            <p>Your request to become a broker is currently: <strong className="status-text">{brokerStatus.status.toUpperCase()}</strong>.</p>
            {brokerStatus.status === 'pending' ? (
              <p>Your request is under review. Please check back within the next 24 - 72 hours for updates.</p>
            ) : (
              <></>
            )}
            {brokerStatus.status === 'rejected' ? (
              <p>Unfortunately, your broker request has been rejected. You may contact support for more information.</p>
            ) : (
              <></>
            )}
            {brokerStatus.status === 'deactivated' ? (
              <p>Your broker status has been deactivated. Please contact support if you believe this is an error.</p>
            ) : (
              <></>
            )}
            <br/>
            <a href="/p2p_trades/offers/search?offer_type=buy">
              <button type="button" className="btn broker-status-cancelbtn">CANCEL</button>
            </a>
          </div>
        </div>
      ) : (
        <></>
      )}

      {!brokerStatus.broker_exists ? (
        <div className="broker-status-cont">
          <div className="status-alert-cont">
            <i className="fa fa-info-circle status-icon"></i>
            <h4 className="status-alert-heading">You are not a Broker</h4>
            <p>To create offers, you need to be a registered broker.</p>
            <a href="https://www.bananacrystal.com/brokers-application/">
              <button type="button" className="btn broker-status-btn">BECOME A BROKER</button>
            </a>
            <br/>
            <a href="/p2p_trades/offers/search?offer_type=buy">
              <button type="button" className="btn broker-status-cancelbtn">CANCEL</button>
            </a>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default CheckBrokerStatus;
