// ChatInput.jsx (Chat input component)
import React, { useState, useEffect } from "react";
import consumer from "channels/consumer";
import { postRequest } from "../../utils/httpRequest";

const ChatInput = ({ tradeId, currentUserId }) => {
  const [message, setMessage] = useState("");
  const [file, setFile] = useState(null);

  const handleFileChange = e => {
    console.log(e);
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const handleSendMessage = () => {
    if (message.trim() === "") return;

    const messageData = {
      message,
      user_id: currentUserId,
      trade_id: tradeId
    };

    consumer.subscriptions.subscriptions[0].send({
      command: "message",
      identifier: JSON.stringify({ channel: "P2pChatChannel", id: tradeId }),
      data: JSON.stringify(messageData)
    });

    setMessage("");
  };

  const handleUpload = () => {
    console.log("the file", file);
    if (file) {
      let formData = new FormData();
      formData.append("file", file);

      postRequest(`/p2p_trades/trades/${tradeId}/chats`, formData)
        .then(response => {
          console.log(response);
        })
        .catch(error => console.log(error));
    }
  };

  return (
    <div>
      <input
        type="text"
        placeholder="Type your message..."
        value={message}
        onChange={e => setMessage(e.target.value)}
      />
      <button onClick={handleSendMessage}>Send</button>
      <div>
        <input type="file" onChange={e => handleFileChange(e)} />
        <button onClick={() => handleUpload()}>Upload</button>
      </div>
    </div>
  );
};

const Chat = ({ tradeId, currentUserId }) => {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const chatChannel = consumer.subscriptions.create(
      { channel: "P2pChatChannel", id: tradeId },
      {
        received(data) {
          // Handle incoming messages
          const newMessage = {
            content: data.message,
            user_id: data.user_id,
            attachment: data.attachment_url
          };
          setMessages(prevMessages => [...prevMessages, newMessage]);
        }
      }
    );

    return () => {
      chatChannel.unsubscribe();
    };
  }, [tradeId]);

  return (
    <div>
      <div className="chat-box">
        <ChatInput tradeId={tradeId} currentUserId={currentUserId} />
        {messages.map((message, index) => (
          <div key={index}>
            {`User ${message.user_id}: ${message.content}`}{" "}
            {message.attachment && <img src={message.attachment} />}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Chat;
