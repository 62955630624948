import React from "react";

const SelectToken = ({ token, updateParent }) => (
  <div className="select-token">
    <h4 className="">Selected Currency</h4>
    <div className="tokens">
      <button
        className={`btn btn-light ${token === "USDT" ? "active" : "inactive"}`}
        onClick={() => updateParent("token", "USDT")}
      >
        <div className="font-1_4 pb-2"><strong>USD</strong> <span className="text-muted pt-1">U.S. Dollar ($)</span></div>
        
      </button>
    </div>
  </div>
);

export default SelectToken;
