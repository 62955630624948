import React from "react";
import Head from "./Head";
import Body from "./Body";
import { Transaction } from "../Types";

interface Props {
  transactions: Transaction[];
  loading: boolean;
}

const Table: React.FC<Props> = ({ transactions, loading }) => (
  <div className="container-fluid">
    <div className="row">
      <div className="col-md-12">
        <div className="card rounded-lg shadow-sm table-responsive">
          <table className="table table-responsive w-100 d-block d-md-table">
            <Head />
            {loading ? (
              <tbody>
                <tr>
                  <td colSpan={8} className="text-center">
                    Loading...
                  </td>
                </tr>
              </tbody>
            ) : (
              <Body transactions={transactions} />
            )}
          </table>
        </div>
      </div>
    </div>
  </div>
);

export default Table;
