import React, { Component } from "react";
import moment from "moment";
import _ from "underscore";

interface Props {
  onSelectFilter: (key: string, value: any, isDate?: boolean) => void;
  getTransactions: (e: any) => void;
  filters: {
    startDate: any;
    endDate: any;
  };
}

interface State {
  startDate: any;
  endDate: any;
  date: string;
  kind: string;
}

class Filters extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      startDate: moment().subtract(2, "months").startOf("month"),
      endDate: moment(),
      date: "",
      kind: "today",
    };
  }

  componentDidMount() {
    this.setDatePicker();
  }

  setDatePicker = () => {
    const that = this;

    var start = moment().subtract(2, "months").startOf("month");
    var end = moment();

    function cb(start, end) {
      const startDate = moment(start);
      const endDate = moment(end);

      that.setState({
        startDate,
        endDate,
      });

      that.props.onSelectFilter("startDate", startDate);
      that.props.onSelectFilter("endDate", endDate);
    }

    ($("#date-range") as any).daterangepicker(
      {
        startDate: start,
        endDate: end,
        ranges: {
          Today: [moment(), moment()],

          "Last 7 Days": [moment().subtract(6, "days"), moment()],
          "This Month": [moment().startOf("month"), moment().endOf("month")],
          "Last Month": [
            moment().subtract(1, "months").startOf("month"),
            moment().subtract(1, "months").endOf("month"),
          ],
          "Last 6 Months": [
            moment().subtract(6, "months").startOf("month"),
            moment().endOf("month"),
          ],
          "Last Year": [
            moment().subtract(1, "years").startOf("month"),
            moment().endOf("month"),
          ],
        },
      },
      cb
    );

    cb(start, end);
  };

  setDateFilters = () => {
    const { onSelectFilter } = this.props;
    onSelectFilter("startDate", this.state.startDate, true);
    onSelectFilter("endDate", this.state.endDate, true);
  };

  render() {
    const { getTransactions } = this.props;

    return (
      <div>
        <form className="form-inline my-2 my-lg-0 ml-3 pt-4">
          <input
            id="date-range"
            placeholder="Date range"
            className="form-control rounded-sm font-0_9 pl-2 pr-2 mb-4"
            style={{ minWidth: 200 }}
            onClick={this.setDateFilters}
          />
          <button
            className="btn btn-tiny btn-purple ml-sm-0 ml-md-4 pt-2 pb-2 mb-4"
            type="submit"
            onClick={getTransactions}
          >
            Apply
          </button>
        </form>
      </div>
    );
  }
}

export default Filters;
