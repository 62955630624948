import { Controller } from "stimulus";
import { validateWalletAddress } from "../model/walletAddress";

export default class extends Controller {
  static targets = ["chain", "selectedAddress", "address", "addressTag"];

  onChangeBlockchainAddress() {
    if (this.selectedAddressTarget.value == 'Select wallet address from ETH blockchain') {
      this.addressTarget.value= null;
      this.addressTagTarget.value = null;
    } else if (this.selectedAddressTarget.value) {
      let wallet_address = this.selectedAddressTarget.value.split(':')
      this.addressTarget.value = wallet_address[0];

      if (wallet_address.length > 1) {
        this.addressTagTarget.value = wallet_address[1];
      } else {
        this.addressTagTarget.value = null;
      }
    }
  }

  onChangeChain() {
    $.ajax({
      url: "/transfers/new_blockchain_transfer",
      type: "GET",
      dataType: "script",
      data: {
        source_wallet_id: document.getElementById("transfer_source_wallet_id")
          .value,
        chain: $(".chain option:selected").val()
      }
    });

    this.validateAddress();
  }

  validateAddress() {
    const chain = this.chainTarget.value?.toUpperCase();
    const address = this.addressTarget.value;

    const validAddress = validateWalletAddress(chain, address);

    if (validAddress) {
      this.addressTarget.setCustomValidity("");
    } else {
      this.addressTarget.setCustomValidity("Address is invalid");
    }
  }
  validateAddressTag() {
    const chain = this.chainTarget.value?.toUpperCase();
    const address = this.addressTarget.value;
    const addressTag = this.addressTagTarget.value;

    const validAddressTag = validateWalletAddressTag(chain, address);

    if (validAddressTag) {
      this.addressTagTarget.setCustomValidity("");
    } else {
      this.addressTagTarget.setCustomValidity("Address Tag or Memo is missing or it is not required");
    }
  }
}
