import React from "react";
import { Field, FormikHelpers, FormikErrors } from "formik";
import SelectField from "../common/SelectField";
import { titleOptions, genderOptions, dialingCodeOptions } from "./helpers";
import { required } from "../../../../../utils/formValidations";
import { IconUserPurple } from "../../../../Dashboard/dashboardIcons";

interface Props {
  apiErrors?: any;
  errors: FormikErrors<{
    salutation: string;
    phone_code: string;
    phone_number: string;
    gender: string;
    occupation: string;
    name_on_card: string;
  }>;
  validateForm: () => Partial<FormikHelpers<any>>;
  handleNextStepChange: (
    validateForm: () => Partial<FormikHelpers<any>>,
    step: string
  ) => void;
  handlePreviousStepChange: (step: string) => void;
}

const PersonalDetails: React.FC<Props> = ({
  apiErrors,
  errors,
  validateForm,
  handleNextStepChange,
  handlePreviousStepChange,
}) => {
  return (
    <>
      <div className="card-body">
        <div id="personal-details">
          <div className="w-100 d-flex align-items-center mb-3">
            <h4>
              <IconUserPurple /> Personal Details
            </h4>
          </div>
          <div className="row card-deck mb-5">
            <form className="col-md-12">
              <div className="row">
                <div className="col-md-6 form-group">
                  <label htmlFor="first_name">First Name</label>
                  <Field
                    name="first_name"
                    className="form-control bg-gray"
                    validate={required}
                    disabled={true}
                  />
                </div>
                <div className="col-md-6 form-group">
                  <label htmlFor="last_name">Last Name</label>
                  <Field
                    name="last_name"
                    className="form-control bg-gray"
                    validate={required}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 form-group">
                  <label htmlFor="salutation">Title</label>
                  <Field
                    name="salutation"
                    component={SelectField}
                    validate={required}
                    error={errors.salutation}
                    placeholder="Select title"
                    options={titleOptions}
                  />
                  {errors.salutation && !apiErrors?.salutation_error && (
                    <small className="error">{errors.salutation}</small>
                  )}
                  {apiErrors?.salutation_error && (
                    <small className="error text-danger">
                      {apiErrors.salutation_error}
                    </small>
                  )}
                </div>
                <div className="col-md-6 form-group">
                  <label htmlFor="phone_number">Phone Number</label>
                  <div className="d-flex">
                    <div
                      style={{
                        width: "50%",
                        marginRight: "7px",
                        fontSize: "14px",
                      }}
                    >
                      <Field
                        name="phone_code"
                        component={SelectField}
                        validate={required}
                        options={dialingCodeOptions}
                        placeholder="Code"
                        error={errors.phone_code}
                      />
                    </div>
                    <Field
                      name="phone_number"
                      type="number"
                      placeholder="Enter Phone Number"
                      className={`form-control ${
                        errors.phone_number ? "is-invalid" : ""
                      }`}
                      validate={required}
                    />
                  </div>
                  {errors.phone_number && !apiErrors?.phone_number_error && (
                    <small className="error">{errors.phone_number}</small>
                  )}
                  {apiErrors?.phone_number_error && (
                    <small className="error text-danger">
                      {apiErrors.phone_number_error}
                    </small>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 form-group">
                  <label htmlFor="gender">Gender</label>
                  <Field
                    name="gender"
                    placeholder="Select gender"
                    component={SelectField}
                    validate={required}
                    options={genderOptions}
                    error={errors.gender}
                  />
                  {errors.gender && !apiErrors?.gender_error && (
                    <small className="error">{errors.gender}</small>
                  )}
                  {apiErrors?.gender_error && (
                    <small className="error text-danger">
                      {apiErrors.gender_error}
                    </small>
                  )}
                </div>
                <div className="col-md-6 form-group">
                  <label htmlFor="occupation">Occcupation</label>
                  <Field
                    name="occupation"
                    className="form-control"
                    placeholder="Enter your occupation"
                    validate={required}
                  />
                  {errors.occupation && !apiErrors?.occupation && (
                    <small className="error">{errors.occupation}</small>
                  )}
                  {apiErrors?.occupation_error && (
                    <small className="error text-danger">
                      {apiErrors.occupation_error}
                    </small>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 form-group">
                  <label htmlFor="name_on_card">Name On Card</label>
                  <Field
                    name="name_on_card"
                    className="form-control"
                    placeholder="Enter name on card"
                    validate={required}
                  />
                  {errors.name_on_card && !apiErrors?.name_on_card && (
                    <small className="error">{errors.name_on_card}</small>
                  )}
                  {apiErrors?.name_on_card && (
                    <small className="error text-danger">
                      {apiErrors.name_on_card_error}
                    </small>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="p-3">
        <div className="d-flex flex-row justify-content-between">
          <button
            type="button"
            className="btn btn-outline-purple float-left"
            onClick={(e) => handlePreviousStepChange("cardType")}
          >
            <i className="fa fa-arrow-left"></i> Previous
          </button>
          <button
            type="button"
            className="btn btn-purple float-right"
            onClick={(e) =>
              handleNextStepChange(validateForm, "identification")
            }
          >
            Next <i className="fa fa-arrow-right"></i>
          </button>
        </div>
      </div>
    </>
  );
};

export default PersonalDetails;
