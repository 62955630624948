import { formErrors } from "../../../../utils/formErrors";
export const validateAmount = (amount: number, balance) => {
  if (Number(amount) < 5) {
    return "Minimum amount is $10";
  }

  if (Number(amount) > Number(balance)) {
    return "Amount to load is greater than your available balance, please enter a lower amount.";
  }

  return "";
};

export const setApiErrors = (errors, validationErrors) => {
  const formattedErrors = formErrors(errors);

  const amountError = formattedErrors["gross_amount"];
  const recipientError = formattedErrors["destination_wallet_id"];

  validationErrors.amountError = amountError;
  validationErrors.recipientError = recipientError;

  return validationErrors;
};
