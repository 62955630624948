import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "source" ]

  copy() {
    // this.sourceTarget.select()
    // document.execCommand("copy")
    const text = this.sourceTarget.value || this.sourceTarget.innerText?.trim()
    navigator.clipboard.writeText(text)
  }

  copyTextarea() {
    //console.log('textarea', this.sourceTarget.select())
    // this.sourceTarget.select()
    // document.execCommand("copy")
    navigator.clipboard.writeText(this.sourceTarget.value)
  }
}
