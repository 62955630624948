import React, { useEffect, useState, useMemo, useCallback } from "react";
import Select from "react-select";
import countries from "../../../../../utils/countries.json";
import { FiatProps } from "../Types";

interface Props {
  attribute: string;
  fiatCurrencies: FiatProps[];
  allLabel: string;
  iconClass: string;
  items: string[];
  label: string;
  handleFilter: (value: string, allLabel: string) => void;
  preferredValue?: string;
}

export const MenuSelect: React.FC<Props> = ({
  attribute,
  allLabel,
  iconClass,
  items,
  label,
  handleFilter,
  preferredValue,
  fiatCurrencies,
}) => {
  const [selectedValue, setSelectedValue] = useState<string>("");

  // Memoizing options list to prevent unnecessary recalculations
  const options = useMemo(() => {
    let optionsList = label === "currency"
      ? fiatCurrencies.map((item) => ({
          value: item.code,
          label: `${item.name} (${item.code})`,
        }))
      : items.map((item) => ({
          value: item,
          label: label === "country"
            ? countries.find((country) => country.code2 === item)?.name
            : item,
        }));

    if (allLabel) {
      optionsList.unshift({
        value: "",
        label: allLabel,
      });
    }
    return optionsList;
  }, [label, items, fiatCurrencies, allLabel]);

  // Memoizing handleChange to avoid re-creations on each render
  const handleChange = useCallback(
    (selectedOption: any) => {
      const value = selectedOption ? selectedOption.value : "";
      setSelectedValue(value);
      handleFilter(label, value);
    },
    [label, handleFilter]
  );

  useEffect(() => {
    if (preferredValue && preferredValue !== selectedValue) {
      setSelectedValue(preferredValue);
      handleFilter(label, preferredValue);
    }
  }, [preferredValue, selectedValue, label, handleFilter]);

  return (
    <div>
      <Select
        className="custom-select"
        value={options.find((option) => option.value === selectedValue)}
        onChange={handleChange}
        options={options}
        placeholder="Select an option"
      />
      <span className="filter-icon">&nbsp;</span>
    </div>
  );
};

export default MenuSelect;
