import React from "react";

const Body = ({ loads, cardId }) => {
  return (
    <tbody className="table-body">
      {loads.map((load, i) => (
        <tr key={load.id}>
          <td>
            <div className="d-flex justify-content-between">
              <span className="show-from-tab font-weight-bold">Date:</span>
              <span className="text-muted">{new Date(load.created_at).getFullYear()}-{(new Date(load.created_at).getMonth() + 1).toString().padStart(2, '0')}-{new Date(load.created_at).getDate().toString().padStart(2, '0')}</span>
            </div>
          </td>
          <td>
            <div className="d-flex justify-content-between">
              <span className="show-from-tab font-weight-bold">Amount:</span>
              <span className="text-muted">{load.amount}</span>
            </div>
          </td>
          <td>
            <div className="d-flex justify-content-between">
              <span className="show-from-tab font-weight-bold">Status:</span>
              <span className="text-muted">{load.status}</span>
            </div>
          </td>
          <td className="">
            <div className="d-flex justify-content-between">
              <span className="show-from-tab font-weight-bold">Loaded At:</span>
              <span className="text-muted">{new Date(load.loaded_at).toLocaleString([], { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).replace(/\//g, '-')}</span>
            </div>
          </td>
          <td>
            <a
              className="btn btn-sm btn-purple w-100"
              href={`/cards/${cardId}/card_loads/${load.id}`}
            >
              View <i className="fa fa-long-arrow-right"></i>
            </a>
          </td>
        </tr>
      ))}
      {loads.length === 0 && (
        <tr>
          <td colSpan={8} className="text-center">
            Currently you have no card loads
          </td>
        </tr>
      )}
    </tbody>
  );
};

export default Body;
