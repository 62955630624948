import { Controller } from "stimulus";
import Rails from "@rails/ujs";
import axios from "axios";

export default class extends Controller {
  static targets = [
    "inputGrossAmount",
    "inputPaymentMethodType",
    "outputGrossAmount",
    "outputFeesAmount",
    "outputNetAmount"
  ];

  connect() {
    let payment_fees, netAmount, paymentMethodType, grossAmount;
    // inputs
    if (this.hasInputPaymentMethodTypeTarget) {
      paymentMethodType = this.inputPaymentMethodTypeTarget.value;
    }
    if (this.hasInputGrossAmountTarget) {
      grossAmount = this.inputGrossAmountTarget.value;
      this.inputGrossAmountTarget.setCustomValidity("");
    }

    if (paymentMethodType && grossAmount && grossAmount >= 100) {
      this.getLimit(grossAmount, paymentMethodType).then(message => {
        if (message) {
          this.inputGrossAmountTarget.setCustomValidity(message);
        } else {
          this.paymentFees(grossAmount, paymentMethodType);
        }
      });
    }
  }

  getLimit(grossAmount, paymentMethodType) {
    return axios
      .get(
        `/limits?gross_amount=${grossAmount}&payment_method_type=${paymentMethodType}`,
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
      .then(response => response.data.message);
  }

  paymentFees(grossAmount, paymentMethodType) {
    let response;
    Rails.ajax({
      type: "GET",
      url: `/payments/fees?gross_amount=${grossAmount}&payment_method_type=${paymentMethodType}`,
      success: (_data, _status, xhr) => {
        response = JSON.parse(xhr.response);
        this.outputGrossAmountTarget.value = response["gross_amount"];
        this.outputFeesAmountTarget.value = response["fees"];
        this.outputNetAmountTarget.value = response["net_amount"];
      }
    });
  }
}
