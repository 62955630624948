import React from "react";

const ReceiveModal = ({ token, tokenAddress, tokenImage, closeModal }) => (
  <div
    className="modal fade"
    id="tokenAddressModal"
    tabIndex="-1"
    aria-labelledby="tokenAddressModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div className="modal-content">
        <div className="modal-header mb-4">
          <h5 className="modal-title" id="tokenAddressModalLabel"></h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => closeModal()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body" data-controller="clipboard">
          <div className="row">
            <div className="col-md-12">
              <p className="font-weight-bold">
              <span className="text-warning ">Use this address only to receive</span> <img src={token?.image} width="30" height="30" /> {token.name}.
              </p>
              <p className="text-warning">
                When funding your account, convert your desired {token.name}{" "}
                balance to USD to avoid market price fluctuations.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <label className="font-0_9 font-weight-bold">
                Your {token.name} Wallet Address
              </label>
              <input
                className="form-control border-0 font-1_0 pt-0 user-select-all"
                data-clipboard-target="source"
                type="text"
                value={tokenAddress}
                readOnly
              />
              <hr />
              <label className="font-0_8">Copy <img src={token?.image} width="20" height="20" /> Address</label>
              <div className="input-group-append">
                <div className="input-group-text border-0">
                  <button
                    title="copy to clipboard"
                    className="btn btn-secondary"
                    data-action="clipboard#copy"
                  >
                    <i className="fa fa-clipboard fa-2x fa-fw"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <label className="font-0_8">Scan <img src={token?.image} width="20" height="20" /> Address</label>
              <br />
              {tokenImage && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: tokenImage
                  }}
                />
              )}
              <br />
            </div>
            <div className="col-md-12">
              <div className="font-0_8 text-muted pt-4 ml-3">
                <p>
                  <strong> Only send {token.label} to this address</strong>.
                  <br />
                  Using this address for another blockchain or other coins or
                  tokens will result in the irreversible loss of your funds.
                  Please always check with your sending party that the
                  blockchain is {token.value} and the cryptocurrency is{" "}
                  {token.label}.
                </p>
              </div>
            </div>
          </div>
          <div className="modal-footer ml-auto border-0">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ReceiveModal;
