export const greetUser = (name) => {
  const hour = new Date().getHours();
  if (hour < 12) return "Good Morning, ";
  if (hour < 18) return "Good Afternoon, ";
  return "Good Evening, ";
};

export const cryptoBalance = (
  type,
  balances,
  preferredCrypto,
  alt_currency
) => {
  let preferredAsset = balances?.find(
    (balance) => balance.crypto === preferredCrypto
  );
  if (preferredAsset) {
    if (type === "crypto_balance") {
      return preferredAsset.crypto_balance;
    } else if (type === "alt_balance") {
      return preferredAsset.alt_balance;
    } else if (type === "usd_balance") {
      return preferredAsset.usd_balance;
    }
  }
  if (type === "crypto_balance") {
    return "$ 0.00";
  } else {
    return `${alt_currency} 0.00`;
  }
};
