import React, { useEffect, useRef, memo, useState } from "react";

function CurrencyTrends({ alt_currency = "NGN" }) {
  const container = useRef();
  const [showHam, setShowHam] = useState(false);

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
    script.type = "text/javascript";
    script.async = true;
    script.innerHTML = `
        {
          "autosize": false,
          "width": "100%",
          "height": "500",
          "symbol": "FX_IDC:USD${
            alt_currency === "USD" ? "EUR" : alt_currency
          }",
          "timezone": "Africa/Lagos",
          "theme": "light",
          "style": "3",
          "locale": "en",
          "enable_publishing": false,
          "range": "1M",
          "allow_symbol_change": true,
          "calendar": false,
          "support_host": "https://www.tradingview.com"
        }`;
    container.current.appendChild(script);
  }, []);

  return (
    <div className="trend-container app-card3">
      <div className="head">
        <h3 className="text-purple">
          <i className="fa fa-globe"></i> Currency Trends
        </h3>
        <div className="trend-menu">
          <div onClick={() => setShowHam(!showHam)}>
            <i className="bx bx-dots-horizontal-rounded icon"></i>
          </div>
          {showHam && (
            <ul className="trend-menu-link">
              <li>
                <a href="#">Edit</a>
              </li>
              <li>
                <a href="#">Save</a>
              </li>
              <li>
                <a href="#">See More</a>
              </li>
            </ul>
          )}
        </div>
      </div>
      <div
        className="tradingview-widget-container"
        ref={container}
        style={{ height: "400px", width: "100%" }}
      >
        <div
          className="tradingview-widget-container__widget"
          style={{ height: "calc(100% - 32px)", width: "100%" }}
        ></div>
        <div className="tradingview-widget-copyright">
          <a
            href="https://www.tradingview.com/"
            rel="noopener nofollow"
            target="_blank"
          >
            <span className="blue-text">Track all markets on TradingView</span>
          </a>
        </div>
      </div>
    </div>
  );
}

export default memo(CurrencyTrends);
