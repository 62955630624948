import React from "react";
import { cardImages } from "../../New/helpers";
import "../../../styles/card.scss";
import "./index.scss";
import card from "../../../Details/Card";

type CardStatus = "completed" | "active" | "processing" | "error";

interface Props {
  cardRequest: {
    id: string;
    user_id: string;
    name_on_card: string;
    status: CardStatus;
    card_type: string;
  };
}

const CardRequest: React.FC<Props> = ({ cardRequest }) => {
  return (
    <div className="dbt-ctn">
      <div className="overlay">
        <p className="card-number">XXXX XXXX XXXX XXXX</p>
        <p className="expires-in">{cardRequest.status}</p>
        <p className="card-holder-name">{cardRequest.name_on_card}</p>
      </div>
      <img src={cardImages[cardRequest.card_type]} alt="" />
      <div className="card-actions">
        <div className="card-activation w-100">
          <a
            className="btn btn-purple mb-3 w-100"
            href={`/cards/requests/${cardRequest.id}`}
          >
            View Card Request Details <i className="fa fa-arrow-right-long"></i>
          </a>
        </div>
      </div>
    </div>
  );
};

export default CardRequest;
