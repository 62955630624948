export const formattedApiErrors = errors => {
  const apiErrors = {};

  const hearAboutUsError = errors["hear_about_us"];
  const businessNameError = errors["business_name"];
  const accessToApiError = errors["access_to_api"];
  const integrationDescriptionError = errors["integration_description"];
  const averageTransactionAmountError = errors["average_transaction_amount"];
  const expectedTransactionError = errors["expected_transactional_volume"];

  apiErrors.hearAboutUsError = hearAboutUsError;
  apiErrors.businessNameError = businessNameError;
  apiErrors.accessToApiError = accessToApiError;
  apiErrors.averageTransactionAmountError = averageTransactionAmountError;
  apiErrors.expectedTransactionError = expectedTransactionError;
  apiErrors.integrationDescriptionError = integrationDescriptionError;

  return apiErrors;
};
