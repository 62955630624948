export const maskCardNumber = (cardNumber) => {
  const lastFourDigits = cardNumber.slice(-4);

  const maskedSection = cardNumber.slice(0, -4).replace(/\d/g, "X");
  const maskedGroups = maskedSection.match(/.{1,4}/g).join("-");

  return maskedGroups + "-" + lastFourDigits;
};

export const formatCardNumber = (cardNumber) => {
  return cardNumber.match(/.{1,4}/g).join("-");
};
