import React, { useState } from "react";
import {
  Formik,
  Form,
  Field,
  FormikHelpers,
  FieldArray,
  ErrorMessage
} from "formik";
import { required } from "./validations";
import { postRequest, putRequest } from "../../utils/httpRequest";
import { formErrors } from "../../utils/formErrors";
import validURL from "./validations"

const PayButtonForm = ({ store_id, integration, showSuccessModal }) => {
  const [creating, setCreating] = useState(false);
  const [errors, setErrors] = useState({});

  const formattedErrors = errors => {
    const { validationErrors } = this.state;
    const descriptionError = errors["description"];
    const payButtonAmountError = errors["pay_button_amount"];
    const payButtonCurrencyError = errors["pay_button_currency"];
    const payButtonNoteError = errors["pay_button_note"];
    const payButtonHeadingError = errors["pay_button_heading"];
    const payButtonCtaError = errors["pay_button_cta"];
    const payButtonSizeError = errors["pay_button_size"];
    const payButtonThemeError = errors["pay_button_theme"];

    validationErrors.descriptionError = descriptionError;
    validationErrors.payButtonAmountError = payButtonAmountError;
    validationErrors.payButtonPayButtonCurrencyError = payButtonPayButtonCurrencyError;
    validationErrors.payButtonNoteError = payButtonNoteError;
    validationErrors.payButtonHeadingError = payButtonHeadingsError;
    validationErrors.payButtonCtaError = payButtonCtaError;
    validationErrors.payButtonSizeError = payButtonSizeError;
    validationErrors.payButtonThemeError = payButtonThemeError;


    return validationErrors;
  };

  const validateUrl = (values, props) => {
    const errors = {};
  
    let pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  
    if (!values.returnUrl) {
      errors.returnUrl = 'Required';
    } else if ( !!pattern.test(values.returnUrl)) {
      errors.email = 'Invalid URL';
    }  
    return errors;
  };


  const submit = values => {
    console.log(integration);
    const crsfToken = $('meta[name="csrf-token"]').attr("content");

    const data = {
      store_integration: values
    };
    setCreating(true);

    if (integration) {
      putRequest(`/stores/${store_id}/integrations/${integration.id}`, data, {
        "X-CSRF-Token": crsfToken
      })
        .then(response => {
          showSuccessModal();

        })
        .catch(error => {
          const errors = formErrors(error.data.errors);

          setErrors(error);
          setCreating(false);
        });
    } else {
      postRequest(`/stores/${store_id}/integrations`, data, {
        "X-CSRF-Token": crsfToken
      })
        .then(response => {
          window.location = `/stores/${store_id}/integrations`;
        })
        .catch(error => {
          const errors = formErrors(error.data.errors);

          setErrors(error);
          setCreating(false);
        });
    }
  };

  return (
    <Formik
      initialValues={{
        description: integration?.description || 'Pay Button',
        pay_button_amount: integration?.pay_button_amount || 0.00,
        pay_button_currency: integration?.pay_button_currency || "USD",
        pay_button_note: integration?.pay_button_note || "",
        pay_button_cta: integration?.pay_button_cta || "Pay",
        pay_button_size: integration?.pay_button_size || "medium",
        pay_button_theme: integration?.pay_button_theme || "primary",
        integration_type: "PayButton"
      }}
      onSubmit={values => submit(values)}
    >
      {({ values, touched, handleChange }) => (
        <Form className="form">
          <div className="text-center pt-2 pb-2">

          <div className="form-group row required">
            <label htmlFor="description" className="form-label store-new-formlabel">
              PayButton Description
            </label>
            <Field
             as="textarea"
              id="description"
              name="description"
              className="form-control"
              validate={required}
              onChange={handleChange}/>
            <ErrorMessage name="description">
              {msg => <div className="error">{msg}</div>}
            </ErrorMessage>
            {errors?.descriptionError && (
              <div className="error text-danger">
                {validationErrors.descriptionError}
              </div>
            )}
          </div>

            <br/>
            <hr/>
            <h4 className="pt-2 pb-2 store-default-title">Settings</h4>
            <hr/>
          </div>
          <div className="form-group row required">
            <label htmlFor="pay_button_amount" className="form-label store-new-formlabel">
              Amount
            </label>
            <Field
              type="number"
              min="0.01"
              step="0.01" 
              id="pay_button_amount"
              name="pay_button_amount"
              className="form-control"
              validate={(value) => {
                if (!value || value <= 0) {
                  return "Amount must be greater than 0";
                }
                return undefined;
              }}
              onChange={(e) => {
                if (e.target.value < 0) {
                  e.target.value = 0;
                }
                handleChange(e);
              }}
            />

            <ErrorMessage name="pay_button_amount">
              {msg => <div className="error">{msg}</div>}
            </ErrorMessage>
            {errors?.payButtonAmountError && (
              <div className="error text-danger">
                {validationErrors.payButtonAmountError}
              </div>
            )}
          </div>

          <div className="form-group row required">
            <label htmlFor="pay_button_currency" className="form-label store-new-formlabel">
              Currency
            </label>
            <Field
              as="select"
              id="pay_button_currency"
              name="pay_button_currency"
              className="form-control"
              validate={required}
              onChange={handleChange}>
                <option value="usd">USD</option>
              </Field>
            <ErrorMessage name="pay_button_currency">
              {msg => <div className="error">{msg}</div>}
            </ErrorMessage>
            {errors?.payButtonCurrencyError && (
              <div className="error text-danger">
                {validationErrors.payButtonCurrencyError}
              </div>
            )}
          </div>

          <div className="form-group row">
            <label htmlFor="pay_button_note" className="form-label store-new-formlabel">
              Pay Button Note
            </label>
            <Field
              id="pay_button_note"
              name="pay_button_note"
              className="form-control"
              onChange={handleChange}
              placeholder="Reference, ID or Comment"
            />
            <ErrorMessage name="pay_button_note">
              {msg => <div className="error">{msg}</div>}
            </ErrorMessage>
            <div style={{display: "block", margin: "0"}}>
            <small style={{display: "block"}}>
            Displays as a reference in the customers transaction activity.
            </small>
          </div>
          </div>


          {errors?.payButtonNoteError && (
            <div className="error text-danger">
              {validationErrors.payButtonNoteError}
            </div>
          )}
          {/* <div className="form-group row">
            <label htmlFor="return_url" className="form-label">
              Return URL
            </label>
            <Field
              id="return_url"
              name="return_url"
              className="form-control"
              // required={true}
              validate={required}
              onChange={handleChange}
            />
            <ErrorMessage name="return_url">
              {msg => <div className="error">{msg}</div>}
            </ErrorMessage>
            {errors?.returnUrlError && (
              <div className="error text-danger">
                {validationErrors.returnUrlError}
              </div>
            )}
            <small>After your customer pays, they will be redirected to this url</small>
          </div> */}

          <div className="text-center pt-2 pb-2">
            <hr/>
            <h4 className="pt-2 pb-2 store-default-title">Appearance</h4>
            <hr/>
          </div>
          <div className="form-group row">
            <label htmlFor="pay_button_page_heading" className="form-label store-new-formlabel">
              Heading Text
            </label>
            <Field
              as="select"
              id="pay_button_page_heading"
              name="pay_button_page_heading"
              className="form-control"
              onChange={handleChange}>
                <option value="pay">PAY</option>
                <option value="tip">TIP</option>
                <option value="donate">DONATE</option>
                <option value="contribute">CONTRIBUTE</option>
                <option value="gift">GIFT</option>
              </Field>
            <ErrorMessage name="pay_button_page_heading">
              {msg => <div className="error">{msg}</div>}
            </ErrorMessage>
            {errors?.payButtonHeadingError && (
              <div className="error text-danger">
                {validationErrors.payButtonHeadingError}
              </div>
            )}
            <small>The heading that will appear at the top of the page, defaults to "PAY".</small>
          </div>
          <div className="form-group row">
            <label htmlFor="pay_button_cta" className="form-label store-new-formlabel">
              Call To Action Button Text
            </label>
            <Field
              id="pay_button_cta"
              name="pay_button_cta"
              className="form-control"
              required={true}
              validate={required}
              onChange={handleChange}
            />
            <ErrorMessage name="pay_button_cta">
              {msg => <div className="error">{msg}</div>}
            </ErrorMessage>
            {errors?.payButtonCtaError && (
              <div className="error text-danger">
                {validationErrors.payButtonCtaError}
              </div>
            )}
          </div>
          <small class="row" style={{display: "block"}}>The call to action text that will be used for the button, defaults to "Pay".</small>

          {/* <div className="form-group row">
            <label htmlFor="pay_button_size" className="form-label">
              Size
            </label>
            <Field
              as="select"
              id="pay_button_size"
              name="pay_button_size"
              className="form-control"
              required={true}
              validate={required}
              onChange={handleChange}>
                <option value="small">Small</option>
                <option value="med">Medium</option>
                <option value="large">Large</option>
            </Field>
            <ErrorMessage name="pay_button_size">
              {msg => <div className="error">{msg}</div>}
            </ErrorMessage>
            {errors?.payButtonSizeError && (
              <div className="error text-danger">
                {validationErrors.payButtonSizeError}
              </div>
            )}
            <small>Small, Medium or Large</small>
          </div> */}
          {/* <div className="form-group row required">
            <label htmlFor="pay_button_theme" className="form-label">
              Size
            </label>
            <Field
              as="select"
              id="pay_button_theme"
              name="pay_button_theme"
              className="form-control"
              required={true}
              validate={required}
              onChange={handleChange}>
                <option value="primary">Primary</option>
            </Field>
            <ErrorMessage name="pay_button_theme">
              {msg => <div className="error">{msg}</div>}
            </ErrorMessage>
            {errors?.payButtonThemeError && (
              <div className="error text-danger">
                {validationErrors.payButtonThemeError}
              </div>
            )}
            <small></small>
          </div> */}
          <br/>
          <hr/>
          <br/> 
          <div className="form actions">
            <div className="row mx-auto">
              <div className="col-md-12 text-right pr-0 pt-4">
                <button
                  type="submit"
                  className="btn btn-primary store-paidbtn"
                  disabled={creating}
                >
                  {creating && (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  Submit
                </button>
              </div>
            </div>
          </div>

        </Form>
      )}
    </Formik>
  );
};

export default PayButtonForm;
