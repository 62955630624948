import React, { useEffect, useState } from "react";
import {
  ArrowTopRight,
  PlusIcon,
  TradeIcon,
  IconRecycle,
  IconRefund2Fill,
  IconBxTransferAlt,
  IconArrowDownLeftSquare,
} from "./dashboardIcons";
import DebitCard from "../NewReactComponents/MainWalletCard/DebitCard";
import SendPaymentCard from "../NewReactComponents/AppTaskComponent/SendPaymentCard";
import { getRequest } from "../../utils/httpRequest";
import P2pMini from "../NewReactComponents/AppTaskComponent/P2pMini";
import TradeHistory from "./TradeHistory";
import FeaturedTraders from "./FeaturedTraders";
import CurrencyTrends from "../NewReactComponents/AppTaskComponent/CurrencyTrends";
import { greetUser } from "./helpers";
import BalanceCard from "./BalanceCard";

const Dashboard = (props) => {
  const [appData, setAppData] = useState(null);
  const [activeTab, setActiveTab] = useState("offers");
  const [walletData, setWalletData] = useState(null);
  const [showBalance, setShowBalance] = useState({
    account_balance: true,
    p2p_balance: true,
    crypto_balance: true,
  });

  const fetchAppData = async () => {
    try {
      const data = await getRequest("/api/v1/dashboard/summary");
      console.log("data: ", data);

      setAppData(data);
    } catch (error) {
      console.error("Error fetching wallet data:", error);
    }
  };

  const fetchWalletData = async () => {
    try {
      const data = await getRequest("/api/v1/dashboard/accounts");
      setWalletData(data);
    } catch (error) {
      console.error("Error fetching wallet data:", error);
    }
  };

  useEffect(() => {
    fetchWalletData();
    fetchAppData();
  }, []);

  const renderActiveTabContent = (activeTab) => {
    switch (activeTab) {
      case "offers":
        return (
          <P2pMini
            p2pData={appData?.offers}
            alt_currency={
              walletData?.wallet_balances?.account_balance?.alt_currency
            }
          />
        );
      case "trades":
        return (
          <TradeHistory userTradeHistory={appData?.user_p2p_trade_history} />
        );
      case "featured":
        return (
          <FeaturedTraders featuredTradersData={appData?.featured_traders} />
        );
      default:
        break;
    }
  };

  return (
    <div className="row">
      <div className="col-12 py-3">
        <h4>
          {greetUser()}
          {props.current_user.split(" ")[0]}{" "}
          <span className="waving-hand">👋</span>
        </h4>
      </div>
      <div className="col-12 col-sm-7 col-md-8 mb-3">
        <div className="grid-container">
          <div className="grid-item">
            <BalanceCard
              title="ACCOUNT BALANCE"
              id="dashboard_card_account"
              alt_currency={
                walletData?.wallet_balances?.account_balance?.alt_currency
              }
              showBalance={showBalance.account_balance}
              setShowBalance={() =>
                setShowBalance((prev) => ({
                  account_balance: !prev.account_balance,
                  p2p_balance: !prev.account_balance,
                  crypto_balance: !prev.account_balance,
                }))
              }
              balance={walletData?.wallet_balances?.account_balance?.balance}
              alt_balance={
                walletData?.wallet_balances?.account_balance?.alt_balance
              }
              links={[
                {
                  title: "Add Funds",
                  icon: <PlusIcon />,
                  link: "/add_funds",
                },
                {
                  title: "Send Funds",
                  icon: <TradeIcon />,
                  link: "/send_usd#SendPaymentsModal",
                },
                {
                  title: "View Assets",
                  icon: <ArrowTopRight />,
                  link: props.walletId,
                },
              ]}
            />
          </div>
          <div className="grid-item">
            <BalanceCard
              title="P2P BALANCE"
              id="dashboard_card_p2p"
              alt_currency={
                walletData?.wallet_balances?.account_balance?.alt_currency
              }
              showBalance={showBalance.p2p_balance}
              setShowBalance={() =>
                setShowBalance((prev) => ({
                  ...prev,
                  p2p_balance: !prev.p2p_balance,
                }))
              }
              balance={walletData?.wallet_balances?.p2p_escrow_balance?.balance}
              alt_balance={
                walletData?.wallet_balances?.p2p_escrow_balance?.alt_balance
              }
              links={[
                {
                  title: "Create Offer",
                  icon: <PlusIcon />,
                  link: "/p2p_trades/offers/new?offer_type=buy",
                },
                {
                  title: "Trade USD",
                  icon: <TradeIcon />,
                  link: "/p2p_trades/offers/search?offer_type=buy",
                },
                {
                  title: "My Trades",
                  icon: <ArrowTopRight />,
                  link: "/p2p_trades/trades",
                },
              ]}
            />
          </div>
          <div className="grid-item">
            <BalanceCard
              title="CRYPTO BALANCE"
              id="dashboard_card_crypto"
              alt_currency={
                walletData?.wallet_balances?.account_balance?.alt_currency
              }
              showBalance={showBalance.crypto_balance}
              setShowBalance={() =>
                setShowBalance((prev) => ({
                  ...prev,
                  crypto_balance: !prev.crypto_balance,
                }))
              }
              balance={
                walletData?.wallet_balances?.crypto_asset_balance?.balance
              }
              balances={
                walletData?.wallet_balances?.crypto_asset_balance?.balances
              }
              alt_balance={
                walletData?.wallet_balances?.crypto_asset_balance?.alt_balance
              }
              links={[
                {
                  title: "Send Token",
                  icon: <IconBxTransferAlt />,
                  link: "/crypto_transfers/send_crypto/new",
                },
                {
                  title: "Receive Token",
                  icon: <IconArrowDownLeftSquare />,
                  link: `${props.walletId}/wallet_addresses`,
                },
                {
                  title: "See More",
                  icon: <ArrowTopRight />,
                  link: props.walletId,
                },
              ]}
            />
          </div>
          <div className="w-100 mt-3">
            <div className="dashboard-tables bg-white">
              <div className="container-fluid px-0">
                <ul
                  className="nav p2p-history-tab rounded-lg"
                  id="tradeTabs"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      className="btn nav-link active"
                      id="offers-tab"
                      data-toggle="tab"
                      href="#offers"
                      role="tab"
                      aria-controls="offers"
                      aria-selected="true"
                      onClick={() => setActiveTab("offers")}
                    >
                      {" "}
                      Latest Offers
                    </a>
                  </li>

                  <li className="nav-item">
                    <a
                      className="btn nav-link"
                      id="trades-tab"
                      data-toggle="tab"
                      href="#trades"
                      role="tab"
                      aria-controls="trades"
                      aria-selected="false"
                      onClick={() => setActiveTab("trades")}
                    >
                      My Trade History
                    </a>
                  </li>

                  <li className="nav-item">
                    <a
                      className="btn  nav-link"
                      id="featured-tab"
                      data-toggle="tab"
                      href="#featured"
                      role="tab"
                      aria-controls="featured"
                      aria-selected="false"
                      onClick={() => setActiveTab("featured")}
                    >
                      Featured Traders
                    </a>
                  </li>
                </ul>
                <div
                  className="px-3 featured"
                  style={{ overflowX: "hidden", minHeight: "530px" }}
                >
                  {renderActiveTabContent(activeTab)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-sm-5 col-md-4 pl-sm-1">
        <div className="dashboard-atm-section">
          <p className="text-white ml-1 mb-1 mt-0">DEBIT CARD</p>
          <DebitCard
            currentUser={props.current_user}
            cardId={props.debitCardId}
            canActivateCard={props.canActivateCard}
          />

          <div className="dashboard-card-bottom mt-3 dashboard-card-buttons">
            <a className="dashboard-badge" href="/cards/requests/new">
              <PlusIcon />
              <span className="text-center">Apply</span>
            </a>
            <a
              className="dashboard-badge"
              href={
                props.canActivateCard || props.cardId
                  ? "/cards/requests"
                  : "/cards/requests/activate_card"
              }
            >
              <IconRecycle />
              <span className="text-center">Activate</span>
            </a>
            <a
              className="dashboard-badge"
              href={
                props.cardId
                  ? `/cards/${props.cardId}/card_loads/new`
                  : "/cards/requests/fund_card"
              }
            >
              <IconRefund2Fill />
              <span className="text-center">Fund</span>
            </a>
          </div>
        </div>
        <div className="dashboard-payment-section mt-3">
          <SendPaymentCard />
        </div>
      </div>
      <div className="col-12 mt-2">
        {walletData?.wallet_balances?.account_balance?.alt_currency && (
          <CurrencyTrends
            alt_currency={
              walletData?.wallet_balances?.account_balance?.alt_currency
            }
          />
        )}
      </div>
    </div>
  );
};

export default Dashboard;
