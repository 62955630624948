import { Controller } from "stimulus";
import Rails from "@rails/ujs";
import MetaMaskOnboarding from "@metamask/onboarding";

export default class extends Controller {
  static targets = ["connectButton"];

  connect() {
    this.metaMaskClientCheck();
  }

  getAccounts = async () => {
    const accounts = await ethereum.request({ method: "eth_accounts" });

    if (accounts[0]) {
      window.location = `/trade/new?metamask_account=${accounts[0]}`;
    }
  };

  metaMaskClientCheck() {
    //Now we check to see if Metmask is installed

    if (!this.isMetaMaskInstalled()) {
      //If it isn't installed we ask the user to click to install it
      this.connectButtonTarget.innerText = "Click here to install MetaMask!";
      //When the button is clicked we call th is function
      this.connectButtonTarget.onclick = this.onClickInstall;
      //The button is now disabled
      this.connectButtonTarget.disabled = false;
    } else {
      //If MetaMask is installed we ask the user to connect to their wallet
      this.connectButtonTarget.innerText = "Connect to Metamask";
      //When the button is clicked we call this function to connect the users MetaMask Wallet
      this.connectButtonTarget.onclick = this.onClickConnect;
      //The button is now disabled
      this.connectButtonTarget.disabled = false;
    }
  }

  isMetaMaskInstalled() {
    //Have to check the ethereum binding on the window object to see if it's installed
    const { ethereum } = window;
    return Boolean(ethereum && ethereum.isMetaMask);
  }

  //This will start the onboarding proccess

  onClickInstall() {
    // const that = this;
    // console.log(that);
    const onboarding = new MetaMaskOnboarding({});
    //
    // that.connectButtonTarget.innerText = "Onboarding in progress";
    // that.connectButtonTarget.disabled = true;
    //On this object we have startOnboarding which will start the onboarding process for our end user
    onboarding.startOnboarding();
  }

  onClickConnect = async () => {
    this.getAccounts();
    try {
      // Will open the MetaMask UI
      // You should disable this button while the request is pending!
      const { ethereum } = window;
      await ethereum.request({ method: "eth_requestAccounts" });
    } catch (error) {
      console.error(error);
    }
  };
}
