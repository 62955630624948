import React from "react";

const Preview = ({
  amount,
  sourceCurrency,
  fees,
  destinationCurrency,
  destinationAmount
}) => (
  <div className="preview-wrapper">
    <h4>Review Your Transfer Details</h4>
    <hr />
    <div className="px-md-5 mx-2">
      <div className="row subtotal">
        <div className="col-md-5">
          <div className="form-group row d-block ">
            <label className="form-label">
              Amount
              <a
                data-toggle="tooltip"
                className="btn btn-link"
                data-original-title="Subtotal amount (Crypto)."
              >
                <i className="fa fa-info-circle"></i>
              </a>
            </label>
            <small
              id="netAmountHelpBlock"
              className="form-text text-muted"
            ></small>
          </div>{" "}
        </div>
        <div className="col-md-7">
          <div className="form-group row ">
            <div className="input-group mb-2 mr-sm-2">
              <div className="input-group-prepend">
                <div className="input-group-text selected-currency border-0">
                  {sourceCurrency == "USDT" ? "$" : sourceCurrency}
                </div>
              </div>
              <input
                className="form-control border-0"
                placeholder="0.00"
                data-target="cryptoTransfer.outputNetAmount"
                required="required"
                readOnly="readonly"
                type="number"
                value={amount}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row fees">
        <div className="col-md-5">
          <div className="form-group row d-block ">
            <label className="form-label">
              Fee
              <a
                data-toggle="tooltip"
                className="btn btn-link"
                data-original-title="Transaction and processing fees (Crypto)"
              >
                <i className="fa fa-info-circle"></i>
              </a>
            </label>
            <small id="feesHelpBlock" className="form-text text-muted"></small>
          </div>{" "}
        </div>
        <div className="col-md-7">
          <div className="form-group row ">
            <div className="input-group mb-2 mr-sm-2">
              <div className="input-group-prepend">
                <div className="input-group-text selected-currency border-0">
                  {sourceCurrency == "USDT" ? "$" : sourceCurrency}
                </div>
              </div>
              <input
                className="form-control border-0"
                placeholder="TBD"
                data-target="cryptoTransfer.outputFeesAmount"
                required="required"
                readOnly="readonly"
                type="number"
                value={parseFloat(fees).toFixed(7)}
              />
            </div>
          </div>{" "}
        </div>
      </div>
      <div className="row total">
        <div className="col-5">
          <div className="form-group row d-block ">
            <label className="form-label">
              Total
              <a
                data-toggle="tooltip"
                className="btn btn-link"
                data-original-title="Total"
              >
                <i className="fa fa-info-circle"></i>
              </a>
            </label>
            <small
              id="netAmountHelpBlock"
              className="form-text text-muted"
            ></small>
          </div>{" "}
        </div>
        <div className="col-7">
          <div className="form-group row ">
            <div className="input-group mb-2 mr-sm-2">
              <div className="input-group-prepend">
                <div className="input-group-text selected-currency border-0 pr-0">
                  {destinationCurrency}
                </div>
              </div>
              <input
                className="form-control border-0"
                placeholder="TBD"
                data-target="cryptoTransfer.outputNetAmount"
                required="required"
                readOnly="readonly"
                type="number"
                value={parseFloat(destinationAmount).toFixed(7)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Preview;
