import React, { useState } from "react";

const TradeId = ({ tradeData }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = (text) => {
      navigator.clipboard.writeText(text);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
  };

  return (
    <div>
      <p className="tradeDetails-tradeId">
        <span>Trade ID: </span>
        <span className="tradeDetails-tradeId-number">{tradeData?.id}</span>
        <button onClick={() => handleCopy(tradeData.id)} className="tradedetails-copybtn">
          <i className={`fas fa-${copied ? "check" : "copy"}`} title={`${copied ? "Copied" : "Copy"}`}></i>
        </button>
      </p>
    </div>
  );
};

export default TradeId;
