import React from "react";
import "../index.scss"

const Head = () => (
  <thead className="bankaccountspage-tablehead">
    <tr className="bankaccountspage-tablehead-row">
      <th>Name</th>
      <th>Bank Name</th>
      <th>Account Number</th>
      <th className="bankaccountspage-table-actions">Actions</th>
    </tr>
  </thead>
);

export default Head;
