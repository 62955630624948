import axios from "axios";

export const getRequest = (url, header = {}) => {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          ...header,
          "Content-Type": "application/json",
        },
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

export const postRequest = (
  url,
  data,
  header = { "Content-Type": "application/json" },
) => {
  return new Promise((resolve, reject) => {
    const token = $('meta[name="csrf-token"]').attr("content");
    axios
      .post(url, data, {
        headers: {
          ...header,
          "X-CSRF-Token": token,
        },
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response));
  });
};

export const putRequest = (url, data, header = {}) => {
  return new Promise((resolve, reject) => {
    const token = $('meta[name="csrf-token"]').attr("content");
    axios
      .put(url, data, {
        headers: {
          ...header,
          "Content-Type": "application/json",
          "X-CSRF-Token": token,
        },
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response));
  });
};

export const patchRequest = (url, data, header = {}) => {
  return new Promise((resolve, reject) => {
    const token = $('meta[name="csrf-token"]').attr("content");
    axios
      .patch(url, data, {
        headers: {
          ...header,
          "Content-Type": "application/json",
          "X-CSRF-Token": token,
        },
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response));
  });
};

export const deleteRequest = (url, data, header = {}) => {
  return new Promise((resolve, reject) => {
    const token = $('meta[name="csrf-token"]').attr("content");
    axios
      .delete(url, data, {
        headers: {
          ...header,
          "Content-Type": "application/json",
          "X-CSRF-Token": token,
        },
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response));
  });
};
