export const calculateTradeFee = (amount: string, fee) => {
  if (!amount || !fee) return 0;

  return (fee / 100) * parseFloat(amount);
};

export const calculateFiatTradeFee = (amount: number, fee, sellerRate) => {
  let feeAmount = calculateTradeFee(amount.toString(), fee);
  return feeAmount * sellerRate;
};

export const calculateTotalAmount = (amount, tradeRate) => {
  return parseFloat(amount || "0") * parseFloat(tradeRate || "0");
};

// Utility function to format the number with "K" for thousands and "M" for millions
export const abbreviateNumber = (number: number): string => {
  if (number >= 1_000_000) {
    return (number / 1_000_000).toFixed(1) + "M";
  } else if (number >= 1_000) {
    return (number / 1_000).toFixed(1) + "K";
  } else {
    return number.toString();
  }
};
