import React, { useState } from "react";
import "../../../styles/trademodals.scss";

const DisputeConfirmationModal = ({ onConfirm, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();
    setIsLoading(true);
    onConfirm();
  };
  return (
    <div
      className="modal-status-cont"
      tabIndex={-1}
      aria-hidden="true"
      id="disputeConfirmModal"
      aria-labelledby="disputeConfirmModalLabel"
    >
      <div className="modal-status-alert-cont">
        <div>
          <button
            type="button"
            className="close"
            onClick={() => {onClose('disputeConfirmModal')}}
            aria-label="Close"
            disabled={isLoading}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <br/>
        </div>
        <div>
          <p className="modal-title" id="disputeConfirmModalLabel">
            Are you sure you want to create a dispute on this trade? 
          </p>
          <div className="trademodal-notice">
            <span>
              <b>This change cannot be undone.</b>{" "}
              Have you discussed with other party and tried to solve the issue
              first?
            </span>
          </div>
          <br/>
          {/* buttons */}
          <div className="modal-buttons-section">
            <div>
              <button
                type="button"
                className="modal-tradeaction-canceltradebtn"
                disabled={isLoading}
                onClick={() => {onClose('disputeConfirmModal')}}
              >
                Cancel
              </button>
            </div>
            <div>
              <button
                type="button"
                className="modal-tradeaction-paidbtn"
                data-dismiss="modal"
                onClick={(e) => handleClick(e)}
              >
                {isLoading ? "Please wait..." : "Confirm"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DisputeConfirmationModal;
