import React, { Component, Fragment } from "react";
import _ from "underscore";
import "./index.scss";
import {
  IconCheck,
  IconSearchPurple,
  IconUserPurple,
} from "../Dashboard/dashboardIcons";

class Autocomplete extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // The active selection's index
      activeSuggestion: 0,
      // The suggestions that match the user's input
      filteredSuggestions: [],
      // Whether or not the suggestion list is shown
      showSuggestions: false,
      // What the user has entered
      userInput: props.userInput || "",
      userInputValue: "",
      userSearching: true,
    };
  }

  componentDidUpdate(prevProps) {
    const { userInput } = this.state;
    const { suggestions, lookedUpRecipient } = this.props;

    if (
      prevProps.suggestions !== suggestions ||
      prevProps.lookedUpRecipient !== lookedUpRecipient
    ) {
      const filteredSuggestions = [lookedUpRecipient]
        .concat(suggestions)
        .filter(
          (suggestion) =>
            suggestion?.keyword
              ?.toLowerCase()
              .indexOf(userInput.toLowerCase()) > -1
        );

      this.setState({
        filteredSuggestions,
      });
    }
  }

  onChange = (e) => {
    const { suggestions } = this.props;

    const userInput = e.currentTarget.value;
    this.setState({ userInputValue: "" });

    // Filter our suggestions that don't contain the user's input
    let filteredSuggestions;
    if (userInput.length > 2) {
      filteredSuggestions = suggestions.filter(
        (suggestion) =>
          suggestion.keyword.toLowerCase().indexOf(userInput.toLowerCase()) > -1
      );
    } else {
      filteredSuggestions = [];
    }

    this.setState({
      activeSuggestion: 0,
      filteredSuggestions,
      showSuggestions: true,
      userInput,
    });

    if (filteredSuggestions.length === 0 && userInput.length > 2) {
      this.props.updateRecipient(userInput);
    } else {
      this.props.updateRecipient(userInput, true);
    }
  };

  onBlur = () => {
    const { filteredSuggestions } = this.state;

    if (filteredSuggestions.length > 0) {
      this.props.updateRecipient(this.state.userInputValue, true);
    } else {
      this.props.updateRecipient(this.state.userInput);
    }
  };

  onClick = (e) => {
    this.setState({
      activeSuggestion: 0,
      showSuggestions: false,
      userInput: e.currentTarget.innerText,
      userInputValue: e.currentTarget.dataset.value,
      userSearching: false,
    });

    this.props.updateRecipient(e.currentTarget.dataset.value, true);
  };

  render() {
    const {
      onChange,
      onClick,
      onBlur,
      state: {
        activeSuggestion,
        filteredSuggestions,
        showSuggestions,
        userInput,
        userSearching,
      },
    } = this;

    let suggestionsListComponent;

    if (showSuggestions && userInput) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <ul className="suggestions">
            {filteredSuggestions.map((suggestion, index) => {
              let className;

              // Flag the active suggestion with a class
              if (index === activeSuggestion) {
                className = "suggestion-active";
              }

              return (
                <li
                  className={className}
                  data-value={suggestion.value}
                  key={suggestion.keyword}
                  onClick={onClick}
                >
                  <div className="font-0_95 font-weight-normal">
                    {" "}
                    <IconUserPurple /> {suggestion.name}
                  </div>
                  <div className="text-muted font-0_8 pl-2">
                    {suggestion.username}
                  </div>
                </li>
              );
            })}
          </ul>
        );
      } else {
        if (userInput.length > 8) {
          suggestionsListComponent = (
            <ul className="suggestions mb-0">
              <a
                className="text-muted font-0_8"
                target="_blank"
                href="/invites/new"
              >
                <li>
                  Can't find your recipient?
                  <br />
                  Invite them to join
                </li>
              </a>
            </ul>
          );
        } else {
          suggestionsListComponent = <div />;
        }
      }
    }

    const { placeholder, className, disabled, validationError } = this.props;

    return (
      <Fragment>
        <div className={`input-group ${validationError ? "is-invalid" : ""}`}>
          <input
            type="text"
            placeholder={placeholder}
            className={className}
            onChange={_.debounce(onChange, 1000, {
              leading: true,
            })}
            disabled={disabled}
            value={userInput}
            onBlur={onBlur}
          />
          <div className="input-group-append">
            <span className="input-group-text">
              {userSearching || showSuggestions ? (
                <IconSearchPurple />
              ) : (
                <IconCheck />
              )}
            </span>
          </div>
        </div>
        {suggestionsListComponent}
      </Fragment>
    );
  }
}

export default Autocomplete;
