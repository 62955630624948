import React from "react";

export const feedback = (props) => {
  if (props.verification_accepted) {
    return null;
  } else if (props.verification_started) {
    return (
      <div className="alert alert-warning verification-alert mt-4 font-weight-bold">
        <i className="fa fa-info-circle"></i> Verification is incomplete, please
        complete your verification.
      </div>
    );
  } else if (props.verification_pending) {
    return (
      <div className="alert alert-warning verification-alert mt-4 font-weight-bold">
        <i className="fa fa-info-circle"></i> Verification is pending due to
        missing documents or it requires processing.
      </div>
    );
  } else if (props.verification_denied) {
    return (
      <div className="alert alert-warning verification-alert verification-alert-danger mt-4 font-weight-bold">
        Verification was not successful.
        <br />
        <span class="font-0_9 pb-4">
          If you believe this is an error, please contact us
        </span>
      </div>
    );
  }
};
