import React, { Component, useState } from "react";
import { getRequest, postRequest } from "../../utils/httpRequest";
import { formErrors } from "../../utils/formErrors";

class StorePayout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      grossAmount: "0.00",
      netAmount: "0.00",
      fees: "0.00",
      subTotal: "0.00",
      validationErrors: {},
      invalidTransfer: true
    };
  }

  validateAmount = () => {
    const { grossAmount, validationErrors } = this.state;

    if (Number(grossAmount) <= this.props.minimumAmount) {
      validationErrors.grossAmountError = `Amount to transfer cannot be less than ${this.props.minimumAmount}. Please enter a higher amount.`;

      this.setState({
        validationErrors,
        invalidTransfer: true
      });
    } else if (Number(grossAmount) > Number(this.props.balanceAmount)) {
      validationErrors.grossAmountError =
        "Amount to transfer is greater than your available balance, please enter a lower amount.";

      this.setState({
        validationErrors,
        invalidTransfer: true
      });
    } else {
      validationErrors.grossAmountError = "";
      this.setState({
        validationErrors,
        invalidTransfer: false
      });
      this.getFees();
    }
  };

  handleAmountChange = e => {
    this.setState({ grossAmount: e.target.value }, () => this.validateAmount());
  };

  getLimit = () => {
    const { validationErrors } = this.state;

    getRequest(
      `/limits?gross_amount=${this.state.grossAmount}&payment_method_type=${this.props.paymentMethodType}&transaction_type=payout`
    ).then(response => {
      if (response.data.message) {
        validationErrors.grossAmountError = message;

        this.setState({
          validationErrors,
          invalidTransfer: true
        });
      }
    });
  };

  getFees = () => {
    const { processorType, paymentMethodType } = this.props;

    getRequest(
      `/payouts/fees?gross_amount=${this.state.grossAmount}&payment_method_type=${paymentMethodType}&processor_type=${processorType}`
    ).then(response => {
      this.setState({
        subTotal: response.gross_amount,
        fees: response.fees,
        netAmount: response.net_amount
      });
    });
  };

  setApiErrors = errors => {
    const formattedErrors = formErrors(errors);

    const { validationErrors } = this.state;

    const grossAmountError = formattedErrors["gross_amount"];

    validationErrors.grossAmountError = grossAmountError;

    return validationErrors;
  };

  submit = e => {
    const { grossAmount, netAmount, fees } = this.state;
    const {
      walletId,
      paymentMethodId,
      processorType,
      paymentMethodType,
      storeId
    } = this.props;

    e.preventDefault();

    this.setState({ submitting: true, validationErrors: {} });

    const data = {
      payout: {
        gross_amount: grossAmount,
        net_amount: netAmount,
        fees,
        wallet_id: walletId,
        payment_method_id: paymentMethodId,
        processor_type: processorType,
        payment_method_type: paymentMethodType,
        store_id: storeId
      }
    };

    const crsfToken = $('meta[name="csrf-token"]').attr("content");

    postRequest("/payouts.json", data, {
      "X-CSRF-Token": crsfToken
    })
      .then(response => {
        window.location = `/payouts/${response.id}`;
      })
      .catch(error => {
        console.log(error);

        const errors = this.setApiErrors(error.data.errors);
        this.setState({ validationErrors: errors, submitting: false });
      });
  };

  render() {
    const {
      grossAmount,
      subTotal,
      netAmount,
      fees,
      validationErrors,
      invalidTransfer,
      submitting
    } = this.state;

    const {
      balance,
      alternateBalance,
      paymemtMethodName,
      minimumAmount
    } = this.props;

    return (
      <form className="form-horizontal" id="new_payout">
        <div className="row">
          <div className="col-md-12">
            <span className="m-0 counter text-default p-0 pr-3 font-weight-normal">
              Available Balance
            </span>
            <h3 className="m-0 counter text-default font-weight-normal">
              {balance}
            </h3>
            <h4
              className="m-0 counter font-weight-bold font-0_8"
              style={{ color: "#ca8040" }}
            >
              {alternateBalance}
            </h4>
          </div>
        </div>
        <div className="pt-4">
          <div className="row">
            <div className="col-md-12">
              <div className="form-group row required ">
                <label
                  className="form-label"
                  htmlFor="payout_Withdraw/Payout Amount"
                >
                  Withdraw/payout amount
                </label>
                <div className="input-group mb-2 mr-sm-2">
                  <div className="input-group-prepend">
                    <div
                      className="input-group-text selected-currency font-2_25"
                      style={{ color: "#4173a7" }}
                    >
                      USD
                    </div>
                  </div>
                  <input
                    step="0.01"
                    min={minimumAmount}
                    autoFocus="autofocus"
                    className="form-control font-2_25 is-invalid"
                    required="required"
                    prefix="USD"
                    style={{ maxWidth: "340px" }}
                    type="number"
                    id="payout_gross_amount"
                    onChange={this.handleAmountChange}
                  />
                  {validationErrors?.grossAmountError && (
                    <span className="error text-danger">
                      <br />
                      {validationErrors.grossAmountError}
                    </span>
                  )}
                </div>
                <div className="font-0_9 text-muted mt-1">
                  <span className="text-muted">
                    Min: ${minimumAmount}
                    <span></span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="row pt-2">
            <div className="col-md-12">
              <hr />
              <div className="form-group row ">
                <label
                  className="form-label"
                  htmlFor="payout_Recipient's Bank Account"
                >
                  Bank account
                </label>
                <div className="form-control form-control-lg">
                  {paymemtMethodName}
                </div>
                <br />
              </div>
              <div className="pt-2 pl-4 pb-3 font-0_9">
                <i className="fa fa-plus-circle "></i>
                <a href="/payment_methods/new?is_payout_method=true&amp;store=683621a7-97c3-43c0-b2e6-0107e9082024">
                  Add a new bank account
                </a>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <hr />
              <h4>Preview</h4>
              <hr />
              <div className="container">
                <div className="row gross_amount">
                  <div className="col-md-5">
                    <div className="form-group row d-block ">
                      <label className="form-label">
                        Subtotal
                        <a
                          data-toggle="tooltip"
                          className="btn btn-link"
                          data-original-title="Total amount of US Digital dollars that will be debited from your wallet"
                        >
                          <i className="fa fa-info-circle"></i>
                        </a>
                      </label>
                      <small
                        id="amountHelpBlock"
                        className="form-text text-muted"
                      ></small>
                    </div>{" "}
                  </div>
                  <div className="col-md-7">
                    <div className="form-group row ">
                      <div className="input-group mb-2 mr-sm-2">
                        <div className="input-group-prepend">
                          <div className="input-group-text border-0">$</div>
                        </div>
                        <input
                          className="form-control border-0 font-weight-bold"
                          placeholder="0.00"
                          readOnly="readonly"
                          type="number"
                          id="payout_gross_amount"
                          value={subTotal}
                        />
                      </div>
                    </div>{" "}
                  </div>
                </div>
                <div className="row fees">
                  <div className="col-md-5">
                    <div className="form-group row d-block ">
                      <label className="form-label">
                        Payout/Withdrawal Fees
                        <a
                          data-toggle="tooltip"
                          className="btn btn-link"
                          data-original-title="Transaction and processing fees (USD)"
                        >
                          <i className="fa fa-info-circle"></i>
                        </a>
                      </label>
                      <small
                        id="feesHelpBlock"
                        className="form-text text-muted"
                      ></small>
                    </div>{" "}
                  </div>
                  <div className="col-md-7">
                    <div className="form-group row ">
                      <div className="input-group mb-2 mr-sm-2">
                        <div className="input-group-prepend">
                          <div className="input-group-text border-0">$</div>
                        </div>
                        <input
                          className="form-control border-0"
                          placeholder="0.00"
                          required="required"
                          readOnly="readonly"
                          type="number"
                          id="payout_fees"
                          value={fees}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row net_amount">
                  <div className="col-md-5">
                    <div className="form-group row d-block ">
                      <label className="form-label">
                        Total Amount To send to Bank Account
                        <a
                          data-toggle="tooltip"
                          className="btn btn-link"
                          data-original-title="Total amount in US Dollars ($) that will be sent to the bank. For currency exchanges, the rate will be determined at the time the bank processes the payout."
                        >
                          <i className="fa fa-info-circle"></i>
                        </a>
                      </label>
                      <small
                        id="netAmountHelpBlock"
                        className="form-text text-muted"
                      ></small>
                    </div>{" "}
                  </div>
                  <div className="col-md-7">
                    <div className="form-group row ">
                      <div className="input-group mb-2 mr-sm-2">
                        <div className="input-group-prepend">
                          <div className="input-group-text border-0">$</div>
                        </div>
                        <input
                          className="form-control border-0 font-1_1 font-bold"
                          placeholder="0.00"
                          required="required"
                          readOnly="readonly"
                          type="number"
                          id="payout_net_amount"
                          value={netAmount}
                        />
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
              <hr />
            </div>
          </div>
          <div className="row form-actions">
            <button
              name="button"
              type="submit"
              className="btn btn-primary ml-auto"
              disabled={invalidTransfer}
              onClick={this.submit}
            >
              {submitting ? (
                <>
                  <i className="fa fa-circle-o-notch fa-spin"></i>
                  <span style={{ paddingLeft: "5px" }}>
                    Please Wait...Loading
                  </span>
                </>
              ) : (
                <span>Submit Payout</span>
              )}
            </button>
          </div>
        </div>
      </form>
    );
  }
}

export default StorePayout;
