import React from "react";
import ActivateCardModal from "./ActivateCardModal";
import { cardImages } from "../../New/helpers";
import "../../../styles/card.scss";
import "../../List/CardRequest/index.scss";

type CardStatus = "completed" | "active" | "processing" | "error";

interface Props {
  isCardActivated: boolean;
  cardRequest: {
    id: string;
    user_id: string;
    first_name: string;
    last_name: string;
    status: CardStatus;
    card_type: string;
  };
}

const ActivateCard: React.FC<Props> = ({ cardRequest, isCardActivated }) => {
  const [activateModalIsOpen, setActivateModalIsOpen] = React.useState(false);

  return (
    <div className="w-100">
      <div className="dbt-ctn">
        <div className="overlay">
          <p className="card-number">XXXX XXXX XXXX XXXX</p>
          <p className="expires-in">{cardRequest.status}</p>
          <p className="card-holder-name">{`${cardRequest.first_name} ${cardRequest.last_name}`}</p>
        </div>
        <img src={cardImages[cardRequest.card_type]} alt="" />
        <div className="card-actions">
          <div className="card-activation w-100">
            <button
              className="btn btn-purple mb-3 w-100"
              disabled={isCardActivated}
              onClick={(e) => {
                e.preventDefault();
                setActivateModalIsOpen(true);
              }}
            >
              {isCardActivated ? "Card Activated" : "Activate Card"}
            </button>
          </div>
        </div>
        <ActivateCardModal
          cardRequestId={cardRequest.id}
          userId={cardRequest.user_id}
          isModalOpen={activateModalIsOpen}
          onClose={setActivateModalIsOpen}
        />
      </div>
      {!isCardActivated && (
        <div className="alert alert-warning">
          <p>Congratulations on receiving your card. </p>
          <p>
            Your card will be activated within 24 hrs on a weekday and up to 72
            hrs upon receiving the correct and clear selfie.
          </p>
          <p>
            To activate a card, please upload a readable selfie of you holding
            your passport (bio-page) and card.
          </p>
        </div>
      )}
    </div>
  );
};

export default ActivateCard;
