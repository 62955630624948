import React, { useState } from "react";
import Modal from "react-modal";
import toastr from "toastr";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { postRequest } from "../../../../../utils/httpRequest";
import FileField from "../../New/common/FileField";
import { formattedErrors } from "./helpers";
import selfieExample from "../../../../../../assets/images/cards/card_selfie_example.jpg";

interface Props {
  cardRequestId: string;
  userId: string;
  isModalOpen: boolean;
  onClose: (value: boolean) => void;
}
const ActivateCardModal: React.FC<Props> = ({
  isModalOpen,
  onClose,
  userId,
  cardRequestId,
}) => {
  toastr.options.positionClass = "toast-top-center";
  toastr.options.showDuration = "10000";

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      width: "40%",
      transform: "translate(-50%, -50%)",
    },
  };

  const validationSchema = Yup.object().shape({
    card_number: Yup.string().required("Card number is required"),
    card_selfie: Yup.string().required("Card selfie is required"),
  });

  const [isActivatingCard, setIsActivatingCard] = useState(false);
  const [errors, setErrors] = useState(null);

  const submitCardActivation = (values) => {
    setIsActivatingCard(true);

    const formData = new FormData();
    formData.append("card_detail[card_request_id]", cardRequestId);
    formData.append("card_detail[card_number]", values.card_number);
    formData.append("card_detail[attachment_attributes][user_id]", userId);
    formData.append(
      "card_detail[attachment_attributes][file]",
      values.card_selfie
    );
    formData.append(
      "card_detail[attachment_attributes][attachment_type]",
      "card_selfie"
    );

    postRequest("/api/v1/cards/activations", formData, {
      "Content-Type": "multipart/form-data",
    })
      .then((response) => {
        window.location.href = "/cards/details";
      })
      .catch((error) => {
        if (error.data.errors) {
          setErrors(formattedErrors(error.data.errors));
        }

        toastr.error("Something went wrong");
        setIsActivatingCard(false);
      });
  };

  return (
    <Modal
      isOpen={isModalOpen}
      style={customStyles}
      contentLabel="card-status-modal"
      shouldCloseOnOverlayClick={false}
      size="max-w-lg"
    >
      <div className="activate-card-modal">
        <h4>Activate Card</h4>

        <div className="mb-20" style={{ marginBottom: "20px" }}>
          <div className="col-md-12">
            <p>See image for an example of how the selfie should look like.</p>
            <div
              className="text-center"
              style={{
                height: "50%",
                width: "40%",
                textAlign: "center",
                margin: "0 auto",
              }}
            >
              <img className="img-thumbnail" src={selfieExample} />
            </div>
          </div>
          <Formik
            initialValues={{
              card_number: "",
              card_selfie: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => submitCardActivation(values)}
          >
            {({}) => (
              <Form className="form">
                <div className="col-md-12">
                  <label htmlFor="card_number">Provide card number</label>
                  <Field
                    id="card_number"
                    name="card_number"
                    placeholder="XXXX XXXX XXXX XXXX"
                    className="form-control"
                  />
                  <ErrorMessage name="card_number">
                    {(msg) => <div className="error">{msg}</div>}
                  </ErrorMessage>
                  {errors?.cardNumberError && (
                    <div className="error text-danger">
                      {errors.cardNumberError}
                    </div>
                  )}
                </div>
                <div className="col-md-12">
                  <label htmlFor="card_selfie">
                    Provide a selfie image of you holding the card
                  </label>
                  <Field
                    id="card_selfie"
                    name="card_selfie"
                    component={FileField}
                    className="form-control"
                  />
                  <ErrorMessage name="card_selfie">
                    {(msg) => <div className="error">{msg}</div>}
                  </ErrorMessage>
                </div>
                <div className="col-md-12 buttons">
                  <button
                    type="button"
                    className="btn btn-danger mb-3"
                    onClick={(e) => {
                      onClose(false);
                    }}
                    disabled={isActivatingCard}
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary mb-3 activate-card-button"
                    disabled={isActivatingCard}
                  >
                    Activate
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
};

export default ActivateCardModal;
