import React from "react";
const bcsuccess = require("../../../../assets/images/bcsuccess.gif");

const BCSuccess = () => {
  return (
    <div id="bcsuccess-cont">
      <div className="bcsuccess-section">
        <p className="bc-success">
          <img src={bcsuccess} alt="loader"/>
        </p>
      </div>
    </div>
  )
}

export default BCSuccess;
