import React from "react";
import Head from "./Head";
import Body from "./Body";
import NoAccountFoundLoader from "../../../../../../assets/images/noaccountsloaderbc.gif";

const Table = ({ wallets, loading, deleteWallet }) => (
  <div>
    <div className="onlinewalletspage-table">
      <table className="_table">
        <Head />
        {loading ? (
          <p className="onlinewalletspage-noaccount-loader">
            <img src={NoAccountFoundLoader} width="500" alt="No Accounts Found" />
          </p>
        ) : (
          <Body wallets={wallets} deleteWallet={deleteWallet} />
        )}
      </table>
    </div>
  </div>
);

export default Table;
