import React from "react";
import Head from "./Head";
import Body from "./Body";
import "../index.scss";

const Table = ({
  bankAccounts,
  loading,
  deleteAccount,
}) => (
  <div>
    <table className="bankaccountspage-table">
      <Head />
      {loading ? (
        <tr className="_table-row">
          <td className="_loader">
            <i className="fa fa-spinner fa-2x"></i>
            <br />
            <div className="text-muted pl-4"> Loading, please wait...</div>
          </td>
        </tr>
      ) : (
        <Body
          bankAccounts={bankAccounts}
          deleteAccount={deleteAccount}
        />
      )}
    </table>
  </div>
);

export default Table;
