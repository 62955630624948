import React from "react";
import DisplayFile from "./DisplayFile";
interface Props {
  data: any;
  onClickEdit: (e: any, step: string) => void;
}

const IdentificationDetails: React.FC<Props> = ({ data, onClickEdit }) => (
  <div>
    <h6>Identification Details</h6>
    <div className="form-group row">
      <label className="col-12 col-md-3 form-label">Place of Birth</label>
      <div className="col-12 col-md-9">
        <span>{data.placeOfBirth}</span>
      </div>
    </div>
    <div className="form-group row">
      <label className="col-12 col-md-3 form-label">Passport Number</label>
      <div className="col-12 col-md-9">
        <span>{data.passportNumber}</span>
      </div>
    </div>
    <div className="form-group row">
      <label className="col-12 col-md-3 form-label">Passport Issued Date</label>
      <div className="col-12 col-md-9">
        <span>{data.passportIssuedDate}</span>
      </div>
    </div>
    <div className="form-group row">
      <label className="col-12 col-md-3 form-label">Passport Expiry Date</label>
      <div className="col-12 col-md-9">
        <span>{data.passportExpiryDate}</span>
      </div>
    </div>
    <div className="form-group row">
      <label className="col-12 col-md-3 form-label">Passport Bio Page</label>
      <div className="col-12 col-md-9">
        <DisplayFile file={data.passportBioPage} />
      </div>
    </div>
    <div className="form-group row">
      <label className="col-12 col-md-3 form-label">
        Selfie with Passport Bio Page
      </label>
      <div className="col-12 col-md-9">
        <DisplayFile file={data.passportWithSelfie} />
      </div>
    </div>
    <div className="form-group row">
      <label className="col-12 col-md-3 form-label">Digital Signature</label>
      <div className="col-12 col-md-9">
        <DisplayFile file={data.digitalSignature} />
      </div>
    </div>
    <div className="ml-auto">
      <a
        className="btn btn-purple"
        href="#"
        onClick={(e) => onClickEdit(e, "identification")}
      >
        Edit <i className="fa fa-pen"></i>
      </a>
    </div>
  </div>
);

export default IdentificationDetails;
