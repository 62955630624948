import React from "react";
import { IconUserPurple } from "./dashboardIcons";

const FeaturedTraders = ({ featuredTradersData }) => {
  const truncatedfeaturedTradersData = featuredTradersData.slice(0, 6);

  return (
    <div id="featured-traders" className="tabcontent">
      <div className="traders">
        {truncatedfeaturedTradersData?.map((trader, index) => (
          <div
            className="trader"
            key={index}
            onClick={() => (window.location.href = trader.trader_profile_link)}
          >
            <div className="profile">
              <IconUserPurple />
              <div className="profile-name">
                <a href={trader.trader_profile_link}>
                  <h4 className="profile-username">{trader.trader_label}</h4>
                </a>
                <h5 className="profile-payments">
                  {trader.trader_payment_method}
                </h5>
                <div className="profile-location d-flex">
                  <i
                    className="fa fa-globe profile-location-icon"
                    aria-hidden="true"
                  ></i>
                  <h6>{trader.trader_location}</h6>
                </div>
              </div>
            </div>

            <div className="details">
              <span>Vol:</span>
              <span>
                {trader.trader_trade_vol}{" "}
                <i className="fa fa-caret-up text-success"></i>
              </span>
            </div>
          </div>
        ))}
        {truncatedfeaturedTradersData.length === 0 && (
          <div className="d-flex justify-content-center align-items-center w-100 h-100">
            <p className="text-center w-100 my-5">
              No Featured Traders Available
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default FeaturedTraders;
