(function (){

  function getSideNavHeight() {
    var hasSideNavHeader = $('.navbar-header').length > 0;
    return hasSideNavHeader ? $('.nav-side-menu').outerHeight() - $('.navbar-header').outerHeight() : $('.nav-side-menu').outerHeight();
  }

  function initSidenav() {
    var width = document.body.clientWidth;
    var sideNavTrackColor = KisoThemes.getRootCssVariable('--sidenav-track-color');

    var sideNavContainer = $('.nav-side-container');

    if(!sideNavContainer.length) {
      return;
    }

    sideNavContainer = $(sideNavContainer[0]);

    // Toggle sidebar visibility on click
    $('.SideNavToggle').on('click', function() {
      $('body').toggleClass('show-sidebar');
    });

    // Collapse previously expanded nav items when a new item is clicked
    $('.nav-side-menu .single-navitem > a').on('click', function(e) {
      var $this = $(this);

      // Collapse all open items except the one being clicked
      $('.nav-side-menu .nav-item .collapse').not($this.next('.collapse')).collapse('hide');
    });

    // Collapse all dropdowns on page load
    $('.nav-side-menu .collapse').collapse('hide');

    // Expand the dropdown with an active link on page load
    $('.nav-side-menu .nav-item').each(function () {
      var $this = $(this);

      // Check if the nav-item contains an active link
      if ($this.find('.active').length > 0) {
        // Expand the corresponding collapse element
        $this.children('.collapse').collapse('show');
      }
    });

    if (!Modernizr.touch && width > 992) {
      sideNavContainer.slimScroll({
        height: getSideNavHeight(),
        color: sideNavTrackColor,
        size: '4px',
        opacity: 0.9,
        wheelStep: 15,
        distance: '0',
        railVisible: false,
        railOpacity: 1
      });

      sideNavContainer.mouseover();
    } else {
      sideNavContainer.slimScroll({ destroy: 'true' });

      sideNavContainer.mouseover();
    }

    // Handle resizing of the sidenav
    $(window).on('resize', KisoThemes.debounce(function() {
      if (Modernizr.touch) return;

      width = document.body.clientWidth;

      if (width < 992) {
        sideNavContainer.slimScroll({ destroy: 'true' });
        sideNavContainer.height('auto');

        sideNavContainer.mouseover();
      } else {
        sideNavContainer.slimScroll({ destroy: 'true' });
        sideNavContainer.slimScroll({
          height: getSideNavHeight(),
          color: sideNavTrackColor,
          size: '4px',
          opacity: 0.9,
          wheelStep: 15,
          distance: '0',
          railVisible: false,
          railOpacity: 1
        });

        sideNavContainer.mouseover();
      }
    }, 250));
  }

  // Hook into page load
  KisoThemes.hookOnPageLoad( function() {
    KisoThemes.jsLibIsActive('sidenav') && initSidenav.call(this);
  });

})();
