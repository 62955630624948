export const convertToNumber = (value: string) => {
  if (!value) return 0;

  const rawValue = value.replace(/[^0-9.,]/g, "");
  return parseFloat(rawValue.replace(/,/g, ""));
};

export const formatAmount = (amount: string) => {
  const numericValue = convertToNumber(amount);
  return numericValue
    ? new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      }).format(numericValue)
    : "";
};
