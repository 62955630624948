
import { Controller } from "stimulus";
import 'jquery.cookie';
// import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["sidebarContainer"];

  connect() {

    $("#sidenavParent").on('shown.bs.collapse', function () {
      let active=$("#sidenavParent .show").attr('id');
      //console.log('active for activeSidenavGroup', active);
      $.cookie('activeSidenavGroup', active)
    });

    $("#sidenavParent").on('hidden.bs.collapse', function () {
      $.removeCookie('activeSidenavGroup');
    });

    $("#sidenavChild").on('shown.bs.collapse', function () {
      let activeChild=$("#sidenavChild .show").attr('id');
      //console.log('activeChild for activeChildSidenavGroup', activeChild)
      $.cookie('activeChildSidenavGroup', activeChild)
    });

    $("#sidenavChild").on('hidden.bs.collapse', function () {
      $.removeCookie('activeChildSidenavGroup');
    });

    let last=$.cookie('activeSidenavGroup');
    let last_child=$.cookie('activeChildSidenavGroup');
    //console.log('last', 'last_child', last, last_child)
    if (last!=null) {
      //remove default collapse settings
      $("#sidenavParent .collapse").removeClass('show');
      //show the last visible group
      $("#"+last).collapse("show");
    }
    if (last_child!=null) {
      //remove default collapse settings
      $("#sidenavChild .collapse").removeClass('show');
      //show the last visible group
      $("#"+last_child).collapse("show");
    }
    if (top.location.pathname.includes('stores') ) {
    $("#sidenavPaymentMenu .collapse").removeClass('show');
    }
  }
}
