import React from "react";

const AddFundsModal = ({ closeModal }) => (
  <div
    className="modal fade"
    id="addFundsModal"
    tabIndex="-1"
    aria-labelledby="addFundsModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title" id="addFundsModalLabel"><i class="fa-solid fa-triangle-exclamation"></i>
          &nbsp; Fund Your Account</h4>
        </div>
        <div className="modal-body" data-controller="clipboard">
          <div className="row mx-auto modal-addfundsbody">
            <div className="col-md-12">
            The amount that you want to pay or transfer is greater than your available balance, please <a href="/add_funds" target="_blank" className="paymepage-error-addfundstext"><strong>fund your account➚</strong></a>
            </div>
          </div>
          <div className="paymepage-buttons-section">
            
            <div>
              <button
                type="button"
                className="paymepage-cancelbtn"
                data-dismiss="modal">
                Close
              </button>
            </div>
            <div>
                <a
                  href="/add_funds"
                  target="_blank"
                  role="button"
                  className="paymepage-paidbtn">Add Funds
                </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default AddFundsModal;
