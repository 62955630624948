import React from "react";

const Carousel = () => (
  <div
    id="p2pBannerCarousel"
    className="carousel slide"
    data-ride="carousel"
    data-interval="5000"
  >
    <ol className="carousel-indicators">
      <li
        data-target="#p2pBannerCarousel"
        data-slide-to="0"
        className="active"
      ></li>
      <li data-target="#p2pBannerCarousel" data-slide-to="1"></li>
      <li data-target="#p2pBannerCarousel" data-slide-to="2"></li>
      <li data-target="#p2pBannerCarousel" data-slide-to="3"></li>
    </ol>
    <div className="carousel-inner p2p-banner">
      <div className="carousel-item active">
        <h2 className="carousel-item-h2">
          Buy and Sell USD with your Preferred Payment Methods
        </h2>
        <h5 className="carousel-item-h5">
          Explore the Best P2P Offers to Buy and Sell USD with Over 100 Payment
          Options.
        </h5>
      </div>

      <a
        href="https://www.bananacrystal.com/bc-debit-card/"
        className="carousel-item"
      >
        <div>
          <h2 className="carousel-item-h2">BananaCrystal USD Debit Card</h2>
          <h5 className="carousel-item-h5">
            With your BananaCrystal USD Debit Card, you can Withdraw your Funds
            from any ATM or POS in Over 200 Countries Worldwide.
          </h5>
        </div>
      </a>

      <a
        href="https://www.bananacrystal.com/business/"
        className="carousel-item"
      >
        <div>
          <h2 className="carousel-item-h2">
            Setup your BananaCrystal Business Account
          </h2>
          <h5 className="carousel-item-h5">
            Accept Payments for your Store and Business with BananaCrystal
            Virtual Terminal (POS).
          </h5>
        </div>
      </a>

      <a
        href="https://www.bananacrystal.com/referral-program/"
        className="carousel-item"
      >
        <div>
          <h2 className="carousel-item-h2">BananaCrystal Referral Program</h2>
          <h5 className="carousel-item-h5">
            Refer Others and Potentially Earn up to $100,000 or More.
          </h5>
        </div>
      </a>
    </div>
  </div>
);

export default Carousel;
