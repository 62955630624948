import React from "react";

export const CirlceQuestionmark = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="12px"
    viewBox="0 -960 960 960"
    width="12px"
    fill="#000"
  >
    <path d="M478-240q21 0 35.5-14.5T528-290q0-21-14.5-35.5T478-340q-21 0-35.5 14.5T428-290q0 21 14.5 35.5T478-240Zm-36-154h74q0-33 7.5-52t42.5-52q26-26 41-49.5t15-56.5q0-56-41-86t-97-30q-57 0-92.5 30T342-618l66 26q5-18 22.5-39t53.5-21q32 0 48 17.5t16 38.5q0 20-12 37.5T506-526q-44 39-54 59t-10 73Zm38 314q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
  </svg>
);

export const TradeLimits = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="20px"
    viewBox="0 -960 960 960"
    width="20px"
    fill="#818990"
  >
    <path d="M288-144 96-336l192-192 51 51-105 105h582v72H234l105 105-51 51Zm384-288-51-51 105-105H144v-72h582L621-765l51-51 192 192-192 192Z" />
  </svg>
);

export const TradeRate = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="20px"
    viewBox="0 -960 960 960"
    width="20px"
    fill="#818990"
  >
    <path d="M480-96q-79 0-149-30t-122.5-82.5Q156-261 126-331T96-480q0-80 30-149.5t82.5-122Q261-804 331-834t149-30h36v322q17 9 26.5 25.5T552-480q0 30-21 51t-51 21q-30 0-51-21t-21-51q0-20 9.5-36.5T444-542v-102q-57 12-94.5 58T312-480q0 70 49 119t119 49q70 0 119-49t49-119q0-35-13-65.5T599-599l51-51q32 33 51 76.5t19 93.5q0 100-70 170t-170 70q-100 0-170-70t-70-170q0-91 58.5-157T444-716v-74q-117 14-196.5 101.5T168-480q0 130 91 221t221 91q130 0 221-91t91-221q0-65-24-121.5T701-701l51-51q52 53 82 122.5T864-480q0 79-30 149t-82.5 122.5Q699-156 629.5-126T480-96Z" />
  </svg>
);

export const TargetAmount = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="20px"
    viewBox="0 -960 960 960"
    width="20px"
    fill="#818990"
  >
    <path d="M349-144q-85 0-145-60t-60-145q0-35 11.5-68t32.5-60l130-165-72-174h468l-73 174 131 165q21 27 32.5 60t11.5 68q0 85-60 145t-145 60H349Zm131-180q-35 0-59.5-24.5T396-408q0-35 24.5-59.5T480-492q35 0 59.5 24.5T564-408q0 35-24.5 59.5T480-324Zm-96-348h192l30-72H354l30 72Zm-35 456h262q55 0 94-39t39-94q0-23-7.5-44T715-432L583-600H377L245-432q-14 18-21.5 39t-7.5 44q0 55 39 94t94 39Z" />
  </svg>
);
