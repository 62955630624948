import React, { useState, useEffect } from "react";
import Select from "react-select";
import NumericInput from "react-numeric-input";
import {
  formatNumber,
  handleAmountChange,
  validateAmount,
  getTotalAmount,
  calculateTraderRate,
} from "../../helpers";
import AmountInput from "./AmountInput";
import { convertToNumber } from "./helpers";

const Pricing = ({ token, updateParent, getData }) => {
  const [fixTradeLimits, setFixTradeLimits] = useState(true);
  const [offerMarginAdvanced, setOfferMarginAdvanced] = useState(false);
  const [currency, setCurrency] = useState(getData("selectedCurrency"));
  const [isFixedPrice, setIsFixedPrice] = useState(false);
  const [rangeMin, setRangeMin] = useState(getData("rangeMin"));
  const [rangeMax, setRangeMax] = useState(getData("rangeMax"));
  const [rangeMinDisplay, setRangeMinDisplay] = useState(new Intl.NumberFormat("en-US", {
    style: "decimal",
    maximumFractionDigits: 2,
  }).format(getData("rangeMin")));
  const [rangeMaxDisplay, setRangeMaxDisplay] = useState(new Intl.NumberFormat("en-US", {
    style: "decimal",
    maximumFractionDigits: 2,
  }).format(getData("rangeMax")));
  const [rangeMinLimit, setRangeMinLimit] = useState(10);
  const [tradeType, setTradeType] = useState(null);
  const [amount, setAmount] = useState(getData("amount") || "");
  const [accountBalance] = useState(getData("availableBalance") || 0);
  const [predefinedAmount, setPredefinedAmount] = useState([]);
  const [actualRate, setActualRate] = useState(0.0);
  const [marginAmount, setMarginAmount] = useState(0.0);
  const [margin, setMargin] = useState(getData("margin") || 5);
  const [tradeExpiry, setTradeExpiry] = useState(8);
  const [maxAmountError, setMaxAmountError] = useState("");
  const [minAmountError, setMinAmountError] = useState("");
  const [desiredRate, setDesiredRate] = useState("");

  const handleMarketChange = (e) => {
    setIsFixedPrice(e.target.value === "fixed_price");
    updateParent("isFixedPrice", e.target.value === "fixed_price");
  };

  const calculateMargin = () => {
    if (!rangeMin) {
      return "0.00";
    }

    const margin = parseFloat(getData("margin")).toFixed(2);
    const rangeMinTemp = parseFloat(rangeMin);
    const calculatedMargin = parseFloat((rangeMinTemp * margin) / 100);
    const calculatedAmount = calculatedMargin + rangeMinTemp;
    return parseFloat(calculatedAmount).toFixed(2);
  };

  const marketMarginText = () => {
    if (currency.overrideRate) {
      return "the parallel price";
    } else if (isFixedPrice) {
      return "the fixed price";
    } else {
      return "market price";
    }
  };

  const getActualRate = (fiatCurrency, tradeType) => {
    if (fiatCurrency.sellRate && tradeType === "sell") {
      return fiatCurrency.sellRate;
    } else if (fiatCurrency.buyRate && tradeType === "buy") {
      return fiatCurrency.buyRate;
    }

    return fiatCurrency.rateToUsd;
  };

  useEffect(() => {
    if (currency.value !== "USD") {
      const actualTempRate = getActualRate(currency, getData("tradeType"));
      const calcMinRange = parseFloat(actualTempRate * 10).toFixed(2);
      setActualRate(actualTempRate);
      const existingMin = getData("rangeMin");
      if (existingMin !== calcMinRange) {
        setRangeMinLimit(calcMinRange);
      } else {
        setRangeMinLimit(existingMin);
      }
    } else {
      setActualRate("1.00");
      setRangeMin("0.00");
      updateParent("rangeMin", "0.00");
      setRangeMinLimit(10);
    }

    setRangeMax(getData("rangeMax") || "");
    setIsFixedPrice(getData("isFixedPrice"));
    setTradeType(getData("tradeType"));
    setMargin(getData("margin"));
    setDesiredRate(calculateTraderRate(margin, getActualRate(currency, getData("tradeType"))));
    const predefinedTemp = [
      "5000",
      "10000",
      "15000",
      "20000",
      "25000",
      "30000",
      "35000",
      "40000",
      "45000",
    ];
    for (let i = 50000; i <= 1000000; i += 50000) {
      predefinedTemp.push(i);
    }
    setPredefinedAmount(predefinedTemp);
    setTradeExpiry(currency.value === "NGN" ? 0.5 : 8);
  }, [currency, getData]);

  const calculateMarginAmount = (marginVal = 5) => {
    const tempAmount = (actualRate * parseFloat(marginVal).toFixed(2)) / 100;
    setMarginAmount(tempAmount);
    setDesiredRate(calculateTraderRate(marginVal, actualRate));
  };

  const handleRateChange = (event) => {
    const currDesiredRate = parseFloat(event.target.value.trim());
    if (!currDesiredRate || currDesiredRate === 0) {
      setMargin("0.00");
      setMarginAmount("0.00");
      updateParent("margin", "0.00");
      setDesiredRate("0");
    } else {
      const tempMargin = ((currDesiredRate - actualRate) / actualRate) * 100;
      const newMargin = parseFloat(tempMargin).toFixed(3);
      setMargin(newMargin);
      const marginPerUnit = currDesiredRate - actualRate;
      setMarginAmount(marginPerUnit.toFixed(2));

      updateParent("margin", parseFloat(tempMargin).toFixed(3));
      setDesiredRate(calculateTraderRate(tempMargin, actualRate));
    }
  };

  const formattedMarginAmount = formatNumber(marginAmount);

  return (
    <div className="pricing create-offer-pricing">
      <div className="rate_type">
        <div className="mb-3">
          <h3 className={`buy-section_title ${tradeType === "sell" ? "sell-section_title " : ""}`}>
            <p className="createoffers-indexnumber"> 1 </p>
            {" "}
            Choose your Preferred Rate Type
          </h3>
        </div>

        <div className="rate_type_select createoffer-cont-boxshadow">
          <div className="custom-control custom-radio">
            <div className="control-label">
              <input
                className="custom-control-input ml-4"
                type="radio"
                name="price_type"
                value="market_price"
                id="market_price"
                checked={!isFixedPrice}
                onChange={handleMarketChange}
              />
              <label
                className="custom-control-label createoffer-sublabels"
                htmlFor="market_price"
                style={{ cursor: "pointer" }}
                title="I want my price to always adjust based on the market price"
              >
                {" "}
                Market Price {" "}
                <i className="fa fa-bar-chart"></i>
              </label>
            </div>
            <div className="mt-2 card-text text-muted font-0_9">
              <i className="fa fa-question-circle-0"></i>
              Your {token === "USDT" ? "USD" : token} to{" "}
              {currency?.value}{" "} price <strong>will flunctuate</strong> as the
              market price changes.
            </div>
          </div>

          <div className="custom-control custom-radio">
            <div className="control-label">
              <input
                className="custom-control-input ml-4"
                type="radio"
                name="price_type"
                value="fixed_price"
                id="fixed_price"
                checked={isFixedPrice}
                onChange={handleMarketChange}
              />
              <label
                className="custom-control-label createoffer-sublabels"
                htmlFor="fixed_price"
                style={{ cursor: "pointer" }}
                title="I want my price to remain fixed the same all the time"
              >
                {" "}
                Fixed Price {" "}
                <i class="fas fa-lock"></i> 
              </label>
            </div>
            <div className="mt-2 card-text text-muted font-0_9">
              <i className="fa fa-question-circle-0"></i>
              Your {token === "USDT" ? "USD" : token} to{" "}
              {currency?.value} price <strong> stays the same </strong> and will not flunctuate
              as the market price changes.
            </div>
          </div>
        </div>
      </div>
      
      <div id="createoffer-amountinput">
        <AmountInput
          autoFocus
          amount={amount}
          tradeType={tradeType}
          accountBalance={accountBalance}
          updateParent={updateParent}
          setAmount={setAmount}
          feePercent={getData("tradeFees")}
        />
      </div>

      <div className="rate mt-5">
        <div className="col-md-12 px-0 mb-3">
          <h3 className={`buy-section_title ${tradeType === "sell" ? "sell-section_title " : ""}`}>
            <p className="createoffers-indexnumber"> 3 </p>
            {" "}Set your Limits Per Transaction for this {tradeType === "sell" ? "Sell" : "Buy"} Offer
          </h3>
        </div>
      </div>

      <div className="mt-2 limit-margin">
        <div className="col-md-12 px-0">
          <div className="col-md-12 mt-0 px-0">
            <form className="limit-form createoffer-cont-boxshadow">
            <div className="mt-2">
              <p>
                <i className="fas fa-info-circle"></i>
                {" "}
                You can have multiple transactions or trades for this offer until you have completely {tradeType === "sell" ? "sold " : "bought "} 
                the total amount you want to {tradeType === "sell" ? "sell" : "buy"}. 
                Please set the minimum and maximunm amount you would like to {tradeType === "sell" ? "sell" : "buy"} per transaction or trade for this offer.
                Your maximum limit per transaction/trade should not exceed{" "}
                    <b>
                      - {" "}
                      {amount <= 0 ? "your current balance:" : `the ${currency?.symbol || currency?.value} equivalent of the total amount you inputed above:`}
                      {" "}
                    {new Intl.NumberFormat("en-US", {
                      style: "decimal",
                      maximumFractionDigits: 2,
                    }).format(
                      getTotalAmount(
                        convertToNumber(amount),
                        actualRate,
                        accountBalance
                      )
                    )}
                    {" "}{currency?.symbol || currency?.value}
                  </b>
              </p>
              </div>
              <div className="row">
                <div className="col-md-6"
                  title={`The lowest amount you would like to ${tradeType === "sell" ? "sell" : "buy"} for each transaction or trade till you have completely ${tradeType === "sell" ? "sold" : "bought"} ${amount} USD - the total amount that you've set for this offer`}
                  >
                  <label className="createoffer-sublabels">
                    <i className="fas fa-question-circle"></i>{" "}
                    Minimum<span style={{ color: "red" }}>*</span>
                  </label>
                  <div className="input-group mb-1">
                    <input
                      type="text"
                      className="createoffer-input"
                      placeholder="0.00"
                      value={rangeMinDisplay || " "}
                      onChange={(e) => handleAmountChange(e, setRangeMin, setRangeMinDisplay)}
                      onBlur={() => {
                        if (rangeMax > 0) {
                          validateAmount(
                            true,
                            amount,
                            tradeType,
                            accountBalance,
                            actualRate,
                            rangeMin,
                            rangeMax,
                            setMinAmountError,
                            setMaxAmountError,
                            setRangeMin,
                            setRangeMax,
                            updateParent,
                            currency
                          );
                        } else {
                          updateParent("rangeMin", rangeMin);
                        }
                      }}                      
                    />
                    <div className="createoffer-currency-code-tag">
                      <p>{currency?.value}</p>
                    </div>
                  </div>
                  {minAmountError && (
                    <div
                    className="createoffer-error-alert"
                    >
                      {minAmountError}
                    </div>
                  )}
                </div>
                <div className="col-md-6"
                  title={`The highest amount you would like to ${tradeType === "sell" ? "sell" : "buy"} for each transaction or trade till you have completely ${tradeType === "sell" ? "sold" : "bought"}  ${amount} USD - the total amount that you've set for this offer`}
                >

                  <label className="createoffer-sublabels" title={`Maximum amount you would like to ${tradeType === "sell" ? "sell" : "buy"} per transaction or trade for this offer`}>
                    <i className="fas fa-question-circle"></i> {" "}
                    Maximum<span style={{ color: "red" }}>*</span>
                  </label>
                  <div className="input-group mb-1">
                    <input
                      type="text"
                      className="createoffer-input"
                      placeholder="0.00"
                      value={rangeMaxDisplay}
                      onChange={(e) => handleAmountChange(e, setRangeMax, setRangeMaxDisplay)}
                      onBlur={() =>
                        validateAmount(
                          false,
                          amount,
                          tradeType,
                          accountBalance,
                          actualRate,
                          rangeMin,
                          rangeMax,
                          setMinAmountError,
                          setMaxAmountError,
                          setRangeMin,
                          setRangeMax,
                          updateParent,
                          currency
                        )
                      }
                    />
                    <div className="createoffer-currency-code-tag">
                      <p>{currency?.value}</p>
                    </div>
                  </div>
                  {maxAmountError && (
                    <div className="createoffer-error-alert">
                      {maxAmountError}
                    </div>
                  )}
                  
                </div>
              </div>
            </form>

            <div className="mt-2">
              {!fixTradeLimits && (
                <small className="text-muted">
                  Your trade partners can start a trade with you with the fixed
                  amount of {currency?.value} you select above.
                </small>
              )}
            </div>
          </div>
        </div>

        <div className="col-md-12 px-0 mt-5 mb-3">
          <h3 className={`buy-section_title ${tradeType === "sell" ? "sell-section_title " : ""}`}>
            <p className="createoffers-indexnumber"> 4 </p>
            {" "}
            Set your Price/USD for this Offer
          </h3>
        </div>

        <div className={`createoffer-payment-method-wrapper ${tradeType === "sell" ? "createoffer-payment-method-wrapper-sell" : ""}`}>
          {token === "BTC" ? (
            <button
              className="btn btn-light"
              onClick={() => setFixTradeLimits(!offerMarginAdvanced)}
            >
              {offerMarginAdvanced ? "Advanced" : "Basic"}
            </button>
          ) : (
            <></>
          )}

          <div className="col-md-12 px-0">
            <div className="mt-2">
              <p>
                <i className="fas fa-info-circle"></i>
                {" "}
                <strong>Use the Most Convenient Option </strong>
              </p>
            </div>
            <div className="col-md-12 mt-0 px-0">
              {!offerMarginAdvanced ? (
                <form>
                  <div className="margin-input-container">
                    <div className="_input-field-card createoffer_input-field-card"
                      title={`The price you are willing to ${tradeType === "sell" ? "sell" : "buy"} 1 USD for`}
                    >
                      <p className="createoffer-margin"><b>Method 1 (Use the Custom Field)</b></p>
                      <label className="createoffer-sublabels">
                        How much do you want to {tradeType === "sell" ? "sell" : "buy"} One(1) USD in {currency?.value}?
                      </label>
                      <div className="input-group mb-1">
                        <input
                          type="number"
                          className="createoffer-input"
                          value={Number(desiredRate) || " "}
                          onChange={handleRateChange}
                        />
                        <div className="createoffer-currency-code-tag">
                          <p>
                              {currency?.value}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="_input-field-card createoffer_input-field-card"
                      title={`The percentage profit you'll make for each transaction or trade in this offer, based on your preferred PRICE vs the MARKET PRICE for 1 USD`}
                    >
                      <p className="createoffer-margin"><b>Method 2 (Use the Margin Stepper)</b>
                        
                        <p>
                          <i className="fas fa-question-circle"></i>
                          {" "}
                          To learn how the margin works,{" "}
                          <strong>
                            <a href={tradeType === "buy" ? "https://www.youtube.com/watch?v=QOKGxcnJVg4" : "https://www.youtube.com/watch?v=RH6OPldYDmU"} target="_blank">
                              Click Here!!
                            </a>
                          </strong>
                        </p>
                      </p>
          
                      <label className="createoffer-sublabels">
                        How many percent(%) above or below the market price do you want to {tradeType === "sell" ? "sell" : "buy"} One(1) USD in {currency?.value}?
                      </label>
                      <div className="input-group mt-2">
                        <NumericInput
                          min={-10}
                          step={0.01}
                          precision={2}
                          value={margin}
                          mobile
                          className="form-control offer-margin-ranger pt-3 pb-2 createoffer-input"
                          onChange={(value) => {
                            updateParent("margin", value);
                            setMargin(value);
                            calculateMarginAmount(value);
                          }}
                        />
                        <div className="input-group-prepend">
                          <div className="input-group-text">
                            <span className="font-weight-bold">%</span>
                          </div>

                        </div>
                      </div>
                      <p>Use the +/- above to adjust your margin below or above the market price</p>
                      {tradeType === "sell" && (
                        <div>
                          <i className="fas fa-bullseye"></i> {" "}
                          {token === "USDT" ? "USD" : token} Market Price:{" "}
                          <strong>
                          {currency.sellRate && !isNaN(currency.sellRate) ? currency.sellRate :  (currency.rateToUsd || "0.00")}
                          {" "}
                          {currency?.value}
                          </strong>
                        </div>
                      )}
                      {tradeType === "buy" && (
                        <div>
                          <i className="fas fa-bullseye"></i> {" "}
                          {token === "USDT" ? "USD" : token} Market Price:{" "}
                          <strong>
                            {currency.buyRate && !isNaN(currency.buyRate) ? currency.buyRate : (currency.rateToUsd || "0.00")}
                            {" "}
                            {currency?.value}
                          </strong>
                        </div>
                      )}
                    </div>
                  </div>
                      
                  
                  <div className="pb-2 createoffer-pricesummary">
                  {/* {currency?.overrideRate ? (
                      <div>
                        Average {token === "USDT" ? "USD" : token} Market Price:{" "}
                        <strong>
                          {currency?.overrideRate} {currency?.value}
                        </strong>
                      </div>
                    ) : (
                      <div>
                        Average {token === "USDT" ? "USD" : token} Market Price:{" "}
                        <strong>
                          {currency?.rateToUsd} {currency?.value}
                        </strong>
                      </div>
                    )} */}
                    <span className="text-primary">
                      You are making <strong>{getData("margin")}%</strong> on
                      every trade.
                    </span>
                      <br />
                    <span>
                      You have a difference of
                      <span>
                        {" "}
                        {formattedMarginAmount}
                        <span className="font-weight-bold">
                          {" "}
                          {currency?.value}{" "}
                        </span>
                        on the current market price.
                      </span>
                    </span>
                    <br/>
                    <div>
                    <p className="pb-2">
                      <b><i className="fas fa-stopwatch"></i> {" "}
                      Offer Time Limit: {" "}
                      <u>{tradeExpiry < 1
                         ? "30 Minutes"
                         : tradeExpiry + " Hours"}
                      </u> 
                      </b><br/>
                      This is how much time your trade partner has to make the payment
                      and click <strong>Paid</strong> before the trade is
                      automatically canceled.
                    </p>

                    </div>
                  </div>
                </form>
              ) : (
                <form className={token !== "BTC" ? "d-none" : ""}>
                  <div className="row">
                    <div className="col">
                      <label>Source</label>
                      <Select
                        className="currencies-select-container"
                        options={[]}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <label>Price point</label>
                      <Select
                        className="currencies-select-container"
                        options={[]}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <label>How much you would like to earn</label>
                      <div className="input-group mb-2 mr-sm-2">
                        <input type="text" className="form-control" />
                        <div className="input-group-prepend">
                          <div className="input-group-text">%</div>
                        </div>
                      </div>
                      <span>on each trade</span>
                    </div>
                  </div>
                </form>
              )}
            </div>
            <div className="createoffer-yourrate">
              Your rate:{" "}
              <strong>
                {formatNumber(calculateTraderRate(margin, actualRate))}{" "}
                {currency?.value} per USD
              </strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
