import React, { Component } from "react";
import { postRequest } from "../../utils/httpRequest";
import "./index.scss";

class WyreTrade extends Component {
  constructor(props) {
    super(props);

    this.state = {
      wyreUrl: "",
      wyreUrlCreatedAt: "",
      reservationId: "",
      buttonCta: props.buttonCta || "Continue"
    };
  }

  componentDidMount() {
    const { reservation, url } = this.props.reservationParams;

    if (reservation) {
      this.setState({ wyreUrl: url, reservationId: reservation }, () =>
        this.initializeWidget(reservation)
      );
      // console.log(url);
    }
  }

  initializeWidget = reservationId => {
    const widget = new Wyre({
      env: this.props.wyreEnvironment,
      reservation: reservationId,
      /*A reservation id is mandatory. In order for the widget to open properly you must use a new, unexpired reservation id.
      Reservation ids are only valid for 1 hour. A new reservation must also be made if a transaction fails.*/
      operation: {
        type: "debitcard-hosted-dialog"
      }
    });

    //console.log(widget);

    widget.on("paymentSuccess", function(e) {
      window.location.reload();
    });

    $("#buy").click(function() {
      widget.open();
    });
  };

  render() {
    const { reservationId, buttonCta } = this.state;
    return (
      <div className="container wyre-trade mt-3">
        <div className="form-actions">
          {reservationId ? (
            <>
              <button className="btn btn-primary ml-auto desktop" id="buy">
                {buttonCta}{" "}
                <i className="fa fa-chevron-right d-inline"></i>
              </button>
              <a
                className="btn btn-primary ml-auto mobile"
                href={this.state.wyreUrl}
              >
                {buttonCta}{" "}
                <i className="fa fa-chevron-right d-inline"></i>
              </a>
            </>
          ) : (
            <button className="btn btn-primary ml-auto">Loading...</button>
          )}
          <div id="wyre-dropin-widget-container" />
        </div>
      </div>
    );
  }
}

export default WyreTrade;
