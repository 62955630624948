// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from 'turbolinks'
import * as ActiveStorage from "@rails/activestorage"
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import 'channels'
import 'controllers'
// import 'lib'
import $ from 'jquery'
import 'bootstrap'
import 'metismenu'
import 'jquery-slimscroll'
import 'stimulus'

require('dotenv').config()

Sentry.init({
  dsn: process.env.SENTRY_REACT_DSN,
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: process.env.SENTRY_TRACES_SAMPLE_RATE,
});


window.jQuery = $;
window.$ = $;


document.addEventListener('turbolinks:load', () => {
  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="popover"]').popover()
})


Rails.start()
Turbolinks.start()
ActiveStorage.start()


require("kiso_themes")

import "controllers"
// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
