import React, { useState, useEffect } from "react";
import moment from "moment";
import ReactLoading from "react-loading";
import FaqSection from "./components/FaqSection";
import PaymentPaid from "./components/PaymentPaid";
import PaymentCancelled from "./components/PaymentCancelled";
import PaymentCompleted from "./components/PaymentCompleted";
import CancelConfirmationModal from "./components/CancelConfirmationModal";
import DisputeConfirmationModal from "./components/DisputeConfirmationModal";
import DisputeInfoModal from "./components/DisputeInfoModal";
import {
  getRequest,
  patchRequest,
  postRequest,
} from "../../../../utils/httpRequest";
import { disputeMessages, disputeResolvedMessages } from "./helpers";
import bcloader from "../../../../../assets/images/bcloader.gif";
import TradeAlerts from "./components/TradeAlerts";
import TradeStatus from "./components/TradeStatus";
import TradeActions from "./components/TradeActions";
import TradeCountdown from "./components/TradeCountdown";
import TradeId from "./components/TradeId";
import TradeChat from "../.././Trades/TradeChat";
import AssetsSafetyCheckModal from "./components/AssetsSafetyCheckModal";
import NoticeMessageModal from "./components/NoticeMessageModal";

interface DisputeData {
  respondent_name?: string;
  claimant_name?: string;
  user_note?: string;
  is_claimant?: boolean;
  resolved_chat?: string;
}
const TradeDetail = ({
  tradeDataObj,
  offer,
  chatActionCableUrl,
  isBuyer,
  isSeller,
  isBroker,
}) => {
  const [bankAccounts, setBankAccounts] = useState([]);
  const [sellerRate, setSellerRate] = useState(0.0);
  const [tradeData, setTradeData] = useState(tradeDataObj);
  const [isOfferUser, setIsOfferUser] = useState(tradeDataObj.is_offer_user);

  const [isLoading, setIsLoading] = useState(false);
  const [isPaidClicked, setIsPaidClicked] = useState(false);
  const [isAcceptClicked, setIsAcceptClicked] = useState(false);
  const [isCancelClicked, setIsCancelClicked] = useState(false);
  const [isReopenClicked, setIsReopenClicked] = useState(false);
  const [isDisputeClicked, setIsDisputeClicked] = useState(false);
  const [currentUserId, setCurrentUserId] = useState(
    tradeDataObj.current_user_profile_id
  );
  const [isDisputed, setIsDisputed] = useState(tradeDataObj.disputed);
  const [isSystemCancelled, setIsSystemCancelled] = useState(
    tradeDataObj.system_cancelled
  );

  const [disputeData, setDisputeData] = useState<DisputeData>(
    tradeDataObj.disputes[0]
  );
  const [validationMessage, setValidationMessage] = useState("");
  const [hasError, setHasError] = useState(false);
  const [readyToAccept, setReadyToAccept] = useState(false);
  const [validationMessageAccept, setValidationMessageAccept] = useState("");
  const [validationMessageDispute, setValidationMessageDispute] = useState("");
  const [showChat, setShowChat] = useState(false);
  const [releaseFundsTimer, setReleaseFundsTimer] = useState(8);
  const [bankAccountList, setBankAccountList] = useState([]);
  const [referenceMessage, setReferenceMessage] = useState(
    tradeDataObj.reference_message
  );
  const [tradeBankAccount, setTradeBankAccount] = useState({});
  const [sellerPaymentDetail, setSellerPaymentDetail] = useState(
    tradeDataObj.seller_payment_method_detail || offer?.tradeDetails
  );
  const [acceptSuccess, setAcceptSuccess] = useState(false);
  const [paidSuccess, setPaidSuccess] = useState(false);
  const [previousTradeStatus, setPreviousTradeStatus] = useState(
    tradeData.external_status
  );
  const [currentTradeStatus, setCurrentTradeStatus] = useState(
    tradeData.external_status
  );

  const refreshPage = () => {
    const url = window.location.href;
    window.location.assign(url);
  };

  useEffect(() => {
    if (currentTradeStatus !== previousTradeStatus) {
      setPreviousTradeStatus(currentTradeStatus);
      refreshPage();
    }
  }, [currentTradeStatus, previousTradeStatus]);

  const showModal = () => {
    const cancelmodal = document.querySelector("#cancelConfirmModal") as HTMLElement;
    if (cancelmodal) {
      cancelmodal.style.display = "flex";
    }
  };

  const closeCancelTradeModal = () => {
    const cancelmodal = document.querySelector("#cancelConfirmModal") as HTMLElement;
    if (cancelmodal) {
      cancelmodal.style.display = "none";
    }
  };

  // All three funcs below handles release modal
  const handleReleaseButtonClick = () => {
    const releasemodal = document.querySelector("#releaseConfirmModal") as HTMLElement;
    if (releasemodal) {
      releasemodal.style.display = "flex";
    }
  };

  const closeReleaseModal = () => {
    const releasemodal = document.querySelector("#releaseConfirmModal") as HTMLElement;
    if (releasemodal) {
      releasemodal.style.display = "none";
    }
  };

  const handleAssetsSafetyCheckConfirm = () => {
    // Logic to handle assets safety check confirmation
    acceptTrade();
    const releasemodal = document.querySelector("#releaseConfirmModal") as HTMLElement;
    if (releasemodal) {
      releasemodal.style.display = "none";
    }
  };

  const showDisputeModal = () => {
    setValidationMessageDispute("");
    if (!isDisputedAllowed()) {
      setValidationMessageDispute(
        "Please wait 3 hours to create a dispute request if payment is not released."
      );
      return;
    }
    setValidationMessageDispute("");
    const disputeconfirmmodal = document.querySelector("#disputeConfirmModal") as HTMLElement;
    if (disputeconfirmmodal) {
      disputeconfirmmodal.style.display = "flex";
    }
  };

  const closeDisputeModal = () => {
    const disputeconfirmmodal = document.querySelector("#disputeConfirmModal") as HTMLElement;
    if (disputeconfirmmodal) {
      disputeconfirmmodal.style.display = "none";
    }
  }

  const showNoticeMessageModal = () => {
    const noticeMessageModal = document.querySelector("#noticeMessageModal") as HTMLElement;
    if (noticeMessageModal) {
      noticeMessageModal.style.display = "flex";
    }
  };

  const closeNoticeMessageModal = () => {
    const noticeMessageModal = document.querySelector("#noticeMessageModal") as HTMLElement;
    if (noticeMessageModal) {
      noticeMessageModal.style.display = "none";
    }
  }

  const showError = (error) => {
    let message;
    if (error.data?.errors?.length > 0) {
      message = error.data?.errors[0]?.error;
    } else if (error.data?.errors?.data?.errors?.length > 0) {
      message = error.data?.errors?.data?.errors[0]?.error;
    }
    if (message) {
      setValidationMessage(message);
      setHasError(true);
    }
  };

  const paidTrade = () => {
    setIsPaidClicked(true);
    patchRequest(`/p2p_trades/trades/${tradeData.id}/paid`, {})
      .then((response) => {
        // TODO: check response before setting paid success
        setCurrentTradeStatus("payment_paid");
        setPaidSuccess(true);
      })
      .catch((error) => {
        console.log(error);
        showError(error);
      });
  };

  const acceptTrade = () => {
    setIsAcceptClicked(true);
    if (!readyToAccept) {
      setValidationMessageAccept("Please confirm the above checkbox");
      return;
    }

    setValidationMessageAccept("");

    patchRequest(`/p2p_trades/trades/${tradeData.id}/accept`, {})
      .then((response) => {
        // TODO: check response before setting success
        setCurrentTradeStatus("payment_accepted");
        setAcceptSuccess(true);
      })
      .catch((error) => {
        console.log(error);
        showError(error);
      });
  };

  const cancelTrade = () => {
    patchRequest(`/p2p_trades/trades/${tradeData.id}/cancel`, {})
      .then((response) => {
        // TODO: check response before setting cancel
        setIsCancelClicked(true);
        refreshPage();
      })
      .catch((error) => {
        console.log(error);
        showError(error);
      });
  };

  const reopenTrade = () => {
    patchRequest(`/p2p_trades/trades/${tradeData.id}/reopen`, {})
      .then((response) => {
        // TODO: check response before setting reopen
        setIsReopenClicked(true);
        refreshPage();
      })
      .catch((error) => {
        console.log(error);
        showError(error);
      });
  };

  const createDispute = () => {
    postRequest(`/p2p_trades/trades/${tradeData.id}/trade_disputes`, {})
      .then((response) => {
        // // TODO: check response before redirecting to open support ticket
        // window.open(response?.dispute_url, "_blank", "noopener,noreferrer");
        setIsDisputeClicked(true);
        setIsDisputed(true);
        setDisputeData(response);
        handleSendMessage(response);
        const disputeConfirmModal = document.querySelector('#disputeConfirmModal') as HTMLElement;
        if (disputeConfirmModal) {
          disputeConfirmModal.style.display = "none";
        }
        refreshPage();
      })
      .catch((error) => {
        console.log(error);
        showError(error);
      });
  };

  const handleSendMessage = (disputeData) => {
    const message = disputeMessages(
      offer,
      disputeData,
      tradeData,
      tradeBankAccount
    );
    if (message.trim() === "") return;
    let formData = new FormData();
    formData.append("message", message);

    postRequest(
      `/p2p_trades/trades/${tradeData.id}/chats`,
      {
        message: formData.get("message"),
      },
      {
        "Content-Type": "multipart/form-data",
      }
    )
      .then((response) => {})
      .catch((error) => {});
  };

  const handleDisputeResolvedSendMessage = (disputeData) => {
    const message = disputeResolvedMessages(
      offer,
      disputeData,
      tradeData,
      tradeBankAccount
    );
  
    if (message.trim() === "") return;
  
    let formData = new FormData();
    formData.append("message", message);
  
    if (disputeData?.status === "resolved" && disputeData?.resolved_chat === "not_sent") {
      postRequest(
        `/p2p_trades/trades/${tradeData.id}/chats`,
        {
          message: formData.get("message"),
        },
        {
          "Content-Type": "multipart/form-data",
        }
      )
        .then((response) => {
          const p2p_trade_dispute = {
            resolved_chat: "sent",
            bypass_trade_expiry_validation: true,
          };
          
          return patchRequest(`/p2p_trades/trade_disputes/${disputeData.id}`, {
            p2p_trade_dispute: p2p_trade_dispute,
            headers: {
              'Content-Type': 'application/json',
            }
          });
        })
        .then((response) => {
          console.log("Resolved chat status updated to sent");
        })
        .catch((error) => {
          showError(error);
        });
    }
  };  

  const getTradeById = () => {
    // TODO: fix is loading
    setIsLoading(true);
    let tradeDetails = getRequest(
      `/p2p_trades/trades/${tradeData.id}/details`
    ).then((response) => {
      return response;
    });

    if (tradeDetails.disputed && tradeDetails.disputes.length > 0) {
      setDisputeData(tradeDetails.disputes[0]);
      const disputeinfomodal = document.querySelector("#disputeInfoModal") as HTMLElement;
      if (disputeinfomodal) {
        disputeinfomodal.style.display = "flex";
      }
    }

    setIsLoading(false);
  };

  const closeModalAndAlerts = (sectionid) => {
    const alertToClose = document.querySelector(`#${sectionid}`) as HTMLElement;
    if (alertToClose) {
      alertToClose.style.display = "none";
    }
  };

  const getTradeBankAccount = () => {
    if (tradeData?.payment_method_name === "Bank Transfer" && offer?.bankAccounts) {
      if (typeof offer?.bankAccounts === 'string') {
        try {
          setTradeBankAccount(JSON.parse(offer?.bankAccounts));
        } catch (error) {
          getRequest(`/p2p_trades/bank_accounts/${offer?.bankAccounts}`)
          .then((response) => {
            setTradeBankAccount(response?.data || {
              error: "The bank account details for this trade have been deleted. Kindly request the bank details via the chat box."
            });
          })
        }
      }
    }
  };

  const handleResize = () => {
    if (window.innerWidth >= 921) {
      setShowChat(true);
    } else {
      setShowChat(false);
    }
  }

  useEffect(() => {
    handleResize();
    getTradeById();
    fetchReviews();
    showNoticeMessageModal();
    /* Get trade rate for the current trade */
    if (tradeData.trade_rate) {
      setSellerRate(tradeData.trade_rate);
    }
    handleDisputeResolvedSendMessage(disputeData);
    setReleaseFundsTimer(offer?.country?.code === "NG" ? 0.5 : 8);
    getTradeBankAccount();
  }, [tradeData, disputeData]);


  // Set display of tradechat on resize above920px
  window.addEventListener("resize", handleResize);

  const checkChatDisabled = () => {
    return ["expired"].includes(tradeData.external_status);
  };

  const isDisputedAllowed = () => {
    return moment.utc(tradeData?.paid_at).local().add(3, "hours").isBefore();
  };

  const hideChat = () => {
    setShowChat(false);
  };

  const [initialReview, setInitialReview] = useState(null);

  const [isRotating, setIsRotating] = useState(false);

  const fetchReviews = async () => {
    try {
      const response = await getRequest('/p2p_trades/reviews');
      const reviews = response;

        // Find the review with the trade
        const matchingReview = reviews?.find(
          (review) => review.resourceId	=== tradeData?.external_id
        );
        if (matchingReview) {
          setInitialReview(matchingReview);
        } else {
          setInitialReview(null);
        }
    } catch (error) {
      console.error("Error fetching reviews:", error);
    }
  };

  const handleRefresh = () => {
    setIsRotating(true);
    refreshPage();
    setTimeout(() => {
      setIsRotating(false);
    }, 500);
  };

  return (
    <div>
      {isLoading && (
        <div className="text-center">
          <img src={bcloader} alt="loader"/>
        </div>
      )}

      {hasError && (
        <div className="col-12 mt-3 createoffer-error-alert">
          {validationMessage}
        </div>
      )}
      {/* PENDING PAYMENT AND PAYMENT PAID */}
      {["pending_payment", "payment_paid"].includes(tradeData.external_status) &&
      (!isSystemCancelled || isDisputed) &&
      tradeData.external_id && (
        <TradeAlerts 
          isDisputed={isDisputed}
          isSeller={isSeller}
          acceptSuccess={acceptSuccess}
          isBuyer={isBuyer}
          paidSuccess={paidSuccess}
          onClose={closeModalAndAlerts}
        />
      )}

      <div className="tradedetails-parent-cont">
        <div>
          <div className="tradestatus-countdown-section">
            <TradeStatus 
              isDisputed={isDisputed}
              tradeData={tradeData}
              isSystemCancelled={isSystemCancelled}
            />
            <TradeCountdown
              tradeData={tradeData}
              isDisputed={isDisputed}
              isSystemCancelled={isSystemCancelled}
              acceptSuccess={acceptSuccess}
              isDisputedAllowed={isDisputedAllowed}
            />
          </div>
          <TradeId 
            tradeData={tradeData}
          />
          <div
              className={`tradechat-button-cont ${
                showChat ? "d-none" : ""
              }`}
            >
              {!showChat && (
                <button
                  className="tradechat-button"
                  onClick={(e) => setShowChat(true)}
                >
                  <i className="fa fa-comments-o fa-2x"></i>
                  Chat with{" "}
                  {isBuyer
                    ? "Seller"
                    : "Buyer"}
                </button>
              )}
            </div>
        </div>

        <div className="tradedetails-subparent-cont">
          <div className="tradedetails-details-cont">
          <div className="payment-container">
            {/* PENDING PAYMENT AND PAYMENT PAID */}
            {["pending_payment", "payment_paid"].includes(
              tradeData.external_status
            ) &&
              (!isSystemCancelled || isDisputed) &&
              tradeData.external_id && (

              <PaymentPaid
                tradeData={tradeData}
                isSystemCancelled={isSystemCancelled}
                showChat={showChat}
                isRotating={isRotating}
                handleRefresh={handleRefresh}
                isBuyer={isBuyer}
                isSeller={isSeller}
                acceptSuccess={acceptSuccess}
                paidSuccess={paidSuccess}
                isPaidClicked={isPaidClicked}
                paidTrade={paidTrade}
                readyToAccept={readyToAccept}
                setReadyToAccept={setReadyToAccept}
                validationMessageAccept={validationMessageAccept}
                handleReleaseButtonClick={handleReleaseButtonClick}
                isAcceptClicked={isAcceptClicked}
                tradeBankAccount={tradeBankAccount}
                referenceMessage={referenceMessage}
                sellerPaymentDetail={sellerPaymentDetail}
                offer={offer}
                sellerRate={sellerRate}
                chatActionCableUrl={chatActionCableUrl}
                hideChat={hideChat}
                disputeData={disputeData}
                isDisputed={isDisputed}
                currentUserId={currentUserId}
                isDisputedAllowed={isDisputedAllowed}
                showDisputeModal={showDisputeModal}
                isDisputeClicked={isDisputeClicked}
                setValidationMessageDispute={setValidationMessageDispute}
                showModal={showModal}
                handleAssetsSafetyCheckConfirm={handleAssetsSafetyCheckConfirm}
                validationMessageDispute={validationMessageDispute}
                checkChatDisabled={checkChatDisabled}
                isCancelClicked={isCancelClicked}
                setShowChat={setShowChat}
                cancelTrade={cancelTrade}
                onClose={closeModalAndAlerts}
              />
            )}
          </div>
          <div className="payment-container">
            {/* CANCELLED */}
            {(tradeData.external_status === "cancelled" || isSystemCancelled) && (
              <PaymentCancelled
                tradeData={tradeData}
                isSystemCancelled={isSystemCancelled}
                isDisputed={isDisputed}
                showChat={showChat}
                isRotating={isRotating}
                handleRefresh={handleRefresh}
                setShowChat={setShowChat}
                isBuyer={isBuyer}
                offer={offer}
                isOfferUser={isOfferUser}
                isReopenClicked={isReopenClicked}
                reopenTrade={reopenTrade}
                tradeBankAccount={tradeBankAccount}
                sellerRate={sellerRate}
                isSeller={isSeller}
                referenceMessage={referenceMessage}
                checkChatDisabled={checkChatDisabled}
                sellerPaymentDetail={sellerPaymentDetail}
                chatActionCableUrl={chatActionCableUrl}
                hideChat={hideChat}
                disputeData={disputeData}
                currentUserId={currentUserId}
              />
            )}
        </div>
            {/* COMPLETED */}
            <PaymentCompleted
              tradeData={tradeData}
              showChat={showChat}
              isRotating={isRotating}
              initialReview={initialReview}
              setShowChat={setShowChat}
              isBuyer={isBuyer}
              isOfferUser={isOfferUser}
              offer={offer}
              tradeBankAccount={tradeBankAccount}
              referenceMessage={referenceMessage}
              sellerPaymentDetail={sellerPaymentDetail}
              sellerRate={sellerRate}
              isSeller={isSeller}
              checkChatDisabled={checkChatDisabled}
              chatActionCableUrl={chatActionCableUrl}
              hideChat={hideChat}
              disputeData={disputeData}
              isDisputed={isDisputed}
              currentUserId={currentUserId}
            />
          </div>
          <div className="tradedetails-chat-cont">
            {/* Content for the second container */}
            <div
              className={`tradechat-show ${
                !showChat ? "d-none tradechat-hide" : ""
              }`}
            >
              <TradeChat
                offerLabel={offer?.customOfferLabel}
                tradeId={tradeData.id}
                currentUserId={currentUserId}
                sellerUsername={tradeData.seller_username}
                buyerUsername={tradeData.buyer_username}
                isBuyer={isBuyer}
                isSeller={isSeller}
                userName={
                  isBuyer ? tradeData.seller_username : tradeData.buyer_username
                }
                isChatDisabled={checkChatDisabled()}
                hideChat={hideChat}
                userOnline={offer?.online}
                lastSeenAt={offer?.lastSeenAt}
                chatActionCableUrl={chatActionCableUrl}
              />
            </div>
          </div>
        </div>
        {/* DISPLAY NOTICE MESSAGE ONLY WHEN PAYMENT IS PENDING */}
        {["pending_payment", "payment_paid"].includes(
              tradeData.external_status
            ) &&
              (!isSystemCancelled || isDisputed) &&
              tradeData.external_id && (
        <NoticeMessageModal onClose={closeNoticeMessageModal}/>)}
        
        <CancelConfirmationModal onConfirm={cancelTrade} onClose={closeModalAndAlerts} />
        <DisputeConfirmationModal onConfirm={createDispute} onClose={closeModalAndAlerts} />
        <DisputeInfoModal isClaimant={disputeData?.is_claimant} onClose={closeModalAndAlerts} />
        <AssetsSafetyCheckModal
          tradeData={tradeData}
          onConfirm={handleAssetsSafetyCheckConfirm}
          onClose={closeModalAndAlerts}
        />
      </div>
      <FaqSection />
    </div>
  );
};

export default TradeDetail;
