import React, { useState, useEffect } from "react";
import consumer from "channels/consumer";

const UsersOnlineStatus = ({ userId = null }) => {
  const [allUserStatus, setAllUserStatus] = useState([]);

  useEffect(() => {
    const channel = consumer.subscriptions.create(
      { channel: "UsersOnlineStatusChannel" },
      {
        received(data) {
          setAllUserStatus(prevUserStatus => {
            const existingUserStatus = prevUserStatus.filter(
              status => status.user_id !== data.user_id
            );
            return [...existingUserStatus, data];
          });
        }
      }
    );

    return () => {
      channel.unsubscribe();
    };
  }, []);

  const userStatus = userId
    ? allUserStatus.filter(status => status.user_id === userId)[0]
    : null;

  return (
    <div>
      {allUserStatus.length > 0 && (
        <>
          {userStatus ? (
            <div>
              {userStatus.online ? (
                <p className="font-0_9">
                  <i
                    className="fa fa-circle"
                    aria-hidden="true"
                    style={{ color: "green" }}
                  ></i>{" "}
                  Online
                </p>
              ) : (
                <p className="font-0_9">Last Seen: {userStatus.last_seen_at}</p>
              )}
            </div>
          ) : (
            <></>
          )}
        </>
      )}
    </div>
  );
};

export default UsersOnlineStatus;
