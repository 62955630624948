import React, { useState, useEffect } from 'react';
import { postRequest, patchRequest } from '../../../../utils/httpRequest';

const ReviewForm = ({ profileId,reviewedProfileId, tradeExternalId, onSuccess, initialReview }) => {
  const [vote, setVote] = useState(initialReview?.vote || '');
  const [comment, setComment] = useState(initialReview?.comment || '');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (initialReview) {
      setVote(initialReview.vote || '');
      setComment(initialReview.comment || '');
    }
  }, [initialReview]);

  const handleVoteChange = (newVote) => {
    setVote(newVote);
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null)

    if (!vote || !comment || comment.trim().length===0 ) {
      setError('Please fill out all fields.');
      return;
    }

    if (!/^[a-zA-Z0-9\s.,'\n]*$/.test(comment)) {
      setError('Please enter a valid comment containing only letters, numbers, spaces, commas, full stops, and apostrophes.');
      return;
    }    

    setIsLoading(true);

    try {
      let response;
      if (initialReview) {
        response = await patchRequest(`/p2p_trades/reviews/${initialReview.id}`, {
          vote: vote,
          comment: comment,
        });
      } else {
        response = await postRequest('/p2p_trades/reviews', {
          profile_id: profileId,
          reviewed_profile_id: reviewedProfileId,
          trade_external_id: tradeExternalId,
          vote: vote,
          comment: comment,
        });
      }

      if (response) {
        setError(null);
        onSuccess();
      }
    } catch (error) {
      console.error('Error submitting review:', error);
      setError('Failed to submit review. Please try again.');
    }

    setIsLoading(false);
  };

  return (
    <div>
      <span className="reviewform-title">{initialReview ? 'Edit Review' : 'Leave a Review'}</span>
      {error && (
        <div className="reviewform-error" role="alert">
          {error}
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label className="reviewform-subtitle">How would you rate your trade experience?</label>
          <span className='vote-btns'> 
            <button
                title='I liked this trade!'
                type="button"
                className={`reviewbtn ${vote === 'upvote' ? 'upvote-button' : 'default-button'} text-white mr-2`}
                onClick={() => handleVoteChange('upvote')}
            >
                <i className="fa fa-thumbs-up" aria-hidden="true"></i>
            </button>
            <button
                title='I did not like this trade!'
                type="button"
                className={`reviewbtn ${vote === 'downvote' ? 'downvote-button' : 'default-button'} text-white`}
                onClick={() => handleVoteChange('downvote')}
            >
                <i className="fa fa-thumbs-down" aria-hidden="true"></i>
            </button>

          </span>

        </div>
        <div className="mb-3">
          <label htmlFor="comment" className="reviewform-subtitle">
            How would you describe your trade experience?
          </label>
          <textarea
            className="review-textarea"
            id="comment"
            value={comment}
            onChange={handleCommentChange}
            rows="4"
          />
        </div>
        <button type="submit" className="review-submit-button" disabled={isLoading}>
          {isLoading ? 'Submitting...' : initialReview ? 'Update Reveiw' : 'Submit Review'}
        </button>
      </form>
    </div>
  );
};

export default ReviewForm;
