import walletAddressValidator from "@swyftx/api-crypto-address-validator/dist/wallet-address-validator.min.js";
import base58check from "base58check";

export const validateWalletAddress = (chain, address) => {
  let validAddress = true;

  if (chain && address) {
    validAddress =
      chain === "SOL"
        ? validateSolanaAddress(address)
        : walletAddressValidator.validate(address, chain, 'both');
  }

  return validAddress;
};

const validateSolanaAddress = address => {
  try {
    base58check.encode(address);
    return true;
  } catch (e) {
    return false;
  }
};

export const validateWalletAddressTag = (chain, addressTag) => {
  let validAddressTag = true;

  console.log(chain, addressTag);

  if (chain !== "XLM" && addressTag) {
    validAddressTag = false;
  }
  if  (chain === "XLM" && (addressTag === '' || addressTag == undefined)) {
    validAddressTag = false;

  }
  return validAddressTag;
};
