import { Controller } from "stimulus";
import Rails from "@rails/ujs";
import axios from "axios";

export default class extends Controller {
  static targets = [
    "inputGrossAmount",
    "inputPaymentMethod",
    "inputPaymentMethodType",
    "outputGrossAmount",
    "outputFeesAmount",
    "outputNetAmount",
    "outputRate",
    "outputNetAmountConverted",
    "wallet",
    "processorType"
  ];

  connect() {
    console.log('connected')
    let payout_fees, netAmount, paymentMethod, paymentMethodType, grossAmount, walletId, processorType, baseCurrency, currency;

    // inputs
    if (this.hasInputPaymentMethodTypeTarget) {
      paymentMethodType = this.inputPaymentMethodTypeTarget.value;
    }
    if (this.hasInputPaymentMethodTarget) {
      paymentMethod = this.inputPaymentMethodTarget.value;
    }

    if (this.hasInputGrossAmountTarget) {
      grossAmount = this.inputGrossAmountTarget.value;
    }

    if (this.walletTarget) {
      walletId = this.walletTarget.value;
    }

    if (this.hasProcessorTypeTarget) {
      processorType = this.processorTypeTarget.value;
    }

    this.inputGrossAmountTarget.setCustomValidity("");
    // set defaults
    this.outputNetAmountTarget.value = 0.00.toFixed(2);

    if (this.hasoutputNetAmountConvertedTarget) {
      this.outputNetAmountConvertedTarget.innterText = 0.00.toFixed(2);
    }

    if (paymentMethodType === 'sepa') {
      baseCurrency = 'USD'
      currency = 'EUR'
      this.payoutFiatRates(baseCurrency, currency);
    }

    if (
      paymentMethodType &&
      this.validGrossAmount(grossAmount, paymentMethodType)
    ) {
      this.hasAvailableBalance(grossAmount, walletId).then(
        walletBalance => {
          this.payoutFees(grossAmount, paymentMethodType, processorType);
          if (paymentMethodType === 'sepa')
            this.payoutFiatRates(baseCurrency, currency);
          this.getLimit(grossAmount, paymentMethodType).then(message => {
            if (message) {
              this.inputGrossAmountTarget.setCustomValidity(message);
            }
          }); 
          if (walletBalance < grossAmount) {
            this.inputGrossAmountTarget.setCustomValidity(
              "The selected account does not have enough funds to perform this transaction"
            );
          } else {
            this.inputGrossAmountTarget.setCustomValidity("");
          }
          //console.log(grossAmount, walletBalance)
        });
    }
  }

  onChangePaymentMethod() {
    if (this.hasInputPaymentMethodTarget) {
      let paymentMethodId = this.inputPaymentMethodTarget.value;
      const parser = new URL(window.location);
      parser.searchParams.set('preferred', paymentMethodId);
      window.location = parser.href;
    }
  }


  // single transaction amount limits
  validGrossAmount(grossAmount, paymentMethodType) {
    let isValidGrossAmount;
    if (paymentMethodType == "wire") {
      isValidGrossAmount = grossAmount && grossAmount >= 1 == true;
    } else if (paymentMethodType == "sepa") {
      isValidGrossAmount = grossAmount && grossAmount >= 1 == true;
    } else if (paymentMethodType == "ach") {
      isValidGrossAmount = grossAmount && grossAmount >= 50 == true;
    }
    return isValidGrossAmount;
  }

  hasAvailableBalance(grossAmount, walletId) {
    return axios
      .get(`/payouts/selected_wallet_balance?wallet_id=${walletId}`, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then(response => response.data);
  }

  getLimit(grossAmount, paymentMethodType) {
    return axios
      .get(
        `/limits?gross_amount=${grossAmount}&payment_method_type=${paymentMethodType}&transaction_type=payout`,
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
      .then(response => response.data.message);
  }

  payoutFees(grossAmount, paymentMethodType, processorType) {
    let response;
    Rails.ajax({
      type: "GET",
      url: `/payouts/fees?gross_amount=${grossAmount}&payment_method_type=${paymentMethodType}&processor_type=${processorType}`,
      success: (_data, _status, xhr) => {
        response = JSON.parse(xhr.response);
        this.outputGrossAmountTarget.value = response["gross_amount"];
        this.outputFeesAmountTarget.value = response["fees"];
        this.outputNetAmountTarget.value = response["net_amount"];
      }
    });
  }

  payoutFiatRates(baseCurrency, currency) {
    let response;
    Rails.ajax({
      type: "GET",
      url: `/payouts/fiat_rates?base_currency=${baseCurrency}&currency=${currency}`,
      success: (_data, _status, xhr) => {
        response = JSON.parse(xhr.response);
        this.outputRateTarget.textContent = parseFloat(response["rate"]);
        this.outputNetAmountConvertedTarget.textContent = (parseFloat(this.outputNetAmountTarget.value) * parseFloat(response["rate"])).toFixed(2);
      }
    });
  }
}
