import React from "react";
import ActivateCard from "./ActivateCard";
import "../../../P2PTrades/Offers/index.scss";

const Activations = ({ cardRequests, isCardActivated }) => {
  return (
    <div className="container-fluid px-3 px-md-5">
      <div className="card shadow-sm rounded-lg">
        <div className="card-header">
          <h5 className="font-weight-bold">Activate Card <i className="fa fa-bolt"></i></h5>
        </div>
        <div className="card-body">
          <div className="d-flex flex-row flex-wrap">
            {cardRequests.map((cardRequest, i) => (
              <ActivateCard
                key={i}
                cardRequest={cardRequest}
                isCardActivated={isCardActivated}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Activations;
