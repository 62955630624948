import React, { Component, useState } from "react";
import Select from "react-select";
import currencies from "./currencies.json";
import "./index.scss";
import { postRequest, patchRequest } from "../../utils/httpRequest";
import { checkPropTypes } from "prop-types";

class SelectCurrency extends Component {
  constructor(props) {
    super(props);
    this.state = {
      preferredCurrency: props.preferredCurrency,
      validationErrors: {},
      saveOnSelect: props.saveOnSelect,
      refreshOnSave: props.refreshOnSave,
    };

    let saveOnSelect = this.state.saveOnSelect;
    let refreshOnSave = this.state.refreshOnSave;

    let selectedCurrency = currencies.filter(currency => {
      return currency.value === this.props.preferredCurrency
    });

    this.state = {
      // selectedOption: currencies[0]
      selectedOption: selectedCurrency[0],
      isFullWidth: props?.isFullWidth
    };
  }

  refreshPage = () => {
    const url = window.location.href;
    window.location.assign(url);
  };

  handleChange = selectedOption => {
    this.setState({ selectedOption });
    if (this.props.saveOnSelect) {
      this.updateAlternateCurrency(selectedOption['value']);
    }
  };

  updateAlternateCurrency = async currency => {
    const { validationErrors } = this.state;

    const crsfToken = $('meta[name="csrf-token"]').attr("content");


    const data = {
      settings: {
        alternate_currency: currency
      }
    };

    postRequest("/users/settings", data, {
      "X-CSRF-Token": crsfToken
    })
      .then(
        () => {
          if (this.props.refreshOnSave) {
            this.refreshPage();
          }
        }
      )
      .catch(error => {
        validationErrors.recipientError = "currency is invalid";

        this.setState({ validationErrors });
        console.log(error);
      });
  };

  render() {
    const { selectedOption, isFullWidth } = this.state;

    return (
      <div className="" style={{ marginTop: "5px", fontSize: '0.9em', textAlign: 'left', minWidth:'200px' ,maxWidth: isFullWidth ? '100%' : '300px' }}>
        <div className="form-actions">
          <Select
            className={"select-currency"}
            name="aria-select-currency"
            value={selectedOption}
            isSearchable
            onChange={this.handleChange}
            options={currencies}
            formatOptionLabel={selectedOption.value}
          />
        </div>
      </div>
    );
  }
}

export default SelectCurrency;
