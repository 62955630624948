import React, { useState } from "react";
import NoAccountFoundLoader from "../../../../../../assets/images/noaccountsloaderbc.gif";

const Body = ({ wallets, deleteWallet }) => {
  const [copied, setCopied] = useState(false);
  const handleCopy = (text, field) => {
    navigator.clipboard.writeText(text);
    setCopied(field);
    setTimeout(() => setCopied(false), 2000);
  };
  return (
    <tbody>
      {wallets.map((wallet, i) => (
        <tr key={wallet.id} className="onlinewalletspage-tablebody-row">
          <td className="onlinewalletspage-indexnumber-cont">
            <span className="onlinewalletspage-indexnumber">{i + 1}</span>
          </td>
          <td data-label="Name:">{wallet.walletName}</td>
          <td data-label="Caption:">
            <div>{wallet.caption}</div>
          </td>
          <td data-label="URL:">
            <div>
              <a href={wallet.url} target="_blank">
                {wallet.url}
              </a>
              <button onClick={() => handleCopy(wallet.url, "url")} className="onlinewalletspage-copybtn">
                <i className={`fas fa-${copied === "url" ? "check" : "copy"}`} title={`${copied ? "Copied" : "Copy"}`}></i>
              </button>
            </div>
          </td>
          <td data-label="Actions:" className="onlinewalletspage-table-actions onlinewalletspage-table-mobileactions">
            <span className="onlinewalletspage-actions-icons-cont">
              <a
                href="#"
                className="bankaccountspage-actions-icons"
                onClick={(e) => {
                  deleteWallet(e, wallet.id);
                }}
              >
                <i className="fas fa-trash"></i>
              </a>
            </span>
          </td>
        </tr>
      ))}
      {wallets.length === 0 && (
        <tr className="onlinewalletspage-noaccount-loader">
          <img src={NoAccountFoundLoader} width="500" alt="No Accounts Found" />
        </tr>
      )}
    </tbody>
  );
};

export default Body;
