import React from "react";
import "../index.scss"

const Head = () => (
  <thead className="onlinewalletspage-tablehead">
    <tr className="onlinewalletspage-tablehead-row">
      <th>Name</th>
      <th>Caption</th>
      <th>URL</th>
      <th className="onlinewalletspage-table-actions">Actions</th>
    </tr>
  </thead>
);

export default Head;
