// Volume Chart
export const volumeOptions = {
  chart: {
    type: "area",
    height: "auto",
    foreColor: "#FFFFFF",
    toolbar: {
      show: false,
    },
  },
  xaxis: {
    type: "datetime",
    labels: {
      style: {
        colors: "#FFFFFF",
      },
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    title: {
      text: "Transaction Volume",
      style: {
        color: "#FFFFFF",
      },
    },
    labels: {
      style: {
        colors: "#FFFFFF",
      },
      formatter: function (value) {
        return "$" + value;
      },
    },
  },
  colors: ["#8884D8"],
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      opacityFrom: 0.7,
      opacityTo: 0.9,
      stops: [0, 90, 100],
    },
  },
  responsive: [
    {
      breakpoint: 480,
      options: {
        chart: {
          width: "100%",
        },
        legend: {
          position: "bottom",
        },
      },
    },
  ],
  legend: {
    labels: {
      colors: "#FFFFFF",
    },
  },
  tooltip: {
    theme: "dark",
    style: {
      fontSize: "12px",
      fontFamily: undefined,
    },
    y: {
      formatter: function (val) {
        return `$${val}`;
      },
    },
  },
};

export const generateLabel = (_date) => {
  const date = new Date(_date);
  return date
    .toLocaleDateString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
    })
    .replace(/(\d+)\s(\w+)\s(\d+)/, (match, day, month, year) => {
      const suffix = ["th", "st", "nd", "rd"][
        (day > 3 && day < 21) || day % 10 > 3 ? 0 : day % 10
      ];
      return `${day}${suffix} ${month}, ${year}`;
    });
};

export const groupByDateAndSum = (sortedData) => {
  const result = [];
  let currentGroup = null;

  for (const { created_at, net_amount } of sortedData) {
    if (!currentGroup || currentGroup.created_at !== created_at) {
      if (currentGroup) result.push(currentGroup);
      currentGroup = { created_at, net_amount: 0 };
    }
    currentGroup.net_amount += +net_amount;
  }
  if (currentGroup) result.push(currentGroup);

  return result;
};
