import React from "react";
import AssetsSafetyCheckModal from "./AssetsSafetyCheckModal";
import moment from "moment";

const TradeActions = ({
  isBuyer,
  tradeData,
  offer,
  isSeller,
  isDisputed,
  isPaidClicked,
  acceptSuccess,
  paidTrade,
  readyToAccept,
  setReadyToAccept,
  validationMessageAccept,
  handleReleaseButtonClick,
  handleAssetsSafetyCheckConfirm,
  isAcceptClicked,
  isSystemCancelled,
  isCancelClicked,
  validationMessageDispute,
  setValidationMessageDispute,
  isDisputeClicked,
  isDisputedAllowed,
  showModal,
  showDisputeModal,
  isRotating,
  handleRefresh,
}) => {
  return (
    <div className="tradeaction-parent-cont">

      {/* tradeaction-title */}
      <div className="tradedetails-tradeinfo-title tradeaction-title-section">
        <div>
          {isBuyer && tradeData.external_status === "pending_payment" && (
            <p>
              <i className="fas fa-exclamation-triangle"></i>{" "}
              Please make a payment of {tradeData?.fiat}{" "}
              {tradeData?.fiat_currency_code} using{" "}
              {offer?.paymentMethod?.name}
            </p>
          )}
          {isBuyer && tradeData.external_status === "payment_paid" && (
            <p>
              <i className="fas fa-exclamation-triangle"></i> {" "}
              You have made a payment of {tradeData?.fiat}{" "}
              {tradeData?.fiat_currency_code} using{" "}
              {offer?.paymentMethod?.name}
            </p>
          )}
          {isSeller && 
          (tradeData.external_status === "pending_payment" || tradeData.external_status === "payment_paid") && (
            <p>
              <i className="fas fa-exclamation-triangle"></i> {" "}
              You are selling{" "}
              {parseFloat(tradeData?.crypto_amount).toFixed(2)}{" "}
              {tradeData?.crypto_currency_code == "USDT"
                ? "USD"
                : tradeData?.crypto_currency_code}{" "}
              using {offer?.paymentMethod?.name}
            </p>
          )}
        </div>
        <div>
          <span className="tradedetails-refresh-btn" title="Refresh Trade Status" onClick={handleRefresh}>
            <i
              className={`fa fa-refresh ${
                isRotating ? "rotate" : ""
              }`}
              id="refreshBtn"
            ></i>
            {" "}
            Refresh
          </span>
        </div>
      </div>
    
      <div>
        {/* Trade Action Status */}
        <div className="tradeaction-status">
          {isBuyer ? (
            <p>
              <i className="fas fa-bullhorn"></i>
              <br/>
              {parseFloat(tradeData?.crypto_amount).toFixed(2)}{" "}
              {tradeData?.crypto_currency_code == "USDT"
                ? "USD"
                : tradeData?.crypto_currency_code}{" "}
              will be added to your BananaCrystal Wallet once the other
              party confirms your payment and releases the funds.
              <span style={{color: '#f79335'}}><b>{" "} WARNING:</b> You can only cancel the trade if you haven't transferred funds to the seller. Otherwise, use the dispute button.</span>
            </p>
          ) : (
            <></>
          )}
          {isBuyer && tradeData?.external_status === "payment_paid" ? (
            <p className="validationMessageDispute">
              <i className="fas fa-bullhorn"></i>
              <br/>
              You have paid. The other party has 3 hours to confirm your payment and release the
              funds.
            </p>
          ) : (
            <></>
          )}
          {isSeller &&
          isSeller !== null &&
          tradeData.external_status === "pending_payment" ? (
            <p>
              <i className="fas fa-bullhorn"></i>
              <br/>
              The buyer has not paid yet.
            </p>
          ) : (
            <></>
          )}
          {isSeller &&
          isSeller !== null &&
          tradeData.external_status === "payment_paid" &&
          acceptSuccess ? (
            <p>
              <i className="fas fa-bullhorn"></i>
              <br/>
              This trade has been accepted.
            </p>
          ) : isSeller &&
            isSeller !== null &&
            tradeData.external_status === "payment_paid" ? (
            <p>
              <i className="fas fa-bullhorn"></i>
              <br/>
              The buyer has paid {tradeData?.fiat} {tradeData?.fiat_currency_code}. Be sure to check your{" "}
              <b>{offer?.paymentMethod?.name}</b> balance to confirm that
              you've received your money.{" "}
            </p>
          ) : (
            <></>
          )}
          {tradeData?.external_status === "payment_paid" &&
            !isDisputed &&
            !acceptSuccess ? (
            <div>
              {validationMessageDispute !== "" && (
                <p className="validationMessageDispute">
                  <i className="fas fa-bullhorn"></i>
                  <br/>
                  {validationMessageDispute}
                </p>
              )}
            </div>
          ) : null}
          {isSeller && !acceptSuccess ? (
            <div>
              {validationMessageAccept !== "" && (
                <p  className="validationMessageDispute">
                  <i className="fas fa-bullhorn"></i>
                  <br/>
                  {validationMessageAccept}
                </p>
              )}
            </div>
          ) : null}
        </div>
  
        {/* Instructions */}
        <div className="tradedetails-instructions-from">
          {isBuyer &&
            tradeData.external_status === "pending_payment" && (
              <p>
                Once you've made the payment, be sure to click{" "}<b>Paid</b> within the given time limit. Otherwise the trade will be automatically canceled and the{" "}
                {tradeData?.crypto_currency_code == "USDT"
                  ? "USD"
                  : tradeData?.crypto_currency_code}{" "}
                will be returned to the other party in the trade.
              </p>
            )}
          {isBuyer && tradeData.external_status === "payment_paid" && (
            <p>
              If the other party does not release the funds within 3 hours. You can click on Dispute request button to create
              dispute against the trade. A moderator from BananaCrystal will help you resolve the dispute.
            </p>
          )}
          {isSeller &&
            isSeller !== null &&
            tradeData.external_status === "pending_payment" && (
              <p>
                Before releasing the{" "}
                <b>
                  {tradeData?.crypto_currency_code == "USDT"
                    ? "USD"
                    : tradeData?.crypto_currency_code}
                </b>
                , be sure to check your{" "}
                <b>{offer?.paymentMethod?.name}</b> balance to confirm that you've received your money. Once you've released
                your funds, the transaction is final and can't be reversed.
              </p>
            )}
        </div>
        
        {/* Action Notification and cta */}
        <div className="tradeaction-notification-cta">
          {/* MADE PAYMENT BUYER / RELEASE USD ACTIONS SELLER */}
          <div className="tradeaction-notification-cta-subsection">
            <div className="buyers-buttons-section">
              {tradeData?.external_status === "pending_payment" &&
              !isSystemCancelled ? (
                <div>
                  {isBuyer &&
                    tradeData?.external_status === "pending_payment" &&
                    !isSystemCancelled && (
                      <div>
                        <button
                          className="tradeaction-canceltradebtn"
                          type="button"
                          disabled={isCancelClicked}
                          onClick={(e) => showModal()}
                          title="Ensure that you have not paid yet"
                        >
                          {isCancelClicked ? "Please wait ..." : "Cancel Trade"}
                        </button>
                      </div>
                    )}
                </div>
              ) : (
                <></>
              )}
              {isBuyer &&
              tradeData?.external_status !== "payment_paid" &&
              !isDisputed ? (
                <button
                  className="tradeaction-paidbtn"
                  disabled={isPaidClicked}
                  type="button"
                  onClick={(e) => paidTrade()}
                >
                  {isPaidClicked ? "Please wait" : "I Have Made Payment"}
                </button>
              ) : (
                <></>
              )}{" "}
            </div>
            {isSeller && !acceptSuccess ? (
              <>
                <div className="tradeaction-checkboxarea-andvalidation-cont">
                  <div className="custom-control custom-checkbox tradeaction-checkboxarea ">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="readyToAccept"
                      checked={readyToAccept}
                      onChange={(e) => {
                        setReadyToAccept(e.target.checked);
                      }}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="readyToAccept"
                    >
                      I have received payment from the other party and I'm now ready to <strong>release{" "}{parseFloat(tradeData?.crypto_amount).toFixed(2)} USD</strong> - the USD equivalent <span className="text-muted">(based on the price per USD of this trade)</span> of {tradeData?.fiat} {tradeData?.fiat_currency_code} that I have received from the buyer.
                    </label>
                  </div>
                </div>
                <div className="sellerReleaseDisputeBtn-cont">
                <button
                  className="tradeaction-paidbtn"
                  disabled={
                    !(
                      tradeData?.external_status === "payment_paid" &&
                      readyToAccept
                    )
                  }
                  type="button"
                  onClick={(e) => handleReleaseButtonClick()}
                >
                  {isAcceptClicked
                    ? "Please wait"
                    : `Release ${
                      tradeData?.crypto_currency_code == "USDT"
                        ? "USD"
                        : tradeData?.crypto_currency_code
                    }`
                  }
                </button>
                {tradeData?.external_status === "payment_paid" &&
                !isDisputed &&
                !acceptSuccess ? (
                  <button
                  className="tradeaction-disputebtn pull-right"
                  type="button"
                  disabled={isDisputeClicked}
                  onClick={(e) => showDisputeModal()}
                  title="Did not receive payment yet?"
                >
                  {isDisputeClicked ? "Please wait ..." : "Dispute Transaction"}
                </button>
                 ) : (
                  <></>
                )}
                </div>
              </>
            ) : (
              <></>
            )}
            
          </div>
        </div>
        {/* NOT DISPUTED RELATED ACTIONS */}
        <div className="tradeaction-notification-cta">
          <div className="tradeaction-notification-cta-subsection">
            {tradeData?.external_status === "payment_paid" &&
            !isDisputed &&
            !acceptSuccess ? (
            <div className="buyers-buttons-section ">
              {/* For buyer only. Display cancel button when buyer has clicked paid */}
              {isBuyer && !isDisputed ?  (
                <div>
                  <button
                    className="tradeaction-canceltradebtn"
                    type="button"
                    disabled={isCancelClicked}
                    onClick={(e) => showModal()}
                    title="I have changed my mind"
                  >
                  {isCancelClicked ? "Please wait ..." : "Cancel Trade"}
                  </button>
                </div>
              ) : (<></>)
              }
              {isBuyer && !isDisputed ?  (
              <div>
                <button
                  className="tradeaction-disputebtn"
                  type="button"
                  disabled={isDisputeClicked}
                  onClick={(e) => showDisputeModal()}
                  title="Did not receive payment yet?"
                >
                  {isDisputeClicked ? "Please wait ..." : "Dispute Transaction"}
                </button>
              </div>
              ) : (<></>)
             }
            </div>
            ) : (
              <></>
            )}
          </div>
        </div>

        {/* DISPUTED RELATED ACTIONS */}
        <div className="tradeaction-notification-cta">
          <div className="tradeaction-notification-cta-subsection">
            {tradeData?.external_status === "payment_paid" &&
            isDisputed &&
            !acceptSuccess ? (
            
            <div className="buyers-buttons-section ">
              {/* For buyer only. Display cancel button when buyer has clicked paid */}
              {isBuyer && isDisputed ?  (
                <div>
                  <button
                    className="tradeaction-canceltradebtn"
                    type="button"
                    disabled={isCancelClicked}
                    onClick={(e) => showModal()}
                    title="I have changed my mind"
                  >
                  {isCancelClicked ? "Please wait ..." : "Cancel Trade"}
                  </button>
                </div>
              ) : (<></>)
              }
            </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <hr />
      <div className="tradedetails-disclaimer-info">
        {isBuyer ? (
          <p>
            <i className="fas fa-exclamation-triangle"></i>
            {" "}
            Keep trades within BananaCrystal. Some users may ask you to trade outside BananaCrystal platform. This is against our Terms of Service and likely a deceptive attempt. You must
            insist on keeping all trade conversations within BananaCrystal. If you choose to proceed outside BananaCrystal, note that we cannot help or support you if anything goes wrong during such trades.
          </p>
        ) : (
          <p>
            <i className="fas fa-exclamation-triangle"></i>
            {" "}
            Look out for dishonest traders who ask for early release or use fake payment screenshots. Make sure you've received your money <b>before releasing the amount</b>.
          </p>
        )}
      </div>
    </div>
  )
}

export default TradeActions;
