import React from "react";
import { QRCodeSVG } from "qrcode.react";

const QrCode = (props) => {
  return (
    <QRCodeSVG
      value={props.link}
      size={200}
      style={{ height: "auto", width: "100%" }}
    />
  );
};

export default QrCode;
