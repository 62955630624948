import React, { useState } from "react";

const DisputeInfo = ({ isDisputed, disputeData }) => {
  const [copied, setCopied] = useState(null);

  const handleCopy = (text, field) => {
    navigator.clipboard.writeText(text);
    setCopied(field);
    setTimeout(() => setCopied(false), 2000);
};
  return (
    <div>
      <p className="tradedetails-tradeinfo-title">
        <i className="fas fa-info-circle"></i>
        <span>Dispute</span>
      </p>
      <div>
        <div className="tradedetails-tradeinfo-specifics">
          {!isDisputed && (
            <div className="tradedetails-tradeinfo-specifics-text">
              <p className="tradedetails-tradeinfo-specifics-textvalues">
                Currently this trade does not have any disputes.
              </p>
            </div>
          )}
          {isDisputed && (
            <div className="tradedetails-tradeinfo-specifics">
              <div className="tradedetails-tradeinfo-specifics-text">
                <p
                  className="tradedetails-tradeinfo-specifics-textnames"
                >
                  <i className="fa fa-hourglass-half"></i>
                  {" "}Status
                </p>
                <span className="tradedetails-tradeinfo-specifics-textvalues disputeinfo-disputestatus">{disputeData?.status}</span>
              </div>
              <div className="tradedetails-tradeinfo-specifics-text">
                <p
                  className="tradedetails-tradeinfo-specifics-textnames"
                >
                  <i className="fas fa-bullseye"></i>
                  {" "}Dispute ID
                </p>
                <p className="tradedetails-tradeinfo-specifics-textvalues">{disputeData?.id}
                  <button onClick={() => handleCopy(disputeData.id, "disputeData.id")} className="tradedetails-copybtn">
                    <i className={`fas fa-${copied === "disputeData.id" ? "check" : "copy"}`} title={`${copied ? "Copied" : "Copy"}`}></i>
                  </button>
                </p>
              </div>
              <div className="tradedetails-tradeinfo-specifics-text">
                <p
                  className="tradedetails-tradeinfo-specifics-textnames"
                >
                  <i className="fa fa-thumbtack"></i> 
                  {" "}Notes
                </p>
                <span className="tradedetails-tradeinfo-specifics-textvalues">{disputeData?.user_note || 'None'}</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DisputeInfo;
