import React, { useState } from "react";
import {
  IconCopy,
  IconUserAddPurple,
  IconUserPurple,
  USDTLogo,
} from "../Dashboard/dashboardIcons";

const SelectPaymentMethod = ({ referralCode, setSelectedPaymentMethod }) => {
  const [isCopied, setIsCopied] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const copyToClipboard = () => {
    const addressInput = document.getElementById("tokenAddressInput");
    addressInput.select();
    navigator.clipboard.writeText(referralCode).then(
      () => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 2000);
      },
      (err) => {
        console.error("Could not copy text: ", err);
      }
    );
  };

  const handleSelectUSD = () => {
    setSelectedPaymentMethod("USD");
    const currentPath = window.location.pathname;
    const newPath = `${currentPath}${
      currentPath.includes("?") ? "&" : "?"
    }payment_method=USD`;
    window.history.pushState({}, "", newPath);
  };

  return (
    <div className="card shadow-sm send-payment-card">
      <div className="card-header flex-column align-items-start">
        <h5 className="card-title mb-0 font-weight-bold">
          Select how you want to send USD
        </h5>
        <p className="m-0 my-1 font-0_85">
          Simply ask your receiver to provide their email associated with their
          BananaCrystal account, or their USDT address.
        </p>
      </div>
      <div className="transfer-options">
        <div
          className="transfer-option-wrapper option-account d-flex flex-row align-items-center"
          onClick={handleSelectUSD}
        >
          <div className="d-flex flex-row align-items-center">
            <div className="transfer-option-icon">
              <IconUserPurple height="3rem" width="3rem" />
            </div>
            <div className="pl-2">
              <p className="font-weight-bold m-0 my-1">
                Send to a BananaCrystal Account
              </p>
              <p className="m-0 my-1">
                <small>
                  Send to a receiver who is already registered on the
                  BananaCrystal platform.
                </small>
              </p>
            </div>
          </div>
          <div className="transfer-option-icon text-purple justify-self-end">
            <i className="fa fa-long-arrow-right"></i>
          </div>
        </div>
        <div className="transfer-option-wrapper option-invite d-flex flex-column">
          <div
            className="d-flex flex-row align-items-center justify-content-between"
            onClick={() => setSelectedOption("invite")}
          >
            <div className="d-flex flex-row align-items-center">
              <div className="transfer-option-icon">
                <IconUserAddPurple height="3rem" width="3rem" />
              </div>
              <div className="pl-2">
                <p className="font-weight-bold m-0 my-1">
                  Invite receiver to join BananaCrystal
                </p>
                <p className="m-0 my-1">
                  <small>
                    Invite a receiver to join the BananaCrystal platform so you
                    can send them USD.
                  </small>
                </p>
              </div>
            </div>
            <div className="transfer-option-icon text-purple">
              <i className="fa fa-long-arrow-right"></i>
            </div>
          </div>
          {selectedOption === "invite" && (
            <div className="option-drop">
              <p className="font-weight-bold font-0_8">
                Copy this link and send to your invite
              </p>
              <div className="input-group mb-2 address-input-wrapper">
                <input
                  type="text"
                  className="form-control"
                  id="tokenAddressInput"
                  placeholder="Select Coin"
                  value={referralCode}
                />
                <div className="drop-icon-wrapper">
                  <div className="address-copy-btn" onClick={copyToClipboard}>
                    {isCopied ? (
                      <>
                        Copied <i className="fa fa-check"></i>
                      </>
                    ) : (
                      <>
                        Copy <IconCopy />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="transfer-option-wrapper option-blockchain d-flex flex-column">
          <div
            className="d-flex flex-row align-items-center justify-content-between"
            onClick={() => setSelectedOption("blockchain")}
          >
            <div className="d-flex flex-row align-items-center">
              <div className="transfer-option-icon">
                <USDTLogo height="2.7rem" width="2.7rem" />
              </div>
              <div className="pl-2">
                <p className="font-weight-bold m-0 my-1">
                  Send via USDT on Polygon Network.
                </p>
                <p className="m-0 my-1">
                  <small>
                    Send USDT via the blockchain to an external wallet address
                    on the Polygon Network.
                  </small>
                </p>
              </div>
            </div>
            <div className="transfer-option-icon text-purple">
              <i className="fa fa-long-arrow-right"></i>
            </div>
          </div>
          {selectedOption === "blockchain" && (
            <div className="option-drop">
              <p className="font-weight-bold font-0_9">
                <i className="fa fa-info-circle"></i> Only send USDT on the
                Polygon Blockchain to this address.
              </p>
              <p className="font-0_8">
                Using this address for another blockchain or other coins or
                tokens will result in the irreversible loss of your funds.
                Please always check with your sending party that the blockchain
                is Polygon and the cryptocurrency is POS Tether (USDT).
              </p>
              <a
                class="btn btn-primary btn-purple mb-2 w-100"
                type="submit"
                href="/crypto_transfers/send_crypto/blockchain_transfer"
              >
                Send USDT <i className="fa fa-arrow-right"></i>
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SelectPaymentMethod;
