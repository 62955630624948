import React, {useState} from "react";

const SellerBankPaymentMethodDetails = ({
  isSeller,
  instructionsFrom,
  buyerUsername,
  buyerName,
  sellerUsername,
  sellerPaymentDetail,
  paymentMethod,
  transferTotal,
  referenceMessage,
}) => {
  if(!sellerPaymentDetail) return null;

  const detailsArray = sellerPaymentDetail.split(",");
  const [copied, setCopied] = useState(null);

  const sellerBankDetail = {
    accountHolderName: detailsArray[0].trim(),
    currency: detailsArray[1].trim(),
    bankName: detailsArray[2].trim(),
    accountNumber: detailsArray[3].trim(),
  };

  const handleCopy = (text, field) => {
      navigator.clipboard.writeText(text);
      setCopied(field);
      setTimeout(() => setCopied(false), 2000);
  };

  return (
    <>
      <div>
        <p className="tradedetails-tradeinfo-title">
          <i className="fas fa-info-circle"></i>
          {" "}{isSeller ? " My " : " Seller's "}
            <span>Bank Payment Details</span>
        </p>
        <div className="tradedetails-tradeinfo-section">
          <div>
            <div>
              <div>
                {!isSeller ? (
                  <p className="tradedetails-instructions-from">
                    Use the details below to make the transfer. Make payment to the bank details below. Make sure you use
                    the exact words in the <b>reference message</b> when sending the
                    transfer.
                  </p>
                ) : (
                  <p className="tradedetails-instructions-from">
                    Confirm your bank details below for this transaction. Make
                    sure you look for the exact words in the <b>reference message </b>
                    when looking for the transfer from the buyer.
                  </p>
                )}
                <div className="bank_details">
                  {sellerBankDetail && (
                    <div>
                      <div className="trade_bank_account tradedetails-tradeinfo-specifics-text">
                        <label className="tradedetails-tradeinfo-payment-textnames">Payment Method:</label>
                        <span className="tradedetails-tradeinfo-payment-textvalues">{paymentMethod}
                          <button onClick={() => handleCopy(paymentMethod, "paymentMethod")} className="tradedetails-copybtn">
                            <i className={`fas fa-${copied === "paymentMethod" ? "check" : "copy"}`} title={`${copied ? "Copied" : "Copy"}`}></i>
                          </button>
                        </span>
                      </div>
                      <div className="trade_bank_account tradedetails-tradeinfo-specifics-text">
                        <label className="tradedetails-tradeinfo-payment-textnames">Bank Name:</label>
                        <span className="tradedetails-tradeinfo-payment-textvalues">{sellerBankDetail?.bankName}
                          <button onClick={() => handleCopy(sellerBankDetail?.bankName, "sellerBankDetail?.bankName")} className="tradedetails-copybtn">
                            <i className={`fas fa-${copied === "sellerBankDetail?.bankName" ? "check" : "copy"}`}   title={`${copied ? "Copied" : "Copy"}`}></i>
                          </button>
                        </span>
                      </div>
                      <div className="trade_bank_account tradedetails-tradeinfo-specifics-text">
                        <label className="tradedetails-tradeinfo-payment-textnames">Account Name:</label>
                        <span className="tradedetails-tradeinfo-payment-textvalues">{sellerBankDetail?.accountHolderName}
                          <button onClick={() => handleCopy(sellerBankDetail?.accountHolderName, "sellerBankDetail?.accountHolderName")} className="tradedetails-copybtn">
                            <i className={`fas fa-${copied === "sellerBankDetail?.accountHolderName" ? "check" : "copy"}`}   title={`${copied ? "Copied" : "Copy"}`}></i>
                          </button>
                        </span>
                      </div>
                      <div className="trade_bank_account tradedetails-tradeinfo-specifics-text">
                        <label className="tradedetails-tradeinfo-payment-textnames">Account Number:</label>
                        <span className="tradedetails-tradeinfo-payment-textvalues">{sellerBankDetail?.accountNumber}
                          <button onClick={() => handleCopy(sellerBankDetail?.accountNumber, "sellerBankDetail?.accountNumber")} className="tradedetails-copybtn">
                            <i className={`fas fa-${copied === "sellerBankDetail?.accountNumber" ? "check" : "copy"}`} title={`${copied ? "Copied" : "Copy"}`}></i>
                          </button>
                        </span>
                      </div>
                      <div className="trade_bank_account tradedetails-tradeinfo-specifics-text">
                        <label className="tradedetails-tradeinfo-payment-textnames">Account Currency:</label>
                        <span className="tradedetails-tradeinfo-payment-textvalues">{sellerBankDetail.currency}
                          <button onClick={() => handleCopy(sellerBankDetail.currency, "sellerBankDetail.currency")} className="tradedetails-copybtn">
                            <i className={`fas fa-${copied === "sellerBankDetail.currency" ? "check" : "copy"}`} title={`${copied ? "Copied" : "Copy"}`}></i>
                          </button>
                        </span>
                      </div>
                      <div className="trade_bank_account tradedetails-tradeinfo-specifics-text">
                        <label className="tradedetails-tradeinfo-payment-textnames">Reference Message:</label>
                        <span className="tradedetails-tradeinfo-payment-textvalues">{referenceMessage || "hands polish"}
                          <button onClick={() => handleCopy(referenceMessage || "hands polish", "referenceMessage")} className="tradedetails-copybtn">
                            <i className={`fas fa-${copied === "referenceMessage" ? "check" : "copy"}`} title={`${copied ? "Copied" : "Copy"}`}></i>
                          </button>
                        </span>
                      </div>
                      <div className="trade_bank_account tradedetails-tradeinfo-specifics-text">
                        <label className="tradedetails-tradeinfo-payment-textnames">Total Amount:</label>
                        <span className="tradedetails-tradeinfo-payment-textvalues">{transferTotal}
                          <button onClick={() => handleCopy(transferTotal, "transferTotal")} className="tradedetails-copybtn">
                            <i className={`fas fa-${copied === "transferTotal" ? "check" : "copy"}`} title={`${copied ? "Copied" : "Copy"}`}></i>
                          </button>
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <p>{isSeller ? "Buyer's Details" : "Seller's Details"}</p>
        <div>
          <div>
            <div>
              <div className="trade_bank_account">
                <div className="trade_bank_account tradedetails-tradeinfo-specifics-text">
                  <label className="tradedetails-tradeinfo-payment-textnames">Name:</label> 
                  <span className="tradedetails-tradeinfo-payment-textvalues">{isSeller ? buyerName : sellerUsername}
                  </span>
                </div>
                <div className="trade_bank_account tradedetails-tradeinfo-specifics-text">
                  <label className="tradedetails-tradeinfo-payment-textnames">Total Amount:</label>
                  <span className="tradedetails-tradeinfo-payment-textvalues">{transferTotal}
                    <button onClick={() => handleCopy(transferTotal, "transferTotal")} className="tradedetails-copybtn">
                      <i className={`fas fa-${copied === "transferTotal" ? "check" : "copy"}`} title={`${copied ? "Copied" : "Copy"}`}></i>
                    </button>
                  </span>
                </div>
                <div className="trade_bank_account tradedetails-tradeinfo-specifics-text">
                <label className="tradedetails-tradeinfo-payment-textnames" title="Transaction Reference or Description Message to include and watchout for when making payment">Transaction Reference Message:</label>
                  <span className="tradedetails-tradeinfo-payment-textvalues">{referenceMessage || "hands polish"}
                    <button onClick={() => handleCopy(referenceMessage || "hands polish", "referenceMessage")} className="tradedetails-copybtn">
                      <i className={`fas fa-${copied === "referenceMessage" ? "check" : "copy"}`} title={`${copied ? "Copied" : "Copy"}`}></i>
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SellerBankPaymentMethodDetails;
