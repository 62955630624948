import React from "react";

const ActivityPagination = ({ currentPage, totalPages, onPageChange }) => {
  const handlePageChange = (e, page) => {
    onPageChange(e, page);
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    if (totalPages <= 10) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else if (currentPage <= 5) {
      for (let i = 1; i <= 10; i++) {
        pageNumbers.push(i);
      }
    } else if (currentPage > totalPages - 5) {
      for (let i = totalPages - 9; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      for (let i = currentPage - 4; i <= currentPage + 5; i++) {
        pageNumbers.push(i);
      }
    }
    return pageNumbers;
  };

  return (
    <div>
      <nav aria-label="Page navigation">
        <ul className="pagination justify-content-center">
          {currentPage > 1 && (
            <li className="page-item">
              <button
                className="page-link"
                onClick={(e) => handlePageChange(e, currentPage - 1)}
              >
                <i className="fa fa-chevron-left"></i> Previous
              </button>
            </li>
          )}

          {getPageNumbers().map((page) => (
            <li
              key={page}
              className={`page-item ${currentPage === page ? "active" : ""}`}
            >
              <button
                className="page-link"
                onClick={(e) => handlePageChange(e, page)}
              >
                {page}
              </button>
            </li>
          ))}

          {currentPage < totalPages && (
            <li className="page-item">
              <button
                className="page-link"
                onClick={(e) => handlePageChange(e, currentPage + 1)}
              >
                Next <i className="fa fa-chevron-right"></i>
              </button>
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
};

export default ActivityPagination;
