import consumer from "./consumer"

document.addEventListener("turbolinks:load", () => {
  const chatContainer = document.querySelector(".chat-container")
  if (chatContainer) {
    const p2pTradeId = chatContainer.dataset.p2pTradeId
    consumer.subscriptions.create({ channel: "P2pChatChannel", id: p2pTradeId }, {
      connected() {
        console.log("Connected to the chat channel.")
      },

      disconnected() {
        console.log("Disconnected from the chat channel.")
      },

      received(data) {
        const messagesContainer = document.querySelector(".messages")
        messagesContainer.insertAdjacentHTML("beforeend", data.message)
      }
    })
  }
})
